import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SpreadOfferTab.module.sass';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';


export const SpreadOfferTab = ({nameTab, textContent}) => {
	const checkId = useRef(uuid());
	const [spreadState, setSpreadState] = useState(false);

	const handlerChange = (event) => {
		if (event.target.closest("."+classnames(styles.spread_checkbox))){
			setSpreadState((prev)=>!prev);
		}
	}

	return (
		<div className={classnames(styles.spread_item)} onChange={handlerChange}>
			<input type="checkbox" id={checkId.current} className={classnames(styles.spread_checkbox)}
				checked={spreadState}
			/>
			<label for={checkId.current} className={classnames(styles.spread_header)}>
				<div className={classnames(styles.descr_spread)}>{nameTab}</div>
				<div className={classnames(styles.spread_icon)}></div>									
			</label>
			<div className={classnames(styles.spread_body)}>
			{textContent}
			</div>								
		</div>		
	);
}

