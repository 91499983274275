import React from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from '../../consts';
import { Tabs } from '../shared/Tabs';
import { HeaderWithCount } from '../shared/HeaderWithCount';
import { getAccountsCount } from '../../store/transactions';

export const AccountsHeader = ({ itemsName }) => {
  const count = useSelector(getAccountsCount);

  return (
    <HeaderWithCount title="Счета" itemsName={itemsName} count={count}>
      <Tabs
        tabs={[
          {
            title: 'Клиенты',
            route: ROUTES.FULL_ACCOUNTS_CLIENTS,
            visible: true,
          },          
          {
            title: 'Декларанты',
            route: ROUTES.FULL_ACCOUNTS_DECLARANTS,
            visible: true,
          },
          {
            title: 'Логисты',
            route: ROUTES.FULL_ACCOUNTS_LOGISTS,
            visible: true,
          },          
          {
            title: 'Партнеры',
            route: ROUTES.FULL_ACCOUNTS_PARTNERS,
            visible: true,
          },
        ]}
      />
    </HeaderWithCount>
  );
};