import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CopyButton.module.sass';
import classnames from 'classnames';

import { takeTemplateOffersPack } from '../../../store/makeOffers';


export const  CopyButton = ({id, setTableType}) => {

	const dispatch = useDispatch();

	const handlerClick = (event) => {
		let id, element;
		if (element = event.target.closest("."+classnames(styles.btn_open))){
			if (id = element.dataset.id){				
				dispatch(takeTemplateOffersPack(id));
				setTableType("newofferspack");					
			}			
		}

	}

	return (
		<div className={classnames(styles.btn_open)} data-id={id} onClick={handlerClick}>
			<span className={classnames(styles.text_open)}>Копировать</span>										
		</div>		
	);
}


