import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { ROUTES, FILTERS } from '../../consts';
import { formatMoney } from '../../utils';
import { SHIPPING_OPTIONS } from '../../configs/request';
import { getRequestCreatedListFilters } from '../../store/filters';
import { isProfiledFilled } from '../../store/profile';
import {
  applyListFilters,
  loadRequests,
  getRequests,
  getCount,
  getLoading,
  getUnreadDraftsCount,
  loadDrafts,
} from '../../store/requests';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { STATUS_OPTIONS } from '../../configs/request';

import styles from './Requests.module.scss';

const REQUEST_HEADERS = [
  {
    label: '№ Заявки',
    value: 'number',
    sortable: true,
    render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
  },
  {
    label: 'Дата заявки',
    value: 'createdAt',
    render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    sortable: true,
  },
  {
    label: 'Тип перевозки',
    value: 'shipping',
    render: (value) => SHIPPING_OPTIONS[value]?.label,
  },
  {
    label: 'Предварительная стоимость заявки',
    value: 'price',
    render: formatMoney,
  },
  {
    label: 'Статус',
    value: 'status',
    render: (value) => STATUS_OPTIONS[value]?.label,
    sortable: true,
  },
  {
    label: '',
    value: 'rowActions',
    render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
  },
];

export const InWork = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileFilled = useSelector(isProfiledFilled);
  const loading = useSelector(getLoading);
  const requests = useSelector(getRequests);
  const countItems = useSelector(getCount);
  const filters = useSelector(getRequestCreatedListFilters);
  const filtersKey = FILTERS.REQUESTS_CREATED_LIST;
  const hasEnoughMoney = true; // balance >= ACCOUNT_THRESHOLD;

  useEffect(() => {
    dispatch(loadRequests(filters));
    dispatch(loadDrafts);
  }, [dispatch, filters]);

  return (    
      <>
        {requests?.length > 0 ? (
          <Table
            loading={loading}
            items={requests}
            headers={REQUEST_HEADERS}
            onRowClick={(item) =>
              navigate(`${ROUTES.FULL_REQUESTS_DECLARATIONS}/client/${item._id}`)
            }
            filters={filters}
            countItems={countItems}
            applyFilters={(filters) =>
              dispatch(applyListFilters(filtersKey, filters))
            }
          />
        ) : (
          <Message
            underlined={!profileFilled}
            type={profileFilled ? 'info' : 'exclamation'}
          >
            {true
              ? 'У вас пока нет ни одной заявки'
              : 'Для создания новой заявки необходимо заполнить хотя бы 1 компанию'}
          </Message>
        )}
     </>  
  );
};
