export const getCompanies = (state) => state.companies.items;
export const getCompany = (state) => state.companies.item;
export const getLoading = (state) => state.companies.loading;
export const getCommonInfo = (state) => state.companies.commonInfo;
export const getExtraInfo = (state) => state.companies.extraInfo;
export const getBankInfo = (state) => state.companies.bankInfo;
export const getDocsOpen = (state) => state.companies.documentsOpen;
export const getCeoDocsOpen = (state) => state.companies.ceoDocsOpen;
export const getbankAccounts = (state) => state.companies.item.bankAccounts;
export const getCompanyOffer = (state) => state.companies.item?.offer;



