const TRIPLET_NAMES = [
    null,
    ['тысяча', 'тысячи', 'тысяч'],
    ['миллион', 'миллиона', 'миллионов'],
    ['миллиард', 'миллиарда', 'миллиардов'],
    ['триллион', 'триллиона', 'триллионов'],
    ['квадрилион', 'квадрилиона', 'квадрилионов'],
  ],
  ZERO_NAME = 'нуль',
  ONE_THOUSANT_NAME = 'одна',
  TWO_THOUSANTS_NAME = 'две',
  HUNDRED_NAMES = [
    null,
    'сто',
    'двести',
    'триста',
    'четыреста',
    'пятьсот',
    'шестьсот',
    'семьсот',
    'восемьсот',
    'девятьсот',
  ],
  TEN_NAMES = [
    null,
    null,
    'двадцать',
    'тридцать',
    'сорок',
    'пятьдесят',
    'шестьдесят',
    'семьдесят',
    'восемьдесят',
    'девяносто',
  ],
  UNIT_NAMES = [
    ZERO_NAME,
    'один',
    'два',
    'три',
    'четыре',
    'пять',
    'шесть',
    'семь',
    'восемь',
    'девять',
  ],
  TEN_UNIT_NAMES = [
    'десять',
    'одиннадцать',
    'двенадцать',
    'тринадцать',
    'четырнадцать',
    'пятнадцать',
    'шестнадцать',
    'семнадцать',
    'восемнадцать',
    'девятнадцать',
  ];

// INPUT_VALUE_LIMIT = TRIPLET_NAMES.length * 3;

function pluralEnding(number, variants) {
  let one = variants[0],
    two = variants[1],
    five = variants[2];
  number = Math.abs(number);
  number %= 100;
  if (number >= 5 && number <= 20) {
    return five;
  }
  number %= 10;
  if (number === 1) {
    return one;
  } else if (number >= 2 && number <= 4) {
    return two;
  }
  return five;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function numberInWords(number, capFirst = false) {
  const numberInWords = [];
  let tripletOfZerosMask = 0,
    i,
    pos,
    length,
    tripletNames,
    tripletIndex,
    digitPosition,
    digitValue,
    prevDigitValue;

  if (typeof number !== `string`) {
    number = number + '';
  }

  let additional;
  [number, additional] = number.split('.');

  length = number.length;

  for (i = 0; i < length; i += 1) {
    pos = length - 1 - i;
    tripletIndex = Math.floor(pos / 3);
    digitPosition = pos % 3;
    digitValue = parseInt(number[i]);

    if (digitPosition === 2) {
      tripletOfZerosMask = 0;
      if (digitValue === 0) {
        tripletOfZerosMask |= 0b100;
      } else if (digitValue) {
        numberInWords.push(HUNDRED_NAMES[digitValue]);
      }
      continue;
    }
    if (digitPosition === 1) {
      if (digitValue === 0) {
        tripletOfZerosMask |= 0b10;
      } else if (digitValue === 1) {
        numberInWords.push(TEN_UNIT_NAMES[parseInt(number[i + 1])]);
      } else if (digitValue) {
        numberInWords.push(TEN_NAMES[digitValue]);
      }
      continue;
    }
    if (digitPosition === 0) {
      prevDigitValue = i - 1 >= 0 ? parseInt(number[i - 1]) : null;
      if (digitValue === 0) {
        tripletOfZerosMask |= 0b1;
        if (length === 1) {
          numberInWords.push(ZERO_NAME);
        }
      } else if ((prevDigitValue && prevDigitValue !== 1) || !prevDigitValue) {
        if (tripletIndex === 1 && digitValue === 1) {
          numberInWords.push(ONE_THOUSANT_NAME);
        } else if (tripletIndex === 1 && digitValue === 2) {
          numberInWords.push(TWO_THOUSANTS_NAME);
        } else {
          numberInWords.push(UNIT_NAMES[digitValue]);
        }
      }

      tripletNames = TRIPLET_NAMES[tripletIndex];
      if (tripletNames && tripletOfZerosMask !== 0b111) {
        if (prevDigitValue === 1) {
          numberInWords.push(pluralEnding(10 + digitValue, tripletNames));
        } else {
          numberInWords.push(pluralEnding(digitValue, tripletNames));
        }
      }
      continue;
    }
  }

  if (additional) {
    if (additional.length === 1) {
      additional += '0'
    }

    additional += ' коп.'
    additional = 'рублей ' + additional
  } else {
    additional = 'рублей 00 коп.';
  }
  numberInWords.push(additional);

  let result = numberInWords.join(' ');

  return capFirst ? capitalizeFirstLetter(result) : result;
}
