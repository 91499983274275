import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import Logo from '../../assets/Logo.svg';
import { ROUTES } from '../../consts';
import { logout } from '../../utils/logout';
import { getToken } from '../../store/auth';
import { getProfile, loadProfile } from '../../store/profile';

import { Avatar } from '../shared/Avatar';
import { Button } from '../shared/Button';

import styles from './AppHeader.module.scss';

export const AppHeader = () => {

  const navigate = useNavigate();
  const goToProfile = () => navigate(ROUTES.FULL_PROFILE_PERSONAL);
  const token = useSelector(getToken);
  const profile = useSelector(getProfile);
  const { name, surname } = profile;  

  return (
    <header className={styles.AppHeader}>
      <div className={styles.AppHeader__Content}>
        <img
          src={Logo}
          alt="Мой Декларант логотип"
          onClick={goToProfile}
          className={styles.Logo}
        />
        <div className={styles.UserInfo} onClick={goToProfile}>
          {token && profile.emailVerified && (
            <>
              <div className={styles.UserInfo__Name}>
                {name} {surname}
              </div>
              <div className={styles.UserInfo__Photo}>
                <Avatar size="42px" />
              </div>
              <div className={styles.Logout}>
                <Button onClick={logout} className={styles.Logout__Button}>
                  Выйти
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
};
