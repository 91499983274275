import React from 'react';

import { Button } from '../shared/Button';
import ExclamationBlue from '../../assets/ExclamationBlue.svg';

export const AccordionFooter = ({
  onClick,
  buttonText = 'Сохранить',
  tooltip,
  textBeforeButton = '',
  attention,
  disabled,
}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '0.9rem',
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {attention ? (
        <img
          src={ExclamationBlue}
          alt="ExclamationBlue"
          style={{ marginRight: '1.2rem' }}
        />
      ) : null}
      <span>{textBeforeButton}</span>
    </div>
    <Button onClick={onClick} filled tooltip={tooltip} disabled={disabled}>
      {buttonText}
    </Button>
  </div>
);
