import { API_ROUTES } from '../consts';
import { ApiHelper } from '../utils';

export const getBankData = (payload) =>
  ApiHelper.post(API_ROUTES.BANK_DATA, payload);

export const getCompanyBankAccounts = (id) =>
  ApiHelper.get(`${API_ROUTES.COMPANY_BANK_ACCOUNTS}/${id}`);

export const createBankAccount = (payload) =>
  ApiHelper.post(API_ROUTES.CREATE_BANK_ACCOUNT, payload);

export const deleteBankAccount = (id) =>
  ApiHelper.delete(`${API_ROUTES.DELETE_BANK_ACCOUNT}/${id}`);
