import { API_ROUTES } from '../consts';
import { ApiHelper, generateQuery } from '../utils';



export function fetchCurrentPayments(){ return ApiHelper.get(API_ROUTES.PAYMENTS); }

/*export const fetchCurrentPayments = () => ApiHelper.get(API_ROUTES.PAYMENTS);*/

export function fetchCompletedPayments(filters){
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.PAYMENTS_COMPLETED}${queryString}`);
}

/*export const fetchCompletedPayments = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.PAYMENTS_COMPLETED}${queryString}`);
}*/

export function getPayment(id){ return ApiHelper.get(`${API_ROUTES.PAYMENTS}/${id}`);}

export function getUserPayments(filters){
  const queryString = generateQuery(filters);  
  return ApiHelper.get(`${API_ROUTES.PAYMENTS_USER_HISTORY}${queryString}`);
}

/*export const getPayment = (id) => ApiHelper.get(`${API_ROUTES.PAYMENTS}/${id}`);*/

/*export const getUserPayments = (filters) => {
  const queryString = generateQuery(filters);
  
  return ApiHelper.get(`${API_ROUTES.PAYMENTS_USER_HISTORY}${queryString}`);
}*/

export function createPayment(payload){
	return ApiHelper.post(API_ROUTES.PAYMENTS, payload);
}
 
export function updatePayment(id, payload){
  return ApiHelper.patch(`${API_ROUTES.PAYMENTS}/${id}`, payload); 
}

/*export const createPayment = (payload) =>
  ApiHelper.post(API_ROUTES.PAYMENTS, payload);*/

/*export const updatePayment = (id, payload) =>
  ApiHelper.patch(`${API_ROUTES.PAYMENTS}/${id}`, payload);*/
