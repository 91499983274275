import React from 'react';

import InfoCircle from '../../../assets/InfoCircle.svg';

import styles from './Reminder.module.scss';

export const Reminder = () => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.CWrapper}>
        <div className={styles.Content}>
          <div>
            Перед подачей заявки не забудьте пополнить ЕЛС счет
            <br />
            для списания таможенных платежей
          </div>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://edata.customs.ru/FtsPersonalCabinetWeb2017/#?view=Home"
          >
            Перейти
          </a>
        </div>
        <img src={InfoCircle} alt="info" width="30" />
      </div>
      <div className={styles.CWrapper}>
        <div className={styles.Content}>
          <div>
            Не забудьте проверить подлинность Сертификата/Декларации
            <br />
            Соответствия в электронных реестрах
          </div>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://fsa.gov.ru/use-of-technology/elektronnye-reestry/"
          >
            Проверить
          </a>
        </div>
      </div>
    </div>
  );
};
