export default {
	API_URL: 'https://system.mydeclarant.ru/api/1.0',
	HOST: 3001,
};

/*export default {
	API_URL: 'http://localhost:3001/api/1.0',
	HOST: 3001,
};*/

/*'https://www.declarantus.ru/api/1.0'
export default {
  API_URL: process.env.REACT_APP_API_URL,
  HOST: process.env.REACT_APP_HOST,
};*/
 
