import React from 'react';

import { AccountsTable } from './AccountsTable';
import { getAccountsDeclarantsFilters } from '../../store/filters';
import { FILTERS } from '../../consts';

export const AccountsDeclarants = () => (
  <AccountsTable
    getFilters={getAccountsDeclarantsFilters}
    filtersKey={FILTERS.ACCOUNTS_DECLARANTS}
    messageSubject="декларантов"
  />
);
