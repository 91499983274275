import jwtDecode from 'jwt-decode';
import { LocalStorageHelper } from '../utils/localStorage';

import { ROUTES, TOKEN } from '../consts';
import { UpdatePasswordModal } from '../components/Personal/UpdatePasswordModal';
import { UpdateEmailModal } from '../components/Personal/UpdateEmailModal';
import { UpdatePhoneModal } from '../components/Personal/UpdatePhoneModal';

const token = LocalStorageHelper.getItem(TOKEN);
const { name, surname } = token ? jwtDecode(token) : { name: '', surname: '' };

export const PERSONAL_OPTIONS = {
  name: {
    label: 'Имя',
    initialValue: name,
    type: 'text',
    editable: true,
  },
  surname: {
    label: 'Фамилия',
    initialValue: surname,
    type: 'text',
    editable: true,
  },
  email: {
    label: 'E-mail',
    type: 'email',
    settingsUrl: ROUTES.FULL_NOTIFICATIONS_EMAIL,
    modal: UpdateEmailModal,
    permitEdit: false
  },
  password: {
    label: 'Пароль',
    initialValue: '',
    type: 'password',
    valueToShow: '********',
    modal: UpdatePasswordModal,
  },
};

