import React from 'react';
import { Navigate, Routes, Route, Link } from 'react-router-dom';
import { ROUTES, ROLES } from '../../../consts';
import styles from './RequestsChoice.module.scss';
import { CheckRole } from '../../hocs/Checker';
import { withLoading } from '../../hocs/withLoading';
import { Title } from '../../shared/Title';
import { GoBackLink } from '../../shared/GoBackLink';
import Declaration from '../../../assets/Declaration.svg';
import Logistics from '../../../assets/Logistics.svg';

export const RequestsChoice = withLoading(() => {

  return (
  	<>
		<Title>Выбор типа заявок</Title>
		<div className={styles.container_choice_item}>
			<Link to="/requests/declarations" className={styles.choise_item}>
				<div className={styles.wrap_icon_choice}>
					<div className={styles.descr_icon_choice}>Декларации</div>
					<img src={Declaration} className={styles.icon_choice} />
				</div>
			</Link>
			<Link to="/requests/logistics" className={styles.choise_item}>			
				<div className={styles.wrap_icon_choice}>
					<div className={styles.descr_icon_choice}>Логистика</div>
					<img src={Logistics} className={styles.icon_choice} />
				</div>					
			</Link>
		</div>
  	</>

  	)
})






