import {
  SET_EMAIL_FORM,
  UPDATE_PHONE_FORM,
  SET_PHONE_FORM,
} from './actions';

const initialState = {
  emailForm: {
    system: false,
    requestUpdates: false,
    infoNews: false,
    commercial: false,
  },
  phoneForm: {
    requestUpdates: false,
    infoNews: false,
    commercial: false,
  },
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMAIL_FORM:
      return {
        ...state,
        emailForm: action.payload,
      };
    case SET_PHONE_FORM:
      return {
        ...state,
        phoneForm: action.payload,
      };

    case UPDATE_PHONE_FORM:
      return {
        ...state,
        phoneForm: {
          ...state.phoneForm,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
