import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import {
  getSubProfiles,
  loadSubProfile,
  updateProfile,
  updateValueRequest,
  deleteProfile,
  getProfile,
} from '../../store/profile';
import { Table } from '../shared/Table';
import { Button } from '../shared/Button';
import { Checkbox } from '../shared/Checkbox';
import { ConfirmModal } from '../shared/ConfirmModal';
import { AddAccountModal } from './AddAccountModal';
import ExclamationBlue from '../../assets/ExclamationBlue.svg';

import styles from './SubAccounts.module.scss';

const DeleteModal = ({ value }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  return (
    <>
      <h2 style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
        &#10006;
      </h2>
      <ConfirmModal
        open={open}
        onConfirm={() => {
          dispatch(deleteProfile(value._id));
          setOpen(false);
        }}
        onReject={() => setOpen(false)}
        message={`Вы уверены, что хотите удалить доступы для пользователя ${value.name} ${value.surname} / ${value.email}?`}
      />
    </>
  );
};

const CATEGORIES = {
  ACCOUNT: {
    id: 'account',
    label: 'Мой счет',
  },
  COMPANY: {
    id: 'company',
    label: 'Компания',
  },
  REQUESTS: {
    id: 'requests',
    label: 'Заявки',
  },
};

export const SubAccounts = () => {
  const dispatch = useDispatch();
  const subProfiles = useSelector(getSubProfiles);
  const profile = useSelector(getProfile);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (profile._id) {
      dispatch(loadSubProfile);
    }
  }, [dispatch, profile._id]);

  const TABLE_HEADERS = [
    {
      label: 'Имя пользователя',
      renderItem: true,
      value: 'name',
      render: (value) => (
        <>
          <h3>
            {value.name} {value.surname}
          </h3>
          <span>{value.email}</span>
        </>
      ),
    },
    {
      label: 'Доступ к разделам',
      value: 'sections',
      renderItem: true,
      render: ({ _id, sections }) => (
        <div className={styles.Categories}>
          {Object.keys(CATEGORIES).map((category) => {
            const { id, label } = CATEGORIES[category];
            return (
              <div key={id} className={styles.Categories__Item}>
                <Checkbox
                  checked={sections.includes(id)}
                  onClick={(e) => {
                    let nextSections;
                    const nextVal = e.target.checked;
                    const newSubProfiles = subProfiles.map((profile) => {
                      if (profile._id === _id) {
                        nextSections = nextVal
                          ? [...profile.sections, id]
                          : profile.sections.filter(
                              (section) => section !== id
                            );

                        return { ...profile, sections: nextSections };
                      }

                      return profile;
                    });

                    dispatch(
                      updateValueRequest(
                        {
                          key: 'sections',
                          value: nextSections,
                        },
                        _id
                      )
                    );

                    dispatch(
                      updateProfile({
                        subProfiles: newSubProfiles,
                      })
                    );
                  }}
                >
                  {label}
                </Checkbox>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      label: 'Дата добавления доступа',
      value: 'createdAt',
      render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    },
    {
      label: '',
      value: 'delete',
      renderItem: true,
      render: (value) => <DeleteModal value={value} />,
    },
  ];

  return (
    <main>
      <div className={styles.MessageWrapper}>
        <img
          src={ExclamationBlue}
          alt="ExclamationBlue"
          className={styles.MessageWrapper__Img}
        />
        <div className={styles.MessageWrapper__Text}>
          Предоставьте доступ своим менеджерам и работайте в команде!
        </div>
      </div>
      {subProfiles.length > 0 && (
        <Table
          headers={TABLE_HEADERS}
          headerCellLargeFont
          items={subProfiles}
          countItems={0}
          filters={{}}
          applyFilters={() => {}}
        />
      )}
      <Button filled onClick={() => setOpen(true)}>
        <div className={styles.AddButtonWrapper}>
          <div className={styles.AddButtonWrapper__LargePlus}>+</div>
          <div className={styles.AddButtonWrapper__Name}>
            Добавить пользователя
          </div>
        </div>
      </Button>
      <AddAccountModal
        open={open}
        onClose={() => {
          dispatch(
            updateProfile({
              defaultPassword: null,
            })
          );
          setOpen(false);
        }}
      />
    </main>
  );
};
