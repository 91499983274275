import { format } from 'date-fns';

export const spreadFieldTable = (arr, field) => {

	if (typeof arr === "object" && arr.length){
		return arr.map((item)=>{

			let str, arr;

			if (field === "container"){
				return {twenty: "20'", forty: "40'", fortyhc: "40HC"}[item[field]];
			} else if (field === "typetransport"){
				return ["ЖД", "Море"][item[field]];
			} else if (field === "pointArrival") {
				return (item.typetransport)? `${item.terminalArrival} ${item.cityArrival}`:item.cityArrival;
			} else if (field == "expireDate"){
				return format(new Date(item[field]), 'dd.MM.yyyy');
			} else if (typeof item[field] == "number" || typeof item[field] == "string"){

				str = item[field].toString().slice(0, 20);

				if (str.length > 17){

					arr = str.split('');
					arr.splice(18, arr.length-17, "...");

					return arr.join('');
				}

				return str;
			}

			return "Нет данных";
		});
	}
	return [];
}

