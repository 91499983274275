import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import {format} from "date-fns";
import {getFio} from "./getFio";
import {getGenitiveName} from "./getGenitiveName";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
//'https://declarant.s3.eu-north-1.amazonaws.com/src/MyDeclarantTemplate.docx'

// {
//   companyNumber: 121,
//   companyName: 'ООО ТехноНИКОЛЬ',
//   registrationDate: '08.03.2021',
//   ceoName: 'Рыбаков Рыбак Рыбакович',
//   companyAddress: 'г. Москва, ул. Звездная 4, 14, офис возле кофе машинки',
//   companyItn: '123193123',
//   companyOgrn: 'orwerwer',
//   bankInfo: 'Tink Off Jazz',
//   companyPhone: '+12391238123',
//   companyEmail: 'tn@gmail.com',
// }

export const createCompanyAgreement = (company) => {
  createDocx({
    data: {
      companyNumber: company.number,
      companyName: company.name,
      companyForm: company.form,
      companyShortName: company.shortName,
      registrationDate: format(
        new Date(company.createdAt),
        'dd.MM.yyyy'
      ),
      ceoName: company.ceo,
      ceoFio: getFio(company.ceo),
      ceoNameGenitive: getGenitiveName(company.ceo),
      companyAddress: company.legalAddress.fullAddress,
      companyItn: company.itn,
      companyOgrn: company.ogrn,
      bankBik: company.bank.bik,
      bankAccountNumber: company.bank.accountNumber,
      bankCorrespondenceAccount: company.bank.correspondenceAccount,
      companyPhone: company.phone,
      companyEmail: company.email,
      ipAddress: company.profile?.ip,
    },
    saveAsName: `МойДекларант_Договор_${company.number}.docx`,
    templateUrl:
      'https://system.mydeclarant.ru/templates/MyDeclarantTempl.docx',
  });
}

export const createDocx = ({
  data,
  saveAsName = 'output.docx',
  templateUrl,
}) => {
  loadFile(templateUrl, function (error, content) {
    if (error) {
      throw error;
    }
    var zip = new PizZip(content);
    var doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });
    doc.setData(data);
    try {
      doc.render();
    } catch (error) {
      function replaceErrors(key, value) {
        if (value instanceof Error) {
          return Object.getOwnPropertyNames(value).reduce(function (
            error,
            key
          ) {
            error[key] = value[key];
            return error;
          },
          {});
        }
        return value;
      }
      console.log(JSON.stringify({ error: error }, replaceErrors));

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function (error) {
            return error.properties.explanation;
          })
          .join('\n');
        console.log('errorMessages', errorMessages);
        // errorMessages is a humanly readable message looking like this :
        // 'The tag beginning with "foobar" is unopened'
      }
      throw error;
    }
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    }); //Output the document using Data-URI

    saveAs(out, saveAsName);
  });
};
