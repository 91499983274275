import { getManagerLogisticsOffersFromPack } from '../../api';

export const START_FETCHING = '@logisticsOffersFromPack/START_FETCHING';
export const LOAD_MY_OFFERS_FROM_PACK_SUCCESS = '@logisticsOffersFromPack/LOAD_MY_OFFERS_FROM_PACK_SUCCESS';
export const LOAD_MY_OFFERS_FROM_PACK_FAILURE = '@logisticsOffersFromPack/LOAD_MY_OFFERS_FROM_PACK_FAILURE';
export const RESET_MY_OFFERS_FROM_PACK = '@logisticsOffersFromPack/RESET_MY_OFFERS_FROM_PACK';

const startFetching = () => ({
  type: START_FETCHING    
});
const loadMyOffersFromPackSuccess = (payload) => ({
  type: LOAD_MY_OFFERS_FROM_PACK_SUCCESS,
  payload    
});
const loadMyOffersFromPackFailure = (payload) => ({
  type: LOAD_MY_OFFERS_FROM_PACK_FAILURE,
  payload    
});

export const resetMyOffersFromPack = {
  type: RESET_MY_OFFERS_FROM_PACK    
};


export const loadMyOffersFromPack = (id, filters) => async (dispatch, getState) => {    
  dispatch(startFetching());
  try {
      const offersFromPack = await getManagerLogisticsOffersFromPack({...filters, id: id});
      dispatch(loadMyOffersFromPackSuccess(offersFromPack));
      console.log(offersFromPack);
  }catch(error){
      loadMyOffersFromPackFailure(error?.data);
  }
}


