import React, { useState } from 'react';

import { updatePayment } from '../../api';
import { Button } from '../shared/Button';
import { Modal } from '../shared/Modal';
import { Title } from '../shared/Title';

export const RejectModal = ({ id, open, onClose, setPayment }) => {
  const [comment, setComment] = useState();
  const handleClick = async () => {
    const { payment } = await updatePayment(id, {
      status: 'rejected',
      comment,
    });

    if (payment) {
      setPayment((prevPayment) => ({ ...prevPayment, status: 'rejected' }));
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ textAlign: 'center' }}>
        <Title>Укажите причину отказа</Title>
        <textarea
          style={{
            border: '1px solid #D9D9D9',
            borderRadius: '5px',
            height: '100px',
            width: '350px',
            margin: '1rem 1rem 2rem',
          }}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <Button disabled={!comment} onClick={handleClick} filled>
          Отклонить
        </Button>
      </div>
    </Modal>
  );
};
