import React from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import classnames from 'classnames';

import styles from './SubTabs.module.scss';

const MatchRoute = ({ path, title }) => {
  const match = useMatch({
    path,
    exact: true
  });

  return (
    <>
      <div
        className={classnames(styles.SubTab__Title, {
          [styles['SubTab--active']]: match,
        })}
      >
        {title}
      </div>
    </>
  );
};

export const SubTabs = ({ subTabs }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.SubTabs}>
      {subTabs.map(({ route, useMatchRoute, href, title }) => (
        <div
          key={route}
          className={styles.SubTab}
          onClick={() => navigate(href || route)}
        >
          <MatchRoute path={useMatchRoute?useMatchRoute:route} title={title} />
        </div>
      ))}
    </div>
  );
};
