import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getRequest } from '../../../store/requests';
import { Accordion } from '../../shared/Accordion';
import { Input } from '../../shared/Input';
import { RequestDetailInfo } from './RequestDetailInfo';
import { DocumentsPreview } from './DocumentsPreview';

import styles from './DetailInfo.module.scss';

export const DetailInfo = () => {
  const [open, setOpen] = useState(false);
  const item = useSelector(getRequest);
  const { company } = item;

  return (
    <div className={styles.DetailInfo}>
      {company && (
        <Accordion
          open={open}
          fadedToggler
          onToggle={() => setOpen(!open)}
          title="Данные о компании"
        >
          <div className={styles.Title}>{company.name}</div>
          <div className={styles.CompanyData}>
            <div>ИНН {company.itn}</div>
            <div>ОГРН {company.ogrn}</div>
            <div>КПП {company.kpp}</div>
          </div>
          <div style={{ marginTop: '3rem' }}>
            <div className={styles.Label}>Полный адрес компании</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Полный адрес компании"
                value={company.legalAddress?.fullAddress}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Страна</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Страна"
                value={company.legalAddress?.country}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Город</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Город"
                value={company.legalAddress?.city}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Индекс</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Индекс"
                value={company.legalAddress?.postalCode}
                lightgrey
              />
            </div>
          </div>
          <DocumentsPreview company={company} disabled viewOnly />
        </Accordion>
      )}
      <RequestDetailInfo />
    </div>
  );
};
