import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { updatePassword } from '../../api';
import { getProfileId } from '../../store/profile';
import GreenCheckmark from '../../assets/GreenCheckmark.svg';
import { Input } from '../shared/Input';
import { Modal } from '../shared/Modal';
import { Button } from '../shared/Button';

import styles from './UpdatePasswordModal.module.scss';

export const UpdatePasswordModal = (props) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errors, setErrors] = useState({
    oldPassword: '',
    repeatPassword: '',
  });
  const [success, setSuccess] = useState(false);
  const profileId = useSelector(getProfileId);

  const validate = () => {
    if (newPassword !== repeatPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        repeatPassword: 'Пароль не совпадает',
      }));

      return false;
    }

    return true;
  };

  const notFinished = [oldPassword, newPassword, repeatPassword].some(
    (input) => !input
  );

  const handleSubmit = async () => {
    if (!validate()) return;

    try {
      await updatePassword({ oldPassword, newPassword }, profileId);
      setSuccess(true);
    } catch (e) {
      if (e.data) {
        setErrors(e.data);
      }
      setErrors({ oldPassword: e?.message || 'Ошибка отправки данных' });
    }
  };

  const successMessage = (
    <>
      <div className={styles.Title}>Пароль успешно изменен</div>
      <div className={styles.Checkmark}>
        <img src={GreenCheckmark} alt="checkmark" />
      </div>
    </>
  );

  const form = (
    <>
      <div className={styles.Title}>Изменить пароль</div>
      <div className={styles.InputRow}>
        <div className={styles.InputRow__Placeholder}>
          Введите старый пароль
        </div>
        <Input
          type="password"
          value={oldPassword}
          onChange={(e) => {
            if (errors.oldPassword) {
              setErrors((prevErrors) => ({ ...prevErrors, oldPassword: '' }));
            }
            setOldPassword(e.target.value);
          }}
        />
        {errors.oldPassword && (
          <div className={styles.InputRow__Error}>{errors.oldPassword}</div>
        )}
      </div>
      <div className={styles.InputRow}>
        <div className={styles.InputRow__Placeholder}>Введите новый пароль</div>
        <Input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <div className={styles.InputRow}>
        <div className={styles.InputRow__Placeholder}>
          Подтвердите новый пароль
        </div>
        <Input
          type="password"
          value={repeatPassword}
          onChange={(e) => {
            if (errors.repeatPassword) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                repeatPassword: '',
              }));
            }
            setRepeatPassword(e.target.value);
          }}
        />
        {errors.repeatPassword && (
          <div className={styles.InputRow__Error}>{errors.repeatPassword}</div>
        )}
      </div>
      <div className={styles.ButtonWrapper}>
        <Button filled disabled={notFinished} onClick={handleSubmit}>
          Принять
        </Button>
      </div>
    </>
  );

  return <Modal {...props}>{success ? successMessage : form}</Modal>;
};
