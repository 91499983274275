import React from 'react';

import { Select } from '../Select';
import { Input } from '../Input';

import styles from './SurveyLine.module.scss';

export const SurveyLine = ({
  creatable,
  onDelete,
  options,
  pair,
  setPairs,
  selectPlaceholder,
  textPlaceholder,
}) => {
  const updatePair = ({ key, value }) =>
    setPairs((prevPairs) =>
      prevPairs.map((_pair) => {
        if (_pair.key === pair.key) {
          return { ..._pair, [key]: value };
        }

        return _pair;
      })
    );

  return (
    <div className={styles.SetDocument}>
      <div className={styles.SetDocument__Select}>
        <Select
          creatable={creatable}
          options={Object.values(options)}
          value={options[pair.option]}
          placeholder={selectPlaceholder}
          onChange={({ value }) => updatePair({ key: 'option', value })}
        />
      </div>

      <div className={styles.SetDocument__Input}>
        <Input
          placeholder={textPlaceholder}
          value={pair.text}
          onChange={(e) => {
            const { value } = e.target;
            updatePair({ key: 'text', value });
          }}
          tooltip={pair.error}
        />
      </div>
      <div className={styles.SetDocument__Delete} onClick={onDelete}>
        Удалить
      </div>
    </div>
  );
};
