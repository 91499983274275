import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import {
  loadUsers,
  getUsersCount,
  getLoading,
  getUsers,
  applyFilters,
} from '../../store/users';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { getLogistsAllUsersFilters } from '../../store/filters';
import { FILTERS, ROUTES } from '../../consts';

export const LogistsAllUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const users = useSelector(getUsers);
  const filters = useSelector(getLogistsAllUsersFilters);
  const countItems = useSelector(getUsersCount);
  const filtersKey = FILTERS.LOGISTS_ALL_USERS;
  
  const LOGISTS_ALL_USERS_HEADERS = [
    {
      label: 'ID',
      value: '_id',
      sortable: true,
      render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
    },
    {
      label: 'Имя Фамилия',
      value: 'name',
      renderItem: true,
      render: (item) =>
        item.name === undefined && item.surname === undefined
          ? ''
          : `${item.name} ${item.surname}`,
    },
    {
      label: 'Аккредитация',
      value: 'accreditation',
      renderItem: true,
      render: (item) =>
        item.verified ? <div>Пройдена</div> : <div>Не пройдена</div>,
    },
    {
      label: 'Контакты',
      value: 'contacts',
      renderItem: true,
      render: (item) => (
        <div>
          <div>{item.phone}</div>
          <div>{item.email}</div>
        </div>
      ),
    },
    {
      label: 'Дата регистрации',
      value: 'createdAt',
      sortable: true,
      render: (value) =>
        value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : 'нет данных',
    },
    {
      label: '',
      value: 'rowActions',
      render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
    },
  ];

  useEffect(() => {
    dispatch(loadUsers(filters));
  }, [dispatch, filters]);

  return (
    <div>
      {users?.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`../${ROUTES.USERS_LOGISTS}/${_id}`);
          }}
          loading={loading}
          items={users}
          headers={LOGISTS_ALL_USERS_HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) =>
            dispatch(applyFilters(filtersKey, filters))
          }
        />
      ) : (
        <Message type="info">
          В системе пока нет сведений о пользователях
        </Message>
      )}
    </div>
  );
};
