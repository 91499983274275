import React, { useState } from 'react';
import styles from './ModalConfirms.module.sass';
import classnames from 'classnames';

export const ModalConfirms = ({modalState, setModalState, descr, dispatchCb}) => {

	const handlerClick = (event) => {		

		if (event.target.classList.contains(classnames(styles.overlay)) ||
			event.target.closest("."+classnames(styles.moduleCustoms_btn_cancel))){
				setModalState({open: false, id: null});
		} else if(event.target.closest("."+classnames(styles.moduleCustoms_btn_confirm))){

			dispatchCb();
			setModalState({open: false, id: null});
		}		
	}	

	return (
		<>
			<div className={classnames(styles.overlay,{[styles['open']]:modalState.open})} onClick={handlerClick}>
				<div className={classnames(styles.moduleCustoms)}>
					<div className={classnames(styles.moduleCustoms_body)}>					
						<div className={classnames(styles.moduleCustoms_main_message)}>{descr.message}</div>	
						<div className={classnames(styles.moduleCustoms_descr_message)}>{descr.descrMessage}</div>
						<div className={classnames(styles.moduleCustoms_wrap_btn_choice)}>
							<div className={classnames(styles.moduleCustoms_btn_confirm, styles.active)}>{descr.resolve}</div>	
							<div className={classnames(styles.moduleCustoms_btn_cancel)}>{descr.reject}</div>
						</div>									
					</div>
				</div>
			</div>		
		</>
	);
}