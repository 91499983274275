export const getDepartureCountryList = (state) => state.selectionOffers.departureCountryList;
export const getDepartureCountry = (state) => state.selectionOffers.departureCountry;
export const getDeparturePointList = (state) => state.selectionOffers.departurePointList;
export const getPointDeparture = (state) => state.selectionOffers.departurePoint;
export const getTypeStation = (state) => state.selectionOffers.typeStation;
export const getDeparturePoints = (state) => state.selectionOffers.departurePoints;
export const getTypeContainer = (state) => state.selectionOffers.typeContainer;
export const getArrivalCountryList = (state) => state.selectionOffers.arrivalCountryList;
export const getArrivalCountry = (state) => state.selectionOffers.arrivalCountry;
export const getArrivalCityTerminalList = (state) => state.selectionOffers.arrivalCityTerminalList;
export const getArrivalCityTerminal = (state) => state.selectionOffers.arrivalCityTerminal;
export const getArrivalPointList = (state) => state.selectionOffers.arrivalPointList;
export const getArrivalPoint = (state) => state.selectionOffers.arrivalPoint;
export const getCustomsList = (state) => state.selectionOffers.customsList;
export const getCustoms = (state) => state.selectionOffers.customs;
export const getStateValidForm = (state) => state.selectionOffers.validForm;
export const getStateForm = (state) => state.selectionOffers;
export const getLoading = (state) => state.selectionOffers.loading;
export const getLoadup = (state) => state.selectionOffers.loadup;
export const getOffers = (state) => state.selectionOffers.offers;
export const getLoadingMessage = (state) => state.selectionOffers.loadingMessage;
export const getSearchObj = (state) => state.selectionOffers.searchObj;
export const getCount = (state) => state.selectionOffers.count;
export const getTotal = (state) => state.selectionOffers.total;

























