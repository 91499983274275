import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import pDebounce from 'p-debounce';

import { getSuggestions } from '../../api';
import { addCompany } from '../../store/companies';
import { Modal } from '../shared/Modal';
import { Title } from '../shared/Title';
import { Input } from '../shared/Input';
import { Button } from '../shared/Button';
import { Select } from '../shared/Select';
import toast from 'react-hot-toast';

import styles from './AddCompanyModal.module.scss';

const debouncedGetSuggestions = pDebounce(getSuggestions, 1500);

export const AddCompanyModal = (props) => {
  const dispatch = useDispatch();
  const [suggestionId, setSuggestionId] = useState(null);
  const [suggs, setSuggs] = useState([]);
  const [value, setValue] = useState('');

  const handleSave = () => {
    setTimeout(props.onClose, 1000);
    dispatch(addCompany(toast, { itn: value, suggestionId }));
  };

  useEffect(() => {
    const getSuggs = async () => {
      const { suggestions } = await debouncedGetSuggestions({ itn: value });
      if (suggestions?.length > 1) {
        setSuggs(
          suggestions.map((sug, idx) => ({ value: idx, label: sug.value }))
        );
      } else {
        setSuggs([]);
      }
    };

    if (value && value.length === 10) {
      getSuggs();
    }
  }, [value]);

  return (
    <div>
      <Modal {...props}>
        <div className={styles.Wrapper}>
          <Title as="h2">Добавление новой компании</Title>
          <Input
            placeholder="Введите ИНН"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          {suggs.length > 0 && (
            <div style={{ width: '100%' }}>
              <Select
                options={suggs}
                value={suggs.find((sug) => sug.value === suggestionId)}
                onChange={({ value }) => setSuggestionId(value)}
                placeholder="Уточните компанию"
              />
            </div>
          )}
          <Button filled onClick={handleSave}>
            Добавить
          </Button>
        </div>
      </Modal>
    </div>
  );
};
