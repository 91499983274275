import { 
  START_FETCHING,
  LOAD_MY_OFFERS_FROM_PACK_SUCCESS,
  LOAD_MY_OFFERS_FROM_PACK_FAILURE,
  RESET_MY_OFFERS_FROM_PACK } from './actions';

const initialState = {
  myoffersfrompack: {
    offers: [],
    count: null
  },
  loading: false
};

export const logisticsOffersFromPackReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCHING:
      return {
        ...state,
        loading: true,
      };     
    case LOAD_MY_OFFERS_FROM_PACK_SUCCESS:      
      return {
        ...state,
        ...{myoffersfrompack: {
            offers: action.payload.offers,
            count: action.payload.total
          }},      
        loading: false,
      };
    case RESET_MY_OFFERS_FROM_PACK:      
      return {
        ...state,
        ...{myoffersfrompack: {
            offers: [],
            count: null
          }},      
        loading: false,
      };      
    case LOAD_MY_OFFERS_FROM_PACK_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };                
    default:
      return state;      
  }	
}