import React, { useState, useEffect, useRef } from 'react';
import styles from './SelectionSwitcher.module.sass';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

export const SelectionSwitcher = ({allowedTypesFunc, listTypes, selectedType, setStateCb}) => {


	const name = useRef(uuid());
	const handlerChange = (event) => {

		let element, value;

		if (element = event.target.closest("."+classnames(styles.radio_terms))){
			if (setStateCb && !element.nextElementSibling.classList.contains(classnames(styles.nothover))){
				setStateCb(element.value);				
			}	
		}
	}

	return(
		<>
			{(typeof listTypes === "object" && listTypes.length > 0) && (<div className={classnames(styles.popup_base_terms)} style={{width: "100%"}} onChange={handlerChange}>
				{listTypes.map(({title, type})=>{
					const id = uuid();					
					return (
						<>
							<input type="radio" id={id} className={classnames(styles.radio_terms)}
								name={name.current}
								value={type}
								checked={(selectedType === type)? true: false}
							/>
							<label htmlFor={id} className={classnames(styles.terms_btn,
								{[styles["nothover"]]:(allowedTypesFunc)?allowedTypesFunc(type):false})}
								style={{width: (100/listTypes.length).toFixed(2)+"%"}}
							>{title}</label>
						</>
					)
				})}								
			</div>)}

		</>
	);



}