import jwtDecode from 'jwt-decode';

import {
  LOAD_PROFILE_SUCCESS,
  LOAD_SUB_PROFILE_SUCCESS,
  UPDATE_VALUE,
  UPDATE_PROFILE,
  LOAD_PROFILE_REQUEST,
  UPDATE_EMAIL_FORM
} from './actions';
import { LocalStorageHelper } from '../../utils/localStorage';
import { TOKEN } from '../../consts';
import { PERSONAL_OPTIONS } from '../../configs/personalOptions';

const token = LocalStorageHelper.getItem(TOKEN);
const { id } = token ? jwtDecode(token) : { id: '' };

const initialState = {
  role: '',
  id,
  loading: false,
  subProfiles: [],
  defaultPassword: null,
};

Object.keys(PERSONAL_OPTIONS).forEach((option) => {
  initialState[option] = PERSONAL_OPTIONS[option].initialValue;
});

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROFILE_REQUEST:
      return { ...state, loading: action.payload };
    case LOAD_PROFILE_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case LOAD_SUB_PROFILE_SUCCESS:
      return { ...state, loading: false, subProfiles: action.payload };
    case UPDATE_PROFILE:
      return { ...state, ...action.payload };
    case UPDATE_VALUE:
      return { ...state, [action.payload.key]: action.payload.value };
    case UPDATE_EMAIL_FORM:
      return {
        ...state,
        emailNotifications: {
          ...state.emailNotifications,
          [action.payload.key]: action.payload.value,
        },
      };      
    default:
      return state;
  }
};
