import {
  LOAD_ITEMS_REQUEST,
  LOAD_ITEMS_SUCCESS,
  UPDATE_VALUE,
  UPDATE_ITEM,
} from './actions';

const INITIAL_ACCOUNT = {
  bik: '',
  bankName: '',
  accountNumber: '',
  correspondenceAccount: '',
  loading: false,
};

const initialState = {
  items: [],
  count: null,
  newAccount: INITIAL_ACCOUNT,
  loading: false,
};

export const personalAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ITEMS_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
        items: action.payload.personalAccounts,
        loading: false,
      };
    case UPDATE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      case UPDATE_ITEM:
        return {
          ...state,
          [action.payload.key]: action.payload.value,
        };

    default:
      return state;
  }
};
