import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SenderEmailModal.module.sass';
import classnames from 'classnames';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';
import { Input } from './OffersMaker'
//import { sendOfferByEmail } from '../store/selectionOffers';
import { sendClientOfferByEmail } from '../../../api';
export const SenderEmailModal = ({setModalState, modalState}) => {

	const initialInput = {value:false , correct:true};
	const [input, setInput] = useState(initialInput);
	const dispatch = useDispatch();

	const sendOfferByEmail = async (id, email, toast) => {
		try{
			await sendClientOfferByEmail({id, email});
			toast.success("Предложение отправлено");
		}catch(error){
			toast.error("Произошла ошибка, попробуйте позже");
		}
	}

	const handlerClick = (event) => {

		let element;				

		if (event.target.classList.contains(classnames(styles.overlay)) ||
			event.target.className == classnames(styles.moduleCustoms_exit)){
				setModalState({id:false, open: false});
				setInput(initialInput);		
		}		

		if (element = event.target.closest("."+classnames(styles.fill_assignment_btn))){
			if (element.classList.contains(classnames(styles.active))){	
				sendOfferByEmail(modalState.id, input.value, toast);
				setModalState({id:false, open: false});
				setInput(initialInput);	
			}
		}
	}


	return(
		<div className={classnames(styles.overlay,{[styles['open']]:modalState.open})} onClick={handlerClick}>
			<div className={classnames(styles.moduleCustoms)}>
				<div className={classnames(styles.moduleCustoms_header)}>
					<div className={classnames(styles.moduleCustoms_descr)}>Отправка предложения на емаил</div>
					<div className={classnames(styles.moduleCustoms_exit)}></div>
				</div>
				<div className={classnames(styles.moduleCustoms_body)}>					
					<Input
						placeHolder="Введите емаил"
						rule="email"
						width="100%"
						inputValue={input}
						setStateCb={setInput}
					/>
					<div className={classnames(styles.fill_assignment_btn,{
						[styles["active"]]:Object.values(input).every(item=>item)
					})}>Готово</div>																
				</div>
			</div>
		</div>
	);

}