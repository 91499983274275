import history from '../../components/App/history';
import {
  debouncedRequest,
  fetchProfile,
  fetchSubProfiles,
  createSubProfile,
  deleteProfile as deleteProfileApi,
  updateProfile as updateProfileRequest,
} from '../../api';
import { updatePassport } from '../passport';
import { updateEducation } from '../education';
import {
  setEmailNotifications,
  setPhoneNotifications,
} from '../notifications';
import { loadTariffs } from '../tariffs';
import { updateWork } from '../work';
import { getProfileId, getSubProfiles } from './selectors';
import { DOCUMENTS_STEPS, ROUTES } from '../../consts';

export const UPDATE_VALUE = '@profile/UPDATE_VALUE';
export const UPDATE_PROFILE = '@profile/UPDATE_PROFILE';
export const LOAD_PROFILE_REQUEST = '@profile/LOAD_PROFILE_REQUEST';
export const LOAD_PROFILE_SUCCESS = '@profile/LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_FAILURE = '@profile/LOAD_PROFILE_FAILURE';
export const LOAD_SUB_PROFILE_REQUEST = '@profile/LOAD_SUB_PROFILE_REQUEST';
export const LOAD_SUB_PROFILE_SUCCESS = '@profile/LOAD_SUB_PROFILE_SUCCESS';
export const LOAD_SUB_PROFILE_FAILURE = '@profile/LOAD_SUB_PROFILE_FAILURE';
export const LOGIN_SUCCESS = '@profile/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@profile/LOGIN_FAILURE';
export const UPDATE_EMAIL_FORM = '@profile/UPDATE_EMAIL_FORM';



export const updateEmailForm = (payload) => ({
  type: UPDATE_EMAIL_FORM,
  payload,
});

export const updateValue = (payload) => ({
  type: UPDATE_VALUE,
  payload,
});

export const updateProfile = (payload) => ({
  type: UPDATE_PROFILE,
  payload,
});

export const loadProfileRequest = (payload) => ({
  type: LOAD_PROFILE_REQUEST,
  payload,
});

export const loadProfileFailure = (payload) => ({
  type: LOAD_PROFILE_FAILURE,
  payload,
});

export const loadProfileSuccess = (payload) => ({
  type: LOAD_PROFILE_SUCCESS,
  payload,
});

export const loadSubProfileRequest = (payload) => ({
  type: LOAD_SUB_PROFILE_REQUEST,
  payload,
});

export const loadSubProfileFailure = (payload) => ({
  type: LOAD_SUB_PROFILE_FAILURE,
  payload,
});

export const loadSubProfileSuccess = (payload) => ({
  type: LOAD_SUB_PROFILE_SUCCESS,
  payload,
});

export const updateValueRequest =
  (payload, profileId) => async (dispatch, getState) => {
    try {
      dispatch(updateValue(payload));
      const state = getState();
      const profileIdToUse = profileId || getProfileId(state);
      await updateProfileRequest(payload, profileIdToUse);
    } catch (error) {
      console.log(error);
    }
  };

export const debouncedUpdateValue = (payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(updateValue(payload));
    debouncedRequest(payload, getProfileId(state));
  } catch (error) {
    console.log(error);
  }
};

export const updateEditableValue = (payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(updateValue(payload));
    await updateProfileRequest(payload, getProfileId(state));
  } catch (error) {
    console.log(error);
  }
};

export const updateSetValues = (payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    dispatch(updateProfile(payload));
    await updateProfileRequest(payload, getProfileId(state));
  } catch (error) {
    console.log(error);
  }
};

export const loadProfile = (navigate) => async (dispatch) => {
  dispatch(loadProfileRequest(true));
  try {
    const profile = await fetchProfile();
    if (!profile.emailVerified && profile.emailVerificationToken) {        
      if (
        !(
          window.location.pathname.includes(ROUTES.VERIFY_EMAIL) &&
          window.location.pathname.length > ROUTES.VERIFY_EMAIL.length
        )
      ) {
        //history.push(ROUTES.VERIFY_EMAIL);
        navigate(ROUTES.FULL_AUTH_GATE_VERIFY_EMAIL)
      }
    }
    dispatch(loadProfileSuccess(profile));
    // TODO handle errors
    // TODO think about batch update
    if (profile.passport) {
      if (profile.passport.step === DOCUMENTS_STEPS.FAILED_REVIEW) {
        profile.passport.open = true;
      }
      dispatch(updatePassport(profile.passport));
    }
    if (profile.education) {
      if (profile.education.step === DOCUMENTS_STEPS.FAILED_REVIEW) {
        profile.education.open = true;
      }
      dispatch(updateEducation(profile.education));
    }
    if (profile.work) {
      if (profile.work.step === DOCUMENTS_STEPS.FAILED_REVIEW) {
        profile.work.open = true;
      }
      dispatch(updateWork(profile.work));
    }
    if (profile.emailNotifications) {
      dispatch(setEmailNotifications(profile.emailNotifications));
    }
    if (profile.phoneNotifications) {
      dispatch(setPhoneNotifications(profile.phoneNotifications));
    }

    dispatch(loadTariffs());
  } catch (error) {
    console.log(error);
    //dispatch(loadProfileRequest(false));
  }
};

export const loadSubProfile = async (dispatch, getState) => {
  dispatch(loadSubProfileRequest());
  try {
    const { subProfiles } = await fetchSubProfiles();
    dispatch(loadSubProfileSuccess(subProfiles));
  } catch (error) {
    console.log(error);
  }
};

export const addSubAccount = (payload) => async (dispatch, getState) => {
  try {
    const state = getState();
    const subProfiles = getSubProfiles(state);
    const { profile, defaultPassword } = await createSubProfile(payload);
    dispatch(
      updateProfile({ subProfiles: [...subProfiles, profile], defaultPassword })
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteProfile = (id) => async (dispatch, getState) => {
  try {
    await deleteProfileApi(id);
    const state = getState();
    const subProfiles = getSubProfiles(state);
    dispatch(
      updateProfile({
        subProfiles: subProfiles.filter((profile) => profile._id !== id),
      })
    );
  } catch (error) {
    console.log(error);
  }
};
