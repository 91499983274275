import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';

export const Pdf = ({ children }) => {
  return (
    <div style={{ height: '100%' }}>      
      <PDFViewer width="100%" height="100%">
        {children}
      </PDFViewer>   
    </div>
  );
};


