import {
    getCompanies as fetchCompanies,
    getManagerLogisticsOffersPacks,
    deleteManagerLogisticsOffersPack} from '../../api';
import {
    fetchItemsRequest,
    fetchItemsSuccess,
    fetchItemsFailure,
    getCompanies
} from '../companies';


export const START_FETCHING = '@logisticsOffers/START_FETCHING';
export const LOAD_MY_OFFERS_PACKS_SUCCESS = '@logisticsOffers/LOAD_MY_OFFERS_PACKS_SUCCESS';
export const LOAD_MY_OFFERS_PACKS_FAILURE = '@logisticsOffers/LOAD_MY_OFFERS_PACKS_FAILURE';
export const DELETE_MY_OFFERS_PACK_SUCCESS = '@logisticsOffers/DELETE_MY_OFFERS_PACK_SUCCESS';
export const DELETE_MY_OFFERS_PACK_FAILURE = '@logisticsOffers/DELETE_MY_OFFERS_PACK_FAILURE';
export const INIT_NEW_OFFER_PACK = '@logisticsOffers/INIT_NEW_OFFER_PACK';

const initialDataNewOffersPack = {
    name: null,
    typetransport: null,
    terminal: null,
    expiredate: null
};


const startFetching = () => ({
  type: START_FETCHING    
});
const loadMyOffersPacksSuccess = (payload) => ({
  type: LOAD_MY_OFFERS_PACKS_SUCCESS,
  payload    
});
const loadMyOffersPacksFailure = (payload) => ({
  type: LOAD_MY_OFFERS_PACKS_FAILURE,
  payload    
});
const initNewOfferPack = (payload) => ({
  type: INIT_NEW_OFFER_PACK,
  payload    
});
const deleteMyOffersPackSuccess = (payload) => ({
  type: DELETE_MY_OFFERS_PACK_SUCCESS,
  payload    
});
const deleteMyOffersPackFailure = (payload) => ({
  type: DELETE_MY_OFFERS_PACK_FAILURE,
  payload    
});


export const switchData = (typeLoad, filters) => async (dispatch, getState) => { // myofferspacks newofferspack

    if (typeLoad == "myofferspacks"){
        dispatch(startFetching());
        try {
            const offersPacks = await getManagerLogisticsOffersPacks(filters);
            dispatch(loadMyOffersPacksSuccess(offersPacks));
            console.log(offersPacks);
        }catch(error){
            loadMyOffersPacksFailure(error?.data);
        }        
    } else if (typeLoad == "newofferspack"){
        dispatch(initNewOfferPack(initialDataNewOffersPack));
    }
}

export const removeMyOffersPack = (id) => async (dispatch, getState) => {
    dispatch(startFetching());
    try {
        const deletedOffersPack = await deleteManagerLogisticsOffersPack(id);
        dispatch(deleteMyOffersPackSuccess(deletedOffersPack));
        console.log(deletedOffersPack);
    }catch(error){
        console.log(error);
        dispatch(deleteMyOffersPackFailure(error?.data));
    }
}