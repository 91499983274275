import React from 'react';
import { ColumnStack } from './ColumnStack'
import styles from './TableBody.module.scss';
import classnames from 'classnames';

export const TableBody = ({ tableData, nullmessage }) => {//{filterType, descrType, title, dataArr, filterKey, sortField}
console.log(tableData);
	return ( 
		<div className={styles.applications_table}>
			<div className={styles.applications_columns}>
				{Boolean(tableData && tableData[0].dataArr.length) && tableData.map((obj)=>ColumnStack(obj))}
				{Boolean(tableData && !tableData[0].dataArr.length) && (
					<div className={classnames(styles.requestisempty)}>{nullmessage}</div>)}
			</div>							
		</div>
	);
}