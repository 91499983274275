import jwtDecode from 'jwt-decode';
import { omit } from 'lodash';
import { login as loginRequest, register as registerRequest } from '../../api';
import { ROUTES, ROLES } from '../../consts';
//import history from '../../components/App/history';
import {
  LoginSchema,
  RegisterSchema,
} from '../../utils';
import {
  getLoginForm,
  getRegisterForm,
} from './selectors';
import { updateProfile, updateValue as updateProfileValue } from '../profile';

export const UPDATE_LOGIN_FORM = '@auth/UPDATE_LOGIN_FORM';
export const UPDATE_AUTH = '@auth/UPDATE_AUTH';
export const UPDATE_REGISTER_FORM = '@auth/UPDATE_REGISTER_FORM';

export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@auth/LOGIN_FAILURE';
export const SET_TOKEN = '@auth/SET_TOKEN';

const setToken = (payload) => ({
  type: SET_TOKEN,
  payload,
});

export const updateAuth = (payload) => ({
  type: UPDATE_AUTH,
  payload,
});

export const updateLoginForm = (payload) => ({
  type: UPDATE_LOGIN_FORM,
  payload,
});

export const updateRegisterForm = (payload) => ({
  type: UPDATE_REGISTER_FORM,
  payload,
});

export const login = (navigate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const loginForm = getLoginForm(state);

    const requestData = {
      emailOrPhone: loginForm.emailOrPhone,
      password: loginForm.password,
    };

    try {
      await LoginSchema.validate(requestData, {
        abortEarly: false,
      });
    } catch (errors) {
      const validationErrors = {};

      errors.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path] = error.message;
        }
      });

      return dispatch(
        updateLoginForm({ key: 'errors', value: validationErrors })
      );
    }

    dispatch(updateProfileValue({ key: 'loading', value: true }));

    const data = await loginRequest(requestData);
    const { token } = data;
    dispatch(setToken(token));
    const { id, role, name, surname, emailVerified } = jwtDecode(token);
    dispatch(updateProfile({ role, id, name, surname, emailVerified, loading: false }));
    dispatch(updateLoginForm({ key: 'password', value: '' }));
    navigate(ROUTES.FULL_PROFILE_PERSONAL);
  } catch (error) {
    if (error?.data) {
      dispatch(updateLoginForm({ key: 'errors', value: error.data }));
    }
    dispatch(updateProfileValue({ key: 'loading', value: false }));
  }
};

export const register = (role, partner, navigate) => async (dispatch, getState) => {
  const state = getState();
  const registerForm = getRegisterForm(state);
  const schema = RegisterSchema;

  try {
    await schema.validate(registerForm, {
      abortEarly: false,
    });
  } catch (errors) {
    const validationErrors = {};
    errors.inner.forEach((error) => {
      if (error.path) {
        validationErrors[error.path] = error.message;
      }
    });

    return dispatch(updateRegisterForm({ key: 'errors', value: validationErrors }));
  }

  dispatch(updateProfileValue({ key: 'loading', value: true }));

  try {
    const requestData = omit({ ...registerForm, role, partner }, [
      'errors',
      'repeatPassword',
    ]);
    const { token } = await registerRequest(requestData);
    dispatch(setToken(token));
    const { id, name, surname } = jwtDecode(token);
    dispatch(updateProfile({ role, id, name, surname, loading: false }));
    dispatch(updateRegisterForm({ key: 'password', value: '' }));
    //history.push(ROUTES.FULL_PROFILE_PERSONAL);
    navigate(ROUTES.FULL_PROFILE_PERSONAL);
  } catch (error) {
    if (error.data) {
      dispatch(updateRegisterForm({ key: 'errors', value: error.data }));
    }
    dispatch(updateProfileValue({ key: 'loading', value: false }));
  }
};

export const registerClient = (partner, navigate) => register(ROLES.USER, partner, navigate);
// we can pass either declarant or partner role here
export const registerManager = register;
