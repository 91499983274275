import { createSelector } from 'reselect';

import { getTariffs } from '../tariffs';

export const getNewRequest = (state) => state.requests.newRequest;
export const getDeliveryInfo = (state) =>
  state.requests.newRequest.deliveryInfo;
export const getDocuments = (state) => state.requests.newRequest.documents;
export const getDiscount = (state) => state.requests.newRequest.discount;
export const getRequestNumber = (state) => state.requests.newRequest.number;
export const getNewRequestTariff = (state) => state.requests.newRequest.tariff;
export const getRequests = (state) => state.requests.items;
export const getCount = (state) => state.requests.count;
export const getRequest = (state) => state.requests.item;
export const getPool = (state) => state.requests.pool;
export const getLoading = (state) => state.requests.loading;
export const getDraftsCount = (state) => state.requests.draftsCount;
export const getUnreadDraftsCount = (state) => state.requests.unreadDraftsCount;
export const getDrafts = (state) => state.requests.drafts;
export const getSelectedTariff = createSelector(
  [getTariffs, getNewRequestTariff],
  (tariffs, newRequestTariff) => {
    const selectedTariff = tariffs.find(
      ({ type }) => type === newRequestTariff
    );
    return selectedTariff;
  }
);
