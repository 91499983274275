import React, { useState } from 'react';

import { WORK_FIELDS } from '../../configs/work';
import { Title } from '../shared/Title';
import { Input } from '../shared/Input';
import { WorkPlacesView } from './WorkPlacesView';
import { Accordion } from '../shared/Accordion';

import styles from './WorkFormView.module.scss';

export const WorkFormView = ({ work }) => {
  const [workOpen, setWorkOpen] = useState(true);
  const handleToggle = () => setWorkOpen(!workOpen);

  return (
    <Accordion
      title={
        <div className={styles.Title}>
          <div className={styles.Title__Text}>Рабочая анкета декларанта</div>
        </div>
      }
      open={workOpen}
      onToggle={handleToggle}
      fadedToggler
    >
      <div>
        <Title as="h4">
          Места работы или проекты, относящиеся к таможенному делу
        </Title>
        <WorkPlacesView places={work.places} />
        <Title as="h4">Предпочтения в работе</Title>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            margin: '0.75rem 0 1.5rem 0',
          }}
        >
          {Object.keys(WORK_FIELDS).map((field, idx) => {
            if (WORK_FIELDS[field].type === 'noDisplay') {
              return null;
            }

            return (
              <div key={idx} style={{ width: idx ? '44%' : '53%' }}>
                <Input value={work[field]} disabled lightgrey />
              </div>
            );
          })}
        </div>
      </div>
    </Accordion>
  );
};
