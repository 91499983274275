import {
    addCompanies,
    addCompaniesSuccess,
    addCompaniesFailure,
    getMyVerifiedCompanies,
    getFirstMyVerifiedCompanyName,
    getFirstMyVerifiedCompanyId } from '../mycompanies';

import {
    sendClientNewLogisticsRequest,
    sendManagerNewLogisticsOffer,
    getClientLogisticsRequests,
    getManagerLogisticsRequests,
    getClientLogisticsInWork,
    getClientLogisticsArchive,
    getClientLogisticsRequest,
    getManagerLogisticsRequest,
    loadMyVerifiedCompanies,
    deleteManagerOffer,
    deleteClientRequest } from '../../api';

import { getRequestsCount, getOffersCount, getRequestId } from './selectors';

import { prepareClientNewRequest,
        prepareManagerNewOffer } from '../../utils';

export const START_FETCHING = '@logistics/START_FETCHING';
export const LOAD_MY_REQUESTS_SUCCESS = '@logistics/LOAD_MY_REQUESTS_SUCCESS';
export const LOAD_MY_REQUESTS_FAILURE = '@logistics/LOAD_MY_REQUESTS_FAILURE';
export const ADD_NEW_MY_REQUEST = '@logistics/ADD_NEW_MY_REQUEST';
export const ADD_NEW_MY_OFFER = '@logistics/ADD_NEW_MY_OFFER';
export const LOAD_MY_IN_WORK = '@logistics/LOAD_MY_IN_WORK';
export const LOAD_MY_IN_WORK_SUCCESS = '@logistics/LOAD_MY_IN_WORK_SUCCESS';
export const LOAD_MY_IN_WORK_FAILURE = '@logistics/LOAD_MY_IN_WORK_FAILURE';
export const LOAD_MY_ARCHIVE = '@logistics/LOAD_MY_ARCHIVE';
export const LOAD_MY_ARCHIVE_SUCCESS = '@logistics/LOAD_MY_ARCHIVE_SUCCESS';
export const LOAD_MY_ARCHIVE_FAILURE = '@logistics/LOAD_MY_ARCHIVE_FAILURE';
export const LOAD_CLIENT_ONE_REQUEST = '@logistics/CLIENT/LOAD_ONE_REQUEST';
export const LOAD_CLIENT_ONE_REQUEST_SUCCESS = '@logistics/CLIENT/LOAD_ONE_REQUEST_SUCCESS';
export const LOAD_CLIENT_ONE_REQUEST_FAILURE = '@logistics/CLIENT/LOAD_ONE_REQUEST_FAILURE';
export const DELETE_MANAGER_OFFER_SUCCESS = '@logistics/MANAGER/DELETE_MANAGER_OFFER_SUCCESS';
export const DELETE_MANAGER_OFFER_FAILURE = '@logistics/MANAGER/DELETE_MANAGER_OFFER_FAILURE';
export const DELETE_CLIENT_REQUEST_SUCCESS = '@logistics/CLIENT/DELETE_CLIENT_REQUEST_SUCCESS';
export const DELETE_CLIENT_REQUEST_FAILURE = '@logistics/CLIENT/DELETE_CLIENT_REQUEST_FAILURE';

const startFetching = (payload) => ({
  type: START_FETCHING,
  payload,    
});
const loadMyRequestsSuccess = (payload) => ({
  type: LOAD_MY_REQUESTS_SUCCESS,
  payload,
});
const loadMyRequestsFailure = (payload) => ({
  type: LOAD_MY_REQUESTS_FAILURE,
  payload,
});
const addNewMyRequest = (payload) => ({
  type: ADD_NEW_MY_REQUEST,
  payload,
});
const addNewMyOffer = (payload) => ({
  type: ADD_NEW_MY_OFFER,
  payload,
});
const loadMyInWorkSuccess = (payload) => ({
  type: LOAD_MY_IN_WORK_SUCCESS,
  payload,
});
const loadMyInWorkFailure = (payload) => ({
  type: LOAD_MY_IN_WORK_FAILURE,
  payload,
});
const loadMyArchiveSuccess = (payload) => ({
  type: LOAD_MY_ARCHIVE_SUCCESS,
  payload,
});
const loadMyArchiveFailure = (payload) => ({
  type: LOAD_MY_ARCHIVE_FAILURE,
  payload,
});
const loadClientOneRequestSuccess = (payload) => ({
  type: LOAD_CLIENT_ONE_REQUEST_SUCCESS,
  payload,
});
const loadClientOneRequestFailure = (payload) => ({
  type: LOAD_CLIENT_ONE_REQUEST_FAILURE,
  payload,
});
const deleteManagerOfferSuccess = (payload) => ({
  type: DELETE_MANAGER_OFFER_SUCCESS,
  payload,
});
const deleteManagerOfferFailure = (payload) => ({
  type: DELETE_MANAGER_OFFER_FAILURE,
  payload,
});
const deleteClientRequestSuccess = (payload) => ({
  type: DELETE_CLIENT_REQUEST_SUCCESS,
  payload,
});
const deleteClientRequestFailure = (payload) => ({
  type: DELETE_CLIENT_REQUEST_FAILURE,
  payload,
});





export const handleSender = (stepsData) => async (dispatch, getState) => {

    let postData, response, count;
    const state = getState();

    if (stepsData.type == "clientnewrequest"){
       
        postData = prepareClientNewRequest(stepsData);
        postData.companyName = getFirstMyVerifiedCompanyName(state);
        postData.companyId = getFirstMyVerifiedCompanyId(state);
        try{
            response = await sendClientNewLogisticsRequest(postData);
            count = getRequestsCount(state);
            console.log(response);
            if (response && count < 6){
                response.data.offers = 0;                 
                dispatch(addNewMyRequest(response));
            }
            
        }catch(err){
            console.log(err);
        };        

    }

    if (stepsData.type == "managernewoffer"){
        
        postData = prepareManagerNewOffer(stepsData);
        postData.companyName = getFirstMyVerifiedCompanyName(state);
        postData.companyId = getFirstMyVerifiedCompanyId(state);
        postData.requestId = getRequestId(state);
        try{            
            response = await sendManagerNewLogisticsOffer(postData);
            count = getOffersCount(state);
            console.log(response);
            if (response) dispatch(addNewMyOffer(response));
        }catch(err){
            console.log(err);
        };
    }

};

export const handleLoadClient = (typeLoad, filters) => async (dispatch, getState) => { //calculations, inwork, archive  

    if (typeLoad == "calculations"){
        dispatch(startFetching());
        try {
            const requests = await getClientLogisticsRequests(filters);
            dispatch(loadMyRequestsSuccess(requests));
            console.log(requests);
        }catch(error){
            loadMyRequestsFailure(error?.data);
        }        
    } else if (typeLoad == "inwork"){
        dispatch(startFetching());
        try {
            const requests = await getClientLogisticsInWork(filters);
            dispatch(loadMyInWorkSuccess(requests));
            console.log(requests);
        }catch(error){
            loadMyInWorkFailure(error?.data);
        }         
    } else if (typeLoad == "archive"){
        dispatch(startFetching());
        try {
            const requests = await getClientLogisticsArchive(filters);
            dispatch(loadMyArchiveSuccess(requests));
            console.log(requests);
        }catch(error){
            loadMyArchiveFailure(error?.data);
        }
    }

    const state = getState();       
    if (!getMyVerifiedCompanies(state).length){
        dispatch(startFetching());
        try {
            const companies = await loadMyVerifiedCompanies();
            dispatch(addCompaniesSuccess(companies));
            console.log(companies);
        } catch (error) {
                dispatch(addCompaniesFailure(error?.data));
            } 
    }
}

export const handleLoadManager = (typeLoad, filters) => async (dispatch, getState) => { //calculations, inwork, archive  

    if (typeLoad == "calculations"){
        dispatch(startFetching());
        try {
            const requests = await getManagerLogisticsRequests(filters); //1
            dispatch(loadMyRequestsSuccess(requests));
            console.log(requests);
        }catch(error){
            loadMyRequestsFailure(error?.data);
        }        
    } else if (typeLoad == "inwork"){
        dispatch(startFetching());
        try {
            const requests = await getClientLogisticsInWork(filters);
            dispatch(loadMyInWorkSuccess(requests));
            console.log(requests);
        }catch(error){
            loadMyInWorkFailure(error?.data);
        }         
    } else if (typeLoad == "archive"){
        dispatch(startFetching());
        try {
            const requests = await getClientLogisticsArchive(filters);
            dispatch(loadMyArchiveSuccess(requests));
            console.log(requests);
        }catch(error){
            loadMyArchiveFailure(error?.data);
        }
    }
}

export const getClientRequestById = (id) => async (dispatch) => {

    dispatch(startFetching());
    try {
        const request = await getClientLogisticsRequest(id);
        dispatch(loadClientOneRequestSuccess(request));
        console.log(request);
    }catch(error){
        loadClientOneRequestFailure(error?.data);
    } 
}

export const getManagerRequestById = (id) => async (dispatch, getState) => {

    dispatch(startFetching());
    try {
        const request = await getManagerLogisticsRequest(id);
        dispatch(loadClientOneRequestSuccess(request));
        console.log(request);
    }catch(error){
        loadClientOneRequestFailure(error?.data);
    } 

    const state = getState();       
    if (!getMyVerifiedCompanies(state).length){
        dispatch(startFetching());
        try {
            const companies = await loadMyVerifiedCompanies();
            dispatch(addCompaniesSuccess(companies));
            console.log(companies);
        } catch (error) {
                dispatch(addCompaniesFailure(error?.data));
            } 
    }    
}

export const removeManagerOffer = (id) => async (dispatch, getState) => {
    dispatch(startFetching());
    try {
        const deletedOffer = await deleteManagerOffer(id);
        dispatch(deleteManagerOfferSuccess(deletedOffer));
        console.log(deletedOffer);
    }catch(error){
        console.log(error);
        dispatch(deleteManagerOfferFailure(error?.data));
    }
}

export const removeClientRequest = (id) => async (dispatch, getState) => {
    dispatch(startFetching());
    try {
        const deletedRequest = await deleteClientRequest(id);
        dispatch(deleteClientRequestSuccess(deletedRequest));
        console.log(deletedRequest);
    }catch(error){
        console.log(error);
        dispatch(deleteClientRequestFailure(error?.data));
    }
}


