import { API_ROUTES } from '../consts';
import { ApiHelper, generateQuery } from '../utils';

export const sendClientNewLogisticsRequest = (payload) =>
  ApiHelper.post(API_ROUTES.LOGISTICS_NEW_REQUEST, payload);

export const sendManagerNewLogisticsOffer = (payload) =>
  ApiHelper.post(API_ROUTES.LOGISTICS_NEW_OFFER, payload);

export const getClientLogisticsRequests = (filters) => {
	const queryString = generateQuery(filters);
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_CLIENT_MY_REQUESTS}${queryString}`); 
}

export const getManagerLogisticsRequests = (filters) => {
	const queryString = generateQuery(filters);
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_MANAGER_MY_REQUESTS}${queryString}`); 
}

export const getClientLogisticsInWork = (filters) => {
	const queryString = generateQuery(filters);
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_MY_IN_WORK}${queryString}`); 
}

export const getClientLogisticsArchive = (filters) => {
	const queryString = generateQuery(filters);
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_MY_ARCHIVE}${queryString}`); 
}

export const getClientLogisticsRequest = (idRequest) => {
	const queryString = generateQuery({id:idRequest});
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_CLIENT_GET_REQUEST}${queryString}`); 
}

export const getManagerLogisticsRequest = (idRequest) => {
	const queryString = generateQuery({id:idRequest});
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_MANAGER_GET_REQUEST}${queryString}`); 
}

export const deleteManagerOffer = (id) =>
  ApiHelper.delete(`${API_ROUTES.LOGISTICS_DELETE_OFFER}/${id}`);

export const deleteClientRequest = (id) =>
  ApiHelper.delete(`${API_ROUTES.LOGISTICS_DELETE_REQUEST}/${id}`);

export const getManagerLogisticsOffersPacks = (filters) => {
	const queryString = generateQuery(filters);
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_MANAGER_MY_OFFERS_PACKS}${queryString}`); 
}

export const deleteManagerLogisticsOffersPack = (id) =>
  ApiHelper.delete(`${API_ROUTES.LOGISTICS_MANAGER_DELETE_MY_OFFERS_PACK}/${id}`);

export const sendManagerNewOffersPack = (payload) =>
  ApiHelper.post(API_ROUTES.LOGISTICS_MANAGER_NEW_MY_OFFERS_PACK, payload);

export const getManagerLogisticsOffersPack = (idRequest) => {
	const queryString = generateQuery({id:idRequest});
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_MANAGER_MY_OFFERS_PACK}${queryString}`); 
}

export const getClientLogisticsOffers = (filters) => {
	const queryString = generateQuery(filters);
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_CLIENT_OFFERS}${queryString}`); 
}

export const sendClientOfferByEmail = (payload) =>
  ApiHelper.post(API_ROUTES.LOGISTICS_CLIENT_SEND_OFFER_EMAIL, payload);



export const getManagerLogisticsOffersFromPack = (filters) => {
	const queryString = generateQuery(filters);
	return ApiHelper.get(`${API_ROUTES.LOGISTICS_MANAGER_MY_OFFERS_FROM_PACK}${queryString}`); 
}

  
  
  

 







