import {
	SET_MESSAGE_BOX_STATE,
	INIT_MESSAGE_BOX } from './actions';



const initState = {
	seaTab: {state: false, data: {
				message: "Вы не добавили морские маршруты",
				descrMessage: "Во вкладке \"Море\" вы не можете создать предложения "+
								"без морской части. Воспользуйтесь вкладкой \"Прямое ЖД\"",
				resolve: "ОК"
			}
	},
	trainTab: {state: false, data:{
				message: "Вы не добавили ЖД маршруты",
				descrMessage: "В панеле справа находятся города отправлений. "+
					"Выберите один / несколько городов и заполните необходимые поля.",	
				resolve: "ОК"								
			}
	},
	successSeaTab: {state: false, data:{
				message: "Предложения по морю успешно созданы",
				descrMessage: "Во вкладке \"мои предложения\" вы можете редактировать"+
					" а так же удалять свои предложения",	
				resolve: "ОК"								
			}
	},
	successTrainTab: {state: false, data:{
				message: "Предложения по прямому ЖД успешно созданы",
				descrMessage: "Во вкладке \"мои предложения\" вы можете редактировать"+
					" а так же удалять свои предложения",	
				resolve: "ОК"								
			}
	},
	failNewOffers: {state: false, data:{
				message: "Произошла ошибка при создании предложений",
				descrMessage: "Обновите страницу и попробуйте снова."+
					" Если ошибка повториралсь обратитесь в техподдержку",	
				resolve: "ОК"								
			}
	}			
	
};

export const modalMessageReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_MESSAGE_BOX_STATE:
      return { ...state, [action.payload.boxName]:{...state[action.payload.boxName], state: action.payload.state}};    
    case INIT_MESSAGE_BOX:
      return { ...state, ...action.payload};        
    default:
      return state;
  }
}