import { API_ROUTES } from '../consts';
import { ApiHelper, generateQuery } from '../utils';

export const createRequest = (payload) =>
  ApiHelper.post(API_ROUTES.CREATE_REQUEST, payload);

export const createDraft = (payload) =>
  ApiHelper.post(API_ROUTES.DRAFT, payload);

export const updateDraft = (id, payload) =>
  ApiHelper.patch(`${API_ROUTES.DRAFT}/${id}`, payload);

export const getDrafts = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.DRAFT}${queryString}`);
};

export const getDraft = (id) => ApiHelper.get(`${API_ROUTES.DRAFT}/${id}`);
export const deleteDraft = (id) =>
  ApiHelper.delete(`${API_ROUTES.DRAFT}/${id}`);

export const updateRequest = (id, payload) =>
  ApiHelper.patch(`${API_ROUTES.REQUESTS}/${id}`, payload);

export const getRequest = (id) => ApiHelper.get(`${API_ROUTES.REQUESTS}/${id}`);

export const getRequests = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.REQUESTS}${queryString}`);
};

export const getPool = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.REQUESTS_POOL}${queryString}`);
};

export const takeRequest = (id) =>
  ApiHelper.get(`${API_ROUTES.REQUESTS}/${id}/take`);

export const getInProgress = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.REQUESTS_IN_PROGRESS}${queryString}`);
};

export const getCompleted = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.REQUESTS_COMPLETED}${queryString}`);
};

export const getAllRequests = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.ALL_REQUESTS}${queryString}`);
};

export const getAllRequestsDone = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.ALL_REQUESTS_DONE}${queryString}`);
};

export const getAllRequestsInProgress = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.ALL_REQUESTS_IN_PROGRESS}${queryString}`);
};

export const getRequestFromAll = (id) =>
  ApiHelper.get(`${API_ROUTES.ALL_REQUESTS}/${id}`);
