import React from 'react';

import { PaymentForm } from './Client/PaymentForm';

import styles from './Refill.module.scss';

export const Refill = () => {
  return (
    <main>
      {/*
      <div className={styles.Refill}>
        <div
          className={styles.Card}
          onClick={() =>
            alert(
              'Возможность пополнения картой будет доступна в следующей версии сервиса'
            )
          }
        >
          <div className={styles.Card__Content}>
            <img src={CreditCard} alt="credit-card" />
            <div className={styles.Card__Title}>Картой</div>
            <div className={styles.Card__Option}>– Visa</div>
            <div className={styles.Card__Option}>– MasterCard</div>
            <div className={styles.Card__Option}>– Maestro</div>
          </div>
          <div className={styles.Card__Footer}>Оплатить картой</div>
        </div>
        <div
          className={styles.Card}
          onClick={() => {
            setOpen(true);
            setTimeout(() => {
              const form = document.getElementById('payment-form');
              if (form) {
                form.scrollIntoView({
                  behavior: 'smooth',
                });
              }
            }, 500);
          }}
        >
          <div className={styles.Card__Content}>
            <img src={Wallet} alt="wallet" />
            <div className={styles.Card__Title}>По реквизитам</div>
            <div className={styles.Card__Option}>– Visa</div>
            <div className={styles.Card__Option}>– MasterCard</div>
            <div className={styles.Card__Option}>– Maestro</div>
          </div>
          <div className={styles.Card__Footer}>По реквизитам</div>
        </div>
      </div>
      */}

      <div id="payment-form" className={styles.PaymentForm}>
        <PaymentForm />
      </div>
      <div className={styles.Footer}>
        Оплачивая счет вы пополняете баланс виртуального счета для дальнейшего
        произведения платежа на платформе, а так же принимаете условия оферты,
        виртуального счета и оферты платформы.
      </div>
    </main>
  );
};
