export const TYPE_OPTIONS = {
  import: { value: 'import', label: 'Импорт', contractType: ' с отправителем' },
  export: { value: 'export', label: 'Экспорт', contractType: ' с получателем' },
};

export const SHIPPING_OPTIONS = {
  avia: { value: 'avia', label: 'Авиа' },
  auto: { value: 'auto', label: 'Авто' },
  sea: { value: 'sea', label: 'Море' },
  rw: { value: 'rw', label: 'ЖД' },
  mixed: { value: 'mixed', label: 'Смешанное' },
};

export const DELIVERY_TERMS = {
  LCL: { value: 'LCL', label: 'LCL (сборный груз)' },
  FCL: { value: 'FCL', label: 'FCL (цельный груз)' },
};

export const STATUS_OPTIONS = {
  new: { value: 'new', label: 'Поиск декларанта' },
  inProgress: { value: 'inProgress', label: 'Заявка в работе' },
  customsPayments: {
    value: 'customsPayments',
    label: 'Таможенные платежи выставлены',
  },
  declarationReady: {
    value: 'declarationReady',
    label: 'Декларация выпущена',
  },
  done: { value: 'done', label: 'Заявка исполнена' },
};
