import React, { useEffect, useState } from 'react';

import { fetchReviews } from '../../../api';
import { useScrollTop } from '../../../hooks';

import { ReviewCard } from './ReviewCard';

export const NewReviews = () => {
  useScrollTop();
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    fetchReviews({ status: 'pending' })
      .then(({ reviews }) => setReviews(reviews))
      .catch(console.log);
  }, []);

  return (
    <div>
      {reviews.length > 0 ? (
        reviews.map((review) => (
          <ReviewCard setReviews={setReviews} {...review} />
        ))
      ) : (
        <div>Новых отзывов пока нет.</div>
      )}
    </div>
  );
};
