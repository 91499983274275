import React, { useEffect, useState } from 'react';

import { checkInvite } from '../api';
import { RegisterClient, RegisterManager } from '../components/Auth/Register';
import { LoadingPage } from '../components/hocs/withLoading/LoadingPage';
import { NotFound } from './NotFound';
import { ROLES } from '../consts';
import { useParams } from 'react-router-dom';

export const Join = () => {
  const [invite, setInvite] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    async function validate() {
      const { valid, invite } = await checkInvite(id);
      if (valid) {
        setInvite(invite);
      }
      setLoading(false);
    }

    validate();
  }, [id]);

  if (loading) {
    return <LoadingPage />;
  }

  return invite ? (
    invite.role === ROLES.USER ? (
      <RegisterClient partner={invite?.profile} />
    ) : (
      <RegisterManager role={invite.role} partner={invite.profile} />
    )
  ) : (
    <NotFound />
  );
};
