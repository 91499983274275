import React from 'react';
import classnames from 'classnames';

import StatusPending from '../../assets/StatusPending.svg';
import StatusResolved from '../../assets/StatusResolved.svg';
import StatusRejected from '../../assets/StatusRejected.svg';

import styles from './Statuses.module.scss';

export const getStatusNode = (status, aligned) =>
  ({
    pending: (
      <span
        className={classnames(styles.StatusLine, {
          [styles['StatusLine--aligned']]: aligned,
        })}
        style={{ color: '#00A4FA' }}
      >
        <img src={StatusPending} alt="pending" /> В обработке
      </span>
    ),
    resolved: (
      <span
        className={classnames(styles.StatusLine, {
          [styles['StatusLine--aligned']]: aligned,
        })}
        style={{ color: '#00B05C' }}
      >
        <img src={StatusResolved} alt="resolved" /> Исполнен
      </span>
    ),
    rejected: (
      <span
        className={classnames(styles.StatusLine, {
          [styles['StatusLine--aligned']]: aligned,
        })}
        style={{ color: '#A40202' }}
      >
        <img src={StatusRejected} alt="pending" /> Отклонен
      </span>
    ),
  }[status]);

export const getTypeNode = (value) =>
  value === 'refill' ? 'пополнение' : 'снятие';
