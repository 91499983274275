import { createTransaction } from '../api';
import { TRANSACTIONS_TYPES, TEMP_PROFILE_ID, SYSTEM_PROFILE_ID } from '../consts';

const {
  REFILL_CA,
  ORDER_PAYMENT_CA,
  ORDER_REFUND_CA,
  ORDER_RECEIPT_DA,
  ORDER_RECEIPT_PA,
  ORDER_RECEIPT_SA,
  ADD_PAYMENT_CADA,
  ADD_PAYMENT_CASA,
  DEBIT_DA,
  DEBIT_PA,
} = TRANSACTIONS_TYPES;

export const generateTransaction = async (
  type,
  sum,
  subjectId,
  requestNumber,
  receiverId
) => {
  const typeAndSum = { type, sum: Number(sum) };

  const payload = () => {
    switch (type) {
      case REFILL_CA:
        return {
          ...typeAndSum,
          agentId: subjectId,
          receiverId: subjectId,
          action: 'refill',
        };
      case ORDER_PAYMENT_CA:
        return {
          ...typeAndSum,
          requestNumber,
          agentId: subjectId,
          receiverId: TEMP_PROFILE_ID,
          action: 'withdrawals',
        };
      case ORDER_REFUND_CA:
        return {
          ...typeAndSum,
          requestNumber,
          agentId: TEMP_PROFILE_ID,
          receiverId: subjectId,
          action: 'refill',
        };
      case ORDER_RECEIPT_DA:
      case ORDER_RECEIPT_PA:
      case ORDER_RECEIPT_SA:
        return {
          ...typeAndSum,
          requestNumber,
          agentId: TEMP_PROFILE_ID,
          receiverId: subjectId,
          action: 'refill',
        };
      case ADD_PAYMENT_CADA:
        return {
          ...typeAndSum,
          receiverId,
          requestNumber,
          agentId: subjectId,
          action: 'withdrawals',
        };
      case ADD_PAYMENT_CASA:
        return {
          ...typeAndSum,
          requestNumber,
          agentId: subjectId,
          receiverId: SYSTEM_PROFILE_ID,
          action: 'withdrawals',
        };
      case DEBIT_DA:
      case DEBIT_PA:
        return {
          ...typeAndSum,
          agentId: subjectId,
          receiverId: subjectId,
          action: 'withdrawals',
        };
      default:
        return {};
    }
  };

  await createTransaction(payload());
};
