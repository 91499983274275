import React, { useState, useRef, useEffect } from 'react';
import styles from './CreatePackBtn.module.sass';
import classnames from 'classnames';

export const CreatePackBtn = ({ setStateCb }) => {

	const handlerClick = (event) => {
		let btn;
		if (btn = event.target.closest("."+classnames(styles.btn_create_pack))){			
			if(setStateCb){
				setStateCb();
			}
		}
	}

	return(
		<div className={classnames(styles.btn_create_pack)} onClick={handlerClick}>Создать предложения</div>
	);

}