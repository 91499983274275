import React from 'react';
import { Routes, Route} from 'react-router-dom';
import { ROUTES } from '../consts';

import { Title } from '../components/shared/Title';
import { Tabs } from '../components/shared/Tabs';
import { Verification as VerificationComponent } from '../components/Verification';
import { withLoading } from '../components/hocs/withLoading';
import { VerifyProfile } from '../components/VerifyProfile';


const TABS = [
  { 
  	title: 'Декларанты',
  	route: ROUTES.FULL_VERIFICATION,
  	visible: true 
  },
];

export const Verification = () => {
  return (
    <>
      <Routes>
        <Route path="/"  Component={withLoading(()=>(
          <main>
            <Title>Верификация</Title>
            <Tabs tabs={TABS.filter(({ visible }) => visible)} />
            <VerificationComponent />
          </main>))}
        />
        <Route path={ROUTES.VERIFICATION_PROFILE}  Component={withLoading(()=>(<VerifyProfile />))}/>
      </Routes>
    </>
  );
};
