export const BANK_LOGOS = {
  44525593: 'https://toplogos.ru/images/logo-alfa-bank.jpg',
  44525225: 'https://toplogos.ru/images/logo-sberbank.png',
  44525974: 'https://toplogos.ru/images/logo-tinkoff.png',
  44525745: 'https://toplogos.ru/images/logo-vtb.png',
  44525823: 'https://toplogos.ru/images/logo-gazprombank.jpg',
  44525111: 'https://toplogos.ru/images/logo-rosselhozbank.png',
  44525659: 'https://toplogos.ru/images/logo-mkb.png',
  44525700: 'https://toplogos.ru/images/logo-raiffeisen-bank.jpg',
};
