import { API_ROUTES } from '../consts';
import { ApiHelper } from '../utils';

export const createPromocode = (payload) =>
  ApiHelper.post(API_ROUTES.PROMOCODES, payload);

export const fetchPromocodes = () => ApiHelper.get(API_ROUTES.PROMOCODES);
export const fetchActivePromocodes = () =>
  ApiHelper.get(`${API_ROUTES.PROMOCODES}/active`);
export const updatePromocode = (id, payload) =>
  ApiHelper.patch(`${API_ROUTES.PROMOCODES}/${id}`, payload);

export const activatePromocode = (payload) =>
  ApiHelper.post(`${API_ROUTES.PROMOCODES}/activate`, payload);
