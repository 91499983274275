import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { getProfile } from '../../store/profile';
import {
  applyFilters,
  getDeclarants,
  getDeclarantsCount,
  getLoading,
  loadDeclarants,
} from '../../store/verification';
import { getDeclarantsFilters } from '../../store/filters';
import { Table } from '../shared/Table';
import { Message } from '../shared/Message';
import { ROUTES } from '../../consts';

export const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const declarants = useSelector(getDeclarants);
  const count = useSelector(getDeclarantsCount);
  const loading = useSelector(getLoading);
  const filters = useSelector(getDeclarantsFilters);

  useEffect(() => {
    if (profile?._id) {
      dispatch(loadDeclarants());
    }
  }, [dispatch, profile]);

  const TABLE_HEADERS = [
    {
      label: 'id',
      value: '_id',
      sortable: true,
      render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
    },
    {
      label: 'Имя Фамилия',
      value: 'name',
      renderItem: true,
      render: (item) => `${item.name} ${item.surname}`,
    },
    {
      label: 'Контакты',
      value: 'contacts',
      renderItem: true,
      render: (item) => (
        <div>
          <div>{item.phone}</div>
          <div>{item.email}</div>
        </div>
      ),
    },
    {
      label: 'Дата регистрации',
      value: 'createdAt',
      sortable: true,
      render: (value) =>
        value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : 'нет данных',
    },
    {
      label: 'Статус',
      value: 'status',
      render: () => 'Ожидает верификацию',
      sortable: true,
    },
    {
      label: '',
      value: 'rowActions',
      render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
    },
  ];

  return (
    
    <div>
      {declarants.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`${ROUTES.FULL_VERIFICATION}/${_id}`);
          }}
          loading={loading}
          items={declarants}
          headers={TABLE_HEADERS}
          filters={filters}
          countItems={count}
          applyFilters={(filters) => dispatch(applyFilters(filters))}
        />
      ) : (
        <Message type="info">
          В настоящий момент в системе нет сведений о декларантах, ожидающих
          верификацию
        </Message>
      )}
    </div>    
  );
};
