import React from 'react';

import { UploadedDocument } from './UploadedDocument';
import ExclamationBlue from '../../../assets/ExclamationBlue.svg';
import { UploadButton } from '../Button';
import { getDocName } from '../../../utils';

import styles from './UploadArea.module.scss';

export const UploadArea = ({
  viewOnly,
  description,
  children,
  files,
  id,
  onUpload,
  onExclude,
  disabled,
  configFiles = {},
}) => {
  const { mainHead, docsList, addHead, note } = configFiles;

  return (
    <div className={viewOnly ? styles.DocsViewArea : styles.UploadArea}>
      <div className={viewOnly ? styles.FilesArea__Full : styles.FilesArea}>
        <div className={styles.FilesArea__PackageName}>{children}</div>
        <div className={styles.FilesArea__UploadFiles}>
          {files?.map((doc, idx) => (
            <UploadedDocument
              key={doc + `${idx}`}
              link={doc}
              index={idx}
              name={getDocName({ doc, id, idx })}
              onExclude={onExclude ? () => onExclude(id, doc) : null}
              disabled={disabled}
            />
          ))}
        </div>
        {!viewOnly && (
          <div className={styles.ButtonArea}>
            <UploadButton
              filled
              disabled={disabled}
              id={id}
              onUpload={onUpload}
            >
              Загрузить
            </UploadButton>
          </div>
        )}
      </div>
      {(!viewOnly || description) && (
        <div className={styles.DescriptionArea}>
          <div className={styles.DescriptionArea__Info}>
            <div className={styles.Line}>
              <div className={styles['DescriptionArea__Info--Head']}>
                {mainHead}:
              </div>
              <img src={ExclamationBlue} alt="ExclamationBlue" />
            </div>
            {docsList?.map((docItem, idx) => {
              return (
                <div className={styles.StringItem} key={idx}>
                  {docItem}
                </div>
              );
            })}
            {addHead && (
              <>
                <div className={styles['DescriptionArea__Info--Head']}>
                  {addHead}:
                </div>
                {note?.map((noteItem, idx) => {
                  return (
                    <div className={styles.StringItem} key={idx}>
                      {noteItem}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
