import React from 'react';
import { SubTabs } from '../shared/SubTabs';
import { ROUTES } from '../../consts';

export const LogistSubTabsHeader = () => {
  return (
    <SubTabs
      subTabs={[
        {
          title: 'Все',
          route: "../"+ROUTES.USERS_LOGISTS,
          useMatchRoute: ROUTES.FULL_USERS_LOGISTS,
          visible: true,
        },
        {
          title: 'Аккредитованные',
          route: "../"+ROUTES.USERS_LOGISTS_ACCREDITED,
          useMatchRoute: ROUTES.FULL_USERS_LOGISTS_ACCREDITED,
          visible: true,
        },
      ]}
    />
  );
};