import { saveAs } from "file-saver";
import {
	Document,
	Packer,
	Paragraph,
	TextRun,
	ImageRun,
	AlignmentType,
	UnderlineType,
	Table,
	TableRow,
	TableCell,
	WidthType,
	HeightRule,
	VerticalAlign} from "docx";
import MyImage from "../assets/pdflogo.png";
import { makeLongDate, costDecore } from './';



const tableCell = (text) => {

	const arial13 = {
		font: "Arial",
		color: "000000",
		size: "26"	    
	}

	const tableCenter = {
		children: [new TextRun({...arial13, text: text})],
		alignment: AlignmentType.CENTER,
	}


	return new Paragraph(tableCenter);
}

const textFrame = (nameFrame, text) => {

	const arial10 = {
		font: "Arial",
		color: "000000",
		size: "20"	    
	}

	const arial12 = {
		font: "Arial",
		color: "000000",
		size: "24"	    
	}

	return [
        new Paragraph({
            children: [new TextRun({...arial10, text: nameFrame})],
            alignment: AlignmentType.START,
        }),
		new Paragraph({
		    children: [new TextRun({...arial12, text: text})],
		    // indent: {left: 20, right: 20},
		    border: {
		        top: {
		            color: "000000",
		            space: 0,
		            style: "single",
		            size: 6,
		        },
		        bottom: {
		            color: "000000",
		            space: 0,
		            style: "single",
		            size: 6,
		        },
		        left: {
		            color: "000000",
		            space: 0,
		            style: "single",
		            size: 6,
		        },
		        right: {
		            color: "000000",
		            space: 0,
		            style: "single",
		            size: 6,
		        },                
		    },
		}),
		new Paragraph({
			children: [],
		}),        
	];


}



export const createOfferDocX = (item) => {

	const logo = new ImageRun({
	    data: MyImage,
	    transformation: {
	        width: 237,
	        height: 31,
	    }	    
	});	

	const header = new TextRun({
		text: `Информация по предложению № ${item.docId}:`,
	    bold: true,
		font: "Arial",
		color: "4472C4",
		size: "28"	    
	});

	const subHeader = new TextRun({
		text: `Данное предложение включает ставки:`,
	    bold: true,
		font: "Arial",
		color: "01D39A",
		size: "28"	    
	});	

	const expireText = new TextRun({
		text: `Срок предложения:`,
		font: "Arial",
		color: "000000",
		size: "26",
	    underline: {
	        type: UnderlineType.SINGLE,
	        color: "000000",
	    },			    
	});

	const expireDate = new TextRun({
		text: `до ${makeLongDate(item.expireDate)}`,
		font: "Arial",
		color: "000000",
		size: "24"	    
	});

	const customsText = new TextRun({
		text: `Таможенное оформление:`,
		font: "Arial",
		color: "000000",
		size: "26",
	    underline: {
	        type: UnderlineType.SINGLE,
	        color: "000000",
	    },			    
	});

	const customsOption = (option) => new TextRun({
		text: ` ${option}`,
		font: "Arial",
		color: "000000",
		size: "24"	    
	});


	const routeText = new TextRun({
		text: `Маршрут:`,
		font: "Arial",
		color: "000000",
		size: "26",
	    underline: {
	        type: UnderlineType.SINGLE,
	        color: "000000",
	    },			    
	});	

	const routeRoute = new TextRun({
		text: `${item.countryDeparture}, ${item.cityDeparture} - ${item.countryArrival}, ${item.cityArrival}`,
		font: "Arial",
		color: "000000",
		size: "26"	    
	});

	const routeSeaRoute = new TextRun({
		text: `${item.countryDeparture}, ${item.cityDeparture} - ${item.countryArrival}, ${item.terminalArrival}, ${item.terminalCityArrival} (Морская перевозка)`,
		font: "Arial",
		color: "000000",
		size: "26"	    
	});

	const routeSeaTrainRoute = new TextRun({
		text: `${item.countryArrival}, ${item.terminalCityArrival} - ${item.countryArrival}, ${item.cityArrival} (Ж/Д перевозка)`,
		font: "Arial",
		color: "000000",
		size: "26"	    
	});

	const routeStraightTrainRoute = new TextRun({
		text: `${item.countryDeparture}, ${item.cityDeparture} - ${item.countryArrival}, ${item.cityArrival} (Ж/Д перевозка)`,
		font: "Arial",
		color: "000000",
		size: "26"	    
	});	

		
	const terminalText = new TextRun({
		text: `Терминал:`,
		font: "Arial",
		color: "000000",
		size: "26",
	    underline: {
	        type: UnderlineType.SINGLE,
	        color: "000000",
	    },			    
	});	

	const terminalTerminal = new TextRun({
		text: `${item.terminalArrival}, ${item.terminalCityArrival}`,
		font: "Arial",
		color: "000000",
		size: "26"	    
	});




	const children = [
        new Paragraph({
            children: [logo],
            alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
            children: [],
        }),
        new Paragraph({
            children: [],
        }),
        new Paragraph({
            children: [],
        }),                
        new Paragraph({
            children: [header],
            alignment: AlignmentType.START,
        }),	
        new Paragraph({
            children: [],
        }),                    		            
        new Paragraph({
            children: [expireText],
            alignment: AlignmentType.START,
        }),
        new Paragraph({
            children: [expireDate],
            alignment: AlignmentType.START,
        }),
        new Paragraph({
            children: [],
        }),                
        new Paragraph({
            children: [routeText],
            alignment: AlignmentType.START,
        }),
        new Paragraph({
            children: [routeRoute],
            alignment: AlignmentType.START,
        }),
        new Paragraph({
            children: [],
        })                
	];

	if (item.terminalArrival){		
		children.push(new Paragraph({
	        children: [terminalText],
	        alignment: AlignmentType.START,
        }));
		children.push(new Paragraph({
	        children: [terminalTerminal],
	        alignment: AlignmentType.START,
        })); 
		children.push(
	        new Paragraph({
	            children: [],
	        })	
		);

		if ([item.customsVtt, item.customsGtd].some(item=>item)){
			children.push(new Paragraph({
		        children: [customsText],
		        alignment: AlignmentType.START,
	        }));
	        if (item.customsVtt){
				children.push(new Paragraph({
			        children: [customsOption("ВТТ")],
			        alignment: AlignmentType.START,
		        }));	        	
	        }  
			if (item.customsGtd){
				children.push(new Paragraph({
			        children: [customsOption("ГТД")],
			        alignment: AlignmentType.START,
		        }));
			} 
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
		}
	}


	children.push(
        new Paragraph({
            children: [subHeader],
            alignment: AlignmentType.START,
        })
	);
	children.push(
        new Paragraph({
            children: [],
        })	
	);	

	if (item.typetransport){ //sea
		if (item.cityArrival === item.terminalCityArrival){
			children.push(
		        new Paragraph({
		            children: [routeSeaRoute],
		            alignment: AlignmentType.START,
		        })
			);
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
			children.push(
				new Table({
				    rows: [
				        new TableRow({
				            children: [
				                new TableCell({
				                    children: [tableCell("Тип контейнера")],
				                    width: { size: 30, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,													                    
				                }),
				                new TableCell({
				                    children: [tableCell("Количество")],
				                    width: { size: 20, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Дней в пути")],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Ставка в $")],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),				                				                				                
				            ],
				        }),
				        new TableRow({
				            children: [
				                new TableCell({
				                    children: [tableCell({twenty:"20'", forty:"40'", fortyhc:"40HC"}[item.container])],
				                    width: { size: 30, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("1")],
				                    width: { size: 20, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell(item.seaTransfer.toString())],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell(item.seaCost.toString())],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),				                				                				                
				            ],
				            height: { value: 450, rule: HeightRule.EXACT }
				        }),				        
				    ],
				    width: { size: 100, type: WidthType.PERCENTAGE }
				})
			);
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
		} else {

			children.push(
		        new Paragraph({
		            children: [routeSeaRoute],
		            alignment: AlignmentType.START,
		        })
			);
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
			children.push(
				new Table({
				    rows: [
				        new TableRow({
				            children: [
				                new TableCell({
				                    children: [tableCell("Тип контейнера")],
				                    width: { size: 30, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Количество")],
				                    width: { size: 20, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Дней в пути")],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Ставка в $")],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),				                				                				                
				            ],
				        }),
				        new TableRow({
				            children: [
				                new TableCell({
				                    children: [tableCell({twenty:"20'", forty:"40'", fortyhc:"40HC"}[item.container])],
				                    width: { size: 30, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("1")],
				                    width: { size: 20, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell(item.seaTransfer.toString())],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell(item.seaCost.toString())],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),				                				                				                
				            ],
				            height: { value: 450, rule: HeightRule.EXACT }
				        }),				        
				    ],
				    width: {size: 100, type: WidthType.PERCENTAGE}
				})
			);
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
			children.push(
		        new Paragraph({
		            children: [routeSeaTrainRoute],
		            alignment: AlignmentType.START,
		        })
			);
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
			children.push(
				new Table({
				    rows: [
				        new TableRow({
				            children: [
				                new TableCell({
				                    children: [tableCell("Тип контейнера")],
				                    width: { size: 30, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Количество")],
				                    width: { size: 20, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Дней в пути")],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Ставка в ₽")],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),				                				                				                
				            ],
				        }),
				        new TableRow({
				            children: [
				                new TableCell({
				                    children: [tableCell({twenty:"20'", forty:"40'", fortyhc:"40HC"}[item.container])],
				                    width: { size: 30, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("1")],
				                    width: { size: 20, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell(item.trainTransfer.toString())],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell(item.trainCost.toString())],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),				                				                				                
				            ],
				            height: { value: 450, rule: HeightRule.EXACT }
				        }),				        
				    ],
				    width: {size: 100, type: WidthType.PERCENTAGE}
				})
			);
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
		}
	} else {
			children.push(
		        new Paragraph({
		            children: [routeStraightTrainRoute],
		            alignment: AlignmentType.START,
		        })
			);
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
			children.push(
				new Table({
				    rows: [
				        new TableRow({
				            children: [
				                new TableCell({
				                    children: [tableCell("Тип контейнера")],
				                    width: { size: 30, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,													                    
				                }),
				                new TableCell({
				                    children: [tableCell("Количество")],
				                    width: { size: 20, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Дней в пути")],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("Ставка в ₽")],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),				                				                				                
				            ],
				        }),
				        new TableRow({
				            children: [
				                new TableCell({
				                    children: [tableCell({twenty:"20'", forty:"40'", fortyhc:"40HC"}[item.container])],
				                    width: { size: 30, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell("1")],
				                    width: { size: 20, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell(item.trainTransfer.toString())],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),
				                new TableCell({
				                    children: [tableCell(item.trainCost.toString())],
				                    width: { size: 25, type: WidthType.PERCENTAGE },
				                    verticalAlign: VerticalAlign.CENTER,
				                }),				                				                				                
				            ],
				        }),				        
				    ],
				    width: { size: 100, type: WidthType.PERCENTAGE }
				})
			);
			children.push(
		        new Paragraph({
		            children: [],
		        })	
			);			
	}


	children.push(...textFrame("Условия оплаты", item.paymentterms));
	children.push(...textFrame("Условия пользования контейнером", item.conditionscarriage));
	children.push(
        new Paragraph({
            children: [],
        })	
	);
	children.push(
        new Paragraph({
            children: [new TextRun({
				text: `ООО «МойДекларант»`,
				font: "Arial",
				color: "000000",
				size: "20"	    
				})
            ],
        }),
        new Paragraph({
            children: [new TextRun({
				text: `108811, г.Москва, Киевское Шоссе 22-й километр, БЦ Румянцево, длвд,4, стр.5,этаж5/БЛОК Е`,
				font: "Arial",
				color: "000000",
				size: "20"	    
				})
            ],
        }),
        new Paragraph({
            children: [new TextRun({
				text: `офис 502Е-21 `,
				font: "Arial",
				color: "000000",
				size: "20"	    
				})
            ],
        }),
        new Paragraph({
            children: [new TextRun({
				text: `Тел. +7 (499) 398 86 93                       Почта:  sales@mydeclarant.ru`,
				font: "Arial",
				color: "000000",
				size: "20"	    
				})
            ],
            alignment: AlignmentType.CENTER,
        }),	        	        	        	
	);	


	const doc = new Document({
	    sections: [{
	        children: children,
	    }]
	});

    Packer.toBlob(doc).then(blob => {      
      saveAs(blob, `${item.cityDeparture}_${item.cityArrival}_${item.docId}.docx`);     
    });
}

/*`Информация по предложению № ${item.docId}:`
`Срок действия предложения до ${makeDate(item.expireDate)}:`

`Маршрут: ${item.countryDeparture}, ${item.cityDeparture} - Россиия,Москва Терминал - ВМТП`
${item.countryArrival}, ${item.cityArrival} ${item.terminalCityArrival} ${item.terminalArrival}*/



/*{
    "_id": "64b86389feca26d0342f9c16",
    "docId": 240,
    "companyProfile": "64aedcf0df2450380e159f71",
    "companyName": "ООО \"СИСТЕМА ПБО\"",
    "companyOffer": "Офер что надо",
    "expireDate": "2023-07-29T21:00:00.000Z",
    "conditionscarriage": "Условия перевозки",
    "paymentterms": "Условия оплаты",
    "typetransport": 0,
    "container": "twenty",
    "cityDeparture": "Шанхай",
    "countryDeparture": "Китай",
    "terminalArrival": "ВМТП",
    "cityArrival": "Санкт-Петербург",
    "countryArrival": "Россия",
    "seaCost": 100,
    "trainCost": 400,
    "seaTransfer": 12,
    "trainTransfer": 14,
    "customsVtt": true,
    "customsGtd": true
}*/