import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import Edit from '../../assets/Edit.svg';
import Gear from '../../assets/Gear.svg';
import GearActive from '../../assets/GearActive.svg';

import styles from './PersonalInput.module.scss';

export const PersonalInput = ({
  placeholder,
  value,
  valueToShow,
  type,
  label,
  onChange,
  editable,
  settingsUrl,
  permitEdit = true,
  modal: Modal,
}) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [gearActive, setGearActive] = useState(false);

  const editIcon = (
    <img
      src={Edit}
      alt="edit"
      onClick={() => {
        if (Modal) {
          setShowModal(true);
        } else if (editable) {
          setTimeout(() => inputRef.current.focus(), 100);
          setEdit(true);
        }
      }}
      className={styles.EditIcon}
    />
  );
  const gearIcon = (
    <img
      src={gearActive ? GearActive : Gear}
      onMouseOver={() => setGearActive(true)}
      onMouseLeave={() => setGearActive(false)}
      alt="gear"
      className={classnames({ [styles['GearIcon--rotate']]: gearActive })}
      onClick={() => navigate(settingsUrl)}
    />
  );

  return (
    <>
      <div className={styles.InputWrapper}>
        <div className={styles.Label}>
          {label}
          <div className={styles.Label__IconGroup}>
            {permitEdit && editIcon}
            {settingsUrl && gearIcon}
          </div>
        </div>
        {editable ? (
          <input
            disabled={!edit}
            ref={inputRef}
            className={styles.Value}
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
          />
        ) : (
          <span className={styles.Value}>{valueToShow || value}</span>
        )}
      </div>
      {Modal && <Modal open={showModal} onClose={() => setShowModal(false)} />}
    </>
  );
};
