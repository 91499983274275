import {
  fetchCompletedPayments,
  fetchCurrentPayments,
  getUserPayments,
} from '../../api';
import { FILTERS } from '../../consts';
import { updateFilters } from '../filters';

export const LOAD_ITEMS_REQUEST = '@paymentRequests/LOAD_ITEMS_REQUEST';
export const LOAD_ITEMS_SUCCESS = '@paymentRequests/LOAD_ITEMS_SUCCESS';
export const LOAD_ITEMS_FAILURE = '@paymentRequests/LOAD_ITEMS_FAILURE';

export const loadItemsRequest = () => ({
  type: LOAD_ITEMS_REQUEST,
});

export const loadItemsSuccess = (payload) => ({
  type: LOAD_ITEMS_SUCCESS,
  payload,
});

export const loadItemsFailure = (payload) => ({
  type: LOAD_ITEMS_FAILURE,
  payload,
});

export const loadCurrent = () => async (dispatch) => {
  try {
    dispatch(loadItemsRequest());
    const { count, items, success } = await fetchCurrentPayments();
    if (success) {
      dispatch(loadItemsSuccess({ count, items }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const loadCompleted = (filters) => async (dispatch) => {
  try {
    dispatch(loadItemsRequest());
    const { count, items, success } = await fetchCompletedPayments(filters);
    if (success) {
      dispatch(loadItemsSuccess({ count, items }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const applyFilters = (filters) => async (dispatch) => {
  dispatch(loadItemsRequest());
  try {
    dispatch(
      updateFilters({ key: FILTERS.PAYMENTS_COMPLETED, value: filters })
    );
    const { count, items, success } = await fetchCompletedPayments(filters);
    if (success) {
      dispatch(loadItemsSuccess({ count, items }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const loadUserPayments = (filters) => async (dispatch) => {
  try {
    dispatch(loadItemsRequest());
    const { count, items, success } = await getUserPayments(filters);
    if (success) {
      dispatch(loadItemsSuccess({ count, items }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const applyUserPaymentsFilters = (filters) => async (dispatch) => {
  dispatch(loadItemsRequest());
  try {
    dispatch(
      updateFilters({ key: FILTERS.PAYMENTS_USER_HISTORY, value: filters })
    );
    const { count, items, success } = await getUserPayments(filters);
    if (success) {
      dispatch(loadItemsSuccess({ count, items }));
    }
  } catch (error) {
    console.log(error);
  }
};
