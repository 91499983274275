import Facebook from '../assets/Facebook.svg';
import Instagram from '../assets/Instagram.svg';
import Telegram from '../assets/Telegram.svg';

export const SOCIALS = [
  {
    src: Instagram,
    alt: 'instagram',
    href: 'https://www.instagram.com/mydeclarant.ru/',
  },
  {
    src: Facebook,
    alt: 'facebook',
    href: 'https://www.facebook.com/mydeclarant.ru',
  },
  { src: Telegram, alt: 'telegram', href: 'https://t.me/mylogist' },
];
