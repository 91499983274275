import {
	getCompanies,
	fetchProfile} from '../../api';
import {
	fetchItemsRequest,
	fetchItemsSuccess,
	fetchItemsFailure,
  loadCompanies} from '../companies';
import {
loadProfileRequest,
loadProfileFailure,
loadProfileSuccess} from '../profile';

export const loadProfileAndVerifCompanies = async (dispatch, getState) => {	
	dispatch(fetchItemsRequest());
	dispatch(loadProfileRequest());

  try {
    const profile = await fetchProfile();
    dispatch(loadProfileSuccess(profile));
  } catch (error) {
      dispatch(loadProfileFailure(error));
    }

  dispatch(loadCompanies);

};

    
