import {
  getAllTransactions,
  getTransactionsByProfileId,
  getUserAccounts,
  getSystemAccounts,
} from '../../api/transactions';
import { FILTERS } from '../../consts';
import { updateFilters, getAllTransactionsFilters } from '../filters';

export const LOAD_TRANSACTIONS_REQUEST =
  '@transactions/LOAD_TRANSACTIONS_REQUEST';
export const LOAD_TRANSACTIONS_SUCCESS =
  '@transactions/LOAD_TRANSACTIONS_SUCCESS';
export const LOAD_TRANSACTIONS_FAILURE =
  '@transactions/LOAD_TRANSACTIONS_FAILURE';
export const LOAD_ACCOUNTS_REQUEST = '@transactions/LOAD_ACCOUNTS_REQUEST';
export const LOAD_ACCOUNTS_SUCCESS = '@transactions/LOAD_ACCOUNTS_SUCCESS';
export const LOAD_ACCOUNTS_FAILURE = '@transactions/LOAD_ACCOUNTS_FAILURE';
export const LOAD_SYSTEM_ACCOUNTS_REQUEST =
  '@transactions/LOAD_SYSTEM_ACCOUNTS_REQUEST';
export const LOAD_SYSTEM_ACCOUNTS_SUCCESS =
  '@transactions/LOAD_SYSTEM_ACCOUNTS_SUCCESS';
export const LOAD_SYSTEM_ACCOUNTS_FAILURE =
  '@transactions/LOAD_SYSTEM_ACCOUNTS_FAILURE';

const loadTransactionsRequest = () => ({
  type: LOAD_TRANSACTIONS_REQUEST,
});

const loadTransactionsSuccess = (payload) => ({
  type: LOAD_TRANSACTIONS_SUCCESS,
  payload,
});

const loadTransactionsFailure = (payload) => ({
  type: LOAD_TRANSACTIONS_FAILURE,
  payload,
});

const loadAccountsRequest = () => ({
  type: LOAD_ACCOUNTS_REQUEST,
});

const loadAccountsSuccess = (payload) => ({
  type: LOAD_ACCOUNTS_SUCCESS,
  payload,
});

const loadAccountsFailure = (payload) => ({
  type: LOAD_ACCOUNTS_FAILURE,
  payload,
});

const loadSystemAccountsRequest = () => ({
  type: LOAD_SYSTEM_ACCOUNTS_REQUEST,
});

const loadSystemAccountsSuccess = (payload) => ({
  type: LOAD_SYSTEM_ACCOUNTS_SUCCESS,
  payload,
});

const loadSystemAccountsFailure = (payload) => ({
  type: LOAD_SYSTEM_ACCOUNTS_FAILURE,
  payload,
});

export const loadAllTransactions = () => async (dispatch, getState) => {
  dispatch(loadTransactionsRequest());
  try {
    const state = getState();
    const filters = getAllTransactionsFilters(state);
    const data = await getAllTransactions(filters);

    dispatch(loadTransactionsSuccess(data));
  } catch (error) {
    loadTransactionsFailure(error?.data);
  }
};

export const applyFilters = (filters) => async (dispatch) => {
  dispatch(loadTransactionsRequest());
  try {
    dispatch(updateFilters({ key: FILTERS.ALL_TRANSACTIONS, value: filters }));
    const transactions = await getAllTransactions(filters);
    const sysAccounts = await getSystemAccounts(filters);

    dispatch(loadSystemAccountsSuccess(sysAccounts));
    dispatch(loadTransactionsSuccess(transactions));
  } catch (error) {
    loadTransactionsFailure(error);
  }
};

export const loadSystemAccounts = () => async (dispatch) => {
  try {
    dispatch(loadSystemAccountsRequest);
    const data = await getSystemAccounts();

    dispatch(loadSystemAccountsSuccess(data));
  } catch (error) {
    loadSystemAccountsFailure(error?.data);
  }
};

export const loadTransactions = (id, filters) => async (dispatch) => {
  dispatch(loadTransactionsRequest());
  try {
    const data = await getTransactionsByProfileId(id, filters);

    dispatch(loadTransactionsSuccess(data));
  } catch (error) {
    loadTransactionsFailure(error?.data);
  }
};

export const applyFilter = (id, filtersKey, filters) => async (dispatch) => {
  dispatch(loadTransactionsRequest());
  try {
    dispatch(updateFilters({ key: filtersKey, value: filters }));
    const data = await getTransactionsByProfileId(id, filters);

    dispatch(loadTransactionsSuccess(data));
  } catch (error) {
    loadTransactionsFailure(error);
  }
};

export const loadAccounts = (filters) => async (dispatch) => {
  dispatch(loadAccountsRequest());
  try {
    const data = await getUserAccounts(filters);

    dispatch(loadAccountsSuccess(data));
  } catch (error) {
    loadAccountsFailure(error?.data);
  }
};

export const applyAccountsFilters = (filtersKey, filters) => async (
  dispatch
) => {
  dispatch(loadAccountsRequest());
  try {
    dispatch(updateFilters({ key: filtersKey, value: filters }));
    const data = await getUserAccounts(filters);

    dispatch(loadAccountsSuccess(data));
  } catch (error) {
    loadAccountsFailure(error);
  }
};
