import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PackTrainRoutes.module.sass';
import classnames from 'classnames';

import { Input } from './Input';
import {
	getTrainTrainView,
	recoverTrainTrainRoute,
	changeTrainTrainView } from '../../../../store/makeOffers';

export const PackTrainRoutes = () => {

	const dispatch = useDispatch();
	const listStraightTrainRoutes = useSelector(getTrainTrainView);

	const handlerClick = (event) => {
		let element;
		if(element = event.target.closest("."+classnames(styles.reject))){
			dispatch(recoverTrainTrainRoute(element.dataset.idx, element.dataset.country));
		}
	}

	const changeValueInput = (index, country, field, state) => {
		dispatch(changeTrainTrainView(index, country, field, state));
	}

	return (
		<div className={classnames(styles.wrap_pack_routes)}>
			{!Boolean(listStraightTrainRoutes.length) && !Boolean(listStraightTrainRoutes.length) && (<div className={classnames(styles.routes_info)}>
				Чтобы создать предложения, воспользуйтесь панелью справа. 
			</div>)}		
			{Boolean(listStraightTrainRoutes.length) && (<ul className={classnames(styles.pack_routes_train)} onClick={handlerClick}>
				{Boolean(listStraightTrainRoutes.length) && listStraightTrainRoutes.map(({index, country, departure, arrival, params})=>(
					<li className={classnames(styles.route)}>
						<div className={classnames(styles.reject)} data-idx={index} data-country={country}></div>
						<div className={classnames(styles.pack_route_descr)}>{`${departure.city} - ${arrival.city}`}</div>
						<div className={classnames(styles.wrap_con_and_cur_train)}>
							<div className={classnames(styles.wrap_con_train)}>
								<Input 
									inputName="20'"
									placeHolder="Цена"
									rule="number"
									inputValue={params.twenty}
									setStateCb={changeValueInput.bind(null, index, country, "twenty")}			
								/>
								<Input 
									inputName="40'"
									placeHolder="Цена"
									rule="number"
									inputValue={params.forty}
									setStateCb={changeValueInput.bind(null, index, country, "forty")}			
								/>	
								<Input 
									inputName="40HC"
									placeHolder="Цена"
									rule="number"
									inputValue={params.fortyhc}
									setStateCb={changeValueInput.bind(null, index, country, "fortyhc")}			
								/>
								<div className={classnames(styles.route_currency_train)}>$</div>	
							</div>
							<div className={classnames(styles.wrap_con_train)}>
								<div className={classnames(styles.pack_containers)}>
									<div className={classnames(styles.descr_input)}>Дней в пути</div>
									<Input
										placeHolder="Дней"
										rule="number"
										inputValue={params.duration}
										setStateCb={changeValueInput.bind(null, index, country, "duration")}			
									/>
								</div>												
							</div>												
						</div>										
					</li>
				))}									
			</ul>)}
		</div>
	);

}