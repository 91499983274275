import { DEFAULT_FILTERS, FILTERS } from '../../consts';

import { UPDATE_FILTERS, RESET_FILTERS } from './actions';

const initialState = {
  [FILTERS.DECLARANTS_TO_VERIFY]: DEFAULT_FILTERS.DECLARANTS_TO_VERIFY,
  [FILTERS.ALL_USERS]: DEFAULT_FILTERS.ALL_USERS,
  [FILTERS.DECLARANTS_ALL_USERS]: DEFAULT_FILTERS.DECLARANTS_ALL_USERS,
  [FILTERS.DECLARANTS_ACCREDITED_USERS]: DEFAULT_FILTERS.DECLARANTS_ACCREDITED_USERS,
  [FILTERS.LOGISTS_ALL_USERS]:DEFAULT_FILTERS.LOGISTS_ALL_USERS,
  [FILTERS.LOGISTS_ACCREDITED_USERS]: DEFAULT_FILTERS.LOGISTS_ACCREDITED_USERS,
  [FILTERS.CLIENTS_USERS_PEOPLE]: DEFAULT_FILTERS.CLIENTS_USERS_PEOPLE,
  [FILTERS.PARTNERS]: DEFAULT_FILTERS.PARTNERS,
  [FILTERS.CLIENTS_USERS_COMPANIES]: DEFAULT_FILTERS.CLIENTS_USERS_COMPANIES,
  [FILTERS.REQUESTS_IN_PROGRESS]: DEFAULT_FILTERS.REQUESTS_IN_PROGRESS,
  [FILTERS.REQUESTS_COMPLETED]: DEFAULT_FILTERS.REQUESTS_COMPLETED,
  [FILTERS.REQUESTS_POOL]: DEFAULT_FILTERS.REQUESTS_POOL,
  [FILTERS.REQUESTS_LIST]: DEFAULT_FILTERS.REQUESTS_LIST,
  [FILTERS.REQUESTS_CREATED_LIST]: DEFAULT_FILTERS.REQUESTS_CREATED_LIST,
  [FILTERS.REQUESTS_CLIENT_COMPLETED]: DEFAULT_FILTERS.REQUESTS_CLIENT_COMPLETED,
  [FILTERS.REQUESTS_PARTNER_COMPLETED]: DEFAULT_FILTERS.REQUESTS_PARTNER_COMPLETED,
  [FILTERS.ALL_REQUESTS]: DEFAULT_FILTERS.ALL_REQUESTS,
  [FILTERS.ALL_REQUESTS_DONE]: DEFAULT_FILTERS.ALL_REQUESTS_DONE,
  [FILTERS.ALL_REQUESTS_IN_PROGRESS]: DEFAULT_FILTERS.ALL_REQUESTS_IN_PROGRESS,
  [FILTERS.ACCOUNTS_DECLARANTS]: DEFAULT_FILTERS.ACCOUNTS_DECLARANTS,
  [FILTERS.ACCOUNTS_PARTNERS]: DEFAULT_FILTERS.ACCOUNTS_PARTNERS,
  [FILTERS.ACCOUNTS_CLIENTS]: DEFAULT_FILTERS.ACCOUNTS_CLIENTS,
  [FILTERS.ACCOUNTS_LOGISTS]: DEFAULT_FILTERS.ACCOUNTS_LOGISTS,
  [FILTERS.ACCOUNT_TRANSACTIONS]: DEFAULT_FILTERS.ACCOUNT_TRANSACTIONS,
  [FILTERS.ALL_TRANSACTIONS]: DEFAULT_FILTERS.ALL_TRANSACTIONS,
  [FILTERS.PAYMENTS_COMPLETED]: DEFAULT_FILTERS.PAYMENTS_COMPLETED,
  [FILTERS.PAYMENTS_USER_HISTORY]: DEFAULT_FILTERS.PAYMENTS_USER_HISTORY,
  [FILTERS.ALL_MY_LOGISTICS_REQUESTS]: DEFAULT_FILTERS.ALL_MY_LOGISTICS_REQUESTS,
  [FILTERS.ALL_MY_LOGISTICS_IN_WORK]: DEFAULT_FILTERS.ALL_MY_LOGISTICS_IN_WORK,
  [FILTERS.ALL_MY_LOGISTICS_ARCHIVE]: DEFAULT_FILTERS.ALL_MY_LOGISTICS_ARCHIVE,
  [FILTERS.MY_OFFERS_PACKS]: DEFAULT_FILTERS.MY_OFFERS_PACKS, 
  [FILTERS.MY_OFFERS_FROM_PACK]: DEFAULT_FILTERS.MY_OFFERS_FROM_PACK,
  [FILTERS.CLIENT_OFFERS]: DEFAULT_FILTERS.CLIENT_OFFERS,  
};

export const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          ...action.payload.value,
        },
      };
    case RESET_FILTERS:
      return {
        ...state, [action.filtersKey]: initialState[action.filtersKey] 
      };
    default:
      return state;
  }
};
