export const getMakeOffers = (state) => state.makeOffers;
export const getMakeOffersSea = (state) => state.makeOffers.sea;
export const getMakeOffersTrain = (state) => state.makeOffers.train;
export const getPanelState = (state) => state.makeOffers.panelState;
export const getSeaName = (state) => state.makeOffers.sea.name;
export const getSeaExpire = (state) => state.makeOffers.sea.expireDate;
export const getSeaTerminal = (state) => state.makeOffers.sea.terminalArrival;
export const getSeaCityArrival = (state) => state.makeOffers.sea.cityArrival;
export const getSeaCustoms = (state) => state.makeOffers.sea.customs;
export const getSeaCustomsCorrect = (state) => state.makeOffers.sea.customs.correct;
export const getSeaCustomVtt = (state) => state.makeOffers.sea.customs.vtt;
export const getSeaCustomGtd = (state) => state.makeOffers.sea.customs.gtd;
export const getSeaSubPanel = (state) => state.makeOffers.sea.subPanelState;
export const getSeaCountryDeparture = (state) => state.makeOffers.sea.seaDeparture.countryDeparture;
export const getSeaCountryArrival = (state) => state.makeOffers.sea.trainArrival.countryArrival;
export const getSeaStationArrivalList = (country) => (state) => state.makeOffers.sea.trainArrival.stationArrival[country];
export const getSeaPortDepartureList = (country) => (state) => state.makeOffers.sea.seaDeparture.portDeparture[country];
export const getSeaConditionsCarriage = (state) => state.makeOffers.sea.conditionscarriage;
export const getSeaField = (field) => (state) => state.makeOffers.sea[field];
export const getSeaPaymentTerms = (state) => state.makeOffers.sea.paymentterms;
export const getSeaSeaView = (state) => state.makeOffers.sea.seaView;
export const getSeaTrainView = (state) => state.makeOffers.sea.trainView;

export const getSeaTrainViewByIdx = (idx, countryList) => (state) => {
	if (state.makeOffers.sea.trainView.length){
		return state.makeOffers.sea.trainView.filter(({index, country})=>(Number(index) === Number(idx) && countryList === country))?.[0];
	} else return null;
}
export const getSeaSeaViewByIdx = (idx, countryList) => (state) => {
	if (state.makeOffers.sea.seaView.length){
		return state.makeOffers.sea.seaView.filter(({index, country})=>(Number(index) === Number(idx) && countryList === country))?.[0];
	} else return null;
}

export const getTrainName = (state) => state.makeOffers.train.name;
export const getTrainExpire = (state) => state.makeOffers.train.expireDate;
export const getTrainCityArrival = (state) => state.makeOffers.train.cityArrival;
export const getTrainCountryDeparture = (state) => state.makeOffers.train.countryDeparture;
export const getTrainConditionsCarriage = (state) => state.makeOffers.train.conditionscarriage;
export const getTrainPaymentTerms = (state) => state.makeOffers.train.paymentterms;
export const getTrainField = (field) => (state) => state.makeOffers.train[field];
export const getTrainTrainView = (state) => state.makeOffers.train.trainView;
export const getTrainStationDepartureList = (country) => (state) => state.makeOffers.train.stationDeparture[country];

export const getTrainTrainViewByIdx = (idx, countryList) => (state) => {
	if (state.makeOffers.train.trainView.length){
		return state.makeOffers.train.trainView.filter(({index, country})=>(Number(index) === Number(idx) && countryList === country))?.[0];
	} else return null;
}

export const getLoadingState = (state) => state.makeOffers.loading;
