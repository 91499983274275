import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import {
  getUsersCount,
  getLoading,
  getUsers,
  loadCompanies,
  applyFilters,
} from '../../store/users';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { getClientsUsersCompaniesFilters } from '../../store/filters';
import { FILTERS } from '../../consts';

const CLIENTS_USERS_COMPANIES_HEADERS = [
  {
    label: 'ID',
    value: '_id',
    sortable: true,
    render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
  },
  {
    label: '№',
    value: 'number',
    render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
  },
  {
    label: 'Наименование',
    value: 'name',
    renderItem: true,
  },
  {
    label: 'Реквизиты',
    value: 'details',
    renderItem: true,
    render: (item) => (
      <div style={{ width: '10rem', textAlign: 'left', paddingLeft: '1.5rem' }}>
        <div>КПП {item.kpp}</div>
        <div>ИНН {item.itn}</div>
        <div> ОГРН {item.ogrn}</div>
      </div>
    ),
  },
  {
    label: 'Контакты',
    value: 'contacts',
    renderItem: true,
    render: (item) => (
      <div>
        <div>{item.phone}</div>
        <div>{item.email}</div>
      </div>
    ),
  },
  {
    label: 'Регистратор',
    value: 'registrar',
    renderItem: true,
    render: (item) => (
      <span style={{ color: '#00A4FA' }}>
        {!item ||
        !item.profile ||
        (!item.profile?.name && !item.profile?.surname)
          ? 'нет данных'
          : `${item.profile?.name} ${item.profile?.surname}`}
      </span>
    ),
  },
  {
    label: 'Дата регистрации',
    value: 'createdAt',
    sortable: true,
    render: (value) =>
      value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : 'нет данных',
  },
  {
    label: 'Верификация',
    value: 'verification',
    render: (value) => (value ? 'Пройдена' : 'нет данных'),
  },
];

export const ClientsUsersCompanies = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const companies = useSelector(getUsers);
  const filters = useSelector(getClientsUsersCompaniesFilters);
  const countItems = useSelector(getUsersCount);

  const filtersKey = FILTERS.CLIENTS_USERS_COMPANIES;

  useEffect(() => {
    dispatch(loadCompanies(filters));
  }, [dispatch, filters]);

  return (
    <div>
      {companies?.length ? (
        <Table
          loading={loading}
          items={companies}
          headers={CLIENTS_USERS_COMPANIES_HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) =>
            dispatch(applyFilters(filtersKey, filters))
          }
        />
      ) : (
        <Message type="info">
          В системе пока нет сведений о компаниях клиентах
        </Message>
      )}
    </div>
  );
};
