import React, { useEffect } from 'react';
import { verifyEmail } from '../../../api';
import { LoadingPage } from '../../hocs/withLoading/LoadingPage';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../consts';

export const VerifyEmailByToken = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      verifyEmail({ token })
        .then((_) => {
          navigate(ROUTES.FULL_PROFILE_PERSONAL);
        })
        .catch(console.log);
    }
  }, [navigate, token]);

  return <LoadingPage withWrapper />;
};
