import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TrainTab.module.sass';
import classnames from 'classnames';

import { Input } from './Input';
import { CreatePackBtn } from './CreatePackBtn';
import { SimpleDropdownList } from '../../../shared/DropdownList';
import { List } from './List';
import {
	getTrainName,
	getTrainExpire,
	getTrainCityArrival,
	changeTrainName,
	changeTrainExpire,
	changeTrainCity,
	arrivalStraightStationList,
	departureStationList,
	departureTrainCountryList,
	changeTrainCountryDeparture,
	getTrainStationDepartureList,
	getTrainCountryDeparture,
	sendTrainOffers} from '../../../../store/makeOffers';

export const TrainTab = () => {

	const dispatch = useDispatch();
	const name = useSelector(getTrainName);
	const expire = useSelector(getTrainExpire);
	const city = useSelector(getTrainCityArrival);
	const countryDeparture = useSelector(getTrainCountryDeparture);
	const country = countryDeparture.datatype;
	const stationDepartureList = useSelector(getTrainStationDepartureList(country));

	return (
		<>
		<div className={classnames(styles.column_inputs)}>
			<Input 
				inputName="Имя предложений"
				placeHolder="Название"
				rule="russian"
				inputValue={name}
				setStateCb={(state)=>{dispatch(changeTrainName(state))}}			
			/>
			<Input 
				inputName="Действует до"
				placeHolder="Дата"
				rule= "date"
				pin="schedule"
				inputValue={expire}
				setStateCb={(state)=>{dispatch(changeTrainExpire(state))}}			
			/>
			<SimpleDropdownList
				nameInput="Город прибытия"
				list={arrivalStraightStationList.russia}
				proposal="Выберите город"
				width="100%"
				stepsData={city}
				setStateCb={(state)=>{dispatch(changeTrainCity(state))}}
				name="cityTrainArrival"							
			/>
			<div className={classnames(styles.wrap_sub_panel)}>
				<div className={classnames(styles.column_inputs)}>
					<SimpleDropdownList
						list={departureTrainCountryList}
						proposal="Выберите страну"
						width="100%"
						stepsData={countryDeparture}
						setStateCb={(state)=>{dispatch(changeTrainCountryDeparture(state))}}
						name="countryTrainDepart"							
					/>
					<List 
						inputName="Города отправления"
						list={stationDepartureList}
						etalonList={departureStationList[country]}
						etalonName="departStation"
						country={country}
					/>			
				</div>
			</div>
			<CreatePackBtn setStateCb={()=>{dispatch(sendTrainOffers)}}/>
		</div>		
		</>
	);

}




