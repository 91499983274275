import React from 'react';
import { useDispatch } from 'react-redux';

import { updateValueRequest } from '../../store/profile';
import { UploadButton } from '../shared/Button';
import { Avatar } from '../shared/Avatar';

import styles from './PersonalPhoto.module.scss';

export const PersonalPhoto = () => {
  const dispatch = useDispatch();

  const handleUpload = (value) =>
    dispatch(updateValueRequest({ key: 'photo', value: value[0] }));

  return (
    <div className={styles.PersonalPhoto}>
      <div className={styles.PersonalPhoto__ImageWrapper}>
        <Avatar size="100%" />
      </div>
      <UploadButton id="personalPhoto" onUpload={handleUpload}>
        Изменить фото
      </UploadButton>
    </div>
  );
};
