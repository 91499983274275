import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Routes, Route } from 'react-router-dom';

import { ROUTES } from '../../../consts';
import { Tabs } from '../../shared/Tabs';
import { PoolAdmin } from './PoolAdmin';
import { AllRequests } from './AllRequests';
import { AllRequestsDone } from './AllRequestsDone';
import { AllRequestsInProgress } from './AllRequestsInProgress';
import { RequestAdminView } from './RequestAdminView';
import { getCount } from '../../../store/requests';

import styles from './RequestsAdmin.module.scss';

const Header = () => {
  const count = useSelector(getCount);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <div className={styles.TitlePart}>
          <div className={styles.TitlePart__Title}>Заявки</div>
          <div>Количество &ndash; {count}</div>
        </div>
        <Tabs
          tabs={[
            {
              title: 'Все',
              isPart: true,
              route: ROUTES.FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS,
              visible: true,
            },
            {
              title: 'Завершенные',
              route: ROUTES.FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS_DONE,
              visible: true,
            },
            {
              title: 'В работе',
              route: ROUTES.FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS_IN_PROGRESS,
              visible: true,
            },
            {
              title: 'Новые',
              route: ROUTES.FULL_REQUESTS_DECLARATIONS_POOL,
              visible: true,
            },
          ]}
        />
      </div>
    </div>
  );
};

export const RequestsAdmin = () => {
  return (
    <main>
      <Routes>
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_ALL_REQUESTS}
          Component={() => (
            <>
              <Header />
              <AllRequests />
            </>
          )}
          
        />
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_ALL_REQUESTS_DONE}
          Component={() => (
            <>
              <Header />
              <AllRequestsDone />
            </>
          )}
          
        />
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_ALL_REQUESTS_IN_PROGRESS}
          Component={() => (
            <>
              <Header />
              <AllRequestsInProgress />
            </>
          )}
          
        />
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_POOL}
          Component={() => (
            <>
              <Header />
              <PoolAdmin />
            </>
          )}
          
        />
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_ALL_REQUESTS_DETAIL+"/*"}
          Component={RequestAdminView}
        />
        <Route path="*" element={<Navigate to={ROUTES.REQUESTS_DECLARATIONS_ALL_REQUESTS} />} />         
      </Routes>
    </main>
  );
};
