import React from 'react';
import classnames from 'classnames';

import styles from './Button.module.scss';
import { Tooltip } from '../Tooltip';
import { Title } from '../Title';

export const Button = ({
  children,
  className,
  onClick,
  filled,
  fluid,
  small,
  light,
  medium,
  disabled,
  tooltip,
  offset,
  invisible,
}) => {
  return (
    <div className={classnames({ [styles.Wrapper]: tooltip })}>
      <button
        className={classnames(styles.Button, className, {
          [styles['Button--filled']]: filled,
          [styles['Button--disabled']]: disabled,
          [styles['Button--invisible']]: invisible,
          [styles['Button--fluid']]: fluid,
          [styles['Button--small']]: small,
          [styles['Button--medium']]: medium,
          [styles['Button--light']]: light,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
      {tooltip && (
        <div
          className={classnames(styles.Tooltip, className, {
            [styles['Tooltip--offset']]: offset,
          })}
        >
          <Tooltip withInfo size={tooltip.size}>
            {tooltip.title && <Title as="h4">{tooltip.title}</Title>}
            <p className={styles.Tooltip__Content}>{tooltip.content}</p>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
