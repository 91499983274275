import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import Edit from '../../../assets/EditSquare.svg';
import { updateReview } from '../../../api';
import { Button } from '../../shared/Button';

import styles from './NewReviews.module.scss';
import { ROUTES } from '../../../consts';

export const ReviewCard = ({ _id, author, company, text, setReviews }) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [updatedText, setUpdatedText] = useState(text);
  const handleEdit = () => setEdit((prevEdit) => !prevEdit);

  const handleSave = async () => {
    await updateReview(_id, { text: updatedText });
    setReviews((prevReviews) =>
      prevReviews.map((prevReview) => {
        if (prevReview._id === _id) {
          return { ...prevReview, text: updatedText };
        }
        return prevReview;
      })
    );
    setEdit(false);
  };

  const handleSubmit = async () => {
    await updateReview(_id, { status: 'resolved' });
    navigate(ROUTES.REVIEWS_OLD);
  };

  const handleReject = async () => {
    await updateReview(_id, { status: 'rejected' });
    navigate(ROUTES.REVIEWS_OLD);
  };

  return (
    <div className={styles.Card} key={_id}>
      <div className={styles.Card__Content}>
        <div className={styles.Card__Photo}>
          <img src={author.photo} alt="avatar" />
        </div>
        <div className={styles.Card__Data}>
          <div className={styles.Card__Company}>{company.name}</div>
          <div className={styles.Card__Author}>
            {author.name} {author.surname}
          </div>
          <div className={styles.Card__Text}>
            {edit ? (
              <textarea
                className={styles.Textarea}
                value={updatedText}
                onChange={(e) => setUpdatedText(e.target.value)}
              />
            ) : (
              text
            )}
          </div>
        </div>
        {!edit && (
          <div onClick={handleEdit} className={styles.Card__EditIcon}>
            <img src={Edit} alt="edit" />
          </div>
        )}
      </div>
      <div className={styles.ButtonArea}>
        {edit ? (
          <Button filled onClick={handleSave}>
            Сохранить
          </Button>
        ) : (
          <>
            <Button onClick={handleReject}>Отклонить</Button>
            <Button className={styles.Submit} filled onClick={handleSubmit}>
              Принять
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
