import { 
  CHANGE_STATUS_TO_LOADING,
  CHANGE_STATUS_TO_LOADED,
  CHANGE_STATUS_TO_NOT_FOUND,
  CHANGE_STATUS_TO_ERROR } from './actions';

const initialState = {
  statusWithLoading: "loading"
};

export const withLoadingReducer = (state = initialState, action) => {

  switch (action.type) {
    case CHANGE_STATUS_TO_LOADING:
    case CHANGE_STATUS_TO_LOADED:
    case CHANGE_STATUS_TO_NOT_FOUND:
    case CHANGE_STATUS_TO_ERROR:
      return {
        ...state,
        statusWithLoading: action.payload,
      };                
    default:
      return state;      
  }	
};