import React, { useState } from 'react';

import Upload from '../../assets/Upload.svg';
import { AddCompanyModal } from './AddCompanyModal';

import styles from './Card.module.scss';

export const AddCard = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);

  return (
    <>
      <div className={styles.Card} onClick={handleClick}>
        <div className={styles.AddCard}>
          <div>
            <img src={Upload} alt="upload" />
          </div>
          <div>Добавить компанию</div>
        </div>
      </div>
      <AddCompanyModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
