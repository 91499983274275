import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { fetchReviews } from '../../../api';
import { Table } from '../../shared/Table';
import { useScrollTop } from '../../../hooks';

export const OldReviews = () => {
  useScrollTop();
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    fetchReviews({ status: 'resolved&status=rejected' })
      .then(({ reviews }) => setReviews(reviews))
      .catch(console.log);
  }, []);

  return (
    <div>
      {reviews.length > 0 ? (
        <Table
          headers={[
            {
              label: 'Создан',
              value: 'createdAt',
              render: (value) => format(new Date(value), 'dd.MM.yyyy'),
            },
            {
              label: 'Решение',
              value: 'status',
              renderItem: true,
              render: (review) => (
                <span>
                  {review.status === 'resolved' ? 'Опубликован ' : 'Отклонен '}
                  {format(new Date(review.updatedAt), 'dd.MM.yyyy')}
                </span>
              ),
            },
            {
              label: 'Компания',
              value: 'company',
              render: ({ name }) =>(name?name:""),
            },
            {
              label: 'Пользователь',
              value: 'author',
              render: ({ name, surname }) => `${name?name:""} ${surname?surname:""}`,
            },
            {
              label: 'Текст',
              value: 'text',
              render: (text) => (
                <div style={{ whiteSpace: 'pre-line' }}>{text?text:text}</div>
              ),
            },
          ]}
          items={reviews}
          countItems={reviews.length}
          filters={{}}
        />
      ) : (
        <div>Обработанных отзывов пока нет.</div>
      )}
    </div>
  );
};
