import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ROLES } from '../../consts';
import { MENU_ITEMS } from '../../configs/menuItems';
import { getProfile } from '../../store/profile';
import { getCompany, getbankAccounts, getCompanyOffer } from '../../store/companies';
import { MenuItem } from './MenuItem';
import { MenuFooter } from './MenuFooter';

import styles from './Menu.module.scss';

export const Menu = () => {
  const profile = useSelector(getProfile);
  const company = useSelector(getCompany);  
  const bankAccounts = useSelector(getbankAccounts);
  const companyOffer = useSelector(getCompanyOffer);

  return (
    <aside className={styles.Menu}>
      <section className={styles.Menu__Main}>
        {profile.role &&
          MENU_ITEMS[profile.role].map((item) => {

            if ((profile.parentProfile && item.id && !profile.sections.includes(item.id))
              || (item.id && item.id === "requests"  && (!company?.verified || !bankAccounts.length || !(profile?.name && profile?.surname)
                ||(profile?.role === "logist" && !companyOffer)))
              || (item.id && item.id === "account"  && (!company?._id || !bankAccounts.length || !(profile?.name && profile?.surname)))
              || !profile.emailVerified) {

              return undefined;
            }

            return <MenuItem key={item.route} {...item} />;
          })}
      </section>
      {profile.role === ROLES.USER && <MenuFooter />}
    </aside>
  );
};

