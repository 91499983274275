import React from 'react';
import classnames from 'classnames';

import styles from './Steps.module.scss';

export const Steps = ({ steps }) => {
  return (
    <div className={styles.Steps}>
      {steps.map(({ active, label }, idx) => (
        <div key={idx} className={styles.Step}>
          <div
            className={classnames(styles.Step__Circle, {
              [styles['Step__Circle--active']]: active,
            })}
          >
            {idx + 1}
          </div>
          <div
            className={classnames(styles.Step__Label, {
              [styles['Step__Label--active']]: active,
            })}
          >
            {label}
          </div>
          {idx !== steps.length - 1 && <div className={styles.Step__Divider} />}
        </div>
      ))}
    </div>
  );
};
