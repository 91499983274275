import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { ROUTES } from '../../../consts';
import { getRequest, getLoading, loadRequestFromAll } from '../../../store/requests';
import { Accordion } from '../../shared/Accordion';
import { Button } from '../../shared/Button';
import { Tabs } from '../../shared/Tabs';
import { Title } from '../../shared/Title';
import { GoBackLink } from '../../shared/GoBackLink';
import { LoadingPage } from '../../hocs/withLoading/LoadingPage';
import { DeliveryInfo } from '../DeliveryInfo';
import { Documents } from '../Documents';
import { RequestDetailProgress } from '../RequestDetailProgress';

import styles from './RequestAdminView.module.scss';
import { Routes, Route } from 'react-router-dom';


export const RequestAdminView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(getLoading);
  const [open, setOpen] = useState(true);
  const [openReq, setOpenReq] = useState(true);
  const item = useSelector(getRequest);
  const { company } = item;
  const { id } = useParams();
  const requestStatus = (key) => {
    switch (key) {
      case 'inProgress':
        return 'В работе';
      case 'done':
        return 'Завершена';
      case '':
        return 'Ожидает декларанта';
      default:
        return 'Ожидает декларанта';
    }
  };

  useEffect(() => {
    id && dispatch(loadRequestFromAll(id));
  }, [id, dispatch]);

  return (
    <div>
      <Title>Заявки</Title>
      <GoBackLink />
      {loading ? (
        <LoadingPage withWrapper />
      ) : (
        <>
          <div className={styles.Header}>
            <div className={styles.Header__Column}>
              <div className={styles.Title}>
                <div>Заявка № {item?.number || item._id}</div>
                {item.createdAt && (
                  <div className={styles.Desc}>
                    Создана{' '}
                    {format(new Date(item.createdAt), 'dd.MM.yyyy HH:mm')}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.SubTitle}>{requestStatus(item.status)}</div>
          </div>
          {item.declarant && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Tabs
                tabs={[
                  {
                    title: 'Информация по заявке',
                    isPart: true,
                    route: ROUTES.FULL_REQUESTS_DECLARATION_ALL_REQUESTS_DETAIL,
                    visible: true,
                    href: './',
                  },
                  {
                    title: 'Работа по заявке',
                    route: ROUTES.FULL_REQUESTS_DECLARATION_ALL_REQUESTS_DETAIL_PROGRESS,
                    visible: true,
                    href: 'progress',
                  },
                ]}
              />
              {item.status === 'done' && (
                <div className={styles.CloseRequest}>
                  <Button
                    filled
                    onClick={() => navigate(`/act/request/${item._id}`)}
                  >
                    Акт
                  </Button>
                </div>
              )}
            </div>
          )}
          <Routes>
            <Route
              path="/"           
              Component={() => (
                <>
                  <div>
                    {company && (
                      <Accordion
                        open={open}
                        fadedToggler
                        onToggle={() => setOpen(!open)}
                        title="Компания"
                      >
                        <div className={styles.Title}>{company.name}</div>
                        <div className={styles.CompanyData}>
                          <div>ИНН {company.itn}</div>
                          <div>ОГРН {company.ogrn}</div>
                          <div>КПП {company.kpp}</div>
                        </div>
                      </Accordion>
                    )}
                  </div>
                  <div>
                    <Accordion
                      fadedToggler
                      title="Данные по заявке"
                      open={openReq}
                      onToggle={() => setOpenReq((prevOpen) => !prevOpen)}
                    >
                      {item && (
                        <>
                          <DeliveryInfo
                            showDiscount
                            price={item.price}
                            discount={item.discount}
                            promocode={item.promocode}
                            deliveryInfo={{
                              ...item,
                              company: item?.company?.name,
                            }}
                            viewOnly
                            lightgrey
                          />
                          <Documents documents={item} disabled viewOnly />
                        </>
                      )}
                    </Accordion>
                  </div>
                </>
              )}
            />
            <Route
              path="progress"
              Component={RequestDetailProgress}
            />
          </Routes>
        </>
      )}
    </div>
  );
};
