import React, { useState } from 'react';

import { PASSPORT_FIELDS_VIEW, PASSPORT_FILES_VIEW } from '../../configs/passport';
import { DataBlock } from '../Documents/DataBlock';
import { Accordion } from '../shared/Accordion';
import { PassportFileView } from './PassportFileView';

import styles from './PassportFormView.module.scss';

export const PassportFormView = ({ passport }) => {
  const [passpOpen, setPasspOpen] = useState(true);
  const handleToggle = () => setPasspOpen((prev) => !prev);

  return (
    <Accordion
      title={
        <div className={styles.Title}>
          <div className={styles.Title__Text}>Паспорт гражданина РФ</div>
        </div>
      }
      open={passpOpen}
      onToggle={handleToggle}
      fadedToggler
    >
      <div className={styles.PassportForm}>
        <div className={styles.PassportForm__Fields}>
          {Object.keys(PASSPORT_FIELDS_VIEW).map((field) => (
            <DataBlock
              key={field}
              field={field}
              {...PASSPORT_FIELDS_VIEW[field]}
              value={passport[field]}
              disabled
              lightgrey
            />
          ))}
        </div>
        <div className={styles.PassportForm__Files}>
          {Object.keys(PASSPORT_FILES_VIEW).map((file) => (
            <PassportFileView
              key={file}
              id={file}
              value={passport[file]}
              {...PASSPORT_FILES_VIEW[file]}
            />
          ))}
        </div>
      </div>
    </Accordion>
  );
};
