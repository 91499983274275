import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { io } from 'socket.io-client';
import { format } from 'date-fns';

import Send from '../../assets/Send.svg';
import Paperclip from '../../assets/Paperclip.png';
import { createDocx } from '../../utils/createDocx';
import { getFio } from '../../utils/getFio';
import { TOKEN } from '../../consts';
import ENV from '../../configs/environment';
import { getMessages } from '../../api';
import { getDocName, LocalStorageHelper } from '../../utils';
import { getProfile } from '../../store/profile';
import { getRequest, saveChecks } from '../../store/requests';
import { Input } from '../shared/Input';
import { Accordion } from '../shared/Accordion';
import { Title } from '../shared/Title';
import { UploadedDocument } from '../shared/UploadArea/UploadedDocument';
import { Button } from '../shared/Button';
import { UploadButton } from '../shared/Button/UploadButton';
import { History } from './History';
import { DeclarationBlock } from './DeclarationBlock';
import { PaymentsNotReady } from './CustomsPayments/PaymentsNotReady';
import { PaymentsList } from './CustomsPayments/PaymentsList';
import { MessageList } from './Chat/MessageList';
import { ChatHeader } from './Chat/ChatHeader';
import { TYPE_OPTIONS } from '../../configs/request';

import styles from './RequestDetailProgress.module.scss';
/*import {UploadArea} from "../shared/UploadArea";*/

export const RequestDetailProgress = () => {
  const dispatch = useDispatch();
  const [onlineUsers, setOnlineUsers] = useState([]);
  const socketRef = useRef(null);
  const { _id, photo, name, surname } = useSelector(getProfile);
  const item = useSelector(getRequest);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [checks, setChecks] = useState(item.checks || []);
  const { company } = item;

  const [openState, setOpenState] = useState({
    customs: false,
    history: false,
    chat: true,
    declaration: false,
  });

  useEffect(() => {
    let unmounted = false;
    let socket;
    if (item._id) {
      const token = LocalStorageHelper.getItem(TOKEN);
      socket = io(process.env.NODE_ENV === "development" ? ENV.API_URL : "system.mydeclarant.ru", {
        query: { token },
      });
      socketRef.current = socket;
      socket.emit('joinRoom', { profile: _id, request: item._id });
      socket.on('message', (message) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            ...message,
            author: {
              photo: item.declarant.photo,
              name: item.declarant.name,
              surname: item.declarant.surname,
            },
          },
        ]);
      });

      socket.on('online', (users) => {
        setOnlineUsers(users);
      });

      async function fetchMessages() {
        try {
          const response = await getMessages(item._id);
          if (response?.messages && !unmounted) {
            setMessages(response.messages);
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchMessages();
    }

    return () => {
      if (item._id) {
        unmounted = true;
        socket.disconnect(true);
      }
    };
  }, [item._id, _id, item.declarant]);

  const handleSaveChecks = () => {
    dispatch(saveChecks(checks, toast));
  };

  const handleToggle = (accordion) => () =>
    setOpenState((prevState) => ({
      ...prevState,
      [accordion]: !prevState[accordion],
    }));

  const handleSend = () => {
    if (socketRef.current) {
      socketRef.current.emit('message', newMessage);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          createdAt: new Date(),
          text: newMessage,
          author: {
            _id,
            photo,
            name,
            surname,
          },
        },
      ]);
    }
    setNewMessage('');
  };

  const online = onlineUsers.includes(item.declarant?._id);

  const chatTitle = item.declarant ? (
    <ChatHeader online={online}>
      Декларант {item.declarant.name} {item.declarant.surname}
    </ChatHeader>
  ) : (
    'Чат с декларантом'
  );

  const chatMessage = item.declarant
    ? 'Сообщений пока нет'
    : 'Чат будет доступен, как только декларант возьмёт заявку в работу';

  const downloadTask = () => {
    createDocx({
      data: {
        companyNumber: company.number,
        companyName: company.name,
        registrationDate: format(new Date(company.createdAt), 'dd.MM.yyyy'),
        ceoName: company.ceo,
        ceoFio: getFio(company.ceo),
        requestNumber: item.number,
        requestDate: format(new Date(item.createdAt), 'dd.MM.yyyy'),
        storeAddress: item.storeAddress,
        operations:
          `${item.tariff === 'declarant' ? '☑' : '□'} Подача ДТ \n` +
          `     ${item.type === TYPE_OPTIONS.import.value ? '☑' : '□'} ИМ40; ${
            item.type === TYPE_OPTIONS.export.value ? '☑' : '□'
          } ЭК10\n` +
          `${
            item.withGoodsLookup ? '☑' : '□'
          } Осуществление осмотра товаров (до подачи ДТ);\n` +
          `${
            item.tariff === 'light' ? '☑' : '□'
          } Подготовка и формирование ДТ для последующей подачи (клиентом за своей ЭЦП)\n` +
          // `     ${item.type === TYPE_OPTIONS.import.value ? '☑' : '□'} ИМ40; ${
          //   item.type === TYPE_OPTIONS.export.value ? '☑' : '□'
          // } ЭК10\n` +
          `${
            item.withPermissionDocs ? '☑' : '□'
          } Организация и получение разрешительных документов и заключений в органах государственного\n` +
          `   контроля и сертификационных органах\n` +
          `${
            item.withPresense ? '☑' : '□'
          } Обеспечение присутствия при осуществлении досмотра/осмотра в ходе таможенного оформления;\n` +
          `${
            item.withLoadingControl ? '☑' : '□'
          } Осуществление контроля погрузочных/разгрузочных работ при проведении досмотра/осмотра товаров;\n` +
          `${
            item.withFitoSanControl ? '☑' : '□'
          } Организация и проведение фитосанитарного контроля\n` +
          `${
            item.withDocsTransfer ? '☑' : '□'
          } Содействие в получении перевода документов уполномоченным лицом\n` +
          `2. Оплата услуг Таможенного представителя производится:\n` +
          `☑ в порядке предварительной оплаты\n` +
          `3. Оплата таможенных платежей:\n` +
          `□ с р/с Таможенного представителя; (Регистрация договора об оказании услуг таможенного представителя в отделе таможенных платежей)\n` +
          `□ с р/с Клиента.`,
      },
      saveAsName: `МойДекларант_Задание_${item.number}.docx`,
      templateUrl:
        'https://system.mydeclarant.ru/templates/RequestContractTempl.docx',
    });
  };

  const onChatUpload = (document) => {
    console.log("doc uploaded", document);
    const newMessage = `##file:${document}`;
    socketRef.current.emit('message', newMessage);

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        createdAt: new Date(),
        text: newMessage,
        author: {
          _id,
          photo,
          name,
          surname,
        },
      },
    ]);
  };

  return (
    <>
      <Button filled onClick={downloadTask}>
        Скачать задание
      </Button>
      <div className={styles.Progress}>
        <div>
          <Accordion
            fadedToggler
            title={
              <>
                <div>Таможенные платежи</div>
                {item.paymentDone && (
                  <div>
                    оплачены{' '}
                    <span role="img" aria-label="success">
                      ✅
                    </span>
                  </div>
                )}
              </>
            }
            open={openState.customs}
            onToggle={handleToggle('customs')}
          >
            <div className={styles.Customs}>
              {item.customsPayments?.length > 0 ? (
                <>
                  <PaymentsList payments={item.customsPayments} />
                  <div className={styles.Upload__Checks}>
                    <Title as="h3">Загрузите платежное поручение</Title>
                    <UploadButton
                      filled
                      disabled={item.checks.length}
                      onUpload={(checks) => {
                        setChecks((prevChecks) => [...prevChecks, ...checks]);
                      }}
                    >
                      Загрузить
                    </UploadButton>
                  </div>
                  <div className={styles.ChecksList}>
                    {checks.map((check, idx) => (
                      <UploadedDocument
                        key={check}
                        name={getDocName({ doc: check, id: 'check', idx })}
                        link={check}
                        disabled={item.checks.length}
                        onExclude={
                          item.paymentDone
                            ? undefined
                            : () =>
                                setChecks((prevChecks) =>
                                  prevChecks.filter(
                                    (_check) => check !== _check
                                  )
                                )
                        }
                      />
                    ))}
                  </div>
                  <div className={styles.Submit__Checks}>
                    <Button
                      disabled={
                        checks.length === 0 ||
                        item.checks.length === checks.length
                      }
                      filled
                      onClick={handleSaveChecks}
                    >
                      Сохранить
                    </Button>
                  </div>
                </>
              ) : (
                <PaymentsNotReady />
              )}
            </div>
          </Accordion>
          <Accordion
            fadedToggler
            title="История по работе с заявкой"
            open={openState.history}
            onToggle={handleToggle('history')}
          >
            <History createdAt={item.createdAt} history={item.history} />
          </Accordion>
        </div>
        <div>
          <Accordion
            fadedToggler
            title="Таможенная Декларация на товары"
            open={openState.declaration}
            onToggle={handleToggle('declaration')}
          >
            <DeclarationBlock />
          </Accordion>
          <Accordion
            fadedToggler
            title={chatTitle}
            open={openState.chat}
            fluid={false}
            onToggle={handleToggle('chat')}
          >
            <div className={styles.Chat}>
              <MessageList chatMessage={chatMessage} messages={messages} />
              {item.declarant && (
                <div className={styles.NewMessage}>
                  <Input
                    onChange={(e) => {
                      const { value } = e.target;
                      setNewMessage(value);
                    }}
                    value={newMessage}
                    className={styles.NewMessage__Input}
                    onEnter={handleSend}
                    placeholder={
                      messages.length > 0
                        ? 'Напишите сообщение... 2'
                        : 'Начните общение по заявке ⏳'
                    }
                  />
                  <div
                    className={styles.NewMessage__IconWrapper}
                    onClick={handleSend}
                  >
                    <img src={Send} alt="send" />
                  </div>
                  <div>
                    <UploadButton
                      light={true}
                      id={`chat_upload_${_id}`}
                      onUpload={onChatUpload}
                    >
                      <img src={Paperclip} alt="send" style={{width: '41px'}} />
                    </UploadButton>
                  </div>
                </div>
              )}
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
};
