import React from 'react';
import { useSelector } from 'react-redux';

import { getLoading } from '../../../store/profile';
import { LoadingPage } from './LoadingPage';

export const withLoading = (Component, selector = getLoading) => (props) => {
  const loading = useSelector(selector);

  if (loading) {
    return <LoadingPage />;
  }

  return <Component {...props} />;
};
