import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { STATUS_OPTIONS, TYPE_OPTIONS } from '../../../configs/request';
import {
  getCount,
  getLoading,
  getPool,
  loadInProgress,
  applyInProgressFilters,
} from '../../../store/requests';
import { Message } from '../../shared/Message';
import { Table } from '../../shared/Table';
import { getRequestInProgressFilters } from '../../../store/filters';
import { formatMoney } from '../../../utils';

const REQUEST_HEADERS = [
  {
    label: '№ Заявки',
    value: 'number',
    sortable: true,
    render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
  },
  {
    label: 'Дата заявки',
    value: 'createdAt',
    render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    sortable: true,
  },
  {
    label: 'Действия',
    value: 'actions',
    render: () => 'Нужна загрузка документов',
  },
  {
    label: 'Тип перевозки',
    value: 'type',
    render: (value) => TYPE_OPTIONS[value]?.label,
  },
  {
    label: 'Статус',
    value: 'status',
    render: (value) => STATUS_OPTIONS[value]?.label,
    sortable: true,
  },
  {
    label: 'Предварительная стоимость заявки',
    value: 'price',
    render: formatMoney,
  },
  {
    label: '',
    value: 'rowActions',
    render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
  },
];

export const InProgress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pool = useSelector(getPool);
  const loading = useSelector(getLoading);
  const filters = useSelector(getRequestInProgressFilters);
  const countItems = useSelector(getCount);

  useEffect(() => {
    dispatch(loadInProgress);
  }, [dispatch]);

  return (
    <div>
      {pool.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`/requests/declarant/${_id}/info`);
          }}
          loading={loading}
          items={pool}
          headers={REQUEST_HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) => dispatch(applyInProgressFilters(filters))}
        />
      ) : (
        <Message type="info">У Вас пока нет заявок в работе</Message>
      )}
    </div>
  );
};
