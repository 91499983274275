import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './TableManagerActivity.module.sass';
import classnames from 'classnames';
import { ROUTES } from '../../../consts';

import { CopyButton } from './CopyButton';

export const TableManagerActivity = ({ setModalState, activity, setTableType }) => {

	const navigate = useNavigate();	

	const handlerClick = (event) => {
		let element, id;
		if (element = event.target.closest("."+classnames(styles.btn_delete))){
			id = element.getAttribute("data-id");
			if (setModalState && id){
				setModalState({open: true, id: id});	
			}			
		}		
	}
	

	return(
		<>
			{(Boolean(typeof activity === "object") && Boolean(activity.length)) && (<div className={classnames(styles.applications_activity)} onClick={handlerClick}>
				<div className={classnames(styles.applications_column_stack)}	
				>												
					<div className={classnames(styles.applications_column)}><div className={classnames(styles.application_name_col)}></div></div>

					{activity.map(({id}) => {
						return (
							<div className={classnames(styles.applications_column)}>
								<CopyButton id={id} setTableType={setTableType}/>
								<div className={classnames(styles.btn_delete)} data-id={id}>
									<svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 4.33398H2.66667H16" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path>
										<path d="M14.3337 4.33333V16C14.3337 16.442 14.1581 16.8659 13.8455 17.1785C13.5329 17.4911 13.109 17.6667 12.667 17.6667H4.33366C3.89163 17.6667 3.46771 17.4911 3.15515 17.1785C2.84259 16.8659 2.66699 16.442 2.66699 16V4.33333M5.16699 4.33333V2.66667C5.16699 2.22464 5.34259 1.80072 5.65515 1.48816C5.96771 1.17559 6.39163 1 6.83366 1H10.167C10.609 1 11.0329 1.17559 11.3455 1.48816C11.6581 1.80072 11.8337 2.22464 11.8337 2.66667V4.33333" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path>
										<path d="M6.83301 8.5V13.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path>
										<path d="M10.167 8.5V13.5" stroke="#B4B4B4" stroke-linecap="round" stroke-linejoin="round"></path>
									</svg>										
								</div>
							</div>
						);
					})}
				</div>							
			</div>)}
		</>
	);
}