import { EDUCATION_HIGHEST } from '../../configs/education';
import { DOCUMENTS_STEPS } from '../../consts';
import {
  ADD_COURSE,
  DELETE_COURSE,
  EDIT_COURSE,
  TOGGLE_OPEN,
  UPDATE_VALUE,
  UPDATE_EDUCATION,
  SET_LOADING,
} from './actions';

export const INITIAL_COURSE = {
  name: '',
  year: '',
  certificate: '',
};

const initialState = {
  step: DOCUMENTS_STEPS.FAILED_REVIEW,
  open: false,
  errors: {},
  courses: [],
  newCourse: INITIAL_COURSE,
  loading: false,
  editedCourse: null,
};

Object.keys(EDUCATION_HIGHEST).forEach((field) => {
  initialState[field] = EDUCATION_HIGHEST[field].initialValue;
});

export const educationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COURSE:
      if (state.editedCourse === null) {
        return {
          ...state,
          courses: [...state.courses, { ...state.newCourse }],
          newCourse: INITIAL_COURSE,
        };
      } else {
        const arrayCourses = [...state.courses];
        arrayCourses.splice(state.editedCourse, 0, state.newCourse);
        return {
          ...state,
          courses: arrayCourses,
          newCourse: INITIAL_COURSE,
          editedCourse: null,
        };
      }
    case DELETE_COURSE:
      return {
        ...state,
        courses: [...state.courses].filter((_, idx) => idx !== action.payload),
      };
    case EDIT_COURSE:
      return {
        ...state,
        newCourse: [...state.courses][action.payload],
        courses: [...state.courses].filter((_, idx) => idx !== action.payload),
        editedCourse: action.payload,
      };
    case TOGGLE_OPEN:
      const prevOpen = state.open;
      const newState = { ...state, open: !prevOpen };
      return newState;
    case UPDATE_EDUCATION:
      const errors = action.payload.errors || {};
      return { ...state, ...action.payload, errors };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case UPDATE_VALUE:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};
