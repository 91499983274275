import { costDecore } from './costDecore';

export const spreadTrainCostTable = (arr) => {
	if (typeof arr === "object" && arr.length){
		return arr.map(({typetransport, seaCost, trainCost})=>{

			if (typetransport){// sea
				if (seaCost >= 0 && trainCost >= 0){
					return costDecore(trainCost, "₽");
				} else if (seaCost >= 0){
					return "────────────";
				} else  return "Нет данных";
			} else { //train
				if (trainCost >= 0){
					return costDecore(trainCost, "$");
				} else return "Нет данных";
			}			

		});
	}
	return [];
}

