import React from 'react';
import { useSelector } from 'react-redux';

import { getRequest } from '../../store/requests';
import { Button } from '../shared/Button';
import { UploadAreaSingle } from '../shared/UploadArea/UploadAreaSingle';

import styles from './DeclarationBlock.module.scss';

export const DeclarationBlock = () => {
  const item = useSelector(getRequest);
  const { declaration } = item;

  return (
    <div className={styles.Declaration}>
      {declaration.length ? (
        <>
          <UploadAreaSingle files={declaration} id="declaration" disabled />
          <div className={styles.ButtonArea}>
            <a href={declaration[0]} target="_blank" rel="noopener noreferrer">
              <Button filled>Скачать</Button>
            </a>
          </div>
        </>
      ) : (
        <>
          <div>Здесь вы сможете скачать декларацию</div>
          <div className={styles.ButtonArea}>
            <Button filled disabled>
              Скачать
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
