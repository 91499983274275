import React from 'react';

import styles from './Loader.module.scss';

export const Loader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 124 124"
    width={145}
    height={145}
    className={styles.Loader}
  >
    <g>
      <path
        fill="#01d39a"
        d="M56.57.24a62.06,62.06,0,0,1,67.19,56.33l-2,.17a60.07,60.07,0,0,0-65-54.51Z"
        id="HIRO_Loader-31"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 62 62"
          to="720 62 62"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(4 4)">
      <path
        fill="#0384b6"
        d="M0,58.07A58.07,58.07,0,0,1,57.93,0V2A56.07,56.07,0,0,0,2,58.07Z"
        id="HIRO_Loader-32"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 58 58; 360 58 58;"
          keyTimes="0; 1"
          keySplines=".42 1 .58 0.8"
          calcMode="spline"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(8 8)">
      <path
        fill="#01d39a"
        d="M72.79,3.38a54.06,54.06,0,0,1,31.84,69.41l-1.88-.7A52,52,0,0,0,72.09,5.25Z"
        id="HIRO_Loader-33"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 54 54; 720 54 54;"
          keyTimes="0; 1"
          keySplines=".172 .223 .739 .665"
          calcMode="spline"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(12 12)">
      <path
        fill="#01d39a"
        d="M15.12,85.82a50,50,0,0,1-1-70.7l1.44,1.39a48.06,48.06,0,0,0,.94,67.88Z"
        id="HIRO_Loader-34"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 50 50; -360 50 50;"
          keyTimes="0; 1"
          keySplines=".061 .416 .938 .592"
          calcMode="spline"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(16 16)">
      <path
        fill="#0384b6"
        d="M78.51,78.51a46.07,46.07,0,0,1-65.06,0l1.42-1.42a44,44,0,0,0,62.22,0Z"
        id="HIRO_Loader-35"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 46 46; -360 46 46;"
          keyTimes="0; 1"
          keySplines=".238 .276 .728 .66"
          calcMode="spline"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(20 20)">
      <path
        fill="#194680"
        d="M61.94,5A42,42,0,0,1,79,61.94L77.19,61A40,40,0,0,0,61,6.79Z"
        id="HIRO_Loader-36"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 42 42; 360 42 42;"
          keyTimes="0; 1"
          keySplines=".652 .333 .778 .896"
          calcMode="spline"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(24 24)">
      <path
        fill="#0384b6"
        d="M24.18,2.6A38,38,0,0,1,73.4,24.18l-1.86.73A36,36,0,0,0,24.91,4.47Z"
        id="HIRO_Loader-37"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 38 38; 720 38 38;"
          keyTimes="0; 1"
          keySplines=".497  0 .48 1"
          calcMode="spline"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(28 28)">
      <path
        fill="#0384b6"
        d="M0,34A34,34,0,0,1,34,0V2A32,32,0,0,0,2,34Z"
        id="HIRO_Loader-38"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 34 34; 360 34 34;"
          keyTimes="0; 1"
          keySplines=".512 0 .55 1"
          calcMode="spline"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(32 32)">
      <path
        fill="#194680"
        d="M22.09,58.94a30,30,0,0,1-21-36.85L3,22.61A28,28,0,0,0,22.61,57Z"
        id="HIRO_Loader-39"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 30 30; 1080 30 30;"
          keyTimes="0; 1"
          keySplines=".49 .18 .83 .83"
          calcMode="spline"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
    <g transform="translate(36 36)">
      <path
        fill="#01d39a"
        d="M44.37,7.6a26,26,0,0,1,0,36.77L43,43A24,24,0,0,0,43,9Z"
        id="HIRO_Loader-40"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 26 26"
          to="1440 26 26"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </g>
  </svg>
);
