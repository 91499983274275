import React from 'react';

import { Message } from '../components/shared/Message';
import { Title } from '../components/shared/Title';
import { SocialIcon } from '../components/Personal/SocialIcon';
import { SOCIALS } from '../configs/socials';

export const BusinessClub = () => {
  return (
    <div>
      <Title>Бизнес клуб</Title>
      <div
        style={{
          background: '#FFFFFF',
          border: '1px solid #C9C9C9',
          boxSizing: 'border-box',
          borderRadius: '5px',
          padding: '26px',
          maxWidth: '1014px',
        }}
      >
        <Message
          type="info"
          full
          style={{ marginBottom: 0, lineHeight: '23px' }}
        >
          <div style={{ lineHeight: '23px' }}>
            Совсем скоро мы откроем бизнес клуб! Следите за новостями на нашем
            сайте{' '}
            <a
              target="_blank"
              href="https://mydeclarant.ru/"
              rel="noopener noreferrer"
            >
              https://mydeclarant.ru/
            </a>{' '}
            и в наших социальных сетях
          </div>
          <div style={{ textAlign: 'right' }}>
            {SOCIALS.map((props) => (
              <SocialIcon key={props.alt} {...props} />
            ))}
          </div>
        </Message>
      </div>
    </div>
  );
};
