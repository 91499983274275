import {
  LOAD_TRANSACTIONS_REQUEST,
  LOAD_TRANSACTIONS_SUCCESS,
  LOAD_TRANSACTIONS_FAILURE,
  LOAD_ACCOUNTS_REQUEST,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_ACCOUNTS_FAILURE,
  LOAD_SYSTEM_ACCOUNTS_REQUEST,
  LOAD_SYSTEM_ACCOUNTS_SUCCESS,
  LOAD_SYSTEM_ACCOUNTS_FAILURE,
} from './actions';

const initialState = {
  accounts: [],
  accountsCount: 0,
  items: [],
  count: 0,
  item: {},
  loading: true,
  totalFundsReceipt: 0,
  systemAccountBalance: 0,
  tempAccountBalance: 0,
  paymentsDebt: 0,
  outPaymentsSum: 0,
  error: null,
};

export const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSACTIONS_REQUEST:
    case LOAD_ACCOUNTS_REQUEST:
    case LOAD_SYSTEM_ACCOUNTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        items: action.payload.transactions,
        count: action.payload.count,
        loading: false,
      };
    case LOAD_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload.accounts,
        accountsCount: action.payload.count,
        loading: false,
      };
    case LOAD_SYSTEM_ACCOUNTS_SUCCESS:
      return {
        ...state,
        totalFundsReceipt: action.payload.totalFundsReceipt,
        systemAccountBalance: action.payload.systemAccountBalance,
        tempAccountBalance: action.payload.tempAccountBalance,
        paymentsDebt: action.payload.paymentsDebt,
        outPaymentsSum: action.payload.outPaymentsSum,
        loading: false,
      };
    case LOAD_TRANSACTIONS_FAILURE:
    case LOAD_ACCOUNTS_FAILURE:
    case LOAD_SYSTEM_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
