import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { ROUTES } from '../../consts';
import {getCompanies, loadUserCompanies, getLoading, setItem} from '../../store/companies';
import { DocumentsView } from './DocumentsView';
import { EsignView } from './EsignView';
import { Message } from '../shared/Message';
import { LoadingPage } from '../hocs/withLoading/LoadingPage';
import { CardView } from './CardView';
import { InfoView } from './InfoView';

import styles from './CompanyView.module.scss';

export const CompanyView = ({ clientId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const companies = useSelector(getCompanies);

  const chooseCompany = (company) => {
    navigate(`${ROUTES.FULL_USERS_CLIENTS}/${clientId}/${company._id}/info`); 
    dispatch(setItem(company));
  };

  useEffect(() => {
    dispatch(loadUserCompanies(clientId));
  }, [dispatch, clientId]);

  return (
    <div>
      {loading && companies.length === 0 ? (
        <LoadingPage withWrapper />
      ) : (
        <>
          {companies.length === 0 && (
            <Message>
              Ошибка! Компании клиента не загрузились. Обратитесь к
              администратору системы.
            </Message>
          )}
          <div className={styles.CardsList}>
            {companies.map((company) => (
              <CardView
                key={company._id}
                company={company}
                onClick={() => {
                  chooseCompany(company);
                  setTimeout(() => {
                    const content = document.getElementById('content');
                    if (content) {
                      content.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }, 100);
                }}
              />
            ))}
          </div>
        </>
      )}
      <div id="content">
        <Routes>
          <Route path={ROUTES.USERS_CLIENT_COMPANY_INFO} Component={InfoView} />
          <Route path={ROUTES.USERS_CLIENT_COMPANY_DOCS} Component={DocumentsView} />
          <Route path={ROUTES.USERS_CLIENT_COMPANY_ESIGN} Component={EsignView} />
        </Routes>
      </div>
    </div>
  );
};