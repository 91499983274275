import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { createInvite } from '../api';
import { ROLES, ROUTES } from '../consts';
import { withLoading } from '../components/hocs/withLoading';
import { UserProfile } from '../components/UserDeclarantProfile';
import { PartnerProfile } from '../components/UserPartnerProfile/PartnerProfile';
import { AllUsers } from '../components/Users/AllUsers';
import { DeclarantsAccreditedUsers } from '../components/Users/DeclarantsAccreditedUsers';
import { LogistsAccreditedUsers } from '../components/Users/LogistsAccreditedUsers';
import { DeclarantsAllUsers } from '../components/Users/DeclarantsAllUsers';
import { LogistsAllUsers } from '../components/Users/LogistsAllUsers';
import { DeclarantsUsers } from '../components/Users/DeclarantsUsers';
import { Partners } from '../components/Users/Partners';
import { ClientsUsers } from '../components/Users/ClientsUsers';
import { SubTabs } from '../components/shared/SubTabs';
import { Button } from '../components/shared/Button';
import { Header } from '../components/Users/Header';
import { ClientsUsersPeople } from '../components/Users/ClientsUsersPeople';
import { ClientsUsersCompanies } from '../components/Users/ClientsUsersCompanies';
import { ClientProfile } from '../components/UserClientProfile/ClientProfile';

export const Users = withLoading(() =>(
  <>  
  <Routes>
    <Route
      path={ROUTES.ALL_USERS}
      Component={() =>(
        <>
          <Header />
          <AllUsers />
        </>)}      
    />
    <Route
      path={ROUTES.USERS_DECLARANTS}
      Component={()=>(
        <>
          <Header />
          <DeclarantsAllUsers />
        </>
        )}              
    />
    <Route
      path={ROUTES.USERS_DECLARANTS_ACCREDITED}
      Component={()=>(
        <>
          <Header />
          <DeclarantsAccreditedUsers />
        </>
      )}              
    />
    <Route
      path={ROUTES.USERS_DECLARANTS_PROFILE}
      Component={()=><UserProfile />}      
    />
    <Route
      path={ROUTES.USERS_LOGISTS}
      Component={()=>(
        <>
          <Header />
          <LogistsAllUsers />
        </>
        )}              
    />
    <Route
      path={ROUTES.USERS_LOGISTS_ACCREDITED}
      Component={()=>(
        <>
          <Header />
          <LogistsAccreditedUsers />
        </>
      )}              
    />
    <Route
      path={ROUTES.USERS_LOGISTS_PROFILE}
      Component={()=><ClientProfile />}      
    />
    <Route
      path={ROUTES.USERS_PARTNERS}
      Component={() =>(
        <>
          <Header />
          <Partners />
        </>
      )}          
    />
    <Route
      path={ROUTES.USERS_PARTNERS_PROFILE}
      Component={() =>(<PartnerProfile />)}          
    />    
    <Route
      path={ROUTES.USERS_CLIENTS_PEOPLE}
      Component={() =>(
        <>
          <Header />
          <ClientsUsersPeople />
        </>
      )}          
    />   
    <Route
      path={ROUTES.USERS_CLIENTS_COMPANIES}
      Component={() =>(
        <>
          <Header />
          <ClientsUsersCompanies />
        </>
      )}          
    />
    <Route
      path={ROUTES.MAIN_USERS_CLIENTS_PROFILE}
      Component={() =><ClientProfile /> }
    />
    <Route path='*' element={ <Navigate to={ROUTES.ALL_USERS} /> } />
  </Routes>
  </>
));
