import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import {
  /*loadEmailForm,*/
  getEmailForm,  
  saveEmailForm,
} from '../../store/notifications';

import { updateEmailForm } from '../../store/profile';

import { ROUTES } from '../../consts';
import { Title } from '../shared/Title';
import { Tabs } from '../shared/Tabs';
import { Button } from '../shared/Button';
import { Checkbox } from '../shared/Checkbox';
import { GoBackLink } from '../shared/GoBackLink';

import styles from './Notifications.module.scss';

export const NotificationsEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailForm = useSelector(getEmailForm);

  const handleCheck = (key) => () =>
    dispatch(updateEmailForm({ key, value: !emailForm[key] }));

  const handleSubmit = () => dispatch(saveEmailForm(toast, navigate));

  return (
    <div>
      <Title>Настройка E-mail уведомлений</Title>
      <Tabs
        tabs={[
          {
            title: 'Почта',
            route: ROUTES.NOTIFICATIONS_EMAIL,
            visible: true,
          },
        ]}
      />
      <GoBackLink />
      <div className={styles.Form}>
        <div className={styles.FieldsArea}>
          <div>
            <div className={styles.FieldWrapper}>
              <Checkbox
                checked={emailForm.system}
                onClick={handleCheck('system')}
                disabled
              >
                Системные сообщения
              </Checkbox>
            </div>
            <div className={styles.FieldWrapper}>
              <Checkbox
                checked={emailForm.requestUpdates}
                onClick={handleCheck('requestUpdates')}
                disabled
              >
                Уведомления об измениях в заявке
              </Checkbox>
            </div>
          </div>
          <div>
            <div className={styles.FieldWrapper}>
              <Checkbox
                checked={emailForm.infoNews}
                onClick={handleCheck('infoNews')}
              >
                Информация о системе и новости
              </Checkbox>
            </div>
            <div className={styles.FieldWrapper}>
              <Checkbox
                checked={emailForm.commercial}
                onClick={handleCheck('commercial')}
              >
                Рекламные и коммерческие рассылки
              </Checkbox>
            </div>
          </div>
        </div>
        <Button filled onClick={handleSubmit}>
          Сохранить
        </Button>
      </div>
    </div>
  );
};
