import React from 'react';

import styles from './WorkPlacesView.module.scss';

export const WorkPlacesView = ({ places }) => (
  <div>
    {places.map(({ name, startDate, finishDate, notFinished, degree }, idx) => (
      <div key={`${name}-${startDate}`} className={styles.Place}>
        <div className={styles.Place__InfoArea}>
          {name} / {degree} / {startDate} -{' '}
          {notFinished ? 'по настоящее время' : finishDate}
        </div>
      </div>
    ))}
  </div>
);
