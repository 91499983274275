export 	const makeLongDate = (dateString) => {

		function correctNum(num){

			if (num < 10){
				return "0" + num;
			} else return num;

		}		
	
		let date, year, month, day;
		let shortMonth = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
		if (dateString)	date = new Date(dateString);
		else date = new Date();

		month = shortMonth[date.getMonth()];
		day = correctNum(date.getDate());

		return day+" "+month+" "+date.getFullYear();
	}