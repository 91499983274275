import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import {
  getCount,
  getLoading,
  getPool,
  applyCompletedFilters,
  loadCompleted,
} from '../../store/requests';
import { formatMoney } from '../../utils';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { getRequestPartnerCompletedFilters } from '../../store/filters';
import { PAGE_PRICE } from '../../consts';

const REQUEST_HEADERS = [
  {
    label: 'Компания',
    value: 'company',
    render: (value) => value.name,
  },
  {
    label: 'Дата регистрации',
    value: 'company',
    render: (value) => format(new Date(value.createdAt), 'dd.MM.yyyy HH:mm'),
  },
  {
    label: 'Заявка',
    value: 'no matter',
    renderItem: true,
    render: (item) => (
      <div>
        №{item.number}&nbsp; &nbsp;{' '}
        {format(new Date(item.updatedAt), 'dd.MM.yyyy')}
      </div>
    ),
  },
  {
    label: 'Стоимость заявки',
    value: 'price',
    render: formatMoney,
  },
  {
    label: 'Выплата',
    value: 'payment',
    renderItem: true,
    render: (item) =>
      formatMoney(
        ((item.price - item.extraPages * PAGE_PRICE) * item.partnerRate) / 100
      ),
  },
];

export const PartnerProgramInfo = () => {
  const dispatch = useDispatch();
  const pool = useSelector(getPool);
  const loading = useSelector(getLoading);
  const filters = useSelector(getRequestPartnerCompletedFilters);
  const countItems = useSelector(getCount);

  useEffect(() => {
    dispatch(loadCompleted(filters));
  }, [dispatch, filters]);

  return (
    <div style={{ marginTop: '5rem' }}>
      {pool.length ? (
        <Table
          loading={loading}
          items={pool}
          headers={REQUEST_HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) => dispatch(applyCompletedFilters(filters))}
        />
      ) : (
        <Message type="info">
          В системе пока нет завершенных заявок, сформированных вашими
          рефералами
        </Message>
      )}
    </div>
  );
};
