import React, {useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {format} from 'date-fns';

import DefaultAvatar from '../../../assets/Avatar.svg';
import File from '../../../assets/File.png';

import {getProfile} from '../../../store/profile';

import styles from './MessageList.module.scss';

const renderMessage = (message, profile) => {
  return (
    <div key={message.createdAt + `_${message.text}`} className={styles.Message}>
      <div className={styles.Message__Avatar}>
        <div className={styles.Message__ImageWrapper}>
          <img
            src={message.author.photo || DefaultAvatar}
            alt={message.author.name}
          />
        </div>
      </div>
      <div className={styles.Message__Content}>
        <div className={styles.AuthorLine}>
          <div>
            <span>
              {message.author.name} {message.author.surname}
            </span>
            {profile._id === message.author._id && ' (Вы)'}
          </div>
          <div
            className={styles.Message__Time}
            title={format(
              new Date(message.createdAt),
              'MMM dd, yyyy в HH:mm:ss'
            )}
          >
            {format(new Date(message.createdAt), 'HH:mm')}
          </div>
        </div>
        {message.messages.map((msg, idx) => {
          const isFileContent = msg.text ? msg.text.match(/##file:/g) : false; //  /\#\#file\:/g

          return <React.Fragment>
            {
              isFileContent ?
                <div key={msg._id} className={styles.Message__Item}>
                  <img src={File} alt="send" style={{width: '20px'}}/>
                  <div>
                    <a href={msg.text.replace(/##file:/g, '')} target={'_blank'} rel={"noreferrer"}>
                      {msg.text.split('/').pop()}
                    </a>
                  </div>
                </div>
                :
                <div key={msg._id} className={styles.Message__Item}>
                  <div>{msg.text}</div>
                  {idx > 0 && (
                    <div
                      className={styles.Message__Time}
                      title={format(
                        new Date(msg.createdAt),
                        'MMM dd, yyyy в HH:mm:ss'
                      )}
                    >
                      {format(new Date(msg.createdAt), 'HH:mm')}
                    </div>
                  )}
                </div>
            }
          </React.Fragment>;
        })}
      </div>
    </div>
  );
};

const STACK_THRESHOLD = 60 * 60 * 1000;

export const MessageList = ({chatMessage, messages}) => {
  const messageRef = useRef(null);
  const profile = useSelector(getProfile);
  const preparedMessages = [];
  let prevMessage;
console.log(messages);
  messages.forEach((message) => {
    const prevDate = prevMessage && new Date(prevMessage.createdAt);
    const currentDate = new Date(message.createdAt);
    const isNextDay = !prevDate || prevDate.getDate() !== currentDate.getDate();
    if (isNextDay) {
      preparedMessages.push({type: 'date', createdAt: message.createdAt});
    }
    const sameAuthor = prevMessage?.author._id === message.author._id;
    const inThreshold = currentDate - prevDate < STACK_THRESHOLD;
    const inStack = prevMessage && sameAuthor && inThreshold;

    if (inStack) {
      const {length} = preparedMessages;
      preparedMessages[length - 1].messages.push(message);
    } else {
      preparedMessages.push({
        author: message.author,
        createdAt: message.createdAt,
        messages: [message],
      });
    }

    prevMessage = message;
  });

  useEffect(() => {
    if (messageRef.current && messages.length > 0) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
    }
  }, [messages.length]);

  return (
    <div className={styles.Wrapper}>
      {messages.length > 0 ? (
        <div className={styles.Messages} ref={messageRef}>
          {preparedMessages.map((message) => {
            if (message?.type === 'date') {
              const messageDate = new Date(message.createdAt);

              return (
                <div className={styles.Date} key={`${message.createdAt}-date`}>
                  {messageDate.getDate() === new Date().getDate()
                    ? 'Сегодня'
                    : format(messageDate, 'dd MMMM')}
                </div>
              );
            }

            return renderMessage(message, profile);
          })}
        </div>
      ) : (
        <div style={{color: 'rgba(0, 0, 0, 0.25)', padding: '1.5rem'}}>
          {chatMessage}
        </div>
      )}
    </div>
  );
};
