import React from 'react';

export const PaymentsNotReady = () => {
  return (
    <div style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
      Таможенные платежи еще не рассчитаны{' '}
      <span
        role="img"
        aria-label="time"
        style={{ color: 'rgb(0, 0, 0, 0.25)' }}
      >
        ⏳
      </span>
    </div>
  );
};
