import React from 'react';
import { format } from 'date-fns';

import styles from './CardView.module.scss';

export const CardView = ({ company, onClick }) => {

  return (
    <div className={styles.Card} onClick={onClick}>
      <div className={styles.Card__Header}>
        <div className={styles.Card__Title}>{company.name}</div>
      </div>
      <div className={styles.Card__Body}>
        <div className={styles.Card__Itn}>ИНН {company.itn}</div>
        <div className={styles.Card__Date}>
          В системе с {format(new Date(company.createdAt), 'dd.MM.yyyy')}
        </div>
      </div>
    </div>
  );
};
