import { API_ROUTES } from '../consts';
import { ApiHelper, generateQuery } from '../utils';

export const createTransaction = (payload) =>
  ApiHelper.post(API_ROUTES.TRANSACTIONS, payload);

export const getUserAccounts = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.TRANSACTIONS_ACCOUNTS}${queryString}`);
};

export const getSystemAccounts = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(
    `${API_ROUTES.TRANSACTIONS_SYSTEM_ACCOUNTS}${queryString}`
  );
};

export const getOutPayments = (roleObj) => {
  const queryString = generateQuery(roleObj);

  return ApiHelper.get(`${API_ROUTES.TRANSACTIONS_OUTPAYMENTS}${queryString}`);
};

export const getAllTransactions = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.TRANSACTIONS}${queryString}`);
};

export const getTransactionsByProfileId = (id, filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(
    `${API_ROUTES.TRANSACTIONS_PROFILE}/${id}${queryString}`
  );
};
