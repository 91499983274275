import React, { useState, useEffect, useCallback  } from 'react';
import { Routes, Route, useNavigate, Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FILTERS } from '../consts';
import { HeaderWithChapter } from '../components/shared/HeaderWithChapter';
import { GoBackLink } from '../components/shared/GoBackLink';
import { TableBody } from '../components/Logistics/Table/TableBody';
import { TableManagerActivity } from '../components/Logistics/Table/TableManagerActivity';
import { TablePagination } from '../components/Logistics/Table/TablePagination';
import { spreadFieldTable } from '../utils/spreadFieldTable';
import { spreadActivityTable } from '../utils/spreadActivityTable';
import { spreadSeaCostTable } from '../utils/spreadSeaCostTable';
import { spreadTrainCostTable } from '../utils/spreadTrainCostTable';
import styles from './RequestsLogisticsClient.module.sass';
import classnames from 'classnames';

import {
	getMyOffersFromPackFilters,
	applyFilters } from '../store/filters';

import {
	loadMyOffersFromPack, 
	getMyOffersFromPack,
	getMyOffersFromPackCount,
	resetMyOffersFromPack
} from '../store/logisticsOffersFromPack';

export const MyOffersLogisticsManagerDetail = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const data = useSelector(getMyOffersFromPack);
	const filters = useSelector(getMyOffersFromPackFilters);
	const count = useSelector(getMyOffersFromPackCount);

	const tableData = [
				{
					filterType: 'simpleFilter',
					descrType: 'plain',
					title: `№ Предложения`,
					dataArr: spreadFieldTable(data,"docId"),
					filterKey: FILTERS.MY_OFFERS_FROM_PACK,
					sortField: "docId",
					position: "center" //center fd_column
				},
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Актуальность до`,
					dataArr: spreadFieldTable(data,"expireDate"),					
				},				
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Откуда`,
					dataArr: spreadFieldTable(data,"cityDeparture"),

				},
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Куда`,
					dataArr: spreadFieldTable(data,"cityArrival"),
				},
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Терминал`,
					dataArr: spreadFieldTable(data,"terminalArrival"),
				},				
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Контейнер`,
					dataArr: spreadFieldTable(data,"container"),
				},
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Ставка(Море)`,
					dataArr: spreadSeaCostTable(data),
				},				
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Ставка(Ж/Д)`,
					dataArr: spreadTrainCostTable(data),
				}																			
	];	

	const filterTable = (tableData, excludeTitleList) => {
		if (data?.[0]?.typetransport === 0) {
			return tableData.filter(({title})=>!excludeTitleList.some(item=>item===title));			
		} else return tableData;
	}	
	
	useEffect(()=>{
		dispatch(loadMyOffersFromPack(id, filters));
		return ()=>{dispatch(resetMyOffersFromPack);}				
	},[filters]);

	return (
		<>
			<HeaderWithChapter title="Мои предложения" chapter="Логистика"/>
			<div className={classnames(styles.wrap_backandnew)}>
				<GoBackLink />						
			</div>
			<div className={classnames(styles.wrap_table)}>
				<div className={classnames(styles.wrap_blocks)}>
					<div className={classnames(styles.left_block, styles.w100)}>
						<TableBody tableData={filterTable(tableData, ["Терминал", "Ставка(Море)"])} nullmessage="На данный момент у вас нет предложений"/>		
					</div>
{/*					<div className={classnames(styles.right_block)}>
						<TableManagerActivity
							setModalState={setModalState}
							setTableType={setTableType}
							activity={spreadActivityTable(myOffersFromPack.data)}
						/>				
					</div>*/}					
				</div>
			</div>
			<div className={classnames(styles.applications_footer)}>
				<div className={classnames(styles.wrap_pagination)}>
					<TablePagination
			          applyFilters={(filters) =>
			            dispatch(applyFilters(FILTERS.MY_OFFERS_FROM_PACK, filters))
			          }	
			          filters={filters}
			          countItems={count}
					/>
				</div>
			</div>					
		</>	
	);
};

	