import React from 'react';
import { useSelector } from 'react-redux';

import DefaultAvatar from '../../../assets/Avatar.svg';
import { getProfile } from '../../../store/profile';

import styles from './Avatar.module.scss';

export const Avatar = ({ size }) => {
  const { photo } = useSelector(getProfile);
  return (
    <img
      width={size}
      height={size}
      className={styles.Avatar}
      src={photo || DefaultAvatar}
      alt="Avatar"
    />
  );
};
