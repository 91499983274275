import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SwitcherPanel.module.sass';
import classnames from 'classnames';



export const SwitcherPanel = ({state, setStateCb}) => {

	const handlerChange = (event) => {
		let element;
		if ( element = event.target.closest("."+classnames(styles.radio_typepack))){
			if(setStateCb){
				setStateCb(element.value);
			}
		}
	}

	return (
		<div className={classnames(styles.wrap_tabs)} onChange={handlerChange}>
			<input id="pack_sea" className={classnames(styles.radio_typepack)} type="radio" name="switchtypepack" value="sea"
			 checked={state === "sea"}
			/>
			<label htmlFor="pack_sea" className={classnames(styles.tabs_typepack)}>
				<span className={classnames(styles.tabs_typepack_inner_shadow)}></span>									
				<span className={classnames(styles.tabs_typepack_text_btn)}>Море</span>									
			</label>
			<input id="pack_train" className={classnames(styles.radio_typepack)} type="radio" name="switchtypepack" value="train"
				checked={state === "train"}
			/>
			<label htmlFor="pack_train" className={classnames(styles.tabs_typepack)}>	
				<span className={classnames(styles.tabs_typepack_inner_shadow)}></span>								
				<span className={classnames(styles.tabs_typepack_text_btn)}>Прямое ЖД</span>									
			</label>
		</div>

	);

}