import React from 'react';

import { getDocName } from '../../utils';

import styles from './EducationCoursesCheck.module.scss';

export const EducationCoursesCheck = ({ courses }) => (
  <div>
    {courses.map(({ name, year, certificate }, idx) => (
      <div key={`${name}-${year}`} className={styles.Course}>
        <div className={styles.Course__InfoArea}>
          {name} - {year}
        </div>
        <div className={styles.Course__FileName}>
          <a
            href={certificate}
            className={styles.Link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {certificate ? getDocName({ doc: certificate }) : ''}
          </a>
        </div>
      </div>
    ))}
  </div>
);
