import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { ROUTES, ROLES } from '../../consts';
import { getProfile } from '../../store/profile';
import { Panel } from '../shared/Panel';
import { getCompanies, getLoading, getbankAccounts } from '../../store/companies';
import { Documents } from './Documents';
import { Esign } from './Esign';
import { Message } from '../shared/Message';
import { LoadingPage } from '../hocs/withLoading/LoadingPage';
import { AddCard } from './AddCard';
import { Card } from './Card';
import { Info } from './Info';
import { Offer } from './Offer';
import { CheckRole } from '../hocs/Checker';
import { withLoading } from '../hocs/withLoading';

import infoStyles from './Info.module.scss';
import styles from './Company.module.scss';

export const Company = withLoading(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const companies = useSelector(getCompanies);
  const profile = useSelector(getProfile);
  const bankAccounts = useSelector(getbankAccounts);

  const addCompany = ()=> {

    if (companies.length < 1){
      return (<AddCard />);
    }    

/*    if (profile.unlim || (profile.role == "user" && companies.length < 2) || companies.length < 1){
      return (<AddCard />);
    }*/ /*else return (
      <div className={infoStyles.PanelWrapper}>
        <Panel
          title="Чтобы иметь возможность добавить дополнительные компании
          обратитесь к администратору."
          fluid
        >
          Администратор сможет снять ограничение на число компаний,
          созданных с одного аккаунта.
        </Panel>
      </div>
      );*/
  }

  return (
    <div>
      {loading && companies.length === 0 ? (
        <LoadingPage withWrapper />
      ) : (
        <>
          {companies.length === 0 && (
            <Message>
              Для того, чтобы оставить заявку в системе, требуется добавить компанию
            </Message>
          )}
          {companies.length >= 1 && !companies.every(({verified})=>verified) && (
            <Message>
              Для того что-бы верифицировать свою компанию, добавьте ее банковские реквизиты в профиле компании и пополните счет на любую сумму от ее лица.
            </Message>
          )}          
          <div className={styles.CardsList}>
            {companies.map((company) => (
              <Card
                key={company._id}
                company={company}
              />
            ))}
            {addCompany()}
          </div>
        </>
      )}
      <div id="content">
        <Routes>
          <Route path={ROUTES.PROFILE_COMPANY_INFO} element={<Info />} />
          <Route path={ROUTES.PROFILE_COMPANY_DOCS} element={<Documents />} />
          <Route path={ROUTES.PROFILE_COMPANY_OFFER} element={ <CheckRole                            
            roles={{
              [ROLES.LOGIST]: Offer                      
            }} redirect={ROUTES.FULL_PROFILE_COMPANY}/> }  
          />          
          {/*<Route path={ROUTES.PROFILE_COMPANY_ESIGN} Component={Esign} />*/}
        </Routes>
      </div>
    </div>
  );
});
