import React, { useEffect } from 'react';
import { useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getProfile } from '../../store/profile';
import { getUser, loadUser } from '../../store/users';
import { Title } from '../shared/Title';
import { GoBackLink } from '../shared/GoBackLink';
import { PartnerInformation } from './PartnerInformation';

export const PartnerProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const user = useSelector(getUser);
  const { id } = useParams();

  useEffect(() => {
    if (profile._id) {
      dispatch(loadUser(id));
    }
  }, [dispatch, id, profile._id]);

  return (
    <main>
      <Title>Профиль пользователя</Title>
      <GoBackLink />
      {user && <PartnerInformation profile={user} />}
    </main>
  );
};
