import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { ROUTES, FILTERS } from '../../consts';
import { formatMoney } from '../../utils';
import { SHIPPING_OPTIONS } from '../../configs/request';
import { getRequestClientCompletedFilters } from '../../store/filters';
import {
  applyListFilters,
  loadRequests,
  getRequests,
  getCount,
  getLoading,
  getUnreadDraftsCount,
  loadDrafts,
} from '../../store/requests';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';

import styles from './Requests.module.scss';

const REQUEST_HEADERS = [
  {
    label: '№ Заявки',
    value: 'number',
    sortable: true,
    render: (value) => <span style={{ color: '#1890FF' }}>{value}</span>,
  },
  {
    label: 'Дата завершения заявки',
    value: 'updatedAt',
    render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    sortable: true,
  },
  {
    label: 'Тип перевозки',
    value: 'shipping',
    render: (value) => SHIPPING_OPTIONS[value]?.label,
  },
  {
    label: 'Стоимость заявки',
    value: 'price',
    render: formatMoney,
  },
  {
    label: '',
    value: 'rowActions',
    render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
  },
];

export const RequestsClientCompleted = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const requests = useSelector(getRequests);
  const countItems = useSelector(getCount);
  const filters = useSelector(getRequestClientCompletedFilters);
  const filtersKey = FILTERS.REQUESTS_CLIENT_COMPLETED;
  const unreadCount = useSelector(getUnreadDraftsCount);

  useEffect(() => {
    dispatch(loadRequests(filters));
    dispatch(loadDrafts);
  }, [dispatch, filters]);

  return (
    <>
      {requests?.length > 0 ? (
        <Table
          loading={loading}
          items={requests}
          headers={REQUEST_HEADERS}
          onRowClick={(item) =>
            navigate(`${ROUTES.FULL_REQUESTS_DECLARATIONS}/client/${item._id}`)
          }
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) =>
            dispatch(applyListFilters(filtersKey, filters))
          }
        />
      ) : (
        <Message type="info">У Вас пока нет завершенных заявок</Message>
      )}
    </>
  );
};
