import React from 'react';
import {format} from 'date-fns';
import {ru} from 'date-fns/locale'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import {formatMoney, numberInWords} from '../../../utils';

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

Font.register({
  family: 'Thin',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-thin-webfont.ttf',
});

const styles = StyleSheet.create({
  main: {
    fontFamily: 'Roboto',
    padding: 10,
  },
  thin: {
    fontFamily: 'Thin',
    fontSize: 10,
  },
  logo: {
    width: '100px',
    height: '13px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  deadline: {
    fontSize: 10,
  },
  image: {
    width: '100px',
    height: '100px',
  },
  supplier: {
    marginTop: 10,
    marginBottom: 4,
    width: 'auto',
    fontSize: 11,
  },
  buyer: {
    fontSize: 11,
    marginTop: 4,
    marginBottom: 4,
  },
  body: {
    borderStyle: 'solid',
    borderWidth: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    fontSize: 11,
  },
  tableRow: {flexDirection: 'row'},
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 5,
  },
  tableCol1: {
    width: '12.5%',
  },
  tableCol2: {
    width: '25%',
  },
  tableCol3: {
    width: '37.5%',
  },
  tableCol4: {
    width: '50%',
  },
  tableCol8: {
    width: '100%',
  },
  tableCell: {margin: 'auto', marginTop: 5, fontSize: 10},
  h1: {
    fontSize: 20,
    marginTop: 15,
    marginBottom: 10,
    padding: 5,
    borderStyle: 'solid',
    borderBottomWidth: 1,
  },
});

export const PdfDocument = ({payment, systemAccount}) => {
  const createdAt = format(new Date(payment.createdAt), 'dd MMMM yyyy', {locale: ru});
  const deadline = format(
    new Date().setDate(new Date(payment.createdAt).getDate() + 14),
    'dd.MM.yyyy'
  );

  const amount = formatMoney(payment.amount);

  return (
    <Document title="Счет">
      <Page style={styles.main}>
        <View style={styles.header}>
          <Image style={styles.logo} source={'/pdflogo.png'}/>
          <Text style={styles.deadline}>
            Оплату необходимо произвести до {deadline}
          </Text>
          <Image
            style={styles.image}
            source={`https://api.qrserver.com/v1/create-qr-code/?data=${window.location}`}
          />
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={{...styles.tableCol, ...styles.tableCol4}}>
              <Text>{systemAccount.name}</Text>
              <Text style={styles.thin}>Банк получателя</Text>
              <Text style={styles.thin}></Text>
            </View>
            <View style={{...styles.tableCol, ...styles.tableCol1}}>
              <Text>БИК</Text>
              <Text>Сч. №</Text>
            </View>
            <View style={{...styles.tableCol, ...styles.tableCol3}}>
              <Text>{systemAccount.bik}</Text>
              <Text>{systemAccount.corrAccount}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{...styles.tableCol, ...styles.tableCol4}}>
              <Text>ИНН 7751186633&nbsp;&nbsp;&nbsp;КПП 775101001</Text>
              <Text>ООО "МОЙ ДЕКЛАРАНТ"</Text>
              <Text style={styles.thin}>Получатель</Text>
            </View>
            <View style={{...styles.tableCol, ...styles.tableCol1}}>
              <Text>Сч. №</Text>
            </View>
            <View style={{...styles.tableCol, ...styles.tableCol3}}>
              <Text>{systemAccount.bankAccount}</Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={styles.h1}>
            Счет на оплату №{payment.number} от {createdAt}
          </Text>
        </View>
        <View style={styles.supplier}>
          <Text>
            <Text style={styles.thin}>Поставщик:</Text> ООО "МОЙ ДЕКЛАРАНТ", ИНН 7751186633,
            КПП 775101001, ОГРН 1207700375410. АДРЕС 108811, РФ, г. Москва, п. Московский,
            Киевское шоссе, 22-й километр, домовладение 4, строение 5, блок Е, этаж 5, 
            офис 502Е-21, тел.: 8 (499) 398-86-93
          </Text>
        </View>
        <View style={styles.buyer}>
          <Text>
            <Text style={styles.thin}>Покупатель:</Text> {payment.company?.name}
            , ИНН {payment.company?.itn} КПП {payment.company?.kpp}. Счет{' '}
            {payment.bankAccount?.accountNumber} в {payment.bankAccount?.name},
            БИК {payment.bankAccount?.bik}, Тел: {payment.company?.phone}
          </Text>
        </View>
        <View style={styles.buyer}>
          <Text>
            <Text style={styles.thin}>Основание:</Text> Договор №{' '}
            {payment.company?.number}{' '}
            {payment.company?.createdAt &&
            `от ${format(new Date(payment.company.createdAt), 'dd MMMM yyyy', {locale: ru})}`}
          </Text>
        </View>
        <View style={styles.table}>
          <View style={{...styles.tableRow, textAlign: 'center'}}>
            <View style={{...styles.tableCol, width: '20px'}}>
              <Text>№</Text>
            </View>
            <View
              style={{...styles.tableCol, flexGrow: 1, textAlign: 'left'}}
            >
              <Text>Товары (работы, услуги)</Text>
            </View>
            <View style={{...styles.tableCol, flexBasis: '50px'}}>
              <Text>Кол-во</Text>
            </View>
            <View style={{...styles.tableCol, flexBasis: '30px'}}>
              <Text>Ед.</Text>
            </View>
            <View style={{...styles.tableCol, flexBasis: '100px'}}>
              <Text>Цена</Text>
            </View>
            <View style={{...styles.tableCol, flexBasis: '100px'}}>
              <Text>Сумма</Text>
            </View>
          </View>
          <View style={{...styles.tableRow, textAlign: 'center'}}>
            <View style={{...styles.tableCol, ...styles.thin, width: '20px'}}>
              <Text>1</Text>
            </View>
            <View
              style={{
                ...styles.tableCol,
                ...styles.thin,
                flexGrow: 1,
                textAlign: 'left',
              }}
            >
              <Text>Пополнение лицевого счета "Мой Декларант"</Text>
            </View>
            <View
              style={{...styles.tableCol, ...styles.thin, flexBasis: '50px'}}
            >
              <Text>1</Text>
            </View>
            <View
              style={{...styles.tableCol, ...styles.thin, flexBasis: '30px'}}
            >
              <Text>шт.</Text>
            </View>
            <View
              style={{...styles.tableCol, ...styles.thin, flexBasis: '100px'}}
            >
              <Text>{amount}</Text>
            </View>
            <View
              style={{...styles.tableCol, ...styles.thin, flexBasis: '100px'}}
            >
              <Text>{amount}</Text>
            </View>
          </View>
        </View>
        {[{label: 'Итого', amount},
          {label: 'Без налога (НДС)', amount: '-'},
          {label: 'Всего к оплате', amount}
        ].map((value, index) => (<View
            key={index}
            style={{
              ...styles.thin,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal: 5,
              marginVertical: 3,
            }}
          >
            <Text>{value.label}</Text>
            <Text>
              {value.amount}
            </Text>
          </View>)
        )}
        <View
          style={{
            ...styles.thin,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 5,
            marginVertical: 3,
            marginTop: 15
          }}
        >
          <Text>
            Всего наименований 1, на сумму {amount} руб.
          </Text>
        </View>
        <View
          style={{
            fontWeight: '900',
            fontSize: 10,
            // fontFamily: 'Bold',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 5,
            marginVertical: 3,
          }}
        >
          <Text>
            {numberInWords(payment.amount, true)}
          </Text>
        </View>
        <View
          style={{
            ...styles.thin,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 5,
            marginVertical: 3
          }}
        >
          <Text>
            Оплата данного счета означает согласие с условиями оферты.
            Уведомление об оплате обязательно.
          </Text>
        </View>
        <Image style={{position: "absolute", width: '90px', top: 720, left: 240}} source={'/shared/stamp.png'}/>
        <Image style={{position: "absolute", width: '90px', top: 630, left: 160}} source={'/shared/sign.png'}/>
        <Image style={{position: "absolute", width: '90px', top: 630, left: 440}} source={'/shared/sign.png'}/>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 12,
            marginTop: 100,
            width: 'auto',
            justifyContent: 'space-between',
            paddingHorizontal: 50,
          }}
        >
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text>Руководитель:</Text>
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text>Бухгалтер: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 12,
            marginTop: 4,
            justifyContent: 'space-between',
            paddingHorizontal: 50,
          }}
        >
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text>Левкина С.И. / </Text>
            <View
              style={{
                width: '100px',
                borderStyle: 'solid',
                borderBottomWidth: 1,
                marginLeft: 10,
              }}
            />
          </View>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text>Левкина С.И. / </Text>
            <View
              style={{
                width: '100px',
                borderStyle: 'solid',
                borderBottomWidth: 1,
                marginLeft: 10,
              }}
            />
          </View>
        </View>

      </Page>
    </Document>
  );
};
