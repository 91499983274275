import React, { useState } from 'react';

import { createSystemRequest } from '../api';
import SuccessIcon from '../assets/Success.svg';
import { Button } from '../components/shared/Button';
import { Message } from '../components/shared/Message';
import { Title } from '../components/shared/Title';
import { Modal } from '../components/shared/Modal';
import { useScrollTop } from '../hooks';

export const Certification = () => {
  useScrollTop();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSend = async () => {
    await createSystemRequest({ text, type: 'Сертификация' });
    setOpen(false);
    setText('');
    setSuccess(true);
  };

  return (
    <div>
      <Title>Сертификация</Title>
      {success ? (
        <div
          style={{ display: 'flex', alignItems: 'center', padding: '5rem 0' }}
        >
          <img
            style={{ marginRight: '1rem' }}
            src={SuccessIcon}
            alt="success"
          />
          Ваш заявка сохранена. Ожидайте обратной связи.
        </div>
      ) : (
        <div
          style={{
            background: '#FFFFFF',
            border: '1px solid #C9C9C9',
            boxSizing: 'border-box',
            borderRadius: '5px',
            padding: '26px',
            maxWidth: '1014px',
          }}
        >
          <Message type="info" full style={{ marginBottom: 0 }}>
            <div style={{ display: 'flex', width: '100%', lineHeight: '23px' }}>
              <div>
                Необходимо организовать сертификацию товаров?
                <br />
                Оставьте, пожалуйста, заявку и мы обязательно вам поможем.
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: '1',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Button filled onClick={() => setOpen(true)}>
                  Оставить заявку
                </Button>
              </div>
            </div>
          </Message>
        </div>
      )}

      <Modal open={open} onClose={() => setOpen(false)}>
        <Title style={{ textAlign: 'center' }}>
          Заявка на сертификацию товаров
        </Title>

        <textarea
          style={{
            borderColor: '#D9D9D9',
            borderRadius: '4px',
            height: '188px',
            width: '30rem',
            resize: 'vertical',
            outline: 'none',
            padding: '0.5rem',
            marginBottom: '3rem',
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button filled onClick={handleSend}>
            Отправить
          </Button>
        </div>
      </Modal>
    </div>
  );
};
