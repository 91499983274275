import React from 'react';

import { Button } from '../Button';
import { Modal } from '../Modal';
import { Title } from '../Title';

import styles from './ConfirmModal.module.scss';

export const ConfirmModal = ({ open, onConfirm, onReject, message }) => {
  return (
    <Modal open={open} onClose={onReject}>
      {({handleClose}) => (
        <div className={styles.Wrapper}>
          <Title as="h2">
            {message || 'Вы уверены, что хотите выполнить данное действие?'}
          </Title>
          <div className={styles.ButtonArea}>
            <Button onClick={onConfirm}>Да</Button>
            <Button filled onClick={handleClose}>
              Нет
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
