import React from 'react';

import { Button } from '../shared/Button';
import { Input } from '../shared/Input';
import { Modal } from '../shared/Modal';
import { Title } from '../shared/Title';

import styles from './EditModal.module.scss';

export const EditModal = ({ value, onChange, open, onClose, onSubmit }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.Wrapper}>
        <Title>Редактирование</Title>
        <div className={styles.Content}>
          <div className={styles.LineWrapper}>Стоимость</div>
          <div className={styles.LineWrapper}>
            <Input value={value} onChange={onChange} />
          </div>
        </div>
        <Button
          onClick={() => {
            onSubmit();
            onClose();
          }}
          filled
          fluid
        >
          Сохранить
        </Button>
      </div>
    </Modal>
  );
};
