import { API_ROUTES } from '../consts';
import { ApiHelper, generateQuery } from '../utils';

export const fetchReviews = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.REVIEWS}${queryString}`);
};
export const createReview = (payload) =>
  ApiHelper.post(API_ROUTES.REVIEWS, payload);
export const updateReview = (id, payload) =>
  ApiHelper.patch(`${API_ROUTES.REVIEWS}/${id}`, payload);
