import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { takeRequest } from '../../../store/requests';
import { Button } from '../../shared/Button';
import { ConfirmModal } from '../../shared/ConfirmModal';

export const GetRequestForWork = ({ requestId, disabled, small, offset }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const tooltip = disabled
    ? {
        title: 'Взять заявку в работу',
        content: 'Будет доступно после прохождения верификации',
      }
    : null;

  return (
    <div style={{ textAlign: 'center' }}>
      <div>
        <Button
          filled
          small={small}
          disabled={disabled}
          tooltip={tooltip}
          offset={offset}
          onClick={() => setOpen(true)}
        >
          Взять в работу
        </Button>
        <ConfirmModal
          message="Вы уверены, что хотите взять заявку в работу?"
          open={open}
          onConfirm={() => dispatch(takeRequest(requestId))}
          onReject={() => setOpen(false)}
        />
      </div>
    </div>
  );
};
