import React from 'react';

import { AccountsTable } from './AccountsTable';
import { getAccountsPartnersFilters } from '../../store/filters';
import { FILTERS } from '../../consts';

export const AccountsPartners = () => (
  <AccountsTable
    getFilters={getAccountsPartnersFilters}
    filtersKey={FILTERS.ACCOUNTS_PARTNERS}
    messageSubject="партнеров"
  />
);
