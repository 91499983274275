import React, { useState, useEffect, useRef } from 'react';
import styles from './DropdownList.module.sass';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

export const SimpleDropdownList = ({list, proposal, width, nameInput, objStyles, name, stepsData, setStateCb}) => {

	const [checkState, setCheckState] = useState(false);
	const [idCheckbox, setIdCheckbox] = useState(uuid());
	const wrapDropdown = useRef(null);
	

	const handlerChange = (event) => {

		if (event.target.closest("."+classnames(styles.moduleCustoms_checkbox))){
			setCheckState(prev=>!prev);
		}
	}

	const handlerClick = (event) => {

		let element, title, datatype;

		if (element = event.target.closest("."+classnames(styles.moduleCustoms_choice_item))){
			title = element.textContent;
			datatype = element.getAttribute("data-type");
			if (title && datatype){
				setStateCb({title:title, datatype:datatype});
			}
			setCheckState(false);
		}
	}

	const clickOutHandler = (event) => {

		let element;	

		if (element = event.target.closest("."+classnames(styles.moduleCustoms_wrap_input))){
			if (element !== wrapDropdown.current && checkState){
				setCheckState(false);
			}
		} else if (checkState){
			setCheckState(false);			
		}
	}

	useEffect(()=>{
		document.addEventListener("click", clickOutHandler);
		return ()=>{document.removeEventListener("click", clickOutHandler);}
	},[checkState]);	


	return (
		<>
			{(typeof list == "object") && (list.length>0) && (<div className={classnames(styles.moduleCustoms_wrap_input)} style={{width: width ? width : '100%', ...objStyles}}
				onClick={handlerClick}
				onChange={handlerChange}
				ref={wrapDropdown}
			>
				{nameInput && (<div className={classnames(styles.moduleCustoms_input_name)}>{nameInput}</div>)}
				<input type="radio" className={classnames(styles.moduleCustoms_radio)} name={name} value="dropdownlist" checked/>
				<input type="checkbox" id={idCheckbox} className={classnames(styles.moduleCustoms_checkbox)}
					checked={checkState}
				/>
				<label htmlFor={idCheckbox} className={classnames(styles.moduleCustoms_dropdwn, {
					[styles["choosen"]]:Boolean(typeof stepsData === "object" && "title" in stepsData)
				})}>
					<span className={classnames(styles.moduleCustoms_default_item)}>{proposal}</span>
					<span className={classnames(styles.moduleCustoms_choosen_item)}>{stepsData?.title}</span>
					<div className={classnames(styles.moduleCustoms_dropdown_icon)}></div>
				</label>
				<ul className={classnames(styles.moduleCustoms_dropdown_list)}>
					{list.map(({title, datatype})=>(<li className={classnames(styles.moduleCustoms_choice_item)} data-type={datatype}>{title}</li>))}
				</ul>
			</div>)}
		</>
	);
}
