import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, Navigate, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES, ROLES, FILTERS } from '../consts';
import { HeaderWithChapter } from '../components/shared/HeaderWithChapter';
import { GoBackLink } from '../components/shared/GoBackLink';
import styles from './OffersLogisticsClient.module.sass';
import { SimpleDropdownList } from '../components/shared/DropdownList';
import { SelectionSwitcher } from '../components/Logistics/Offers';
import { OfferTab } from '../components/Logistics/Offers';
import { Loader } from '../components/shared/Loader';
import toast from 'react-hot-toast';
import classnames from 'classnames';

import {
	changeDepartureCountry,
	changeDeparturePoint,
	changeTypeStation,
	getDeparturePointList,
	getPointDeparture,
	getTypeStation,
	getDepartureCountryList,
	getDepartureCountry,
	getDeparturePoints,
	getTypeContainer,
	changeTypeContainer,
	changeArrivalCountry,
	getArrivalCountryList,
	getArrivalCountry,
	changeArrivalTerminal,
	getArrivalCityTerminalList,
	getArrivalCityTerminal,
	getArrivalPointList,
	getArrivalPoint,
	changeArrivalPoint,
	getCustomsList,
	getCustoms,
	changeCustoms,
	getStateValidForm,
	getLoading,
	getLoadup,
	getOffers,
	getLoadingMessage,
	getStateForm,
	loadingOffers,
	loadupOffers,
	getCount,
	getTotal,
	changeLoadup,
	checkSearchParams} from '../store/selectionOffers';


export const OffersLogisticsClient = () => {

	const dispatch = useDispatch();	
	const departureCountryList = useSelector(getDepartureCountryList);
	const arrivalCountryList = useSelector(getArrivalCountryList);
	const arrivalCountry = useSelector(getArrivalCountry);
	const departureCountry = useSelector(getDepartureCountry);
	const departurePointList = useSelector(getDeparturePointList);
	const departurePoint = useSelector(getPointDeparture);
	const typeStation = useSelector(getTypeStation);
	const departurePoints = useSelector(getDeparturePoints);
	const typeContainer = useSelector(getTypeContainer);
	const arrivalCityTerminalList = useSelector(getArrivalCityTerminalList);
	const arrivalCityTerminal = useSelector(getArrivalCityTerminal);
	const arrivalPointList = useSelector(getArrivalPointList);
	const arrivalPoint = useSelector(getArrivalPoint);
	const customsList = useSelector(getCustomsList);
	const customs = useSelector(getCustoms);
	const isValid = useSelector(getStateValidForm);
	const loading = useSelector(getLoading);
	const loadup = useSelector(getLoadup);
	const offers = useSelector(getOffers);	
	const loadingMessage = useSelector(getLoadingMessage);
	const state = useSelector(getStateForm);
	const count = useSelector(getCount);
	const total = useSelector(getTotal);

	
	const scrollTop = useRef(0);

	const listTypes = [
		{
			title: "Морской порт",
			type: "sea"
		},
		{
			title: "Ж/Д станция",
			type: "train"
		}		
	];
	const listContainer = [
		{
			title: "20'",
			type: "twenty"
		},
		{
			title: "40'",
			type: "forty"
		},
		{
			title: "40HC",
			type: "fortyhc"
		}				
	];	

	const allowedTypesFunc = (type) => {
		return (departurePoints[type][departureCountry.datatype])? false : true;
	}

	const handlerClick = (event) => {
		if (event.target.classList.contains(classnames(styles.active))){
			scrollTop.current = document.documentElement.scrollTop;	
			dispatch(loadingOffers(toast)); 
		}
	}

	useEffect(()=>{	
		dispatch(checkSearchParams(toast));
		const scrollHandler = (e) => {			
			if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100){				
				scrollTop.current = e.target.documentElement.scrollTop;
				dispatch(loadupOffers(toast));			
			}		 
		}
		document.addEventListener("scroll", scrollHandler);					
		return () => {			
			document.removeEventListener("scroll", scrollHandler);
		}
	}, []);

	useEffect(()=>{
        window.scrollTo({
          top: scrollTop.current,
          behavior: "instant",
        });
	},[loadup]);

	return (
		<>
			<HeaderWithChapter title="Предложения" chapter="Логистика"/>
			<div className={classnames(styles.wrap_backandnew)}>
				<GoBackLink />						
			</div>
			<div className={classnames(styles.wrap_search_panel)}>
				<div className={classnames(styles.wrap_search_menu)}>
					<div className={classnames(styles.wrap_search_cloumn)}>
						<div className={classnames(styles.descr_column)}>Откуда</div>
						<SimpleDropdownList
							nameInput="Страна"
							list={departureCountryList}
							proposal="Выберите стану"
							width="100%"
							stepsData={departureCountry}
							setStateCb={(state)=>{dispatch(changeDepartureCountry(state))}}
							name="countryDep"							
						/>
						<SimpleDropdownList
							nameInput="Пункт отправления"
							list={departurePointList}
							proposal="Выберите пункт"
							width="100%"
							stepsData={departurePoint}
							setStateCb={(state)=>{dispatch(changeDeparturePoint(state))}}
							name="pointDep"							
						/>
						<SelectionSwitcher
							allowedTypesFunc={allowedTypesFunc}
							listTypes={listTypes}
							selectedType={typeStation}
							setStateCb={(state)=>{dispatch(changeTypeStation(state))}}
						/>
						<SelectionSwitcher
							listTypes={listContainer}
							selectedType={typeContainer}
							setStateCb={(state)=>{dispatch(changeTypeContainer(state))}}
						/>															
					</div>
					<div className={classnames(styles.wrap_search_cloumn)}>
						<div className={classnames(styles.descr_column)}>Куда</div>
						<SimpleDropdownList
							nameInput="Страна"
							list={arrivalCountryList}
							proposal="Выберите стану"
							width="100%"
							stepsData={arrivalCountry}
							setStateCb={(state)=>{dispatch(changeArrivalCountry(state))}}
							name="countryArr"							
						/>
						<SimpleDropdownList
							nameInput="Порт прибытия"
							list={arrivalCityTerminalList}
							proposal="Выберите порт"
							width="100%"
							stepsData={arrivalCityTerminal}
							setStateCb={(state)=>{dispatch(changeArrivalTerminal(state))}}
							name="terminalArr"							
						/>
						<SimpleDropdownList
							nameInput="Пункт назначения"
							list={arrivalPointList}
							proposal="Выберите пункт"
							width="100%"
							stepsData={arrivalPoint}
							setStateCb={(state)=>{dispatch(changeArrivalPoint(state))}}
							name="pointArr"							
						/>
						<SimpleDropdownList
							nameInput="Таможенное оформление"
							list={customsList}
							proposal="Выберите пункт"
							width="100%"
							stepsData={customs}
							setStateCb={(state)=>{dispatch(changeCustoms(state))}}
							name="customs"							
						/>																			
					</div>
				</div>
				<div className={classnames(styles.btn_request, {[styles["active"]]:isValid})} onClick={handlerClick}>Расчитать</div>
			</div>
			<div className={classnames(styles.wrap_offers)}>				
				{Boolean(!offers?.length) && (<div className={classnames(styles.wrap_loading)}>
					{Boolean(loading) && (<Loader />)}
					{Boolean(!loading) && (<div className={classnames(styles.loading_message)}>{loadingMessage}</div>)}								
				</div>)}
				<OfferTab />
			</div>			
		</>	
	);
}

	