import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './OffersMaker.module.sass';
import classnames from 'classnames';

import { Panel } from './Panel';
import { View } from './View';
import { Loader } from '../../../shared/Loader';
import { getMakeOffers, getLoadingState } from '../../../../store/makeOffers';
import { ModalMessage } from '../../ModalMessage';

export const OffersMaker = () => {

	const dispatch = useDispatch();
	const state = useSelector(getMakeOffers);
	const loading = useSelector(getLoadingState);

	useEffect(()=>{
		console.log(state);
	},[state]);



	return(
		<>
			{loading && (<div className={classnames(styles.wrap_loader)}><Loader /></div>)}
			{!loading && (<div className={classnames(styles.new_pack)}>
				<View />
				<Panel />
				<ModalMessage />			
			</div>)}
		</>
	);
}