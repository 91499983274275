import { omit } from 'lodash';

import { updateProfile } from '../../api';
import { getEducation } from './selectors';
import { getProfileId } from '../profile';
import { INITIAL_COURSE } from './reducer';

export const UPDATE_VALUE = '@education/UPDATE_VALUE';
export const TOGGLE_OPEN = '@education/TOGGLE_OPEN';
export const UPDATE_EDUCATION = '@education/UPDATE_EDUCATION';
export const ADD_COURSE = '@education/ADD_COURSE';
export const DELETE_COURSE = '@education/DELETE_COURSE';
export const EDIT_COURSE = '@education/EDIT_COURSE';
export const SET_LOADING = '@education/SET_LOADING';

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const toggleOpen = () => ({
  type: TOGGLE_OPEN,
});

export const updateValue = (payload) => ({
  type: UPDATE_VALUE,
  payload,
});

export const updateEducation = (payload) => ({
  type: UPDATE_EDUCATION,
  payload,
});

export const addCourse = () => ({
  type: ADD_COURSE,
});

export const deleteCourse = (payload) => ({
  type: DELETE_COURSE,
  payload,
});

export const editCourse = (payload) => ({
  type: EDIT_COURSE,
  payload,
});

export const saveEducationData = async (dispatch, getState) => {
  dispatch(setLoading(true));
  const state = getState();
  const educationState = getEducation(state);
  const value = omit(educationState, [
    'open',
    'errors',
    'newCourse',
    'loading',
  ]);

  /* push the data from the new course form if any */
  if (
    Object.keys(educationState.newCourse).some(
      (key) => educationState.newCourse[key].length > 0
    )
  ) {
    value.courses.push({ ...educationState.newCourse });
    dispatch(
      updateEducation({
        courses: [...value.courses],
        newCourse: INITIAL_COURSE,
      })
    );
  }

  try {
    const { profile } = await updateProfile(
      { key: 'education', value },
      getProfileId(state)
    );
    const newEducation = profile.education;
    if (
      !profile.education.errors ||
      Object.keys(profile.education.errors).length === 0
    ) {
      newEducation.open = false;
    }
    dispatch(updateEducation(newEducation));
  } catch (e) {
    console.log(e);
  }
  dispatch(setLoading(false));
};
