import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './OfferTab.module.sass';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';

import { SpreadOfferTab } from './SpreadOfferTab';
import { makeDate, costDecore } from '../../../utils';
import { getOffers } from '../../../store/selectionOffers';
import { createOfferDocX } from '../../../utils/createOfferDocX';
import { SenderEmailModal } from './SenderEmailModal';

export const OfferTab = () => {

	const makeIataCode = (string) => {
		return string.toUpperCase().slice(0, 3);
	}
	const offers = useSelector(getOffers);
	const [modalState, setModalState] = useState({id:false, open: false});

	return(
		<>
		{Boolean(offers?.length) && (offers.map((item)=>(
			<div className={classnames(styles.wrap_offer)} key={item._id}>
				<div className={classnames(styles.offer_left_block)}>
					<div className={classnames(styles.offer_wrap_info)}>
						<div className={classnames(styles.offer_expire)}>
							<div className={classnames(styles.offer_expire_icon)}></div>
							<div className={classnames(styles.offer_expire_descr)}>до {makeDate(item.expireDate)}</div>
						</div>								
						<div className={classnames(styles.offer_company_name)}>{item.companyName}</div>
						<div className={classnames(styles.offer_company_inn)}>
							<div className={classnames(styles.offer_company_item)}>ИНН</div>
							<div className={classnames(styles.offer_company_item)}>{item.companyInn}</div>							
						</div>
						<div className={classnames(styles.company_offer)}>{item.companyOffer}</div>								
					</div>							
				</div>
				<div className={classnames(styles.offer_right_block)}>
					<div className={classnames(styles.offer_company)}>
						<div className={classnames(styles.offer_result_sum)}>{item.name}</div>
						<div className={classnames(styles.offer_id)}>№{item.docId}</div>
					</div>
					<div className={classnames(styles.offer_wrap_route)}>
						<div className={classnames(styles.offer_start_route)}>
							<div className={classnames(styles.offer_wrap_popup)}>
								<div className={classnames(styles.offer_route_checkpoint)}>{item.cityDeparture}</div>
								<div className={classnames(styles.offer_route_country)}>{item.countryDeparture}</div>										
							</div>
						</div>
						<div className={classnames(styles.offer_view_route)}>
							{(item.typetransport === 1 && item.terminalCityArrival === item.cityArrival)
							&& (<>
									<div className={classnames(styles.route_line)}>
										<div className={classnames(styles.route_icon_point, styles.start)}></div>
									</div>
									<div className={classnames(styles.route_sea_type)}>
										<div className={classnames(styles.route_price)}>{item.seaCost}$</div>
										<div className={classnames(styles.route_transfer)}>{item.seaTransfer} дн</div>
									</div>
									<div className={classnames(styles.route_line)}>
										<div className={classnames(styles.route_icon_point, styles.end)}></div>
									</div>
								</>
							)}
							{(item.typetransport === 1 && item.terminalCityArrival !== item.cityArrival)
							&& (<>
									<div className={classnames(styles.route_line, styles.short)}>
										<div className={classnames(styles.route_icon_point, styles.start)}></div>
									</div>
									<div className={classnames(styles.route_sea_type)}>
										<div className={classnames(styles.route_price)}>{item.seaCost}$</div>
										<div className={classnames(styles.route_transfer)}>{item.seaTransfer} дн</div>
									</div>
									<div className={classnames(styles.route_line)}>
										<div className={classnames(styles.route_icon_point, styles.center)}></div>
										<div className={classnames(styles.route_IATA_code, styles.center)}>{makeIataCode(item.terminalCityArrival)}</div>
										<div className={classnames(styles.route_descr_code, styles.center)}>{`${item.terminalArrival}, ${item.terminalCityArrival}`}</div>
									</div>
									<div className={classnames(styles.route_train_type)}>
										<div className={classnames(styles.route_price)}>{item.trainCost}₽</div>
										<div className={classnames(styles.route_transfer)}>{item.trainTransfer} дн.</div>
									</div>								
									<div className={classnames(styles.route_line, styles.short)}>
										<div className={classnames(styles.route_icon_point, styles.end)}></div>
									</div>								
								</>
							)}	
							{(item.typetransport === 0)
							&& (<>
									<div className={classnames(styles.route_line)}>
										<div className={classnames(styles.route_icon_point, styles.start)}></div>
									</div>
									<div className={classnames(styles.route_train_type)}>
										<div className={classnames(styles.route_price)}>{item.trainCost}$</div>
										<div className={classnames(styles.route_transfer)}>{item.trainTransfer} дн.</div>
									</div>								
									<div className={classnames(styles.route_line)}>
										<div className={classnames(styles.route_icon_point, styles.end)}></div>
									</div>								
								</>
							)}																										
						</div>
						<div className={classnames(styles.offer_end_route)}>
							<div className={classnames(styles.offer_wrap_popup)}>
								<div className={classnames(styles.offer_route_checkpoint)}>
								{(item.typetransport === 1 && item.terminalCityArrival === item.cityArrival)
									?	`${item.terminalArrival} ${item.cityArrival}`
									: 	item.cityArrival}								
								</div>
								<div className={classnames(styles.offer_route_country)}>{item.countryArrival}</div>										
							</div>
							
						</div>								
					</div>
					<SpreadOfferTab
						nameTab="Условия оплаты"
						textContent={item.paymentterms}
					/>	
					<SpreadOfferTab
						nameTab="Условия пользования контейнером"
						textContent={item.conditionscarriage}
					/>										
					<div className={classnames(styles.wrap_addition)}>
						<div className={classnames(styles.addition_btn)} onClick={()=>setModalState({id:item._id, open: true})}>Отправить на email</div>
						<div className={classnames(styles.addition_btn)} onClick={createOfferDocX.bind(null,item)}>Скачать предложение</div>								
					</div>							
				</div>						
			</div>
		)))}
		<SenderEmailModal
			setModalState={setModalState}
			modalState={modalState}
		/>
	</>
	);
}

/*{
    "_id": "64b86389feca26d0342f9c16",
    "docId": 240,
    "companyProfile": "64aedcf0df2450380e159f71",
    "companyName": "ООО \"СИСТЕМА ПБО\"",
    "companyOffer": "Офер что надо",
    "expireDate": "2023-07-29T21:00:00.000Z",
    "conditionscarriage": "Условия перевозки",
    "paymentterms": "Условия оплаты",
    "typetransport": 0,
    "container": "twenty",
    "cityDeparture": "Шанхай",
    "countryDeparture": "Китай",
    "terminalArrival": "ВМТП",
    "cityArrival": "Санкт-Петербург",
    "countryArrival": "Россия",
    "seaCost": 100,
    "trainCost": 400,
    "seaTransfer": 12,
    "trainTransfer": 14,
    "customsVtt": true,
    "customsGtd": true
}*/

