import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ModalMessage.module.sass';
import classnames from 'classnames';
import {
	getOpenBoxName,
	getMessageBoxState,
	getMessageBoxData,
	setMessageBoxState } from '../../../store/modalMessage';

export const ModalMessage = () => {

	const dispatch = useDispatch();
	const boxName = useSelector(getOpenBoxName);
	const boxState = useSelector(getMessageBoxState(boxName));
	const boxData = useSelector(getMessageBoxData(boxName));

	const handlerClick = (event) => {		

		if (event.target.classList.contains(classnames(styles.overlay)) ||
			event.target.closest("."+classnames(styles.moduleCustoms_btn_cancel))){
			dispatch(setMessageBoxState({boxName: boxName, state: false }))
				
		} else if(event.target.closest("."+classnames(styles.moduleCustoms_btn_confirm))){
			dispatch(setMessageBoxState({boxName: boxName, state: false }));			
		}		
	}	

	return (
		<>
			{boxName && (<div className={classnames(styles.overlay,{[styles['open']]:boxState})} onClick={handlerClick}>
				<div className={classnames(styles.moduleCustoms)}>
					<div className={classnames(styles.moduleCustoms_body)}>					
						<div className={classnames(styles.moduleCustoms_main_message)}>{boxData.message}</div>	
						<div className={classnames(styles.moduleCustoms_descr_message)}>{boxData.descrMessage}</div>
						<div className={classnames(styles.moduleCustoms_wrap_btn_choice)}>
							<div className={classnames(styles.moduleCustoms_btn_confirm, styles.active)}>{boxData.resolve}</div>
						</div>									
					</div>
				</div>
			</div>)}		
		</>
	);
}