export const ACTION_OPTIONS = {
  refill: { value: 'refill', label: 'Пополнение' },
  withdrawals: { value: 'withdrawals', label: 'Снятие' },
};

export const TYPE_OPTIONS = {
  selfRefillCA: { value: 'selfRefillCA', label: 'Пополнение СК' },
  orderPaymentCA: { value: 'orderPaymentCA', label: 'Оплата заявки СК-> ТС' },
  orderRufundCA: { value: 'orderRufundCA', label: 'Возврат за заявку ТС-> СК' },
  orderReceiptDA: { value: 'orderReceiptDA', label: 'За заявку ТС-> СД' },
  orderReceiptPA: { value: 'orderReceiptPA', label: 'За заявку ТС-> СП' },
  orderReceiptSA: { value: 'orderReceiptSA', label: 'За заявку ТС-> СС' },
  addPaymentCADA: { value: 'addPaymentCADA', label: 'За доп листы СК-> СД' },
  addPaymentCASA: { value: 'addPaymentCASA', label: 'За доп листы СК-> СС' },
  selfDebitDA: { value: 'selfDebitDA', label: 'Вывод средств СД' },
  selfDebitPA: { value: 'selfDebitPA', label: 'Вывод средств СП' },
}