import { omit } from 'lodash';

import {
  getDeclarantById,
  getDeclarants,
  createRejectionReason,
  updateProfile,
} from '../../api';
import { FILTERS } from '../../consts';
import { getDeclarantsFilters, updateFilters } from '../filters';

export const LOAD_DECLARANTS_REQUEST = '@verification/LOAD_DECLARANTS_REQUEST';
export const LOAD_DECLARANTS_SUCCESS = '@verification/LOAD_DECLARANTS_SUCCESS';
export const LOAD_DECLARANTS_FAILURE = '@verification/LOAD_DECLARANTS_FAILURE';
export const LOAD_DECLARANT_REQUEST = '@verification/LOAD_DECLARANT_REQUEST';
export const LOAD_DECLARANT_SUCCESS = '@verification/LOAD_DECLARANT_SUCCESS';
export const LOAD_DECLARANT_FAILURE = '@verification/LOAD_DECLARANT_FAILURE';
export const UPDATE_VERIFY_STEP = '@verification/UPDATE_VERIFY_STEP';

const loadDeclarantsRequest = () => ({
  type: LOAD_DECLARANTS_REQUEST,
});

const loadDeclarantsSuccess = (payload) => ({
  type: LOAD_DECLARANTS_SUCCESS,
  payload,
});

const loadDeclarantsFailure = (payload) => ({
  type: LOAD_DECLARANTS_FAILURE,
  payload,
});

const loadDeclarantRequest = () => ({
  type: LOAD_DECLARANT_REQUEST,
});

const loadDeclarantSuccess = (payload) => ({
  type: LOAD_DECLARANT_SUCCESS,
  payload,
});

const loadDeclarantFailure = (payload) => ({
  type: LOAD_DECLARANT_FAILURE,
  payload,
});

const updateVerifyStep = (payload) => ({
  type: UPDATE_VERIFY_STEP,
  payload,
});

export const applyFilters = (filters) => async (dispatch) => {
  dispatch(loadDeclarantsRequest());

  try {
    dispatch(
      updateFilters({ key: FILTERS.DECLARANTS_TO_VERIFY, value: filters })
    );
    const data = await getDeclarants(filters);
    dispatch(loadDeclarantsSuccess(data));
  } catch (error) {
    loadDeclarantsFailure(error);
  }
};

export const loadDeclarants = () => async (dispatch, getState) => {
  dispatch(loadDeclarantsRequest());
  try {
    const state = getState();
    const filters = getDeclarantsFilters(state);
    const data = await getDeclarants(filters);
    dispatch(loadDeclarantsSuccess(data));
  } catch (error) {
    loadDeclarantsFailure(error);
  }
};

export const loadDeclarant = (id) => async (dispatch) => {
  dispatch(loadDeclarantRequest());
  try {
    const data = await getDeclarantById(id);
    dispatch(loadDeclarantSuccess(data));
  } catch (error) {
    loadDeclarantFailure(error);
  }
};

export const saveRejectionReasons = (
  rejections,
  declarantId,
  section
) => async () => {
  try {
    const rejectionItems = rejections.map((item) =>
      omit({ reason: item.option, comment: item.text }, ['key'])
    );
    const payload = {
      declarantId,
      profileSection: section,
      rejectionItems,
    };

    await createRejectionReason(payload);
  } catch (error) {
    console.log(error);
  }
};

export const updateSectionStep = (declarantId, section, stepToSet) => async (
  dispatch
) => {
  dispatch(updateVerifyStep({ section, stepToSet }));

  try {
    await updateProfile(
      { key: [section] + '.step', value: stepToSet },
      declarantId
    );
  } catch (error) {
    console.log(error);
  }
};

export const setValue = (declarantId, key, value) => async (dispatch) => {
  try {
    await updateProfile({ key, value }, declarantId);
  } catch (error) {
    console.log(error);
  }
};
