import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNewRequest, updateNewRequest } from '../../store/requests';
import { Title } from '../shared/Title';
import { DeliveryInfo } from './DeliveryInfo';
import { Documents } from './Documents';
import ExclamationBlue from '../../assets/ExclamationBlue.svg';

import styles from './Checklist.module.scss';

export const Checklist = () => {
  const dispatch = useDispatch();
  const { deliveryInfo, documents, errors } = useSelector(getNewRequest);
  const changeHandler = (key) => (subKey, value) =>
    dispatch(updateNewRequest({ key, subKey, value }));

  useEffect(() => {
    const top = document.getElementById('step-top');
    if (top) {
      top.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  return (
    <div id="step-top">
      <div className={styles.TitleWrapper}>
        <img
          src={ExclamationBlue}
          alt="ExclamationBlue"
          className={styles.Img}
        />
        <Title as="h2">
          Проверьте полноту и достоверность информации перед подачей заявки
        </Title>
      </div>
      <DeliveryInfo
        deliveryInfo={deliveryInfo}
        onChange={changeHandler('deliveryInfo')}
        errors={errors}
      />
      <Documents
        documents={documents}
        errors={errors}
        onChange={changeHandler('documents')}
      />
    </div>
  );
};
