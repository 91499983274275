import { CLEAR_REQUEST_FORM, UPDATE_REQUEST_FORM } from './actions';

const initialState = {
  requestForm: {
    company: '',
    bankAccount: '',
    amount: '',
  },
};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REQUEST_FORM:
      return {
        ...state,
        requestForm: { ...initialState.requestForm },
      };
    case UPDATE_REQUEST_FORM:
      return {
        ...state,
        requestForm: { ...state.requestForm, ...action.payload },
      };
    default:
      return state;
  }
};
