import React from 'react';
import { SubTabs } from '../shared/SubTabs';
import { ROUTES } from '../../consts';

export const DeclarantSubTabsHeader = () => {
  return (
    <SubTabs
      subTabs={[
        {
          title: 'Все',
          route: "../"+ROUTES.USERS_DECLARANTS,
          useMatchRoute: ROUTES.FULL_USERS_DECLARANTS,
          visible: true,
        },
        {
          title: 'Аккредитованные',
          route: "../"+ROUTES.USERS_DECLARANTS_ACCREDITED,
          useMatchRoute: ROUTES.FULL_USERS_DECLARANTS_ACCREDITED,
          visible: true,
        },
      ]}
    />
  );
};