import {
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILURE,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAILURE,
  LOAD_COMPANIES_REQUEST,
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANIES_FAILURE,
  LOAD_USER_COMPANIES_REQUEST,
  LOAD_USER_COMPANIES_SUCCESS,
  LOAD_USER_COMPANIES_FAILURE,
  UPDATE_USER_VALUE,
  UPDATE_USERS_VALUE,
} from './actions';

const initialState = {
  items: [],
  count: 0,
  loading: true,
  error: null,
  item: null,
  companies: [],
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_VALUE:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload,
        },
      };
    case UPDATE_USERS_VALUE:
      return {
        ...state,
        items: state.items.map((user) => {
          if (user._id === action.id) {
            return { ...user, ...action.payload };
          }

          return user;
        }),
      };
    case LOAD_USERS_REQUEST:
    case LOAD_USER_REQUEST:
    case LOAD_COMPANIES_REQUEST:
    case LOAD_USER_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USERS_SUCCESS:
      return {
        ...state,
        items: action.payload.users,
        count: action.payload.count,
        loading: false,
      };
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    case LOAD_COMPANIES_SUCCESS:
      return {
        ...state,
        items: action.payload.companies,
        count: action.payload.count,
        loading: false,
      };
    case LOAD_USER_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.payload.companies,
        loading: false,
      };
    case LOAD_USERS_FAILURE:
    case LOAD_USER_FAILURE:
    case LOAD_COMPANIES_FAILURE:
    case LOAD_USER_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
