import React, {useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getRequestForm, updateRequestForm, savePayment } from '../../../store/account';
import { getCompanies, loadBankAccounts, loadCompanies } from '../../../store/companies';
import { Button } from '../../shared/Button';
import { Input } from '../../shared/Input';
import { Message } from '../../shared/Message';
import { Select } from '../../shared/Select';

import styles from './PaymentForm.module.scss';

const INITIAL_ERROR = {
  amount: '',
  bankAccount: '',
  company: '',
};

export const PaymentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(INITIAL_ERROR);
  const createRefillRequest = () => {
    if (
      requestForm.amount > 0 &&
      accountOptions[requestForm?.bankAccount] &&
      companyOptions[requestForm?.company]
    ) {
      dispatch(savePayment(navigate));
      setErrors(INITIAL_ERROR);
    } else {
      setErrors({
        amount:
          requestForm.amount < 0
            ? 'Сумма счета не может иметь отрицательное значение'
            : requestForm.amount && requestForm.amount !== '0'
            ? ''
            : 'Укажите сумму счета',
        bankAccount: accountOptions[requestForm?.bankAccount]
          ? ''
          : 'Укажите реквизиты банка-плательщика | ' + requestForm?.bankAccount,
        company: companyOptions[requestForm?.company] ? '' : 'Укажите компанию',
      });
    }
  };
  const requestForm = useSelector(getRequestForm);
  const companies = useSelector(getCompanies);

  const {companyOptions, accountOptions} = useMemo(() => {
    const companyOptions = {};
    const accountOptions = {};

    companies.forEach((company) => {
      if (requestForm?.company === company._id) {
        if (company.bankAccounts) {
          company.bankAccounts.forEach((account) => {
            accountOptions[account._id] = {
              value: account._id,
              label: `${account.name} ${account.accountNumber}`,
            };
          });
        }
      }
      companyOptions[company._id] = {
        value: company._id,
        label: company.name,
      };
    });
    return {
      companyOptions, accountOptions
    }
  }, [companies, requestForm]);

  const handleChange = (key) => (e) =>
    dispatch(updateRequestForm({ [key]: e.target.value }));

  const handleSelectChange = (key) => ({ value }) => {
    dispatch(updateRequestForm({ [key]: value }));
  };

/*  useEffect(() => {
    dispatch(loadCompanies);
  }, [dispatch]);*/

/*  useEffect(() => {
    if (requestForm?.company) {
      dispatch(loadBankAccounts(requestForm.company));
    }
  }, [dispatch, requestForm.company]);*/

  return companyOptions[requestForm?.company] ? (
    <div className={styles.Form}>
      <div className={styles.Header}>Формирование счета на пополнение</div>
      <div className={styles.Content}>
        <div className={styles.Row}>
          <Message type="info">
            <div className={styles.Message}>
              После формирования счета он будет доступен для скачивания и
              просмотра в отдельном окне. Пополнение счета займет от 3 до 5
              рабочих дней.
            </div>
          </Message>
        </div>
        <div className={styles.Row}>
          <div className={styles.InputWrapper}>
            <div className={styles.Label}>Компания плательщик</div>
            <Select
              options={Object.values(companyOptions)}
              value={companyOptions[requestForm?.company]}
              onChange={handleSelectChange('company')}
            />
            <div className={styles.InputWrapper__Error}>{errors.company}</div>
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.InputWrapper}>
            <div className={styles.Label}>Реквизиты банка-плательщика</div>
            <Select
              isDisabled={!requestForm?.bankAccount}
              options={Object.values(accountOptions)}
              value={accountOptions[requestForm?.bankAccount]}
              onChange={handleSelectChange('bankAccount')}
              placeholder={`Для ${
                companyOptions[requestForm?.company].label
              } не задан банковский счет`}
            />
            <div className={styles.InputWrapper__Error}>
              {errors.bankAccount}
            </div>
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.InputWrapper}>
            <div className={styles.Label}>Сумма ( руб )</div>
            <Input
              placeholder="Сумма"
              value={requestForm.amount}
              onChange={handleChange('amount')}
              error={false}
            />
            <div className={styles.InputWrapper__Error}>{errors.amount}</div>
          </div>
        </div>
        <div className={styles.ButtonArea}>
          <Button filled onClick={createRefillRequest}>
            Сформировать счет
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};
