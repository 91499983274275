import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

import { ROUTES } from '../../consts';
import {
  getLoading,
  loadRequest,
  getRequest,
  conductTransactions,
  updateItem,
  updateRequest,
} from '../../store/requests';

import { Title } from '../shared/Title';
import { GoBackLink } from '../shared/GoBackLink';
import { ConfirmModal } from '../shared/ConfirmModal';
import { Button } from '../shared/Button';
import { Tabs } from '../shared/Tabs';
import { RequestDetailInfo } from './RequestDetailInfo';
import { LoadingPage } from '../hocs/withLoading/LoadingPage';
import { RequestDetailProgress } from './RequestDetailProgress';
import { STATUS_OPTIONS } from '../../configs/request';
import { getProfile } from '../../store/profile';

import styles from './RequestDetail.module.scss';

export const RequestDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getProfile);
  const [modalOpen, setModalOpen] = useState(false);
  const loading = useSelector(getLoading);
  const item = useSelector(getRequest);
  const { id } = useParams();

  const setStatusDone = () => {
    dispatch(updateItem({ key: 'status', value: 'done' }));
    dispatch(updateRequest);
    dispatch(conductTransactions);
    toast.success('Заявка закрыта');
  };

  useEffect(() => {
    if (id && profile._id) {
      dispatch(loadRequest(id));
    }
  }, [id, dispatch, profile._id]);

  return (
    <div>
      <Title>Заявки</Title>
      <GoBackLink />
      {loading ? (
        <LoadingPage withWrapper />
      ) : (
        <>
          <div className={styles.Header}>
            <div className={styles.Header__Column}>
              <div className={styles.Title}>
                Заявка № {item?.number || item._id}
              </div>
              {item.createdAt && (
                <div className={styles.Desc}>
                  Создана {format(new Date(item.createdAt), 'dd.MM.yyyy HH:mm')}
                </div>
              )}
            </div>
            <div className={styles.Header__Column}>
              <div className={styles.Desc}>Статус заявки</div>
              {item?.status && (
                <div className={styles.SubTitle}>
                  {STATUS_OPTIONS[item?.status]?.label}
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tabs
              tabs={[
                {
                  title: 'Информация по заявке',
                  route: ROUTES.FULL_REQUESTS_DECLARATIONS_CLIENT_DETAIL_INFO,
                  href: './info',                  
                  visible: true
                },
                {
                  title: 'Работа по заявке',
                  route: ROUTES.FULL_REQUESTS_DECLARATIONS_CLIENT_DETAIL_PROGRESS,
                  href: './progress',                  
                  visible: true                  
                },
              ]}
            />
            {item.status !== 'done' && item.declaration?.length > 0 && (
              <>
                <div className={styles.CloseRequest}>
                  <Button filled onClick={() => setModalOpen(true)}>
                    Закрыть заявку
                  </Button>
                </div>
                <ConfirmModal
                  message="Вы уверены, что хотите закрыть заявку?"
                  open={modalOpen}
                  onConfirm={() => {
                    setModalOpen(false);
                    setStatusDone();
                    navigate(ROUTES.FULL_REQUESTS_DECLARATIONS, {replace: true});
                  }}
                  onReject={() => setModalOpen(false)}
                />
              </>
            )}
            {item.status === 'done' && (
              <div className={styles.CloseRequest}>
                <Button
                  filled
                  onClick={() => navigate(`/act/request/${item._id}`)}
                >
                  Акт
                </Button>
              </div>
            )}
          </div>
          <Routes>
            <Route
              path={ROUTES.REQUESTS_DECLARATIONS_CLIENT_DETAIL_INFO}
              Component={RequestDetailInfo}
            />
            <Route
              path={ROUTES.REQUESTS_DECLARATIONS_CLIENT_DETAIL_PROGRESS}
              Component={RequestDetailProgress}
            />
            <Route 
              path="*"
              element={<Navigate to='./info' />}
            />            
          </Routes>
        </>
      )}
    </div>
  );
};
