import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {format} from 'date-fns';
import classnames from 'classnames';

import InfoCircle from '../../assets/InfoCircle.svg';

import {PAGE_PRICE, CODES_PER_PAGE} from '../../consts';
import {
  DELIVERY_TERMS,
  SHIPPING_OPTIONS,
  TYPE_OPTIONS,
} from '../../configs/request';
import {getCompanies, loadCompanies} from '../../store/companies';
import {getTariffHash} from '../../store/tariffs';
import {Input} from '../shared/Input';
import {Tooltip} from '../shared/Tooltip';
import {CalendarModal} from '../shared/Calendar/CalendarModal';
import {Select} from '../shared/Select';

import styles from './DeliveryInfo.module.scss';
import legendStyles from './Legend.module.scss';
import {Checkbox} from '../shared/Checkbox';
/*import {getNewRequest} from "../../store/requests";*/

export const DeliveryInfo = (props) => {
  const dispatch = useDispatch();
  const companies = useSelector(getCompanies);
  const tariffs = useSelector(getTariffHash);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const {
    discount,
    promocode,
    deliveryInfo,
    onChange,
    errors,
    disabled,
    viewOnly,
    lightgrey,
    price,
    showDiscount,
  } = props;
  // TODO reverse engineer the price
  const finalPrice = price;

  const companyOptions = {};

  companies.forEach((company) => {
    if (company.verified) {
      companyOptions[company._id] = {
        value: company._id,
        label: company.name,
      };
    }
  });

  useEffect(() => {
    if (!companies.length) {
      dispatch(loadCompanies);
    }
  }, [dispatch, companies.length]);

  return (
    <div style={{display: 'flex', position: 'relative', width: '100%'}}>
      <div style={{width: '100%'}}>
        <div className={styles.SelectWrapper}>
          <div className={styles.SelectWrapper__Text}>Таможенная процедура</div>
          {viewOnly ? (
            <Input
              value={TYPE_OPTIONS[deliveryInfo.type]?.label}
              disabled
              lightgrey={lightgrey}
            />
          ) : (
            <Select
              options={Object.values(TYPE_OPTIONS)}
              value={TYPE_OPTIONS[deliveryInfo.type]}
              placeholder="Выберите таможенную процедуру"
              onChange={({value}) => onChange('type', value)}
              isDisabled={disabled}
            />
          )}
          {errors?.type && (
            <div className={styles.SelectWrapper__Error}>{errors.type}</div>
          )}
        </div>
        <div className={styles.SelectWrapper}>
          <div className={styles.SelectWrapper__Text}>Тип перевозки</div>
          {viewOnly ? (
            <Input
              value={SHIPPING_OPTIONS[deliveryInfo.shipping]?.label}
              disabled
              lightgrey={lightgrey}
            />
          ) : (
            <Select
              options={Object.values(SHIPPING_OPTIONS)}
              value={SHIPPING_OPTIONS[deliveryInfo.shipping]}
              placeholder="Выберите тип перевозки"
              onChange={({value}) => onChange('shipping', value)}
              isDisabled={disabled}
            />
          )}
          {errors?.shipping && (
            <div className={styles.SelectWrapper__Error}>{errors.shipping}</div>
          )}
        </div>
        {SHIPPING_OPTIONS[deliveryInfo.shipping] && (
          <div>
            {deliveryInfo.shipping === SHIPPING_OPTIONS.avia.value && (
              <Checkbox
                disabled={disabled || viewOnly}
                checked={deliveryInfo?.speedUp}
                onClick={() => onChange('speedUp', !deliveryInfo?.speedUp)}
              >
                Применить ускоренное таможенное оформление (+
                {tariffs?.fasterCustom?.price}₽ к стоимости заявки)
              </Checkbox>
            )}
            <Checkbox
              disabled={disabled || viewOnly}
              checked={deliveryInfo?.withSupport}
              onClick={() =>
                onChange('withSupport', !deliveryInfo?.withSupport)
              }
            >
              Применить поддержку при таможенном оформлении (+
              {tariffs?.supportedCustom?.price}₽ к стоимости заявки)
            </Checkbox>
            <Checkbox
              disabled={disabled || viewOnly}
              checked={deliveryInfo?.withGoodsLookup}
              onClick={() =>
                onChange('withGoodsLookup', !deliveryInfo?.withGoodsLookup)
              }
            >
              Осуществление осмотра товаров (до подачи ДТ) (+
              {tariffs?.goodsLookup?.price}₽ к стоимости заявки)
            </Checkbox>
            {false && (
              <Checkbox
                disabled={disabled || viewOnly}
                checked={deliveryInfo?.withPermissionDocs}
                onClick={() =>
                  onChange(
                    'withPermissionDocs',
                    !deliveryInfo?.withPermissionDocs
                  )
                }
              >
                Организация и получение разрешительных документов и заключений в
                органах государственного контроля и сертификационных органах (+
                {tariffs?.permissionDocs?.price}₽ к стоимости заявки)
              </Checkbox>
            )}
            <Checkbox
              disabled={disabled || viewOnly}
              checked={deliveryInfo?.withPresense}
              onClick={() =>
                onChange('withPresense', !deliveryInfo?.withPresense)
              }
            >
              Обеспечение присутствия при осуществлении досмотра/осмотра в ходе
              таможенного оформления (+
              {tariffs?.presense?.price}₽ к стоимости заявки)
            </Checkbox>
            {false && (
              <Checkbox
                disabled={disabled || viewOnly}
                checked={deliveryInfo?.withLoadingControl}
                onClick={() =>
                  onChange(
                    'withLoadingControl',
                    !deliveryInfo?.withLoadingControl
                  )
                }
              >
                Осуществление контроля погрузочных/разгрузочных работ при
                проведении досмотра/осмотра товаров (+
                {tariffs?.loadingControl?.price}₽ к стоимости заявки)
              </Checkbox>
            )}
            <Checkbox
              disabled={disabled || viewOnly}
              checked={deliveryInfo?.withFitoSanControl}
              onClick={() =>
                onChange(
                  'withFitoSanControl',
                  !deliveryInfo?.withFitoSanControl
                )
              }
            >
              Организация и проведение фитосанитарного контроля (+
              {tariffs?.fitoSanControl?.price}₽ к стоимости заявки)
            </Checkbox>
            <Checkbox
              disabled={disabled || viewOnly}
              checked={deliveryInfo?.withDocsTransfer}
              onClick={() =>
                onChange('withDocsTransfer', !deliveryInfo?.withDocsTransfer)
              }
            >
              Содействие в получении перевода документов уполномоченным лицом (+
              {tariffs?.docsTransfer?.price}₽ к стоимости заявки)
            </Checkbox>
            <br/>
          </div>
        )}
        <div className={styles.SelectWrapper}>
          <div className={styles.SelectWrapper__Text}>Компания заказчик</div>
          {viewOnly ? (
            <Input
              value={deliveryInfo.company}
              disabled
              lightgrey={lightgrey}
            />
          ) : (
            <Select
              options={Object.values(companyOptions)}
              value={companyOptions[deliveryInfo?.company]}
              placeholder="Выберите компанию на которую хотите создать заявку"
              onChange={({value}) => onChange('company', value)}
              isDisabled={disabled}
            />
          )}
          {errors?.company && (
            <div className={styles.SelectWrapper__Error}>{errors.company}</div>
          )}
        </div>
        <div className={styles.SelectWrapper}>
          <div className={styles.SelectWrapper__Text}>Условия перевозки</div>
          {viewOnly ? (
            <Input
              value={DELIVERY_TERMS[deliveryInfo.terms]?.label}
              disabled
              lightgrey={lightgrey}
            />
          ) : (
            <Select
              options={Object.values(DELIVERY_TERMS)}
              value={DELIVERY_TERMS[deliveryInfo?.terms]}
              placeholder="Выберите условия перевозки"
              onChange={({value}) => onChange('terms', value)}
              isDisabled={disabled}
            />
          )}
          {errors?.terms && (
            <div className={styles.SelectWrapper__Error}>{errors.terms}</div>
          )}
        </div>

        <div className={styles.AddInfoWrapper}>
          <div>
            <div className={styles.Label}>Количество кодов ТНВЭД</div>
            <div className={styles.InputWrapperCode}>
              <Input
                placeholder="15"
                type="number"
                min="0"
                value={deliveryInfo.uniqueCount}
                onChange={(e) => onChange('uniqueCount', e.target.value)}
                disabled={disabled || viewOnly}
                lightgrey={lightgrey}
              />
            </div>
            {errors?.uniqueCount && (
              <div className={styles.SelectWrapper__Error}>
                {errors.uniqueCount}
              </div>
            )}
          </div>
          <div>
            <div className={styles.Label}>Общая информация о грузе</div>
            <textarea
              className={classnames(styles.Textarea, {
                [styles['Textarea--disabled']]:
                disabled || (viewOnly && !lightgrey),
                [styles['Textarea--fon']]: lightgrey,
              })}
              value={deliveryInfo.techDesc}
              disabled={disabled || viewOnly}
              onChange={(e) => onChange('techDesc', e.target.value)}
              placeholder="Введите сведения о грузе: страна происхождения, наименование, вес , объём"
            />
            {errors?.techDesc && (
              <div className={styles.SelectWrapper__Error}>
                {errors.techDesc}
              </div>
            )}
          </div>
        </div>
        <div className={styles.AddInfoWrapper}>
          <div>
            <div className={styles.Label}>
              Дата предварительного поступления груза на СВХ
            </div>
            <div className={styles.InputWrapperDate}>
              <Input
                placeholder={format(new Date(), 'dd.MM.yyyy')}
                type="string"
                value={
                  deliveryInfo?.receiptDate
                    ? format(new Date(deliveryInfo?.receiptDate), 'dd.MM.yyyy')
                    : format(new Date(), 'dd.MM.yyyy')
                }
                onClick={() => setCalendarOpen(true)}
                onChange={() => {
                }}
                disabled={disabled || viewOnly}
                lightgrey={lightgrey}
              />
              <CalendarModal
                dateOnly
                buttonLabel="Выбрать"
                title="Дата предварительного поступления груза на СВХ"
                open={calendarOpen}
                defaultDate={
                  deliveryInfo?.receiptDate
                    ? new Date(deliveryInfo?.receiptDate)
                    : undefined
                }
                onSubmit={(date) => {
                  onChange('receiptDate', date);
                }}
                onClose={() => setCalendarOpen(false)}
              />
            </div>
            {errors?.receiptDate && (
              <div className={styles.SelectWrapper__Error}>
                {errors.receiptDate}
              </div>
            )}
          </div>
          <div>
            <div className={styles.Label}>Пункт прибытия СВХ (адрес)</div>
            <textarea
              className={classnames(styles.Textarea, {
                [styles['Textarea--disabled']]:
                disabled || (viewOnly && !lightgrey),
                [styles['Textarea--fon']]: lightgrey,
              })}
              value={deliveryInfo.storeAddress}
              disabled={disabled || viewOnly}
              onChange={(e) => onChange('storeAddress', e.target.value)}
              placeholder="Введите пункт прибытия порт Владивосток, порт Новороссийск, жд станция Ворсино, аэропорт Домодедово"
            />
            {errors?.storeAddress && (
              <div className={styles.SelectWrapper__Error}>
                {errors.storeAddress}
              </div>
            )}
          </div>
        </div>
      </div>
      {showDiscount && (
        <div
          className={legendStyles.CostArea}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '30rem',
            textAlign: 'right',
          }}
        >
          <div className={legendStyles.Legend__Title}>
            Стоимость заявки
            <Tooltip iconSrc={InfoCircle}>
              <div className={legendStyles.InfoMessage}>
                <p>
                  Стоимость дополнительных листов будет рассчитана при закрытии
                  заявки.
                </p>
                <p>
                  Стоимость одного дополнительного листа - {PAGE_PRICE} рублей.
                </p>
                <p>
                  Количество листов зависит от количества кодов В первом листе
                  декларации - 1 код В последующих - {CODES_PER_PAGE} кода.
                </p>
              </div>
            </Tooltip>
          </div>

          <div className={legendStyles.Cost__Value}>
            <span className={legendStyles.Cost__Number}>{finalPrice}₽</span>
            {discount > 0 && (
              <span className={legendStyles.PreviousPrice}>
                {Math.round((finalPrice / (100 - discount)) * 100)}₽
              </span>
            )}
          </div>

          <div className={legendStyles.Cost__Text}>+ стоимость доп. листов ({PAGE_PRICE} ₽/шт.)</div>
          {discount > 0 && promocode && (
            <div>
              применен промокод:{' '}
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                  color: '#00B05C',
                }}
              >
                {promocode.name}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
