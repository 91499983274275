import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, useNavigate } from 'react-router-dom';

import { ROUTES } from '../../../consts';
import { getToken } from '../../../store/auth';
import { getRole, getProfile, loadProfile } from '../../../store/profile';
import { NotFound } from '../../../pages/NotFound';

export const CheckRole = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roles, redirect } = props;
  const token = useSelector(getToken);
  const profileRole = useSelector(getRole);

  useEffect(() => {
    if (!profileRole){
      dispatch(loadProfile(navigate)); 
    } 
  }, []);

  if (!token) {
    return <Navigate to={ROUTES.FULL_AUTH_GATE_LOGIN} />;
  }

  if (profileRole && roles) {
    if (profileRole in roles) {      
      const ComponentForRole = roles[profileRole];
      return <ComponentForRole />;
    } else if (redirect){
      return <Navigate to={redirect} />;
    } else return <Navigate to={ROUTES.FULL_AUTH_GATE_LOGIN} />;
  }  
};
