import { API_ROUTES } from '../consts';
import { ApiHelper } from '../utils';

export const createSystemRequest = (payload) =>
  ApiHelper.post(API_ROUTES.SYSTEM_REQUEST, payload);

export const getSystemAccount = () =>
  ApiHelper.get(API_ROUTES.SYSTEM_ACCOUNT);

export const setSystemAccount = (payload) =>
  ApiHelper.post(API_ROUTES.SYSTEM_ACCOUNT, payload);
  
  
