import React from 'react';

import { UploadArea } from '../../shared/UploadArea';

export const DocumentsPreview = ({ company }) => (
  <>
    <div>
      <UploadArea files={company.statut} id="statut" viewOnly disabled>
        Устав компании и все изменения к нему
      </UploadArea>
    </div>
    <div>
      <UploadArea files={company.memorandum} id="memorandum" viewOnly disabled>
        Учредительный договор и все изменения к нему или решение (протокол) о
        создании организации
      </UploadArea>
    </div>
    <div>
      <UploadArea
        files={company.stateRegistration}
        id="stateRegistration"
        viewOnly
        disabled
      >
        Свидетельство о государственной регистрации ФНС
      </UploadArea>
    </div>
    <div>
      <UploadArea
        files={company.legalEntityRegistry}
        id="legalEntityRegistry"
        viewOnly
        disabled
      >
        Свидетельство о внесении в ЕГРЮЛ ФНС и всех изменений в учредительные
        документы
      </UploadArea>
    </div>
    <div>
      <UploadArea
        files={company.taxOfficeRegistry}
        id="taxOfficeRegistry"
        viewOnly
        disabled
      >
        Свидетельство или уведомление о постановке на учет в органах ФНС России
      </UploadArea>
    </div>
    <div>
      <UploadArea
        files={company.accountSertificates}
        id="accountSertificates"
        viewOnly
        disabled
      >
        Справки из банков об открытии банковских счетов (валютных, рублевых)
      </UploadArea>
    </div>
    <div>
      <UploadArea
        files={company.headAppointmentOrder}
        id="headAppointmentOrder"
        viewOnly
        disabled
      >
        Приказ (выписка из приказа) о назначении руководителя
      </UploadArea>
    </div>
    <div>
      <UploadArea
        files={company.accountantAppointmentOrder}
        id="accountantAppointmentOrder"
        viewOnly
        disabled
      >
        Приказ (выписка из приказа) о назначении главного бухгалтера
      </UploadArea>
    </div>
    <div>
      <UploadArea
        files={company.registerExtract}
        id="registerExtract"
        viewOnly
        disabled
      >
        Выписка из ЕГРЮЛ
      </UploadArea>
    </div>
    <div>
      <UploadArea
        files={company.passportCeo}
        id="passportCeo"
        viewOnly
        disabled
      >
        Паспорт руководителя (главная + прописка)
      </UploadArea>
    </div>
  </>
);
