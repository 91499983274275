import React from 'react';
import classnames from 'classnames';

import { Button } from '../Button';
import { LoadingPage } from '../../hocs/withLoading/LoadingPage';

import styles from './Accordion.module.scss';

export const Accordion = ({
  children,
  preview,
  loading,
  footer,
  title,
  fluid = true,
  open,
  fadedToggler,
  blueToggler,
  onToggle,
  openText = 'Открыть',
  closeText = 'Закрыть'
}) => {
  return (
    <div className={styles.Accordion}>
      <div className={styles.Title}>
        <span>{title}</span>
        {fadedToggler ? (
          <div
            onClick={onToggle}
            className={blueToggler ? styles.BlueToggler : styles.Toggler}
          >
            {open ? closeText : openText}
          </div>
        ) : (
          <Button onClick={onToggle} filled={!open}>
            {open ? closeText : openText}
          </Button>
        )}
      </div>
      {loading ? (
        <div style={{ padding: '8rem 0' }}>
          <LoadingPage />
        </div>
      ) : open ? (
        <div
          className={classnames(styles.Content, {
            [styles['Content--aligned']]: fluid,
          })}
        >
          {children}
          {footer}
        </div>
      ) : (
        preview
      )}
    </div>
  );
};
