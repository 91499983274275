import { ApiHelper, generateQuery } from '../utils';
import { API_ROUTES } from '../consts';

export const getUsers = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.USERS}${queryString}`);
};

export const getUserById = (id) => ApiHelper.get(`${API_ROUTES.PROFILE}/${id}`);

export const getCompaniesForUser = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.USERS_COMPANIES}${queryString}`);
};

export const getUserCompanies = (id) =>
  ApiHelper.get(`${API_ROUTES.USERS_COMPANIES}/${id}`);

export const getCompanyById = (id) =>
  ApiHelper.get(`${API_ROUTES.USERS_COMPANY}/${id}`);
