import React from 'react';
import { useMatch, Link } from 'react-router-dom';

import classnames from 'classnames';

import styles from './MenuItem.module.scss';

export const MenuItem = ({ label, icon, route }) => {
  const match = useMatch({
    path: route+"/*",
  });

  return (
    <Link to={route}>
      <div
        className={classnames(styles.MenuItem, {
          [styles['MenuItem--active']]: match,
        })}
      >
        <div className={styles.MenuItem__Icon}>{icon}</div>
        <div className={styles.MenuItem__Label}>{label}</div>
      </div>
    </Link>
  );
};
