import React, { useState, useRef, useEffect } from 'react';
import styles from './SwitcherSubPanel.module.sass';
import classnames from 'classnames';

export const SwitcherSubPanel = ({state, setStateCb}) => {

	const handlerChange = (event) => {
		let element;
		if ( element = event.target.closest("."+classnames(styles.radio_type_departure))){
			if(setStateCb){
				setStateCb(element.value);
			}	
		}
	}

	return (
			<div className={classnames(styles.wrap_sub_tabs)} onChange={handlerChange}>
				<input id="sea_departure" className={classnames(styles.radio_type_departure)} type="radio" name="switchdeparture" value="seaDeparture"
					checked={state === "seaDeparture"}
				/>
				<label htmlFor="sea_departure" className={classnames(styles.sub_tabs)}>
					<span className={classnames(styles.sub_tabs_inner_shadow)}></span>									
					<span className={classnames(styles.sub_tabs_text_btn)}>Море отправление</span>									
				</label>
				<input id="train_arrival" className={classnames(styles.radio_type_departure)} type="radio" name="switchdeparture" value="trainArrival"
					checked={state === "trainArrival"}
				/>
				<label htmlFor="train_arrival" className={classnames(styles.sub_tabs)}>	
					<span className={classnames(styles.sub_tabs_inner_shadow)}></span>								
					<span className={classnames(styles.sub_tabs_text_btn)}>ЖД прибытие</span>									
				</label>
			</div>		

	);



}

