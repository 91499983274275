import { fetchTariffs, updateTariff } from '../../api';

export const LOAD_TARIFFS_REQUEST = '@tariffs/LOAD_TARIFFS_REQUEST';
export const LOAD_TARIFFS_SUCCESS = '@tariffs/LOAD_TARIFFS_SUCCESS';
export const LOAD_TARIFFS_FAILURE = '@tariffs/LOAD_TARIFFS_FAILURE';
export const MODIFY_TARIFF_REQUEST = '@tariffs/MODIFY_TARIFF_REQUEST';
export const MODIFY_TARIFF_SUCCESS = '@tariffs/MODIFY_TARIFF_SUCCESS';
export const MODIFY_TARIFF_FAILURE = '@tariffs/MODIFY_TARIFF_FAILURE';

const loadTariffsSuccess = (payload) => ({
  type: LOAD_TARIFFS_SUCCESS,
  payload,
});

const loadTariffsFailure = (payload) => ({
  type: LOAD_TARIFFS_FAILURE,
  payload,
});

const loadTariffsRequest = () => ({
  type: LOAD_TARIFFS_REQUEST,
});

const modifyTariffSuccess = (payload) => ({
  type: MODIFY_TARIFF_SUCCESS,
  payload,
});

const modifyTariffFailure = (payload) => ({
  type: MODIFY_TARIFF_FAILURE,
  payload,
});

const modifyTariffRequest = () => ({
  type: MODIFY_TARIFF_REQUEST,
});

export const loadTariffs = () => async (dispatch) => {
  dispatch(loadTariffsRequest());
  try {
    const { tariffs } = await fetchTariffs();
    dispatch(loadTariffsSuccess(tariffs));
  } catch (error) {
    dispatch(loadTariffsFailure(error));
  }
};

export const modifyTariff = (id, payload, toast) => async (dispatch) => {
  dispatch(modifyTariffRequest());
  try {
    const { success, tariff } = await updateTariff(id, payload);
    if (success && tariff) {
      dispatch(modifyTariffSuccess(tariff));
      toast.success('Данные успешно обновлены', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      dispatch(modifyTariffFailure('oops...'));
    }
  } catch (error) {
    dispatch(modifyTariffFailure(error));
  }
};
