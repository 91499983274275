import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { PERSONAL_OPTIONS } from '../../configs/personalOptions';
import { getProfile, updateSetValues } from '../../store/profile';
import { Title } from '../shared/Title';
import { Button } from '../shared/Button';
import { PersonalInput } from './PersonalInput';
import { SocialBlock } from './SocialBlock';

import styles from './PersonalInfo.module.scss';
import { ROLES } from '../../consts';

export const PersonalInfo = () => {
  const dispatch = useDispatch();
  const [edited, setEdited] = useState(false);
  const profile = useSelector(getProfile);

  const changeState = () => {
    if (!edited) {
      setEdited(true);
    }
  };

  const [state, setState] = useState({
    name: profile.name,
    surname: profile.surname,
  });

  const handleSaveEditable = () => {
    dispatch(updateSetValues(state));
    setEdited(false);
    toast.success('Изменения сохранены');
  };

  const getValue = (option) =>
    ['name', 'surname'].includes(option) ? state[option] : profile[option];

  return (
    <div className={styles.PersonalInfo}>
      <Title as="h2">Персональная информация</Title>
      <div className={styles.PersonalOptions}>
        {Object.keys(PERSONAL_OPTIONS).map((option) => (
          <PersonalInput
            key={option}
            option={option}
            {...PERSONAL_OPTIONS[option]}
            value={getValue(option)}
            onChange={(e) => {
              e.persist();
              setState((prevState) => ({
                ...prevState,
                [option]: e?.target?.value,
              }));
              changeState();
            }}
          />
        ))}
      </div>
      {profile.role !== ROLES.ADMIN && (
        <div className={styles.LowLine}>
          <SocialBlock />
          {edited && (
            <div className={styles.ButtonArea}>
              <Button filled onClick={handleSaveEditable}>
                Сохранить
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
