import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { createInvite, getOutPayments } from '../api';
import { getBalance } from '../store/profile';
import { withLoading } from '../components/hocs/withLoading';
import { PartnerProgramInfo } from '../components/PartnerProgram/PartnerProgramInfo';
import { Button } from '../components/shared/Button';
import { Title } from '../components/shared/Title';
import { formatMoney } from '../utils';
import styles from './Account.module.scss';
import { ROLES } from '../consts';

export const PartnerProgramm = withLoading(() => {
  const balance = useSelector(getBalance);
  const [totalEarned, setTotalEarned] = useState(0);

  useEffect(() => {
    const getEarnings = async () => {
      const { outPaymentsSum } = await getOutPayments({ role: ROLES.PARTNER });
      if (outPaymentsSum || outPaymentsSum === 0) {
        setTotalEarned(balance + outPaymentsSum);
      }
    };
    getEarnings();
  }, [setTotalEarned, balance]);

  const [id, setId] = useState(null);

  const handleClick = async () => {
    try {
      const { invite } = await createInvite({ role: ROLES.USER });

      if (invite) {
        setId(invite._id);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <main>
      <div className={styles.Wrapper}>
        <div className={styles.Header}>
          <Title>Партнерская программа</Title>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '1.5rem',
              padding: '1rem',
              background: 'white',
              borderRadius: '5px',
              alignItems: 'center',
            }}
          >
            {id && (
              // TODO create a separte component for invite link generator

              <div
                style={{
                  background: '#FAFAFA',
                  borderRadius: '5px',
                  marginRight: '2rem',
                  padding: '0.5rem 1rem',
                }}
              >
                <a
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  href={`/join/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  /join/{id}
                </a>
              </div>
            )}
            <Button
              onClick={handleClick}
              filled
              tooltip={{
                title: 'Генерация приглашения',
                content: 'По ссылке будет доступна форма регистрации клиента.',
              }}
            >
              Сгенерировать
            </Button>
          </div>
        </div>

        <div className={styles.Income}>
          <div className={styles.Income__Title}>
            Заработано за время в системе
          </div>
          <div className={styles.Income__Value}>
            <span className={styles.Income__Number}>
              {formatMoney(totalEarned)}
            </span>
          </div>
        </div>
      </div>
      <PartnerProgramInfo />
    </main>
  );
});
