import React from 'react';

import { formatMoney } from '../../utils';

import styles from './BalancesRow.module.scss';

export const BalancesRow = ({
  totalFundsReceipt,
  systemAccountBalance,
  tempAccountBalance,
  outPaymentsSum,
  paymentsDebt,
}) => {
  return (
    <>
      <div className={styles.Row}>
        <div className={styles.AccountBalance}>
          <div className={styles.AccountBalance__Name}>Общее поступление</div>
          <div className={styles.AccountBalance__Balance}>
            {formatMoney(totalFundsReceipt)}
          </div>
        </div>
        <div className={styles.AccountBalance}>
          <div className={styles.AccountBalance__Name}>Отчислено системе</div>
          <div
            className={styles.AccountBalance__Balance}
            style={{ color: '#40C45D' }}
          >
            {formatMoney(systemAccountBalance)}
          </div>
        </div>
        <div className={styles.AccountBalance}>
          <div className={styles.AccountBalance__Name}>Долг по платежам</div>
          <div
            className={styles.AccountBalance__Balance}
            style={{ color: '#C34040' }}
          >
            {formatMoney(paymentsDebt * -1)}
          </div>
        </div>
        <div className={styles.AccountBalance}>
          <div className={styles.AccountBalance__Name}>Состояние ТС</div>
          <div className={styles.AccountBalance__Balance}>
            {formatMoney(tempAccountBalance)}
          </div>
        </div>
        <div className={styles.AccountBalance}>
          <div className={styles.AccountBalance__Name}>Сумма выплат</div>
          <div className={styles.AccountBalance__Balance}>
            {formatMoney(outPaymentsSum)}
          </div>
        </div>
      </div>
    </>
  );
};
