import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { Tabs } from '../shared/Tabs';
import { Accordion } from '../shared/Accordion';
import { Button } from '../shared/Button';
import { TextArea } from '../Logistics/Offers/OffersMaker';

import { ROUTES } from '../../consts';
import { updateCompanyOffer, getCompanyOffer } from '../../store/companies';
import { getRole } from '../../store/profile';
import styles from './Offer.module.sass';
import classnames from 'classnames';

export const Offer = () => {
  const dispatch = useDispatch();
  const companyOffer = useSelector(getCompanyOffer);
  const [textArea, setTextArea] = useState({value: companyOffer?companyOffer:false, correct: true});
  const [accordionState, setAccordionState] = useState(false);
  const { id } = useParams();
  const role = useSelector(getRole); 

  useEffect(()=>{
    if (typeof companyOffer === "string" && companyOffer.length > 0  && companyOffer !== textArea.value){
      setTextArea((prev)=>({value: companyOffer, correct: true }));
    }    
  },[companyOffer, accordionState]);



  const saveCompanyOffer = () => {
    if (Object.values(textArea).every(item=>item)){
      dispatch(updateCompanyOffer(id, textArea.value, toast));      
    }
  }

  const toggleHandler = () => {
    setAccordionState((prev)=>!prev);
  }

  return (
      <div className={classnames(styles.offer)}>
        <Tabs
          tabs={[
            {
              title: 'Общие сведения о компании',
              route: ROUTES.FULL_PROFILE_COMPANY_INFO,
              href: `../${id}/info`,
              visible: true,
            },
            {
              title: 'Документы компании',
              route: ROUTES.FULL_PROFILE_COMPANY_DOCS,
              href: `../${id}/docs`,
              visible: true,
            },
            {
              title: 'О компании',
              route: ROUTES.FULL_PROFILE_COMPANY_OFFER,
              href: `../${id}/offer`,
              visible: (role === "logist")?true:false,
            }
          ]}
        />
        <Accordion
          open={accordionState}
          title="О компании"
          onToggle={toggleHandler}
        >
          <div className={classnames(styles.content)}>
            <div className={classnames(styles.half)}>
              <div className={classnames(styles.contact_info)}>
                <TextArea 
                  name="offersCompany"
                  placeholder="Указанная здесь информация будет видна в карточке предложений, напишите УТП или преимущества."
                  rule="russian"
                  areaValue={textArea}
                  setStateCb={setTextArea}
                  maxlength={210}
                />
                <div className={classnames(styles.button_wrapper)}>
                  <Button filled onClick={saveCompanyOffer}>
                    Сохранить
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Accordion> 
      </div>   
  );

}


