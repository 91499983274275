import { 
  ADD_COMPANIES,
  ADD_COMPANIES_SUCCESS,
  ADD_COMPANIES_FAILURE } from './actions';

const initialState = {
  mycompanies: [],
  loading: true
};

export const mycompaniesReducer = (state = initialState, action) => {

  switch (action.type) {
    case ADD_COMPANIES:
      return {
        ...state,
        loading: true,
      }; 
    case ADD_COMPANIES_SUCCESS:
      return {
        ...state,
        ...{mycompanies: action.payload.companies},
        loading: false,
      };
    case ADD_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };                
    default:
      return state;      
  }	
}