import React, { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';

import { createPromocode, fetchPromocodes, updatePromocode } from '../../api';
import { Button } from '../shared/Button';
import { CalendarModal } from '../shared/Calendar/CalendarModal';
import { Input } from '../shared/Input';
import { Select } from '../shared/Select';
import { Table } from '../shared/Table';

import styles from './Promocodes.module.scss';

const DAYS_OFFSET = 10;
const DEFAULT_STATE = {
  name: '',
  discount: '',
  service: '',
  activationsCount: '',
  usersAllowed: '',
  expireDate: null,
};

export const SERVICE_OPTIONS = {
  request: { value: 'request', label: 'Заявка' },
};

export const Promocodes = () => {
  const defaultDate = useMemo(() => {
    const today = new Date();
    const resultDate = new Date(today);
    resultDate.setDate(resultDate.getDate() + DAYS_OFFSET);

    return resultDate;
  }, []);

  const [promocode, setPromocode] = useState(DEFAULT_STATE);
  const [data, setData] = useState({
    items: [],
    loading: false,
    count: 0,
  });

  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleChange = (key) => (val) =>
    setPromocode((prevVal) => ({ ...prevVal, [key]: val }));
  const handleTextInputChange = (key) => (e) =>
    handleChange(key)(e.target.value);

  const handleAdd = async () => {
    await createPromocode(promocode);
    setPromocode(DEFAULT_STATE);
    setData((prevData) => ({
      ...prevData,
      items: [...prevData.items, { ...promocode, usages: 0, enabled: true }],
    }));
  };

  useEffect(() => {
    async function fetchData() {
      setData((prevData) => ({ ...prevData, loading: true }));

      const { count, promocodes } = await fetchPromocodes();

      if (promocodes) {
        setData({ count, items: promocodes, loading: false });
      }
    }

    fetchData();
  }, []);

  const ActionButton = (promocode) => {
    let color = '#DADADA';
    let text = 'Включить';
    if (promocode.enabled) {
      text = 'Отключить';
    }
    if (new Date(promocode.expireDate).getTime() > new Date().getTime()) {
      color = promocode.enabled ? '#B92B2B' : '#01D39A';
    }

    const handleClick = async () => {
      setData((prevData) => ({ ...prevData, loading: true }));

      const { promocode: updatedCode } = await updatePromocode(promocode._id, {
        enabled: !promocode.enabled,
      });

      setData((prevData) => ({
        ...prevData,
        loading: false,
        items: prevData.items.map((item) => {
          if (item._id === promocode._id) {
            return updatedCode;
          }

          return item;
        }),
      }));
    };

    return (
      <span onClick={handleClick} className={styles.Action} style={{ color }}>
        {text}
      </span>
    );
  };

  const headers = [
    {
      label: 'Промокод',
      value: 'name',
      render: (value) => <span className={styles.Code}>{value}</span>,
    },
    {
      label: 'Срок действия до',
      value: 'expireDate',
      render: (value) => format(new Date(value), 'dd.MM.yyyy'),
    },
    {
      label: 'Услуга',
      value: 'service',
      render: (value) => SERVICE_OPTIONS[value]?.label,
    },
    {
      label: 'Скидка',
      value: 'discount',
      render: (value) => `${value} %`,
    },
    {
      label: 'Возможно активаций',
      value: 'activationsCount',
    },
    {
      label: 'Макс. пользователей',
      value: 'usersAllowed',
      render: (value) => value || '∞'
    },
    {
      label: 'Воспользовались',
      value: 'usages',
      render: (value, row) => {
        if (!row.usersAllowed)
          return value;

        return `${value} / ${row.usagesByUser}`;
      }
    },
    {
      label: 'Действие',
      renderItem: true,
      sortable: true,
      render: ActionButton,
    },
  ];

  return (
    <div>
      <div className={styles.Form}>
        <div className={styles.InputsRow}>
          <Input
            placeholder="Название промокода"
            onChange={handleTextInputChange('name')}
            value={promocode.name}
          />
          <Input
            className={styles.InputsRow__NumberInput}
            placeholder="% Скидки"
            onChange={handleTextInputChange('discount')}
            type="number"
            value={promocode.discount}
          />
          <div style={{ width: '100%' }}>
            <Select
              options={Object.values(SERVICE_OPTIONS)}
              value={SERVICE_OPTIONS[promocode.service]}
              onChange={({ value }) => handleChange('service')(value)}
              placeholder="Услуга"
            />
          </div>
          <Input
            placeholder="Срок действия до"
            onClick={() => setCalendarOpen(true)}
            readOnly={true}
            value={
              promocode.expireDate
                ? format(new Date(promocode.expireDate), 'dd.MM.yyyy')
                : ''
            }
          />
          <Input
            className={styles.InputsRow__NumberInput}
            type="number"
            min={1}
            placeholder="Активаций"
            onChange={handleTextInputChange('activationsCount')}
            value={promocode.activationsCount}
          />
          <Input
            className={styles.InputsRow__NumberInput}
            type="number"
            min={1}
            placeholder="Макс. пользователей"
            onChange={handleTextInputChange('usersAllowed')}
            value={promocode.usersAllowed}
          />
          <CalendarModal
            dateOnly
            buttonLabel="Выбрать"
            title="Дата окончания промокода"
            open={calendarOpen}
            defaultDate={defaultDate}
            onSubmit={(d) => {
              const date = new Date(
                d.setTime(d.getTime() + 1000 * 60 * 60 * 24 - 1)
              );
              handleChange('expireDate')(date);
            }}
            onClose={() => setCalendarOpen(false)}
          />
        </div>

        <Button filled onClick={handleAdd}>
          Добавить промокод
        </Button>
      </div>
      <Table
        loading={data.loading}
        items={data.items}
        headers={headers}
        filters={{}}
        countItems={data.count}
        applyFilters={() => {}}
      />
    </div>
  );
};
