export const UPDATE_FILTERS = '@filters/UPDATE_FILTERS';
export const RESET_FILTERS = '@filters/RESET_FILTERS';

export const updateFilters = (payload) => ({
  type: UPDATE_FILTERS,
  payload,
});

export const resetFilters = (filtersKey) => ({
  type: RESET_FILTERS,
  filtersKey,
});

export const applyFilters = (filtersKey, filters) => async (dispatch) => { 
    dispatch(updateFilters({ key: filtersKey, value: filters }));
};
