import { omit } from 'lodash';

import { updateProfile } from '../../api';
import { getWork } from './selectors';
import { INITIAL_PLACE } from './reducer';
import { getProfileId } from '../profile';

export const UPDATE_VALUE = '@work/UPDATE_VALUE';
export const TOGGLE_OPEN = '@work/TOGGLE_OPEN';
export const UPDATE_WORK = '@work/UPDATE_WORK';
export const ADD_PLACE = '@work/ADD_PLACE';
export const DELETE_PLACE = '@work/DELETE_PLACE';
export const EDIT_PLACE = '@work/EDIT_PLACE';
export const SET_LOADING = '@work/SET_LOADING';

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const toggleOpen = () => ({
  type: TOGGLE_OPEN,
});

export const updateValue = (payload) => ({
  type: UPDATE_VALUE,
  payload,
});

export const updateWork = (payload) => ({
  type: UPDATE_WORK,
  payload,
});

export const addPlace = () => ({
  type: ADD_PLACE,
});

export const deletePlace = (payload) => ({
  type: DELETE_PLACE,
  payload,
});

export const editPlace = (payload) => ({
  type: EDIT_PLACE,
  payload,
});

export const saveWorkData = async (dispatch, getState) => {
  dispatch(setLoading(true));
  const state = getState();
  const workState = getWork(state);
  const value = omit(workState, [
    'open',
    'errors',
    'newPlace',
    'loading',
  ]);

  /* push the data from the new place form if any */
  if (
    Object.keys(workState.newPlace).some(
      (key) => workState.newPlace[key].length > 0
    )
  ) {
    value.places.push({ ...workState.newPlace });
    dispatch(
      updateWork({ places: [...value.places], newPlace: INITIAL_PLACE })
    );
  }

  const { profile } = await updateProfile(
    { key: 'work', value },
    getProfileId(state)
  );
  const newWork = profile.work;
  if (!profile.work.errors || Object.keys(profile.work.errors).length === 0) {
    newWork.open = false;
  }
  dispatch(updateWork(newWork));
  dispatch(setLoading(false));
};
