import React, { useState } from 'react';

import ExclamationRed from '../../assets/ExclamationRed.svg';
import { matchArrayDocs } from '../../configs/requestDocs';
import { matchArray } from '../../configs/requestDocs';
import { Accordion } from '../shared/Accordion';
import { AccordionFooter } from '../Documents/AccordionFooter';
import { Table } from '../shared/Table';
import { Modal } from '../shared/Modal';
import { ConfirmModal } from '../shared/ConfirmModal';
import GreenCheckmark from '../../assets/GreenCheckmark.svg';
import { upateDocsQuery } from '../../api/docsQueries';

import styles from './DocsQuery.module.scss';

export const DocsQuery = ({ query, setData }) => {
  const { docsSection, queryItems, _id } = query;
  const anchor = matchArray.find((doc) => doc.section === docsSection)?.anchor;
  const gotoDocs = () => {
    const goLabel = document.getElementById(anchor);
    if (goLabel) {
      goLabel.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const tableItems = queryItems.map((item) => {
    return {
      ...item,
      label: matchArrayDocs.find((doc) => doc.value === item.docName)?.label,
    };
  });

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const QUERY_HEADERS = [
    {
      label: 'Документ',
      value: 'label',
    },
    {
      label: 'Комментарий',
      value: 'comment',
    },
    {
      label: '',
      render: () => (
        <span
          onClick={gotoDocs}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            padding: '0.25rem 1rem',
          }}
        >
          Перейти к документу
        </span>
      ),
    },
  ];

  return (
    <Accordion
      fadedToggler
      blueToggler
      title={
        <div className={styles.WhyThisDocs}>
          <img
            src={ExclamationRed}
            alt="ExclamationRed"
            className={styles.WhyThisDocs__Img}
          />
          <div className={styles.WhyThisDocs__Text}>
            {`Запрос инспектора. `} &nbsp; &nbsp; {` ${docsSection}.`}
          </div>
        </div>
      }
      open={open}
      onToggle={() => setOpen((prevOpen) => !prevOpen)}
      footer={
        <AccordionFooter
          buttonText="Закрыть запрос"
          onClick={() => setOpenConfirm(true)}
          textBeforeButton="После загрузки документов, подготовленных согласно требованиям запроса, нажмите кнопку"
          attention
          tooltip={{
            title: 'Закрытие запроса',
            content: 'Закрытый запрос фиксируется системой, как выполненный',
          }}
        />
      }
    >
      <Table
        items={tableItems}
        headers={QUERY_HEADERS}
        filters={{}}
        applyFilters={() => {}}
        lowTable
      />
      <ConfirmModal
        message="Вы уверены, что полностью выполнили требования инспектора?"
        open={openConfirm}
        onConfirm={() => {
          setOpenConfirm(false);
          setOpenModal(true);
          setTimeout(() => {
            setOpenModal(false);
            setData((prevData) => prevData.filter((doc) => doc._id !== _id));
          }, 2000);
          upateDocsQuery(_id, { done: true });
        }}
        onReject={() => setOpenConfirm(false)}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <div className={styles.Title}>Запрос закрыт</div>
          <div className={styles.Checkmark}>
            <img src={GreenCheckmark} alt="checkmark" />
          </div>
        </>
      </Modal>
    </Accordion>
  );
};
