import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { getRequest, getLoading, loadRequest } from '../../../store/requests';
import { Accordion } from '../../shared/Accordion';
import { Button } from '../../shared/Button';
import { Title } from '../../shared/Title';
import { GoBackLink } from '../../shared/GoBackLink';
import { LoadingPage } from '../../hocs/withLoading/LoadingPage';
import { DeliveryInfo } from '../DeliveryInfo';
import { Documents } from '../Documents';
import { GetRequestForWork } from './GetRequestForWork';
import { getProfile } from '../../../store/profile';

import styles from './RequestPreview.module.scss';

export const RequestPreview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(getLoading);
  const [open, setOpen] = useState(true);
  const [openReq, setOpenReq] = useState(true);
  const verified = useSelector(getProfile).verified;
  const item = useSelector(getRequest);
  const { company } = item;
  const { id } = useParams();

  useEffect(() => {
    id && dispatch(loadRequest(id));
  }, [id, dispatch]);

  return (
    <div>
      <Title>Заявки</Title>
      <GoBackLink />
      {loading ? (
        <LoadingPage withWrapper />
      ) : (
        <>
          <div className={styles.Header}>
            <div className={styles.Header__Column}>
              <div className={styles.Title}>
                <div>Заявка № {item?.number || item._id}</div>
                {item.createdAt && (
                  <div className={styles.Desc}>
                    Создана{' '}
                    {format(new Date(item.createdAt), 'dd.MM.yyyy HH:mm')}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.SubTitle}>Предварительный просмотр</div>
          </div>
          <div>
            {company && (
              <Accordion
                open={open}
                fadedToggler
                onToggle={() => setOpen(!open)}
                title="Компания"
              >
                <div className={styles.Title}>{company.name}</div>
                <div className={styles.CompanyData}>
                  <div>ИНН {company.itn}</div>
                  <div>ОГРН {company.ogrn}</div>
                  <div>КПП {company.kpp}</div>
                </div>
              </Accordion>
            )}
          </div>
          <div>
            <Accordion
              fadedToggler
              title="Данные по заявке"
              open={openReq}
              onToggle={() => setOpenReq((prevOpen) => !prevOpen)}
              footer={
                <div className={styles.ButtonArea}>
                  <Button filled={false} onClick={navigate(-1)}>
                    Назад
                  </Button>
                  <GetRequestForWork
                    requestId={item._id}
                    disabled={!verified}
                  />
                </div>
              }
            >
              {item && (
                <>
                  <DeliveryInfo
                    deliveryInfo={{ ...item, company: item?.company?.name }}
                    viewOnly
                  />
                  <Documents documents={item} disabled viewOnly />
                </>
              )}
            </Accordion>
          </div>
        </>
      )}
    </div>
  );
};
