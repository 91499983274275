import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';

import { accountReducer } from './account';
import { authReducer } from './auth';
import { companiesReducer } from './companies';
import { educationReducer } from './education';
import { filtersReducer } from './filters';
import { notificationsReducer } from './notifications';
import { passportReducer } from './passport';
import { paymentRequestsReducer } from './paymentRequests';
import { personalAccountsReducer } from './personalAccounts';
import { profileReducer } from './profile';
import { requestsReducer } from './requests';
import { tariffsReducer } from './tariffs';
import { transactionsReducer } from './transactions';
import { usersReducer } from './users';
import { verificationReducer } from './verification';
import { workReducer } from './work';
import { logisticsReducer } from './logistics';
import { mycompaniesReducer } from './mycompanies';
import { logisticsOffersPacksReducer } from './logisticsOffersPacks';
import { makeOffersReducer } from './makeOffers';
import { modalMessageReducer } from './modalMessage';
import { selectionOffersReducer } from './selectionOffers';
import { withLoadingReducer } from './withLoading';
import { logisticsOffersFromPackReducer } from './logisticsOffersFromPack';
import { systemAccountReducer } from './systemAccount';

const reducer = combineReducers({
  account: accountReducer,
  auth: authReducer,
  companies: companiesReducer,
  education: educationReducer,
  filters: filtersReducer,
  notifications: notificationsReducer,
  passport: passportReducer,
  paymentRequests: paymentRequestsReducer,
  personalAccounts: personalAccountsReducer,
  profile: profileReducer,
  requests: requestsReducer,
  tariffs: tariffsReducer,
  transactions: transactionsReducer,
  users: usersReducer,
  verification: verificationReducer,
  work: workReducer,
  logistics: logisticsReducer,
  mycompanies: mycompaniesReducer,
  logisticsOffersPacks: logisticsOffersPacksReducer,
  makeOffers: makeOffersReducer,
  modalMessage: modalMessageReducer,
  selectionOffers: selectionOffersReducer,
  withLoading: withLoadingReducer,
  logisticsOffersFromPack: logisticsOffersFromPackReducer,
  systemAccount: systemAccountReducer
});

const composeEnhancers = composeWithDevTools({ name: 'Declarant' });

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default { store };
