import { 
  SET_DEFAULT_STATE,
  SET_DEFAULT_SEA_STATE,
  SET_PANEL_STATE,
  SET_SEA_NAME,
  SET_SEA_EXPIRE,
  SET_SEA_TERMINAL,
  SET_SEA_CUSTOMS,
  SET_SEA_SUB_PANEL_STATE,
  SET_SEA_COUNTRY_DEPARTURE,
  SET_SEA_COUNTRY_ARRIVAL,
  REMOVE_PORT_DEPARTURE_IN_LIST,
  RECOVER_CITY_PORT_DEPARTURE_IN_LIST,
  REMOVE_STATION_ARRIVAL_IN_LIST,
  RECOVER_STATION_ARRIVAL_IN_LIST,
  CHANGE_SEA_TRAIN_VIEW_FIELD,
  CHANGE_SEA_SEA_VIEW_FIELD,
  SET_DEFAULT_TRAIN_STATE,
  SET_TRAIN_NAME,
  SET_TRAIN_EXPIRE,
  SET_TRAIN_CITY,
  SET_TRAIN_COUNTRY_DEPARTURE,
  REMOVE_STATION_DEPARTURE_IN_LIST,
  RECOVER_STATION_DEPARTURE_IN_LIST,
  CHANGE_TRAIN_TRAIN_VIEW_FIELD,
  CHANGE_SEA_TEXT_AREA,
  CHANGE_TRAIN_TEXT_AREA,
  UPDATE_TRAIN_TRAIN_VIEW_ITEMS,
  UPDATE_SEA_TRAIN_VIEW_ITEMS,
  UPDATE_SEA_SEA_VIEW_ITEMS,
  ADD_SEA_INCORRECT_FIELDS,
  ADD_TRAIN_INCORRECT_FIELDS,
  LOADING_OFFERS_PACK} from './actions';

import {
  INIT_STATE,
  departureCountryList,
  arrivalCountryList,
  departureCityPortList,
  departureStationList,
  arrivalFromPortStationList,
  arrivalStraightStationList,
  arrivalCityPortList,
  arrivalPortTerminalList,
  subsetPortTerminalList,
  cityTerminal } from './consts';

import { cloneDeep } from 'lodash';

  const initState = cloneDeep(INIT_STATE);

export const makeOffersReducer = (state = initState, action) => {

  switch (action.type) {
    case LOADING_OFFERS_PACK:
      return { ...state, loading: action.payload };
    case SET_DEFAULT_STATE:
      return { ...action.payload }; 
    case SET_DEFAULT_SEA_STATE:
      return {
        ...state, sea:{...action.payload}
      };
    case SET_DEFAULT_TRAIN_STATE:
      return {
        ...state, train:{...action.payload}
      };
    case RECOVER_STATION_DEPARTURE_IN_LIST:
      return {
        ...state, train:{...state.train, stationDeparture: {...state.sea.stationDeparture, ...action.payload}}
      };
    case RECOVER_STATION_ARRIVAL_IN_LIST:
      return {
        ...state, sea:{...state.sea, trainArrival: {...state.sea.trainArrival, stationArrival:{...state.sea.trainArrival.stationArrival, ...action.payload}}}
      }; 
    case UPDATE_SEA_TRAIN_VIEW_ITEMS:
      return {
        ...state, sea:{...state.sea, trainView: action.payload}
      }; 
    case UPDATE_SEA_SEA_VIEW_ITEMS:
      return {
        ...state, sea:{...state.sea, seaView: action.payload}
      };
    case RECOVER_CITY_PORT_DEPARTURE_IN_LIST:
      return {
        ...state, sea:{...state.sea, seaDeparture:{...state.sea.seaDeparture, portDeparture:{...state.sea.seaDeparture.portDeparture, ...action.payload}}}
      };
    case UPDATE_TRAIN_TRAIN_VIEW_ITEMS:
      return {
        ...state, train:{...state.train, trainView: action.payload}
      };
    case CHANGE_SEA_TEXT_AREA:
      return {
        ...state, sea:{...state.sea, ...action.payload}
      };
    case CHANGE_TRAIN_TEXT_AREA:
      return {
        ...state, train:{...state.train, ...action.payload}
      };
    case SET_PANEL_STATE:
      return {
        ...state, panelState: action.payload
      };
    case SET_SEA_NAME:
      return {
        ...state, sea: {...state.sea, name: action.payload}
      };
    case SET_SEA_EXPIRE:
      return {
        ...state, sea: {...state.sea, expireDate: action.payload}
      };
    case SET_SEA_TERMINAL:
      return {
        ...state, sea: {
          ...state.sea, terminalArrival: action.payload,
          cityArrival: cityTerminal[action.payload.datatype],
          seaDeparture: {...state.sea.seaDeparture, portDeparture: departureCityPortList},
          trainArrival: {...state.sea.trainArrival, stationArrival: arrivalFromPortStationList},
          seaView: [],
          trainView: []
        }
      };
    case SET_TRAIN_NAME:
      return {
        ...state, train: {...state.train, name: action.payload}
      };
    case SET_TRAIN_EXPIRE:
      return {
        ...state, train: {...state.train, expireDate: action.payload}
      };
    case SET_TRAIN_CITY:
      return {
        ...state, train: {
          ...state.train, cityArrival: action.payload,
          stationDeparture: departureStationList,
          trainView: []          
        }
      };
    case SET_TRAIN_COUNTRY_DEPARTURE:
      return {
        ...state, train: {...state.train, countryDeparture: action.payload}
      };
    case SET_SEA_CUSTOMS:
      return {
        ...state, sea: {...state.sea, customs:{...state.sea.customs, ...action.payload}}
      };
    case SET_SEA_SUB_PANEL_STATE:
      return {
        ...state, sea: {...state.sea, subPanelState: action.payload}
      };
    case SET_SEA_COUNTRY_DEPARTURE:
      return {
        ...state, sea: {...state.sea, seaDeparture:{...state.sea.seaDeparture, countryDeparture: action.payload}}
      };
    case SET_SEA_COUNTRY_ARRIVAL:
      return {
        ...state, sea: {...state.sea, trainArrival:{...state.sea.trainArrival, countryArrival: action.payload}}
      };
    case ADD_SEA_INCORRECT_FIELDS:
      return {
        ...state, sea: {
          ...state.sea, ...Object.fromEntries(Object.entries(state.sea).filter(([key, value])=>{
              if (["seaView", "trainView"].includes(key)){
                return false;
              } else {
                return Object.keys(action.payload).includes(key);            
              }
            }).map(([key, value])=>{
                return [key, Object.fromEntries(Object.entries(value).map(([key, value])=>{
                  if (key === "correct"){
                    return [key, false];
                  } else return [key, value];
                }))];

            })), ...Object.fromEntries(Object.entries(state.sea).filter(([key, value])=>{
              if (["seaView", "trainView"].includes(key)){
                return (key in action.payload);
              } else return false;
            }).map(([key, value])=>{ // key -> "seaView" value -> arr

              return [key, value.map((item)=>{
                action.payload[key]
                .find(({idx, country})=>(item.index === idx && item.country === country))?.fields
                .forEach((field)=>{item.params[field].correct = false});
                return item;
              })];

            }))
        }
      };
    case ADD_TRAIN_INCORRECT_FIELDS:
      return {
        ...state, train: {
          ...state.train, ...Object.fromEntries(Object.entries(state.train).filter(([key, value])=>{
              if ("trainView" === key){
                return false;
              } else {
                return Object.keys(action.payload).includes(key);            
              }
            }).map(([key, value])=>{
                return [key, Object.fromEntries(Object.entries(value).map(([key, value])=>{
                  if (key === "correct"){
                    return [key, false];
                  } else return [key, value];
                }))];

            })), ...Object.fromEntries(Object.entries(state.train).filter(([key, value])=>{
              if ("trainView" === key){
                return (key in action.payload);
              } else return false;
            }).map(([key, value])=>{ // key -> "trainView" value -> arr

              return [key, value.map((item)=>{
                action.payload[key]
                .find(({idx, country})=>(item.index === idx && item.country === country))?.fields
                .forEach((field)=>{item.params[field].correct = false});
                return item;
              })];

            }))
        }
      };  
    default:
      return state;      
  }	
}





