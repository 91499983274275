import React from 'react';

import Upload from '../../assets/Upload.svg';
import Exclamation from '../../assets/Exclamation.svg';
import { Title } from '../shared/Title';
import { Tooltip } from '../shared/Tooltip';

import styles from './PassportFileView.module.scss';

export const PassportFileView = ({
  title,
  tooltip,
  subtitle,
  photoDesc,
  value,
}) => {
  return (
    <div>
      <div className={styles.Header}>
        <Title as="h4">{title}</Title>
        {tooltip && (
          <div className={styles.Tooltip}>
            <Tooltip iconSrc={Exclamation}>
              <p className={styles.Tooltip__Content}>{tooltip}</p>
            </Tooltip>
          </div>
        )}
      </div>
      <div className={styles.Content}>
        <div className={styles.Content__Photo}>
          {value ? (
            <img src={value} alt={title} className={styles.UploadedImage} />
          ) : (
            <img src={Upload} alt="upload" className={styles.UploadIcon} />
          )}
        </div>
        <div className={styles.Content__ButtonArea}>
          <div className={styles.Subtitle}>{subtitle}</div>
          {value && (
            <div className={styles.Content__LoadedUrl}>
              <a href={value} target="_blank" rel="noopener noreferrer">
                {photoDesc}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
