import ENV from './configs/environment';

export const AGREEMENT_URL =
  'https://system.mydeclarant.ru/templates/TermsAndConditions.pdf';

export const ROUTES = {
  OFFERS: 'offers',
  HOME: '/',
  MAIN_AUTH_GATE: '/auth-gate/*',
  AUTH_GATE_LOGIN: 'login',
  FULL_AUTH_GATE_LOGIN:'/auth-gate/login',
  AUTH_GATE_RESET_PASSWORD: 'reset-password',
  FULL_AUTH_GATE_RESET_PASSWORD: '/auth-gate/reset-password',
  AUTH_GATE_VERIFY_EMAIL: 'verify-email',
  FULL_AUTH_GATE_VERIFY_EMAIL: '/auth-gate/verify-email',
  AUTH_GATE_VERIFY_EMAIL_BY_TOKEN: 'verify-email/:token',
  FULL_AUTH_GATE_VERIFY_EMAIL_BY_TOKEN: '/auth-gate/verify-email/:token',
  AUTH_GATE_SET_NEW_PASSWORD: 'set-new-password/:hash',  
  FULL_AUTH_GATE_SET_NEW_PASSWORD: '/auth-gate/set-new-password/:hash',
  AUTH_GATE_REGISTER_CLIENT: 'register/client',
  FULL_AUTH_GATE_REGISTER_CLIENT: '/auth-gate/register/client',
  REGISTER_DECLARANT: '/auth-gate/register/declarant',  
  MAIN_PROFILE: 'profile/*',
  FULL_PROFILE: '/profile',
  FULL_PROFILE_PERSONAL:'/profile/personal',
  PROFILE_PERSONAL: 'personal',
  PROFILE_DOCUMENTS: 'documents',
  FULL_PROFILE_DOCUMENTS: '/profile/documents',
  PROFILE_SUB_ACCOUNTS: 'sub-accounts',
  FULL_PROFILE_SUB_ACCOUNTS: '/profile/sub-accounts',
  PROFILE_COMPANY: 'company',
  FULL_PROFILE_COMPANY: '/profile/company',
  PROFILE_COMPANY_INFO: ':id/info',
  FULL_PROFILE_COMPANY_INFO: '/profile/company/:id/info',
  PROFILE_COMPANY_DOCS: ':id/docs',
  FULL_PROFILE_COMPANY_DOCS: '/profile/company/:id/docs',
  PROFILE_COMPANY_OFFER: ':id/offer',
  FULL_PROFILE_COMPANY_OFFER: '/profile/company/:id/offer',
  COMPANY_ESIGN: ':id/esign',
  FULL_COMPANY_ESIGN: '/company/:id/esign',  
  NOTIFICATIONS: 'notifications',
  MAIN_NOTIFICATIONS: 'notifications/*',
  NOTIFICATIONS_EMAIL: 'email',  
  FULL_NOTIFICATIONS_EMAIL: '/notifications/email',
  NOTIFICATIONS_SMS: 'sms',  
  FULL_NOTIFICATIONS_SMS: '/notifications/sms',
  MAIN_ACCOUNT: 'account/*',
  FULL_ACCOUNT: '/account',
  ACCOUNT_HISTORY: 'history',
  FULL_ACCOUNT_HISTORY: '/account/history',
  ACCOUNT_HISTORY_ACTIONS: 'actions',
  FULL_ACCOUNT_HISTORY_ACTIONS: '/account/history/actions',
  ACCOUNT_HISTORY_PAYMENTS: 'payments',
  FULL_ACCOUNT_HISTORY_PAYMENTS: '/account/history/payments',
  ACCOUNT_WITHDRAWALS: 'widthdrawal',
  FULL_ACCOUNT_WITHDRAWALS: '/account/widthdrawal',
  ACCOUNT_REFILL: 'refill',
  FULL_ACCOUNT_REFILL: '/account/refill',
  ACCOUNT_REFILL_REQUISITES: '/account/refill/requisits/:id',
  ALL_REQUESTS: 'all-requests',
  REQUESTS_DECLARATIONS_ALL_REQUESTS: 'all-requests',
  FULL_ALL_REQUESTS: '/requests/all-requests',
  FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS: '/requests/declarations/all-requests',
  REQUESTS_ALL_REQUESTS: 'all-requests',
  REQUESTS_DECLARATIONS_ALL_REQUESTS_DONE: 'all-requests/done',
  FULL_REQUESTS_ALL_REQUESTS_DONE:'/requests/all-requests/done',
  FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS_DONE: '/requests/declarations/all-requests/done',
  FULL_REQUESTS_DECLARATION_ALL_REQUESTS_DETAIL: '/requests/declarations/all-requests/:id',
  FULL_REQUESTS_DECLARATION_ALL_REQUESTS_DETAIL_PROGRESS: '/requests/declarations/all-requests/:id/progress',
  REQUESTS_ALL_REQUESTS_IN_PROGRESS: 'all-requests/in-progress',
  REQUESTS_DECLARATIONS_ALL_REQUESTS_IN_PROGRESS: 'all-requests/in-progress',
  FULL_REQUESTS_ALL_REQUESTS_IN_PROGRESS:'/requests/all-requests/in-progress',
  FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS_IN_PROGRESS: '/requests/declarations/all-requests/in-progress',
  REQUESTS_ALL_REQUESTS_DETAIL: 'all-requests/:id',
  REQUESTS_DECLARATIONS_ALL_REQUESTS_DETAIL: 'all-requests/:id',
  FULL_REQUESTS_ALL_REQUESTS_DETAIL: '/requests/all-requests/:id/',
  ALL_REQUESTS_DETAIL_PROGRESS: 'all-requests/:id/progress',
  FULL_REQUESTS_ALL_REQUESTS_DETAIL_PROGRESS: '/requests/all-requests/:id/progress',  
  REQUESTS: 'requests',
  FULL_REQUESTS: '/requests',
  INDEX_REQUESTS: '/requests',
  MAIN_REQUESTS:'requests/*',
  FULL_REQUESTS_CLIENT_COMPLETED: '/requests/client/completed',
  REQUESTS_CLIENT_COMPLETED: 'client/completed',  
  REQUESTS_LOGISTICS: 'logistics',
  FULL_REQUESTS_LOGISTICS: '/requests/logistics',
  REQUESTS_LOGISTICS_MY_OFFERS: 'logistics/myoffers',
  FULL_REQUESTS_LOGISTICS_MY_OFFERS: '/requests/logistics/myoffers',
  REQUESTS_LOGISTICS_MY_OFFERS_DETAIL: 'logistics/myoffers/:id',
  FULL_REQUESTS_LOGISTICS_EDIT_OFFERS: '/requests/logistics/editoffers',
  REQUESTS_LOGISTICS_EDIT_OFFERS_DETAIL: 'logistics/editoffers/:id',
  REQUESTS_LOGISTICS_APPLICATIONS: 'logistics/applications',
  REQUESTS_LOGISTICS_CALCUATIONS: 'calculations',
  FULL_REQUESTS_LOGISTICS_CALCUATIONS: '/requests/logistics/calculations',
  REQUESTS_LOGISTICS_CALCUATION: 'calculation',
  FULL_REQUESTS_LOGISTICS_CALCUATION: '/requests/logistics/calculation',  
  REQUESTS_LOGISTICS_IN_WORK: 'inwork',
  FULL_REQUESTS_LOGISTICS_IN_WORK: '/requests/logistics/inwork',
  REQUESTS_LOGISTICS_IN_WORK_DETAIL: 'logistics/inwork/id',
  FULL_REQUESTS_LOGISTICS_IN_WORK_DETAIL: '/requests/logistics/inwork/id',
  REQUESTS_LOGISTICS_ARCHIVE: 'archive',
  FULL_REQUESTS_LOGISTICS_ARCHIVE: '/requests/logistics/archive',
  REQUESTS_LOGISTICS_OFFERS: 'logistics/offers',
  FULL_REQUESTS_LOGISTICS_OFFERS: '/requests/logistics/offers',
  REQUESTS_LOGISTICS_CALCULATION_DETAIL:'logistics/calculation/:id',
  FULL_REQUESTS_LOGISTICS_CALCULATION_DETAIL:'/requests/logistics/calculation/id',
  REQUESTS_LOGISTICS_INWORK_DETAIL: 'logistics/inwork/id',
  FULL_REQUESTS_LOGISTICS_INWORK_DETAIL: '/requests/logistics/inwork/id',
  REQUESTS_LOGISTICS_ARCHIVE_DETAIL: 'logistics/archive/id',
  FULL_REQUESTS_LOGISTICS_ARCHIVE_DETAIL: '/requests/logistics/archive/id',
  REQUESTS_LOGISTICS_OFFER_DETAIL: 'logistics/offer/id',
  FULL_REQUESTS_LOGISTICS_OFFER_DETAIL: '/requests/logistics/offer/id',
  REQUESTS_LOGISTICS_OFFER_SIGN_DETAIL: 'logistics/offersign/detail',
  FULL_REQUESTS_LOGISTICS_OFFER_SIGN_DETAIL: '/requests/logistics/offersign/detail',
  REQUESTS_DECLARATIONS: 'declarations',
  INDEX_REQUESTS_DECLARATIONS: '/declarations',
  FULL_REQUESTS_DECLARATIONS: '/requests/declarations',
  REQUESTS_DECLARATIONS_CLIENT_IN_WORK: 'client/inwork',
  FULL_REQUESTS_DECLARATIONS_CLIENT_IN_WORK: '/requests/declarations/client/inwork',
  REQUESTS_DECLARATIONS_CLIENT_COMPLETED: 'client/completed',
  FULL_REQUESTS_DECLARATIONS_CLIENT_COMPLETED: '/requests/declarations/client/completed',
  REQUESTS_DECLARATIONS_CLIENT_DETAIL: 'client/:id',
  FULL_REQUESTS_DECLARATIONS_CLIENT_DETAIL: '/requests/declarations/client/:id',
  REQUESTS_DECLARATIONS_CLIENT_DETAIL_INFO: 'info',
  FULL_REQUESTS_DECLARATIONS_CLIENT_DETAIL_INFO: '/requests/declarations/client/:id/info',
  REQUESTS_DECLARATIONS_CLIENT_DETAIL_PROGRESS: 'progress',
  FULL_REQUESTS_DECLARATIONS_CLIENT_DETAIL_PROGRESS: '/requests/declarations/client/:id/progress',
  REQUESTS_DETAIL: '/requests/client/:id',
  REQUESTS_CLIENT_DETAIL: 'client/:id',
  REQUESTS_CLIENT_DETAIL_INFO: 'client/:id/info',
  FULL_REQUESTS_CLIENT_DETAIL_INFO: '/requests/client/:id/info',
  REQUESTS_CLIENT_DETAIL_PROGRESS: 'client/:id/progress',
  FULL_REQUESTS_CLIENT_DETAIL_PROGRESS: '/requests/client/:id/progress',
  FULL_REQUESTS_CLIENT_NEW_REQUEST: '/requests/client/new',
  REQUESTS_DECLARATIONS_CLIENT_NEW_REQUEST: 'client/new',
  FULL_REQUESTS_DECLARATIONS_CLIENT_NEW_REQUEST: '/requests/declarations/client/new',
  REQUESTS_CLIENT_NEW_REQUEST: 'client/new',
  REQUESTS_CLIENT_DRAFTS: 'client/drafts',
  REQUESTS_DECLARATIONS_CLIENT_DRAFTS: 'client/drafts',
  FULL_REQUESTS_DECLARATIONS_CLIENT_DRAFTS: '/requests/declarations/client/drafts',
  FULL_REQUESTS_CLIENT_DRAFTS: '/requests/client/drafts',
  REQUESTS_IN_PROGRESS: '/requests/declarant/in-progress',
  REQUESTS_DECLARATIONS_POOL: 'pool',
  FULL_REQUESTS_DECLARATIONS_POOL: '/requests/declarations/pool',
  REQUESTS_DECLARATIONS_COMPLETED: 'completed',
  FULL_REQUESTS_DECLARATIONS_COMPLETED: '/requests/declarations/completed',
  REQUESTS_DECLARATIONS_DETAIL: ':id',
  REQUESTS_DECLARATIONS_DETAIL_PREVIEW: ':id/preview',
  FULL_REQUESTS_DECLARATIONS_DETAIL_INFO: '/requests/declarations/:id/info',
  FULL_REQUESTS_DECLARATIONS_DETAIL_PROGRESS: '/requests/declarations/:id/progress',
  REQUESTS_COMPLETED: '/requests/declarant/completed',
  REQUESTS_DECLARANT_COMPLETED: 'declarant/completed',
  FULL_REQUESTS_DECLARANT_COMPLETED: '/requests/declarant/completed',
  REQUESTS_DETAIL_PREVIEW: ':id/preview',
  REQUESTS_DECLARANT_DETAIL: 'declarant/:id',
  FULL_REQUESTS_DECLARANT_DETAIL_INFO: '/requests/declarant/:id/info',
  FULL_REQUESTS_DECLARANT_DETAIL_PROGRESS: '/requests/declarant/:id/progress',
  MESSAGES: 'messages',
  SERVICES: 'services',
  REVIEWS: 'reviews',
  FULL_REVIEWS: '/reviews',
  MAIN_REVIEWS: 'reviews/*',
  REVIEWS_NEW: 'new',
  FULL_REVIEWS_NEW: '/reviews/new',
  REVIEWS_OLD: 'old',
  FULL_REVIEWS_OLD: '/reviews/old',
  FULL_LOGISTICS: '/logistics',
  LOGISTICS_INFO: 'logisticsinfo',
  CERTIFICATION: 'certification',
  FULL_CERTIFICATION: '/certification',
  BUSINESS_CLUB: 'business-club',
  FULL_BUSINESS_CLUB: '/business-club',
  HELP: '/help',
  LEGAL_INFORMATION: '/legal-information',
  CHATS: 'chats',
  USERS: 'users',
  FULL_USERS: '/users',
  MAIN_USERS: 'users/*', 
  ALL_USERS: 'all-users',
  FULL_ALL_USERS: '/users/all-users',
  USER_PROFILE: 'all-users/:id',
  MAIN_USERS_DECLARANTS: '/users/declarants/*',
  USERS_DECLARANTS: 'declarants',
  FULL_USERS_DECLARANTS: '/users/declarants',
  USERS_DECLARANTS_ACCREDITED: 'declarants/accredited',
  FULL_USERS_DECLARANTS_ACCREDITED: '/users/declarants/accredited',
  USERS_DECLARANTS_PROFILE: 'declarants/:id',
  USERS_CLIENTS_PEOPLE: 'clients/people',
  FULL_USERS_CLIENTS_PEOPLE: '/users/clients/people',
  USERS_CLIENTS_COMPANIES: 'clients/companies',
  FULL_USERS_CLIENTS_COMPANIES: '/users/clients/companies',
  USERS_PARTNERS: 'partners',
  FULL_USERS_PARTNERS: '/users/partners',
  USERS_LOGISTS:'logists',
  FULL_USERS_LOGISTS:'/users/logists',
  USERS_LOGISTS_ACCREDITED: 'logists/accredited',
  FULL_USERS_LOGISTS_ACCREDITED: '/users/logists/accredited',
  USERS_LOGISTS_PROFILE: 'logists/:id',
  USERS_CLIENTS: 'clients',
  FULL_USERS_CLIENTS: '/users/clients',
  CURRENT_USERS_PARTNERS: 'partners',
  USERS_PARTNERS_PROFILE: 'partners/:id',
  USERS_CLIENTS_PROFILE: 'clients/:id',
  MAIN_USERS_CLIENTS_PROFILE: 'clients/:id/*',
  USERS_CLIENT_COMPANY_INFO: ':companyId/info',
  USERS_CLIENT_COMPANY_DOCS: ':companyId/docs',
  USERS_CLIENT_COMPANY_ESIGN: ':companyId/esign',
  FULL_USERS_CLIENT_COMPANY_INFO: '/users/clients/:id/:companyId/info', 
  FULL_USERS_CLIENT_COMPANY_DOCS: '/users/clients/:id/:companyId/docs',
  FULL_USERS_CLIENT_COMPANY_ESIGN: '/users/clients/:id/:companyId/esign',
  STAFF: 'staff',
  VERIFICATION: 'verification',
  MAIN_VERIFICATION: 'verification/*',
  FULL_VERIFICATION: '/verification',
  VERIFICATION_PROFILE: ':id',
  VERIFY_PROFILE: 'verification/:id',
  SYSTEM_ACCOUNT: 'system-account',
  SYSTEM_ACCOUNT_BALANCE: 'balance',
  FULL_SYSTEM_ACCOUNT_BALANCE: '/system-account/balance',
  SYSTEM_ACCOUNT_REQUISITES: 'requisites',
  FULL_SYSTEM_ACCOUNT_REQUISITES: '/system-account/requisites',
  FULL_SYSTEM_ACCOUNT: '/system-account',
  MAIN_SYSTEM_ACCOUNT: 'system-account/*',
  MARKETING: 'marketing',
  FULL_MARKETING: '/marketing',
  MAIN_MARKETING: 'marketing/*',
  REQUESTS_FOR_PAYMENT: 'requests-for-payment',
  FULL_REQUESTS_FOR_PAYMENT: '/requests-for-payment',
  MAIN_REQUESTS_FOR_PAYMENT: 'requests-for-payment/*',
  REQUESTS_FOR_PAYMENT_COMPLETED: 'completed',
  FULL_REQUESTS_FOR_PAYMENT_COMPLETED: '/requests-for-payment/completed',
  REQUESTS_FOR_PAYMENT_DETAIL: ':id',
  FULL_REQUESTS_FOR_PAYMENT_DETAIL: '/requests-for-payment/:id',
  SETTINGS: 'settings',
  FULL_SETTINGS: '/settings',
  MAIN_SETTINGS: 'settings/*',
  JOIN: 'join/:id',
  ACCOUNTS: 'accounts',
  FULL_ACCOUNTS: '/accounts',
  MAIN_ACCOUNTS: 'accounts/*',
  ACCOUNTS_DECLARANTS: 'declarants',
  FULL_ACCOUNTS_DECLARANTS: '/accounts/declarants',
  ACCOUNTS_LOGISTS: 'logists',
  FULL_ACCOUNTS_LOGISTS: '/accounts/logists',  
  ACCOUNTS_PARTNERS: 'partners',
  FULL_ACCOUNTS_PARTNERS: '/accounts/partners',
  ACCOUNTS_CLIENTS: 'clients',
  FULL_ACCOUNTS_CLIENTS: '/accounts/clients',
  ACCOUNTS_USER_HISTORY: ':id',
  FULL_ACCOUNTS_USER_HISTORY: '/accounts/:id',
  PARTNER_PROGRAMM: 'partner-programm',
  FULL_PARTNER_PROGRAMM: '/partner-programm',
  ACT_FOR_REQUEST: 'act/request/:id',
  NOT_FOUND: '/notfound'
};

export const API_ROUTES = {
  UPLOAD: `${ENV.API_URL}/upload`,
  PROFILE: `${ENV.API_URL}/profile`,
  SUB_PROFILE: `${ENV.API_URL}/profile/sub-profile`,
  LOGIN: `${ENV.API_URL}/profile/login`,
  REGISTER: `${ENV.API_URL}/profile/create`,
  VERIFY_EMAIL: `${ENV.API_URL}/profile/verify-email`,
  MODIFY_PROFILE: `${ENV.API_URL}/profile/modify`,
  UPDATE_PASSWORD: `${ENV.API_URL}/profile/update-password`,
  RESET_PASSWORD: `${ENV.API_URL}/profile/reset-password`,
  UPDATE_EMAIL: `${ENV.API_URL}/profile/update-email`,
  CREATE_REQUEST: `${ENV.API_URL}/requests/create`,
  DRAFT: `${ENV.API_URL}/requests/draft`,
  ALL_REQUESTS: `${ENV.API_URL}/all-requests`,
  ALL_REQUESTS_DONE: `${ENV.API_URL}/all-requests/done`,
  ALL_REQUESTS_IN_PROGRESS: `${ENV.API_URL}/all-requests/in-progress`,
  REQUESTS: `${ENV.API_URL}/requests`,
  REQUESTS_POOL: `${ENV.API_URL}/requests/pool`,
  REQUESTS_IN_PROGRESS: `${ENV.API_URL}/requests/in-progress`,
  REQUESTS_COMPLETED: `${ENV.API_URL}/requests/completed`,
  COMPANIES: `${ENV.API_URL}/companies`,
  COMPANIES_DELETE_ONE: `${ENV.API_URL}/companies/deleteone`,
  MYCOMPANIES: `${ENV.API_URL}/mycompanies`,
  COMPANY_SUGGESTIONS: `${ENV.API_URL}/companies/suggestions`,
  BANK_DATA: `${ENV.API_URL}/bank-accounts/resolver`,
  CREATE_BANK_ACCOUNT: `${ENV.API_URL}/bank-accounts/create`,
  DELETE_BANK_ACCOUNT: `${ENV.API_URL}/bank-accounts/delete`,
  COMPANY_BANK_ACCOUNTS: `${ENV.API_URL}/bank-accounts/company`,
  PERSONAL_ACCOUNTS: `${ENV.API_URL}/personal-accounts`,
  PERSONAL_ACCOUNTS_CREATE: `${ENV.API_URL}/personal-accounts/create`,
  PERSONAL_ACCOUNTS_DELETE: `${ENV.API_URL}/personal-accounts/delete`,
  PERSONAL_ACCOUNTS_ARCHIVE: `${ENV.API_URL}/personal-accounts/archive`,
  PAYMENTS: `${ENV.API_URL}/payments`,
  PAYMENTS_COMPLETED: `${ENV.API_URL}/payments-completed`,
  PAYMENTS_USER_HISTORY: `${ENV.API_URL}/payments-user`,
  DOCS_QUERIES: `${ENV.API_URL}/docs-queries`,
  MESSAGES: `${ENV.API_URL}/messages`,
  VERIFICATION: `${ENV.API_URL}/verification`,
  REJECTION_REASONS: `${ENV.API_URL}/rejection-reasons`,
  USERS: `${ENV.API_URL}/users`,
  USERS_COMPANIES: `${ENV.API_URL}/users/companies`,
  USERS_COMPANY: `${ENV.API_URL}/users/company`,
  PROMOCODES: `${ENV.API_URL}/promocodes`,
  INVITES: `${ENV.API_URL}/invites`,
  SYSTEM_REQUEST: `${ENV.API_URL}/system/request`,
  SYSTEM_ACCOUNT: `${ENV.API_URL}/system/account`,
  TARIFFS: `${ENV.API_URL}/tariffs`,
  TRANSACTIONS: `${ENV.API_URL}/transactions`,
  TRANSACTIONS_ACCOUNTS: `${ENV.API_URL}/transactions/accounts`,
  TRANSACTIONS_OUTPAYMENTS: `${ENV.API_URL}/transactions/outpayments`,
  TRANSACTIONS_SYSTEM_ACCOUNTS: `${ENV.API_URL}/transactions/system/accounts`,
  TRANSACTIONS_PROFILE: `${ENV.API_URL}/transactions/profile`,
  REVIEWS: `${ENV.API_URL}/reviews`,
  LOGISTICS_NEW_REQUEST: `${ENV.API_URL}/logistics/newrequest`,
  LOGISTICS_NEW_OFFER: `${ENV.API_URL}/logistics/newoffer`,   
  LOGISTICS_CLIENT_MY_REQUESTS: `${ENV.API_URL}/logistics/client/myrequests`,
  LOGISTICS_MANAGER_MY_REQUESTS: `${ENV.API_URL}/logistics/manager/myrequests`,
  LOGISTICS_MY_IN_WORK: `${ENV.API_URL}/logistics/myinwork`,
  LOGISTICS_MY_ARCHIVE: `${ENV.API_URL}/logistics/myarchive`, 
  LOGISTICS_CLIENT_GET_REQUEST: `${ENV.API_URL}/logistics/client/getrequest`,
  LOGISTICS_MANAGER_GET_REQUEST: `${ENV.API_URL}/logistics/manager/getrequest`,
  LOGISTICS_DELETE_OFFER: `${ENV.API_URL}/logistics/manager/deleteoffer`,
  LOGISTICS_DELETE_REQUEST: `${ENV.API_URL}/logistics/client/deleterequest`,
  LOGISTICS_MANAGER_MY_OFFERS_PACKS: `${ENV.API_URL}/logistics/manager/myofferspacks`,
  LOGISTICS_MANAGER_MY_OFFERS_PACK: `${ENV.API_URL}/logistics/manager/myofferspack`,
  LOGISTICS_MANAGER_MY_OFFERS_FROM_PACK: `${ENV.API_URL}/logistics/manager/myoffersfrompack`,
  LOGISTICS_MANAGER_DELETE_MY_OFFERS_PACK: `${ENV.API_URL}/logistics/manager/deleteofferspack`,
  LOGISTICS_MANAGER_NEW_MY_OFFERS_PACK: `${ENV.API_URL}/logistics/manager/newmyofferspacks`,
  LOGISTICS_CLIENT_OFFERS: `${ENV.API_URL}/logistics/client/offers`,
  LOGISTICS_CLIENT_SEND_OFFER_EMAIL: `${ENV.API_URL}/logistics/client/sendofferemail`,
};

export const DEBOUNCE_DELAY = 2000;

export const ROLES = {
  ADMIN: 'admin',
  DECLARANT: 'declarant',
  USER: 'user',
  PARTNER: 'partner',
  LOGIST: 'logist',
};

export const DOCUMENTS_STEPS = {
  EMPTY: 'empty',
  IN_PROGRESS: 'inProgress',
  FILLED_IN: 'filledIn',
  IN_REVIEW: 'inReview',
  FAILED_REVIEW: 'failedReview',
  PASSED_REVIEW: 'passedReview',
};

export const TOKEN = 'auth-token';

export const ITEMS_PER_PAGE = 8;

export const WARNING_MESSAGE = {
  COMPANY_IS_NOT_VERIFIED: {
    title: "Компания не верифицирована",
    description: "Что-бы верифицировать свою компанию, добавьте ее банковские реквизиты в профиле компании и пополните счет на любую сумму от ее лица."
  },
  COMPANY_IS_NOT_ADDED: {
    title: "Компания не добавлена",
    description: "Для того, чтобы оставить заявку в системе, требуется добавить компанию"
  },  
  PROFILE_IS_NOT_FILLED: {
    title: "Профиль не заполнен",
    description: "Заполните имя и фамилия в профиле пожалуйста"
  },
  COMPANY_HAS_NOT_OFFER: {
    title: "Оффер предложений не заполнен",
    description: "Для того, чтобы составить предложения в системе, требуется добавить оффер компании"
  },
  ACCOUNT_IS_NOT_ADDED: {
    title: "Счет компании не добавлен",
    description: "Для того, чтобы пользоваться сервисом, требуется добавить счет компании"
  }     

}

export const INPUT_FILL = {
  MANUFACTURE: {
    NAME_INPUT: "Адрес отправителя",
    NAME_HINT: "Поле ввода адреса отправителя",
    HINT_ONE: "В этом поле указывается адрес завода, на который необходимо предоставить контейнер.",
    HINT_TWO: "Адрес указывается фактический, без названия страны, и на английском языке",
    EXAMPLE: "Пример: Shanghai, Big Bada Boom Ave. 16233"
  },
  DEPARTURE: {
    NAME_INPUT: "Пункт отправления",
    NAME_HINT: "Поле ввода пункта отправления",
    HINT_ONE: "В этом поле указывается наменование ближайшего порта или жд станции откуда отправляется груз.",
    HINT_TWO: "Наменование указывается без названия страны, и на английском языке",
    EXAMPLE: "Пример: Shanghai"
  },
  ARRIVAL: {
    NAME_INPUT: "Пункт прибытия",
    NAME_HINT: "Поле ввода пункта прибытия",
    HINT_ONE: "В этом поле указывается наменование порта или жд станции прибытия груза",
    HINT_TWO: "Наменование указывается без названия страны, и на английском языке",
    EXAMPLE: "Пример: Vladivostok"
  },    
}


export const DEFINE_DIFFERENCE_CURENCY = {
  CURR_PLUS_PERCENT: "ЦБ на день оплаты + %",
  CURR_RUB: "Оплата в рублях ЦБ РФ"
}

export const CURENCY = {
  USD: "$",
  RUB: "₽",
  YEN: "¥",
}

export const FILTERS = {
  DECLARANTS_TO_VERIFY: 'DECLARANTS_TO_VERIFY',
  DRAFTS: 'DRAFTS',
  REQUESTS_LIST: 'REQUESTS_LIST',
  REQUESTS_CREATED_LIST: 'REQUESTS_CREATED_LIST',
  REQUESTS_CLIENT_COMPLETED: 'REQUESTS_CLIENT_COMPLETED',
  REQUESTS_PARTNER_COMPLETED: 'REQUESTS_PARTNER_COMPLETED',
  REQUESTS_IN_PROGRESS: 'REQUESTS_IN_PROGRESS',
  REQUESTS_COMPLETED: 'REQUESTS_COMPLETED',
  REQUESTS_POOL: 'REQUESTS_POOL',
  ALL_REQUESTS: 'ALL_REQUESTS',
  ALL_REQUESTS_DONE: 'ALL_REQUESTS_DONE',
  ALL_REQUESTS_IN_PROGRESS: 'ALL_REQUESTS_IN_PROGRESS',
  ALL_USERS: 'ALL_USERS',
  DECLARANTS_ALL_USERS: 'DECLARANTS_ALL_USERS',
  DECLARANTS_ACCREDITED_USERS: 'DECLARANTS_ACCREDITED_USERS',
  LOGISTS_ALL_USERS: 'LOGISTS_ALL_USERS',
  LOGISTS_ACCREDITED_USERS: 'LOGISTS_ACCREDITED_USERS',
  CLIENTS_USERS_PEOPLE: 'CLIENTS_USERS_PEOPLE',
  CLIENTS_USERS_COMPANIES: 'CLIENTS_USERS_COMPANIES',
  ACCOUNTS_CLIENTS: 'ACCOUNTS_CLIENTS',
  ACCOUNTS_DECLARANTS: 'ACCOUNTS_DECLARANTS',
  ACCOUNTS_PARTNERS: 'ACCOUNTS_PARTNERS',
  ACCOUNTS_LOGISTS: 'ACCOUNTS_LOGISTS',
  ACCOUNT_TRANSACTIONS: 'ACCOUNT_TRANSACTIONS',
  ALL_TRANSACTIONS: 'ALL_TRANSACTIONS',
  PAYMENTS_COMPLETED: 'PAYMENTS_COMPLETED',
  PAYMENTS_USER_HISTORY: 'PAYMENTS_USER_HISTORY',
  PARTNERS: 'PARTNERS',
  ALL_MY_LOGISTICS_REQUESTS: 'ALL_MY_LOGISTICS_REQUESTS',
  ALL_MY_LOGISTICS_IN_WORK: 'ALL_MY_LOGISTICS_IN_WORK',
  ALL_MY_LOGISTICS_ARCHIVE: 'ALL_MY_LOGISTICS_ARCHIVE',
  MY_OFFERS_PACKS: 'MY_OFFERS_PACKS',
  MY_OFFERS_FROM_PACK: 'MY_OFFERS_FROM_PACK',
  CLIENT_OFFERS: 'CLIENT_OFFERS',
};

export const DEFAULT_FILTERS = {
  [FILTERS.DECLARANTS_TO_VERIFY]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.DRAFTS]: {
    offset: 0,
    sortField: 'updatedAt',
    sortDirection: '-1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.REQUESTS_LIST]: {
    offset: 0,
    sortField: 'number',
    sortDirection: '-1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.REQUESTS_CREATED_LIST]: {
    offset: 0,
    sortField: 'number',
    sortDirection: '-1',
    noDone: true,
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.REQUESTS_CLIENT_COMPLETED]: {
    offset: 0,
    sortField: 'number',
    sortDirection: '-1',
    done: true,
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.REQUESTS_IN_PROGRESS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    status: 'inProgress',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.REQUESTS_COMPLETED]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    role: 'declarant',
    status: 'done',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.REQUESTS_PARTNER_COMPLETED]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    role: 'partner',
    status: 'done',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.REQUESTS_POOL]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ALL_REQUESTS]: {
    offset: 0,
    sortField: 'number',
    sortDirection: '-1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ALL_REQUESTS_DONE]: {
    offset: 0,
    sortField: 'updatedAt',
    sortDirection: '-1',
    status: 'done',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ALL_REQUESTS_IN_PROGRESS]: {
    offset: 0,
    sortField: 'number',
    sortDirection: '-1',
    status: 'inProgress',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ALL_USERS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'all',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.DECLARANTS_ACCREDITED_USERS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    accredited: 'true',
    role: 'declarant',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.DECLARANTS_ALL_USERS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'declarant',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.LOGISTS_ALL_USERS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'logist',
    limit: ITEMS_PER_PAGE,
  }, 
  [FILTERS.LOGISTS_ACCREDITED_USERS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    accredited: 'true',
    role: 'logist',
    limit: ITEMS_PER_PAGE,
  },   
  [FILTERS.CLIENTS_USERS_PEOPLE]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'client',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.PARTNERS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'partner',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.CLIENTS_USERS_COMPANIES]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ACCOUNTS_CLIENTS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'user',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ACCOUNTS_DECLARANTS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'declarant',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ACCOUNTS_PARTNERS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'partner',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ACCOUNTS_LOGISTS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    role: 'logist',
    limit: ITEMS_PER_PAGE,
  },  
  [FILTERS.ACCOUNT_TRANSACTIONS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ALL_TRANSACTIONS]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    fromDate: '',
    toDate: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.PAYMENTS_COMPLETED]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.PAYMENTS_USER_HISTORY]: {
    offset: 0,
    sortField: 'createdAt',
    sortDirection: '-1',
    search: '',
    limit: ITEMS_PER_PAGE,
  },
  [FILTERS.ALL_MY_LOGISTICS_REQUESTS]: {
    offset: 0,
    sortField: 'docId', //readydate
    sortDirection: '1',
    limit: 6,
  },
  [FILTERS.ALL_MY_LOGISTICS_IN_WORK]: {
    offset: 0,
    sortField: 'docId',
    sortDirection: '1',
    limit: 6,
  },
  [FILTERS.ALL_MY_LOGISTICS_ARCHIVE]: {
    offset: 0,
    sortField: 'docId', 
    sortDirection: '1',
    limit: 6,
  },
  [FILTERS.MY_OFFERS_PACKS]: {
    offset: 0,
    sortField: 'docId', //docId / typetransport / expireDate
    sortDirection: '1',
    limit: 6,
  },
  [FILTERS.MY_OFFERS_FROM_PACK]: {
    offset: 0,
    sortField: 'docId', //docId / expireDate
    sortDirection: '1',
    limit: 6,
  },  
  [FILTERS.CLIENT_OFFERS]: {
    offset: 0,
    sortField: 'docId',
    sortDirection: '1',
    limit: 1, //1
  },    
};

export const NEW_REQUEST_LAST_STEP = 4;

export const FILE_NAME_LENGTH = 15;

export const RANDOM_KEY_MIN = 1;
export const RANDOM_KEY_MAX = 1000000000;

export const WEEK_DAYS = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
export const DAY = 1000 * 60 * 60 * 24;

export const TEMP_PROFILE_ID = 'TEMP_PROFILE_ID';
export const SYSTEM_PROFILE_ID = 'SYSTEM_PROFILE_ID';

export const TRANSACTIONS_TYPES = {
  REFILL_CA: 'selfRefillCA',
  ORDER_PAYMENT_CA: 'orderPaymentCA',
  ORDER_REFUND_CA: 'orderRefundCA',
  ORDER_RECEIPT_DA: 'orderReceiptDA',
  ORDER_RECEIPT_PA: 'orderReceiptPA',
  ORDER_RECEIPT_SA: 'orderReceiptSA',
  ADD_PAYMENT_CADA: 'addPaymentCADA',
  ADD_PAYMENT_CASA: 'addPaymentCASA',
  DEBIT_DA: 'selfDebitDA',
  DEBIT_PA: 'selfDebitPA',
};

export const BASIC_PRICE = 9990;
export const CODES_PER_PAGE = 3;
export const PAGE_PRICE = 900;
export const DECLARANT_SHARE = 60;
export const PARTNER_RATE = 60;
export const ACCOUNT_THRESHOLD = 2990;
