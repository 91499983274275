import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from '../consts';
import { OldReviews } from '../components/Reviews/OldReviews';
import { NewReviews } from '../components/Reviews/NewReviews';
import { Tabs } from '../components/shared/Tabs';
import { Title } from '../components/shared/Title';

export const AdminReviews = () => {
  return (
    <div>
      <Title>Обращения</Title>
      <Tabs
        tabs={[
          {
            title: 'Новые',
            route: ROUTES.FULL_REVIEWS_NEW,
            visible: true,
          },
          {
            title: 'Завершенные',
            route: ROUTES.FULL_REVIEWS_OLD,
            visible: true,
          },
        ]}
      />
      <Routes>
        <Route path={ROUTES.REVIEWS_NEW} Component={NewReviews} />
        <Route path={ROUTES.REVIEWS_OLD} Component={OldReviews} />
        <Route path="*" element={<Navigate to={ROUTES.REVIEWS_NEW} />} />        
      </Routes>
    </div>
  );
};
