import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { STATUS_OPTIONS } from '../../../configs/request';
import {
  getCount,
  getLoading,
  getPool,
  applyAllRequestsInProgressFilters,
  loadAllRequestsInProgress,
} from '../../../store/requests';
import { Message } from '../../shared/Message';
import { Table } from '../../shared/Table';
import { ROUTES } from '../../../consts';
import { formatMoney } from '../../../utils';
import { getAllRequestsInProgressFilters } from '../../../store/filters';

const REQUEST_HEADERS = [
  {
    label: '№ Заявки',
    value: 'number',
    sortable: true,
    render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
  },
  {
    label: 'Дата создания заявки',
    value: 'createdAt',
    sortable: true,
    render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
  },
  {
    label: 'Декларант',
    value: 'declarant',
    render: (value) =>
      value ? (
        <div>
          {value?.['name']} {value?.['surname']}
        </div>
      ) : (
        <div>&mdash;</div>
      ),
  },
  {
    label: 'Компания',
    value: 'company',
    render: (value) => <div>{value?.['name']}</div>,
  },
  {
    label: 'Статус заявки',
    value: 'status',
    render: (value) => STATUS_OPTIONS[value]?.label,
  },
  {
    label: 'Предварительная стоимость заявки',
    value: 'price',
    render: formatMoney,
  },
  {
    label: '',
    value: 'rowActions',
    render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
  },
];

export const AllRequestsInProgress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pool = useSelector(getPool);
  const loading = useSelector(getLoading);
  const filters = useSelector(getAllRequestsInProgressFilters);
  const countItems = useSelector(getCount);

  useEffect(() => {
    dispatch(loadAllRequestsInProgress);
  }, [dispatch]);

  return (
    <div>
      {pool.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`${ROUTES.FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS}/${_id}/`);
          }}
          loading={loading}
          items={pool}
          headers={REQUEST_HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) =>
            dispatch(applyAllRequestsInProgressFilters(filters))
          }
        />
      ) : (
        <Message type="info">
          В системе нет сведений о заявках, взятых в работу
        </Message>
      )}
    </div>
  );
};
