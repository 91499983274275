import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import {
  loadUsers,
  getUsersCount,
  getLoading,
  getUsers,
  applyFilters,
} from '../../store/users';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { getPartnersFilters } from '../../store/filters';
import { FILTERS, ROUTES } from '../../consts';
import { getProfile } from '../../store/profile';

export const Partners = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(getLoading);
  const users = useSelector(getUsers);
  const filters = useSelector(getPartnersFilters);
  const countItems = useSelector(getUsersCount);
  const profile = useSelector(getProfile);
  const filtersKey = FILTERS.PARTNERS;

  const HEADERS = [
    {
      label: 'ID',
      value: '_id',
      sortable: true,
      render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
    },
    {
      label: 'Контакты',
      value: 'contacts',
      renderItem: true,
      render: (item) => (
        <div>
          <div>{item.phone}</div>
          <div>{item.email}</div>
        </div>
      ),
    },
    {
      label: 'Пользователь',
      value: 'name',
      renderItem: true,
      render: (item) =>
        item.name === undefined && item.surname === undefined
          ? ''
          : `${item.name} ${item.surname}`,
    },
    {
      label: 'Дата регистрации',
      value: 'createdAt',
      sortable: true,
      render: (value) =>
        value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : 'нет данных',
    },
    {
      label: '% ставка',
      value: 'partnerRate',
      render: (value) => <span>{value} %</span>,
    },
    {
      label: 'Рефералы',
      value: 'referrals',
      sortable: true,
      render: () => <span>0</span>,
    },
    {
      label: '',
      value: 'rowActions',
      render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
    },
  ];

  useEffect(() => {
    if (profile._id) {
      dispatch(loadUsers(filters));
    }
  }, [dispatch, filters, profile._id]);

  return (
    <div>
      {users?.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`${ROUTES.FULL_USERS_PARTNERS}/${_id}`);
          }}
          loading={loading}
          items={users}
          headers={HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) =>
            dispatch(applyFilters(filtersKey, filters))
          }
        />
      ) : (
        <Message type="info">В системе пока нет сведений о партнерах</Message>
      )}
    </div>
  );
};
