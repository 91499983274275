import {
  CLEAN_FORM,
  RESET_FORM,
  UPDATE_ITEM,
  SET_ITEM,
  UPDATE_NEW_REQUEST,
  SET_LOADING,
  LOAD_CLIENT_REQUESTS_REQUEST,
  LOAD_CLIENT_REQUESTS_SUCCESS,
  LOAD_CLIENT_REQUESTS_FAILURE,
  LOAD_ALL_REQUESTS_REQUEST,
  LOAD_ALL_REQUESTS_SUCCESS,
  LOAD_ALL_REQUESTS_FAILURE,
  LOAD_POOL_REQUEST,
  LOAD_POOL_SUCCESS,
  LOAD_POOL_FAILURE,
  LOAD_IN_PROGRESS_REQUEST,
  LOAD_IN_PROGRESS_SUCCESS,
  LOAD_IN_PROGRESS_FAILURE,
  LOAD_COMPLETED_REQUEST,
  LOAD_COMPLETED_SUCCESS,
  LOAD_COMPLETED_FAILURE,
  LOAD_DRAFTS_REQUEST,
  LOAD_DRAFTS_SUCCESS,
  LOAD_DRAFTS_FAILURE,
} from './actions';

import { NEW_REQUEST_LAST_STEP } from '../../consts';

const initialState = {
  items: [],
  count: 0,
  pool: [],
  item: {},
  drafts: [],
  draftsCount: 0,
  unreadDraftsCount: 0,
  loading: false,
  newRequest: {
    deliveryInfo: {
      type: '',
      shipping: '',
      company: '',
      terms: '',
      uniqueCount: 0,
      techDesc: '',
      receiptDate: new Date(),
      storeAddress: '',
      withSupport: false,
      speedUp: false,
      withGoodsLookup: false,
      withPermissionDocs: false,
      withPresense: false,
      withLoadingControl: false,
      withFitoSanControl: false,
      withDocsTransfer: false,
    },
    documents: {
      contract: [],
      statute: [],
      misc: [],
      permits: [],
      docs: [],
    },
    tariff: 'declarant',
    declaration: [],
    errors: {},
    step: 0,
    discount: 0,
    fromDraft: false,
  },
};

export const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_FORM:
      return {
        ...state,
        newRequest: {
          ...initialState.newRequest,
        },
      };
    case RESET_FORM:
      return {
        ...initialState,
        newRequest: {
          ...initialState.newRequest,
          step: NEW_REQUEST_LAST_STEP,
          deliveryInfo: {
            ...initialState.newRequest.deliveryInfo,
            company: state.newRequest.deliveryInfo.company,
          },
          number: action.payload.number,
          discount: action.payload.discount,
          price: action.payload.price,
        },
      };

    case UPDATE_NEW_REQUEST:
      const { key, subKey, value } = action.payload;
      if (subKey) {
        const res = {
          ...state,
          newRequest: {
            ...state.newRequest,
            [key]: {
              ...state.newRequest[key],
              [subKey]: value,
            },
            errors: {
              ...state.newRequest.errors,
              [subKey]: undefined,
            },
          },
        };
        return res;
      }

      return {
        ...state,
        newRequest: {
          ...state.newRequest,
          [key]: value,
        },
      };
    case UPDATE_ITEM:
      return {
        ...state,
        item: {
          ...state.item,
          [action.payload.key]: action.payload.value,
        },
      };
    case SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case LOAD_CLIENT_REQUESTS_SUCCESS:
      return {
        ...state,
        items: action.payload.requests,
        count: action.payload.count,
        loading: false,
      };
    case LOAD_CLIENT_REQUESTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case LOAD_IN_PROGRESS_REQUEST:
    case LOAD_COMPLETED_REQUEST:
    case LOAD_CLIENT_REQUESTS_REQUEST:
    case LOAD_ALL_REQUESTS_REQUEST:
    case LOAD_DRAFTS_REQUEST:
    case LOAD_POOL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_POOL_SUCCESS:
      return {
        ...state,
        pool: action.payload.requests,
        count: action.payload.count,
        loading: false,
      };
    case LOAD_ALL_REQUESTS_SUCCESS:
      return {
        ...state,
        pool: action.payload.requests,
        count: action.payload.count,
        loading: false,
      };

    case LOAD_DRAFTS_SUCCESS:
      return {
        ...state,
        drafts: action.payload.drafts,
        draftsCount: action.payload.count,
        unreadDraftsCount: action.payload.unreadDraftsCount,
        loading: false,
      };
    case LOAD_POOL_FAILURE:
    case LOAD_DRAFTS_FAILURE:
    case LOAD_ALL_REQUESTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case LOAD_IN_PROGRESS_SUCCESS:
    case LOAD_COMPLETED_SUCCESS:
      return {
        ...state,
        pool: action.payload.requests,
        count: action.payload.count,
        loading: false,
      };
    case LOAD_IN_PROGRESS_FAILURE:
    case LOAD_COMPLETED_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
