import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { format } from 'date-fns';

import { ROUTES } from '../../consts';
import { SHIPPING_OPTIONS } from '../../configs/request';
import { getRequestListFilters } from '../../store/filters';
import { updateDraft } from '../../api/requests';
import { isProfiledFilled } from '../../store/profile';
import {
  applyListFilters,
  deleteDraft,
  loadDrafts,
  getDrafts,
  getDraftsCount,
  getLoading,
  getUnreadDraftsCount,
} from '../../store/requests';
import { ConfirmModal } from '../shared/ConfirmModal';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import styles from './Requests.module.scss';

const DeleteDraft = ({ bold, onDelete }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        style={{
          color: 'red',
          padding: '0.5rem 1rem',
          cursor: 'pointer',
          fontWeight: bold ? 'bold' : 'normal',
        }}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        Удалить
      </div>
      <ConfirmModal
        message={`Вы уверены, что следует удалить черновик?`}
        open={open}
        onConfirm={() => {
          setOpen(false);
          onDelete();
        }}
        onReject={() => setOpen(false)}
      />
    </>
  );
};

const renderBold = (item, value) => {
  return item.unread ? <b>{value}</b> : value;
};

export const Drafts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileFilled = useSelector(isProfiledFilled);
  const loading = useSelector(getLoading);
  const drafts = useSelector(getDrafts);
  const countItems = useSelector(getDraftsCount);
  const filters = useSelector(getRequestListFilters);

  useEffect(() => {
    dispatch(loadDrafts);
  }, [dispatch]);

  const REQUEST_HEADERS = [
    {
      label: 'Последнее изменение',
      value: 'updatedAt',
      renderItem: true,
      render: (item) => {
        return (
          <>
            {item.unread && (
              <div
                style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  height: '10px',
                  width: '10px',
                  backgroundColor: '#00A0F9',
                  borderRadius: '50%',
                  display: 'inline-block',
                }}
              />
            )}
            {renderBold(
              item,
              format(new Date(item.updatedAt), 'dd.MM.yyyy HH:mm')
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      label: 'Тип перевозки',
      value: 'shipping',
      renderItem: true,
      render: (item) =>
        renderBold(item, SHIPPING_OPTIONS[item.shipping]?.label),
    },
    // {
    //   label: 'Статус',
    //   value: 'status',
    //   renderItem: true,
    //   render: (value) => STATUS_OPTIONS[value]?.label,
    //   sortable: true,
    // },
    {
      label: '',
      renderItem: true,
      value: 'rowActions',
      render: (item) => (
        <DeleteDraft
          bold={item.unread}
          onDelete={() => dispatch(deleteDraft(item._id))}
        />
      ),
    },
    {
      label: '',
      value: 'rowActions',
      renderItem: true,
      render: (item) => (
        <Link
          style={{
            color: '#1890ff',
            textDecoration: 'none',
            fontWeight: item.unread ? 'bold' : 'normal',
          }}
          to={`${ROUTES.FULL_REQUESTS}/client/new?fromDraft=${item._id}`}
          onClick={() => {
            if (item.unread) {
              updateDraft(item._id, { unread: false });
            }
          }}
        >
          Продолжить
        </Link>
      ),
    },
  ];

  return (
      <>
        {drafts?.length > 0 ? (
          <Table
            loading={loading}
            items={drafts}
            headers={REQUEST_HEADERS}
            filters={filters}
            countItems={countItems}
            applyFilters={(filters) => dispatch(applyListFilters(filters))}
          />
        ) : (
          <Message
            underlined={!profileFilled}
            type={profileFilled ? 'info' : 'exclamation'}
          >
            {true
              ? 'У вас пока нет черновиков'
              : 'Для создания новой заявки необходимо заполнить хотя бы 1 компанию'}
          </Message>
        )}
    </>
  );
};
