import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getDeclarant } from '../../store/verification';
import { PASSPORT_FIELDS_VIEW, PASSPORT_FILES_VIEW } from '../../configs/passport';
import { DataBlock } from '../Documents/DataBlock';
import { ButtonCheckBlock } from './ButtonCheckBlock';
import { Accordion } from '../shared/Accordion';
import { PassportFileCheck } from './PassportFileCheck';
import { DOCUMENTS_STEPS } from '../../consts';
import RedCross from '../../assets/RedCross.svg';
import GreenCheckmark from '../../assets/GreenCheckmark.svg';
import YellowQuestion from '../../assets/YellowQuestion.svg';

import styles from './PassportFormCheck.module.scss';

export const PassportFormCheck = ({
  passport,
  declarantId,
  section = 'passport',
}) => {
  const declarant = useSelector(getDeclarant);

  const step = declarant?.passport.step;
  const { FAILED_REVIEW, PASSED_REVIEW, IN_REVIEW } = DOCUMENTS_STEPS;
  const mark =
    {
      [FAILED_REVIEW]: RedCross,
      [PASSED_REVIEW]: GreenCheckmark,
      [IN_REVIEW]: YellowQuestion,
    }[step] || null;

  const [passpOpen, setPasspOpen] = useState(true);
  const handleToggle = () => setPasspOpen((prev) => !prev);

  return (
    <Accordion
      title={
        <div className={styles.Title}>
          {mark && <img src={mark} alt="mark" className={styles.Title__Img} />}
          <div className={styles.Title__Text}>Паспорт гражданина РФ</div>
        </div>
      }
      open={passpOpen}
      onToggle={handleToggle}
      fadedToggler
    >
      <div className={styles.PassportForm}>
        <div className={styles.PassportForm__Fields}>
          {Object.keys(PASSPORT_FIELDS_VIEW).map((field) => (
            <DataBlock
              key={field}
              field={field}
              {...PASSPORT_FIELDS_VIEW[field]}
              value={passport[field]}
              disabled
              lightgrey
            />
          ))}
        </div>
        <div className={styles.PassportForm__Files}>
          {Object.keys(PASSPORT_FILES_VIEW).map((file) => (
            <PassportFileCheck
              key={file}
              id={file}
              value={passport[file]}
              {...PASSPORT_FILES_VIEW[file]}
            />
          ))}
        </div>
      </div>
      <div className={styles.PassportForm__ButtonBlock}>
        <ButtonCheckBlock
          declarantId={declarantId}
          section={section}
          config={PASSPORT_FIELDS_VIEW}
          selectPlaceholder="Причина отказа"
        />
      </div>
    </Accordion>
  );
};
