import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES, ROLES, FILTERS } from '../consts';
import { HeaderWithChapter } from '../components/shared/HeaderWithChapter';
import { GoBackLink } from '../components/shared/GoBackLink';
import { TablesNav } from '../components/Logistics/Table/TablesNav';
import { TableBody } from '../components/Logistics/Table/TableBody';
import { ColumnEditor } from '../components/Logistics/Table/ColumnEditor';
import { TableManagerActivity } from '../components/Logistics/Table/TableManagerActivity';
import { TablePagination } from '../components/Logistics/Table/TablePagination';
import { ModalConfirms } from '../components/Logistics/ModalConfirms';
import {spreadFieldTable} from '../utils/spreadFieldTable';
import {spreadLinkTable} from '../utils/spreadLinkTable';
import {spreadActivityTable} from '../utils/spreadActivityTable';
import { OffersMaker } from '../components/Logistics/Offers/OffersMaker';
import styles from './RequestsLogisticsClient.module.sass';
import classnames from 'classnames';

import {
	getMyOffersPacksFilters,
	applyFilters } from '../store/filters';

import { 
	getMyOffersPacks,
	getMyOffersPacksCount,
	removeMyOffersPack,
	switchData } from '../store/logisticsOffersPacks';

export const MyOffersLogisticsManager = () => {
	const dispatch = useDispatch();
	const [tableType, setTableType] = useState("myofferspacks"); // myofferspacks newofferspack
	const [modalState, setModalState] = useState({open:false, id: null});

	const logisticsManager = {
		myofferspacks:{
			data: useSelector(getMyOffersPacks),
			filters: useSelector(getMyOffersPacksFilters),
			count: useSelector(getMyOffersPacksCount),
			filterKey: FILTERS.MY_OFFERS_PACKS
		}
	};
	const navList =[
		{
			type: "myofferspacks",
			text: "Мои предложения"
		},
		{
			type: "newofferspack",
			text: "Новые предложения"
		},		
	];	
	const tableData = [
				{
					filterType: 'simpleFilter',
					descrType: 'link',
					linkArr: spreadLinkTable(logisticsManager.myofferspacks.data),
					title: `№ Предложений`,
					dataArr: spreadFieldTable(logisticsManager.myofferspacks.data,"docId"),
					filterKey: FILTERS.MY_OFFERS_PACKS,
					sortField: "docId",
					position: "center" //center fd_column
				},
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Название`,
					dataArr: spreadFieldTable(logisticsManager.myofferspacks.data,"name"),					
				},				
				{
					filterType: 'simpleFilter',
					descrType: 'plain',
					title: `Вид`,
					dataArr: spreadFieldTable(logisticsManager.myofferspacks.data,"typetransport"),
					filterKey: FILTERS.MY_OFFERS_PACKS,
					sortField: "typetransport"
				},
				{
					filterType: 'plain',
					descrType: 'plain',
					title: `Пункт прибытия`,
					dataArr: spreadFieldTable(logisticsManager.myofferspacks.data,"pointArrival"),

				},
				{
					filterType: 'simpleFilter',
					descrType: 'plain',
					title: `Актуальность до`,
					dataArr: spreadFieldTable(logisticsManager.myofferspacks.data,"expireDate"),
					filterKey: FILTERS.MY_OFFERS_PACKS,
					sortField: "expireDate"					
				}																				
	];
	const descrModalConfirms = {
		message: "Вы уверены что хотите удалить предложения?",
		descrMessage: "При согласии предложения будут удалены"+
						" вместе с заявками по ним, и историей сообщений",
		resolve: "Удалить",
		reject: "Отменить"
	};

	const dispatchCb = () => {
		dispatch(removeMyOffersPack(modalState.id));
	}	
	useEffect(()=>{
		dispatch(switchData(tableType, logisticsManager.myofferspacks.filters));
	},[tableType, logisticsManager.myofferspacks.filters]);	

	return (
		<>
			<HeaderWithChapter title="Мои предложения" chapter="Логистика"/>
			<div className={classnames(styles.wrap_backandnew)}>
				<GoBackLink />						
			</div>
			<div className={classnames(styles.wrap_table)}>
				<div className={classnames(styles.wrap_nav)}>
					<TablesNav
						tableType={tableType}
						setTableType={setTableType}
						btnList={navList}
						width="100%"
					/>			
				</div>
				{(tableType === "myofferspacks")&&(<div className={classnames(styles.wrap_blocks)}>
					<div className={classnames(styles.left_block)}>
						<TableBody tableData={tableData} nullmessage="На данный момент у вас нет предложений"/>		
					</div>
					<div className={classnames(styles.right_block)}>
						<TableManagerActivity
							setModalState={setModalState}
							setTableType={setTableType}
							activity={spreadActivityTable(logisticsManager[tableType].data)}
						/>				
					</div>					
				</div>)}
				{(tableType === "newofferspack")&&(
					<OffersMaker />
				)}

			</div>
			{(tableType === "myofferspacks")&&(<div className={classnames(styles.applications_footer)}>
				<div className={classnames(styles.wrap_pagination)}>
					<TablePagination
			          applyFilters={(filters) =>
			            dispatch(applyFilters(logisticsManager[tableType].filterKey, filters))
			          }	
			          filters={logisticsManager[tableType].filters}
			          countItems={logisticsManager[tableType].count}
					/>
				</div>
			</div>)}
			<ModalConfirms
				modalState={modalState}
				setModalState={setModalState}
				descr={descrModalConfirms}
				dispatchCb={dispatchCb}
			/>					
		</>	
	);
}

	