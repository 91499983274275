import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './ColumnStack.module.scss';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { 
	applyFilters,
	getSortFieldByFilterKey,
	getSortDirectionByFilterKey,
} from '../../../store/filters';

export const ColumnStack = ({filterType, descrType, title, dataArr, linkArr, filterKey, sortField, position}) => {

	const [radioId, setRadioId] = useState(uuid());
	const [checkboxId, setCheckboxId] = useState(uuid());
	const dispatch = useDispatch();
	const stateSortDirection = useSelector(getSortDirectionByFilterKey(filterKey));
	const stateSortField = useSelector(getSortFieldByFilterKey(filterKey));
	const navigate = useNavigate();

	const handlerChange = (event) => {
		let element;
		if (element = event.target.closest("."+classnames(styles.radio))){
			dispatch(applyFilters(filterKey, {sortField: sortField, sortDirection: 1}));
		} else if (element = event.target.closest("."+classnames(styles.checkbox))){
			dispatch(applyFilters(filterKey, {sortDirection: (stateSortDirection<1)?1:-1}));
		}
	}

	return (
		<>		
			<div className={classnames(styles.applications_column_stack)} onChange={handlerChange}>									
				{filterType=="simpleFilter" && (<div className={classnames(styles.applications_column, styles[position])}>
					<input type="radio" id={radioId} className={classnames(styles.radio)} name="filter"
						checked={(stateSortField == sortField)?true:false}
					/> 
					<input type="checkbox" id={checkboxId} className={classnames(styles.checkbox)} 
						checked={(stateSortDirection > 0)?true:false}
					/>
					<label htmlFor={radioId} className={classnames(styles.wrap_label)}></label>
					<label htmlFor={checkboxId} className={classnames(styles.descr_column)}>{title}
						<div className={classnames(styles.wrap_arrows)}>
							<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path className={classnames(styles.col_arr_up)} d="M0.119185 5.49713L4.54791 0.15483C4.73267 -0.0516099 5.05627 -0.0516099 5.24103 0.15483L9.64789 5.49713C9.91464 5.79642 9.70255 6.27046 9.30133 6.27046H0.46465C0.064523 6.27046 -0.148659 5.79642 0.119185 5.49713Z" fill="#BFBFBF"></path>
							</svg>
							<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path className={classnames(styles.col_arr_down)} d="M0.118294 1.54396L4.51392 6.84633C4.69729 7.05122 5.01847 7.05122 5.20185 6.84633L9.57577 1.54396C9.84053 1.24691 9.63002 0.776412 9.2318 0.776412H0.461176C0.0640406 0.776412 -0.147548 1.24691 0.118294 1.54396Z" fill="#BFBFBF"></path>
							</svg>
						</div>
					</label>										
				</div>)}
				{filterType=="refFilter" && (<div className={classnames(styles.applications_column, styles[position])}>
					<div className={classnames(styles.wrap_reference)}>
						<div className={classnames(styles.application_name_col, {
								[styles['application_name_col--ref']]: true
							})}
						>{title}</div>
						<div className={classnames(styles.reference, {
							[styles['reference--show']]: true
							})}
						></div>
					</div>
				</div>)}
				{filterType=="plain" && (<div className={classnames(styles.applications_column, styles[position])}>
					<div className={classnames(styles.application_name_col)}>{title}</div>
				</div>)}
				{(descrType=="link") && dataArr.map((text, index)=>(<div className={classnames(styles.applications_column, styles[position])}>
					<div className={classnames(styles.applications_link_col)} onClick={()=>navigate(linkArr[index])}>{text}</div>
				</div>))}
				{(descrType=="plain") && dataArr.map((text)=>(<div className={classnames(styles.applications_column, styles[position])}>
					<div className={classnames(styles.applications_descr_col)}>{text}</div>
				</div>))}													
			</div>
		</>
	);
}