import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet} from 'react-router-dom';
import { Menu } from '../Menu';
import { AppHeader } from './AppHeader';
import styles from './Layout.module.scss';
import jwtDecode from 'jwt-decode';

import { loadProfileAndVerifCompanies } from '../../store/mergeMyCompanyAndProfile';
import { logout } from '../../utils/logout';
import { getToken } from '../../store/auth';
import { getProfile } from '../../store/profile';
import { getCompany, loadCompanies } from '../../store/companies';


export const Layout = () => {

  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const profile = useSelector(getProfile);
  const firstCompany = useSelector(getCompany)

  useEffect(() => {
    if (token) {
      const { exp, email, role } = jwtDecode(token);
      if (Date.now() > exp * 1000) {
        logout();
      } else if (profile.role !== role || profile.email !== email){
        dispatch(loadProfileAndVerifCompanies);         
      } else if (!firstCompany?._id){  
        dispatch(loadCompanies);
      }
    }
  }, [profile.role, profile.email, firstCompany._id]);

	return (
		<div className={styles.Wrapper}>
			<Menu />
			<div className={styles.Content}>
				<AppHeader />
				<div className={styles.Page}>
					<div className={styles.PageContent}>
						<Outlet/>
					</div>
				</div>
			</div>
		</div>
	);
}