import React, {useRef} from 'react';
import toast from 'react-hot-toast';
import {uploadDocument} from '../../../utils';
import {Button} from './Button';

export const UploadButton = ({
                               children,
                               disabled,
                               onUpload,
                               id = 'upload-file',
                               custom = false,
                               ...restProps
                             }) => {
    const inputRef = useRef(null);

    const handleClick = () => {
      document.getElementById(id).click();
    };

    const handleChange = async (event) => {
      const document = await uploadDocument(event);
      if (document) {
        onUpload(document);
      } else {
        toast.error('Проблема при загрузке файла');
      }
    };

    return (
      <>
        <Button onClick={handleClick} disabled={disabled} {...restProps}>
          {children}
        </Button>
        <input
          type="file"
          multiple
          ref={inputRef}
          id={id}
          style={{display: 'none'}}
          onClick={() => {
            if (inputRef) {
              inputRef.current.value = null;
            }
          }}
          onChange={handleChange}
        />
      </>
    );
  }
;
