import { updateProfile } from '../../api';
import history from '../../components/App/history';
import { getProfileId } from '../profile';
import { getEmailForm, getPhoneForm } from './selectors';

export const SET_EMAIL_FORM = '@notifications/SET_EMAIL_FORM';
export const SET_PHONE_FORM = '@notifications/SET_PHONE_FORM';
export const UPDATE_PHONE_FORM = '@notifications/UPDATE_PHONE_FORM';

export const setEmailNotifications = (payload) => ({
  type: SET_EMAIL_FORM,
  payload,
});

export const updatePhoneForm = (payload) => ({
  type: UPDATE_PHONE_FORM,
  payload,
});

export const setPhoneNotifications = (payload) => ({
  type: SET_PHONE_FORM,
  payload,
});

export const saveEmailForm = (toast, navigate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const emailForm = getEmailForm(state);

    // TODO check response
    await updateProfile(
      {
        key: 'emailNotifications',
        value: emailForm,
      },
      getProfileId(state)
    );    
    navigate(-1);
    toast.success("Настройки сохранены");
  } catch (error) {
    // TODO
    console.log(error);
    toast.error("Не удалось сохранить, попробуйте позже");
  }
};

export const savePhoneForm = (navigate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const phoneForm = getPhoneForm(state);

    // TODO check response
    await updateProfile({
      key: 'phoneNotifications',
      value: phoneForm,
    });
    navigate(-1);
    //history.goBack();
  } catch (error) {
    // TODO
    console.log(error);
  }
};
