import React from 'react';
import classnames from 'classnames';

import styles from './Title.module.scss';

export const Title = ({ children, as, className, style, thin }) => {
  const Tag = as || 'h1';
  return (
    <Tag
      style={style}
      className={classnames(styles.Title, className, {
        [styles[`Title--${as}`]]: as,
        [styles['Title--thin']]: thin,
      })}
    >
      {children}
    </Tag>
  );
};
