import { costDecore } from './costDecore';

export const spreadSeaCostTable = (arr) => {
	if (typeof arr === "object" && arr.length){
		return arr.map(({typetransport, seaCost})=>{

			if (typetransport){// sea
				if (Number(seaCost) >= 0){
					return costDecore(seaCost, "$");
				} else return "Нет данных";
			} else return "────────────";			

		});
	}
	return [];
}

