import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfoCircle from '../../assets/InfoCircle.svg';

import { CODES_PER_PAGE, PAGE_PRICE } from '../../consts';
import { Title } from '../shared/Title';
import { Tooltip } from '../shared/Tooltip';
import {
  getDiscount,
  loadNewRequestDiscount,
  getSelectedTariff,
  getNewRequest, updateNewRequest,
} from '../../store/requests';
import { getTariffHash } from '../../store/tariffs';
import { getProfile } from '../../store/profile';

import styles from './Legend.module.scss';

export const Legend = () => {
  const dispatch = useDispatch();
  const discount = useSelector(getDiscount);
  const profile = useSelector(getProfile);
  const selectedTariff = useSelector(getSelectedTariff);
  const { deliveryInfo } = useSelector(getNewRequest);
  const tariffs = useSelector(getTariffHash);

  useEffect(() => {
    if (profile._id) {
      dispatch(loadNewRequestDiscount());
    }
  }, [dispatch, profile._id]);

  const { price } = selectedTariff || { price: 0 };

  const {tariffPrice} = useMemo(() => {
    let tariffPrice = 0;
    if (deliveryInfo?.speedUp) {
      tariffPrice += tariffs?.fasterCustom?.price;
    }

    if (deliveryInfo?.withSupport) {
      tariffPrice += tariffs?.supportedCustom?.price;
    }

    if (deliveryInfo.withGoodsLookup) {
      tariffPrice += tariffs.goodsLookup?.price || 0;
    }
    if (deliveryInfo.withPermissionDocs) {
      tariffPrice += tariffs.permissionDocs?.price || 0;
    }
    if (deliveryInfo.withPresense) {
      tariffPrice += tariffs.presense?.price || 0;
    }
    if (deliveryInfo.withLoadingControl) {
      tariffPrice += tariffs.loadingControl?.price || 0;
    }
    if (deliveryInfo.withFitoSanControl) {
      tariffPrice += tariffs.fitoSanControl?.price || 0;
    }
    if (deliveryInfo.withDocsTransfer) {
      tariffPrice += tariffs.docsTransfer?.price || 0;
    }

    return {tariffPrice};
  }, [deliveryInfo, tariffs, price]);

  const finalPrice = useMemo(() => {
    let finalPrice = price;

    if (discount > 0) {
      finalPrice = Math.round(price * (1 - discount / 100));
    }

    finalPrice += tariffPrice;

    return finalPrice;
  }, [discount, price, deliveryInfo, tariffs, tariffPrice]); 

  useEffect(() => {
    // const chooseTariff = (value) =>
    //   dispatch(updateNewRequest({ key: 'tariff', value }));
    console.log("Update final price", finalPrice);
    dispatch(updateNewRequest({ key: 'price', value: finalPrice }));
  }, [dispatch, finalPrice]);

  return (
    <div className={styles.Legend}>
      <Title fitted>Заявка</Title>
      <div className={styles.CostArea}>
        <div className={styles.Legend__Title}>
          Стоимость заявки
          <Tooltip iconSrc={InfoCircle}>
            <div className={styles.InfoMessage}>
              <p>
                Стоимость дополнительных листов будет рассчитана при закрытии
                заявки.
              </p>
              <p>
                Стоимость одного дополнительного листа - {PAGE_PRICE} рублей.
              </p>
              <p>
                Количество листов зависит от количества кодов В первом листе
                декларации - 1 код В последующих - {CODES_PER_PAGE} кода.
              </p>
            </div>
          </Tooltip>
        </div>
        <div className={styles.Cost}>
          <div className={styles.Cost__Value}>
            <span className={styles.Cost__Number}>
              {finalPrice}
              ₽
            </span>
            {discount > 0 && (
              <span className={styles.PreviousPrice}>{price}₽</span>
            )}
          </div>
          <div className={styles.Cost__Text}>+ стоимость доп. листов ({PAGE_PRICE} ₽/шт.)</div>
        </div>
      </div>
    </div>
  );
};
