import React from 'react';

import styles from './HeaderWithCount.module.scss';

export const HeaderWithCount = ({ children, count, itemsName, title }) => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <div className={styles.TitlePart}>
          <div className={styles.TitlePart__Title}>{title}</div>
          <div>
            Количество {itemsName} &ndash; {count}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
