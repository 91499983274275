import React, { useState } from 'react';
import { format } from 'date-fns';

import { BANK_LOGOS } from '../../configs/bankLogos';
import { ConfirmModal } from '../shared/ConfirmModal';

import styles from './AccountCard.module.scss';

export const AccountCard = ({ account, onClick, fnDelete }) => {
  const [open, setOpen] = useState(false);
  const [openDelAccountConfirm, setOpenDelAccountConfirm] = useState(false);
  const logo = BANK_LOGOS[account.bik];

  return (
    <div className={styles.Card} onClick={() => {}}>
      <div
        className={styles.Card__Header}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
      >
        <div className={styles.Card__Title}>
          <>
            {logo ? (
              <img className={styles.Logo} src={logo} alt={account.name} />
            ) : (
              <h3>{account.name}</h3>
            )}
            {account.accountNumber}
          </>
        </div>
        <div className={styles.Card__DeleteLink}>
          {open ? 'Свернуть' : 'Развернуть'}
        </div>
      </div>
      {open && (
        <div className={styles.Card__Body}>
          <div className={styles.Card__Line}>
            Расчетный счет:{' '}
            <span className={styles.Card__Details}>
              {account.accountNumber}
            </span>
          </div>
          <div className={styles.Card__Line}>
            Бик банка:{' '}
            <span className={styles.Card__Details}>{`0${account.bik}`}</span>
          </div>
          <div className={styles.Card__Line}>
            Наименование банка:{' '}
            <span className={styles.Card__Details}>{account.name}</span>
          </div>
          <div className={styles.Card__Line}>
            Корреспондентский счет:{' '}
            <span className={styles.Card__Details}>
              {account.correspondenceAccount}
            </span>
          </div>
          <div className={styles.Card__Line}>
            ИНН: <span className={styles.Card__Details}>{account.itn}</span>
          </div>
          <div className={styles.Card__Line}>
            КПП: <span className={styles.Card__Details}>{account.kpp}</span>
          </div>
          <div className={styles.Card__BottomLine}>
            <div className={styles.Card__Date}>
              Добавлен {format(new Date(account.createdAt), 'dd.MM.yyyy')}
            </div>
            <div
              className={styles.Card__DeleteAccount}
              onClick={() => setOpenDelAccountConfirm(true)}
            >
              Удалить
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        message={`Вы уверены, что следует удалить банковский счет ${account.accountNumber}?`}
        open={openDelAccountConfirm}
        onConfirm={() => {
          setOpenDelAccountConfirm(false);
          fnDelete(account._id);
        }}
        onReject={() => setOpenDelAccountConfirm(false)}
      />
    </div>
  );
};
