export const customsList = [
		{
			title:"В пункте прибытия",
			datatype:"vtt"
		},
		{
			title:"В порту прибытия",
			datatype:"gtd"
		}		
];

export const departureCountryList = [
		{
			title:"Китай",
			datatype:"china"
		},	
		{
			title:"Вьетнам",
			datatype:"vietnam"
		},
		{
			title:"Индия",
			datatype:"india"
		},
		{
			title:"Корея",
			datatype:"korea"
		},
		{
			title:"Тайланд",
			datatype:"thailand"
		}							
	];

export const departureTrainCountryList = [
		{
			title:"Китай",
			datatype:"china"
		}							
	];	
export const arrivalCountryList = [
		{
			title:"Россия",
			datatype:"russia"
		}
	];	
export const departureCityPortList = {
		china:[
			{
				title:"Шанхай",
				datatype:"shanghai"
			},	
			{
				title:"Нингбо",
				datatype:"ningbo"
			},
			{
				title:"Циндао",
				datatype:"qingdao"
			},
			{
				title:"Сямынь",
				datatype:"xiamen"
			},									
			{
				title:"Яньтянь",
				datatype:"yantian"
			},
			{
				title:"Далянь",
				datatype:"dalian"
			},
			{
				title:"Синганг",
				datatype:"xingang"
			},
			{
				title:"Тяньцзинь",
				datatype:"tianjin"
			},
			{
				title:"Шантоу",
				datatype:"shantou"
			},
			{
				title:"Шэкоу",
				datatype:"shekou"
			},
			{
				title:"Тайцанг",
				datatype:"taicang"
			},
			{
				title:"Жапу",
				datatype:"zhapu"
			},
			{
				title:"Нанша",
				datatype:"nansha"
			},
			{
				title:"Рижао",
				datatype:"rizhao"
			},
			{
				title:"Ляньюньган",
				datatype:"lianyungang"
			}
		],
		vietnam:[
			{
				title:"Хайфон",
				datatype:"haiphong"
			},
			{
				title:"Хошимин",
				datatype:"hochiminh"
			}
		],
		india:[
			{
				title:"Мундра",
				datatype:"mundra"
			},
			{
				title:"Нава Шева",
				datatype:"nhavasheva"
			}
		],
		korea:[
			{
				title:"Пусан",
				datatype:"busan"
			}
		],
		thailand:[
			{
				title:"Бангкок",
				datatype:"bangkok"
			},
			{
				title:"Лаем Чабанг",
				datatype:"laemchabang"
			}
		]
	};
export const departureStationList = {
		china:[
			{
				title:"Шенжень",
				datatype:"shenzhen"
			},
			{
				title:"Гуанчжоу",
				datatype:"guangzhou"
			},
			{
				title:"Фучжоу",
				datatype:"train"
			},
			{
				title:"Шанхай",
				datatype:"shanghai"
			},	
			{
				title:"Нингбо",
				datatype:"ningbo"
			},
			{
				title:"Циндао",
				datatype:"qingdao"
			},
			{
				title:"Сямынь",
				datatype:"xiamen"
			},
			{
				title:"Тяньцзинь",
				datatype:"tianjin"
			}
		]
	};
	
export const arrivalFromPortStationList = {
		russia:[
			{
				title:"Санкт-Петербург",
				datatype:"saintpetersburg"
			},
			{
				title:"Москва",
				datatype:"moscow"
			},
			{
				title:"Екатеринбург",
				datatype:"ekaterinburg"
			},
			{
				title:"Новосибирск",
				datatype:"novosibirsk"
			},
		]
	};

export const arrivalClientFromPortStationList = {
		russia:[
			{
				title:"Владивосток",
				datatype:"vladivostok"
			},
			{
				title:"Находка",
				datatype:"nahodka"
			},
			{
				title:"Новороссийск",
				datatype:"novorossiysk"
			},
			{
				title:"Санкт-Петербург",
				datatype:"saintpetersburg"
			},
			{
				title:"Москва",
				datatype:"moscow"
			},
			{
				title:"Екатеринбург",
				datatype:"ekaterinburg"
			},
			{
				title:"Новосибирск",
				datatype:"novosibirsk"
			},
		]
	};

export const arrivalStraightStationList = {
		russia:[
				{
					title:"Санкт-Петербург",
					datatype:"saintpetersburg"
				},
				{
					title:"Москва",
					datatype:"moscow"
				}
			]
	};
export const arrivalCityPortList = {
		russia:[
			{
				title:"Владивосток",
				datatype:"vladivostok"
			},
			{
				title:"Находка",
				datatype:"nahodka"
			},	
			{
				title:"Санкт-Петербург",
				datatype:"saintpetersburg"
			},
			{
				title:"Новороссийск",
				datatype:"novorossiysk"
			}
		]
	};
export const arrivalPortTerminalList = {
		vladivostok:[
			{
				title:"ВМТП",
				datatype:"vmtp"
			},
			{
				title:"ВМКТ(FISH PORT)",
				datatype:"vmkt"
			},
			{
				title:"СОЛЛЕРС",
				datatype:"sollers"
			},
			{
				title:"ВМПП",
				datatype:"vmpp"
			},
			{
				title:"ПЕРВОМАЙСКИЙ",
				datatype:"perv"
			},
																	
		],
		nahodka:[
			{
				title:"ВСК",
				datatype:"vsk"
			},
			{
				title:"ППК-1",
				datatype:"ppk1"
			}			
		],
		saintpetersburg:[
			{
				title:"ПКТ",
				datatype:"pkt"
			},
			{
				title:"БРОНКА",
				datatype:"bronka"
			}					
		],
		novorossiysk:[
			{
				title:"НУТЭП",
				datatype:"nutep"
			}		
		]		
	};	
export const subsetPortTerminalList = [
		{
			title:"ВМТП",
			datatype:"vladivostok"
		},	
		{
			title:"ВМКТ(FISH PORT)",
			datatype:"vladivostok"
		},
		{
			title:"ВСК",
			datatype:"nahodka"
		},
		{
			title:"ППК-1",
			datatype:"nahodka"
		},
		{
			title:"СОЛЛЕРС",
			datatype:"vladivostok"
		},
		{
			title:"ВМПП",
			datatype:"vladivostok"
		},
		{
			title:"ПЕРВОМАЙСКИЙ",
			datatype:"vladivostok"
		},
		{
			title:"ПКТ",
			datatype:"saintpetersburg"
		},
		{
			title:"БРОНКА",
			datatype:"saintpetersburg"
		},
		{
			title:"НУТЭП",
			datatype:"novorossiysk"
		},									
	];
export const cityTerminal = {
	vladivostok: "Владивосток",
	nahodka: "Находка",
	saintpetersburg: "Санкт-Петербург",
	novorossiysk: "Новороссийск"
};


export const INIT_STATE = {	
	loading: false,
	panelState: "sea", // train
	sea: {
		name:{
			value: false,
			correct: true
		},
		expireDate:{
			value: false,
			correct: true
		},
		terminalArrival: {title:"ВМТП", datatype:"vladivostok"},
		cityArrival: "Владивосток",
		customs: {
			vtt: false,
			gtd: false,
			correct: true
		},
		subPanelState: "seaDeparture",// trainArrival
		seaDeparture: {
			countryDeparture:{title:"Китай", datatype:"china"}, 
			portDeparture: departureCityPortList,
		},
		trainArrival: {
			countryArrival:{title:"Россия", datatype:"russia"}, 
			stationArrival: arrivalFromPortStationList,
		},
		seaView: [],
		trainView: [],
		conditionscarriage:{
			value: false,
			correct: true
		},
		paymentterms:{
			value: false,
			correct: true
		}		
	},
	train: {
		name:{
			value: false,
			correct: true
		},
		expireDate:{
			value: false,
			correct: true
		},
		cityArrival: {title:"Санкт-Петербург", datatype: "saintpetersburg"},
		countryDeparture: {title:"Китай", datatype:"china"},
		stationDeparture: departureStationList,
		trainView: [],
		conditionscarriage:{
			value: false,
			correct: true
		},
		paymentterms:{
			value: false,
			correct: true
		}		
	}
};

export const initParams = {
	params: {
		twenty: {
			value: false,
			correct: true
		},
		forty: {
			value: false,
			correct: true
		},
		fortyhc: {
			value: false,
			correct: true
		},
		duration: {
			value: false,
			correct: true
		}
	}
}
