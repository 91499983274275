import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import toast from 'react-hot-toast';

import {
  loadUsers,
  getUsersCount,
  getLoading,
  getUsers,
  applyFilters,
  updateUserById,
} from '../../store/users';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { getClientsUsersPeopleFilters } from '../../store/filters';
import { FILTERS, ROUTES } from '../../consts';
import { Checkbox } from '../shared/Checkbox';

export const ClientsUsersPeople = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(getLoading);
  const users = useSelector(getUsers);
  const filters = useSelector(getClientsUsersPeopleFilters);
  const countItems = useSelector(getUsersCount);
  const filtersKey = FILTERS.CLIENTS_USERS_PEOPLE;

  const CLIENTS_USERS_PEOPLE_HEADERS = [
    {
      label: 'ID',
      value: '_id',
      render: (value) => (
        <span
          style={{ color: '#1890ff', cursor: 'pointer' }}
          onClick={() => {
            navigate(`../${ROUTES.USERS_CLIENTS}/${value}`);
          }}
        >
          {value}
        </span>
      ),
    },
    {
      label: 'Имя Фамилия',
      value: 'name',
      renderItem: true,
      render: (item) =>
        item.name === undefined && item.surname === undefined
          ? ''
          : `${item.name} ${item.surname}`,
    },
    {
      label: 'Контакты',
      value: 'contacts',
      renderItem: true,
      render: (item) => (
        <div>
          <div>{item.phone}</div>
          <div>{item.email}</div>
        </div>
      ),
    },
    {
      label: 'Дата регистрации',
      value: 'createdAt',
      sortable: true,
      render: (value) =>
        value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : 'нет данных',
    },
    {
      label: 'Партнер клиента',
      value: 'partner',
      render: (partner) =>
        partner ? (
          <div
            style={{ color: '#1890ff', cursor: 'pointer' }}
            onClick={() => {
              navigate(`../${ROUTES.USERS_PARTNERS}/${partner._id}`);
            }}
          >
            {partner.name} {partner.surname}
          </div>
        ) : (
          '-'
        ),
    },
    {
      label: '>2 компаний',
      value: 'unlim',
      renderItem: true,
      render: ({ unlim, _id }) => {        
        return (
          <Checkbox
            style={{ display: 'flex', justifyContent: 'center' }}
            checked={unlim}
            onClick={() => {
              dispatch(updateUserById({ unlim: !unlim }, _id));
              toast.success('Изменения сохранены');
            }}
          />
        );
      },
    },
    {
      label: '',
      value: 'rowActions',
      renderItem: true,
      render: (item) => (
        <div
          style={{ color: '#1890ff', cursor: 'pointer' }}
          onClick={() => navigate(`../${ROUTES.USERS_CLIENTS}/${item._id}`)}
        >
          Открыть
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(loadUsers(filters));
  }, [dispatch, filters]);

  return (
    <div>
      {users?.length ? (
        <Table
          loading={loading}
          items={users}
          headers={CLIENTS_USERS_PEOPLE_HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) =>
            dispatch(applyFilters(filtersKey, filters))
          }
        />
      ) : (
        <Message type="info">
          В системе пока нет сведений о пользователях клиентах
        </Message>
      )}
    </div>
  );
};
