import { RANDOM_KEY_MIN, RANDOM_KEY_MAX } from '../consts';

export function getUniqueInt(
  exceptions,
  min = RANDOM_KEY_MIN,
  max = RANDOM_KEY_MAX
) {
  min = Math.ceil(min);
  max = Math.floor(max);

  const candidate = Math.floor(Math.random() * (max - min)) + min;

  if (exceptions && exceptions.includes(candidate)) {
    return getUniqueInt(exceptions, min, max);
  }

  return candidate;
}
