import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import { HandySvg } from 'handy-svg';
import Eye from '../../../assets/Eye.svg';
import EyeClosed from '../../../assets/EyeClosed.svg';
import Exclamation from '../../../assets/Exclamation.svg';
import { Tooltip } from '../Tooltip';

import styles from './Input.module.scss';

export function Input({
  autoComplete,
  autoFocus,
  className,
  disabled,
  error,
  grey,
  lightgrey,
  maxLength,
  min,
  name,
  onChange,
  onClick,
  onEnter,
  outRef,
  placeholder,
  readOnly,
  tooltip,
  type = 'text',
  value,
  phoneMask,
}){
  const [showPass, setShowPass] = useState(false);  
  const handleKeyPress = onEnter
    ? (event) => {
        if (event.charCode === 13) {
          onEnter();
        }
      }
    : undefined;

  const classNames = classnames(styles.Input, {
    [styles['Input--disabled']]: disabled,
    [styles['Input--lightgrey']]: lightgrey,
    [styles['Input--grey']]: grey,
    [styles['Input--error']]: error,
  });

  const commonProps = {
    className: classNames,
    value,
    name,
    onClick,
    onChange,
    readOnly,
    type: showPass ? 'input' : type,
    placeholder,
    maxLength,
    ref: outRef,
    min,
    autoFocus,
    autoComplete: !autoComplete && 'new-password',
    onKeyPress: handleKeyPress,
    disabled,
  };

  const InputTag = phoneMask ? InputMask : 'input';
  const props = phoneMask
    ? { ...commonProps, mask: '+7 999 999 99 99', maskChar: ' ' }
    : commonProps;

  function toggleShowPass(event){    
    
    setShowPass((prevVal) => !prevVal);
  } 

  return (
    <div className={classnames(styles.Wrapper, className)}>
      <InputTag {...props} />
      {type === 'password' && (
        <div className={styles.IconWrapper} onClick={toggleShowPass}>
          <img src={showPass ? Eye : EyeClosed} alt="eye" />        
        </div>
      )}
      {tooltip && (
        <div className={styles.IconWrapper}>
          <Tooltip iconSrc={Exclamation}>
            <p>{tooltip}</p>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
