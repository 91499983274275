import React from 'react';
import { useSelector } from 'react-redux';

import Link from '../../assets/Link.svg';
import { ROUTES } from '../../consts';
import { getCompany } from '../../store/companies';
import { Tabs } from '../shared/Tabs';
import { Title } from '../shared/Title';

import styles from './EsignView.module.scss';

const companyTabs = (
  <Tabs
    tabs={[
      {
        title: 'Общие сведения о компании',
        route: ROUTES.USERS_CLIENT_COMPANY_INFO,
        href: './info',
        visible: true,
      },
      {
        title: 'Документы компании',
        route: ROUTES.USERS_CLIENT_COMPANY_DOCS,
        href: './docs',
        visible: true,
      },
      // {
      //   title: 'Электронно-цифровая подпись',
      //   route: ROUTES.USERS_CLIENT_COMPANY_ESIGN,
      //   href: './esign',
      //   visible: true,
      // },
    ]}
  />
);

export const EsignView = () => {
  const company = useSelector(getCompany);
  const { esign } = company;

  const noLink = (
    <span style={{ fontSize: '0.8rem' }}>Файл ЭЦП не загружен</span>
  );

  const hasLink = (
    <div className={styles.Ready}>
      <div className={styles.LinkArea}>
        <div className={styles.LinkArea__Img}>
          <img src={Link} alt="link" />
        </div>
        <a href={esign} target="_blank" rel="noopener noreferrer">
          загруженный файл.doc
        </a>
      </div>
    </div>
  );

  return (
    <div className={styles.Wrapper}>
      {companyTabs}
      <div className={styles.Esign}>
        <div className={styles.Esign__Title}>
          <Title as="h3">Электронно цифровая подпись</Title>
        </div>
        <div className={styles.Esign__Content}>{esign ? hasLink : noLink}</div>
      </div>
    </div>
  );
};
