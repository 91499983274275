import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from '../consts';
import { withLoading } from '../components/hocs/withLoading';
import { AccountsDeclarants } from '../components/Accounts/AccountsDeclarants';
import { AccountsClients } from '../components/Accounts/AccountsClients';
import { AccountsUserHistory } from '../components/Accounts/AccountsUserHistory';
import { AccountsHeader } from '../components/Accounts/AccountsHeader';
import { AccountsPartners } from '../components/Accounts/AccountsPartners';
import { AccountsLogists } from '../components/Accounts/AccountsLogists';



export const Accounts = withLoading(() => {
  return (
    <main>
      <Routes>
        <Route
          path={ROUTES.ACCOUNTS_CLIENTS}
          Component={() => (
            <>
              <AccountsHeader />
              <AccountsClients />
            </>
          )}          
        />      
        <Route
          path={ROUTES.ACCOUNTS_DECLARANTS}
          Component={() => (
            <>
              <AccountsHeader />
              <AccountsDeclarants />
            </>
          )}          
        />
        <Route
          path={ROUTES.ACCOUNTS_LOGISTS}
          Component={() => (
            <>
              <AccountsHeader />
              <AccountsLogists />
            </>
          )}          
        />        
        <Route
          path={ROUTES.ACCOUNTS_PARTNERS}
          Component={() => (
            <>
              <AccountsHeader />
              <AccountsPartners />
            </>
          )}          
        />
        <Route
          path={ROUTES.ACCOUNTS_USER_HISTORY}
          Component={AccountsUserHistory}          
        />
        <Route path="*" element={<Navigate to={ROUTES.FULL_ACCOUNTS_DECLARANTS} />} />
        
      </Routes>
    </main>
  );
});