import { getProfileId } from '../profile';
import { getNewAccount } from '../personalAccounts';
import {
  createPersonalAccount,
  getBankData,
  getPersonalAccounts,
  archivePersonalAccount,
} from '../../api';

export const LOAD_ITEMS_REQUEST = '@personalAccounts/LOAD_ITEMS_REQUEST';
export const LOAD_ITEMS_SUCCESS = '@personalAccounts/LOAD_ITEMS_SUCCESS';
export const LOAD_ITEMS_FAILURE = '@personalAccounts/LOAD_ITEMS_FAILURE';
export const UPDATE_VALUE = '@personalAccounts/UPDATE_VALUE';
export const UPDATE_ITEM = '@personalAccounts/UPDATE_ITEM';

export const loadItemsRequest = () => ({
  type: LOAD_ITEMS_REQUEST,
});

export const loadItemsSuccess = (payload) => ({
  type: LOAD_ITEMS_SUCCESS,
  payload,
});

export const loadItemsFailure = (payload) => ({
  type: LOAD_ITEMS_FAILURE,
  payload,
});

export const updateValue = (payload) => ({
  type: UPDATE_VALUE,
  payload,
});

export const updateItem = (payload) => ({
  type: UPDATE_ITEM,
  payload,
});

export const loadPersonalAccounts = () => async (dispatch, getState) => {
  dispatch(loadItemsRequest());
  try {
    const { personalAccounts, count } = await getPersonalAccounts();

    dispatch(loadItemsSuccess({personalAccounts, count}));
  } catch (error) {
    console.log(error);
  }
};

export const savePersonalAccount = (toast) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const newAccount = getNewAccount(state);
    const profileId = getProfileId(state);

    await createPersonalAccount({
      ...newAccount,
      profileId,
    });
    dispatch(loadPersonalAccounts());

    toast.sucess('Реквизиты сохранены');
  } catch (error) {
    console.log(error);
  }
};

export const deletePersonalAccount = (id, toast) => async (dispatch) => {
  try {
    await archivePersonalAccount(id);
    dispatch(loadPersonalAccounts());

    toast.success('Реквизиты удалены');
  } catch (error) {
    console.log(error);
  }
};

export const getBank = (query) => async (dispatch, getState) => {
  try {
    const { bank } = await getBankData({ query });
    if (bank) {
      const state = getState();
      const newAccount = getNewAccount(state);
      dispatch(
        updateItem({
          key: 'newAccount',
          value: {
            ...newAccount,
            bankName: bank?.value,
            correspondenceAccount: bank?.data?.correspondent_account,
          },
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};
