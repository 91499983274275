import { PASSPORT_FIELDS, PASSPORT_FILES } from '../../configs/passport';
import { DOCUMENTS_STEPS } from '../../consts';
import {
  TOGGLE_OPEN,
  UPDATE_VALUE,
  UPDATE_PASSPORT,
  SET_LOADING,
} from './actions';

const initialState = {
  step: DOCUMENTS_STEPS.PASSED_REVIEW,
  open: false,
  errors: {},
  loading: false,
};

Object.keys(PASSPORT_FIELDS).forEach((field) => {
  initialState[field] = PASSPORT_FIELDS[field].initialValue;
});

Object.keys(PASSPORT_FILES).forEach((file) => {
  initialState[file] = '';
});

export const passportReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_OPEN:
      const prevOpen = state.open;
      const newState = { ...state, open: !prevOpen };

      return newState;
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case UPDATE_PASSPORT:
      const errors = action.payload.errors || {};
      return { ...state, ...action.payload, errors };
    case UPDATE_VALUE:
      const { key, value } = action.payload;
      return {
        ...state,
        errors: { ...state.errors, [key]: undefined },
        [key]: value,
      };
    default:
      return state;
  }
};
