import React from 'react';
import { format } from 'date-fns';

import styles from './History.module.scss';

const EVENTS_LABELS = {
  ASSIGN: 'Заявка взята в работу',
  CHECKS: 'Сохранено платежное поручение',
  CUSTOMS_PAYMENTS_CALCULATED: 'Таможенные платежи рассчитаны',
  DOC_QUERY_CREATED: 'Создан запрос документов',
  DOC_QUERY_FULFILLED: 'Закрыт запрос документов',
  DECLARATION_ADDED: 'Декларация загружена',
  PAYMENT_DONE: 'Таможенные платежи оплачены',
  UPDATE: 'Заявка обновлена',
  DONE: 'Заявка закрыта',
};

export const History = ({ history, createdAt }) => {
  const reversedHistory = [...history].reverse();

  const dateCreatedAt = new Date(createdAt);

  return (
    <div className={styles.History}>
      {reversedHistory.map((event) => {
        const eventDate = new Date(event.date);

        return (
          <div className={styles.Record} key={event._id}>
            <div className={styles.Record__Action}>
              {EVENTS_LABELS[event.type]}
            </div>
            <div className={styles.Record__Date}>
              <span>{format(eventDate, 'yyyy-MM-dd')}</span>
              <span className={styles.Date__Time}>
                {format(eventDate, 'HH:mm:ss')}
              </span>
            </div>
          </div>
        );
      })}
      <div className={styles.Record} key="creation">
        <div className={styles.Record__Action}>Заявка создана</div>
        <div className={styles.Record__Date}>
          <span>{format(dateCreatedAt, 'yyyy-MM-dd')}</span>
          <span className={styles.Date__Time}>
            {format(dateCreatedAt, 'HH:mm:ss')}
          </span>
        </div>
      </div>
    </div>
  );
};
