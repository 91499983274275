import React, { useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { ROUTES } from '../../../consts';
import { loadRequest, getRequest } from '../../../store/requests';
import { Title } from '../../shared/Title';
import { GoBackLink } from '../../shared/GoBackLink';
import { Tabs } from '../../shared/Tabs';
import { LoadingPage } from '../../hocs/withLoading/LoadingPage';
import { DetailInfo } from './DetailInfo';
import { DetailProgress } from './DetailProgress';
import { STATUS_OPTIONS } from '../../../configs/request';

import styles from './RequestDeclarantDetail.module.scss';
import { getLoading } from '../../../store/requests';

export const RequestDeclarantDetail = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const item = useSelector(getRequest);
  const { id } = useParams();

  useEffect(() => {
    id && dispatch(loadRequest(id));
  }, [id, dispatch]);

  return (
    <div>
      <Title>Заявки</Title>
      <GoBackLink />
      {loading ? (
        <LoadingPage withWrapper />
      ) : (
        <>
          <div className={styles.Header}>
            <div className={styles.Header__Column}>
              <div className={styles.Title}>
                <div>Заявка № {item?.number || item._id}</div>
                {item.createdAt && (
                  <div className={styles.Desc}>
                    Создана{' '}
                    {format(new Date(item.createdAt), 'dd.MM.yyyy HH:mm')}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.Header__Column}>
              <div className={styles.Desc}>Статус заявки</div>
              <div className={styles.SubTitle}>
                {STATUS_OPTIONS[item?.status]?.label}
              </div>
            </div>
          </div>

          <Tabs
            tabs={[
              {
                title: 'Информация по заявке',
                route: ROUTES.FULL_REQUESTS_DECLARATIONS_DETAIL_INFO,
                visible: true,
                href: './info',
              },
              {
                title: 'Работа по заявке',
                route: ROUTES.FULL_REQUESTS_DECLARATIONS_DETAIL_PROGRESS,
                visible: true,
                href: './progress',
              },
            ]}
          />
          <Routes>
            <Route
              path="info"
              Component={DetailInfo}
            />
            <Route
              path="progress"
              Component={DetailProgress}
            />
          </Routes>
        </>
      )}
    </div>
  );
};
