import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { ROUTES } from '../../consts';
import {
  getCommonInfo,
  getBank,
  getCompany,
  getBankInfo,
  getExtraInfo,
  setBankInfo,
  setCommonInfo,
  setExtraInfo,
  loadBankAccounts,
  loadCompany,
  updateItem,
  updateContactInfo,
  saveBankAccount,
  deleteBankAccount,
  updateExtraInfo,
} from '../../store/companies';
import { AccountCard } from './AccountCard';
import { Tabs } from '../shared/Tabs';
import { Accordion } from '../shared/Accordion';
import { Input } from '../shared/Input';
import { Checkbox } from '../shared/Checkbox';
import { Title } from '../shared/Title';
import { Button } from '../shared/Button';
import { Panel } from '../shared/Panel';
import { getRole } from '../../store/profile';
import styles from './Info.module.scss';

export const Info = () => {
  const dispatch = useDispatch();
  const [addBank, setAddBank] = useState(false);  
  const commonInfo = useSelector(getCommonInfo);
  const extraInfo = useSelector(getExtraInfo);
  const bankInfo = useSelector(getBankInfo);
  const company = useSelector(getCompany);
  const { id } = useParams();
  const role = useSelector(getRole);



  const handleCommonInfoToggle = () =>
    dispatch(setCommonInfo({ open: !commonInfo.open }));

  const handleExtraInfoToggle = () =>
    dispatch(setExtraInfo({ open: !extraInfo.open }));

  const handleBankInfoToggle = () =>
    dispatch(setBankInfo({ open: !bankInfo.open }));

  const handleCheckCommonAddress = () => {
    if (!company.commonAddress) {
      dispatch(
        updateItem({ key: 'actualAddress', value: { ...company.legalAddress } })
      );
    }
    dispatch(
      updateItem({
        key: 'commonAddress',
        value: !company.commonAddress,
      })
    );
  };

  const handleChange = (key, subKey) => (e) => {
    if (company.commonAddress) {
      dispatch(
        updateItem({
          key: 'commonAddress',
          value: false,
        })
      );
    }
    dispatch(updateItem({ key, subKey, value: e.target.value }));
  };

  const saveContactInfo = () => dispatch(updateContactInfo(toast));
  const saveActualAddressInfo = () => dispatch(updateExtraInfo(toast));

  const handleBikUpdate = (e) => {
    const { value } = e.target;
    dispatch(
      updateItem({ key: 'bank', value: { ...company.bank, bik: value } })
    );
    handleBankGuess(value);
  };

  const handleSaveBankAccount = () => {
    dispatch(saveBankAccount(toast));
    setAddBank(false);
  };

  const addHideBank = () => {
    setAddBank((prev) => !prev);
    if (!addBank) {
      dispatch(
        updateItem({
          key: 'bank',
          value: {
            ...company.bank,
            bik: '',
            accountNumber: '',
            name: '',
            correspondenceAccount: '',
            itn: '',
            kpp: '',
            mainAccount: false,
          },
        })
      );
    }
  };

  const handleDeleteBankAccount = (id) => {
    dispatch(deleteBankAccount(id, toast));
  };

  const handleBankGuess = debounce((query) => dispatch(getBank(query)), 2000);

  useEffect(() => {
    dispatch(loadCompany(id));
    dispatch(loadBankAccounts(id));
  }, [dispatch, id]);

  return (
    <div className={styles.Info}>
      <Tabs
        tabs={[
          {
            title: 'Общие сведения о компании',
            route: ROUTES.FULL_PROFILE_COMPANY_INFO,
            href: `../${id}/info`,
            visible: true,
          },
          {
            title: 'Документы компании',
            route: ROUTES.FULL_PROFILE_COMPANY_DOCS,
            href: `../${id}/docs`,
            visible: true,
          },
          {
            title: 'О компании',
            route: ROUTES.FULL_PROFILE_COMPANY_OFFER,
            href: `../${id}/offer`,
            visible: (role === "logist")?true:false,
          },      
          // {
          //   title: 'Электронно-цифровая подпись',
          //   route: ROUTES.COMPANY_ESIGN,
          //   href: './esign',
          //   visible: true,
          // },
        ]}
      />
      {!company.verified && (
        <div className={styles.PanelWrapper}>
          <Panel
            title="Для того чтобы оставить заявку в системе от лица этой компании, требуется пройти верификацию"
            fluid
          >
            Для прохождения верификации, пополните счет на любую сумму от лица
            компании, которую хотите верифицировать
          </Panel>
        </div>
      )}
      <Accordion
        open={commonInfo.open}
        title="Общие сведения о компании"
        onToggle={handleCommonInfoToggle}
      >
        <div className={styles.AccordionDouble}>
          <div className={styles.AccordionHalf}>
            <div className={styles.Label}>ИНН</div>
            <div className={styles.InputWrapper}>
              <Input disabled placeholder="ИНН" value={company.itn} />
            </div>
            <div className={styles.Label}>Наименование компании</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Наименование компании"
                value={company.name}
              />
            </div>
            <div className={styles.Label}>Форма управления</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Форма управления"
                value={company.form}
              />
            </div>
            <div className={styles.Label}>Тип организации</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Тип организации"
                value={company.type}
              />
            </div>
            <div className={styles.Label}>КПП</div>
            <div className={styles.InputWrapper}>
              <Input disabled placeholder="КПП" value={company.kpp} />
            </div>
            <div className={styles.Label}>ОГРН</div>
            <div className={styles.InputWrapper}>
              <Input disabled placeholder="ОГРН" value={company.ogrn} />
            </div>
            <div className={styles.Label}>Дата регистрации</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Дата регистрации"
                type="date"
                value={format(new Date(company.registrationDate), 'yyyy-MM-dd')}
              />
            </div>
            <div className={styles.Label}>Статус организации</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Статус организации"
                value={company.status}
              />
            </div>
            <div className={styles.Label}>Генеральный директор</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Генеральный директор"
                value={company.ceo}
              />
            </div>
          </div>
          <div className={styles.AccordionHalf}>
            <div className={styles.ContactInfo}>
              <div className={styles.ContactInfo__Title}>
                <Title as="h3">Контактная информация</Title>
              </div>
              <div className={styles.Label}>Номер телефона</div>
              <div className={styles.InputWrapper}>
                <Input
                  placeholder="Номер телефона"
                  value={company.phone}
                  onChange={handleChange('phone')}
                />
              </div>
              <div className={styles.Label}>Почта</div>
              <div className={styles.InputWrapper}>
                <Input
                  placeholder="Почта"
                  value={company.email}
                  onChange={handleChange('email')}
                />
              </div>
              <div className={styles.Label}>Сайт</div>
              <div className={styles.InputWrapper}>
                <Input
                  placeholder="Сайт"
                  value={company.site}
                  onChange={handleChange('site')}
                />
              </div>
              <div className={styles.ContactInfo__ButtonWrapper}>
                <Button filled onClick={saveContactInfo}>
                  Сохранить
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Accordion>
      {company.legalAddress && (
        <Accordion
          open={extraInfo.open}
          title="Дополнительные сведения о компании"
          onToggle={handleExtraInfoToggle}
        >
          <div className={styles.AccordionContent}>
            <div className={styles.SubTitleWrapper}>
              <Title as="h3">Юридический адрес компании</Title>
            </div>
            <div className={styles.Label}>Полный адрес компании</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Полный адрес компании"
                value={company.legalAddress.fullAddress}
              />
            </div>
            <div className={styles.Label}>Страна</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Страна"
                value={company.legalAddress.country}
              />
            </div>
            <div className={styles.Label}>Город</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Город"
                value={company.legalAddress.city}
              />
            </div>
            <div className={styles.Label}>Индекс</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Индекс"
                value={company.legalAddress.postalCode}
              />
            </div>
            <div className={styles.InputWrapper}>
              <Checkbox
                checked={company.commonAddress}
                onClick={handleCheckCommonAddress}
              >
                Совпадает с фактическим адресом
              </Checkbox>
            </div>
            <div className={styles.ActualAddressInfo}>
              <>
                <div className={styles.SubTitleWrapper}>
                  <Title as="h3">Фактический адрес компании</Title>
                </div>
                <div className={styles.Label}>Полный адрес</div>
                <div className={styles.InputWrapper}>
                  <Input
                    placeholder="Полный адрес"
                    value={company.actualAddress?.fullAddress}
                    onChange={handleChange('actualAddress', 'fullAddress')}
                  />
                </div>
                <div className={styles.Label}>Страна</div>
                <div className={styles.InputWrapper}>
                  <Input
                    placeholder="Страна"
                    value={company.actualAddress?.country}
                    onChange={handleChange('actualAddress', 'country')}
                  />
                </div>
                <div className={styles.Label}>Город</div>
                <div className={styles.InputWrapper}>
                  <Input
                    value={company.actualAddress?.city}
                    placeholder="Город"
                    onChange={handleChange('actualAddress', 'city')}
                  />
                </div>
                <div className={styles.Label}>Индекс</div>
                <div className={styles.InputWrapper}>
                  <Input
                    placeholder="Индекс"
                    value={company.actualAddress?.postalCode}
                    onChange={handleChange('actualAddress', 'postalCode')}
                  />
                </div>

                <div className={styles.ContactInfo__ButtonWrapper}>
                  <Button filled onClick={saveActualAddressInfo}>
                    Сохранить
                  </Button>
                </div>
              </>
            </div>
          </div>
        </Accordion>
      )}
      <Accordion
        open={bankInfo.open}
        title="Банковские реквизиты"
        onToggle={handleBankInfoToggle}
      >
        <div className={styles.Accounts}>
          {company.bankAccounts?.map((account) => (
            <AccountCard
              key={account._id}
              account={account}
              fnDelete={handleDeleteBankAccount}
            />
          ))}
        </div>
        <Button filled onClick={addHideBank}>
          {addBank ? 'Скрыть' : 'Добавить реквизиты'}
        </Button>

        {addBank && (
          <div className={styles.AccordionContent}>
            <>
              <br />
              <br />
              <div className={styles.Label}>БИК банка</div>
              <div className={styles.InputWrapper}>
                <Input value={company.bank.bik} onChange={handleBikUpdate} />
              </div>
              <div className={styles.Label}>Расчетный счет</div>
              <div className={styles.InputWrapper}>
                <Input
                  value={company.bank.accountNumber}
                  onChange={(e) =>
                    dispatch(
                      updateItem({
                        key: 'bank',
                        value: {
                          ...company.bank,
                          accountNumber: e.target.value,
                        },
                      })
                    )
                  }
                />
              </div>
              <div className={styles.Label}>Наименование</div>
              <div className={styles.InputWrapper}>
                <Input disabled value={company.bank.name} />
              </div>
              <div className={styles.Label}>Корреспондентский счет</div>
              <div className={styles.InputWrapper}>
                <Input disabled value={company.bank.correspondenceAccount} />
              </div>
              <div className={styles.Label}>ИНН</div>
              <div className={styles.InputWrapper}>
                <Input disabled value={company.bank.itn} />
              </div>
              <div className={styles.Label}>КПП</div>
              <div className={styles.InputWrapper}>
                <Input disabled value={company.bank.kpp} />
              </div>
              <div className={styles.CheckArea}>
                <Checkbox
                  checked={company.bank?.mainAccount}
                  onClick={(e) =>
                    dispatch(
                      updateItem({
                        key: 'bank',
                        value: {
                          ...company.bank,
                          mainAccount: e.target.checked,
                        },
                      })
                    )
                  }
                >
                  Использовать этот счет
                </Checkbox>
                <Button filled onClick={handleSaveBankAccount}>
                  Сохранить
                </Button>
              </div>
            </>
          </div>
        )}
      </Accordion>
    </div>
  );
};
