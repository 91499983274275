import {
  departureCityPortList,
  departureStationList,
  arrivalClientFromPortStationList,
  arrivalStraightStationList,
  departureCountryList} from '../makeOffers/consts';

import { searchParamsSeaSchema, searchParamsTrainSchema } from "../../utils/validation";

import { getClientLogisticsOffers } from '../../api';
import {
  resetFilters,
  getFiltersByFilterKey,
  applyFilters } from '../filters';
import { FILTERS, DEFAULT_FILTERS } from '../../consts';
import { 
  getTypeStation,
  getStateForm,
  getSearchObj,
  getLoading,
  getLoadup,
  getCount,
  getTotal } from './selectors';

export const departurePoints = {
  sea: departureCityPortList,
  train: departureStationList
}

export const arrivalPoints = {
  sea: arrivalClientFromPortStationList,
  train: arrivalStraightStationList
}

export const SET_DEFAULT_STATE = '@selectionOffers/SET_DEFAULT_STATE';
export const CHANGE_DEPARTURE_COUNTRY = '@selectionOffers/CHANGE_DEPARTURE_COUNTRY';
export const CHANGE_DEPARTURE_POINT = '@selectionOffers/CHANGE_DEPARTURE_POINT';
export const CHANGE_TYPE_STATION = '@selectionOffers/CHANGE_TYPE_STATION';
export const CHANGE_TYPE_CONTAINER = '@selectionOffers/CHANGE_TYPE_CONTAINER';
export const CHANGE_ARRIVAL_COUNTRY = '@selectionOffers/CHANGE_ARRIVAL_COUNTRY';
export const CHANGE_ARRIVAL_TERMINAL = '@selectionOffers/CHANGE_ARRIVAL_TERMINAL';
export const CHANGE_ARRIVAL_POINT = '@selectionOffers/CHANGE_ARRIVAL_POINT';
export const CHANGE_CUSTOMS = '@selectionOffers/CHANGE_CUSTOMS';
export const CHANGE_LOADING = '@selectionOffers/CHANGE_LOADING';
export const CHANGE_LOADUP = '@selectionOffers/CHANGE_LOADUP';
export const RESET_OFFERS = '@selectionOffers/RESET_OFFERS';
export const ADD_OFFERS = '@selectionOffers/ADD_OFFERS';
export const SET_SEARCH_OBJECT = '@selectionOffers/SET_SEARCH_OBJECT';
export const SET_COUNT = '@selectionOffers/SET_COUNT';
export const SET_SEA_BY_SEARCH_PARAMS = '@selectionOffers/SET_SEA_BY_SEARCH_PARAMS'; 
export const SET_TRAIN_BY_SEARCH_PARAMS = '@selectionOffers/SET_TRAIN_BY_SEARCH_PARAMS'; 






export const changeDepartureCountry = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_DEPARTURE_COUNTRY,
    payload
  });
}

export const changeDeparturePoint = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_DEPARTURE_POINT,
    payload
  });
}

export const changeTypeStation = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_TYPE_STATION,
    payload
  });
}

export const changeTypeContainer = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_TYPE_CONTAINER,
    payload
  });
}

export const changeArrivalCountry = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_ARRIVAL_COUNTRY,
    payload
  });
}

export const changeArrivalTerminal = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_ARRIVAL_TERMINAL,
    payload
  });
}

export const changeArrivalPoint = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_ARRIVAL_POINT,
    payload
  });
}

export const changeCustoms = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_CUSTOMS,
    payload
  });
}

export const changeLoading = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_LOADING,
    payload
  });
}

export const changeLoadup = (payload) => async (dispatch) => {
  dispatch({
    type: CHANGE_LOADUP,
    payload
  });
}

export const resetOffers = {
    type: RESET_OFFERS
}


export const setSearchObj = (payload) => async (dispatch) => {
  dispatch({
    type: SET_SEARCH_OBJECT,
    payload
  });
}

export const addOffers = (payload) => async (dispatch) => {
  dispatch({
    type: ADD_OFFERS,
    payload
  });
}

export const setCount = (payload) => async (dispatch) => {
  dispatch({
    type: SET_COUNT,
    payload
  });
}

export const loadingOffers = (toast) => async (dispatch, getState) => {

  const state = getState();
  const stateForm = getStateForm(state);
  const typeStation = getTypeStation(state);  
  const searchObj = {};

  try{ 
    dispatch(changeLoading(true));             
    if (typeStation === "sea"){
      searchObj.typetransport = 1;
      searchObj.container = stateForm.typeContainer;
      searchObj.cityDeparture = stateForm.departurePoint.title;
      searchObj.countryDeparture = stateForm.departureCountry.title;
      searchObj.terminalCityArrival = stateForm.arrivalCityTerminal.title;
      searchObj.cityArrival = stateForm.arrivalPoint.title;
      searchObj.countryArrival = stateForm.arrivalCountry.title;
      if (stateForm.customs.datatype === "vtt"){
        searchObj.customsVtt = true;
      } else {
        searchObj.customsGtd = true;
      }
    } else if (typeStation === "train"){
      searchObj.typetransport = 0;
      searchObj.container = stateForm.typeContainer;
      searchObj.cityDeparture = stateForm.departurePoint.title;
      searchObj.countryDeparture = stateForm.departureCountry.title;
      searchObj.cityArrival = stateForm.arrivalPoint.title;
      searchObj.countryArrival = stateForm.arrivalCountry.title;
    }

    dispatch(resetOffers);
    dispatch(setSearchObj(searchObj));
    dispatch(resetFilters(FILTERS.CLIENT_OFFERS));
    const offers = await getClientLogisticsOffers({...DEFAULT_FILTERS[FILTERS.CLIENT_OFFERS], ...searchObj});
    dispatch(applyFilters(FILTERS.CLIENT_OFFERS, {offset: DEFAULT_FILTERS[FILTERS.CLIENT_OFFERS].limit}));
    if (!offers.offers.length) toast.error('По вашему запросу нет предложений');
    dispatch(addOffers(offers));    
    dispatch(changeLoading(false));     

  }catch(err){
    console.error(err);
    toast.error('Ошибка на сервере, попробуйте позже.');
    dispatch(resetFilters(FILTERS.CLIENT_OFFERS));
    dispatch(resetOffers);
    dispatch(changeLoading(false));
  }
}

export const loadupOffers = (toast) => async (dispatch, getState) => {

  try{
    const state = getState();  
    const searchObj = getSearchObj(state)||{};
    const loading = getLoading(state);
    const loadup = getLoadup(state);
    const count = getCount(state);  
    const total = getTotal(state);  
    const filters = getFiltersByFilterKey(FILTERS.CLIENT_OFFERS)(state);

    if (!loading && !loadup && Object.keys(searchObj).length && count < total){
      dispatch(changeLoadup(true));
      const offers = await getClientLogisticsOffers({...filters, ...searchObj}); 
      dispatch(addOffers(offers));      
      dispatch(applyFilters(FILTERS.CLIENT_OFFERS, {offset: filters.offset+1}));
      dispatch(changeLoadup(false));
    }

  } catch(err){
    console.error(err);
    toast.error('Ошибка на сервере, попробуйте позже.');
    dispatch(resetFilters(FILTERS.CLIENT_OFFERS));
    dispatch(resetOffers);
    dispatch(changeLoadup(false));
  }

}

export const checkSearchParams = (toast) => async (dispatch, getState) => {

    const url = new URL(window.location.href);
    let result;
    if (url.search){

      const searchParams = Object.fromEntries(url.searchParams);      
      result = await searchParamsSeaSchema.validate(searchParams,{abortEarly: true, stripUnknown: true}).catch(err=>{return})
        || await searchParamsTrainSchema.validate(searchParams,{abortEarly: true, stripUnknown: true}).catch(err=>{return});
      
      if (result){

        if (result.typeStation == "sea"){
          dispatch({type: SET_SEA_BY_SEARCH_PARAMS, payload: result});
        } else if (result.typeStation == "train"){
          dispatch({type: SET_TRAIN_BY_SEARCH_PARAMS, payload: result});
        } 

        await dispatch(loadingOffers(toast));         
      }      
    }

}