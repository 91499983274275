import { 
  START_FETCHING,
  LOAD_MY_OFFERS_PACKS_SUCCESS,
  LOAD_MY_OFFERS_PACKS_FAILURE,
  INIT_NEW_OFFER_PACK,
  DELETE_MY_OFFERS_PACK_SUCCESS,
  DELETE_MY_OFFERS_PACK_FAILURE } from './actions';

const initialState = {
  myofferspacks: {
    packs: [],
    count: null
  },
  newofferspack: {
    name: null,
    typetransport: null,
    terminal: null,
    expiredate: null
  },
  loading: false
};

export const logisticsOffersPacksReducer = (state = initialState, action) => {

  let offersArr, requestsArr;

  switch (action.type) {
    case START_FETCHING:
      return {
        ...state,
        loading: true,
      };
    case INIT_NEW_OFFER_PACK:
      return {
        ...state,
        newofferspack: action.payload,
        loading: false,
      };      
    case LOAD_MY_OFFERS_PACKS_SUCCESS:      
      return {
        ...state,
        ...{myofferspacks: {
            packs: action.payload.packs,
            count: action.payload.count
          }},      
        loading: false,
      };
    case DELETE_MY_OFFERS_PACK_SUCCESS: 
      offersArr = state.myofferspacks.packs.filter(i=>i._id!=action.payload.deletedOffersPack._id);     
      return {
        ...state,
        ...{myofferspacks: {
            packs: offersArr,
            count: state.myofferspacks.count - 1
          }},      
        loading: false,
      };      
    case DELETE_MY_OFFERS_PACK_FAILURE:
    case LOAD_MY_OFFERS_PACKS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };                
    default:
      return state;      
  }	
}