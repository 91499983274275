import p from 'petrovich';

// Колесников Сергей Анатольевич
export const getGenitiveName = (fullName) => {
  let genitivePerson = {};
  if (!fullName) {
    return '';
  }
  const [last, first, middle] = fullName.split(' ');

  const initPerson = {
    first,
    middle,
    last,
  };

  try{
    genitivePerson = p(initPerson, 'genitive');    
  }catch(err){
    genitivePerson.last = last?last:'';
    genitivePerson.first = first?first:'';
    genitivePerson.middle = middle?middle:'';
  }

  return `${genitivePerson.last} ${genitivePerson.first} ${genitivePerson.middle}`;
};
