import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from '../consts';
import { NotificationsEmail } from '../components/Notifications';
import { NotificationsPhone } from '../components/Notifications';

export const Notifications = () => {
  return (
    <main>
      <Routes>
        <Route
          path={ROUTES.NOTIFICATIONS_EMAIL}
          element={<NotificationsEmail />}
        />
        <Route path={ROUTES.NOTIFICATIONS_SMS} element={<NotificationsPhone />} />
        <Route path='*' element={ <Navigate to={ROUTES.NOT_FOUND} /> } />
      </Routes>
    </main>
  );
};
