import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from '../../../consts';
import { getCount } from '../../../store/requests';
import { HeaderWithCount } from '../../shared/HeaderWithCount';
import { Tabs } from '../../shared/Tabs';
import { Pool } from './Pool';
import { InProgress } from './InProgress';
import { Completed } from './Completed';
import { RequestDeclarantDetail } from './RequestDeclarantDetail';
import { RequestPreview } from './RequestPreview';

const Header = () => {
  const count = useSelector(getCount);

  return (
    <div style={{ marginTop: '2rem' }}>
      <HeaderWithCount title="Заявки" count={count}>
        <Tabs
          tabs={[
            {
              title: 'Новые',
              route: ROUTES.FULL_REQUESTS_DECLARATIONS_POOL,
              href:  "../"+ROUTES.REQUESTS_DECLARATIONS_POOL,
              visible: true,
            },
            {
              title: 'Заявки в работе',
              route: ROUTES.FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS_IN_PROGRESS,
              href:  "../"+ROUTES.REQUESTS_DECLARATIONS_ALL_REQUESTS_IN_PROGRESS,
              visible: true,
            },
            {
              title: 'Завершенные',
              route: ROUTES.FULL_REQUESTS_DECLARATIONS_COMPLETED,
              href:  "../"+ROUTES.REQUESTS_DECLARATIONS_COMPLETED,
              visible: true,
            },
          ]}
        />
      </HeaderWithCount>
    </div>
  );
};

export const RequestsDeclarant = () => {  
  return (
    <main>
      <Routes>
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_POOL}
          Component={() => (
            <>
              <Header />
              <Pool />
            </>
          )}
          
        />
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_ALL_REQUESTS_IN_PROGRESS}
          Component={() => (
            <>
              <Header />
              <InProgress />
            </>
          )}
          
        />
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_COMPLETED}
          Component={() => (
            <>
              <Header />
              <Completed />
            </>
          )}
          
        />
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_DETAIL+"/*"}
          Component={RequestDeclarantDetail}
        />
        <Route
          path={ROUTES.REQUESTS_DECLARATIONS_DETAIL_PREVIEW}
          Component={RequestPreview}
        />
        <Route path="*" element={<Navigate to={ROUTES.FULL_REQUESTS_DECLARATIONS_POOL} />} />
      </Routes>
    </main>
  );
};
