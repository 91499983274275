import { debounce } from 'lodash';

import { ApiHelper } from '../utils';
import { API_ROUTES, DEBOUNCE_DELAY } from '../consts';

export const register = (payload) =>
  ApiHelper.post(API_ROUTES.REGISTER, payload);

export const login = ({ emailOrPhone, password }) => {  
  return ApiHelper.post(API_ROUTES.LOGIN, { emailOrPhone, password });
};

export const updateProfile = (payload, id) =>
  ApiHelper.patch(`${API_ROUTES.MODIFY_PROFILE}/${id}`, payload);

export const debouncedRequest = debounce(updateProfile, DEBOUNCE_DELAY);

export const updatePassword = (payload, id) =>
  ApiHelper.post(`${API_ROUTES.UPDATE_PASSWORD}/${id}`, payload);

export const requestEmailPin = (id) =>
  ApiHelper.get(`${API_ROUTES.UPDATE_EMAIL}/${id}`);

export const fetchProfile = () => ApiHelper.get(API_ROUTES.PROFILE);
export const fetchSubProfiles = () => ApiHelper.get(API_ROUTES.SUB_PROFILE);
export const createSubProfile = (payload) =>
  ApiHelper.post(API_ROUTES.SUB_PROFILE, payload);
export const deleteProfile = (id) =>
  ApiHelper.delete(`${API_ROUTES.PROFILE}/${id}`);

export const resetPassword = (payload) =>
  ApiHelper.post(API_ROUTES.RESET_PASSWORD, payload);

export const verifyEmail = (payload) =>
  ApiHelper.post(API_ROUTES.VERIFY_EMAIL, payload);

export const checkResetToken = (hash) =>
  ApiHelper.get(`${API_ROUTES.PROFILE}/reset-password/${hash}`);

export const setNewPW = (hash, payload) =>
  ApiHelper.post(`${API_ROUTES.PROFILE}/reset-password/${hash}`, payload);
