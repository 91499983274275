import { 
  START_FETCHING,
  LOAD_MY_REQUESTS_SUCCESS,
  LOAD_MY_REQUESTS_FAILURE,
  ADD_NEW_MY_REQUEST,
  ADD_NEW_MY_OFFER,
  LOAD_MY_IN_WORK_SUCCESS,
  LOAD_MY_IN_WORK_FAILURE,
  LOAD_MY_ARCHIVE_SUCCESS,
  LOAD_MY_ARCHIVE_FAILURE,
  LOAD_CLIENT_ONE_REQUEST_SUCCESS,
  LOAD_CLIENT_ONE_REQUEST_FAILURE,
  DELETE_MANAGER_OFFER_SUCCESS,
  DELETE_MANAGER_OFFER_FAILURE,
  DELETE_CLIENT_REQUEST_SUCCESS,
  DELETE_CLIENT_REQUEST_FAILURE } from './actions';

const initialState = {
  requests: {
    data: [],
    count: null
  },
  inwork: {
    data: [],
    count: null
  },
  archive: {
    data: [],
    count: null
  },
  chosenDoc:{
    data: null
  },
  loading: true
};

export const logisticsReducer = (state = initialState, action) => {

  let offersArr, requestsArr;

  switch (action.type) {
    case START_FETCHING:
      return {
        ...state,
        loading: true,
      };
    case ADD_NEW_MY_REQUEST:
      return {
        ...state,
        ...{requests: {
            data: [...state.requests.data, action.payload.data ],
            count: state.requests.count+1 
          }},
        loading: false,
      };
    case ADD_NEW_MY_OFFER:
      offersArr = [...state.chosenDoc.data[0].offers, action.payload.data];
      return {
        ...state,
        ...{chosenDoc: {
            data: [{...state.chosenDoc.data[0], offers: offersArr}] 
          }},
        loading: false,
      };      
    case LOAD_MY_REQUESTS_SUCCESS:
      return {
        ...state,
        ...{requests: {
            data: action.payload.requests,
            count: action.payload.count
          }},
        loading: false,
      }; 
    case LOAD_MY_IN_WORK_SUCCESS:
      return {
        ...state,
        ...{inwork: {
            data: action.payload.inwork,
            count: action.payload.count
          }},
        loading: false,
      };
    case LOAD_MY_ARCHIVE_SUCCESS:
      return {
        ...state,
        ...{archive: {
            data: action.payload.archive,
            count: action.payload.count
          }},
        loading: false,
      }; 
    case LOAD_CLIENT_ONE_REQUEST_SUCCESS:
      return {
        ...state,
        ...{chosenDoc: {
            data: action.payload.chosendoc
          }},
        loading: false,
      }; 
    case DELETE_MANAGER_OFFER_SUCCESS:
      offersArr = state.chosenDoc.data[0].offers.filter(i=>i._id!=action.payload.offer._id);
      return {
        ...state,
        ...{chosenDoc: {
            data: [{...state.chosenDoc.data[0], offers: offersArr}] 
          }},
        loading: false,
      };
    case DELETE_CLIENT_REQUEST_SUCCESS:
      requestsArr = state.requests.data.filter(i=>i._id!=action.payload.request._id);
      return {
        ...state,
        ...{requests: {
            data: requestsArr,
            count: state.requests.count-1 
          }},
        loading: false,
      };      
    case DELETE_CLIENT_REQUEST_FAILURE:
    case DELETE_MANAGER_OFFER_FAILURE:                     
    case LOAD_MY_REQUESTS_FAILURE:
    case LOAD_MY_IN_WORK_FAILURE:      
    case LOAD_MY_REQUESTS_FAILURE:
    case LOAD_CLIENT_ONE_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };                
    default:
      return state;      
  }	
}