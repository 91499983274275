import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import { CheckRole } from '../components/hocs/Checker';
import { getRole, getBalance } from '../store/profile';
import { getOutPayments } from '../api';
import { ROUTES, ROLES } from '../consts';
import { withLoading } from '../components/hocs/withLoading';
import { Title } from '../components/shared/Title';
import { Tabs } from '../components/shared/Tabs';
import { ActionsHistory } from '../components/Account/ActionsHistory';
import { Refill } from '../components/Account/Refill';
import { Withdrawals } from '../components/Account/Withdrawals';
import { AccountHeader } from '../components/Account/AccountHeader';
import { formatMoney } from '../utils';

import styles from './Account.module.scss';

export const Account = withLoading(() => {
  const role = useSelector(getRole);
  const balance = useSelector(getBalance);
  const isRoleUser = role === ROLES.USER;
  const isRoleDeclarant = role === ROLES.DECLARANT;

  const [totalEarned, setTotalEarned] = useState(0);

  useEffect(() => {
    const getEarnings = async () => {
      const { outPaymentsSum } = await getOutPayments({
        role: ROLES.DECLARANT,
      });
      if (outPaymentsSum || outPaymentsSum === 0) {
        setTotalEarned(balance + outPaymentsSum);
      }
    };
    getEarnings();
  }, [setTotalEarned, balance]);

  return (
    <main>
      <div className={styles.Wrapper}>
        <div className={styles.Header}>
          <Title>Мой счет</Title>
          <Tabs
            tabs={[
              {
                title: 'История операций',
                route: ROUTES.FULL_ACCOUNT_HISTORY,
                visible: true,
              },
              {
                title: 'Пополнение',
                route: ROUTES.FULL_ACCOUNT_REFILL,
                visible: true,
              },
/*              {
                title: 'Вывод средств',
                route: ROUTES.FULL_ACCOUNT_WITHDRAWALS,
                visible: !isRoleUser? true: false,
              },*/              
            ]}
            hasSubTabs
          />
        </div>
        {isRoleDeclarant && (
          <div className={styles.Income}>
            <div className={styles.Income__Title}>
              Заработано за время в системе
            </div>
            <div className={styles.Income__Value}>
              <span className={styles.Income__Number}>
                {formatMoney(totalEarned)}
              </span>
            </div>
          </div>
        )}        
{/*        {!isRoleUser && (
          <div className={styles.Income}>
            <div className={styles.Income__Title}>
              Заработано за время в системе
            </div>
            <div className={styles.Income__Value}>
              <span className={styles.Income__Number}>
                {formatMoney(totalEarned)}
              </span>
            </div>
          </div>
        )}*/}
      </div>

      {/*<AccountHeader />*/}
      <Routes>
        <Route
          path={ROUTES.ACCOUNT_HISTORY+"/*"}
          Component={ActionsHistory}
        />
        <Route path={ROUTES.ACCOUNT_REFILL} Component={Refill} /> 
        {/*<Route path={ROUTES.ACCOUNT_WITHDRAWALS} Component={Withdrawals} />*/} 
{/*        <Route path={ROUTES.ACCOUNT_WITHDRAWALS}  element={ <CheckRole                            
          roles={{            
            [ROLES.DECLARANT]: Withdrawals,
            [ROLES.PARTNER]: Withdrawals,
            [ROLES.LOGIST]: Withdrawals,
          }} /> }        
        />*/}

{/*        <Route path={ROUTES.ACCOUNT_OPERATION}  element={ <CheckRole                            
          roles={{
            [ROLES.USER]: Refill,
            [ROLES.DECLARANT]: Withdrawals,
            [ROLES.PARTNER]: Withdrawals
          }} /> }        
        />*/} 
        <Route path="*" element={<Navigate to={ROUTES.FULL_ACCOUNT_HISTORY} />} />
      </Routes>
    </main>
  );
});
