import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SuccessIcon from '../assets/Success.svg';
import { createReview } from '../api';
import { Button } from '../components/shared/Button';
import { Panel } from '../components/shared/Panel';
import { Select } from '../components/shared/Select';
import { Title } from '../components/shared/Title';
import { useScrollTop } from '../hooks';
import { getCompanies, loadCompanies } from '../store/companies';

export const Reviews = () => {
  useScrollTop();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [text, setText] = useState('');
  const [company, setCompany] = useState('');
  const companies = useSelector(getCompanies);
  const handleChange = ({ value }) => {
    setCompany(value);
  };

  useEffect(() => {
    dispatch(loadCompanies);
  }, [dispatch]);

  useEffect(() => {
    if (companies.length > 0) {
      const [firstCompany] = companies;
      setCompany(firstCompany._id);
    }
  }, [companies]);

  const options = companies.map(({ _id, name }) => ({
    value: _id,
    label: name,
  }));

  const handleSubmit = async () => {
    await createReview({ company, text });
    setSuccess(true);
  };

  return (
    <div>
      <Title>Создать обращение</Title>
      {success ? (
        <div
          style={{ display: 'flex', alignItems: 'center', padding: '5rem 0' }}
        >
          <img
            style={{ marginRight: '1rem' }}
            src={SuccessIcon}
            alt="success"
          />
          Ваше обращение создано! В ближайшее время мы с вами свяжемся.
        </div>
      ) : (
        <Panel
          title={
            <>
              <div>
                Понравилась платформа или произошли технические проблемы при использовании платформы?
              </div>
              <div>
                Создайте, пожалуйста свое обращение и мы вам поможем в ближайшее время.
              </div>
            </>
          }
        >
          <div style={{ maxWidth: '30rem', margin: '2rem 0' }}>
            <div style={{ marginBottom: '0.5rem' }}>
              От лица какой компании создать обращение?
            </div>
            <Select
              options={options}
              value={options.find((option) => option.value === company)}
              onChange={handleChange}
              placeholder="Укажите компанию"
            />
          </div>
          <div>
            <textarea
              style={{
                borderColor: '#D9D9D9',
                borderRadius: '4px',
                height: '188px',
                width: '100%',
                resize: 'vertical',
                outline: 'none',
                padding: '0.5rem',
              }}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div
            style={{
              marginTop: '26px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button filled onClick={handleSubmit}>
              Создать обращение
            </Button>
          </div>
        </Panel>
      )}
    </div>
  );
};
