import React, {
  //  useEffect,
  useRef,
  useState,
} from 'react';

// import { requestEmailPin } from 'api';
import { Modal } from '../shared/Modal';
import { Title } from '../shared/Title';
import { Loader } from '../shared/Loader';
import { Steps } from './Steps';

import styles from './UpdateEmailModal.module.scss';
import { Input } from '../shared/Input';

export const UpdateEmailModal = (props) => {
  // const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const refsArr = useRef([]);

  // useEffect(() => {
  //   requestEmailPin();
  // }, []);

  // const handlePinInput =

  return (
    <Modal {...props}>
      {loading ? (
        <div className={styles.LoaderWrapper}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.Title}>Изменение почты</div>
          <Steps
            steps={[
              {
                label: 'Код подтверждения',
                active: true,
              },
              {
                label: 'Новая почта',
                active: false,
              },
              {
                label: 'Код подтверждения',
                active: false,
              },
            ]}
          />
          <div className={styles.CTA}>
            <Title as="h2">Введите код подтверждения</Title>
          </div>
          <div className={styles.InputGroup}>
            {[1, 2, 3, 4].map((num) => (
              <React.Fragment key={num}>
                <div className={styles.Input}>
                  <Input
                    placeholder="*"
                    maxLength={1}
                    outRef={(ref) => refsArr.current.push(ref)}
                    autoFocus={num === 1}
                    onChange={(e) => {
                      e.persist();
                      // setCode((prevCode) => prevCode + e.target.value);
                      if (num !== 4) {
                        refsArr.current[num].focus();
                      } else {
                        // TODO - use finalValue
                        // const finalValue = code + e.target.value;
                        setLoading(true);
                      }
                    }}
                  />
                </div>
                {num !== 4 && <div className={styles.Input__Divider} />}
              </React.Fragment>
            ))}
          </div>
          <div className={styles.SendAgain}>Отправить повторно</div>
        </>
      )}
    </Modal>
  );
};
