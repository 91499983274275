import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
// import {format} from 'date-fns';

import {ROUTES} from '../../consts';
import {
  getDocsOpen,
  getCeoDocsOpen,
  getCompany,
  setDocsOpen,
  setCeoDocsOpen,
  updateDocs,
  excludeCompanyDocs,
} from '../../store/companies';
import {Accordion} from '../shared/Accordion';
import {Button} from '../shared/Button';
import ExclamationBlue from '../../assets/ExclamationBlue.svg';
import {UploadArea} from '../shared/UploadArea';
import {Tabs} from '../shared/Tabs';
import {createCompanyAgreement/*, createDocx*/} from '../../utils/createDocx';
/*import {getGenitiveName} from '../../utils/getGenitiveName';*/
/*import {getFio} from '../../utils/getFio';*/
import { getRole } from '../../store/profile';
import styles from './Documents.module.scss';

const configFilesStatut = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
  addHead: 'Примечание',
  note: [
    'Обратить внимание на индекс, адрес, срок полномочий директора, наименование на английском',
  ],
};

const configFilesMemorandum = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
};

const configFilesStateRegistration = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
  addHead: 'Примечание',
  note: ['ОГРН, дата регистрации'],
};

const configFilesLegalEntityRegistry = {
  mainHead: 'Формат документа',
  docsList: [' Скан '],
};

const configFilesTaxOfficeRegistry = {
  mainHead: 'Формат документа',
  docsList: [' Скан '],
  addHead: 'Примечание',
  note: ['ИНН/КПП'],
};

const configFilesAccountSertificates = {
  mainHead: 'Формат документов',
  docsList: [' Скан '],
  addHead: 'Примечание',
  note: [
    'Со сроком не более 1 месяца на фирменных бланках Банка (содержащих информацию: название, код ОКПО, ИНН, юридический адрес, корреспондентский счет и БИК Банка',
  ],
};

const configFilesHeadAppointmentOrder = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
  addHead: 'Примечание',
  note: [
    'Если руководитель является нерезидентом, то требуется подтверждение миграционной службы на право работы в РФ, либо вид на жительство',
  ],
};

const configFilesAccountantAppointmentOrder = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
};

const configFilesRegisterExtract = {
  mainHead: 'Формат документа',
  docsList: [' С сайта '],
  addHead: 'Примечание',
  note: ['Если не было никаких изменений в учредительных документах'],
};

const configFilesPassportCeo = {
  mainHead: 'Формат документа',
  docsList: [' Скан '],
  addHead: 'Примечание',
  note: ['При отсутствии прописки, необходима временная регистрация'],
};

export const Documents = () => {
  const dispatch = useDispatch();
  const docsOpen = useSelector(getDocsOpen);
  const ceoDocsOpen = useSelector(getCeoDocsOpen);
  const company = useSelector(getCompany);
  const { id } = useParams();
  const role = useSelector(getRole);
 
  const onChange = (key, value) => {
    dispatch(updateDocs({key, value}));
  };
  const onExclude = (docGroup, doc) => {
    dispatch(excludeCompanyDocs(docGroup, doc));
  };

  return (
    <div className={styles.Documents}>
      <Tabs
        tabs={[
          {
            title: 'Общие сведения о компании',
            route: ROUTES.FULL_PROFILE_COMPANY_INFO,
            href: `../${id}/info`,
            visible: true,
          },
          {
            title: 'Документы компании',
            route: ROUTES.FULL_PROFILE_COMPANY_DOCS,
            href: `../${id}/docs`,
            visible: true,
          },
          {
            title: 'О компании',
            route: ROUTES.FULL_PROFILE_COMPANY_OFFER,
            href: `../${id}/offer`,
            visible: (role === "logist")?true:false,
          } 
        ]}
      />
      <div className={styles.WhyThisDocs}>
        <img
          src={ExclamationBlue}
          alt="ExclamationBlue"
          className={styles.WhyThisDocs__Img}
        />
        <div className={styles.WhyThisDocs__Text}>
          Документы необходимы для верификации компании и формирования декларации
        </div>
      </div>
      <div>
        <Button
          filled
          onClick={() =>
            createCompanyAgreement(company)
          }
        >
          Скачать договор
        </Button>
      </div>
      <Accordion
        title="Учредительные документы компании"
        open={docsOpen}
        onToggle={() => dispatch(setDocsOpen(!docsOpen))}
      >
        <div>
          <UploadArea
            files={company.statut}
            id="statut"
            configFiles={configFilesStatut}
            onUpload={(value) =>
              onChange('statut', [...company.statut, ...value])
            }
            onExclude={onExclude}
          >
            Устав компании и все изменения к нему
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.memorandum}
            id="memorandum"
            configFiles={configFilesMemorandum}
            onUpload={(value) =>
              onChange('memorandum', [...company.memorandum, ...value])
            }
            onExclude={onExclude}
          >
            Учредительный договор и все изменения к нему или решение (протокол)
            о создании организации
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.stateRegistration}
            id="stateRegistration"
            configFiles={configFilesStateRegistration}
            onUpload={(value) =>
              onChange('stateRegistration', [
                ...company.stateRegistration,
                ...value,
              ])
            }
            onExclude={onExclude}
          >
            Свидетельство о государственной регистрации ФНС
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.legalEntityRegistry}
            id="legalEntityRegistry"
            configFiles={configFilesLegalEntityRegistry}
            onUpload={(value) =>
              onChange('legalEntityRegistry', [
                ...company.legalEntityRegistry,
                ...value,
              ])
            }
            onExclude={onExclude}
          >
            Свидетельство о внесении в ЕГРЮЛ ФНС и всех изменений в
            учредительные документы
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.taxOfficeRegistry}
            id="taxOfficeRegistry"
            configFiles={configFilesTaxOfficeRegistry}
            onUpload={(value) =>
              onChange('taxOfficeRegistry', [
                ...company.taxOfficeRegistry,
                ...value,
              ])
            }
            onExclude={onExclude}
          >
            Свидетельство или уведомление о постановке на учет в органах ФНС
            России
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.accountSertificates}
            id="accountSertificates"
            configFiles={configFilesAccountSertificates}
            onUpload={(value) =>
              onChange('accountSertificates', [
                ...company.accountSertificates,
                ...value,
              ])
            }
            onExclude={onExclude}
          >
            Справки из банков об открытии банковских счетов (валютных, рублевых)
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.headAppointmentOrder}
            id="headAppointmentOrder"
            configFiles={configFilesHeadAppointmentOrder}
            onUpload={(value) =>
              onChange('headAppointmentOrder', [
                ...company.headAppointmentOrder,
                ...value,
              ])
            }
            onExclude={onExclude}
          >
            Приказ (выписка из приказа) о назначении руководителя
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.accountantAppointmentOrder}
            id="accountantAppointmentOrder"
            configFiles={configFilesAccountantAppointmentOrder}
            onUpload={(value) =>
              onChange('accountantAppointmentOrder', [
                ...company.accountantAppointmentOrder,
                ...value,
              ])
            }
            onExclude={onExclude}
          >
            Приказ (выписка из приказа) о назначении главного бухгалтера
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.registerExtract}
            id="registerExtract"
            configFiles={configFilesRegisterExtract}
            onUpload={(value) =>
              onChange('registerExtract', [
                ...company.registerExtract,
                ...value,
              ])
            }
            onExclude={onExclude}
          >
            Выписка из ЕГРЮЛ
          </UploadArea>
        </div>
      </Accordion>
      <Accordion
        title="Документы руководителя компании"
        open={ceoDocsOpen}
        onToggle={() => dispatch(setCeoDocsOpen(!ceoDocsOpen))}
      >
        <div>
          <UploadArea
            files={company.passportCeo}
            id="passportCeo"
            configFiles={configFilesPassportCeo}
            onUpload={(value) =>
              onChange('passportCeo', [...company.passportCeo, ...value])
            }
            onExclude={onExclude}
          >
            Паспорт генерального директора (главная + прописка)
          </UploadArea>
        </div>
      </Accordion>
    </div>
  );
};
