import { API_ROUTES } from '../consts';
import { ApiHelper, generateQuery } from '../utils';

export const getDocsQueriesByRequestId = (id, filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.DOCS_QUERIES}/${id}${queryString}`);
};

export const createDocsQuery = (payload) =>
  ApiHelper.post(API_ROUTES.DOCS_QUERIES, payload);

export const upateDocsQuery = (id, payload) =>
  ApiHelper.patch(`${API_ROUTES.DOCS_QUERIES}/${id}`, payload);
