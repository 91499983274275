import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import {
  excludeNewRequestDocs,
  getNewRequest,
  loadDraft,
  saveDraft,
  saveRequest,
  updateNewRequest,
} from '../../store/requests';
import {Button} from '../shared/Button';
import {Checkbox} from '../shared/Checkbox';
import {Title} from '../shared/Title';
import {getCompanies} from '../../store/companies';
import {getProfile} from '../../store/profile';
import {GoBackLink} from '../shared/GoBackLink';
import {Navigator} from './Navigator';
import {DeliveryInfo} from './DeliveryInfo';
import {Checklist} from './Checklist';
import {ChooseTariff} from './ChooseTariff';
import {Documents} from './Documents';
import {Legend} from './Legend';
import {Result} from './Result';
import {NEW_REQUEST_LAST_STEP, TRANSACTIONS_TYPES} from '../../consts';

import styles from './NewRequest.module.scss';

const penultStep = NEW_REQUEST_LAST_STEP - 1;

export const NewRequest = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {search} = useLocation();
  const stepRef = useRef();
  const timeoutRef = useRef();
  const {fromDraft} = queryString.parse(search);
  const profile = useSelector(getProfile);
  const newRequest = useSelector(getNewRequest);
  const companies = useSelector(getCompanies);
  const {documents, deliveryInfo, step} = newRequest;
  const updateStep = (value) =>
    dispatch(updateNewRequest({key: 'step', value}));
  const toPrevStep = () => updateStep(step - 1);
  const toNextStep = () => updateStep(step + 1);
  const changeHandler = (key) => (subKey, value) =>
    dispatch(updateNewRequest({key, subKey, value}));
  const onExclude = (docGroup, doc) => {
    dispatch(excludeNewRequestDocs(docGroup, doc));
  };

  const handleSubmit = async () => {
    // return;
    timeoutRef.current = await dispatch(
      saveRequest(fromDraft, {
        type: TRANSACTIONS_TYPES.ORDER_PAYMENT_CA,
        subjectId: profile._id,
      }, navigate)
    );
  };

  useEffect(() => {
    return () => {
      const timeoutId = timeoutRef.current;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const renderStep = () => {
    switch (step) {
      case 0:
        return <ChooseTariff/>;
      case 1:
        return (
          <DeliveryInfo
            deliveryInfo={deliveryInfo}
            onChange={changeHandler('deliveryInfo')}
          />
        );
      case 2:
        return (
          <Documents
            documents={documents}
            onChange={changeHandler('documents')}
            onExclude={onExclude}
          />
        );
      case penultStep:
        return <Checklist/>;
      case NEW_REQUEST_LAST_STEP:
        return <Result/>;
      default:
        return <h2>Oops... something went wrong</h2>;
    }
  };

  useEffect(() => {
    if (fromDraft && profile._id) {
      dispatch(loadDraft(fromDraft));
    }

    return () => {
      if (stepRef.current !== NEW_REQUEST_LAST_STEP && profile._id) {
        console.log({
          step: stepRef.current,
        });
        dispatch(saveDraft(fromDraft));
      }
    };
  }, [dispatch, fromDraft, profile._id]);

  useEffect(() => {
    stepRef.current = step;
  }, [step]);

  return (
    <div>
      <Title>Новая заявка</Title>
      <GoBackLink/>
      <Navigator activeIdx={step} setActiveIdx={updateStep}/>

      <div id="new-req-wrapper" className={styles.Wrapper}>
        {step === NEW_REQUEST_LAST_STEP ? (
          <Result/>
        ) : (
          <>
            <Legend/>
            <div className={styles.Step}>{renderStep()}</div>
            {step === penultStep && (
              <div className={styles.ConfirmArea}>
                <Checkbox
                  checked={checked}
                  onClick={() => setChecked((prev) => !prev)}
                >
                  Достоверность сведений и документов подтверждаю
                </Checkbox>
              </div>
            )}
            <div className={styles.ButtonArea}>
              <Button onClick={toPrevStep} invisible={!step}>
                Назад
              </Button>
              <Button
                filled
                disabled={
                  step === penultStep &&
                  (!checked || !companies.some((c) => c.verified))
                }
                onClick={step === penultStep ? handleSubmit : toNextStep}
              >
                {step === penultStep ? 'Подать заявку' : 'Далее'}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
