import React from 'react';
import { Routes, Route, useNavigate, Navigate, useMatch } from 'react-router-dom';
import { ROUTES, ROLES } from '../consts';
import { CheckRole } from '../components/hocs/Checker';
import { withLoading } from '../components/hocs/withLoading';
import { Tabs } from '../components/shared/Tabs';
import { useSelector } from 'react-redux';
import { getCount, getUnreadDraftsCount } from '../store/requests';
import { HeaderWithCount } from '../components/shared/HeaderWithCount';
import { isProfiledFilled, getProfile } from '../store/profile';
import ExclamationRed from '../assets/ExclamationRed.svg';
import { Reminder } from '../components/shared/Reminder';
import { Drafts } from '../components/Requests/Drafts';
import { RequestsClientCompleted } from '../components/Requests/RequestsClientCompleted';
import { InWork } from '../components/Requests/InWork';
import { NewRequest } from '../components/Requests/NewRequest';
import { RequestDetail } from '../components/Requests/RequestDetail';
import { Button } from '../components/shared/Button';
import styles from '../components/Requests/Requests.module.scss';

export const RequestsDeclarationsClient = () => {

	function lastIndexPathIsUid(lastIndex){

		let url = new URL(window.location.href);
		let lastPath;

		if (Boolean(url?.pathname)){

			lastPath = (lastPath = url.pathname.match(/(?<=\/)[^\/]+/g))?
			lastPath[lastPath.length-(1+lastIndex)] : null;

			if (lastPath && /[0-9]/.test(lastPath) && /[a-z]/.test(lastPath)){
				return true;
			} else return false;

		} else return false;

	}

	const unreadCount = useSelector(getUnreadDraftsCount);
	const countItems = useSelector(getCount);
	const profileFilled = useSelector(isProfiledFilled);
	const profile = useSelector(getProfile);
	const hasEnoughMoney = true; // balance >= ACCOUNT_THRESHOLD;
	const navigate = useNavigate();
	const isNewRequest = useMatch({ path: ROUTES.FULL_REQUESTS_DECLARATIONS_CLIENT_NEW_REQUEST });
	const isInfo = useMatch({ path: ROUTES.FULL_REQUESTS_DECLARATIONS_CLIENT_DETAIL_INFO });
	const isProgress = useMatch({ path: ROUTES.FULL_REQUESTS_DECLARATIONS_CLIENT_DETAIL_PROGRESS });	
	const isRequestDetail = (isInfo || isProgress)? lastIndexPathIsUid(1):lastIndexPathIsUid(0);
	const isClientTabsInfo = (isNewRequest || isRequestDetail)?false:true;

	const TABS = [
	  {
	    title: 'В работе',
	    isPart: true,
	    route: ROUTES.FULL_REQUESTS_DECLARATIONS,
	    href:  "../"+ROUTES.REQUESTS_DECLARATIONS, 
	    replace: true,                                      
	    visible: true,
	  },
	  {
	    title: (
	      <span>
	        Черновики
	        {unreadCount > 0 && (
	          <b style={{ color: '#00A0F9' }}> ({unreadCount})</b>
	        )}
	      </span>
	    ),
	    route: ROUTES.FULL_REQUESTS_DECLARATIONS_CLIENT_DRAFTS,
	    href:  "./"+ROUTES.REQUESTS_DECLARATIONS_CLIENT_DRAFTS,  
	    replace: true,                                      
	    visible: true,
	  },
	  {
	    title: 'Завершенные',
	    route: ROUTES.FULL_REQUESTS_DECLARATIONS_CLIENT_COMPLETED,  
	    href:  "./"+ROUTES.REQUESTS_DECLARATIONS_CLIENT_COMPLETED, 
	    replace: true,                                    
	    visible: true,
	  },
	];

	return (
	    <div>
			{ isClientTabsInfo && (<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ marginTop: '2rem', width: '56%' }}>
					<HeaderWithCount title="Заявки" count={countItems}>
						<div>
							<Tabs tabs={TABS} />
							{hasEnoughMoney ? (
				                <Button
				                  filled
				                  disabled={!profileFilled}
				                  onClick={() => navigate(ROUTES.FULL_REQUESTS_DECLARATIONS_CLIENT_NEW_REQUEST)}
				                >
									Новая заявка
				                </Button>
							) : (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<img
										src={ExclamationRed}
										alt="ExclamationRed"
										style={{ marginRight: '1.2rem' }}
									/>
									{profile.emailVerified
										? 'Недостаточно средств на счете для формирования новой заявки'
										: 'Для формирования новой заявки необходимо подтвердить Ваш email'}
								</div>
							)}
			            </div>
					</HeaderWithCount>
				</div>
				<div style={{ alignSelf: 'flex-end' }}>
					<Reminder />
				</div>
			</div>)}
			<div className={styles.ContentWrapper}>
				<Routes>
					<Route index element={ <CheckRole                            
						roles={{
							[ROLES.USER]: InWork,            
						}} redirect={ROUTES.FULL_REQUESTS}/> }  
					/>
					<Route path={ROUTES.REQUESTS_DECLARATIONS_CLIENT_DRAFTS+"/*"} element={ <CheckRole                            
						roles={{
							[ROLES.USER]: Drafts,            
						}} redirect={ROUTES.FULL_REQUESTS}/> }  
					/>
					<Route path={ROUTES.REQUESTS_DECLARATIONS_CLIENT_COMPLETED+"/*"} element={ <CheckRole                            
						roles={{
							[ROLES.USER]: RequestsClientCompleted,            
						}} redirect={ROUTES.FULL_REQUESTS}/> }  
					/>					
			        <Route path={ROUTES.REQUESTS_DECLARATIONS_CLIENT_NEW_REQUEST+"/*"} element={ <CheckRole                            
			          roles={{
			            [ROLES.USER]: NewRequest,            
			          }} redirect={ROUTES.FULL_REQUESTS}/> }  
			        /> 
			        { isRequestDetail && (<Route path={ROUTES.REQUESTS_DECLARATIONS_CLIENT_DETAIL+"/*"} element={ <CheckRole                            
			          roles={{
			            [ROLES.USER]: RequestDetail,            
			          }} redirect={ROUTES.FULL_REQUESTS}/> }  
			        />)}

					{/*<Route path="*" element={<Navigate to={ROUTES.INDEX_REQUESTS} />} />*/}      
				</Routes>
			</div>
	    </div>
	);

}