import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROLES, ROUTES, WARNING_MESSAGE } from '../consts';
import { getProfile, getRole, isProfiledFilled } from '../store/profile';
import { getCompanies, getCompanyOffer, getbankAccounts } from '../store/companies';
import { Title } from '../components/shared/Title';
import { Tabs } from '../components/shared/Tabs';
import { Personal } from '../components/Personal';
import { Documents } from '../components/Documents';
import { SubAccounts } from '../components/SubAccounts/';
import { withLoading } from '../components/hocs/withLoading';
import { CheckRole } from '../components/hocs/Checker';
import { Company } from '../components/Company';
import styles from './Profile.module.sass';
import classnames from 'classnames';

export const Profile = withLoading(() => {

  const profile = useSelector(getProfile);
  const profileIsFilled = useSelector(isProfiledFilled);
  const companies = useSelector(getCompanies);
  const companyOffer = useSelector(getCompanyOffer);
  const bankAccounts = useSelector(getbankAccounts);   


  const showWarningMessage = () => {
    if (companies?.length && !companies.every(({verified})=>verified)){
        return {visible: true, ...WARNING_MESSAGE.COMPANY_IS_NOT_VERIFIED };      
    } else if (!companies?.length){
      return {visible: true, ...WARNING_MESSAGE.COMPANY_IS_NOT_ADDED };      
    } else if (!bankAccounts?.length){
      return {visible: true, ...WARNING_MESSAGE.ACCOUNT_IS_NOT_ADDED };
    } else if (profile.role === "logist" && !companyOffer){
      return {visible: true, ...WARNING_MESSAGE.COMPANY_HAS_NOT_OFFER };
    }
  }

  function getPermissions(chapter, rolesArr, profile){

    if (profile.parentProfile && profile.sections.includes(chapter) && rolesArr.includes(profile.role)){
      return true;
    } else if (profile.parentProfile && !profile.sections.includes(chapter)){
      return false;
    } else if (!profile.parentProfile && rolesArr.includes(profile.role)){
      return true;
    } else {
      return false;
    }

  }

  const TABS = [
    {
      title: 'Личные данные',      
      route: ROUTES.FULL_PROFILE_PERSONAL,
      href: "./"+ROUTES.PROFILE_PERSONAL,
      withWarning: {visible: profileIsFilled? false : true, ...WARNING_MESSAGE.PROFILE_IS_NOT_FILLED},
      visible: true
    },
    {
      title: 'Доступы',
      route: ROUTES.FULL_PROFILE_SUB_ACCOUNTS,
      href: "./"+ROUTES.PROFILE_SUB_ACCOUNTS,
      visible: getPermissions("accesses", [ROLES.USER, ROLES.DECLARANT, ROLES.LOGIST], profile),
    },
    {
      title: 'Моя компания',
      route: ROUTES.FULL_PROFILE_COMPANY,
      href: "./"+ROUTES.PROFILE_COMPANY,
      withWarning: showWarningMessage(),
      visible: getPermissions("company", [ROLES.USER, ROLES.DECLARANT, ROLES.LOGIST], profile),
    },    
  ];

  return (
    <>
    {profile.emailVerified && (<main>
      <Title>
        Профиль{' '}
        {{user:"пользователя", declarant: "декларанта", logist: "логиста", admin: "администратора"}[profile.role]}
      </Title>
      <Tabs tabs={TABS.filter(({ visible }) => visible)} />
      <Routes>
        <Route path={ROUTES.PROFILE_PERSONAL} element={<Personal />} />
        <Route path={ROUTES.PROFILE_SUB_ACCOUNTS} element={<CheckRole redirect={ROUTES.PROFILE_PERSONAL} roles={{ [ROLES.USER]: SubAccounts, [ROLES.DECLARANT]:SubAccounts, [ROLES.LOGIST]: SubAccounts }} />}/> 
        <Route path={ROUTES.PROFILE_COMPANY+"/*"}  element={ <CheckRole redirect={ROUTES.PROFILE_PERSONAL} roles={{ [ROLES.USER]:Company, [ROLES.DECLARANT]:Company, [ROLES.LOGIST]: Company }} /> } />        
        <Route path="*" element={<Navigate to={ROUTES.PROFILE_PERSONAL} />} />   
      </Routes>
    </main>)}
    {!profile.emailVerified && (
      <div className={classnames(styles.wrap_message)}>
        <div className={classnames(styles.thanks)}>Спасибо за регистрацию!</div>
        <div className={classnames(styles.descr)}>На указанный email отправлено письмо подтверждения.</div>
        <div className={classnames(styles.achive)}>Подтвердите почту пожалуйста.</div>         
      </div>)}
    </>
  );
});
