import React from 'react';

import { UploadedDocument } from './UploadedDocument';
import { getDocName } from '../../../utils';

import styles from './UploadAreaSingle.module.scss';

export const UploadAreaSingle = ({
  children,
  files,
  id,
  onExclude,
  disabled,
}) => {
  return (
    <div className={styles.DocsViewArea}>
      <div className={styles.FilesArea}>
        <div className={styles.FilesArea__PackageName}>{children}</div>
        <div className={styles.FilesArea__UploadFiles}>
          {files?.map((doc, idx) => (
            <UploadedDocument
              key={doc + `${idx}`}
              link={doc}
              index={idx}
              name={getDocName({ doc, id, idx })}
              onExclude={() => onExclude(id, doc)}
              disabled={disabled}
              single
            />
          ))}
        </div>
      </div>
    </div>
  );
};
