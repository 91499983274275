export const spreadActivityTable = (arr) => {
	if (typeof arr === "object" && arr.length){
		return arr.map((item)=> {
			if ("offers" in item) {
				return {total: item.offers, id: item._id};
			} else if ("messages" in item){
				return {total: item.messages, id: item._id};
			} else return {id: item._id};
		});		
	}
	return [];

}