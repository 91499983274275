import {
  SET_CURRENT_SYSTEM_ACCOUNT,
  UPDATE_EDITOR_ITEM_VALUE,
  UPDATE_EDITOR_ITEMS_VALUE,
  CLEAR_EDITOR_ITEMS_VALUE } from './actions';

const initialState = {
  current:{
    bankAccount: undefined,
    bik: undefined,
    name: undefined,
    corrAccount: undefined,
    inn: undefined,
    kpp: undefined    
  },
  editor:{
    bankAccount: {value:false, correct: true},
    bik: {value:false, correct: true},
    name: {value:false, correct: true},
    corrAccount: {value:false, correct: true},
    inn: {value:false, correct: true},
    kpp: {value:false, correct: true}    
  }
};



export const systemAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_SYSTEM_ACCOUNT:
      return { ...state, current: {...Object.fromEntries(Object.entries(state.current).map(([k,v])=>[k,action.payload[k]]))}}  
    case UPDATE_EDITOR_ITEM_VALUE:
      return {...state, editor: (action.payload.clearPush)
              ?{...initialState.editor, [action.payload.item]:action.payload.state}
              :{...state.editor, [action.payload.item]:action.payload.state}
            }
    case UPDATE_EDITOR_ITEMS_VALUE:
      return {...state,
        editor:{...state.editor, ...Object.fromEntries(Object.entries(action.payload).map(([key, val])=>[key,{value:val, correct: true}]))}
      }
    case CLEAR_EDITOR_ITEMS_VALUE:
      return {...state, editor: initialState.editor}  
    default:
      return state;
  }
};
