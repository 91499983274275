import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import {
  getLoading,
  getTransactions,
  getCount,
  applyFilters,
  loadAllTransactions,
  loadSystemAccounts,
  getTotalFundsReceipt,
  getSystemAccountBalance,
  getTempAccountBalance,
  getOutPaymentsSum,
  getPaymentsDebt,
} from '../../store/transactions';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { BalancesRow } from './BalancesRow';
import { getAllTransactionsFilters } from '../../store/filters';
import { TYPE_OPTIONS } from '../../configs/transactions';
import { formatMoney } from '../../utils';
import { TRANSACTIONS_TYPES } from '../../consts';
import { Input } from '../shared/Input';

export const SystemAccount = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const totalFundsReceipt = useSelector(getTotalFundsReceipt);
  const systemAccountBalance = useSelector(getSystemAccountBalance);
  const tempAccountBalance = useSelector(getTempAccountBalance);
  const outPaymentsSum = useSelector(getOutPaymentsSum);
  const paymentsDebt = useSelector(getPaymentsDebt);
  const transactions = useSelector(getTransactions);
  const countItems = useSelector(getCount);
  const filters = useSelector(getAllTransactionsFilters);
  
  const {
    ORDER_REFUND_CA,
    ORDER_RECEIPT_DA,
    ORDER_RECEIPT_PA,
    ORDER_RECEIPT_SA,
  } = TRANSACTIONS_TYPES;
  const receiver = [ORDER_REFUND_CA, ORDER_RECEIPT_DA, ORDER_RECEIPT_PA];

  const SYSTEM_ACCOUNT_TABLE_HEADER = [
    {
      label: 'ID',
      value: '_id',
    },
    {
      label: 'Дата операции',
      value: 'createdAt',
      render: (value) =>
        value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : 'нет данных',
    },
    {
      label: 'Тип операции*',
      value: 'type',
      render: (value) => TYPE_OPTIONS[value]?.label,
    },
    {
      label: 'Пользователь',
      value: 'user',
      renderItem: true,
      render: (item) =>
        item.type === ORDER_RECEIPT_SA ? (
          <div>&ndash;</div>
        ) : receiver.includes(item.type) ? (
          <div>
            {item.receiver?.name} {item.receiver?.surname}
          </div>
        ) : (
          <div>
            {item.agent?.name} {item.agent?.surname}
          </div>
        ),
    },
    {
      label: 'Сумма операции',
      value: 'sum',
      render: formatMoney,
    },
  ];

  const footnote = (
    <div>
      * СК - счет клиента, СД - счет декларанта, СП - счет партнера, ТС -
      темпоральный счет, СС - счет системы
    </div>
  );

  useEffect(() => {
    dispatch(loadSystemAccounts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadAllTransactions());
  }, [dispatch]);

  return (
    <main>      
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0 0 1.5rem 0',
          }}
        >
          Выберите промежуток времени с
          <div style={{ width: '10rem', margin: '0 0.5rem' }}>
            <Input
              type="date"
              value={filters.fromDate}
              onChange={(e) => {
                dispatch(applyFilters({ ...filters, fromDate: e.target.value }));
              }}
            />
          </div>
          по
          <div style={{ width: '10rem', margin: '0 0 0 0.5rem' }}>
            <Input
              type="date"
              value={filters.toDate}
              onChange={(e) => {
                dispatch(applyFilters({ ...filters, toDate: e.target.value }));
              }}
            />
          </div>
        </div>
        <BalancesRow
          totalFundsReceipt={totalFundsReceipt}
          systemAccountBalance={systemAccountBalance}
          tempAccountBalance={tempAccountBalance}
          outPaymentsSum={outPaymentsSum}
          paymentsDebt={paymentsDebt}
        />
      </React.Fragment>      
      <div style={{ marginTop: '3rem' }}>
        {transactions?.length ? (
          <Table
            loading={loading}
            items={transactions}
            headers={SYSTEM_ACCOUNT_TABLE_HEADER}
            countItems={countItems}
            filters={filters}
            footnote={footnote}
            applyFilters={(filters) => dispatch(applyFilters(filters))}
          />
        ) : (
          <div style={{ marginTop: '-1rem' }}>
            <Message type="info">
              В системе пока нет сведений об операциях по счетам системы
            </Message>
          </div>
        )}
      </div>
    </main>
  );
};
