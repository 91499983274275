import React from 'react';
import classnames from 'classnames';

import paginationLeft from '../../../assets/paginationLeft.svg';
import paginationRight from '../../../assets/paginationRight.svg';

import styles from './styles.module.scss';

export const Pagination = ({ countItems, filters, applyFilters }) => {
  const currentPage = filters.offset ? filters.offset / filters.limit + 1 : 1;
  const pagesAmount =
    countItems % filters.limit
      ? Math.floor(countItems / filters.limit) + 1
      : countItems / filters.limit;
  const manyPages = pagesAmount > 18 ? true : false;

  const renderItem = (_, idx) => {
    const num = idx + 1;
    const current = num === currentPage;
    return (
      <div
        className={classnames(styles.Pagination__Item, {
          [styles['Pagination__Item--current']]: current,
        })}
        key={idx}
        onClick={() =>
          !current && applyFilters({ ...filters, offset: idx * filters.limit })
        }
      >
        {num}
      </div>
    );
  };

  return (
    pagesAmount > 1 && (
      <div
        className={classnames(styles.Pagination, {
          [styles['Pagination--manyPages']]: manyPages,
        })}
      >
        <div
          className={styles.Pagination__Item}
          onClick={() =>
            currentPage > 1 &&
            applyFilters({ ...filters, offset: filters.offset - filters.limit })
          }
        >
          <img src={paginationLeft} alt="<" />
        </div>

        {new Array(pagesAmount).fill(null).map(renderItem)}

        <div
          className={styles.Pagination__Item}
          onClick={() =>
            currentPage < pagesAmount &&
            applyFilters({ ...filters, offset: filters.offset + filters.limit })
          }
        >
          <img src={paginationRight} alt=">" />
        </div>
      </div>
    )
  );
};
