import React from 'react';

import { ROUTES } from '../consts';
import { withLoading } from '../components/hocs/withLoading';
import { Tabs } from '../components/shared/Tabs';
import { Title } from '../components/shared/Title';
import { Promocodes } from '../components/Promocodes';

export const Marketing = withLoading(() => {
  return (
    <main>
      <Title>
        Маркетинг{' '}
        <span role="img" aria-label="trend">
          💹
        </span>
      </Title>
      <Tabs
        tabs={[
          {
            title: 'Промокоды',
            route: ROUTES.FULL_MARKETING,
            visible: true,
          },
        ]}
      />
      <Promocodes />
    </main>
  );
});
