import React from 'react';

import { PersonalPhoto } from './PersonalPhoto';
import { PersonalInfo } from './PersonalInfo';
import styles from './Personal.module.scss';

export const Personal = () => (
  <div className={styles.Personal}>
    <PersonalPhoto />
    <PersonalInfo />
  </div>
);
