import React from 'react';

import { NavigatorItem } from './NavigatorItem';
import { NEW_REQUEST_LAST_STEP } from '../../consts';

import styles from './Navigator.module.scss';

const OPTIONS = [
  {
    label: 'Выбор тарифа',
  },
  {
    label: 'Инфо об отправке',
  },
  {
    label: 'Документы',
  },
  {
    label: 'Проверка заявки',
  },
  {
    label: 'Подача заявки',
  },
];

export const Navigator = ({ activeIdx, setActiveIdx }) => {
  return (
    <div className={styles.Navigator}>
      {OPTIONS.map((option, idx) => (
        <NavigatorItem
          key={idx}
          number={idx + 1}
          label={option.label}
          active={activeIdx === idx}
          lastItem={OPTIONS.length === idx + 1}
          onClick={() => idx !== NEW_REQUEST_LAST_STEP && setActiveIdx(idx)}
        />
      ))}
    </div>
  );
};
