import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRequest, updateItem } from '../../../store/requests';
import { Accordion } from '../../shared/Accordion';
import { DeliveryInfo } from '../DeliveryInfo';
import { DocumentsRequest } from './DocumentsRequest';

export const RequestDetailInfo = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const item = useSelector(getRequest);
  const changeHandler = (key, value) => dispatch(updateItem({ key, value }));

  return (
    <div>
      <Accordion
        fadedToggler
        title="Данные по заявке"
        open={open}
        onToggle={() => setOpen((prevOpen) => !prevOpen)}
      >
        {item && (
          <>
            <DeliveryInfo
              deliveryInfo={{ ...item, company: item?.company?.name }}
              onChange={changeHandler}
              viewOnly
            />
            <DocumentsRequest disabled documents={item} viewOnly />
          </>
        )}
      </Accordion>
    </div>
  );
};
