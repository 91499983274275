import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {PAGE_PRICE, ROUTES} from '../../consts';
import { getNewRequest, getSelectedTariff } from '../../store/requests';
import GreenCheckmark from '../../assets/GreenCheckmark.svg';
import { Title } from '../shared/Title';
import { Button } from '../shared/Button';
import { getTariffHash } from '../../store/tariffs';

import styles from './Result.module.scss';
import legentStyles from './Legend.module.scss';

export const Result = () => {
  const navigate = useNavigate();
  const request = useSelector(getNewRequest);
  const selectedTariff = useSelector(getSelectedTariff);
  const tariffs = useSelector(getTariffHash);

  const { deliveryInfo } = request;

  const { price } = selectedTariff || { price: 0 };

  const finalPrice = useMemo(() => {
    let res = price;

    if (deliveryInfo?.speedUp) {
      res += tariffs?.fasterCustom?.price;
    }

    if (deliveryInfo?.withSupport) {
      res += tariffs?.supportedCustom?.price;
    }

    if (deliveryInfo.withGoodsLookup) {
      res += tariffs.goodsLookup?.price || 0;
    }
    if (deliveryInfo.withPermissionDocs) {
      res += tariffs.permissionDocs?.price || 0;
    }
    if (deliveryInfo.withPresense) {
      res += tariffs.presense?.price || 0;
    }
    if (deliveryInfo.withLoadingControl) {
      res += tariffs.loadingControl?.price || 0;
    }
    if (deliveryInfo.withFitoSanControl) {
      res += tariffs.fitoSanControl?.price || 0;
    }
    if (deliveryInfo.withDocsTransfer) {
      res += tariffs.docsTransfer?.price || 0;
    }

    return res;
  }, [deliveryInfo, price, tariffs]);

  return (
    <div className={styles.Result}>
      <div className={styles.Result__Content}>
        <Title>Заявка № {request.number} принята</Title>
        <div>
          <div className={legentStyles.Cost__Value}>
            <span className={legentStyles.Cost__Number}>{request.price}₽</span>
            {request.discount > 0 && (
              <span className={legentStyles.PreviousPrice}>{finalPrice}₽</span>
            )}
          </div>
          <div className={legentStyles.Cost__Text}>+ стоимость доп. листов ({PAGE_PRICE} ₽/шт.)</div>
        </div>
      </div>
      <div className={styles.Checkmark}>
        <img src={GreenCheckmark} alt="checkmark" />
      </div>
      <Button filled onClick={() => navigate(ROUTES.REQUESTS)}>
        Ко всем заявкам
      </Button>
    </div>
  );
};
