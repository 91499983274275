import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useParams} from 'react-router-dom';

import {
  getLoading,
  getTransactions,
  getAccounts,
  getCount,
  applyFilter,
  loadTransactions
} from '../../store/transactions';
import { Message } from '../shared/Message';
import { getAccountTransactionsFilters } from '../../store/filters';
import { Table } from '../shared/Table';
import { Title } from '../shared/Title';
import { GoBackLink } from '../shared/GoBackLink';
import { ACTION_OPTIONS } from '../../configs/transactions';
import { formatMoney } from '../../utils';
import { FILTERS, TRANSACTIONS_TYPES } from '../../consts';

export const AccountsUserHistory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const transactions = useSelector(getTransactions);
  const countItems = useSelector(getCount);
  const filters = useSelector(getAccountTransactionsFilters);
  const filtersKey = FILTERS.ACCOUNT_TRANSACTIONS;
  const user = useSelector(getAccounts).find((user) => user._id === id);  
  const ACCOUNT_USER_TABLE_HEADER = [
    {
      label: 'ID',
      value: '_id',
    },
    {
      label: 'Операция',
      value: 'action',
      renderItem: true,
      render: (item) =>
        item?.type !== TRANSACTIONS_TYPES.ADD_PAYMENT_CADA ||
        id !== item?.receiver
          ? ACTION_OPTIONS[item?.action]?.label
          : 'Пополнение',
    },
    {
      label: 'Сумма операции',
      value: 'sum',
      renderItem: true,
      render: (item) =>
        item.action === 'withdrawals' &&
        (item.type !== TRANSACTIONS_TYPES.ADD_PAYMENT_CADA ||
          id !== item.receiver) ? (
          <div>- {formatMoney(item.sum)}</div>
        ) : (
          <div>+ {formatMoney(item.sum)}</div>
        ),
    },
    {
      label: 'Дата операции',
      value: 'createdAt',
      sortable: true,
      render: (value) =>
        value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : 'нет данных',
    },
    {
      label: 'Баланс счета',
      value: 'balance',
      renderItem: true,
      render: (item) =>
        formatMoney(
          item?.action === 'refill' ||
            (item.type === TRANSACTIONS_TYPES.ADD_PAYMENT_CADA &&
              id === item.receiver)
            ? item.receiverBalance
            : item.agentBalance
        ),
    },
  ];

  useEffect(() => {
    dispatch(loadTransactions(id, filters));
  }, [dispatch, id, filters]);  

  return (
    <main>
      <Title>
        История счета {user?.name} {user?.surname}
      </Title>
      <GoBackLink />
      <div style={{ marginTop: '3rem' }}>
        {transactions?.length ? (
          <Table
            loading={loading}
            items={transactions}
            headers={ACCOUNT_USER_TABLE_HEADER}
            countItems={countItems}
            filters={filters}
            applyFilters={(filters) =>
              dispatch(applyFilter(id, filtersKey, filters))
            }
          />
        ) : (
          <div style={{ marginTop: '-1rem' }}>
            <Message type="info">Операции по счету не проводились</Message>
          </div>
        )}
      </div>
    </main>
  );
};
