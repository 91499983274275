import React from 'react';

import { AccountsTable } from './AccountsTable';
import { getAccountsLogistsFilters } from '../../store/filters';
import { FILTERS } from '../../consts';

export const AccountsLogists = () => (
  <AccountsTable
    getFilters={getAccountsLogistsFilters}
    filtersKey={FILTERS.ACCOUNTS_LOGISTS}
    messageSubject="логистов"
  />
);
