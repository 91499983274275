import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { TYPE_OPTIONS } from '../../../configs/request';
import {
  getCount,
  getLoading,
  getPool,
  applyCompletedFilters,
  loadCompleted,
} from '../../../store/requests';
import { formatMoney } from '../../../utils';
import { Message } from '../../shared/Message';
import { Table } from '../../shared/Table';
import { getRequestCompletedFilters } from '../../../store/filters';

const REQUEST_HEADERS = [
  {
    label: '№ Заявки',
    value: 'number',
    sortable: true,
    render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
  },
  {
    label: 'Дата завершения заявки',
    value: 'updatedAt',
    render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    sortable: true,
  },
  {
    label: 'Тип перевозки',
    value: 'type',
    render: (value) => TYPE_OPTIONS[value]?.label,
  },
  {
    label: 'Компания заказчик',
    value: 'company',
    render: (value) => value.name,
  },
  {
    label: 'Стоимость заявки',
    value: 'price',
    render: formatMoney,
  },
  {
    label: '',
    value: 'rowActions',
    render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
  },
];

export const Completed = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pool = useSelector(getPool);
  const loading = useSelector(getLoading);
  const filters = useSelector(getRequestCompletedFilters);
  const countItems = useSelector(getCount);

  useEffect(() => {
    dispatch(loadCompleted(filters));
  }, [dispatch, filters]);

  return (
    <div>
      {pool.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`/requests/declarant/${_id}/info`);
          }}
          loading={loading}
          items={pool}
          headers={REQUEST_HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) => dispatch(applyCompletedFilters(filters))}
        />
      ) : (
        <Message type="info">У Вас пока нет завершенных заявок</Message>
      )}
    </div>
  );
};
