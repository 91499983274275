import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from '../../../consts';
import { getToken } from '../../../store/auth';
import { Join } from '../../../pages/Join';
import { AuthGate } from '../../../pages/AuthGate';
import { BusinessClub } from '../../../pages/BusinessClub';
import { Certification } from '../../../pages/Certification';
import { Logistics } from '../../../pages/Logistics';


export const CheckAuth = () => {
  const token = useSelector(getToken);
  return token ? <Outlet /> : <Navigate to={ROUTES.FULL_AUTH_GATE_LOGIN} />;
};
