import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getProfile } from '../../store/profile';
import { getDeclarant, loadDeclarant } from '../../store/verification';
import { PassportFormCheck } from './PassportFormCheck';
import { Title } from '../shared/Title';
import { GoBackLink } from '../shared/GoBackLink';
import { PersonalInformation } from '../shared/PersonalInformation';
import { EducationFormCheck } from './EducationFormCheck';
import { WorkFormCheck } from './WorkFormCheck';

export const VerifyProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const declarant = useSelector(getDeclarant);
  const { id } = useParams();

  useEffect(() => {
    if (profile._id) {
      dispatch(loadDeclarant(id));
    }
  }, [dispatch, id, profile._id]);

  return declarant ? (
    <main>
      <Title>Верификация пользователя</Title>
      <GoBackLink />
      <PersonalInformation profile={declarant} permitEdit={false} />
      <PassportFormCheck passport={declarant.passport} declarantId={id} />
      <EducationFormCheck education={declarant.education} declarantId={id} />
      <WorkFormCheck work={declarant.work} declarantId={id} />
    </main>
  ) : null;
};
