import React from 'react';

import { EDUCATION_HIGHEST } from '../../configs/education';
import { Input } from '../shared/Input';
import { Checkbox } from '../shared/Checkbox';

import styles from './HighestEducationCheck.module.scss';

export const HighestEducationCheck = ({ education }) => (
  <div className={styles.HighestEducation}>
    <div className={styles.HighestEducation__Right}>
      <div className={styles.InputRow}>
        <Input
          value={education.institution}
          tooltip={education.errors?.institution}
          disabled
          lightgrey
        />
      </div>
      <div className={styles.HighestEducation__Years}>
        <div className={styles.Doubled}>
          <Input
            value={education.startYear}
            tooltip={education.errors?.startYear}
            disabled
            lightgrey
          />
        </div>
        <div className={styles.Doubled}>
          <Input value={education.finishYear} disabled lightgrey />
        </div>
      </div>
    </div>
    <div className={styles.HighestEducation__Left}>
      <div className={styles.InputRow}>
        <Input
          value={education.degree}
          tooltip={education.errors?.degree}
          disabled
          lightgrey
        />
      </div>
      <div className={styles.Aligned}>
        <Checkbox checked={education.notFinished}>
          {EDUCATION_HIGHEST.notFinished.label}
        </Checkbox>
      </div>
    </div>
  </div>
);
