import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDeclarant,
  saveRejectionReasons,
  updateSectionStep,
  setValue,
} from '../../store/verification';
import { Button } from '../shared/Button';
import { Survey } from '../shared/Survey';
import { DOCUMENTS_STEPS } from '../../consts';

import styles from './ButtonCheckBlock.module.scss';

export const ButtonCheckBlock = ({
  declarantId,
  section,
  config,
  selectPlaceholder,
}) => {
  const { FAILED_REVIEW, PASSED_REVIEW, IN_REVIEW } = DOCUMENTS_STEPS;
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const declarant = useSelector(getDeclarant);

  const passportStep = declarant.passport.step;
  const educationStep = declarant.education.step;
  const workStep = declarant.work.step;
  const stepsArray = [passportStep, educationStep, workStep];
  const disabled =
    declarant && declarant[section]?.step === PASSED_REVIEW ? true : false;

  const sendRejectionReasons = (rejections) => {
    dispatch(saveRejectionReasons(rejections, declarantId, section));
    dispatch(updateSectionStep(declarantId, section, FAILED_REVIEW));
  };

  const verify = () => {
    dispatch(updateSectionStep(declarantId, section, PASSED_REVIEW));
  };

  if (stepsArray.every((step) => step !== IN_REVIEW) && declarant.onChecking) {
    dispatch(setValue(declarantId, 'onChecking', false));
  }
  if (
    stepsArray.every((step) => step === PASSED_REVIEW) &&
    !declarant.verified
  ) {
    dispatch(setValue(declarantId, 'verified', true));
  }

  return (
    <>
      <div className={styles.Block}>
        <div className={styles.Block__LeftButton}>
          <Button medium disabled={disabled} onClick={() => setModalOpen(true)}>
            Отказать
          </Button>
        </div>
        <Button filled disabled={disabled} onClick={verify}>
          Верифицировать
        </Button>
      </div>
      <Survey
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        options={config}
        onSubmit={sendRejectionReasons}
        title="Отказ в верификации"
        selectPlaceholder={selectPlaceholder}
        textPlaceholder="Комментарий"
        submitButtonText="Отказать"
        message="Информация отправлена"
      />
    </>
  );
};
