import { createDocsQuery } from '../../api/docsQueries';
import { omit } from 'lodash';

export const saveDocsQuery = (queries, requestId, docsSection) => async () => {
  try {
    const queryItems = queries.map((item) =>
      omit({ docName: item.option, comment: item.text }, ['key'])
    );
    const payload = {
      requestId: requestId,
      docsSection: docsSection,
      queryItems,
    };

    await createDocsQuery(payload);
  } catch (error) {
    console.log(error);
  }
};
