import React, { useState, useRef, useEffect } from 'react';
import styles from './TextArea.module.sass';
import classnames from 'classnames';

export const TextArea = ({name, placeholder, textareaName, rule, areaValue, setStateCb, maxlength }) => { 

	const wrapArea = useRef(null);
	const handlerChange = (event) => {

		let textarea, regExp;

		if ( textarea = event.target.closest("."+classnames(styles.textarea))){

			regExp = {
				english: /^[0-9a-zA-Z,.'\-_№\\\/() :&*]+$/g,
				number: /^\d+$/g,
				date: /^\d{4}[ \/\.]\d{1,2}[ \/\.]\d{1,2}$|^\d{1,2}[ \/\.]\d{1,2}[ \/\.]\d{4}$/g,
				russian: /^[а-яА-Я0-9a-zA-Z,ё.'\-_№\\\/() :&\*\%\!\+\r\n]+$/g
			}[rule];			

			if ((!regExp.test(textarea.value) && /.+/g.test(textarea.value)) || /^\s+$/g.test(textarea.value)){

				if (setStateCb){
					setStateCb({
						value: /^\s+$/g.test(textarea.value)?false:null,
						correct: /^\s+$/g.test(textarea.value)?true:false
					});
				}				

			} else {

				if (setStateCb){
					setStateCb({
						value: (textarea.value)? textarea.value : false,
						correct: true
					});
				}				
								
			}		
		}
	}

	return (
		<div className={classnames(styles.wrap_textarea, {[styles["invalid"]]: !areaValue.correct})}
			onChange={handlerChange}			
			ref={wrapArea}
		>
			<textarea name={name} cols="30" rows="10" placeholder={placeholder}
				className={classnames(styles.textarea)}
				value={areaValue.value === false?"":areaValue.value}
				maxlength={maxlength}
			></textarea>
			{(textareaName) && (<div className={classnames(styles.textarea_name)}>{textareaName}</div>)}								
		</div>
	);
}