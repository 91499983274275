import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import {
  getRequest,
  updateItem,
  updateRequest,
  excludeFormedRequestDocs,
} from '../../../store/requests';
import { Button, UploadButton } from '../../shared/Button';
import { Input } from '../../shared/Input';
import { UploadAreaSingle } from '../../shared/UploadArea/UploadAreaSingle';

import styles from './Declaration.module.scss';

export const Declaration = () => {
  const dispatch = useDispatch();
  const item = useSelector(getRequest);
  const { paymentDone, declaration, declarationSaved } = item;
  const onChange = (key, value) => dispatch(updateItem({ key, value }));
  const onExclude = (docGroup, doc) => {
    dispatch(excludeFormedRequestDocs(docGroup, doc));
  };
  const saveUploadedDeclaration = () => {
    onChange('declarationSaved', true);
    dispatch(updateRequest);
    toast.success('Декларация сохранена');
  };

  return (
    <div className={styles.Declaration}>
      {!paymentDone ? (
        <div>Здесь вы сможете загрузить декларацию</div>
      ) : (
        <>
          <div className={styles.ExtraPages}>
            <div>Количество доп.листов:</div>
            <Input
              placeholder="Укажите количество доп.листов"
              disabled={item.declarationSaved}
              type="number"
              min="0"
              value={item.extraPages || 0}
              onChange={(e) => onChange('extraPages', e.target.value)}
            />
          </div>
          <UploadAreaSingle
            files={declaration}
            id="declaration"
            onUpload={(value) =>
              onChange('declaration', [...declaration, ...value])
            }
            onExclude={onExclude}
            disabled={item.declarationSaved}
          />
        </>
      )}

      <div className={styles.ButtonArea}>
        <Button
          filled
          disabled={!declaration.length || declarationSaved}
          onClick={saveUploadedDeclaration}
        >
          Сохранить
        </Button>
        <div style={{ marginLeft: '1rem' }}>
          <UploadButton
            filled
            disabled={!paymentDone || declarationSaved}
            id={'declaration'}
            onUpload={(value) =>
              onChange('declaration', [...declaration, ...value])
            }
          >
            Загрузить
          </UploadButton>
        </div>
      </div>
    </div>
  );
};
