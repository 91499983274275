import { omit } from 'lodash';

import {
  createRequest,
  createDraft,
  deleteDraft as deleteDraftApi,
  fetchActivePromocodes,
  getAllRequests,
  getAllRequestsDone,
  getAllRequestsInProgress,
  getRequests,
  getRequest,
  getPool,
  getInProgress,
  getCompleted,
  getDraft as getDraftApi,
  getDrafts as getDraftsApi,
  takeRequest as takeRequestApi,
  updateDraft,
  updateRequest as updateRequestApi,
  getRequestFromAll,
} from '../../api';
import { generateTransaction } from '../../utils';
import history from '../../components/App/history';
import {
  FILTERS,
  ROUTES,
  SYSTEM_PROFILE_ID,
  PAGE_PRICE,
  TRANSACTIONS_TYPES,
  DECLARANT_SHARE     // PARTNER_RATE
} from '../../consts';

import {
  getDocuments,
  getNewRequest,
  getRequest as selectRequest,
  getDrafts,
  getDraftsCount,
  getUnreadDraftsCount,
} from './selectors';
import {
  updateFilters,
  getRequestListFilters,
  getRequestInProgressFilters,
  getAllRequestsFilters,
  getAllRequestsDoneFilters,
  getAllRequestsInProgressFilters,
  getDraftFilters,
} from '../filters';
import { getTariffHash } from '../tariffs';

export const UPDATE_NEW_REQUEST = '@requests/UPDATE_NEW_REQUEST';
export const RESET_FORM = '@requests/RESET_FORM';
export const CLEAN_FORM = '@requests/CLEAN_FORM';
export const SET_ITEM = '@requests/SET_ITEM';
export const UPDATE_ITEM = '@requests/UPDATE_ITEM';
export const SET_FILTERS = '@requests/SET_FILTERS';
export const SET_PAGE = '@requests/SET_PAGE';
export const SET_LOADING = '@requests/SET_LOADING';
export const LOAD_CLIENT_REQUESTS_REQUEST =
  '@requests/LOAD_CLIENT_REQUESTS_REQUEST';
export const LOAD_CLIENT_REQUESTS_SUCCESS =
  '@requests/LOAD_CLIENT_REQUESTS_SUCCESS';
export const LOAD_CLIENT_REQUESTS_FAILURE =
  '@requests/LOAD_CLIENT_REQUESTS_FAILURE';
export const LOAD_POOL_REQUEST = '@requests/LOAD_POOL_REQUEST';
export const LOAD_POOL_SUCCESS = '@requests/LOAD_POOL_SUCCESS';
export const LOAD_POOL_FAILURE = '@requests/LOAD_POOL_FAILURE';
export const LOAD_DRAFTS_REQUEST = '@requests/LOAD_DRAFTS_REQUEST';
export const LOAD_DRAFTS_SUCCESS = '@requests/LOAD_DRAFTS_SUCCESS';
export const LOAD_DRAFTS_FAILURE = '@requests/LOAD_DRAFTS_FAILURE';
export const LOAD_IN_PROGRESS_REQUEST = '@requests/LOAD_IN_PROGRESS_REQUEST';
export const LOAD_IN_PROGRESS_SUCCESS = '@requests/LOAD_IN_PROGRESS_SUCCESS';
export const LOAD_IN_PROGRESS_FAILURE = '@requests/LOAD_IN_PROGRESS_FAILURE';
export const LOAD_COMPLETED_REQUEST = '@requests/LOAD_COMPLETED_REQUEST';
export const LOAD_COMPLETED_SUCCESS = '@requests/LOAD_COMPLETED_SUCCESS';
export const LOAD_COMPLETED_FAILURE = '@requests/LOAD_COMPLETED_FAILURE';
export const LOAD_ALL_REQUESTS_REQUEST = '@requests/LOAD_ALL_REQUESTS_REQUEST';
export const LOAD_ALL_REQUESTS_SUCCESS = '@requests/LOAD_ALL_REQUESTS_SUCCESS';
export const LOAD_ALL_REQUESTS_FAILURE = '@requests/LOAD_ALL_REQUESTS_FAILURE';

export const loadPoolRequest = () => ({
  type: LOAD_POOL_REQUEST,
});

export const cleanForm = () => ({
  type: CLEAN_FORM,
});

export const resetForm = (payload) => ({
  type: RESET_FORM,
  payload,
});

export const loadPoolSuccess = (payload) => ({
  type: LOAD_POOL_SUCCESS,
  payload,
});

export const loadPoolFailure = (payload) => ({
  type: LOAD_POOL_FAILURE,
  payload,
});

export const loadClientRequests = () => ({
  type: LOAD_CLIENT_REQUESTS_REQUEST,
});

export const loadClientRequestsSuccess = (payload) => ({
  type: LOAD_CLIENT_REQUESTS_SUCCESS,
  payload,
});

export const loadClientRequestsFailure = (payload) => ({
  type: LOAD_CLIENT_REQUESTS_FAILURE,
  payload,
});

export const loadAllRequestsRequest = () => ({
  type: LOAD_ALL_REQUESTS_REQUEST,
});

export const loadAllRequestsSuccess = (payload) => ({
  type: LOAD_ALL_REQUESTS_SUCCESS,
  payload,
});

export const loadAllRequestsFailure = (payload) => ({
  type: LOAD_ALL_REQUESTS_FAILURE,
  payload,
});

export const loadDraftsRequest = () => ({
  type: LOAD_DRAFTS_REQUEST,
});

export const loadDraftsSuccess = (payload) => ({
  type: LOAD_DRAFTS_SUCCESS,
  payload,
});

export const loadDraftsFailure = (payload) => ({
  type: LOAD_DRAFTS_FAILURE,
  payload,
});

export const loadInProgressRequest = () => ({
  type: LOAD_IN_PROGRESS_REQUEST,
});

export const loadInProgressSuccess = (payload) => ({
  type: LOAD_IN_PROGRESS_SUCCESS,
  payload,
});

export const loadInProgressFailure = (payload) => ({
  type: LOAD_IN_PROGRESS_FAILURE,
  payload,
});

export const loadCompletedRequest = () => ({
  type: LOAD_COMPLETED_REQUEST,
});

export const loadCompletedSuccess = (payload) => ({
  type: LOAD_COMPLETED_SUCCESS,
  payload,
});

export const loadCompletedFailure = (payload) => ({
  type: LOAD_COMPLETED_FAILURE,
  payload,
});

export const updateNewRequest = (payload) => ({
  type: UPDATE_NEW_REQUEST,
  payload,
});

export const updateItem = (payload) => ({
  type: UPDATE_ITEM,
  payload,
});

export const setItem = (payload) => ({
  type: SET_ITEM,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const updatePage = (payload) => ({
  type: SET_PAGE,
  payload,
});

export const setPage = (payload, cbAction) => async (dispatch) => {
  dispatch(updatePage(payload));
  dispatch(cbAction);
};

export const saveRequest = (draftId, payload, navigate) => async (dispatch, getState) => {
  try {
    const state = getState();
    console.log(state);
    const newRequest = getNewRequest(state);

    /* validate request start */
    // TODO - use yup for validation
    const errors = {};
    Object.keys(newRequest.deliveryInfo).forEach((field) => {
      if (
        !newRequest.deliveryInfo[field] &&
        ![
          'withSupport',
          'speedUp',
          'withGoodsLookup',
          'withPermissionDocs',
          'withPresense',
          'withLoadingControl',
          'withFitoSanControl',
          'withDocsTransfer',
        ].includes(field)
      ) {
        errors[field] = 'Данное поле является обязательным для заполнения';
      }
    });

    if (Object.values(errors).length > 0) {
      return dispatch(updateNewRequest({ key: 'errors', value: errors }));
    }
    /* validate request end */

    const tariffs = getTariffHash(state);

    // TODO
    // TODO
    // TODO
    // DANGEROUS!!! TODO move transactions to the BE
    let finalPrice = newRequest.price;
    if (newRequest.speedUp) {
      const increment = tariffs?.fasterCustom?.price;
      finalPrice += increment;
    }

    if (newRequest.withSupport) {
      const increment = tariffs?.supportedCustom?.price;
      finalPrice += increment;
    }

    if (newRequest.withGoodsLookup) {
      const increment = tariffs?.supportedCustom?.price;
      finalPrice += increment;
    }

    if (newRequest.withPermissionDocs) {
      const increment = tariffs?.permissionDocs?.price;
      finalPrice += increment;
    }

    if (newRequest.withPresense) {
      const increment = tariffs?.presense?.price;
      finalPrice += increment;
    }

    if (newRequest.withLoadingControl) {
      const increment = tariffs?.loadingControl?.price;
      finalPrice += increment;
    }

    if (newRequest.withFitoSanControl) {
      const increment = tariffs?.fitoSanControl?.price;
      finalPrice += increment;
    }

    if (newRequest.withDocsTransfer) {
      const increment = tariffs?.docsTransfer?.price;
      finalPrice += increment;
    }

    const { request } = await createRequest(
      omit({ ...newRequest, price: finalPrice }, ['errors', 'step'])
    );

    generateTransaction(
      payload.type,
      finalPrice,
      payload.subjectId,
      request.number
    );

    if (draftId && request) {
      deleteDraftApi(draftId);
    }

    dispatch(
      resetForm({
        number: request.number,
        price: request.price,
        discount: request.discount,
      })
    );

    const CLEAR_REQ_DELAY = 4500;

    const timeoutId = setTimeout(() => {
      //history.push(ROUTES.REQUESTS);
      navigate(ROUTES.REQUESTS);
    }, CLEAR_REQ_DELAY);

    setTimeout(() => {
      dispatch(updateNewRequest({ key: 'step', value: 0 }));
      dispatch(loadNewRequestDiscount());
    }, CLEAR_REQ_DELAY);

    return timeoutId;
  } catch (error) {
    console.log(error);
  }
};

export const takeRequest = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const data = await takeRequestApi(id);
    history.push(`/requests/declarant/${data?.request?._id}/info`);
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoading(false));
};

export const excludeNewRequestDocs =
  (docGroup, link) => async (dispatch, getState) => {
    const state = getState();
    const documents = getDocuments(state);
    const value = [...documents[docGroup]].filter((doc) => doc !== link);
    const payload = { key: 'documents', subKey: docGroup, value: value };

    dispatch(updateNewRequest(payload));
  };

export const excludeFormedRequestDocs =
  (docGroup, link) => async (dispatch, getState) => {
    const state = getState();
    const request = selectRequest(state);
    const value = [...request[docGroup]].filter((doc) => doc !== link);
    const payload = { key: docGroup, value: value };

    dispatch(updateItem(payload));
  };

export const loadRequest = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const data = await getRequest(id);
    dispatch(setItem(data.request));
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoading(false));
};

export const loadRequestFromAll = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const data = await getRequestFromAll(id);
    dispatch(setItem(data.request));
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoading(false));
};

export const loadRequests = (filters) => async (dispatch) => {
  dispatch(loadClientRequests());

  try {
    const data = await getRequests(filters);
    dispatch(loadClientRequestsSuccess(data));
  } catch (error) {
    loadClientRequestsFailure(error?.data);
  }
};

export const loadAllRequests = async (dispatch, getState) => {
  dispatch(loadAllRequestsRequest());

  try {
    const state = getState();
    const filters = getAllRequestsFilters(state);
    const data = await getAllRequests(filters);
    dispatch(loadAllRequestsSuccess(data));
  } catch (error) {
    loadAllRequestsFailure(error?.data);
  }
};

export const loadAllRequestsDone = async (dispatch, getState) => {
  dispatch(loadAllRequestsRequest());

  try {
    const state = getState();
    const filters = getAllRequestsDoneFilters(state);
    const data = await getAllRequestsDone(filters);

    dispatch(loadAllRequestsSuccess(data));
  } catch (error) {
    loadAllRequestsFailure(error?.data);
  }
};

export const loadAllRequestsInProgress = async (dispatch, getState) => {
  dispatch(loadAllRequestsRequest());

  try {
    const state = getState();
    const filters = getAllRequestsInProgressFilters(state);
    const data = await getAllRequestsInProgress(filters);

    dispatch(loadAllRequestsSuccess(data));
  } catch (error) {
    loadAllRequestsFailure(error?.data);
  }
};

export const loadDrafts = async (dispatch, getState) => {
  dispatch(loadDraftsRequest());

  try {
    const state = getState();
    const filters = getDraftFilters(state);
    const data = await getDraftsApi(filters);
    const unreadDraftsCount = data.drafts.reduce((acc, draft) => {
      if (draft.unread) {
        return acc + 1;
      }
      return acc;
    }, 0);
    dispatch(loadDraftsSuccess({ ...data, unreadDraftsCount }));
  } catch (error) {
    loadDraftsFailure(error?.data);
  }
};

export const deleteDraft = (id) => async (dispatch, getState) => {
  try {
    const { success } = await deleteDraftApi(id);
    if (success) {
      const state = getState();
      const drafts = getDrafts(state);
      const draftsCount = getDraftsCount(state);
      let unreadDraftsCount = getUnreadDraftsCount(state);

      const newDrafts = drafts.filter((draft) => {
        if (draft._id !== id) {
          return true;
        }

        if (draft.unread) {
          unreadDraftsCount--;
        }

        return false;
      });

      dispatch(
        loadDraftsSuccess({
          drafts: newDrafts,
          draftsCount: draftsCount - 1,
          unreadDraftsCount,
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const loadPool = async (dispatch, getState) => {
  dispatch(loadPoolRequest());
  try {
    const state = getState();
    const filters = getRequestListFilters(state);
    const data = await getPool(filters);
    dispatch(loadPoolSuccess(data));
  } catch (error) {
    loadPoolFailure(error?.data);
  }
};

export const loadInProgress = async (dispatch, getState) => {
  dispatch(loadInProgressRequest());
  try {
    const state = getState();
    const filters = getRequestInProgressFilters(state);
    const data = await getInProgress(filters);
    dispatch(loadInProgressSuccess(data));
  } catch (error) {
    loadInProgressFailure(error?.data);
  }
};

export const loadCompleted = (filters) => async (dispatch, getState) => {
  dispatch(loadCompletedRequest());
  try {
    const data = await getCompleted(filters);
    dispatch(loadCompletedSuccess(data));
  } catch (error) {
    loadCompletedFailure(error?.data);
  }
};

export const saveChecks = (checks, toast) => async (dispatch, getState) => {
  try {
    const state = getState();
    const request = selectRequest(state);

    const data = await updateRequestApi(request._id, {
      checks,
    });

    dispatch(
      setItem({
        ...request,
        status: data.request.status,
        checks: data.request.checks,
      })
    );
    toast.success('Платежи успешно сохранены');
  } catch (error) {
    console.log(error);
  }
};

export const confirmPayments = (toast) => async (dispatch, getState) => {
  try {
    const state = getState();
    const request = selectRequest(state);

    const data = await updateRequestApi(request._id, {
      paymentDone: true,
    });

    dispatch(setItem({ ...request, paymentDone: data.request.paymentDone }));
    toast.success('Оплата подтверждена');
  } catch (error) {
    console.log(error);
  }
};

export const saveCustomsPayments =
  (options, toast) => async (dispatch, getState) => {
    try {
      const state = getState();
      const request = selectRequest(state);
      const customsPayments = options.map(({ option, text }) => ({
        type: option,
        amount: parseInt(text),
      }));

      const data = await updateRequestApi(request._id, {
        customsPayments,
      });

      dispatch(
        setItem({ ...request, customsPayments: data.request.customsPayments })
      );
      toast.success('Таможенные платежи успешно сохранены');
    } catch (error) {
      console.log(error);
    }
  };

export const updateRequest = async (dispatch, getState) => {
  try {
    const state = getState();
    const request = selectRequest(state);
    if (request._id) {
      const data = await updateRequestApi(
        request._id,
        omit(request, [
          '_id',
          'createdAt',
          'udpatedAt',
          'profile',
          'company',
          'history',
          'checks',
          'customsPayments',
          'paymentDone',
        ])
      );

      dispatch(setItem({ ...data.request, profile: request.profile }));
    }
  } catch (error) {
    console.log(error);
  }
};

export const applyListFilters = (filtersKey, filters) => async (dispatch) => {
  dispatch(loadClientRequests());

  try {
    dispatch(updateFilters({ key: filtersKey, value: filters }));
    const data = await getRequests(filters);
    dispatch(loadClientRequestsSuccess(data));
  } catch (error) {
    loadClientRequestsFailure(error?.data);
  }
};

export const applyPoolFilters = (filters) => async (dispatch) => {
  dispatch(loadPoolRequest());

  try {
    dispatch(updateFilters({ key: FILTERS.REQUESTS_POOL, value: filters }));
    const data = await getPool(filters);
    dispatch(loadPoolSuccess(data));
  } catch (error) {
    dispatch(loadPoolFailure(error));
  }
};

export const applyAllRequestsFilters = (filters) => async (dispatch) => {
  dispatch(loadAllRequestsRequest());

  try {
    dispatch(updateFilters({ key: FILTERS.ALL_REQUESTS, value: filters }));
    const data = await getAllRequests(filters);

    dispatch(loadAllRequestsSuccess(data));
  } catch (error) {
    dispatch(loadAllRequestsFailure(error));
  }
};

export const applyAllRequestsDoneFilters = (filters) => async (dispatch) => {
  dispatch(loadAllRequestsRequest());

  try {
    dispatch(updateFilters({ key: FILTERS.ALL_REQUESTS_DONE, value: filters }));
    const data = await getAllRequestsDone(filters);

    dispatch(loadAllRequestsSuccess(data));
  } catch (error) {
    dispatch(loadAllRequestsFailure(error));
  }
};

export const applyAllRequestsInProgressFilters =
  (filters) => async (dispatch) => {
    dispatch(loadAllRequestsRequest());

    try {
      dispatch(
        updateFilters({ key: FILTERS.ALL_REQUESTS_IN_PROGRESS, value: filters })
      );
      const data = await getAllRequestsInProgress(filters);

      dispatch(loadAllRequestsSuccess(data));
    } catch (error) {
      dispatch(loadAllRequestsFailure(error));
    }
  };

export const applyInProgressFilters = (filters) => async (dispatch) => {
  dispatch(loadInProgressRequest());

  try {
    dispatch(
      updateFilters({ key: FILTERS.REQUESTS_IN_PROGRESS, value: filters })
    );
    const data = await getInProgress(filters);

    dispatch(loadInProgressSuccess(data));
  } catch (error) {
    dispatch(loadInProgressFailure(error?.data));
  }
};

export const applyCompletedFilters = (filters) => async (dispatch) => {
  dispatch(loadCompletedRequest());

  try {
    dispatch(
      updateFilters({ key: FILTERS.REQUESTS_COMPLETED, value: filters })
    );
    const data = await getCompleted(filters);

    dispatch(loadCompletedSuccess(data));
  } catch (error) {
    dispatch(loadCompletedFailure(error?.data));
  }
};

export const loadDraft = (draftId) => async (dispatch, getState) => {
  try {
    const { draft } = await getDraftApi(draftId);
    if (draft) {
      dispatch(
        updateNewRequest({
          key: 'deliveryInfo',
          value: {
            type: draft.type || '',
            shipping: draft.shipping || '',
            company: draft.company || '',
            terms: draft.terms || '',
            uniqueCount: draft.uniqueCount || 0,
            techDesc: draft.techDesc || '',
            receiptDate: draft.receiptDate || '',
            storeAddress: draft.storeAddress || '',
          },
        })
      );
      dispatch(
        updateNewRequest({
          key: 'documents',
          value: {
            contract: draft.contract || [],
            statute: draft.statute || [],
            misc: draft.misc || [],
            permits: draft.permits || [],
            docs: draft.docs || [],
          },
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveDraft = (draftId) => async (dispatch, getState) => {
  try {
    const state = getState();
    const newRequest = getNewRequest(state);
    if (draftId) {
      await updateDraft(
        draftId,
        omit(newRequest, ['discount', 'errors', 'step'])
      );
    } else {
      await createDraft(omit(newRequest, ['discount', 'errors', 'step']));
    }

    dispatch(cleanForm());
  } catch (error) {
    console.log(error);
  }
};

export const loadNewRequestDiscount = () => async (dispatch) => {
  const { promocodes } = await fetchActivePromocodes();
  if (promocodes && promocodes.length > 0) {
    let discount = 0;
    promocodes.forEach((code) => {
      if (code.discount > discount) {
        discount = code.discount;
      }
    });

    dispatch(updateNewRequest({ key: 'discount', value: discount }));
  }
};

export const conductTransactions = async (dispatch, getState) => {
  // Temporary value (share of declarant).
  const declarantShare = DECLARANT_SHARE / 100; // Do after getting data.

  try {
    const state = getState();
    const request = selectRequest(state);
    const {
      profile: client,
      declarant,
      number,
      extraPages,
      partner,
      partnerRate,
      price,
    } = request;

    const partnerShare = (partnerRate || 0) / 100;

    if (request._id) {
      let reminder = price;
      const sumPartner = partner ? (reminder * partnerShare).toFixed(2) : 0;
      reminder -= sumPartner;
      const sumDeclarant = (reminder * declarantShare).toFixed(2);
      reminder -= sumDeclarant;
      const sumSystem = reminder;

      const sumAddDeclarant = (
        PAGE_PRICE *
        extraPages *
        declarantShare
      ).toFixed(2);
      const sumAddSystem = extraPages
        ? PAGE_PRICE * extraPages - sumAddDeclarant
        : 0;

      const {
        ORDER_RECEIPT_DA,
        ORDER_RECEIPT_PA,
        ORDER_RECEIPT_SA,
        ADD_PAYMENT_CADA,
        ADD_PAYMENT_CASA,
      } = TRANSACTIONS_TYPES;

      await generateTransaction(
        ORDER_RECEIPT_DA,
        sumDeclarant,
        declarant,
        number
      );
      await generateTransaction(
        ORDER_RECEIPT_SA,
        sumSystem,
        SYSTEM_PROFILE_ID,
        number
      );
      if (partner) {
        await generateTransaction(
          ORDER_RECEIPT_PA,
          sumPartner,
          partner,
          number
        );
      }
      if (extraPages) {
        await generateTransaction(
          ADD_PAYMENT_CADA,
          sumAddDeclarant,
          client,
          number,
          declarant
        );
        await generateTransaction(
          ADD_PAYMENT_CASA,
          sumAddSystem,
          client,
          number,
          SYSTEM_PROFILE_ID
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
};
