import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import { ROUTES } from '../consts';
import { withLoading } from '../components/hocs/withLoading';
import { Tabs } from '../components/shared/Tabs';
import { Detail } from '../components/PaymentRequests/Detail';
import { PaymentRequests as PaymentRequestsComponent } from '../components/PaymentRequests';
import { HeaderWithCount } from '../components/shared/HeaderWithCount';
import { getCount, loadCompleted, loadCurrent } from '../store/paymentRequests';

const Header = () => {
  const count = useSelector(getCount);

  return (
    <HeaderWithCount title="Запросы" itemsName="запросов" count={count}>
      <Tabs
        tabs={[
          {
            title: 'Текущие запросы',
            isPart: true,
            route: ROUTES.FULL_REQUESTS_FOR_PAYMENT,
            href: ROUTES.FULL_REQUESTS_FOR_PAYMENT,
            visible: true,
          },
          {
            title: 'Завершенные',
            route: ROUTES.FULL_REQUESTS_FOR_PAYMENT_COMPLETED,
            href: ROUTES.FULL_REQUESTS_FOR_PAYMENT_COMPLETED,
            visible: true,
          },
        ]}
      />
    </HeaderWithCount>
  );
};

export const PaymentRequests = withLoading(() => {
  return (
    <main>      
      <Routes>
        <Route
          path={ROUTES.REQUESTS_FOR_PAYMENT_COMPLETED}
          Component={() => (
            <>
              <Header />
              <PaymentRequestsComponent action={loadCompleted} completed /> 
            </>
            )}          
        />
        <Route
          path={ROUTES.REQUESTS_FOR_PAYMENT_DETAIL}
          Component={Detail}
        />
        <Route
          path="/"
          Component={() => (
            <>
              <Header />
              <PaymentRequestsComponent action={loadCurrent} />
            </>
            )}
        />
      </Routes>
    </main>
  );
});
