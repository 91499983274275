import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import {
  getLoading,
  getPool,
  loadPool,
  applyPoolFilters,
  getCount,
} from '../../../store/requests';
import { getRequestPoolFilters } from '../../../store/filters';
import { Message } from '../../shared/Message';
import { Table } from '../../shared/Table';
import { ROUTES } from '../../../consts';
import { formatMoney } from '../../../utils';

export const PoolAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pool = useSelector(getPool);
  const loading = useSelector(getLoading);
  const filters = useSelector(getRequestPoolFilters);
  const countItems = useSelector(getCount);

  const REQUEST_HEADERS = [
    {
      label: `№ заявки`,
      value: 'number',
      sortable: true,
    },
    {
      label: 'Дата создания заявки',
      value: 'createdAt',
      sortable: true,
      render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    },
    {
      label: 'Компания заказчик',
      value: 'company',
      subKey: 'name',
    },
    {
      label: 'Стоимость заявки',
      value: 'price',
      render: formatMoney,
    },
    {
      label: '',
      value: 'rowActions',
      render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
    },
  ];

  useEffect(() => {
    dispatch(loadPool);
  }, [dispatch]);

  return (
    <div>
      {pool.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`${ROUTES.FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS}/${_id}`);
          }}
          loading={loading}
          filters={filters}
          items={pool}
          countItems={countItems}
          headers={REQUEST_HEADERS}
          applyFilters={(filters) => dispatch(applyPoolFilters(filters))}
        />
      ) : (
        <Message type="info">Новые заявки отсутствуют</Message>
      )}
    </div>
  );
};
