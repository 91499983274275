import React from 'react';
import styles from './TablePagination.module.scss';
import classnames from 'classnames';

export const TablePagination = ({ countItems, filters, applyFilters }) => {
  
  const currentPage = filters.offset ? filters.offset / filters.limit + 1 : 1;
  const pagesAmount =
    countItems % filters.limit
      ? Math.floor(countItems / filters.limit) + 1
      : countItems / filters.limit;
  const manyPages = pagesAmount > 18 ? true : false;

  const renderItem = (_, idx) => {
    const num = idx + 1;
    const current = num === currentPage;
    return (
		<div className={classnames(styles.page_btn, {
				[styles['page_btn--active']]: current,
				[styles['hover']]: !current
			})}
        key={idx}
        onClick={() =>
          !current && applyFilters({ ...filters, offset: idx * filters.limit })
        }		
		>{num}</div>
    );
  };
	return (
		pagesAmount > 1 && (
			<div className={styles.pagination}>
				<div className={classnames(styles.page_btn, {
						[styles['page_btn--prev']]: true
					})}
					onClick={() =>
						currentPage > 1 &&
						applyFilters({ ...filters, offset: filters.offset - filters.limit })
					}				
				></div>
				{new Array(pagesAmount).fill(null).map(renderItem)}
				<div className={classnames(styles.page_btn, {
						[styles['page_btn--next']]: true
					})}
					onClick={() =>
						currentPage < pagesAmount &&
						applyFilters({ ...filters, offset: filters.offset + filters.limit })
					}				
				></div>
			</div>
		)
	);
}