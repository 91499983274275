import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import classnames from 'classnames';
import styles from './Card.module.scss';

import { ROUTES } from '../../consts';
import { ConfirmModal } from '../shared/ConfirmModal';
import { removeCompanyById } from '../../store/companies';


export const Card = ({ company }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteCompany = () => {
    dispatch(removeCompanyById(company._id));
    navigate(`${"../"+ROUTES.PROFILE_COMPANY}`);
  }

  const clickHandler = (event) => {
    if (event.target.closest("."+classnames(styles.Card__DeleteLnk))){        
        setDeleteModalOpen(true);
    } else if (event.target.closest("."+classnames(styles.Card))){
      navigate(`${"../"+ROUTES.PROFILE_COMPANY}/${company._id}/info`);
      setTimeout(() => {
        const content = document.getElementById('content');
        if (content) {
          content.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 100);
    }
  }  


  return (
    <>
    <div className={styles.Card} onClick={clickHandler}>
      <div className={styles.Card__Header}>
        <div className={styles.Card__Title}>{company.name}</div>
        {!company.verified && (<div className={styles.Card__DeleteLnk}>Удалить</div>)}
      </div>
      <div className={styles.Card__Body}>
        <div className={styles.Card__Itn}>ИНН {company.itn}</div>
        <div className={styles.Card__Date}>
          В системе с {format(new Date(company.createdAt), 'dd.MM.yyyy')}
        </div>
        <div
          className={classnames(styles.Badge, {
            [styles['Badge--verified']]: company.verified,
            [styles['Badge--unverified']]: !company.verified,
          })}
        >
          {company.verified ? 'Верификация пройдена' : 'Не верифицированна'}
        </div>
      </div>
    </div>
    <ConfirmModal
      open={deleteModalOpen}
      onConfirm={deleteCompany}
      onReject={() => setDeleteModalOpen(false)}
      message={`Вы уверены, что хотите удалить компанию ${company.name}?`}
    />
    </>    
  );
};
