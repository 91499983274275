export 	const makeDate = (dateString) => {

		function correctNum(num){

			if (num < 10){
				return "0" + num;
			} else return num;

		}		
	
		let date, year, month, day;
		let shortMonth = ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"];
		if (dateString)	date = new Date(dateString);
		else date = new Date();

		month = shortMonth[date.getMonth()];
		day = correctNum(date.getDate());

		return day+" "+month+" "+date.getFullYear();
	}