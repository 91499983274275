import React from 'react';
import styles from './Checkbox.module.scss';

export const Checkbox = ({ onClick, checked, children, disabled, style }) => {
  return (
    <div
      className={styles.Checkbox}
      style={style}
      onClick={disabled ? () => {} : onClick}
    >
      <input
        type="checkbox"
        disabled={disabled}
        className={styles.Checkbox__Input}
        checked={Boolean(checked)}
        readOnly
      />
      {/* <div className={styles.Checkbox__Text}>{children}</div> */}
      <div
        className={
          checked ? styles.Checkbox__CheckedText : styles.Checkbox__Text
        }
      >
        {children}
      </div>
    </div>
  );
};
