import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { useScrollTop } from '../../hooks';
import { Table } from '../shared/Table';
import { Title } from '../shared/Title';
import { getTariffs, modifyTariff } from '../../store/tariffs';
import { getHeaders } from './headers';
import { EditModal } from './EditModal';

export const Settings = () => {
  const dispatch = useDispatch();
  const tariffs = useSelector(getTariffs);
  const [id, setId] = useState(null);
  const [price, setPrice] = useState(0);
  useScrollTop();

  const handleSubmit = () => dispatch(modifyTariff(id, { price }, toast));

  return (
    <div>
      <Title>Настройки</Title>
      <Table
        loading={false}
        items={tariffs}
        headers={getHeaders({ setId, setPrice })}
        filters={{}}
        applyFilters={() => {}}
      />
      <EditModal
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        open={id}
        onSubmit={handleSubmit}
        onClose={() => setId(null)}
      />
    </div>
  );
};
