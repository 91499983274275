import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  savePhoneForm,
  getPhoneForm,
  updatePhoneForm,
} from '../../store/notifications';
import { ROUTES } from '../../consts';
import { Title } from '../shared/Title';
import { Tabs } from '../shared/Tabs';
import { Button } from '../shared/Button';
import { Checkbox } from '../shared/Checkbox';
import { GoBackLink } from '../shared/GoBackLink';

import styles from './Notifications.module.scss';

export const NotificationsPhone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneForm = useSelector(getPhoneForm);

  const handleCheck = (key) => () =>
    dispatch(updatePhoneForm({ key, value: !phoneForm[key] }));

  const handleSubmit = () => dispatch(savePhoneForm(navigate));

  return (
    <div>
      <Title>Настройка SMS уведомлений</Title>
      <Tabs
        tabs={[
          {
            title: 'SMS',
            route: ROUTES.NOTIFICATIONS_SMS,
            visible: true,
          },
        ]}
      />
      <GoBackLink />
      <div className={styles.Form}>
        <div className={styles.FieldsArea}>
          <div>
            <div className={styles.FieldWrapper}>
              <Checkbox
                checked={phoneForm.requestUpdates}
                onClick={handleCheck('requestUpdates')}
              >
                Уведомления об измениях в заявке
              </Checkbox>
            </div>
            <div className={styles.FieldWrapper}>
              <Checkbox
                checked={phoneForm.infoNews}
                onClick={handleCheck('infoNews')}
              >
                Информация о системе и новости
              </Checkbox>
            </div>
          </div>
          <div>
            <div className={styles.FieldWrapper}>
              <Checkbox
                checked={phoneForm.commercial}
                onClick={handleCheck('commercial')}
              >
                Рекламные и коммерческие рассылки
              </Checkbox>
            </div>
          </div>
        </div>
        <Button filled onClick={handleSubmit}>
          Сохранить
        </Button>
      </div>
    </div>
  );
};
