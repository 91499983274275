import React from 'react';
import toast from 'react-hot-toast';

import { uploadDocument } from '../../../utils';

import UploadIcon from '../../../assets/Upload.svg';
import { Button } from '../Button';

import styles from './Upload.module.scss';

export const Upload = ({ id, onUpload, title, value, downloadOnly }) => {
  // TODO use ref instead of #id

  const handleClick = () => document.getElementById(id).click();
  const handleUpload = async (event) => {
    const document = await uploadDocument(event);
    if (document) {
      onUpload(document);
    } else {
      toast.error('Проблема при загрузке файла');
    }
  };

  if (downloadOnly) {
    return (
      <div className={styles.Block}>
        <div className={styles.Block__Title}>{title}</div>
        <div className={styles.Block__Upload}>
          <img src={UploadIcon} alt="upload" className={styles.UploadIcon} />
        </div>
        <Button filled fluid>
          Скачать
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.Block} onClick={handleClick}>
      <div className={styles.Block__Title}>{title}</div>
      <div className={styles.Block__Upload}>
        <img src={UploadIcon} alt="upload" className={styles.UploadIcon} />
      </div>

      {value && (
        <a
          href={value}
          target="_blank"
          className={styles.Block__Url}
          rel="noopener noreferrer"
        >
          {id}
        </a>
      )}
      <input
        type="file"
        id={id}
        style={{ display: 'none' }}
        onChange={handleUpload}
      />
    </div>
  );
};
