export const PASSPORT_FIELDS = {
  number: {
    label: 'Серия и номер',
    initialValue: '',
  },
  fullName: {
    label: 'Фамилия, имя, отчество',
    initialValue: '',
  },
  sex: {
    label: 'Пол',
    type: 'select',
    initialValue: '',
  },
  birthDate: {
    label: 'Дата рождения',
    initialValue: '',
    type: 'date',
  },
  birthPlace: {
    label: 'Место рождения (точно так, как указано в паспорте)',
    initialValue: '',
  },
  departmentCode: {
    label: 'Код подразделения',
    initialValue: '',
  },
  issuanceDate: {
    label: 'Дата выдачи',
    initialValue: '',
    type: 'date',
  },
  issuanceDepartment: {
    label: 'Кем выдан (точно так, как указано в паспорте)',
    initialValue: '',
  },
  registrationAddress: {
    label: 'Адрес регистрации (точно так, как указано в паспорте)',
    initialValue: '',
  },
};

export const PASSPORT_FIELDS_VIEW = {
  number: {
    value: 'number',
    label: 'Серия и номер',
    initialValue: '',
  },
  fullName: {
    value: 'fullName',
    label: 'Фамилия, имя, отчество',
    initialValue: '',
  },
  sex: {
    value: 'sex',
    label: 'Пол',
    type: 'selectView',
    initialValue: '',
  },
  birthDate: {
    value: 'birthDate',
    label: 'Дата рождения',
    initialValue: '',
    type: 'date',
  },
  birthPlace: {
    value: 'birthPlace',
    label: 'Место рождения',
    initialValue: '',
  },
  departmentCode: {
    value: 'departmentCode',
    label: 'Код подразделения',
    initialValue: '',
  },
  issuanceDate: {
    value: 'issuanceDate',
    label: 'Дата выдачи',
    initialValue: '',
    type: 'date',
  },
  issuanceDepartment: {
    value: 'issuanceDepartment',
    label: 'Кем выдан',
    initialValue: '',
  },
  registrationAddress: {
    value: 'registrationAddress',
    label: 'Адрес регистрации',
    initialValue: '',
  },
  lastPage: {
    value: 'lastPage',
    label: 'Основной разворот (фото)',
    type: 'noDisplay',
  },
  registration: {
    value: 'registration',
    label: 'Регистрация (фото)',
    type: 'noDisplay',
  },
  selfie: {
    value: 'selfie',
    label: 'Селфи с паспортом',
    type: 'noDisplay',
  },
  other: {
    value: 'other',
    label: 'Прочее',
    type: 'noDisplay',
  },
};

export const PASSPORT_FILES = {
  lastPage: {
    photoDesc: 'Основной_разворот.jpg',
    title: 'Шаг 1 : основной разворот',
    subtitle: 'Сделайте фото основного разворота паспорта',
  },
  registration: {
    photoDesc: 'Регистрация.jpg',
    title: 'Шаг 2 : регистрация',
    subtitle: 'Сделайте фото паспорта с регистрацией',
  },
  selfie: {
    photoDesc: 'Селфи_с_паспортом.jpg',
    title: 'Шаг 3 : селфи с паспортом',
    subtitle: 'Сделайте селфи с вашим паспортом',
  },
};

export const PASSPORT_FILES_VIEW = {
  lastPage: {
    photoDesc: 'Основной_разворот.jpg',
    title: 'Основной разворот',
  },
  registration: {
    photoDesc: 'Регистрация.jpg',
    title: 'Регистрация',
  },
  selfie: {
    photoDesc: 'Селфи_с_паспортом.jpg',
    title: 'Селфи с паспортом',
  },
};

export const SEX_OPTIONS = {
  male: { value: 'male', label: 'Мужской' },
  female: { value: 'female', label: 'Женский' },
};

export const PASSPORT_ANCHOR = 'passportAnch';
