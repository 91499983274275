import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { ROUTES } from '../../consts';
import { getProfile } from '../../store/profile';
import { getPaymentsCompletedFilters } from '../../store/filters';
import {
  getCount,
  getLoading,
  getItems,
  applyFilters,
} from '../../store/paymentRequests';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { formatMoney } from '../../utils';
import { getStatusNode, getTypeNode } from './utils';

export const PaymentRequests = ({ action, completed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const count = useSelector(getCount);
  const loading = useSelector(getLoading);
  const items = useSelector(getItems);
  const filters = useSelector(getPaymentsCompletedFilters);
  const profile = useSelector(getProfile);

  useEffect(() => {
    if (profile._id) {
      dispatch(action(filters));
    }
  }, [action, dispatch, profile._id, filters]);

  const headers = [
    {
      label: '№ запроса',
      value: 'number',
    },
    {
      label: 'Дата создания',
      value: 'createdAt',
      sortable: true,
      render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    },
    ...(completed
      ? [
          {
            label: 'Дата выполнения',
            value: 'updatedAt',
            render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
          },
          {
            label: 'Сотрудник',
            value: 'handledBy',
            render: (value) => (value ? `${value.name} ${value.surname}` : '-'),
          },
        ]
      : []),

    {
      label: 'Тип запроса',
      value: 'type',
      render: (value) => {
        const s = getTypeNode(value);
        if (s) {
          return s?.charAt(0).toUpperCase() + s.slice(1);
        }
      },
    },
    {
      label: 'Кто запросил',
      value: 'profile',
      render: (value) => (
        <span
          style={{ color: '#1890ff', cursor: 'pointer' }}
          onClick={() => {
            navigate(
              `${ROUTES.FULL_USERS}/${
                value.role === 'user' ? 'clients' : 'declarants'
              }/${value._id}`
            );
          }}
        >
          {value?.name} {value?.surname}
        </span>
      ),
    },
    {
      label: 'Сумма',
      value: 'amount',
      render: formatMoney,
    },
    {
      label: 'Статус',
      value: 'status',
      render: getStatusNode,
    },
    {
      label: '',
      value: '_id',
      render: (value) => (
        <div
          style={{ color: '#1890ff', cursor: 'pointer' }}
          onClick={() => {
            navigate(`${ROUTES.FULL_REQUESTS_FOR_PAYMENT}/${value}`);
          }}
        >
          Открыть
        </div>
      ),
    },
  ];

  if (!loading && items.length === 0) {
    return (
      <Message type="info">
        В настоящий момент в системе нет необработанных запросов{' '}
        <span role="img" aria-label="sorry">
          😥
        </span>
      </Message>
    );
  }

  return (
    <>
      {items?.length > 0 && (
        <Table
          loading={loading}
          items={items}
          headers={headers}
          filters={filters}
          countItems={count}
          applyFilters={(filters) => dispatch(applyFilters(filters))}
        />
      )}
    </>
  );
};
