import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SeaTab.module.sass';
import classnames from 'classnames';

import { Input } from './Input';
import { SubPanel } from './SubPanel';
import { CreatePackBtn } from './CreatePackBtn';
import { SimpleDropdownList } from '../../../shared/DropdownList';
import { Checkbox } from './Checkbox';
import {
	subsetPortTerminalList,
	getSeaName,
	getSeaExpire,
	getSeaTerminal,
	getSeaCustomVtt,
	getSeaCustomGtd,
	changeSeaName,
	changeSeaExpireDate,
	changeSeaTerminal,
	changeSeaCustoms,
	sendSeaOffers,
	getSeaCustomsCorrect} from '../../../../store/makeOffers';

export const SeaTab = () => {

	const dispatch = useDispatch();
	const name = useSelector(getSeaName);
	const expire = useSelector(getSeaExpire);
	const terminal = useSelector(getSeaTerminal);
	const vtt = useSelector(getSeaCustomVtt);
	const gtd = useSelector(getSeaCustomGtd);
	const correct = useSelector(getSeaCustomsCorrect);

	return (
		<>
		<div className={classnames(styles.column_inputs)}>
			<Input 
				inputName="Имя предложений"
				placeHolder="Название"
				rule="russian"
				inputValue={name}
				setStateCb={(state)=>{dispatch(changeSeaName(state))}}			
			/>
			<Input 
				inputName="Действует до"
				placeHolder="Дата"
				rule= "date"
				pin="schedule"
				inputValue={expire}
				setStateCb={(state)=>{dispatch(changeSeaExpireDate(state))}}			
			/>
			<SimpleDropdownList
				nameInput="Терминал прибытия"
				list={subsetPortTerminalList}
				proposal="Выберите терминал"
				width="100%"
				stepsData={terminal}
				setStateCb={(state)=>{dispatch(changeSeaTerminal(state))}}
				name="terminal"							
			/>
			<div className={classnames(styles.pack_border)}>
				<div className={classnames(styles.pack_invalid_border, {[styles["show"]]: !correct})}></div>			
				<div className={classnames(styles.pack_border_text)}>Таможенное оформление</div>
				<div className={classnames(styles.pack_wrap_checkboxes)}>
					<Checkbox 
						descrText="ВТТ"
						dataFieldChecked={vtt}
						setStateCb={(state)=>{dispatch(changeSeaCustoms({vtt: state}))}}
					/>
					<Checkbox 
						descrText="ГТД"
						dataFieldChecked={gtd}
						setStateCb={(state)=>{dispatch(changeSeaCustoms({gtd: state}))}}
					/>														
				</div>													
			</div>
			<SubPanel />
			<CreatePackBtn setStateCb={()=>{dispatch(sendSeaOffers)}}/>			
		</div>
		</>
	);


}


