import React, { useState, useRef, useEffect } from 'react';
import styles from './Checkbox.module.sass';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';


export const Checkbox = ({descrText, dataFieldChecked, setStateCb }) => {

	const [idCheckbox, setIdCheckbox] = useState(uuid());

	const handlerChange = (event) => {
		let checkbox;
		if (checkbox = event.target.closest("."+classnames(styles.checkbox_hidden))){
			if(setStateCb){
				setStateCb(!dataFieldChecked);
			}
		}
	}

	return(
		<div className={classnames(styles.wrap_checkbox)} onChange={handlerChange}>
			<input type="checkbox" id={idCheckbox} className={classnames(styles.checkbox_hidden)}				
				checked={dataFieldChecked}
			/>
			<label for={idCheckbox} className={classnames(styles.visual_checkbox)}></label>
			<label for={idCheckbox} className={classnames(styles.descr_checkbox)}>{descrText}</label>
		</div>
	);
}