import React from 'react';
import classnames from 'classnames';
import { get } from 'lodash';

import { Pagination } from '../Pagination';

import styles from './Table.module.scss';
import { LoadingPage } from '../../hocs/withLoading/LoadingPage';

export const Table = ({
  onRowClick,
  items,
  headers,
  filters,
  countItems,
  applyFilters,
  loading,
  headerCellLargeFont,
  lowTable,
  footnote,
}) => {
  return (
    <div className={lowTable ? styles.LowTabWrapper : styles.TabWrapper}>
      <table cellSpacing="0">
        {headers && (
          <thead>
            <tr key={"headersMain"}>
              {headers.map((header, headerId) => (
                <th
                  key={header.value + headerId}
                  className={
                    headerCellLargeFont
                      ? styles.Header__CellLargeFont
                      : styles.Header__Cell
                  }
                >
                  {header.label}
                  {header.sortable && (
                    <div className={styles.Header__Arrows}>
                      <span
                        className={classnames(
                          styles.Header__Arrow,
                          styles['Header__Arrow--top'],
                          {
                            [styles['Header__Arrow--active']]:
                              filters.sortField === header.value &&
                              filters.sortDirection === '-1',
                          }
                        )}
                        onClick={() => {
                          applyFilters({
                            ...filters,
                            sortField: header.value,
                            sortDirection: '-1',
                          });
                        }}
                      />
                      <span
                        className={classnames(
                          styles.Header__Arrow,
                          styles['Header__Arrow--bottom'],
                          {
                            [styles['Header__Arrow--active']]:
                              filters.sortField === header.value &&
                              filters.sortDirection === '1',
                          }
                        )}
                        onClick={() => {
                          applyFilters({
                            ...filters,
                            sortField: header.value,
                            sortDirection: '1',
                          });
                        }}
                      />
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        {loading ? (
          <tbody>
            <tr key={"loadingHeader"}>
              <td colSpan={100}>
                <LoadingPage withWrapper />
              </td>
            </tr>
          </tbody>
        ) : (
          items && (
            <tbody>
              {items.map((item) => (
                <tr
                  key={item._id}
                  onClick={() => onRowClick && onRowClick(item)}
                  className={classnames(styles.Row, {
                    [styles['Row--clickable']]: onRowClick,
                  })}
                >
                  {headers.map(
                    ({ render, subKey, value, renderItem }, headerId) => {
                      const itemValue = item[value];
                      const renderValue = subKey
                        ? get(itemValue, subKey)
                        : itemValue;

                      return (
                        <td key={value + headerId} className={styles.Cell}>
                          {render
                            ? render(renderItem ? item : renderValue?renderValue:"", item)
                            : renderValue}
                        </td>
                      );
                    }
                  )}
                </tr>
              ))}
            </tbody>
          )
        )}
      </table>
      {!loading && (
        <Pagination
          applyFilters={applyFilters}
          filters={filters}
          countItems={countItems}
        />
      )}
      {footnote && <div className={styles.Footnote}>{footnote}</div>}
    </div>
  );
};
