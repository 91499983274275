import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getProfile } from '../../store/profile';
import { getUser, loadUser } from '../../store/users';
import { PassportFormView } from './PassportFormView';
import { Title } from '../shared/Title';
import { GoBackLink } from '../shared/GoBackLink';
import { PersonalInformation } from '../shared/PersonalInformation';
import { EducationFormView } from './EducationFormView';
import { WorkFormView } from './WorkFormView';

export const UserProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const user = useSelector(getUser);
  let { id } = useParams();
  
  useEffect(() => {
    if (profile._id) {
      dispatch(loadUser(id));
    }
  }, [dispatch, id, profile._id]);

  return (
    <main>
      <Title>Профиль пользователя</Title>
      <GoBackLink />
      {user && (
        <>
          <PersonalInformation profile={user} permitEdit={false} />
          <PassportFormView passport={user.passport} />
          <EducationFormView education={user.education} />
          <WorkFormView work={user.work} />
        </>
      )}
    </main>
  );
};
