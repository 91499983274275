import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';

import './styles/index.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { App } from './components/App/App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<StrictMode>
	<App />
	</StrictMode>
);

