import React from 'react';
import { SubTabs } from '../shared/SubTabs';
import { ROUTES } from '../../consts';

export const ClientsSubTabsHeader = () => {
  return (
    <SubTabs
      subTabs={[
        {
          title: 'Пользователи',
          route: "../"+ROUTES.USERS_CLIENTS_PEOPLE,
          useMatchRoute: ROUTES.FULL_USERS_CLIENTS_PEOPLE,
          visible: true,
        },
        {
          title: 'Компании',
          route: "../"+ROUTES.USERS_CLIENTS_COMPANIES,
          useMatchRoute: ROUTES.FULL_USERS_CLIENTS_COMPANIES,
          visible: true,
        },
      ]}
    />
  );
};