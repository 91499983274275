import React from 'react';
import styles from './TablesNav.module.sass';
import classnames from 'classnames';

export const TablesNav = ({tableType, setTableType, btnList}) => {	

	const handlerClick = (event) => {
		let element;
		if (element = event.target.closest("."+classnames(styles.applications_data_cat))){
			setTableType(element.getAttribute('data-type'));
		}
	}
	 
	return(
		<>
			<div className={classnames(styles.applications_data_nav)} onClick={handlerClick}>
				{btnList.map(({type, text})=>(	<div data-type={type} className={classnames(styles.applications_data_cat,{
					[styles["active"]]:tableType === type
				})}>{text}</div>))}				
			</div>
		</>	

	);	
}
