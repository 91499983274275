import React from 'react';

export const ChatHeader = ({ children, online }) => {
  return (
    <div style={{ position: 'relative', paddingLeft: online ? '1rem' : '0' }}>
      {online && (
        <div
          style={{
            position: 'absolute',
            left: '-5px',
            top: '50%',
            transform: 'translateY(-50%)',
            height: '10px',
            width: '10px',
            backgroundColor: '#06d49a',
            borderRadius: '50%',
            display: 'inline-block',
          }}
        />
      )}
      {children}
      {online && ' (Online)'}
    </div>
  );
};
