import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { HeaderWithCount } from '../shared/HeaderWithCount';
import { Tabs } from '../shared/Tabs';
import { ROUTES, ROLES } from '../../consts';
import toast from 'react-hot-toast';
import { getUsersCount } from '../../store/users';
import { createInvite } from '../../api';
import { Button } from '../shared/Button';
import { DeclarantSubTabsHeader } from './DeclarantSubTabsHeader';
import { ClientsSubTabsHeader } from './ClientsSubTabsHeader';
import {LogistSubTabsHeader } from './LogistSubTabsHeader';
export const Header = ({ itemsName }) => {

  const count = useSelector(getUsersCount);
  const declarant = useMatch({ path: ROUTES.FULL_USERS_DECLARANTS+"/*" });
  const partner = useMatch({ path: ROUTES.FULL_USERS_PARTNERS });
  const clients = useMatch({ path: ROUTES.FULL_USERS_CLIENTS+"/*" });
  const clientsCompany = useMatch({ path: ROUTES.FULL_USERS_CLIENTS_COMPANIES });
  const logist = useMatch({ path: ROUTES.FULL_USERS_LOGISTS+"/*" });
  
  const [id, setId] = useState(null);
  let role, descrRole, marginTop={marginTop: "0px"};

  if (declarant) {
    role = ROLES.DECLARANT;
    descrRole = "декларанта.";
  }
  if (partner) {
    role = ROLES.PARTNER;
    descrRole = "партнера.";
    marginTop.marginTop = "1.5rem";
  }
  if (logist) {
    role = ROLES.LOGIST;
    descrRole = "логиста.";    
  }
  if (clientsCompany){
    itemsName = " компаний ";
  }

  const handleClick = async (role) => {
    try {
      const { invite } = await createInvite({ role });

      if (invite) {
        await navigator.clipboard.writeText(`${window.location.href}join/${invite._id}`);        
        setId(invite._id); 
        toast.success('Ссылка скопирована в буфер');       
      }
    } catch (e) {
      toast.error('Произошла ошибка');
      console.log(e);
    }
  };  

  if (!declarant && !partner && !logist){
    return (
      <>
      <HeaderWithCount
        title="Пользователи системы"
        itemsName={itemsName}
        count={count}
      >
        <Tabs
          hasSubTabs
          tabs={[
            {
              title: 'Все',
              route: ROUTES.FULL_ALL_USERS,
              visible: true,
            },
            {
              title: 'Клиенты',
              href: ROUTES.FULL_USERS_CLIENTS_PEOPLE,
              route: ROUTES.FULL_USERS_CLIENTS,
              visible: true,
            },            
            {
              title: 'Декларанты',
              href: ROUTES.FULL_USERS_DECLARANTS_ACCREDITED,
              route: ROUTES.FULL_USERS_DECLARANTS,
              visible: true,
            },
            {
              title: 'Логисты',
              href: ROUTES.FULL_USERS_LOGISTS_ACCREDITED,
              route: ROUTES.FULL_USERS_LOGISTS,
              visible: true,
            },
            {
              title: 'Партнеры',
              route: ROUTES.FULL_USERS_PARTNERS,
              visible: true,
            }            
          ]}
        />
      </HeaderWithCount>
      {clients && <ClientsSubTabsHeader />}
      </>
    );
  } else return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <HeaderWithCount
            title="Пользователи системы"
            itemsName={itemsName}
            count={count}
          >
            <Tabs
              hasSubTabs
              tabs={[
                {
                  title: 'Все',
                  route: ROUTES.FULL_ALL_USERS,
                  visible: true,
                },
                {
                  title: 'Клиенты',
                  href: ROUTES.FULL_USERS_CLIENTS_PEOPLE,
                  route: ROUTES.FULL_USERS_CLIENTS,
                  visible: true,
                },                
                {
                  title: 'Декларанты',
                  href: ROUTES.FULL_USERS_DECLARANTS_ACCREDITED,
                  route: ROUTES.FULL_USERS_DECLARANTS,
                  visible: true,
                },
                {
                  title: 'Логисты',
                  href: ROUTES.FULL_USERS_LOGISTS_ACCREDITED,
                  route: ROUTES.FULL_USERS_LOGISTS,
                  visible: true,
                },
                {
                  title: 'Партнеры',
                  route: ROUTES.FULL_USERS_PARTNERS,
                  visible: true,
                }               
              ]}
            />
          </HeaderWithCount>
          {declarant && <DeclarantSubTabsHeader />}
          {logist && <LogistSubTabsHeader />}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop,            
            padding: '1rem',
            background: 'white',
            borderRadius: '5px',
            alignItems: 'center',
          }}
        >
          {id && (
            <div
              style={{
                background: '#FAFAFA',
                borderRadius: '5px',
                marginRight: '2rem',
                padding: '0.5rem 1rem',
                width: 'max-content'
              }}
            >
              <a
                style={{ color: 'inherit', textDecoration: 'none' }}
                href={`/join/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Ссылка для перехода
              </a>
            </div>
          )}
          <Button
            onClick={()=>{handleClick(role)}}
            filled
            tooltip={{
              title: 'Генерация приглашения',
              content: 'По ссылке будет доступна форма регистрации '+descrRole,
            }}
          >
            Сгенерировать
          </Button>
        </div>
      </div>
    )

  

  


};