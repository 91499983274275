export const getTransactions = (state) => state.transactions.items;
export const getTransaction = (state) => state.transactions.item;
export const getAccounts = (state) => state.transactions.accounts;
export const getAccountsCount = (state) => state.transactions.accountsCount;
export const getCount = (state) => state.transactions.count;
export const getTotalFundsReceipt = (state) =>
  state.transactions.totalFundsReceipt;
export const getSystemAccountBalance = (state) =>
  state.transactions.systemAccountBalance;
export const getTempAccountBalance = (state) =>
  state.transactions.tempAccountBalance;
export const getOutPaymentsSum = (state) => state.transactions.outPaymentsSum;
export const getPaymentsDebt = (state) => state.transactions.paymentsDebt;
export const getLoading = (state) => state.transactions.loading;
