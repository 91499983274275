export const WORK_FIELDS = {
  regime: {
    value: 'regime',
    label: 'Таможенный режим',
    initialValue: '',
  },
  category: {
    value: 'category',
    label: 'Категория товаров, с которой проще работать',
    initialValue: '',
  },
};

export const WORK_REJECT_REASON = {
  experience: {
    value: 'experience',
    label: 'Опыт работы',
    type: 'noDisplay',
  },
  preferences: {
    value: 'preferences',
    label: 'Предпочтения в работе',
    type: 'noDisplay',
  },
  other: {
    value: 'other',
    label: 'Прочее',
    type: 'noDisplay',
  },
};

export const WORK_ANCH = 'workAnch';
