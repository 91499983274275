import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES, ROLES } from '../consts';
import { CheckRole } from '../components/hocs/Checker';
import { withLoading } from '../components/hocs/withLoading';
import { RequestsChoice } from '../components/Requests/AdminAndUser/RequestsChoice';
import { RequestsChoice as RequestsChoiceLogist} from '../components/Requests/Logist/RequestsChoice';
import { RequestsDeclarationsClient } from './RequestsDeclarationsClient';
import { OffersLogisticsClient } from './OffersLogisticsClient';
import { MyOffersLogisticsManager } from './MyOffersLogisticsManager';
import { MyOffersLogisticsManagerDetail } from './MyOffersLogisticsManagerDetail';
import { RequestsAdmin } from '../components/Requests/Admin/RequestsAdmin';
import { RequestsDeclarant } from '../components/Requests/Declarant/RequestsDeclarant';
export const Requests = withLoading(() => {    
  return (
    <main>
      <Routes>
        <Route path="/" element={ <CheckRole                            
          roles={{
            [ROLES.ADMIN]: RequestsChoice,
            [ROLES.USER]: Navigate.bind(null,{to:ROUTES.REQUESTS_LOGISTICS}),
            [ROLES.DECLARANT]: Navigate.bind(null,{to:ROUTES.REQUESTS_DECLARATIONS}),
            [ROLES.LOGIST]: Navigate.bind(null,{to:ROUTES.REQUESTS_LOGISTICS})
          }} /> }        
        />
        <Route path={ROUTES.REQUESTS_DECLARATIONS+"/*"} element={ <CheckRole                            
          roles={{
            [ROLES.USER]: RequestsDeclarationsClient, 
            [ROLES.ADMIN]: RequestsAdmin,
            [ROLES.DECLARANT]: RequestsDeclarant,                       
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        />             
        <Route path={ROUTES.REQUESTS_LOGISTICS} element={ <CheckRole                            
          roles={{
            [ROLES.USER]: OffersLogisticsClient,
            [ROLES.LOGIST]: Navigate.bind(null,{to:ROUTES.FULL_REQUESTS_LOGISTICS_MY_OFFERS}),
            /*[ROLES.LOGIST]: RequestsChoiceLogist,*/
/*          [ROLES.ADMIN]: RequestsAdmin*/             
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        />
        <Route path={ROUTES.REQUESTS_LOGISTICS_MY_OFFERS} element={ <CheckRole                            
          roles={{
            [ROLES.LOGIST]: MyOffersLogisticsManager,            
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        />
        <Route path={ROUTES.REQUESTS_LOGISTICS_MY_OFFERS_DETAIL} element={ <CheckRole                            
          roles={{
            [ROLES.LOGIST]: MyOffersLogisticsManagerDetail,            
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        />
        
 {/*       <Route path={ROUTES.REQUESTS_LOGISTICS_EDIT_OFFERS_DETAIL} element={ <CheckRole                            
          roles={{
            [ROLES.LOGIST]: EditOffersLogisticsManager,            
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        />        
        <Route path={ROUTES.REQUESTS_LOGISTICS_APPLICATIONS} element={ <CheckRole                            
          roles={{
            [ROLES.LOGIST]: MyOffersLogisticsManager,            
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        />
        <Route path={ROUTES.REQUESTS_LOGISTICS_CALCULATION_DETAIL} element={ <CheckRole                            
          roles={{
            [ROLES.USER]: LogisticsOffersClient,
            [ROLES.LOGIST]: LogisticsOffersManager         
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        /> 
        <Route path={ROUTES.REQUESTS_LOGISTICS_OFFER_DETAIL} element={ <CheckRole                            
          roles={{
            [ROLES.USER]: LogisticsOfferSignClient,
            [ROLES.LOGIST]: LogisticsOfferSignManager          
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        />
        <Route path={ROUTES.REQUESTS_LOGISTICS_IN_WORK_DETAIL} element={ <CheckRole                            
          roles={{
            [ROLES.USER]: LogisticsStageProgressClient,
            [ROLES.LOGIST]: LogisticsStageProgressManager           
          }} redirect={ROUTES.FULL_REQUESTS}/> }  
        /> */}

        {/*<Route path="*" element={<Navigate to={ROUTES.INDEX_REQUESTS} />} /> */}     
      </Routes>
    </main>
  );
});


