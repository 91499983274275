import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import { getPayment } from '../../api';
import { GoBackLink } from '../shared/GoBackLink';
import { LoadingPage } from '../hocs/withLoading/LoadingPage';
import { PersonalInput } from '../Personal/PersonalInput';
import { Button } from '../shared/Button';
import { Title } from '../shared/Title';
import { UploadedDocument } from '../shared/UploadArea/UploadedDocument';
import { getProfile } from '../../store/profile';

import { getStatusNode, getTypeNode } from './utils';
import { formatMoney } from '../../utils';
import { RejectModal } from './RejectModal';
import { ResolveModal } from './ResolveModal';

import styles from './Detail.module.scss';

export const Detail = () => {
  const [payment, setPayment] = useState(null);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [resolveOpen, setResolveOpen] = useState(false);
  const profile = useSelector(getProfile);
  const { id } = useParams();

  const FIELDS = [
    {
      id: 'profile',
      key: 'profile',
      label: 'Кто запросил',
      render: (value) => `${value?.name} ${value?.surname}`,
    },
    {
      id: 'amount',
      key: 'amount',
      label: 'Сумма запроса',
      render: (value) => formatMoney(value),
    },
    {
      id: 'createdAt',
      key: 'createdAt',
      label: 'Дата запроса',
      render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    },
    {
      id: payment?.bankAccount ? 'bankAccount' : 'personalAccount',
      key: 'bankName',
      label: 'Банк-получатель',
      render: (value) => (payment?.bankAccount ? value?.name : value?.bankName),
    },
    {
      id: payment?.bankAccount ? 'bankAccount' : 'personalAccount',
      key: 'bik',
      label: 'БИК',
      render: (value) => (value ? `0${value.bik}` : null),
    },
    {
      id: payment?.bankAccount ? 'bankAccount' : 'personalAccount',
      key: payment?.bankAccount ? 'accountNumber' : 'correspondenceAccount',
      label: 'Корр. счет',
      render: (value) =>
        payment?.bankAccount
          ? value?.accountNumber
          : value?.correspondenceAccount,
    },
    {
      id: 'personalAccount',
      key: 'accountNumber',
      label: 'Счет получателя',
      render: (value) => value?.accountNumber,
    },
  ];

  useEffect(() => {
    let unmounted = false;
    async function fetchData() {
      const { payment } = await getPayment(id);

      if (!unmounted) {
        setPayment(payment);
      }
    }

    if (profile._id) {
      fetchData();
    }

    return () => {
      unmounted = true;
    };
  }, [id, profile._id]);

  return payment ? (
    <div>
      <Title>
        <div className={styles.Title}>
          <div>
            Запрос на {getTypeNode(payment.type)} № {payment.number}
            &nbsp;&nbsp;&nbsp;
            {getStatusNode(payment.status, true)}
          </div>
        </div>
      </Title>
      <GoBackLink />
      <main className={styles.Card}>
        <div className={styles.Row}>
          {FIELDS.map(({ id, key, label, render }, idx) => {
            if (idx === FIELDS.length - 1 && payment.bankAccount) {
              return null;
            }
            const fieldVal = payment[id];
            const value = render ? render(fieldVal) : fieldVal;

            return (
              <div className={styles.CardItem} key={key + id}>
                <PersonalInput
                  permitEdit={false}
                  key={key}
                  label={label}
                  value={value}
                />
              </div>
            );
          })}
        </div>
        {payment.comment && (
          <div style={{ margin: '3rem 0', display: 'flex' }}>
            <span>Причина отклонения:</span>&nbsp;&nbsp;&nbsp;
            <p className={styles.Rejection}>{payment.comment}</p>
          </div>
        )}
        {payment.confirmationDoc && (
          <div>
            <br />
            <br />
            <UploadedDocument
              name="Подтверждение"
              link={payment.confirmationDoc}
            />
          </div>
        )}
        {payment.status === 'pending' && (
          <div className={styles.ButtonArea}>
            <Button onClick={() => setRejectOpen(true)}>Отклонить</Button>
            <Button
              filled
              onClick={() => setResolveOpen(true)}
              className={styles.Button}
              setPayment={setPayment}
            >
              Принять
            </Button>
            <ResolveModal
              id={id}
              open={resolveOpen}
              setPayment={setPayment}
              role={payment.profile.role}
              onClose={() => setResolveOpen(false)}
            />
            <RejectModal
              id={id}
              open={rejectOpen}
              setPayment={setPayment}
              onClose={() => setRejectOpen(false)}
            />
          </div>
        )}
      </main>
    </div>
  ) : (
    <LoadingPage withWrapper />
  );
};
