import {
  departureCountryList,
  arrivalCountryList,
  departureCityPortList,
  departureStationList,
  arrivalFromPortStationList,
  arrivalStraightStationList,
  arrivalCityPortList,
  arrivalPortTerminalList,
  subsetPortTerminalList,
  cityTerminal,
  customsList,
  arrivalClientFromPortStationList } from '../makeOffers/consts';

import {
  departurePoints,
  arrivalPoints} from './actions';

import {
  SET_DEFAULT_STATE,
  CHANGE_DEPARTURE_COUNTRY,
  CHANGE_DEPARTURE_POINT,
  CHANGE_TYPE_STATION,
  CHANGE_TYPE_CONTAINER,
  CHANGE_ARRIVAL_COUNTRY,
  CHANGE_ARRIVAL_TERMINAL,
  CHANGE_ARRIVAL_POINT,
  CHANGE_CUSTOMS,
  CHANGE_LOADING,
  CHANGE_LOADUP,
  RESET_OFFERS,
  ADD_OFFERS,
  SET_SEARCH_OBJECT,
  SET_COUNT,
  SET_SEA_BY_SEARCH_PARAMS,
  SET_TRAIN_BY_SEARCH_PARAMS} from './actions';

const seaForm = ["departureCountry", "arrivalCountry", "arrivalCityTerminal", "departurePoint", "arrivalPoint", "customs"];
const trainForm = ["departureCountry", "arrivalCountry", "departurePoint", "arrivalPoint"];

const initialState = {
  departurePoints: departurePoints,
  arrivalPoints: arrivalPoints,
  departureCountryList: departureCountryList,
  departureCountry: departureCountryList[0],
  arrivalCountryList: arrivalCountryList,
  arrivalCountry: arrivalCountryList[0],
  arrivalCityTerminalList: arrivalCityPortList[arrivalCountryList[0].datatype],
  arrivalCityTerminal: arrivalCityPortList[arrivalCountryList[0].datatype]?.[0],
  typeStation: "sea",
  departurePointList: departurePoints.sea.china,
  departurePoint: undefined,
  loadingMessage: "Заполните поля для формирования предложений.", 
  typeContainer: "twenty",
  arrivalPointList: arrivalPoints.sea.russia.filter(({datatype})=>{
    const filterList = arrivalCityPortList[arrivalCountryList[0].datatype].filter(({datatype})=>datatype!=="saintpetersburg" && datatype!==arrivalCityPortList[arrivalCountryList[0].datatype]?.[0].datatype);
    return !filterList.find((item)=>item.datatype===datatype);
    }),
  arrivalPoint: undefined,
  customsList: customsList,
  customs: customsList[1],
  validForm: false,
  loading: false,
  loadup:false,
  count: 0,
  total:0,
  offers: [],
  searchObj: {}
};

let validFields, arrivalPortPointList;

export const selectionOffersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEFAULT_STATE:
      return initialState;
    case CHANGE_DEPARTURE_COUNTRY:
      const typeStation = (departurePoints[state.typeStation][action.payload.datatype])
          ?state.typeStation
          : (state.typeStation === "train")? "sea":"train";
      return {
        ...state, departureCountry: action.payload,
        departurePointList: departurePoints[typeStation][action.payload.datatype],
        departurePoint: undefined,
        typeStation: typeStation,
        arrivalCityTerminalList: (typeStation === "sea")?arrivalCityPortList[state.arrivalCountry.datatype]:undefined,
        arrivalCityTerminal: (typeStation === "sea")?arrivalCityPortList[state.arrivalCountry.datatype][0]:undefined,
        arrivalPointList: (typeStation === "sea")?arrivalPoints.sea[state.arrivalCountry.datatype].filter(({datatype})=>{
          const filterList = arrivalCityPortList[state.arrivalCountry.datatype].filter(({datatype})=>datatype!=="saintpetersburg" && datatype!==arrivalCityPortList[state.arrivalCountry.datatype][0].datatype);
          return !filterList.find((item)=>item.datatype===datatype);
        }) : arrivalPoints[typeStation][state.arrivalCountry.datatype],
        arrivalPoint: undefined,
        customsList: (typeStation === "sea")?customsList:undefined,
        validForm: false 
      };
    case CHANGE_DEPARTURE_POINT:
      validFields = (state.typeStation === "sea")?[...seaForm]:[...trainForm];
      return {
        ...state,        
        departurePoint: action.payload,
        validForm: validFields.every((item)=>{
            if (item === "departurePoint") return true;
            return (typeof state[item] === "object" && state[item] !== null);
          })        
      };            
    case CHANGE_TYPE_STATION:
      return {
        ...state,        
        typeStation: action.payload,
        departurePointList: departurePoints[action.payload][state.departureCountry.datatype],
        departurePoint: undefined,
        arrivalCityTerminalList: (action.payload === "sea")?arrivalCityPortList[state.arrivalCountry.datatype]:undefined,
        arrivalCityTerminal: (action.payload === "sea")?arrivalCityPortList[state.arrivalCountry.datatype][0]:undefined,
        arrivalPointList: (action.payload === "sea")?arrivalPoints.sea[state.arrivalCountry.datatype].filter(({datatype})=>{
          const filterList = arrivalCityPortList[state.arrivalCountry.datatype].filter(({datatype})=>datatype!=="saintpetersburg" && datatype!==arrivalCityPortList[state.arrivalCountry.datatype][0].datatype);
          return !filterList.find((item)=>item.datatype===datatype);
        }) : arrivalPoints[action.payload][state.arrivalCountry.datatype],
        arrivalPoint: undefined,
        customsList: (action.payload === "sea")?customsList:undefined,
        validForm: false                
      };
    case CHANGE_TYPE_CONTAINER:
      return {
        ...state,        
        typeContainer: action.payload                     
      }; 
    case CHANGE_ARRIVAL_COUNTRY:
      validFields = (state.typeStation === "sea")?[...seaForm]:[...trainForm];
      return {
        ...state,        
        arrivalCountry: action.payload,
        validForm: validFields.every((item)=>{
          if (item === "arrivalCountry") return true;
            return (typeof state[item] === "object" && state[item] !== null);
          })                     
      };  
    case CHANGE_ARRIVAL_TERMINAL:
      validFields = [...seaForm];
      return {
        ...state,
        arrivalPointList: arrivalPoints.sea[state.arrivalCountry.datatype].filter(({datatype})=>{
        const filterList = state.arrivalCityTerminalList.filter(({datatype})=>datatype!=="saintpetersburg" && datatype!==action.payload.datatype);
        return !filterList.find((item)=>item.datatype===datatype);
        }),        
        arrivalCityTerminal: action.payload,
        arrivalPoint: undefined,
        validForm: validFields.every((item)=>{
          if (item === "arrivalCityTerminal") return true;          
            return (typeof state[item] === "object" && state[item] !== null);
          })                     
      }; 
    case CHANGE_ARRIVAL_POINT:
      validFields = (state.typeStation === "sea")?[...seaForm]:[...trainForm];
      return {
        ...state,        
        arrivalPoint: action.payload,        
        validForm: validFields.every((item)=>{
          if (item === "arrivalPoint") return true;
            return (typeof state[item] === "object" && state[item] !== null);
          })                     
      }; 
    case CHANGE_CUSTOMS:
      validFields = (state.typeStation === "sea")?[...seaForm]:[...trainForm];
      return {
        ...state,        
        customs: action.payload,
        validForm: validFields.every((item)=>{
          if (item === "customs") return true;          
            return (typeof state[item] === "object" && state[item] !== null);
          })                    
      };
    case CHANGE_LOADING:
      return {
        ...state,        
        loading: action.payload                     
      };
    case CHANGE_LOADUP:
      return {
        ...state,        
        loadup: action.payload                     
      };
    case RESET_OFFERS:
      return {
        ...state,        
        offers: [],
        total: 0,
        count: 0                    
      }; 
    case SET_SEARCH_OBJECT:
      return {
        ...state,        
        searchObj: action.payload                     
      };
    case ADD_OFFERS:
      return {
        ...state,        
        offers: [...state.offers,...action.payload.offers],
        count: state.count + action.payload.offers.length,
        total: action.payload.total,
      }; 
    case SET_COUNT:
      return {
        ...state,        
        count: action.payload                     
      };
    case SET_SEA_BY_SEARCH_PARAMS:
      const arrivalPL = [...arrivalFromPortStationList[arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype]];
      if (!arrivalPL.find(item=>item.title === action.payload.arrivalCityTerminal)) arrivalPL.splice(0,0,{title: action.payload.arrivalCityTerminal, datatype:arrivalClientFromPortStationList[arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype].find(item=>item.title === action.payload.arrivalCityTerminal).datatype});
      return {
        ...state,
          departureCountry:{title:action.payload.departureCountry, datatype: departureCountryList.find(item=>item.title===action.payload.departureCountry).datatype},
          arrivalCountry: {title: action.payload.arrivalCountry, datatype: arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype},
          arrivalCityTerminalList: [...arrivalCityPortList[arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype]],
          arrivalCityTerminal: {title: action.payload.arrivalCityTerminal, datatype: arrivalCityPortList[arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype].find(item=>item.title===action.payload.arrivalCityTerminal).datatype},
          typeStation: "sea",
          departurePointList:[...departureCityPortList[departureCountryList.find(item=>item.title===action.payload.departureCountry).datatype]],
          departurePoint: {title: action.payload.departurePoint, datatype: departureCityPortList[departureCountryList.find(item=>item.title===action.payload.departureCountry).datatype].find(item=>item.title===action.payload.departurePoint).datatype},
          typeContainer: action.payload.typeContainer,
          arrivalPointList: arrivalPL,
          arrivalPoint: {title: action.payload.arrivalPoint, datatype: arrivalClientFromPortStationList[arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype].find(item=>item.title===action.payload.arrivalPoint).datatype},
          customs: {title: action.payload.customs, datatype: customsList.find(item=>item.title===action.payload.customs).datatype},
          validForm: true                      
      }; 
    case SET_TRAIN_BY_SEARCH_PARAMS:
      return {
        ...state,        
          departureCountry: {title:action.payload.departureCountry, datatype: departureCountryList.find(item=>item.title===action.payload.departureCountry).datatype},
          arrivalCountry: {title: action.payload.arrivalCountry, datatype: arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype},
          typeStation: "train",
          departurePointList: [...departureStationList[departureCountryList.find(item=>item.title===action.payload.departureCountry).datatype]],
          departurePoint: {title: action.payload.departurePoint, datatype: departureStationList[departureCountryList.find(item=>item.title===action.payload.departureCountry).datatype].find(item=>item.title===action.payload.departurePoint).datatype},
          typeContainer: action.payload.typeContainer,
          arrivalPointList: [...arrivalStraightStationList[arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype]],
          arrivalPoint: {title: action.payload.arrivalPoint, datatype: arrivalStraightStationList[arrivalCountryList.find(item=>item.title===action.payload.arrivalCountry).datatype].find(item=>item.title===action.payload.arrivalPoint).datatype}, 
          validForm: true,
          arrivalCityTerminalList: undefined,
          arrivalCityTerminal: undefined,
          customsList: undefined                           
      };                                             
    default:
      return state;
  }
};
