import React from 'react';

import { Loader } from '../../shared/Loader';

export const LoadingPage = ({ withWrapper }) => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      padding: withWrapper ? '15vh 0' : '0',
    }}
  >
    <Loader />
  </div>
);
