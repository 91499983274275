import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './View.module.sass';
import classnames from 'classnames';

import { PackTrainRoutes } from './PackTrainRoutes';
import { PackSeaRoutes } from './PackSeaRoutes';
import { TextArea } from './TextArea';

import {
	getPanelState,
	getSeaTerminal,
	getSeaCityArrival,
	getTrainCityArrival,
	getSeaConditionsCarriage,
	getSeaPaymentTerms,
	getTrainConditionsCarriage,
	getTrainPaymentTerms,
	updateSeaTextArea,
	updateTrainTextArea} from '../../../../store/makeOffers';


export const View = () => { 

	const dispatch = useDispatch();
	const panelState = useSelector(getPanelState);
	const seaTerminal = useSelector(getSeaTerminal);
	const citySeaArrival = useSelector(getSeaCityArrival);
	const cityTrainArrival = useSelector(getTrainCityArrival);
	const seaConditionsCarriage = useSelector(getSeaConditionsCarriage);
	const seaPaymentTerms = useSelector(getSeaPaymentTerms);
	const trainConditionsCarriage = useSelector(getTrainConditionsCarriage);
	const trainPaymentTerms = useSelector(getTrainPaymentTerms);

	const changeSeaTextArea = (field, state) => {
		dispatch(updateSeaTextArea(field, state));
	}
	const changeTrainTextArea = (field, state) => {
		dispatch(updateTrainTextArea(field, state));
	}


	return (
		<div className={classnames(styles.view_block)}>
			<div className={classnames(styles.wrap_terminal_arrival)}>
				<div className={classnames(styles.city_arrival)}>{(panelState==="sea")?`${citySeaArrival} ${seaTerminal.title}`:cityTrainArrival.title}</div>
			</div>			
			{(panelState === "train") && (<PackTrainRoutes />)}
			{(panelState === "sea") && (<PackSeaRoutes />)}			
			<TextArea 
				name="conditionscarriage"
				placeholder="Что включено в ставку, условия пользования контейнером, дроп офф"
				textareaName="Условия перевозки"
				rule="russian"
				areaValue={(panelState==="sea")?seaConditionsCarriage:trainConditionsCarriage}
				setStateCb={(panelState==="sea")?changeSeaTextArea.bind(null, "conditionscarriage"):changeTrainTextArea.bind(null, "conditionscarriage")}
				maxlength="800"
			/>
			<TextArea 
				name="paymentterms"
				placeholder="Когда платить, курсовые надбавки, штрафы, пени"
				textareaName="Условия оплаты"
				rule="russian"
				areaValue={(panelState==="sea")?seaPaymentTerms:trainPaymentTerms}
				setStateCb={(panelState==="sea")?changeSeaTextArea.bind(null, "paymentterms"):changeTrainTextArea.bind(null, "paymentterms")}
				maxlength="800"			
			/>
		</div>

	);
}