import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { io } from 'socket.io-client';

import Send from '../../../assets/Send.svg';
import { TOKEN } from '../../../consts';
import { getDocName, LocalStorageHelper } from '../../../utils';
import { getMessages } from '../../../api';
import ENV from '../../../configs/environment';
import {
  confirmPayments,
  getRequest,
  saveCustomsPayments,
} from '../../../store/requests';
import { getProfile } from '../../../store/profile';
import { Input } from '../../shared/Input';
import { Accordion } from '../../shared/Accordion';
import { Button } from '../../shared/Button';
import { Survey } from '../../shared/Survey';
import { Title } from '../../shared/Title';
import { UploadedDocument } from '../../shared/UploadArea/UploadedDocument';
import { CUSTOM_QUERIES } from '../../../configs/customQueries';
import { History } from '../History';
import { PaymentsNotReady } from '../CustomsPayments/PaymentsNotReady';
import { PaymentsList } from '../CustomsPayments/PaymentsList';
import { Declaration } from './Declaration';
import { MessageList } from '../Chat/MessageList';
import { ChatHeader } from '../Chat/ChatHeader';

import styles from './DetailProgress.module.scss';
import chatStyles from '../RequestDetailProgress.module.scss';

export const DetailProgress = () => {
  const dispatch = useDispatch();
  const [onlineUsers, setOnlineUsers] = useState([]);
  const { _id, photo, name, surname } = useSelector(getProfile);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const socketRef = useRef(null);
  const [showCustomsSurvey, setShowCustomsSurvey] = useState(false);
  const [openState, setOpenState] = useState({
    customs: false,
    history: false,
    declaration: false,
    chat: true,
  });
  const item = useSelector(getRequest);

  useEffect(() => {
    let unmounted = false;
    let socket;
    if (item._id) {
      const token = LocalStorageHelper.getItem(TOKEN);
      socket = io(process.env.NODE_ENV === "development" ? ENV.API_URL : "system.mydeclarant.ru", {
        query: { token },
      });
      socketRef.current = socket;
      socket.emit('joinRoom', { profile: _id, request: item._id });
      socket.on('message', (message) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            ...message,
            author: {
              photo: item.profile.photo,
              name: item.profile.name,
              surname: item.profile.surname,
            },
          },
        ]);
      });

      socket.on('online', (users) => {
        setOnlineUsers(users);
      });

      async function fetchMessages() {
        try {
          const response = await getMessages(item._id);
          if (response?.messages && !unmounted) {
            setMessages(response.messages);
          }
        } catch (e) {
          console.log(e);
        }
      }

      fetchMessages();
    }

    return () => {
      if (item._id && socket) {
        unmounted = true;
        socket.disconnect(true);
      }
    };
  }, [item._id, _id, item.profile]);

  const handleToggle = (accordion) => () =>
    setOpenState((prevState) => ({
      ...prevState,
      [accordion]: !prevState[accordion],
    }));

  const handleSend = () => {
    if (socketRef.current) {
      socketRef.current.emit('message', newMessage);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          createdAt: new Date(),
          text: newMessage,
          author: {
            _id,
            surname,
            photo,
            name,
          },
        },
      ]);
    }
    setNewMessage('');
  };

  const online = onlineUsers.includes(item.profile?._id);

  const chatTitle = (
    <ChatHeader online={online}>
      {item.profile?.name} {item.profile?.surname} / {item.company?.name}
    </ChatHeader>
  );

  const chatMessage = 'Сообщений пока нет';

  return (
    <div className={styles.Progress}>
      <div>
        <Accordion
          fadedToggler
          title={
            <>
              <div>Таможенные платежи +</div>
              <div>коды ТНВЭД + </div>
              <div>Статистика ИТС</div>
              {item.paymentDone && (
                <div>
                  оплачены{' '}
                  <span role="img" aria-label="success">
                    ✅
                  </span>
                </div>
              )}
            </>
          }
          open={openState.customs}
          onToggle={handleToggle('customs')}
        >
          <div className={styles.Customs}>
            {item.customsPayments?.length > 0 ? (
              <>
                <PaymentsList payments={item.customsPayments} />
                <Title as="h3">Платежное поручение</Title>
                <div className={styles.ChecksList}>
                  {item.checks.map((check, idx) => (
                    <UploadedDocument
                      key={check}
                      name={getDocName({ doc: check, id: 'check', idx })}
                      link={check}
                    />
                  ))}
                </div>
                <div className={styles.Submit__Checks}>
                  <Button
                    disabled={item.paymentDone || item.checks.length === 0}
                    filled
                    onClick={() => dispatch(confirmPayments(toast))}
                  >
                    Оплачено
                  </Button>
                </div>
              </>
            ) : (
              <>
                <PaymentsNotReady />
                <div className={styles.ButtonArea}>
                  <Button filled onClick={() => setShowCustomsSurvey(true)}>
                    Рассчитать
                  </Button>
                </div>
              </>
            )}
          </div>
        </Accordion>
        <Accordion
          fadedToggler
          title="История по работе с заявкой"
          open={openState.history}
          onToggle={handleToggle('history')}
        >
          <History createdAt={item.createdAt} history={item.history} />
        </Accordion>
      </div>
      <div>
        <Accordion
          fadedToggler
          title="Таможенная Декларация на товары"
          open={openState.declaration}
          onToggle={handleToggle('declaration')}
        >
          <Declaration />
        </Accordion>
        <Accordion
          fadedToggler
          title={chatTitle}
          open={openState.chat}
          fluid={false}
          onToggle={handleToggle('chat')}
        >
          <div className={chatStyles.Chat}>
            <MessageList chatMessage={chatMessage} messages={messages} />
            {item.declarant && (
              <div className={chatStyles.NewMessage}>
                <Input
                  onChange={(e) => {
                    const { value } = e.target;
                    setNewMessage(value);
                  }}
                  onEnter={handleSend}
                  value={newMessage}
                  className={chatStyles.NewMessage__Input}
                  placeholder={
                    messages.length > 0
                      ? 'Напишите сообщение... 1 '
                      : 'Начните общение по заявке ⏳'
                  }
                />
                <div
                  className={chatStyles.NewMessage__IconWrapper}
                  onClick={handleSend}
                >
                  <img src={Send} alt="send" />
                </div>
              </div>
            )}
          </div>
        </Accordion>
      </div>
      <Survey
        creatable
        open={showCustomsSurvey}
        onClose={() => setShowCustomsSurvey(false)}
        options={CUSTOM_QUERIES}
        onSubmit={(options) => dispatch(saveCustomsPayments(options, toast))}
        title="Укажите таможенные платежи"
        textPlaceholder="Сумма"
        requiredInput
        submitButtonText="Рассчитать"
      />
    </div>
  );
};
