import { FILTERS } from '../../consts';

export const getAccountsClientsFilters = (state) =>
  state.filters[FILTERS.ACCOUNTS_CLIENTS];

export const getAccountsDeclarantsFilters = (state) =>
  state.filters[FILTERS.ACCOUNTS_DECLARANTS];

export const getAccountsPartnersFilters = (state) =>
  state.filters[FILTERS.ACCOUNTS_PARTNERS];

export const getAccountsLogistsFilters = (state) =>
  state.filters[FILTERS.ACCOUNTS_LOGISTS];

export const getAccountTransactionsFilters = (state) =>
  state.filters[FILTERS.ACCOUNT_TRANSACTIONS];

export const getAllTransactionsFilters = (state) =>
  state.filters[FILTERS.ALL_TRANSACTIONS];

export const getPaymentsCompletedFilters = (state) =>
  state.filters[FILTERS.PAYMENTS_COMPLETED];

export const getUserPaymentsFilters = (state) =>
  state.filters[FILTERS.PAYMENTS_USER_HISTORY];

export const getDeclarantsFilters = (state) =>
  state.filters[FILTERS.DECLARANTS_TO_VERIFY];

export const getDraftFilters = (state) => state.filters[FILTERS.DRAFTS];

export const getRequestListFilters = (state) =>
  state.filters[FILTERS.REQUESTS_LIST];

export const getRequestCreatedListFilters = (state) =>
  state.filters[FILTERS.REQUESTS_CREATED_LIST];

export const getRequestClientCompletedFilters = (state) =>
  state.filters[FILTERS.REQUESTS_CLIENT_COMPLETED];

export const getRequestPartnerCompletedFilters = (state) =>
  state.filters[FILTERS.REQUESTS_PARTNER_COMPLETED];

export const getRequestInProgressFilters = (state) =>
  state.filters[FILTERS.REQUESTS_IN_PROGRESS];

export const getRequestCompletedFilters = (state) =>
  state.filters[FILTERS.REQUESTS_COMPLETED];

export const getAllRequestsFilters = (state) =>
  state.filters[FILTERS.ALL_REQUESTS];

export const getAllUsersFilters = (state) => state.filters[FILTERS.ALL_USERS];

export const getDeclarantsAllUsersFilters = (state) =>
  state.filters[FILTERS.DECLARANTS_ALL_USERS];

export const getDeclarantsAccreditedUsersFilters = (state) =>
  state.filters[FILTERS.DECLARANTS_ACCREDITED_USERS];

export const getPartnersFilters = (state) => state.filters[FILTERS.PARTNERS];

export const getLogistsAllUsersFilters = (state) =>
  state.filters[FILTERS.LOGISTS_ALL_USERS];

export const getLogistsAccreditedUsersFilters = (state) =>
  state.filters[FILTERS.LOGISTS_ACCREDITED_USERS];  

export const getClientsUsersPeopleFilters = (state) =>
  state.filters[FILTERS.CLIENTS_USERS_PEOPLE];

export const getClientsUsersCompaniesFilters = (state) =>
  state.filters[FILTERS.CLIENTS_USERS_COMPANIES];

export const getAllRequestsDoneFilters = (state) =>
  state.filters[FILTERS.ALL_REQUESTS_DONE];

export const getAllRequestsInProgressFilters = (state) =>
  state.filters[FILTERS.ALL_REQUESTS_IN_PROGRESS];

export const getRequestPoolFilters = (state) =>
  state.filters[FILTERS.REQUESTS_POOL];

export const getAllMyRequestsLogisticsFilters = (state) =>
  state.filters[FILTERS.ALL_MY_LOGISTICS_REQUESTS];

export const getAllMyInWorkLogisticsFilters = (state) =>
  state.filters[FILTERS.ALL_MY_LOGISTICS_IN_WORK];

export const getAllMyArchiveLogisticsFilters = (state) =>
  state.filters[FILTERS.ALL_MY_LOGISTICS_ARCHIVE];

export const getMyOffersPacksFilters = (state) =>
  state.filters[FILTERS.MY_OFFERS_PACKS];

export const getMyOffersFromPackFilters = (state) =>
  state.filters[FILTERS.MY_OFFERS_FROM_PACK];
  
export const getFiltersByFilterKey = (filterKey) => (state) =>
  state.filters[filterKey];

export const getSortFieldByFilterKey = (filterKey) => (state) =>
  state.filters[filterKey]?.sortField;

export const getSortDirectionByFilterKey = (filterKey) => (state) =>
  state.filters[filterKey]?.sortDirection;




