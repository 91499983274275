import React from 'react';

import { Title } from '../shared/Title';
import { SocialIcon } from './SocialIcon';
import { SOCIALS } from '../../configs/socials';
import styles from './SocialBlock.module.scss';

export const SocialBlock = () => (
  <div className={styles.SocialBlock}>
    <Title as="h4">Наши социальные сети</Title>
    <div className={styles.SocialBlock__IconArea}>
      {SOCIALS.map((props) => (
        <SocialIcon key={props.alt} {...props} />
      ))}
    </div>
  </div>
);
