import React from 'react';

import { UploadRequestArea } from '../../shared/UploadArea/UploadRequestArea';
import {
  TECHNICAL_DOCS,
  CONTRACT_DOCS,
  OTHER_DOCS,
  PERMISSION_DOCS,
} from '../../../configs/requestDocs';

export const DocumentsRequest = ({ disabled, documents, viewOnly }) => {
  const isRequestDone = documents.status === 'done';
  return (
    <div>
      <UploadRequestArea
        files={documents.docs}
        id="docs"
        requestDocs={TECHNICAL_DOCS}
        disabled={disabled}
        viewOnly={viewOnly}
        showButton={!isRequestDone}
      >
        Техническое описание товаров из спецификации
      </UploadRequestArea>
      <UploadRequestArea
        files={documents.contract}
        id="contract"
        requestDocs={CONTRACT_DOCS}
        disabled={disabled}
        viewOnly={viewOnly}
        showButton={!isRequestDone}
      >
        ВЭД контракт и дополнения к нему
      </UploadRequestArea>
      <UploadRequestArea
        files={documents.misc}
        id="misc"
        requestDocs={OTHER_DOCS}
        disabled={disabled}
        viewOnly={viewOnly}
        showButton={!isRequestDone}
      >
        Прочие документы
      </UploadRequestArea>
      <UploadRequestArea
        files={documents.permits}
        id="permits"
        requestDocs={PERMISSION_DOCS}
        disabled={disabled}
        viewOnly={viewOnly}
        showButton={!isRequestDone}
      >
        Разрешительная документация на ввоз/вывоз товаров
      </UploadRequestArea>
    </div>
  );
};
