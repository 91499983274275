import React, { useState, useRef, useEffect } from 'react';
import styles from './ButtonL.module.sass';
import classnames from 'classnames';

export const ButtonL = ({ setStateCb, text }) => {

	const handlerClick = (event) => {
		let btn;
		if (btn = event.target.closest("."+classnames(styles.btn))){			
			if(setStateCb){
				setStateCb();
			}
		}
	}

	return(
		<div className={classnames(styles.btn)} onClick={handlerClick}>{text}</div>
	);

}