import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { format } from 'date-fns';

import { Accordion } from '../shared/Accordion';
import { Button } from '../shared/Button';
import { getUser, updateSetValue, updateUserValue } from '../../store/users';
import { PersonalInput } from '../Personal/PersonalInput';

import styles from './PartnerInformation.module.scss';

const FIELDS = [
  {
    label: 'Имя',
    key: 'name',
  },
  {
    label: 'Фамилия',
    key: 'surname',
  },
  {
    label: 'Дата регистрации',
    key: 'createdAt',
    renderValue: (value) =>
      value ? format(new Date(value), 'dd.MM.yyyy') : 'информация недоступна',
  },
  {
    label: 'E-mail',
    key: 'email',
  },
  {
    label: 'Телефон',
    key: 'phone',
  },
  {
    label: 'Процентная ставка, %',
    key: 'partnerRate',
    render: (value) => value.toFixed(2),
  },
  {
    label: 'id',
    key: '_id',
  },
];

export const PartnerInformation = ({ profile }) => {
  const [infoOpen, setInfoOpen] = useState(true);
  const [edited, setEdited] = useState(false);
  const dispatch = useDispatch();
  const handleToggle = () => setInfoOpen(!infoOpen);
  const rate = useSelector(getUser).partnerRate;

  const changeState = () => {
    if (!edited) {
      setEdited(true);
    }
  };

  const handleChange = (e) => {
    dispatch(updateUserValue({ partnerRate: e.target.value }));
    changeState();
  };

  const handleSaveValue = () => {
    dispatch(updateSetValue({ partnerRate: rate }, profile._id));
    setEdited(false);
    toast.success('Изменения сохранены');
  };

  return (
    <Accordion
      open={infoOpen}
      title="Личная информация"
      onToggle={handleToggle}
      fadedToggler
    >
      <div className={styles.Wrapper}>
        <div className={styles.PersonalFields}>
          {FIELDS.map((field) => {
            const { key, renderValue } = field;
            const fieldValue = profile[key];
            const value = renderValue ? renderValue(fieldValue) : fieldValue;

            return key === 'partnerRate' ? (
              <PersonalInput
                key="partnerRate"
                label={field.label}
                value={value}
                editable
                onChange={handleChange}
              />
            ) : (
              <PersonalInput
                key={key}
                label={field.label}
                value={value}
                permitEdit={false}
              />
            );
          })}
        </div>
        {edited && (
          <div className={styles.ButtonArea}>
            <Button filled onClick={handleSaveValue}>
              Сохранить
            </Button>
          </div>
        )}
      </div>
    </Accordion>
  );
};
