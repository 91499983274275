import { LOAD_ITEMS_REQUEST, LOAD_ITEMS_SUCCESS } from './actions';

const initialState = {
  items: [],
  count: 0,
  loading: false,
};

export const paymentRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ITEMS_SUCCESS:
      return {
        ...state,
        count: action.payload.count,
        items: action.payload.items,
        loading: false,
      };

    default:
      return state;
  }
};
