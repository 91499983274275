import { API_ROUTES } from '../consts';
import { ApiHelper } from '../utils';

export const getCompanies = () => ApiHelper.get(API_ROUTES.COMPANIES);

export const loadMyVerifiedCompanies = () => ApiHelper.get(API_ROUTES.MYCOMPANIES);

export const getCompany = (id) =>
  ApiHelper.get(`${API_ROUTES.COMPANIES}/${id}`);

export const updateCompany = (id, payload) =>
  ApiHelper.patch(`${API_ROUTES.COMPANIES}/${id}`, payload);

export const createCompany = (payload) =>
  ApiHelper.post(API_ROUTES.COMPANIES, payload);

export const getSuggestions = (payload) =>
  ApiHelper.post(API_ROUTES.COMPANY_SUGGESTIONS, payload);

export const deleteCompanyById = (id) =>
	ApiHelper.delete(`${API_ROUTES.COMPANIES_DELETE_ONE}/${id}`);