import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { resetPassword } from '../../../api';
import { ROUTES } from '../../../consts';
import { Input } from '../../shared/Input';
import { Button } from '../../shared/Button';
import { Title } from '../../shared/Title';
import { ResetPasswordSchema } from '../../../utils';

import styles from './Login.module.scss';

export const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    try {
      await ResetPasswordSchema.validate(
        { email },
        {
          abortEarly: false,
        }
      );

      const res = await resetPassword({ email });
      if (res.success) {
        setError(null);
        setSuccess(true);
      }
    } catch (err) {
      setError(err.data?.errors?.email);
    }
  };

  return (
    <div className={styles.Login}>
      <Title className={styles.Title}>Восстановление доступа</Title>
      <div className={styles.Desc}>
        Чтобы восстановить доступ, укажите вашу почту, вам придет письмо с новым
        паролем
      </div>
      {success ? (
        <Title className={styles.Title}>
          Письмо с ссылкой на востановление пароля высланно на указанный email.
        </Title>
      ) : (
        <div className={styles.InputWrapper}>
          <div className={styles.InputWrapper__Title}>Почта</div>
          <Input
            value={email}
            error={error}
            placeholder="Введите почту"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className={styles.InputWrapper__Error}>{error}</div>
        </div>
      )}

      <div className={styles.ButtonArea}>
        {!success && (
          <Button filled onClick={handleSubmit}>
            Отправить письмо
          </Button>
        )}
        <div className={styles.RegisterLink}>
          <Link to={ROUTES.FULL_AUTH_GATE_LOGIN}>Вернуться назад</Link>
        </div>
      </div>
    </div>
  );
};
