import React, { useState } from 'react';

import Question from '../../../assets/Question.svg';
import InfoCircle from '../../../assets/InfoCircle.svg';
import Exclamation from '../../../assets/Exclamation.svg';

import { useMedia } from '../../../hooks';

import styles from './Tooltip.module.scss';

export const Tooltip = ({ children, size, withInfo, iconSrc = "question" }) => {
  const mobile = useMedia('(max-width: 56.25em)');
  const [popoverVisible, setPopoverVisible] = useState(false);

  return (
    <div className={styles.Popover}>
      <div
        className={styles.IconWrapper}
        onMouseOver={() => !mobile && setPopoverVisible(true)}
        onMouseLeave={() => !mobile && setPopoverVisible(false)}
        style={{
          height: size ? `${size + 1}px` : '15px',
          width: size ? `${size + 1}px` : '15px',
        }}
      >
        <img
          src={(iconSrc == "question")? Question : Exclamation}
          alt="tooltip"
          onClick={(e) => {
            e.stopPropagation();
            setPopoverVisible(!popoverVisible);
          }}
          width={size ? `${size}px` : '14px'}
          height={size ? `${size}px` : '14px'}
          className={styles.Icon}
        />
      </div>
      {popoverVisible && (
        <div
          className={styles.Popover__Text}
          style={{
            left: size ? `${size / 2}px` : '7px',
          }}
        >
          <div>{children}</div>
          {withInfo && (
            <div className={styles.Popover__Info}>
              <img src={InfoCircle} className={styles.Image} alt="info" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
