import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { ROUTES } from '../../consts';
import {
  getCommonInfo,
  getCompany,
  getBankInfo,
  getExtraInfo,
  setBankInfo,
  setCommonInfo,
  setExtraInfo,
  loadBankAccounts,
  loadCompany,
} from '../../store/companies';
import { AccountCardView } from './AccountCardView';
import { Tabs } from '../shared/Tabs';
import { Accordion } from '../shared/Accordion';
import { Input } from '../shared/Input';
import { Checkbox } from '../shared/Checkbox';
import { Title } from '../shared/Title';

import styles from './InfoView.module.scss';

  const CompanyTabs = ({id, companyId})=>{
    
    return (
      <Tabs
        tabs={[
          {
            title: 'Общие сведения о компании',
            route: ROUTES.FULL_USERS_CLIENT_COMPANY_INFO, 
            href: `../${companyId}/info`,
            visible: true,
          },
          {
            title: 'Документы компании',
            route: ROUTES.FULL_USERS_CLIENT_COMPANY_DOCS, 
            href: `../${companyId}/docs`,
            visible: true,
          }
        ]}
      />
  )}; 


export const InfoView = () => {
  const dispatch = useDispatch();
  const commonInfo = useSelector(getCommonInfo);
  const extraInfo = useSelector(getExtraInfo);
  const bankInfo = useSelector(getBankInfo);
  const company = useSelector(getCompany);
  let { companyId } = useParams();
  let { id } = useParams(); 

  const handleCommonInfoToggle = () =>
    dispatch(setCommonInfo({ open: !commonInfo.open }));

  const handleExtraInfoToggle = () =>
    dispatch(setExtraInfo({ open: !extraInfo.open }));

  const handleBankInfoToggle = () =>
    dispatch(setBankInfo({ open: !bankInfo.open }));

  useEffect(() => {
    dispatch(loadCompany(companyId));
    dispatch(loadBankAccounts(companyId));
  }, [dispatch, companyId]);

  return (
    <div className={styles.Info}>
      <CompanyTabs  id={id} companyId={companyId}/>
      <Accordion
        open={commonInfo.open}
        title="Общие сведения о компании"
        onToggle={handleCommonInfoToggle}
      >
        <div className={styles.AccordionDouble}>
          <div className={styles.AccordionHalf}>
            <div className={styles.Label}>ИНН</div>
            <div className={styles.InputWrapper}>
              <Input disabled placeholder="ИНН" value={company.itn} lightgrey />
            </div>
            <div className={styles.Label}>Наименование компании</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Наименование компании"
                value={company.name}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Форма управления</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Форма управления"
                value={company.form}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Тип организации</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Тип организации"
                value={company.type}
                lightgrey
              />
            </div>
            <div className={styles.Label}>КПП</div>
            <div className={styles.InputWrapper}>
              <Input disabled placeholder="КПП" value={company.kpp} lightgrey />
            </div>
            <div className={styles.Label}>ОГРН</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="ОГРН"
                value={company.ogrn}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Дата регистрации</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Дата регистрации"
                type="date"
                value={format(new Date(company.registrationDate), 'yyyy-MM-dd')}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Статус организации</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Статус организации"
                value={company.status}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Генеральный директор</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Генеральный директор"
                value={company.ceo}
                lightgrey
              />
            </div>
          </div>
          <div className={styles.AccordionHalf}>
            <div className={styles.ContactInfo}>
              <div className={styles.ContactInfo__Title}>
                <Title as="h3">Контактная информация</Title>
              </div>
              <div className={styles.Label}>Номер телефона</div>
              <div className={styles.InputWrapper}>
                <Input
                  placeholder="Номер телефона"
                  value={company.phone}
                  lightgrey
                  disabled
                />
              </div>
              <div className={styles.Label}>Почта</div>
              <div className={styles.InputWrapper}>
                <Input
                  placeholder="Почта"
                  value={company.email}
                  lightgrey
                  disabled
                />
              </div>
              <div className={styles.Label}>Сайт</div>
              <div className={styles.InputWrapper}>
                <Input
                  placeholder="Сайт"
                  value={company.site}
                  lightgrey
                  disabled
                />
              </div>
              <div className={styles.ContactInfo__ButtonWrapper}></div>
            </div>
          </div>
        </div>
      </Accordion>
      {company.legalAddress && (
        <Accordion
          open={extraInfo.open}
          title="Дополнительные сведения о компании"
          onToggle={handleExtraInfoToggle}
        >
          <div className={styles.AccordionContent}>
            <div className={styles.SubTitleWrapper}>
              <Title as="h3">Юридический адрес компании</Title>
            </div>
            <div className={styles.Label}>Полный адрес компании</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Полный адрес компании"
                value={company.legalAddress.fullAddress}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Страна</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Страна"
                value={company.legalAddress.country}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Город</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Город"
                value={company.legalAddress.city}
                lightgrey
              />
            </div>
            <div className={styles.Label}>Индекс</div>
            <div className={styles.InputWrapper}>
              <Input
                disabled
                placeholder="Индекс"
                value={company.legalAddress.postalCode}
                lightgrey
              />
            </div>
            <div className={styles.InputWrapper}>
              <Checkbox checked={company.commonAddress}>
                Совпадает с фактическим адресом
              </Checkbox>
            </div>
            <div className={styles.ActualAddressInfo}>
              <>
                <div className={styles.SubTitleWrapper}>
                  <Title as="h3">Фактический адрес компании</Title>
                </div>
                <div className={styles.Label}>Полный адрес</div>
                <div className={styles.InputWrapper}>
                  <Input
                    placeholder="Полный адрес"
                    value={company.actualAddress?.fullAddress}
                    lightgrey
                    disabled
                  />
                </div>
                <div className={styles.Label}>Страна</div>
                <div className={styles.InputWrapper}>
                  <Input
                    placeholder="Страна"
                    value={company.actualAddress?.country}
                    lightgrey
                    disabled
                  />
                </div>
                <div className={styles.Label}>Город</div>
                <div className={styles.InputWrapper}>
                  <Input
                    value={company.actualAddress?.city}
                    placeholder="Город"
                    lightgrey
                    disabled
                  />
                </div>
                <div className={styles.Label}>Индекс</div>
                <div className={styles.InputWrapper}>
                  <Input
                    placeholder="Индекс"
                    value={company.actualAddress?.postalCode}
                    lightgrey
                    disabled
                  />
                </div>

                <div className={styles.ContactInfo__ButtonWrapper}></div>
              </>
            </div>
          </div>
        </Accordion>
      )}
      <Accordion
        open={bankInfo.open}
        title="Банковские реквизиты"
        onToggle={handleBankInfoToggle}
      >
        <div className={styles.Accounts}>
          {company.bankAccounts?.map((account) => (
            <AccountCardView key={account._id} account={account} />
          ))}
        </div>
      </Accordion>
    </div>
  );
};
