import history from '../../components/App/history';
import { createPayment } from '../../api/payments';

import { getRequestForm } from './selectors';
import { ROUTES } from '../../consts';

export const UPDATE_REQUEST_FORM = '@account/UPDATE_REQUEST_FORM';
export const CLEAR_REQUEST_FORM = '@account/CLEAR_REQUEST_FORM';

export const updateRequestForm = (payload) => ({
  type: UPDATE_REQUEST_FORM,
  payload,
});

export const clearRequestForm = () => ({
  type: CLEAR_REQUEST_FORM,
});

export const savePayment = (navigate) => async (dispatch, getState) => {
  try {
    const state = getState();
    const requestForm = getRequestForm(state);
    const data = await createPayment({ ...requestForm, type: 'refill' });

    const paymentId = data?.payment?._id;
    if (paymentId) {
      navigate(ROUTES.ACCOUNT_HISTORY);
      //dispatch(clearRequestForm());
      window.open(`/account/refill/requisits/${paymentId}`);
    }
  } catch (error) {
    console.log(error);
  }
};

export const savePaymentOut = (personalAccount, navigate) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const amount = getRequestForm(state).amount;
    const data = await createPayment({
      amount,
      personalAccount,
      type: 'withdrawals',
    });

    const paymentId = data?.payment?._id;
    if (paymentId) {
      navigate(ROUTES.ACCOUNT_HISTORY);
      dispatch(clearRequestForm());
    }
  } catch (error) {
    console.log(error);
  }
};
