import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import styles from './SystemAccountRequisites.module.sass';
import { Input } from '../Logistics/Offers/OffersMaker/Input';
import { ButtonL } from '../shared/Button';

import {
	getCurrentSystemAccount,
	getEditorSystemAccount,
	getSystemAccount,
	updateEditorItemValue,
	getBank,
	saveSystemAccount
} from '../../store/systemAccount';


export const SystemAccountRequisites = () => {

	const dispatch = useDispatch();
	const currentSystemAccount = useSelector(getCurrentSystemAccount);
	const editorSystemAccount = useSelector(getEditorSystemAccount);



	const handleBankGuess = debounce((query) => dispatch(getBank(query)), 2000);

	const handleBikUpdate = (state) => {				
		dispatch(updateEditorItemValue({item:"bik", state, clearPush: true}));		
		if (state.value && state.correct) handleBankGuess(state.value);
	};	

	useEffect(()=>{
		dispatch(getSystemAccount);
	},[]);

	return (
        <>
        <div className={classnames(styles.wrap_panel)}>
	        <div className={classnames(styles.view_panel)}>
	        	<div className={classnames(styles.text_wrap)}>
		        	<div className={classnames(styles.text_name)}>БИК</div>
		        	<div className={classnames(styles.text_descr)}>{currentSystemAccount.bik}</div>
	        	</div>
	        	<div className={classnames(styles.text_wrap)}>
		        	<div className={classnames(styles.text_name)}>Расчетный счет</div>
		        	<div className={classnames(styles.text_descr)}>{currentSystemAccount.bankAccount}</div>
	        	</div>
	        	<div className={classnames(styles.text_wrap)}>
		        	<div className={classnames(styles.text_name)}>Наименование</div>
		        	<div className={classnames(styles.text_descr)}>{currentSystemAccount.name}</div>
	        	</div>
	        	<div className={classnames(styles.text_wrap)}>
		        	<div className={classnames(styles.text_name)}>Корреспондентский счет</div>
		        	<div className={classnames(styles.text_descr)}>{currentSystemAccount.corrAccount}</div>
	        	</div>
	        	<div className={classnames(styles.text_wrap)}>
		        	<div className={classnames(styles.text_name)}>ИНН</div>
		        	<div className={classnames(styles.text_descr)}>{currentSystemAccount.inn}</div>
	        	</div>
	        	<div className={classnames(styles.text_wrap)}>
		        	<div className={classnames(styles.text_name)}>КПП</div>
		        	<div className={classnames(styles.text_descr)}>{currentSystemAccount.kpp}</div>
	        	</div>	        		        		        		        		        	
	        </div>
	        <div className={classnames(styles.view_panel)}>
				<Input 
					inputName="БИК банка"
					placeHolder="Введите БИК"
					rule="number"
					inputValue={editorSystemAccount.bik}
					setStateCb={(state)=>{handleBikUpdate(state)}} 		
				/>
				<Input 
					inputName="Расчетный счет"
					placeHolder="Введите расчетный счет"
					rule="number"
					inputValue={editorSystemAccount.bankAccount}
					setStateCb={(state)=>{dispatch(updateEditorItemValue({item:"bankAccount", state}))}} 		
				/>
				<Input 
					inputName="Наименование"					
					rule="russian"
					inputValue={editorSystemAccount.name}
					disabled						
				/>
				<Input 
					inputName="Корреспондентский счет"					
					rule="number"
					inputValue={editorSystemAccount.corrAccount}
					disabled							
				/>	
				<Input 
					inputName="ИНН"					
					rule="number"
					inputValue={editorSystemAccount.inn}
					disabled							
				/>
				<Input 
					inputName="КПП"					
					rule="number"
					inputValue={editorSystemAccount.kpp}
					disabled							
				/>
				<ButtonL
					text="Изменить"
					setStateCb={()=>{dispatch(saveSystemAccount(toast))}}					
				/>																			
	        </div>
        </div>
		</>        
	);
}

