import { API_ROUTES } from '../consts';
import { ApiHelper, generateQuery } from '../utils';

export const createRejectionReason = (payload) =>
  ApiHelper.post(API_ROUTES.REJECTION_REASONS, payload);

export const getRejectionReasonsByDeclarantId = (id, filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.REJECTION_REASONS}/${id}${queryString}`);
};

export const updateRejectionReason = (id, payload) =>
  ApiHelper.patch(`${API_ROUTES.REJECTION_REASONS}/${id}`, payload);
