import { pick } from 'lodash';

import {
  createBankAccount,
  createCompany,
  getBankData,
  getCompanies,
  getUserCompanies,
  getCompanyById,
  getCompany,
  getCompanyBankAccounts,
  updateCompany,
  deleteBankAccount as deleteBankAccountApi,
  deleteCompanyById,
} from '../../api';

import { getCompany as getCompanyState} from './selectors';
import { initialState } from './reducer.js';
import { getDeliveryInfo, updateNewRequest } from '../requests';
import { updateRequestForm } from '../account';
import {
  getCommonInfo,
  getCompanies as selectCompanies,
  getCompany as selectCompany,
  getExtraInfo,
  getBankInfo,
} from './selectors';

export const FETCH_ITEMS_REQUEST = '@companies/FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = '@companies/FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = '@companies/FETCH_ITEMS_FAILURE';
export const FETCH_ITEM_REQUEST = '@companies/FETCH_ITEM_REQUEST';
export const FETCH_ITEM_SUCCESS = '@companies/FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_FAILURE = '@companies/FETCH_ITEM_FAILURE';
export const REMOVE_ITEM = '@companies/REMOVE_ITEM';
export const SET_DEFAULT_ITEM = '@companies/SET_DEFAULT_ITEM';
export const SET_ITEM = '@companies/SET_ITEM';
export const UPDATE_ITEM = '@companies/UPDATE_ITEM';
export const UPDATE_COMPANY_DOCS = '@companies/UPDATE_COMPANY_DOCS';
export const UPDATE_BANK_ACCOUNTS = '@companies/UPDATE_BANK_ACCOUNTS';
export const SET_COMMON_INFO = '@companies/SET_COMMON_INFO';
export const SET_EXTRA_INFO = '@companies/SET_EXTRA_INFO';
export const SET_BANK_INFO = '@companies/SET_BANK_INFO';
export const SET_DOCS_OPEN = '@companies/SET_DOCS_OPEN';
export const SET_CEO_DOCS_OPEN = '@companies/SET_CEO_DOCS_OPEN';
export const ADD_ITEM_REQUEST = '@companies/ADD_ITEM_REQUEST';
export const ADD_ITEM_SUCCESS = '@companies/ADD_ITEM_SUCCESS';
export const ADD_ITEM_FAILURE = '@companies/ADD_ITEM_FAILURE';
export const SET_COMPANY_OFFER = '@companies/SET_COMPANY_OFFER';

export const addItemRequest = () => ({
  type: ADD_ITEM_REQUEST,
});

export const addItemSuccess = (payload) => ({
  type: ADD_ITEM_SUCCESS,
  payload,
});

export const addItemFailure = (payload) => ({
  type: ADD_ITEM_FAILURE,
  payload,
});

export const removeItem = (payload) => ({
  type: REMOVE_ITEM,
  payload  
});

export const setItem = (payload) => ({
  type: SET_ITEM,
  payload,
});

export const setDefaultItem = { type: SET_DEFAULT_ITEM };

export const updateItem = (payload) => ({
  type: UPDATE_ITEM,
  payload,
});

export const setCommonInfo = (payload) => ({
  type: SET_COMMON_INFO,
  payload,
});

export const setExtraInfo = (payload) => ({
  type: SET_EXTRA_INFO,
  payload,
});

export const setBankInfo = (payload) => ({
  type: SET_BANK_INFO,
  payload,
});

export const setDocsOpen = (payload) => ({
  type: SET_DOCS_OPEN,
  payload,
});

export const setCeoDocsOpen = (payload) => ({
  type: SET_CEO_DOCS_OPEN,
  payload,
});

export const fetchItemsRequest = () => ({
  type: FETCH_ITEMS_REQUEST,
});

export const fetchItemsSuccess = (payload) => ({
  type: FETCH_ITEMS_SUCCESS,
  payload,
});

export const fetchItemsFailure = (payload) => ({
  type: FETCH_ITEMS_FAILURE,
  payload,
});

export const fetchItemRequest = () => ({
  type: FETCH_ITEM_REQUEST,
});

export const fetchItemSuccess = (payload) => ({
  type: FETCH_ITEM_SUCCESS,
  payload,
});

export const fetchItemFailure = (payload) => ({
  type: FETCH_ITEM_FAILURE,
  payload,
});

export const setCompanyOffer = (payload) => ({
  type: SET_COMPANY_OFFER,
  payload,
});


export const loadCompanies = async (dispatch, getState) => {
  dispatch(fetchItemsRequest());
  try {
    const data = await getCompanies();
    dispatch(fetchItemsSuccess(data.companies));
    const state = getState();
    const { company } = getDeliveryInfo(state);
    if (!company) {
      const [firstCompany] = data.companies;

      if (firstCompany?._id){

        dispatch(
          updateNewRequest({
            key: 'deliveryInfo',
            subKey: 'company',
            value: firstCompany._id,
          })
        );

        dispatch(setItem(firstCompany));
        dispatch(
          updateRequestForm({
            company: firstCompany._id,
          })
        );

        dispatch(loadBankAccounts(firstCompany._id));
      }

    }
  } catch (error) {
    dispatch(fetchItemsFailure(error));
  }
};

export const removeCompanyById = (id) => async (dispatch, getState) => {
  try{
    const state = getState();
    const data = await deleteCompanyById(id);    
    dispatch(removeItem(data.removedCompany._id));

    if (data.removedCompany._id === getCompanyState(state)?._id){
      console.log("remove company");
      dispatch(setDefaultItem);
      dispatch(
        updateNewRequest({
          key: 'deliveryInfo',
          subKey: 'company',
          value: '',
        })
      );      
      dispatch(loadCompanies);
    }

  }catch(error){
    console.log(error);
  }
}

export const loadUserCompanies = (id) => async (dispatch, getState) => {
  dispatch(fetchItemsRequest());

  try {
    const data = await getUserCompanies(id);

    dispatch(fetchItemsSuccess(data.companies));
    const state = getState();
    const { company } = getDeliveryInfo(state);
    if (!company) {
      const [firstCompany] = data.companies;
      dispatch(
        updateNewRequest({
          key: 'deliveryInfo',
          subKey: 'company',
          value: firstCompany?._id,
        })
      );

      dispatch(
        updateRequestForm({
          company: firstCompany._id,
        })
      );
    }
  } catch (error) {
    dispatch(fetchItemsFailure(error));
  }
};

export const loadCompany = (id) => async (dispatch, getState) => {
  const state = getState();
  const companies = selectCompanies(state);
  const company = companies.find(({ _id }) => _id === id);

  if (company) {
    dispatch(setItem(company));
  } else {
    dispatch(fetchItemRequest());

    try {
      const data = await getCompany(id);
      dispatch(fetchItemSuccess(data.company));
    } catch (error) {
      dispatch(fetchItemFailure(error));
    }
  }
};

export const loadCompanyById = (id) => async (dispatch, getState) => {
  const state = getState();
  const companies = selectCompanies(state);
  const company = companies.find(({ _id }) => _id === id);

  if (company) {
    dispatch(setItem(company));
  } else {
    dispatch(fetchItemRequest());

    try {
      const data = await getCompanyById(id);
      dispatch(fetchItemSuccess(data.company));
    } catch (error) {
      dispatch(fetchItemFailure(error));
    }
  }
};

export const updateContactInfo = (toast) => async (dispatch, getState) => {
  const state = getState();
  const company = selectCompany(state);
  const commonInfo = getCommonInfo(state);
  const contactInfoFields = ['phone', 'email', 'site'];

  const payload = pick(company, contactInfoFields);

  try {
    await updateCompany(company._id, payload);
    toast.success('Контактные данные сохранены');
    dispatch(setCommonInfo({ ...commonInfo, edit: false }));
  } catch (error) {
    console.log(error);
  }
};

export const updateExtraInfo = (toast) => async (dispatch, getState) => {
  const state = getState();
  const company = selectCompany(state);
  const extraInfo = getExtraInfo(state);
  const extraInfoFields = ['legalAddress', 'actualAddress', 'commonAddress'];

  const payload = pick(company, extraInfoFields);

  try {
    await updateCompany(company._id, payload);
    toast.success('Сведения о фактическом адресе компании сохранены');
    dispatch(loadCompanies);

    setTimeout(() => {
      dispatch(setExtraInfo({ ...extraInfo, open: false }));
    }, 200);
  } catch (error) {
    console.log(error);
  }
};

export const updateBankInfo = () => async (dispatch, getState) => {
  const state = getState();
  const company = selectCompany(state);
  const bankInfo = getBankInfo(state);
  const bankInfoFields = ['bank'];

  const payload = pick(company, bankInfoFields);

  try {
    await updateCompany(company._id, payload);

    dispatch(setBankInfo({ ...bankInfo, edit: false }));
  } catch (error) {
    console.log(error);
  }
};

export const updateDocs =
  ({ key, value }) =>
  async (dispatch, getState) => {
    dispatch(updateItem({ key, value }));

    const state = getState();
    const company = selectCompany(state);
    const docsFields = [key];
    const payload = pick(company, docsFields);

    try {
      await updateCompany(company._id, payload);
    } catch (error) {
      console.log(error);
    }
  };

export const updateEsign =
  ({ key, value }) =>
  async (dispatch, getState) => {
    dispatch(updateItem({ key, value }));
    try {
      const state = getState();
      const company = selectCompany(state);
      const esign = [key];
      const payload = pick(company, esign);
      await updateCompany(company._id, payload);
    } catch (error) {
      console.log(error);
    }
  };

export const addCompany = (toast, payload) => async (dispatch) => {
  dispatch(addItemRequest());
  try {
    const data = await createCompany(payload);    
    dispatch(addItemSuccess(data.company));
    dispatch(loadCompanies);
  } catch (error) {
    dispatch(addItemFailure(error));
    toast.error('Компания не найдена');
  }
};

export const loadBankAccounts = (id) => async (dispatch, getState) => {
  try {
    const { bankAccounts } = await getCompanyBankAccounts(id);
    const state = getState();
    const companies = selectCompanies(state);
    dispatch(
      fetchItemsSuccess(
        companies.map((company) => {
          if (company._id === id) {
            return { ...company, bankAccounts };
          }
          return company;
        })
      )
    );
    const [firstAccount] = bankAccounts;
    dispatch(
      updateRequestForm({
        bankAccount: firstAccount ? firstAccount._id : null,
      })
    );

    dispatch(setItem({ bankAccounts })); // все счета от id компании летят в выбранную компанию
  } catch (error) {}
};

export const saveBankAccount = (toast) => async (dispatch, getState) => {
  try {
    const state = getState();
    const company = selectCompany(state);
    const companies = selectCompanies(state);

    await createBankAccount({
      ...company.bank,
      companyId: company._id,
    });
    dispatch(loadBankAccounts(company._id));

    toast.success('Банковский счет сохранен');
  } catch (error) {}
};

export const deleteBankAccount =
  (id, toast) => async (dispatch, getState) => {
    try {
      await deleteBankAccountApi(id);
      const state = getState();
      const company = selectCompany(state);
      const companies = selectCompanies(state);

      dispatch(
        updateItem({
        key: 'bankAccounts',
        value: company.bankAccounts.filter((account) => account._id !== id),
      }));    

      dispatch(
        fetchItemsSuccess(
          companies.map((company) => {
            if (company?.bankAccounts?.length){
              return {...company, bankAccounts: company.bankAccounts.filter((account) => account._id !== id)};
            }
            return company;
          })
        )
      );

      toast.success('Банковский счет удален');
    } catch (error) {
      console.log(error);
    }
  };

export const getBank = (query) => async (dispatch, getState) => {
  try {
    const { bank } = await getBankData({ query });
    if (bank) {
      const state = getState();
      const company = selectCompany(state);
      dispatch(
        updateItem({
          key: 'bank',
          value: {
            ...company.bank,
            name: bank?.value,
            correspondenceAccount: bank?.data?.correspondent_account,
            itn: bank?.data?.inn,
            kpp: bank?.data?.kpp,
          },
        })
      );
    }
  } catch (error) {
    dispatch(addItemFailure(error?.data));
  }
};

export const excludeCompanyDocs =
  (docGroup, link) => async (dispatch, getState) => {
    const state = getState();
    const company = selectCompany(state);
    const value = [...company[docGroup]].filter((doc) => doc !== link);
    const payload = { key: docGroup, value: value };

    dispatch(updateDocs(payload));
  };


export const  updateCompanyOffer = (id, value, toast) => async (dispatch, getState) => {
    try {
      await updateCompany(id, {offer: value});
      dispatch(setCompanyOffer(value));
      toast.success('Оффер сохранен.');
    } catch (error) {
      console.log(error);
      toast.error('Оффер не сохранен, попробуйте позже');
    }
}

