import { createSelector } from 'reselect';

export const getTariffs = (state) => state.tariffs.items;
export const getSupportedCustomPrice = createSelector(
  [getTariffs],
  (tariffs) => {
    return (
      tariffs.find((tariff) => tariff.type === 'supportedCustom')?.price || '?'
    );
  }
);
export const getFasterCustomPrice = createSelector([getTariffs], (tariffs) => {
  return tariffs.find((tariff) => tariff.type === 'fasterCustom')?.price || '?';
});

export const getTariffHash = createSelector([getTariffs], (tariffs) => {
  const res = {};
  tariffs.forEach((tariff) => {
    res[tariff.type] = tariff;
  });

  return res;
});

export const getRequestTariffs = createSelector([getTariffs], (tariffs) => {
  const requestTariffs = {};
  tariffs.forEach((tariff) => {
    if (['declarant', 'light'].includes(tariff.type)) {
      requestTariffs[tariff.type] = tariff;
    }
  });
  return requestTariffs;
});
