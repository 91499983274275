import React from 'react';
import { useSelector } from 'react-redux';

import { Title } from '../../shared/Title';

import styles from './Register.module.scss';
import { getProfile } from '../../../store/profile';

export const VerifyEmail = () => {
  const { email } = useSelector(getProfile);

  return (
    <div className={styles.Register}>
      <Title className={styles.Title}>Регистрация</Title>
      <div className={styles.Desc}>
        На адрес вашей электронной почты{' '}
        <span style={{ color: '#00a4fa' }}>{email}</span> отправлено письмо со
        ссылкой.
        <br />
        Для подтверждения почты перейдите по ссылке
      </div>
    </div>
  );
};
