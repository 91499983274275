import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getDeclarant } from '../../store/verification';
import { Title } from '../shared/Title';
import { HighestEducationCheck } from './HighestEducationCheck';
import { EducationCoursesCheck } from './EducationCoursesCheck';
import { ButtonCheckBlock } from './ButtonCheckBlock';
import { Accordion } from '../shared/Accordion';
import { EDUCATION_REJECT_REASON } from '../../configs/education';
import { DOCUMENTS_STEPS } from '../../consts';
import RedCross from '../../assets/RedCross.svg';
import GreenCheckmark from '../../assets/GreenCheckmark.svg';
import YellowQuestion from '../../assets/YellowQuestion.svg';

import styles from './EducationFormCheck.module.scss';

export const EducationFormCheck = ({
  education,
  declarantId,
  section = 'education',
}) => {
  const declarant = useSelector(getDeclarant);

  const step = declarant?.education.step;
  const { FAILED_REVIEW, PASSED_REVIEW, IN_REVIEW } = DOCUMENTS_STEPS;
  const mark =
    {
      [FAILED_REVIEW]: RedCross,
      [PASSED_REVIEW]: GreenCheckmark,
      [IN_REVIEW]: YellowQuestion,
    }[step] || null;

  const [educationOpen, setEducationOpen] = useState(true);
  const handleToggle = () => setEducationOpen(!educationOpen);

  return (
    <Accordion
      title={
        <div className={styles.Title}>
          {mark && <img src={mark} alt="mark" className={styles.Title__Img} />}
          <div className={styles.Title__Text}>Образование</div>
        </div>
      }
      open={educationOpen}
      onToggle={handleToggle}
      fadedToggler
    >
      <div>
        <Title as="h4">Данные о высшем образовании</Title>
        <HighestEducationCheck education={education} />
        <Title as="h4">Дополнительные курсы и сертификаты</Title>
        <EducationCoursesCheck courses={education.courses} />
      </div>
      <div className={styles.ButtonBlock}>
        <ButtonCheckBlock
          declarantId={declarantId}
          section={section}
          config={EDUCATION_REJECT_REASON}
          selectPlaceholder="Причина отказа"
        />
      </div>
    </Accordion>
  );
};
