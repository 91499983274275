import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES } from '../consts';
import { Login, ResetPassword, SetNewPassword } from '../components/Auth/Login';
import {
  RegisterClient,
  VerifyEmail,
  VerifyEmailByToken
} from '../components/Auth/Register';
import { withLoading } from '../components/hocs/withLoading';

export const AuthGate = withLoading(() => (
  <main>
    <Routes>
      <Route path={ROUTES.AUTH_GATE_LOGIN} element={<Login />} />
      <Route
        path={ROUTES.AUTH_GATE_VERIFY_EMAIL_BY_TOKEN}
        element={<VerifyEmailByToken />}
      />
      <Route path={ROUTES.AUTH_GATE_RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.AUTH_GATE_SET_NEW_PASSWORD} element={<SetNewPassword />} />
      <Route path={ROUTES.AUTH_GATE_VERIFY_EMAIL} element={<VerifyEmail />} />
      <Route path={ROUTES.AUTH_GATE_REGISTER_CLIENT} element={<RegisterClient />} />
      <Route path="*" element={<Navigate to={ROUTES.FULL_AUTH_GATE_LOGIN} />} />      
    </Routes>
  
  </main>
));
