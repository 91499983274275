import { toDate } from '../../utils';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import {
  INIT_STATE,
  departureCountryList,
  arrivalCountryList,
  departureCityPortList,
  departureStationList,
  arrivalFromPortStationList,
  arrivalStraightStationList,
  arrivalCityPortList,
  arrivalPortTerminalList,
  subsetPortTerminalList,
  cityTerminal,
  initParams } from './consts';

import { getManagerLogisticsOffersPack } from '../../api';

import {
	getTrainCountryDeparture,
	getTrainStationDepartureList,
	getTrainTrainView,
	getTrainTrainViewByIdx,
	getSeaStationArrivalList,
	getSeaTrainView,
	getSeaCountryDeparture,
	getSeaPortDepartureList,
	getSeaSeaView,
	getSeaTrainViewByIdx,
	getSeaSeaViewByIdx,
	getSeaConditionsCarriage,
	getSeaPaymentTerms,
	getTrainConditionsCarriage,
	getTrainPaymentTerms,
	getSeaField,
	getTrainField,
	getTrainCityArrival,
	getSeaCityArrival,
	getSeaTerminal,
	getMakeOffers,
	getMakeOffersSea,
	getMakeOffersTrain,
	getSeaCustoms} from './selectors';

import { getCompanies } from '../companies';
import { setMessageBoxState, initMessageBox } from '../modalMessage';
import { sendManagerNewOffersPack } from '../../api/logistics';


export const SET_DEFAULT_STATE = '@makeOffers/SET_DEFAULT_STATE';
export const LOADING_OFFERS_PACK = '@makeOffers/LOADING_OFFERS_PACK';


// SEA AND TRAIN
export const SET_DEFAULT_SEA_STATE = '@makeOffers/SET_DEFAULT_SEA_STATE';
export const SET_PANEL_STATE = '@makeOffers/SET_PANEL_STATE';
export const SET_SEA_NAME = '@makeOffers/SET_SEA_NAME';
export const SET_SEA_EXPIRE = '@makeOffers/SET_SEA_EXPIRE';
export const SET_SEA_TERMINAL = '@makeOffers/SET_SEA_TERMINAL';
export const SET_SEA_CUSTOMS = '@makeOffers/SET_SEA_CUSTOMS';
export const SET_SEA_SUB_PANEL_STATE = '@makeOffers/SET_SEA_SUB_PANEL_STATE';
export const SET_SEA_COUNTRY_DEPARTURE = '@makeOffers/SET_SEA_COUNTRY_DEPARTURE';
export const SET_SEA_COUNTRY_ARRIVAL = '@makeOffers/SET_SEA_COUNTRY_ARRIVAL';
export const UPDATE_SEA_TRAIN_VIEW_ITEMS = '@makeOffers/UPDATE_SEA_TRAIN_VIEW_ITEMS';
export const REMOVE_PORT_DEPARTURE_IN_LIST = '@makeOffers/REMOVE_PORT_DEPARTURE_IN_LIST';
export const UPDATE_SEA_SEA_VIEW_ITEMS = '@makeOffers/UPDATE_SEA_SEA_VIEW_ITEMS';
export const RECOVER_CITY_PORT_DEPARTURE_IN_LIST = '@makeOffers/RECOVER_CITY_PORT_DEPARTURE_IN_LIST';
export const REMOVE_STATION_ARRIVAL_IN_LIST = '@makeOffers/REMOVE_STATION_ARRIVAL_IN_LIST';
export const RECOVER_STATION_ARRIVAL_IN_LIST = '@makeOffers/RECOVER_STATION_ARRIVAL_IN_LIST';
export const CHANGE_SEA_TRAIN_VIEW_FIELD = '@makeOffers/CHANGE_SEA_TRAIN_VIEW_FIELD';
export const CHANGE_SEA_SEA_VIEW_FIELD = '@makeOffers/CHANGE_SEA_SEA_VIEW_FIELD';
export const CHANGE_SEA_TEXT_AREA = '@makeOffers/CHANGE_SEA_TEXT_AREA';
export const ADD_SEA_INCORRECT_FIELDS = '@makeOffers/ADD_SEA_INCORRECT_FIELDS';
export const REMOVE_SEA_INCORRECT_FIELDS = '@makeOffers/REMOVE_SEA_INCORRECT_FIELDS';


// STRAIGHT TRAIN
export const SET_DEFAULT_TRAIN_STATE = '@makeOffers/SET_DEFAULT_TRAIN_STATE';
export const SET_TRAIN_NAME = '@makeOffers/SET_TRAIN_NAME';
export const SET_TRAIN_EXPIRE = '@makeOffers/SET_TRAIN_EXPIRE';
export const SET_TRAIN_CITY = '@makeOffers/SET_TRAIN_CITY';
export const SET_TRAIN_COUNTRY_DEPARTURE = '@makeOffers/SET_TRAIN_COUNTRY_DEPARTURE';
export const UPDATE_TRAIN_TRAIN_VIEW_ITEMS = '@makeOffers/UPDATE_TRAIN_TRAIN_VIEW_ITEMS';
export const REMOVE_STATION_DEPARTURE_IN_LIST = '@makeOffers/REMOVE_STATION_DEPARTURE_IN_LIST';
export const RECOVER_STATION_DEPARTURE_IN_LIST = '@makeOffers/RECOVER_STATION_DEPARTURE_IN_LIST';
export const CHANGE_TRAIN_TRAIN_VIEW_FIELD = '@makeOffers/CHANGE_TRAIN_TRAIN_VIEW_FIELD';
export const CHANGE_TRAIN_TEXT_AREA = '@makeOffers/CHANGE_TRAIN_TEXT_AREA';
export const ADD_TRAIN_INCORRECT_FIELDS = '@makeOffers/ADD_TRAIN_INCORRECT_FIELDS';
export const REMOVE_TRAIN_INCORRECT_FIELDS = '@makeOffers/REMOVE_TRAIN_INCORRECT_FIELDS';



const setDefaultAllState = (payload) => ({
  type: SET_DEFAULT_STATE,
  payload,    
});

const setDefaultSeaState = (payload) => ({
  type: SET_DEFAULT_SEA_STATE,
  payload,    
});

const setDefaultTrainState = (payload) => ({
  type: SET_DEFAULT_TRAIN_STATE,
  payload,    
});

const recoverStationDepartureInList = (payload) => ({ 
  type: RECOVER_STATION_DEPARTURE_IN_LIST,
  payload,    
});

const updateTrainTrainViewItems = (payload) => ({ 
  type: UPDATE_TRAIN_TRAIN_VIEW_ITEMS,
  payload,    
});

const updateSeaTrainViewItems = (payload) => ({ 
  type: UPDATE_SEA_TRAIN_VIEW_ITEMS,
  payload,    
});

const recoverStationArrivalInList = (payload) => ({ 
  type: RECOVER_STATION_ARRIVAL_IN_LIST,
  payload,    
});

const recoverCityPortDepartureInList = (payload) => ({ 
  type: RECOVER_CITY_PORT_DEPARTURE_IN_LIST,
  payload,    
});

const updateSeaSeaViewItems = (payload) => ({ 
  type: UPDATE_SEA_SEA_VIEW_ITEMS,
  payload,    
});

const changeTrainTrainViewField = (payload) => ({ 
  type: CHANGE_TRAIN_TRAIN_VIEW_FIELD,
  payload,    
});

const changeSeaTextArea = (payload) => ({ 
  type: CHANGE_SEA_TEXT_AREA,
  payload,    
});

const changeTrainTextArea = (payload) => ({ 
  type: CHANGE_TRAIN_TEXT_AREA,
  payload,    
});

export const changePanelState = (payload) => async (dispatch) => {
	dispatch({
		type: SET_PANEL_STATE,
		payload
	});
}

export const changeSeaName = (payload) => async (dispatch) => {
	dispatch({
		type: SET_SEA_NAME,
		payload
	});
}

export const changeTrainName = (payload) => async (dispatch) => {
	dispatch({
		type: SET_TRAIN_NAME,
		payload
	});
}

export const changeTrainExpire = (payload) => async (dispatch) => {
	dispatch({
		type: SET_TRAIN_EXPIRE,
		payload
	});
}

export const changeTrainCity = (payload) => async (dispatch) => {
	dispatch({
		type: SET_TRAIN_CITY,
		payload
	});
}

export const changeTrainCountryDeparture = (payload) => async (dispatch) => {
	dispatch({
		type: SET_TRAIN_COUNTRY_DEPARTURE,
		payload
	});
}

export const changeSeaExpireDate = (payload) => async (dispatch) => {
	dispatch({
		type: SET_SEA_EXPIRE,
		payload
	});
}

export const changeSeaTerminal = (payload) => async (dispatch) => {
	dispatch({
		type: SET_SEA_TERMINAL,
		payload
	});
}

export const changeSeaCustoms = (payload) => async (dispatch, getState) => {

	const state = getState();
	const remainsKey = ["vtt", "gtd"].find((key)=>key !== Object.keys(payload)[0]);

	payload.correct = [getSeaCustoms(state)[remainsKey], Object.values(payload)[0]].some((item)=>item)
					? true: false;

	dispatch({
		type: SET_SEA_CUSTOMS,
		payload
	});
}

export const changeSubPanelState = (payload) => async (dispatch) => {
	dispatch({
		type: SET_SEA_SUB_PANEL_STATE,
		payload
	});
}

export const changeSeaCountryDeparture = (payload) => async (dispatch) => {
	dispatch({
		type: SET_SEA_COUNTRY_DEPARTURE,
		payload
	});
}

export const changeSeaCountryArrival = (payload) => async (dispatch) => {
	dispatch({
		type: SET_SEA_COUNTRY_ARRIVAL,
		payload
	});
}

export const addSeaIncorrectFields = (payload) => async (dispatch) => {
	dispatch({
		type: ADD_SEA_INCORRECT_FIELDS,
		payload
	});
}

export const addTrainIncorrectFields = (payload) => async (dispatch) => {
	dispatch({
		type: ADD_TRAIN_INCORRECT_FIELDS,
		payload
	});
}

export const loadingOffersPack = (payload) => async (dispatch) => {
	dispatch({
		type: LOADING_OFFERS_PACK,
		payload
	});
}

export const updateOffersPack = (payload) => async (dispatch) => {
	dispatch({
		type: SET_DEFAULT_STATE,
		payload
	});
}


export const takeTemplateOffersPack = (id) => async (dispatch, getState) => {
	
	const seaState   = cloneDeep(INIT_STATE.sea);
	const trainState = cloneDeep(INIT_STATE.train);
	const initState  = cloneDeep(INIT_STATE);
	
	let excludeListPortDeparture, excludeListStationArrival, excludeListStationDeparture, countryArrival;

	dispatch(loadingOffersPack(true));
	const offersPack = (await getManagerLogisticsOffersPack(id)).offersPack[0];

	if (offersPack.typetransport){ // sea

		seaState.name.value = offersPack.name;
		seaState.expireDate.value = format(new Date(offersPack.expireDate), 'dd.MM.yyyy');
		seaState.terminalArrival = subsetPortTerminalList.find(item => item.title === offersPack.terminalArrival);
		seaState.cityArrival = (seaState.terminalArrival?.datatype)?cityTerminal[seaState.terminalArrival.datatype]:"Не известен";
		seaState.customs = {...seaState.customs, ...offersPack.customs};
		seaState.conditionscarriage.value = offersPack.conditionscarriage;
		seaState.paymentterms.value = offersPack.paymentterms;

		if (offersPack.seaView.length){
			excludeListPortDeparture = offersPack.seaView.reduce((list, item)=>{
				if (item.country in list){
					list[item.country].push(item.departure.cityPort);
				} else {
					list[item.country] = [];
					list[item.country].push(item.departure.cityPort);				
				}
				return list;
			},{});

			seaState.seaDeparture.portDeparture = Object.fromEntries(Object.entries(departureCityPortList).map(([key, value])=>{
				if (key in excludeListPortDeparture){
					value = value.filter(item=>!excludeListPortDeparture[key].includes(item.title));
				}				
				return [key, value];
			}));

			seaState.seaView = offersPack.seaView.map(obj=>{
				obj.index = departureCityPortList[obj.country].findIndex(item=>obj.departure.cityPort === item.title).toString();
				obj.params = {...initParams.params, ...obj.params};				
				return obj;
			});
		}

		if (offersPack.trainView.length){
			excludeListStationArrival = offersPack.trainView.reduce((list, item)=>{
				if (item.country in list){
					list[item.country].push(item.arrival.city);
				} else {
					list[item.country] = [];
					list[item.country].push(item.arrival.city);				
				}
				return list;
			},{});		

			seaState.trainArrival.stationArrival = Object.fromEntries(Object.entries(arrivalFromPortStationList).map(([key, value])=>{
				if (key in excludeListStationArrival) {
					value = value.filter(item=>!excludeListStationArrival[key].includes(item.title));					
				}
				return [key, value];
			}));

			seaState.trainView = offersPack.trainView.map(obj=>{
				obj.index = arrivalFromPortStationList[obj.country].findIndex(item=>obj.arrival.city === item.title).toString();
				obj.params = {...initParams.params, ...obj.params};
				return obj;
			});
		}

		initState.panelState = "sea";
		initState.sea = seaState;
		dispatch(updateOffersPack(initState));

	} else { //train

		trainState.name.value = offersPack.name;
		trainState.expireDate.value = format(new Date(offersPack.expireDate), 'dd.MM.yyyy');

		countryArrival = (offersPack.trainView?.[0]?.arrival?.country)
		? arrivalCountryList.find(item=>item.title === offersPack.trainView[0].arrival.country)?.datatype
		: "russia";
		trainState.cityArrival = arrivalStraightStationList[countryArrival].find(item => item.title === offersPack.cityArrival);
		trainState.conditionscarriage.value = offersPack.conditionscarriage;
		trainState.paymentterms.value = offersPack.paymentterms;

		if (offersPack.trainView.length){

			excludeListStationDeparture = offersPack.trainView.reduce((list, item)=>{
				if (item.country in list){
					list[item.country].push(item.departure.city);
				} else {
					list[item.country] = [];
					list[item.country].push(item.departure.city);				
				}
				return list;
			},{});

			trainState.stationDeparture = Object.fromEntries(Object.entries(departureStationList).map(([key, value])=>{
				if (key in excludeListStationDeparture) {
					value = value.filter(item=>!excludeListStationDeparture[key].includes(item.title));					
				}
				return [key, value];
			}));

			trainState.trainView = offersPack.trainView.map(obj=>{
				obj.index = departureStationList[obj.country].findIndex(item=>obj.departure.city === item.title).toString();
				obj.params = {...initParams.params, ...obj.params};
				return obj;
			});
		}

		initState.panelState = "train";
		initState.train = trainState;
		dispatch(updateOffersPack(initState));
	}

	dispatch(loadingOffersPack(false));

	console.log(offersPack);
}



export const sendTrainOffers = async (dispatch, getState) => {

	let failedTrain, validateStack, fields;
	const state = getState();
	const trainState = getMakeOffersTrain(state);
	const firstCompany = getCompanies(state)?.[0];
	const {expireDate, name, paymentterms, conditionscarriage, trainView} = trainState;

	failedTrain = trainView.reduce((result, route)=>{
		if ((Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).some(([key, value])=>value.value === null)
			|| Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).every(([key, value])=>!value.value))
			|| !route.params.duration.value){			
			result.push({
				type: "trainTrain",
				idx: route.index,
				country: route.country,
				fields: (()=>{
					const resultArr = [];
					if (Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).every(([key, value])=>!value.value)){
						resultArr.push("twenty", "forty", "fortyhc");
					}
					if (!route.params.duration.value){
						resultArr.push("duration");
					}
					return resultArr;					
				})()
			});
		}
		return result;
	}, []);	

	validateStack = {
		name: name, paymentterms: paymentterms,
		conditionscarriage: conditionscarriage, expireDate: expireDate,
		trainView: failedTrain
	};	

	if (Object.entries(validateStack).every(([key, value])=>{		
		let viewKey;
		if (viewKey = ["trainView"].find((item)=>item === key)){	
			if (!validateStack[viewKey].length){
				return {trainView: trainView}[viewKey].length;				
			} else {
				return false;
			}
		} else {
			return Object.values(value).every((value)=>value);
		}
	})){
		console.log("success");
		console.log(trainState);
		trainState.expireDate.value = toDate(trainState.expireDate.value);
		trainState.trainView.forEach(( _, index)=>{
			delete trainState.trainView[index].index;
			Object.entries(trainState.trainView[index].params).forEach(([key, value])=>{
				if(!value.value){
					delete trainState.trainView[index].params[key];
				}
			});
		});
		delete trainState.stationDeparture;
		delete trainState.countryDeparture;

		//prepare trainState?

        try {
            const result = await sendManagerNewOffersPack({train: trainState, companyInn: firstCompany?.itn, companyName: firstCompany?.name, companyOffer: firstCompany?.offer, companyId: firstCompany?._id});
            dispatch(setDefaultTrainState(INIT_STATE.train));
            dispatch(setMessageBoxState({boxName:"successTrainTab", state: true}));
           console.log(result);
        }catch(error){
            console.log(error);            
            dispatch(setDefaultTrainState(INIT_STATE.train));
            dispatch(setMessageBoxState({boxName:"failNewOffers", state: true}));
        }
	} else {
		fields = Object.fromEntries(Object.entries(validateStack).filter(([key, value])=>{
			if ("trainView" === key){
				return Boolean(value.length);
			} else {
				return !Object.values(value).every((value)=>value);
			}
		}).map(([key, value])=>{
			if ("trainView" === key){
				return [key, value];
			} else return [key, false];
		}));
		console.log(fields);

		if (!(trainView.length)){
			console.log("no trainView");
			dispatch(setMessageBoxState({boxName:"trainTab", state: true}));
		}

		if (Object.keys(fields).length)	dispatch(addTrainIncorrectFields(fields));		
	}	
}

export const sendSeaOffers = async (dispatch, getState) => {

	let failedSea, failedTrain, validateStack, fields, views;
	const state = getState();
	const seaState = getMakeOffersSea(state);
	const firstCompany = getCompanies(state)?.[0];
	const {customs, expireDate, name, paymentterms, conditionscarriage, seaView, trainView} = seaState;

	failedSea = seaView.reduce((result, route)=>{		
		if ((Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).some(([key, value])=>value.value === null)
			|| Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).every(([key, value])=>!value.value))
			|| !route.params.duration.value){			
			result.push({
				type: "seaSea",
				idx: route.index,
				country: route.country,
				fields: (()=>{
					const resultArr = [];
					if (Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).every(([key, value])=>!value.value)){
						resultArr.push("twenty", "forty", "fortyhc");
					}
					if (!route.params.duration.value){
						resultArr.push("duration");
					}
					return resultArr;					
				})()
			});
		}
		return result;
	}, []);

	failedTrain = trainView.reduce((result, route)=>{
		if ((Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).some(([key, value])=>value.value === null)
			|| Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).every(([key, value])=>!value.value))
			|| !route.params.duration.value){			
			result.push({
				type: "seaTrain",
				idx: route.index,
				country: route.country,
				fields: (()=>{
					const resultArr = [];
					if (Object.entries(route.params).filter(([key, value])=>["twenty", "forty", "fortyhc"].includes(key)).every(([key, value])=>!value.value)){
						resultArr.push("twenty", "forty", "fortyhc");
					}
					if (!route.params.duration.value){
						resultArr.push("duration");
					}
					return resultArr;					
				})()

			});
		}
		return result;
	}, []);

	validateStack = {
		name: name, paymentterms: paymentterms,
		conditionscarriage: conditionscarriage, expireDate: expireDate,
		customs: customs, views: {
			seaView: failedSea,
			trainView: failedTrain			
		}
	};

	if (Object.entries(validateStack).every(([key, value])=>{
		if (key === "views"){

			if (!seaView.length){
				return false;
			} else if (!trainView.length){	// only sea
				return !value.seaView.length; 
			} else {                        //sea and train
				return Object.values(value).every((value)=>!value.length);
			}

		} else if (["customs"].includes(key)){
			return Object.entries(value).filter(([key, value])=>["vtt", "gtd"].includes(key))
				.some(([key, value])=>value);
		} else {
			return Object.values(value).every((value)=>value);
		}
	})){
			
		delete seaState.subPanelState;
		delete seaState.seaDeparture;
		delete seaState.trainArrival;
		delete seaState.customs.correct;
		seaState.seaView.forEach(( _, index)=>{
			delete seaState.seaView[index].index;
			Object.entries(seaState.seaView[index].params).forEach(([key, value])=>{
				if(!value.value){
					delete seaState.seaView[index].params[key];
				}
			});
		});
		seaState.trainView.forEach(( _, index)=>{
			delete seaState.trainView[index].index;
			Object.entries(seaState.trainView[index].params).forEach(([key, value])=>{
				if(!value.value){
					delete seaState.trainView[index].params[key];
				}
			});			

		});

		seaState.expireDate.value = toDate(seaState.expireDate.value);
		console.log(seaState);
		//prepare seaState?

        try {
            const result = await sendManagerNewOffersPack({sea: seaState, companyName: firstCompany?.name, companyInn: firstCompany?.itn, companyOffer: firstCompany?.offer, companyId: firstCompany?._id});
            dispatch(setDefaultSeaState(INIT_STATE.sea));
            dispatch(setMessageBoxState({boxName:"successSeaTab", state: true}));
           // console.log(result);
        }catch(error){
            console.log(error);
            dispatch(setDefaultSeaState(INIT_STATE.sea));
            dispatch(setMessageBoxState({boxName:"failNewOffers", state: true}));
        }	
	} else {

		fields = Object.fromEntries(Object.entries(validateStack).filter(([key, value])=>{

			if (key === "views"){
				return Object.values(value).some((value)=> value.length);
			} else if (key === "customs"){
				return !Object.entries(value).filter(([key, value])=>["vtt", "gtd"].includes(key))
					.some(([key, value])=>value);
			} else {
				return !Object.values(value).every((value)=>value);
			}
		}).map(([key, value])=>{			
			if (key === "views"){
				value = Object.fromEntries(Object.entries(value).filter(([key, value])=>value.length));
				return [key, value];
			} else return [key, false];
		}));

		if ("views" in fields){
			views = {...fields.views};
			delete fields.views;
			Object.assign(fields,views);
		}

		if (!(seaView.length)){
			console.log("no seaView");
			dispatch(setMessageBoxState({boxName:"seaTab", state: true}));			
		}

		console.log(fields);
		if (Object.keys(fields).length)	dispatch(addSeaIncorrectFields(fields));
	}
}


export const handlerAddToViewRoute = ({idx, country, nameList}) => async (dispatch, getState) => {


	let chosenList, datatypeChosenItem, filteredList, routeObj, countryDeparture,
		cityDeparture, cityArrival, terminalArrival, viewList, updatedViewList;
	const state = getState();

	if (nameList === "departCityPort"){ //seaSeaView

		chosenList = getSeaPortDepartureList(country)(state);
		datatypeChosenItem = departureCityPortList[country][idx].datatype;
		filteredList = chosenList.filter(({datatype}) => (datatype !== datatypeChosenItem));

		countryDeparture = getSeaCountryDeparture(state).title;
		cityDeparture = departureCityPortList[country][idx].title;
		cityArrival = getSeaCityArrival(state);
		terminalArrival = getSeaTerminal(state).title;
		viewList = getSeaSeaView(state);

		routeObj = {
			index: idx, 
			country: country, 
			departure:{
				country: countryDeparture, 
				cityPort: cityDeparture, 
			},
			arrival: {
				country: "Россия",
				city: cityArrival, 
				terminal: terminalArrival, 
			},
			params: {
				twenty:{
					value: false,
					correct: true
				},
				forty:{
					value: false,
					correct: true
				},
				fortyhc:{
					value: false,
					correct: true
				},
				duration:{
					value: false,
					correct: true
				}
			}
		} 


		updatedViewList = [...viewList, routeObj];
		dispatch(recoverCityPortDepartureInList({[country]:filteredList}));
		dispatch(updateSeaSeaViewItems(updatedViewList));

	} else if (nameList === "fromPortStation"){ //seaTrainView

		chosenList = getSeaStationArrivalList(country)(state);
		datatypeChosenItem = arrivalFromPortStationList[country][idx].datatype;
		filteredList = chosenList.filter(({datatype}) => (datatype !== datatypeChosenItem));		

		cityDeparture = getSeaCityArrival(state);
		cityArrival = arrivalFromPortStationList[country][idx].title;
		viewList = getSeaTrainView(state);

		routeObj = {
			index: idx, 
			country: country, 
			departure:{
				country: "Россия",
				city: cityDeparture,
			},
			arrival: {
				country: "Россия",
				city: cityArrival, 
			},
			params: {
				twenty:{
					value: false,
					correct: true
				},
				forty:{
					value: false,
					correct: true
				},
				fortyhc:{
					value: false,
					correct: true
				},
				duration:{
					value: false,
					correct: true
				}
			}
		}

		updatedViewList = [...viewList, routeObj];
		dispatch(recoverStationArrivalInList({[country]:filteredList}));
		dispatch(updateSeaTrainViewItems(updatedViewList));

	} else if (nameList === "departStation"){ //trainTrainView

		chosenList = getTrainStationDepartureList(country)(state);
		datatypeChosenItem = departureStationList[country][idx].datatype;
		filteredList = chosenList.filter(({datatype}) => (datatype !== datatypeChosenItem));

		countryDeparture = getTrainCountryDeparture(state).title;
		cityDeparture = departureStationList[country][idx].title;
		cityArrival = getTrainCityArrival(state).title;
		viewList = getTrainTrainView(state);

		routeObj = {
			index: idx,
			country: country,
			departure:{
				country: countryDeparture,
				city: cityDeparture,
			},
			arrival: {
				country: "Россия",
				city: cityArrival,
			},
			params: {
				twenty:{
					value: false,
					correct: true
				},
				forty:{
					value: false,
					correct: true
				},
				fortyhc:{
					value: false,
					correct: true
				},
				duration:{
					value: false,
					correct: true
				}
			}
		}		

		updatedViewList = [...viewList, routeObj];
		dispatch(recoverStationDepartureInList({[country]:filteredList}));
		dispatch(updateTrainTrainViewItems(updatedViewList));
	}
}

export const recoverTrainTrainRoute = (idx, outCountry) => async (dispatch, getState) => {

	let newDepartureList = [];
	let newTrainViewList = [];
	let remainsKeysArr = [];	
	const state = getState();
	const entireDepartureList = departureStationList[outCountry];
	const remainsDepartureList = getTrainStationDepartureList(outCountry)(state);
	const stationDeparture = departureStationList[outCountry]?.[Number(idx)];
	const trainViewList = getTrainTrainView(state);

	if (typeof remainsDepartureList === "object"){
		if (typeof stationDeparture === "object" && "datatype" in stationDeparture){
			if (remainsDepartureList.length){
				remainsKeysArr = remainsDepartureList.map(({datatype})=>datatype);
				newDepartureList = entireDepartureList.filter((item)=>{
					return (remainsKeysArr.includes(item.datatype) || stationDeparture.datatype === item.datatype)
				});					
			} else newDepartureList.push(stationDeparture);
		}
	}

	if (newDepartureList.length){
		dispatch(recoverStationDepartureInList({[outCountry]:newDepartureList}));
		newTrainViewList = trainViewList.filter(({index, country})=>((Number(idx) !== Number(index) && outCountry === country) || (outCountry !== country)));
		dispatch(updateTrainTrainViewItems(newTrainViewList));
	}
}

export const recoverSeaTrainRoute = (idx, outCountry) => async (dispatch, getState) => {

	let newArrivalList = [];
	let newTrainViewList = [];
	let remainsKeysArr = [];	
	const state = getState();
	const entireArrivalList = arrivalFromPortStationList[outCountry];
	const remainsArrivalList = getSeaStationArrivalList(outCountry)(state);
	const stationArrival = arrivalFromPortStationList[outCountry]?.[Number(idx)];
	const trainViewList = getSeaTrainView(state);

	if (typeof remainsArrivalList === "object"){
		if (typeof stationArrival === "object" && "datatype" in stationArrival){
			if (remainsArrivalList.length){
				remainsKeysArr = remainsArrivalList.map(({datatype})=>datatype);
				newArrivalList = entireArrivalList.filter((item)=>{
					return (remainsKeysArr.includes(item.datatype) || stationArrival.datatype === item.datatype);
				});					
			} else newArrivalList.push(stationArrival);
		}
	}

	if (newArrivalList.length){
		dispatch(recoverStationArrivalInList({[outCountry]: newArrivalList}));
		newTrainViewList = trainViewList.filter(({index, country})=>((Number(idx) !== Number(index) && outCountry === country) || (outCountry !== country)));
		dispatch(updateSeaTrainViewItems(newTrainViewList));
	}
}

export const recoverSeaSeaRoute = (idx, outCountry) => async (dispatch, getState) => {

	let newDepartureList = [];
	let newSeaViewList = [];
	let remainsKeysArr = [];	
	const state = getState();
	const entireDepartureList = departureCityPortList[outCountry];
	const remainsDepartureList = getSeaPortDepartureList(outCountry)(state);
	const cityPortDeparture = departureCityPortList[outCountry]?.[Number(idx)];
	const seaViewList = getSeaSeaView(state);

	if (typeof remainsDepartureList === "object"){
		if (typeof cityPortDeparture === "object" && "datatype" in cityPortDeparture){
			if (remainsDepartureList.length){
				remainsKeysArr = remainsDepartureList.map(({datatype})=>datatype);
				newDepartureList = entireDepartureList.filter((item)=>{
					return (remainsKeysArr.includes(item.datatype) || cityPortDeparture.datatype === item.datatype)					
				});					
			} else newDepartureList.push(cityPortDeparture);
		}
	}

	if (newDepartureList.length){
		dispatch(recoverCityPortDepartureInList({[outCountry]:newDepartureList}));	
		newSeaViewList = seaViewList.filter(({index, country})=>((Number(idx) !== Number(index) && outCountry === country) || (outCountry !== country)));	
		dispatch(updateSeaSeaViewItems(newSeaViewList));
	}
}

export const changeTrainTrainView = (index, outCountry, field, outState) => async (dispatch, getState) => {

	let newTrainViewList = [];
	const state = getState();
	const route = getTrainTrainViewByIdx(Number(index), outCountry)(state);
	const trainViewList = getTrainTrainView(state);

	if (route !== null && typeof trainViewList === "object" && trainViewList.length){
		if (["twenty", "forty", "fortyhc"].includes(field)){
			["twenty", "forty", "fortyhc"].filter(item=>item!==field).forEach(item=>{
				if (route.params[item].value === false && !route.params[item].correct){
					route.params[item].correct=true;
				}
			});
		}		
		route.params[field] = outState;
		newTrainViewList = trainViewList.map((item)=>{
			if (Number(item.index) === Number(route.index) && item.country === outCountry){
				return route;
			} else return item;
		});
		dispatch(updateTrainTrainViewItems(newTrainViewList));
	}
}

export const changeSeaTrainView = (index, outCountry, field, outState) => async (dispatch, getState) => {

	let newTrainViewList = [];
	const state = getState();
	const route = getSeaTrainViewByIdx(Number(index), outCountry)(state);
	const trainViewList = getSeaTrainView(state);

	if (route !== null && typeof trainViewList === "object" && trainViewList.length){

		if (["twenty", "forty", "fortyhc"].includes(field)){
			["twenty", "forty", "fortyhc"].filter(item=>item!==field).forEach(item=>{
				if (route.params[item].value === false && !route.params[item].correct){
					route.params[item].correct=true;
				}
			});
		}		
		route.params[field] = outState;
		newTrainViewList = trainViewList.map((item)=>{
			if (Number(item.index) === Number(route.index) && item.country === outCountry){
				return route;
			} else return item;
		});
		dispatch(updateSeaTrainViewItems(newTrainViewList));
	}
}

export const changeSeaSeaView = (index, outCountry, field, outState) => async (dispatch, getState) => {

	let newSeaViewList = [];
	const state = getState();
	const route = getSeaSeaViewByIdx(Number(index), outCountry)(state);
	const seaViewList = getSeaSeaView(state);

	if (route !== null && typeof seaViewList === "object" && seaViewList.length){

		if (["twenty", "forty", "fortyhc"].includes(field)){
			["twenty", "forty", "fortyhc"].filter(item=>item!==field).forEach(item=>{
				if (route.params[item].value === false && !route.params[item].correct){
					route.params[item].correct=true;
				}
			});
		}
		route.params[field] = outState;
		newSeaViewList = seaViewList.map((item)=>{
			if (Number(item.index) === Number(route.index) && item.country === outCountry){
				return route;
			} else return item;
		});
		dispatch(updateSeaSeaViewItems(newSeaViewList));
	}
}

export const updateSeaTextArea = (field, state) => async (dispatch) => {
	dispatch(changeSeaTextArea({[field]: state}));	
}

export const updateTrainTextArea = (field, state) => async (dispatch) => {
	dispatch(changeTrainTextArea({[field]: state}));	
}