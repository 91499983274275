import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRequest } from '../api';
import { Pdf, PdfActDocument } from '../components/shared/Pdf';
import { getProfile } from '../store/profile';
import { PDFViewer } from '@react-pdf/renderer';

export const Act4Request = () => {
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState(null);
  const profile = useSelector(getProfile);
  const { id } = useParams();

  useEffect(() => {
    console.log("запуск ");
    async function fetchData() {
      const data = await getRequest(id);
      setRequest(data?.request);
      setTimeout(() => setLoading(false), 500);
    }
    if (profile._id) {
      fetchData().then();
    }
  }, [id, profile._id]);
  const src = `https://api.qrserver.com/v1/create-qr-code/?data=${window.location}`;

  return (
    <>
      {!loading && request &&
        (<div style={{ height: '100%' }}>      
          <PDFViewer width="100%" height="100%">
            <PdfActDocument request={request} />
          </PDFViewer>   
        </div>)
      }
      <img style={{ display: 'none' }} src={src} alt="url" />
      <img
        style={{ display: 'none' }}
        src="http://declarant.wires-web.com/app/themes/custom-theme/dist/images/logo_44dce396.svg"
        alt="url"
      />
    </>
  );
};


