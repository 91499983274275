import React from 'react';

import { Modal } from '../Modal';
import { Calendar } from '../Calendar/Calendar';

import styles from './CalendarModal.module.scss';

export const CalendarModal = (props) => {
  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      className={styles.CalendarModal}
    >
      <Calendar
        buttonLabel={props.buttonLabel}
        title={props.title}
        dateOnly={props.dateOnly}
        onClose={props.onClose}
        defaultDate={props.defaultDate}
        onSubmit={props.onSubmit}
      />
    </Modal>
  );
};
