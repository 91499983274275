export const toDate = (string) => {

	let dateArr, date, resultDate;

	if (typeof string === "string"){
		dateArr = string.split("").map(item =>{
			if (["/","\\","."].includes(item)){
				item = ".";
			}
			return item;
		}).join("").split(".").reverse();

		if (dateArr.length === 3){
			dateArr[1] = Number(dateArr[1]) - 1; //month
			date = new Date(...dateArr);
			resultDate = new Date();
			resultDate.setTime(date.getTime());
			return resultDate;			
		}
	}

	return new Date();
}
