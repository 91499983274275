export const CHANGE_STATUS_TO_LOADING = '@withLoading/CHANGE_STATUS_TO_LOADING';
export const CHANGE_STATUS_TO_LOADED = '@withLoading/CHANGE_STATUS_TO_LOADED';
export const CHANGE_STATUS_TO_NOT_FOUND = '@withLoading/CHANGE_STATUS_TO_NOT_FOUND';
export const CHANGE_STATUS_TO_ERROR = '@withLoading/CHANGE_STATUS_TO_ERROR';

export const changeStatusToLoading = {
  type: CHANGE_STATUS_TO_LOADING,
  payload: "loading"     
};

const changeStatusToLoaded = {
  type: CHANGE_STATUS_TO_LOADED,
  payload: "loaded"     
};

const changeStatusToNotFound = {
  type: CHANGE_STATUS_TO_NOT_FOUND,
  payload: "notFound"     
};

const changeStatusToError = {
  type: CHANGE_STATUS_TO_ERROR,
  payload: "error"     
};




