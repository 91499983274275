import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getProfile } from '../../store/profile';
import { getUser, loadUser } from '../../store/users';
import { Title } from '../shared/Title';
import { GoBackLink } from '../shared/GoBackLink';
import { PersonalInformation } from '../shared/PersonalInformation';
import { CompanyView } from './CompanyView';

export const ClientProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const user = useSelector(getUser);
  let { id } = useParams();

  useEffect(() => {
    if (profile._id) {
      dispatch(loadUser(id));
    }
  }, [dispatch, id, profile._id]);

  return (
    <main>
      <Title>Профиль пользователя</Title>
      <GoBackLink />
      {user && (
        <>
          <PersonalInformation profile={user} permitEdit={false} />
          <CompanyView clientId={id}/>
        </>
      )}
    </main>
  );
};
