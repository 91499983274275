import React from 'react';

import { AccountsTable } from './AccountsTable';
import { getAccountsClientsFilters } from '../../store/filters';
import { FILTERS } from '../../consts';

export const AccountsClients = () => (
  <AccountsTable
    getFilters={getAccountsClientsFilters}
    filtersKey={FILTERS.ACCOUNTS_CLIENTS}
    messageSubject="клиентов"
  />
);
