import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getDeclarant } from '../../store/verification';
import { WORK_FIELDS, WORK_REJECT_REASON } from '../../configs/work';
import { Title } from '../shared/Title';
import { Input } from '../shared/Input';
import { ButtonCheckBlock } from './ButtonCheckBlock';
import { WorkPlacesCheck } from './WorkPlacesCheck';
import { Accordion } from '../shared/Accordion';
import { DOCUMENTS_STEPS } from '../../consts';
import RedCross from '../../assets/RedCross.svg';
import GreenCheckmark from '../../assets/GreenCheckmark.svg';
import YellowQuestion from '../../assets/YellowQuestion.svg';


import styles from './WorkFormCheck.module.scss';

export const WorkFormCheck = ({ work, declarantId, section = 'work' }) => {
  const declarant = useSelector(getDeclarant);

  const step = declarant?.work.step;
  const { FAILED_REVIEW, PASSED_REVIEW, IN_REVIEW } = DOCUMENTS_STEPS;
  const mark =
    {
      [FAILED_REVIEW]: RedCross,
      [PASSED_REVIEW]: GreenCheckmark,
      [IN_REVIEW]: YellowQuestion,
    }[step] || null;

  const [workOpen, setWorkOpen] = useState(true);
  const handleToggle = () => setWorkOpen(!workOpen);

  return (
    <Accordion
      title={
        <div className={styles.Title}>
          {mark && <img src={mark} alt="mark" className={styles.Title__Img} />}
          <div className={styles.Title__Text}>Рабочая анкета декларанта</div>
        </div>
      }
      open={workOpen}
      onToggle={handleToggle}
      fadedToggler
    >
      <div>
        <Title as="h4">
          Места работы или проекты, относящиеся к таможенному делу
        </Title>
        <WorkPlacesCheck places={work.places} />
        <Title as="h4">Предпочтения в работе</Title>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            margin: '0.75rem 0 1.5rem 0',
          }}
        >
          {Object.keys(WORK_FIELDS).map((field, idx) => {
            if (WORK_FIELDS[field].type === 'noDisplay') {
              return null;
            }

            return (
              <div key={idx} style={{ width: idx ? '44%' : '53%' }}>
                <Input value={work[field]} disabled lightgrey />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.ButtonBlock}>
        <ButtonCheckBlock
          declarantId={declarantId}
          section={section}
          config={WORK_REJECT_REASON}
          selectPlaceholder="Причина отказа"
        />
      </div>
    </Accordion>
  );
};
