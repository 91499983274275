import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import { getUserPaymentsFilters } from '../../store/filters';
import {
  getCount,
  getLoading,
  getItems,
  loadUserPayments,
  applyUserPaymentsFilters,
} from '../../store/paymentRequests';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { UploadedDocument } from '../shared/UploadArea/UploadedDocument';
import { formatMoney } from '../../utils';
import { getStatusNode } from '../PaymentRequests/utils';
import { getRole } from '../../store/profile';
import { ROLES } from '../../consts';

export const RequestsHistory = () => {
  const dispatch = useDispatch();
  const count = useSelector(getCount);
  const loading = useSelector(getLoading);
  const items = useSelector(getItems);
  const filters = useSelector(getUserPaymentsFilters);
  const role = useSelector(getRole);

  useEffect(() => {
    dispatch(loadUserPayments(filters));
  }, [dispatch, filters]);

  const headers = [
    {
      label: '№ запроса',
      value: 'number',
    },
    {
      label: 'Дата запроса',
      value: 'createdAt',
      sortable: true,
      render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
    },
    {
      label: role === ROLES.USER ? 'Способ пополнения' : 'Способ зачисления',
      value: 'method',
      render: () => 'По реквизитам',
    },
    {
      label: 'Сумма запроса',
      value: 'amount',
      render: formatMoney,
    },
    {
      label: 'Статус запроса',
      value: 'status',
      render: getStatusNode,
    },
    {
      label: 'Комментарий',
      value: 'comment',
      render: (value) => (value ? value : <span>&ndash;</span>),
    },
    {
      label: '',
      value: 'confirmationDoc',
      renderItem: true,
      render: (item) =>
        item?.status === 'resolved' ? (
          <UploadedDocument noName link={item.confirmationDoc} />
        ) : (
          ''
        ),
    },
  ];

  if (!loading && items.length === 0) {
    return (
      <Message type="info">
        {role === ROLES.USER
          ? 'У вас пока нет запросов на пополнение счета'
          : 'У вас пока нет запросов на выплату'}
        &nbsp;&nbsp;
        <span role="img" aria-label="sorry">
          😥
        </span>
      </Message>
    );
  }

  return (
    <>
      {items?.length > 0 && (
        <Table
          loading={loading}
          items={items}
          headers={headers}
          filters={filters}
          countItems={count}
          applyFilters={(filters) =>
            dispatch(applyUserPaymentsFilters(filters))
          }
        />
      )}
    </>
  );
};
