import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './List.module.sass';
import classnames from 'classnames';

import {
	departureCityPortList,
	arrivalFromPortStationList,
	getSeaSubPanel,
	getSeaCountryDeparture,
	getSeaCountryArrival,
	handlerAddToViewRoute
} from '../../../../store/makeOffers';

export const List = ({inputName, list, etalonList, etalonName, country}) => {

	const dispatch = useDispatch();
	const subPanelState = useSelector(getSeaSubPanel);
	const countryDeparture = useSelector(getSeaCountryDeparture);
	const countryArrival = useSelector(getSeaCountryArrival);

	const handlerClick = (event) => {
		let element;
		if (element = event.target.closest("."+classnames(styles.item_port))){

			dispatch(handlerAddToViewRoute({
				idx: element.dataset.idx,
				country: element.dataset.country,
				nameList: element.dataset.name,
			}));
		}
	}


	return (
		<div className={classnames(styles.wrap_port_departure)}>
			<div className={classnames(styles.pack_input_name)}>{inputName}</div>
			<ul className={classnames(styles.list_ports)} onClick={handlerClick}>
				{Boolean(list) && Boolean(list.length) && list.map(({title, datatype})=>{					
							let idx, outDatatype;
							outDatatype = datatype;							
							idx = etalonList.findIndex(({datatype})=>datatype === outDatatype);							
							return <li className={classnames(styles.item_port)}
										data-idx={Number(idx)}
										data-country={country}
										data-name={etalonName}
									>{title}</li>;
						}
					)
				}							
			</ul>											
		</div>
	)
}