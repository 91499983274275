import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ROUTES, ROLES } from '../../consts';
import { SubTabs } from '../shared/SubTabs';
import { AccountHistory } from '../Account/AccountHistory';
import { RequestsHistory } from '../Account/RequestsHistory';
import { getRole } from '../../store/profile';

const SubTabsHeader = () => {
  const role = useSelector(getRole);

  return (
    <SubTabs
      subTabs={[
        {
          title: 'История операций',
          route: ROUTES.FULL_ACCOUNT_HISTORY_ACTIONS,
          visible: true,
        },
        {
          title: 'Запросы на пополнение',
          route: ROUTES.FULL_ACCOUNT_HISTORY_PAYMENTS,
          visible: true,
        },        
/*        {
          title:
            role === ROLES.USER
              ? 'Запросы на пополнение'
              : 'Запросы на выплату',
          route: ROUTES.FULL_ACCOUNT_HISTORY_PAYMENTS,
          visible: true,
        },*/
      ]}
    />
  );
};

export const ActionsHistory = () => {  
  return (
    <main>
      <SubTabsHeader />
      <Routes>
        <Route
          path={ROUTES.ACCOUNT_HISTORY_ACTIONS}
          Component={AccountHistory}          
        />
        <Route
          path={ROUTES.ACCOUNT_HISTORY_PAYMENTS}
          Component={RequestsHistory}          
        />
        <Route path="*" element={<Navigate to={ROUTES.FULL_ACCOUNT_HISTORY_PAYMENTS} />}/>        
      </Routes>
    </main>
  );
};
