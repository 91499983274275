import { loadMyVerifiedCompanies } from '../../api';

export const ADD_COMPANIES = '@mycompany/ADD_COMPANY';
export const ADD_COMPANIES_SUCCESS = '@mycompany/ADD_COMPANY_SUCCESS';
export const ADD_COMPANIES_FAILURE = '@mycompany/ADD_COMPANY_FAILURE';


export const addCompanies = (payload) => ({
  type: ADD_COMPANIES,
  payload,
});
export const addCompaniesSuccess = (payload) => ({
  type: ADD_COMPANIES_SUCCESS,
  payload,
});
export const addCompaniesFailure = (payload) => ({
  type: ADD_COMPANIES_FAILURE,
  payload,
});

export const takeMyVerifiedCompanies = async (dispatch, getState) => {
  dispatch(addCompanies());
  try {
    const companies = await loadMyVerifiedCompanies();
    dispatch(addCompaniesSuccess(companies));
    console.log(companies);
  } catch (error) {
      dispatch(addCompaniesFailure(error?.data));
    }  
};