import { API_ROUTES } from '../consts';
import { ApiHelper, generateQuery } from '../utils';

export const getDeclarants = (filters) => {
  const queryString = generateQuery(filters);

  return ApiHelper.get(`${API_ROUTES.VERIFICATION}${queryString}`);
};

export const getDeclarantById = (id) =>
  ApiHelper.get(`${API_ROUTES.PROFILE}/${id}`);
