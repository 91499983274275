import React from 'react';
import { useNavigate } from 'react-router-dom';

import GoBack from '../../../assets/GoBack.svg';

import styles from './GoBackLink.module.scss';

export const GoBackLink = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.GoBack} onClick={navigate.bind(null, -1)}>
      <div className={styles.GoBack__Icon}>
        <img src={GoBack} alt="GoBack" />
      </div>
      <div className={styles.GoBack__Text}>Вернуться назад</div>
    </div>
  );
};
