import React from 'react';

import { ROUTES, ROLES } from '../consts';

import Account from '../assets/Account.svg';
// import Chats from 'assets/Chats.svg';
import Company from '../assets/Company.svg';
// import Messages from 'assets/Messages.svg';
import Requests from '../assets/Requests.svg';
import RequestsForPayment from '../assets/RequestsForPayment.svg';
import Partners from '../assets/Partners.svg';
import PaymentRequests from '../assets/PaymentRequests.svg';
import Percentage from '../assets/Percentage.svg';
// import Services from 'assets/Services.svg';
import Settings from '../assets/Settings.svg';
// import Staff from 'assets/Staff.svg';
import Verification from '../assets/Verification.svg';
import Users from '../assets/Users.svg';
import Wallet from '../assets/Wallet2.svg';
import Reviews from '../assets/Reviews.svg';

export const MENU_ITEMS = {
  [ROLES.USER]: [
    {
      label: 'Мой аккаунт',
      icon: <img src={Users} alt="Account" />,
      route: ROUTES.FULL_PROFILE,
    },  
    {
      id: 'account',
      label: 'Мой счёт',
      icon: <img src={Account} alt="Account" />,
      route: ROUTES.FULL_ACCOUNT,
    },
    {
      id: 'requests',
      label: 'Заявки',
      icon: <img src={Requests} alt="Requests" />,
      route: ROUTES.FULL_REQUESTS,
    },
  ],
  [ROLES.PARTNER]: [
    {
      label: 'Мой аккаунт',
      icon: <img src={Users} alt="Account" />,
      route: ROUTES.FULL_PROFILE,
    },  
    {
      label: 'Мой счёт',
      icon: <img src={Account} alt="Account" />,
      route: ROUTES.FULL_ACCOUNT,
    },
    {
      label: 'Партнерская программа',
      icon: <img src={Partners} alt="Partners" />,
      route: ROUTES.FULL_PARTNER_PROGRAMM,
    },
  ],
  [ROLES.DECLARANT]: [
    {
      label: 'Мой аккаунт',
      icon: <img src={Users} alt="Account" />,
      route: ROUTES.FULL_PROFILE,
    },  
    {
      id: 'account',
      label: 'Мой счёт',
      icon: <img src={Account} alt="Account" />,
      route: ROUTES.FULL_ACCOUNT,
    },
    {
      id: 'requests',
      label: 'Заявки',
      icon: <img src={Requests} alt="Requests" />,
      route: ROUTES.FULL_REQUESTS,
    },
  ],
  [ROLES.LOGIST]: [
    {
      label: 'Мой аккаунт',
      icon: <img src={Users} alt="Account" />,
      route: ROUTES.FULL_PROFILE,
    },  
    {
      id: 'account',
      label: 'Мой счёт',
      icon: <img src={Account} alt="Account" />,
      route: ROUTES.FULL_ACCOUNT,
    },    
    {
      id: 'requests',
      label: 'Логистика',
      icon: <img src={Requests} alt="Requests" />,
      route: ROUTES.FULL_REQUESTS,
    },
  ],  
  [ROLES.ADMIN]: [
    {
      label: 'Пользователи',
      icon: <img src={Users} alt="Users" />,
      route: ROUTES.FULL_USERS,
    },

/*    {
      label: 'Верификация',
      icon: <img src={Verification} alt="Verification" />,
      route: ROUTES.FULL_VERIFICATION,
    },*/
/*    {
      label: 'Заявки',
      icon: <img src={Requests} alt="Requests" />,
      route: ROUTES.FULL_REQUESTS,
    },*/
    {
      label: 'Запросы',
      icon: <img src={PaymentRequests} alt="Queries" />,
      route: ROUTES.FULL_REQUESTS_FOR_PAYMENT,
    },
    {
      label: 'Счета',
      icon: <img src={RequestsForPayment} alt="Accounts" />,
      route: ROUTES.FULL_ACCOUNTS,
    },
    {
      label: 'Счет системы',
      icon: <img src={Wallet} alt="SystemAccount" />,
      route: ROUTES.FULL_SYSTEM_ACCOUNT,
    },
/*    {
      label: 'Маркетинг',
      icon: <img src={Percentage} alt="Marketing" />,
      route: ROUTES.FULL_MARKETING,
    },*/
/*    {
      label: 'Настройки',
      icon: <img src={Settings} alt="Settings" />,
      route: ROUTES.FULL_SETTINGS,
    },*/
    {
      label: 'Обращения',
      icon: <img src={Reviews} alt="Reviews" />,
      route: ROUTES.FULL_REVIEWS,
    },
  ],
};
