import React from 'react';

import { formatMoney } from '../../../utils';
import { CUSTOM_QUERIES } from '../../../configs/customQueries';

import styles from './PaymentsList.module.scss';
import { Title } from '../../shared/Title';

export const PaymentsList = ({ payments }) => {
  const total = payments.reduce((acc, item) => acc + item.amount, 0);
  return (
    <div>
      <Title as="h3">Итого таможенные платежи: {formatMoney(total)}</Title>
      <div className={styles.PaymentsList}>
        {payments.map((payment) => (
          <div key={payment._id} className={styles.Item}>
            <div>{CUSTOM_QUERIES[payment.type]?.label || payment.type}</div>
            <div>{formatMoney(payment.amount)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
