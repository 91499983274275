import { getSystemAccount as fetchSystemAccount, setSystemAccount, getBankData } from '../../api';
import { SystemAccountSchema } from '../../utils/validation';
import {
	getEditorSystemAccountValue,
	getCurrentSystemAccount } from './selectors';

export const SET_CURRENT_SYSTEM_ACCOUNT = '@systemAccount/SET_CURRENT_SYSTEM_ACCOUNT';
export const UPDATE_EDITOR_ITEM_VALUE = '@systemAccount/UPDATE_EDITOR_ITEM_VALUE';
export const UPDATE_EDITOR_ITEMS_VALUE = '@systemAccount/UPDATE_EDITOR_ITEMS_VALUE';
export const CLEAR_EDITOR_ITEMS_VALUE = '@systemAccount/CLEAR_EDITOR_ITEMS_VALUE';




export const getBank = (query) => async (dispatch, getState) => {
  try {
    const { bank } = await getBankData({ query });
    if (bank) {
		await dispatch({
			type: UPDATE_EDITOR_ITEMS_VALUE,
			payload: {
	            name: bank?.value,
	            corrAccount: bank?.data?.correspondent_account,
	            inn: bank?.data?.inn,
	            kpp: bank?.data?.kpp,    				
			}
		});
    }
  } catch (error) {
    console.error(error);
  }
};

export const getSystemAccount = async (dispatch) => {
	try{
		const bankData = await fetchSystemAccount();
		await dispatch({type: SET_CURRENT_SYSTEM_ACCOUNT, payload: bankData.account});
	}catch(error){
		console.error(error);
	}
}

export const updateEditorItemValue = ({item, state, clearPush=false}) => async (dispatch) => {
	await dispatch({
		type:UPDATE_EDITOR_ITEM_VALUE,
		payload: {item, state, clearPush}
	});
}

export const saveSystemAccount = (toast) => async (dispatch, getState) => {

	try{
		const state = getState();
		const systemAccount = getEditorSystemAccountValue(state);

		await SystemAccountSchema.validate(systemAccount,{abortEarly: false});		
		const bankData = await setSystemAccount(systemAccount);
		console.log(bankData);
		await dispatch({type: SET_CURRENT_SYSTEM_ACCOUNT, payload: bankData.account});
		await dispatch({type: CLEAR_EDITOR_ITEMS_VALUE });
		toast.success('Реквизиты системы сохранены');

	}catch(error){
		console.error(error);
		toast.error('Реквизиты не сохранены');
	}
}


