import React from 'react';

import { UploadArea } from '../shared/UploadArea';

const configFilesGoods = {
  mainHead: 'Документы',
  docsList: [' Техничка каждого товара (Word) '],
};

const configFilesContract = {
  mainHead: 'Документы',
  docsList: [
    '1) Контракт с отправителем или получателем (Скан + Word) ',
    '2) Уникальный номер документа (паспорт сделки) - Скан',
    '3) Дополнения к контракту (Скан + Word)',
  ],
};

const configFilesMisc = {
  mainHead: 'Документы',
  docsList: [
    '1) Инвойс (Excel + Скан + перевод на русский язык) ',
    '2) Упаковочный лист (Excel + Скан)',
    '3) Прайс-лист (Скан + Word или Excel)',
    '4) Экспортная ДТ (Скан + перевод на русский язык)',
    '5) Договор транспортной экспедиции ТЭО (Скан)',
    '6) Заявка на ТЭО (Скан)',
    '7) Транспортная накладная (Скан)',
    '8) Платежное подтверждение транспортных расходов или доп. соглашение об отсрочке платежа (Скан)',
    '9) Сертификат страны происхождения (Скан)',
  ],
  addHead: 'Дополнительно',
  note: [
    '1) Платежное подтверждение оплаты за товар (если была 100% оплата товара или предоплата) - Cкан',
    '2) Фото товара (при наличии)',
  ],
};

const configFilesPermits = {
  mainHead: 'Документы',
  docsList: ['1) Сертификат', '2) Декларация', '3) Отказные письма и т.д.'],
  addHead: 'Примечание',
  note: ['Все документы в формате Скан'],
};

export const Documents = ({
  disabled,
  documents,
  onChange,
  onExclude,
  viewOnly,
}) => {
  return (
    <div>
      <UploadArea
        files={documents.docs}
        id="docs"
        configFiles={configFilesGoods}
        onUpload={(value) => onChange('docs', [...documents.docs, ...value])}
        onExclude={onExclude}
        disabled={disabled}
        viewOnly={viewOnly}
      >
        <div id="technicalDocs">
          Техническое описание товаров из спецификации
        </div>
      </UploadArea>
      <UploadArea
        files={documents.contract}
        id="contract"
        configFiles={{ ...configFilesContract }}
        onUpload={(value) =>
          onChange('contract', [...documents.contract, ...value])
        }
        onExclude={onExclude}
        disabled={disabled}
        viewOnly={viewOnly}
      >
        <div id="contractAnch">ВЭД контракт и дополнения к нему</div>
      </UploadArea>
      <UploadArea
        files={documents.misc}
        id="misc"
        configFiles={{ ...configFilesMisc }}
        onUpload={(value) => onChange('misc', [...documents.misc, ...value])}
        onExclude={onExclude}
        disabled={disabled}
        viewOnly={viewOnly}
      >
        <div id="otherDocs">Прочие документы</div>
      </UploadArea>
      <UploadArea
        files={documents.permits}
        id="permits"
        configFiles={{ ...configFilesPermits }}
        onUpload={(value) =>
          onChange('permits', [...documents.permits, ...value])
        }
        onExclude={onExclude}
        disabled={disabled}
        viewOnly={viewOnly}
      >
        <div id="permitsAnch">
          Разрешительная документация на ввоз/вывоз товаров
        </div>
      </UploadArea>
    </div>
  );
};
