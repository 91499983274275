import React, { useState } from 'react';
import classnames from 'classnames';

import CloseCross from '../../../assets/CloseCross.svg';

import styles from './Modal.module.scss';

export const Modal = ({ children, className, onClose, open }) => {
  const [closed, setClosed] = useState(false);
  const handleClose = () => {
    setClosed(true);
    setTimeout(() => {
      setClosed(false);
      onClose();
    }, 400);
  };

  // TODO support render props pattern

  return (
    <div
      className={classnames(styles.Wrapper, {
        [styles['Wrapper--open']]: open,
        [styles['Wrapper--closed']]: closed,
      })}
    >
      <div className={classnames(styles.Modal, className)}>
        <div className={styles.Cross} onClick={handleClose}>
          <img src={CloseCross} alt="close" />
        </div>
        {typeof children === 'function' ? children({ handleClose }) : children}
      </div>
    </div>
  );
};
