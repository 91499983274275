import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from '../consts'; 
import { SystemAccount as SystemAccountComponent } from '../components/Accounts/SystemAccount';
import { SystemAccountRequisites } from '../components/SystemAccountRequisites';
import { withLoading } from '../components/hocs/withLoading';
import { Title } from '../components/shared/Title';
import { Tabs } from '../components/shared/Tabs';
import styles from './SystemAccount.module.sass';

export const SystemAccount = withLoading(() => {
  return (
    <main>
		<div className={styles.Wrapper}>
			<div className={styles.Header}>    
				<Title>Счет системы</Title>
				<Tabs
					tabs={[
						{
							title: 'Баланс системы',
							route: ROUTES.FULL_SYSTEM_ACCOUNT_BALANCE,
							href: ROUTES.SYSTEM_ACCOUNT_BALANCE,
							visible: true,
						},
						{
							title: 'Реквизиты системы',
							route: ROUTES.FULL_SYSTEM_ACCOUNT_REQUISITES,
							href: ROUTES.SYSTEM_ACCOUNT_REQUISITES,
							visible: true,
						}             
					]}					
				/> 
			</div>
		</div> 
		<Routes>
			<Route
				path={ROUTES.SYSTEM_ACCOUNT_BALANCE}
				element={<SystemAccountComponent />}
			/>
			<Route 
				path={ROUTES.SYSTEM_ACCOUNT_REQUISITES}
				element={<SystemAccountRequisites />} 
			/>
			<Route path="*" element={<Navigate to={ROUTES.SYSTEM_ACCOUNT_BALANCE} />} />
		</Routes>      
    </main>
  );
});
