import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GreenCheckmark from '../../assets/GreenCheckmark.svg';
import { addSubAccount, getDefaultPassword, getRole } from '../../store/profile';
import { Button } from '../shared/Button';
import { Input } from '../shared/Input';
import { Modal } from '../shared/Modal';
import { Title } from '../shared/Title';

import styles from './AddAccountModal.module.scss';

export const AddAccountModal = (props) => {
  const dispatch = useDispatch();
  const defaultPassword = useSelector(getDefaultPassword);
  const role = useSelector(getRole);
  const [email, setEmail] = useState('');

  return (
    <Modal {...props}>
      <div className={styles.Wrapper}>
        <Title as="h3">Предоставление доступа</Title>
        {defaultPassword ? (
          <div style={{ textAlign: 'center' }}>
            <div style={{ margin: '1rem' }}>
              Email: <b>{email}</b>
            </div>
            <div style={{ margin: '1rem' }}>
              Временный пароль: <b>{defaultPassword}</b>
            </div>
            <img src={GreenCheckmark} alt="success" />
          </div>
        ) : (
          <>
            <Input
              placeholder="Введите email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              filled
              onClick={() => {
                dispatch(addSubAccount({ email, role }));
              }}
            >
              Добавить
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
