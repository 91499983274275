import React from 'react';
import classnames from 'classnames';

import CaretRight from '../../assets/CaretRight.svg';

import styles from './NavigatorItem.module.scss';

export const NavigatorItem = ({ active, label, number, lastItem, onClick }) => {
  return (
    <div
      className={classnames(styles.Item, { [styles['Item--active']]: active })}
      onClick={onClick}
    >
      <div className={styles.Item__Content}>
        {active && <div className={styles.Item__Number}>{number}</div>}
        <div className={styles.Item__Label}>{label}</div>
      </div>
      {!lastItem && (
        <img
          src={CaretRight}
          alt="caret-right"
          className={styles.Item__SingleQuote}
        />
      )}
    </div>
  );
};
