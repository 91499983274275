import {
  LOAD_TARIFFS_SUCCESS,
  LOAD_TARIFFS_REQUEST,
  MODIFY_TARIFF_REQUEST,
  LOAD_TARIFFS_FAILURE,
  MODIFY_TARIFF_FAILURE,
  MODIFY_TARIFF_SUCCESS,
} from './actions';

const initialState = {
  items: [],
  loading: false,
};

export const tariffsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TARIFFS_REQUEST:
    case MODIFY_TARIFF_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_TARIFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };

    case MODIFY_TARIFF_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map((item) => {
          if (item._id === action.payload._id) {
            return { ...action.payload };
          }

          return item;
        }),
      };

    case LOAD_TARIFFS_FAILURE:
    case MODIFY_TARIFF_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
