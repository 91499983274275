export const SET_MESSAGE_BOX_STATE = '@modalMessage/SET_MESSAGE_BOX_STATE';
export const INIT_MESSAGE_BOX = '@modalMessage/INIT_MESSAGE_BOX';



export const setMessageBoxState = (payload) => async (dispatch) => {
	dispatch({
		type: SET_MESSAGE_BOX_STATE,
		payload
	});
}
export const initMessageBox = (payload) => async (dispatch) => {
	dispatch({
		type: INIT_MESSAGE_BOX,
		payload
	});
}
