import React from 'react';

import Document from '../../../assets/Document.svg';
import Pdf from '../../../assets/Pdf.svg';
import Xls from '../../../assets/Xls.svg';
import Zip from '../../../assets/Zip.svg';
import Jpg from '../../../assets/Jpg.svg';
import Png from '../../../assets/Png.svg';
import DefaultDoc from '../../../assets/DefaultDoc.svg';

import { getDocExtention } from '../../../utils';

import styles from './UploadedDocument.module.scss';

const KNOWN_TYPES = {
  doc: Document,
  docx: Document,
  pdf: Pdf,
  png: Png,
  jpg: Jpg,
  jpeg: Jpg,
  zip: Zip,
  xls: Xls,
  xlsx: Xls,
};

export const UploadedDocument = ({
  disabled,
  link,
  name,
  noName,
  onExclude,
  single,
}) => {
  const type = getDocExtention(link);
  return (
    <>
      {noName ? (
        <div className={single ? styles.WrapperShort : styles.Wrapper}>
          <a
            href={link}
            className={styles.LinkNoName}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={KNOWN_TYPES[type?.toLowerCase()] || DefaultDoc}
              alt="document"
              width="30"
            />
          </a>
        </div>
      ) : (
        <div className={single ? styles.WrapperShort : styles.Wrapper}>
          <img
            src={KNOWN_TYPES[type?.toLowerCase()] || DefaultDoc}
            alt="document"
            width="30"
          />
          <a
            href={link}
            className={styles.Link}
            target="_blank"
            rel="noopener noreferrer"
            title={name}
          >
            {name}
          </a>
          {onExclude && (
            <div
              className={disabled ? styles.DimCross : styles.Cross}
              onClick={disabled ? null : onExclude}
            >
              &#10006;
            </div>
          )}
        </div>
      )}
    </>
  );
};
