export const getFio = (fullName) => {
  const [last, first, middle] = fullName.split(' ');

  const person = {
    first,
    middle,
    last,
  };

  return `${person.first?.charAt(0)}.${person.middle?.charAt(0)}. ${
    person.last
  }`;
};
