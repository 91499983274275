import React, { useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { ROLES, ROUTES } from '../../consts';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from '../Toaster';
import storeConfig from '../../store';
import { CheckAuth, CheckRole } from '../hocs/Checker';
import { Menu } from '../Menu';
import { AppHeader } from './AppHeader';
import { BusinessClub } from '../../pages/BusinessClub';
import { Certification } from '../../pages/Certification';
import { AuthGate } from '../../pages/AuthGate';
import { Profile } from '../../pages/Profile';
import { Account } from '../../pages/Account';
import { Act4Request } from '../../pages/Act4Request';
import { Accounts } from '../../pages/Accounts';
import { Join } from '../../pages/Join';
import { Company } from '../../pages/Company';
import { Logistics } from '../../pages/Logistics';
import { Marketing } from '../../pages/Marketing';
import { Requests } from '../../pages/Requests';
import { Users } from '../../pages/Users';
import { Notifications } from '../../pages/Notifications';
import { Verification } from '../../pages/Verification';
import { VerifyProfile } from '../../pages/VerifyProfile';
import { PartnerProgramm } from '../../pages/PartnerProgramm';
import { PaymentRequests } from '../../pages/PaymentRequests';
import { RefillFile } from '../../pages/RefillFile';
import { AdminReviews } from '../../pages/AdminReviews';
import { Reviews } from '../../pages/Reviews';
import { Settings } from '../../pages/Settings';
import { SystemAccount } from '../../pages/SystemAccount';
import { NotFound } from '../../pages/NotFound';
import { Layout } from './Layout';
import { OffersLogisticsClient } from '../../pages/OffersLogisticsClient';

const { store } = storeConfig;

export function App() {
  return (
    <Provider store={store}>
      <Toaster/>
        <Router>      
          <Routes>
            <Route
              path={ROUTES.ACCOUNT_REFILL_REQUISITES}
              element={<RefillFile/>}
            />
            <Route
              path={ROUTES.NOT_FOUND}
              element={<NotFound/>}
            />            
            <Route path="/" element={<Layout />}> 
              <Route index element={ <Navigate to={ROUTES.FULL_PROFILE_PERSONAL} /> } /> 
              <Route path={ROUTES.MAIN_AUTH_GATE} element={<AuthGate/>} />                         
              <Route path={ROUTES.JOIN} element={<Join/>} />
              <Route path={ROUTES.OFFERS} element={<OffersLogisticsClient/>} />
              <Route path={ROUTES.BUSINESS_CLUB} element={<BusinessClub/>} />
              <Route path={ROUTES.CERTIFICATION} element={<Certification/>} /> 
              <Route path={ROUTES.LOGISTICS_INFO} element={<Logistics/>} />              
              <Route path="/*" element={<CheckAuth />} >
                <Route path="*" element={ <Navigate to={ROUTES.FULL_PROFILE_PERSONAL} /> } />
                <Route path={ROUTES.MAIN_PROFILE} element={<Profile />} />
                <Route path={ROUTES.MAIN_NOTIFICATIONS} element={<Notifications/>} />                                                        
                <Route path={ROUTES.ACT_FOR_REQUEST} element={<Act4Request />} />
                <Route path={ROUTES.MAIN_USERS} element={<Users/>} />
                <Route path={ROUTES.MAIN_REQUESTS} element={<Requests/>} />
                <Route path={ROUTES.MAIN_ACCOUNT} element={<Account/>} /> 

                <Route path={ROUTES.MAIN_REQUESTS_FOR_PAYMENT}  element={ <CheckRole                            
                  roles={{ [ROLES.ADMIN]: PaymentRequests }} /> }                              
                />  
                <Route path={ROUTES.MAIN_ACCOUNTS}  element={ <CheckRole                            
                  roles={{ [ROLES.ADMIN]: Accounts }} /> }                              
                /> 
                <Route path={ROUTES.MAIN_SYSTEM_ACCOUNT}  element={ <CheckRole                            
                  roles={{ [ROLES.ADMIN]: SystemAccount }} /> }                              
                /> 
                <Route path={ROUTES.MAIN_MARKETING}  element={ <CheckRole                            
                  roles={{ [ROLES.ADMIN]: Marketing }} /> }
                />  
                <Route path={ROUTES.MAIN_SETTINGS}  element={ <CheckRole                            
                  roles={{ [ROLES.ADMIN]: Settings }} /> }
                />
                <Route path={ROUTES.MAIN_REVIEWS}  element={ <CheckRole
                 redirect={ROUTES.FULL_PROFILE_PERSONAL}
                 roles={{ [ROLES.ADMIN]: AdminReviews,
                 [ROLES.USER]: Reviews }} /> } />
                <Route path={ROUTES.PARTNER_PROGRAMM}  element={ <CheckRole                            
                  roles={{ [ROLES.PARTNER]: PartnerProgramm }} /> }                              
                />                            
                <Route path={ROUTES.MAIN_VERIFICATION}  element={ <CheckRole                            
                  roles={{ [ROLES.ADMIN]: Verification }} /> }                              
                />                                                                                                                                                                                                
              </Route>             
            </Route> 
          </Routes>
        </Router>             
    </Provider>
  );
}
