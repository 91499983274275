import * as yup from 'yup';
import { 
  departureCountryList,
  arrivalCountryList,
  departureCityPortList,
  departureStationList,
  arrivalCityPortList,
  arrivalClientFromPortStationList,
  arrivalFromPortStationList,
  arrivalStraightStationList,
  customsList } from "../store/makeOffers/consts";


const CommonRegisterShape = {
  email: yup
    .string()
    .email('Укажите валидный email')
    .required('Заполните поле'),
  password: yup
    .string()
    .min(8, 'Пароль должен быть не менее 8 символов')
    .required('Заполните поле'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
    .required('Заполните поле'),
};

export const LoginSchema = yup.object().shape({
  emailOrPhone: yup
    .string()
    .email('Укажите валидный email')
    .required('Заполните поле'),
  password: yup
    .string()
    .min(3, 'Пароль должен быть не менее 3 символов')
    .required('Заполните поле'),
});

export const RegisterSchema = yup.object().shape(CommonRegisterShape);

export const ResetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Укажите валидный email')
    .required('Заполните поле'),
});

export const SetNewPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Пароль должен быть не менее 8 символов')
    .required('Заполните поле'),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
    .required('Заполните поле'),
});

export const SystemAccountSchema = yup.object().shape({
  name: yup.string().strict(true).required(),
  bankAccount: yup.string().strict(true).required(),
  bik: yup.string().strict(true).required(),
  corrAccount: yup.string().strict(true).required(),
  inn: yup.string().strict(true).required(),
  kpp: yup.string().strict(true).required()
});


function validateArr(siblingRef, outerArr, innerArr, additionRef) { 
  return this.test({
    name: 'validateArr',
    exclusive: false,   
    params: { siblingReference: siblingRef.path},
    test: function (value) {    
      const ref = this.resolve(siblingRef);
      let addRef = additionRef?this.resolve(additionRef):undefined;      
      return (function(siblingRef, value, outerArr, innerArr){
        let validationArr = outerArr[innerArr.find(item=>item.title == siblingRef)?.datatype]?.map(item=>item.title);
        if (addRef && !validationArr.includes(addRef)) validationArr.push(addRef);        
        return (validationArr?.length && validationArr.some(point=>point == value))?value:null;              
      })(ref, value, outerArr, innerArr);
    }
  })
}

yup.addMethod(yup.string, 'validateArr', validateArr);

export const searchParamsSeaSchema = yup.object().shape({
  typeStation: yup.mixed().oneOf(["sea"]).required(),
  departureCountry: yup.mixed().oneOf(departureCountryList.map(item=>item.title)).required(),
  departurePoint: yup.string().validateArr(yup.ref("departureCountry"), departureCityPortList, departureCountryList).required(),
  arrivalCountry: yup.mixed().oneOf(arrivalCountryList.map(item=>item.title)).required(),
  arrivalCityTerminal: yup.string().validateArr(yup.ref("arrivalCountry"), arrivalCityPortList, arrivalCountryList).required(),
  arrivalPoint: yup.string().validateArr(yup.ref("arrivalCountry"), arrivalFromPortStationList, arrivalCountryList, yup.ref("arrivalCityTerminal")).required(),
  typeContainer: yup.mixed().oneOf(["twenty", "forty", "fortyhc"]).required(),
  customs: yup.mixed().oneOf(customsList.map(item=>item.title)).required()
});

export const searchParamsTrainSchema = yup.object().shape({
  departureCountry: yup.mixed().oneOf(departureCountryList.map(item=>item.title)).required(),
  arrivalCountry: yup.mixed().oneOf(arrivalCountryList.map(item=>item.title)).required(),
  typeStation: yup.mixed().oneOf(["train"]).required(),
  departurePoint: yup.string().validateArr(yup.ref("departureCountry"), departureStationList, departureCountryList).required(),
  typeContainer: yup.mixed().oneOf(["twenty", "forty", "fortyhc"]).required(),
  arrivalPoint: yup.string().validateArr(yup.ref("arrivalCountry"), arrivalStraightStationList, arrivalCountryList).required(),
});




/*departureCountry=Китай&arrivalCountry=Россия&arrivalCityTerminal=Москва&typeStation=sea&departurePoint=Шанхай&typeContainer=twenty&arrivalPoint=Владивосток&customs=ВТТ*/