import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classnames from 'classnames';

import {getNewRequestTariff, updateNewRequest} from '../../store/requests';
import {getRequestTariffs} from '../../store/tariffs';

import Badge from '../../assets/Badge.svg';
import Checkmark from '../../assets/Checkmark.svg';

import styles from './ChooseTariff.module.scss';

export const ChooseTariff = () => {
  const dispatch = useDispatch();
  const chooseTariff = (value) =>
    dispatch(updateNewRequest({key: 'tariff', value}));
  const tariff = useSelector(getNewRequestTariff);
  const requestTariffs = useSelector(getRequestTariffs);

  const isLight = tariff === 'light';
  const isDeclarant = tariff === 'declarant';

  return (
    <div>
      <div className={styles.CardList}>
        <div
          onClick={() => chooseTariff('light')}
          className={classnames(styles.Card, {
            [styles['Card--active']]: isLight,
          })}
        >
          <div className={styles.Card__Badge}>
            <img src={Badge} alt="price-badge"/>
            <span className={styles.Card__BadgeTitle}>
              {requestTariffs.light?.price}₽
            </span>
          </div>
          <div>
            <div className={styles.Card__Title}>Лайт</div>
            <div className={styles.Card__Desc}>Самый популярный</div>
          </div>
          {isLight && (
            <div className={styles.Card__Choosen}>
              Выбрано <img src={Checkmark} alt="check-sign"/>
            </div>
          )}
        </div>
        <div
          onClick={() => chooseTariff('declarant')}
          className={classnames(styles.Card, {
            [styles['Card--active']]: isDeclarant,
          })}
        >
          <div className={styles.Card__Badge}>
            <img src={Badge} alt="badge"/>
            <span className={styles.Card__BadgeTitle}>
              {requestTariffs.declarant?.price}₽
            </span>
          </div>
          <div>
            <div className={styles.Card__Title}>Декларант</div>
            <div className={styles.Card__Desc}>Максимум возможностей</div>
          </div>
          {isDeclarant && (
            <div className={styles.Card__Choosen}>
              Выбрано <img src={Checkmark} alt="checkmark"/>
            </div>
          )}
        </div>
      </div>
      <div>
        {isDeclarant && (
          <ul className={styles.TariffList}>
            <li>
              Проверка полноты коммерческих и товаросопроводительных документов, выявление
              ошибок, рекомендации по исправлению
            </li>
            <li>
              Подбор и рекомендации по кодам ТНВЭД
            </li>
            <li>Предварительный расчет таможенной стоимости и размера таможенных платежей</li>
            <li>
              Проверка подлинности Сертификатов и Деклараций Соответствия на товары
              необходимых для ТО, по средствам ресурсов Росаккредитации

            </li>
            <li>Консультирование и информирование по вопросам, в рамках поставки
            </li>
            <li>Составление проекта ТД (в рамках одной из процедур ИМ40 / ЭК10)
            </li>
            <li>Формализация документов для Таможни
            </li>
            <li>Таможенное декларирование товаров за ЭЦП Таможенного</li>
            <li>Представителя,
              содержащихся в одном транспортном средстве, без учета добавочных листов
            </li>
            <li>Сопровождение ТД, на всех этапах оформления
            </li>
            <li>Ответы на запросы Таможни
            </li>
            <li>Контроль списания сумм таможенных платежей (пошлины, НДС) и сборов в ходе
              таможенного оформления
            </li>
            <li>Внесение обеспечения за счет денежных средств Таможенного представителя в рамках
              проводимых дополнительных проверок в сроки таможенного оформления, взимается
              одноразово 3% от суммы обеспечения (при необходимости)
            </li>
            <li>Персональный менеджер
            </li>
            <li>Доступ в личный кабинет 24/7
            </li>
            <li>Управление счетом онлайн
            </li>
            <li>Закрывающие документы онлайн
            </li>
            <li>Выгрузка отчетности онлайн</li>
          </ul>
        )}


        {isLight && (
          <ul className={styles.TariffList}>
            <li>Предварительный расчет таможенной стоимости и размера таможенных платежей, по
              кодам ТНВЭД предоставленных Постоянным клиентом
            </li>
            <li>Проверка полноты комплекта документов для подачи ТД
            </li>
            <li>Проверка подлинности Сертификатов и Деклараций Соответствия на товары
              необходимых для ТО, по средствам ресурсов Росаккредитации
            </li>
            <li>Регистрация Постоянного Клиента, на таможне (при необходимости)
            </li>
            <li>Составление проекта ТД, ИМ40
            </li>
            <li>Формализация документов для Таможни
            </li>
            <li>Таможенное декларирование товаров за ЭЦП Постоянного клиента, содержащихся в
              одном транспортном средстве, без учета добавочных листов
            </li>
            <li>Ответы на запросы Таможни
            </li>
            <li>Доступ в личный кабинет 24/7
            </li>
            <li>Выгрузка отчетности онлайн</li>
          </ul>
        )}

        {isDeclarant && false && (
          <div className={styles.Legend}>
            <ul className={styles.ExtasList}>
              <li>
                <span>Услуга обещанный платеж</span>
              </li>
              <li>
                <span>Организация международной логистики</span>
              </li>
              <li>
                <span>
                  Организация оформления и получение разрешительных документов
                  на товары
                </span>
              </li>
            </ul>
            <div className={styles.TariffTitle}>Декларант</div>
          </div>
        )}
      </div>
    </div>
  );
};
