import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { checkResetToken, setNewPW } from '../../../api';
import { ROUTES } from '../../../consts';
import { Input } from '../../shared/Input';
import { Button } from '../../shared/Button';
import { Title } from '../../shared/Title';
import { LoadingPage } from '../../hocs/withLoading/LoadingPage';
import { NotFound } from '../../../pages/NotFound';
import { SetNewPasswordSchema } from '../../../utils';

import styles from './Login.module.scss';

const DEFAULT_DATA_STATE = {
  password: '',
  repeatPassword: '',
};

const DEFAULT_ERROR_STATE = {
  password: null,
  repeatPassword: null,
};

export const SetNewPassword = () => {
  const { hash } = useParams();
  const [success, setSuccess] = useState(false);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(DEFAULT_DATA_STATE);
  const [errors, setErrors] = useState(DEFAULT_ERROR_STATE);

  useEffect(() => {
    async function validate() {
      try {
        const { success } = await checkResetToken(hash);
        if (success) {
          setValid(true);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }

    if (hash) {
      validate();
    }
  }, [hash]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const handleRequest = async () => {
    const res = await setNewPW(hash, { password: data.password });
    if (res.success) {
      setErrors(DEFAULT_ERROR_STATE);
      setSuccess(true);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      await SetNewPasswordSchema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      setErrors(DEFAULT_ERROR_STATE);
      handleRequest();
    } catch (errors) {
      const validationErrors = {};

      errors.inner.forEach((error) => {
        if (error.path) {
          validationErrors[error.path] = error.message;
        }
      });

      setErrors(validationErrors);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (!valid) {
    return <NotFound />;
  }

  return (
    <div className={styles.Login}>
      <Title className={styles.Title}>Установите новый пароль</Title>
      <div className={styles.Desc}>
        Пароль не может быть короче 8 символов и должен состоять из латинских
        букв и цифр
      </div>
      {success ? (
        <Title className={styles.Title}>Пароль успешно обновлен.</Title>
      ) : (
        <>
          <div className={styles.InputWrapper}>
            <div className={styles.InputWrapper__Title}>Придумайте пароль</div>
            <div className={styles.InputWrapper__InputRow}>
              <Input
                className={styles.InputWrapper__Input}
                type="password"
                name="password"
                placeholder="Пароль"
                value={data.password}
                onChange={handleChange}
                error={errors.password}
              />
            </div>
            <div className={styles.InputWrapper__Error}>{errors.password}</div>
          </div>
          <div className={styles.InputWrapper}>
            <div className={styles.InputWrapper__Title}>Повторите пароль</div>
            <div className={styles.InputWrapper__InputRow}>
              <Input
                className={styles.InputWrapper__Input}
                type="password"
                placeholder="Пароль"
                name="repeatPassword"
                value={data.repeatPassword}
                onChange={handleChange}
                error={errors.repeatPassword}
              />
              <div className={styles.InputWrapper__Tip}></div>
            </div>
            <div className={styles.InputWrapper__Error}>
              {errors.repeatPassword}
            </div>
          </div>
        </>
      )}
      <div className={styles.ButtonArea}>
        {!success && (
          <Button filled onClick={handleSubmit}>
            Обновить пароль
          </Button>
        )}
        <div className={styles.RegisterLink}>
          <Link to={ROUTES.HOME}>Вернуться на главную</Link>
        </div>
      </div>
    </div>
  );
};
