import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import Link from '../../assets/Link.svg';
import { createSystemRequest } from '../../api';
import { ROUTES } from '../../consts';
import { getCompany, updateEsign } from '../../store/companies';
import { Modal } from '../shared/Modal';
import { Tabs } from '../shared/Tabs';
import { Title } from '../shared/Title';
import { AttachButton, Button, UploadButton } from '../shared/Button';

import styles from './Esign.module.scss';

const companyTabs = (
  <Tabs
    tabs={[
      {
        title: 'Общие сведения о компании',
        route: ROUTES.FULL_COMPANY_INFO,
        href: './info',
        visible: true,
      },
      {
        title: 'Документы компании',
        route: ROUTES.FULL_COMPANY_DOCS,
        href: './docs',
        visible: true,
      },
      // {
      //   title: 'Электронно-цифровая подпись',
      //   route: ROUTES.COMPANY_ESIGN,
      //   href: './esign',
      //   visible: true,
      // },
    ]}
  />
);

export const Esign = () => {  
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const dispatch = useDispatch();
  const handleEsignUpload = (value) =>
    dispatch(updateEsign({ key: 'esign', value: value[0] }));
  const company = useSelector(getCompany);
  const { esign } = company;

  const handleSend = async () => {
    await createSystemRequest({ text, type: 'ЭЦП' });
    setOpen(false);
    setText('');
    toast.success('Заявка отправлена');
  };

  const noLink = (
    <>
      <p>
        Вы можете создать ЭЦП по выгодным условиям у наших партнеров за полчаса
        или загрузить файл по кнопке.
      </p>
      <div className={styles.ButtonArea}>
        <Button filled onClick={() => setOpen(true)}>
          Получить ЭЦП
        </Button>
        <div className={styles.AttachButtonWrapper}>
          <AttachButton onUpload={handleEsignUpload}>
            Загрузить ЭЦП
          </AttachButton>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Title style={{ textAlign: 'center' }}>Заявка на получение ЭЦП</Title>

        <textarea
          style={{
            borderColor: '#D9D9D9',
            borderRadius: '4px',
            height: '188px',
            width: '30rem',
            resize: 'vertical',
            outline: 'none',
            padding: '0.5rem',
            marginBottom: '3rem',
          }}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button filled onClick={handleSend}>
            Отправить
          </Button>
        </div>
      </Modal>
    </>
  );

  const hasLink = (
    <div className={styles.Ready}>
      <div className={styles.LinkArea}>
        <div className={styles.LinkArea__Img}>
          <img src={Link} alt="link" />
        </div>
        <a href={esign} target="_blank" rel="noopener noreferrer">
          загруженный файл.doc
        </a>
      </div>
      <UploadButton id="reapload-esign" filled onUpload={handleEsignUpload}>
        Изменить
      </UploadButton>
    </div>
  );

  return (
    <div className={styles.Wrapper}>
      {companyTabs}
      <div className={styles.Esign}>
        <div className={styles.Esign__Title}>
          <Title as="h3">Электронно цифровая подпись</Title>
        </div>
        <div className={styles.Esign__Content}>{esign ? hasLink : noLink}</div>
      </div>
    </div>
  );
};
