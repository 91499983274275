import { WORK_FIELDS } from '../../configs/work';
import { DOCUMENTS_STEPS } from '../../consts';
import {
  ADD_PLACE,
  DELETE_PLACE,
  EDIT_PLACE,
  TOGGLE_OPEN,
  UPDATE_VALUE,
  UPDATE_WORK,
  SET_LOADING,
} from './actions';

export const INITIAL_PLACE = {
  name: '',
  startDate: '',
  finishDate: '',
  degree: '',
  notFinished: false,
  loading: false,
};

const initialState = {
  step: DOCUMENTS_STEPS.EMPTY,
  open: false,
  errors: {},
  places: [],
  newPlace: INITIAL_PLACE,
  editedPlace: null,
};

Object.keys(WORK_FIELDS).forEach((field) => {
  initialState[field] = WORK_FIELDS[field].initialValue;
});

export const workReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLACE:
      if (state.editedPlace === null) {
        return {
          ...state,
          places: [...state.places, { ...state.newPlace }],
          newPlace: INITIAL_PLACE,
        };
      } else {
        const arrayPlaces = [...state.places];
        arrayPlaces.splice(state.editedPlace, 0, state.newPlace);
        return {
          ...state,
          places: arrayPlaces,
          newPlace: INITIAL_PLACE,
          editedPlace: null,
        };
      }
    case DELETE_PLACE:
      return {
        ...state,
        places: [...state.places].filter((_, idx) => idx !== action.payload),
      };
    case EDIT_PLACE:
      return {
        ...state,
        newPlace: [...state.places][action.payload],
        places: [...state.places].filter((_, idx) => idx !== action.payload),
        editedPlace: action.payload,
      };
    case TOGGLE_OPEN:
      const prevOpen = state.open;
      const newState = { ...state, open: !prevOpen };

      return newState;
    case UPDATE_WORK:
      const errors = action.payload.errors || {};
      return { ...state, ...action.payload, errors };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case UPDATE_VALUE:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
};
