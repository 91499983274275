import React, { useState } from 'react';

import { getUniqueInt } from '../../../utils';
import { Modal } from '../Modal';
import { Title } from '../Title';
import { Button } from '../Button';
import GreenCheckmark from '../../../assets/GreenCheckmark.svg';
import { SurveyLine } from './SurveyLine';

import styles from './Survey.module.scss';

const getPair = (exceptions) => ({
  option: '',
  text: '',
  key: getUniqueInt(exceptions),
  error: null,
});

export const Survey = ({
  creatable,
  onClose,
  onSubmit,
  open,
  options,
  submitButtonText,
  selectPlaceholder = 'Название',
  textPlaceholder,
  title,
  requiredInput,
  message = 'Запрос отправлен',
}) => {
  const [disabled, setDisabled] = useState(true);
  const [success, setSuccess] = useState(false);
  const [pairs, setPairs] = useState([getPair()]);

  const addPair = () =>
    setPairs((prevPairs) => [
      ...prevPairs,
      getPair(Object.values(prevPairs).map(({ key }) => key)),
    ]);

  const deletePair = (keyToDelete) => {
    if (pairs.length > 1) {
      setPairs((prevPairs) => {
        const newPairs = prevPairs.filter(({ key }) => key !== keyToDelete);
        if (newPairs.every((pair) => pair.option?.trim() === '')) {
          setDisabled(true);
        }
        return newPairs;
      });
    }
  };

  const handleSubmit = () => {
    if (requiredInput) {
      let earlyReturn = false;
      const newPairs = pairs.map((pair) => {
        if (pair.text.length === 0) {
          earlyReturn = true;
          return { ...pair, error: 'Обязательное поле' };
        }
        return pair;
      });

      if (earlyReturn) {
        return setPairs(newPairs);
      }
    }

    onSubmit(pairs);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
      setPairs([getPair()]);
      setDisabled(true);
      onClose();
    }, 2500);
  };

  const successMessage = (
    <>
      <div className={styles.Title}>{message}</div>
      <div className={styles.Checkmark}>
        <img src={GreenCheckmark} alt="checkmark" />
      </div>
    </>
  );

  return (
    <Modal onClose={onClose} open={open}>
      {success ? (
        successMessage
      ) : (
        <div className={styles.Wrapper}>
          <Title as="h2">{title}</Title>
          <div className={styles.MainArea}>
            {pairs.map((pair) => (
              <SurveyLine
                creatable={creatable}
                key={pair.key}
                pair={pair}
                options={options}
                onDelete={() => deletePair(pair.key)}
                pairs={pairs}
                setPairs={(cb) => {
                  if (disabled) {
                    setDisabled(false);
                  }
                  setPairs(cb);
                }}
                textPlaceholder={textPlaceholder}
                selectPlaceholder={selectPlaceholder}
              />
            ))}
            <div className={styles.AddDocument} onClick={addPair}>
              + Добавить
            </div>
          </div>
          <div>
            <Button disabled={disabled} filled onClick={handleSubmit}>
              {submitButtonText}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
