import React from 'react';
import classnames from 'classnames';

import Exclamation from '../../../assets/Exclamation.svg';
import InfoCircle from '../../../assets/InfoCircle.svg';
import { Title } from '../Title';

import styles from './Message.module.scss';

export const Message = ({
  children,
  type = 'exclamation',
  underlined,
  style,
  full,
}) => {
  let imgSrc;

  switch (type) {
    case 'exclamation':
      imgSrc = Exclamation;
      break;
    case 'info':
      imgSrc = InfoCircle;
      break;
    default:
      imgSrc = Exclamation;
  }

  return (
    <div
      className={classnames(styles.Message, {
        [styles['Message--underlined']]: underlined,
      })}
      style={style}
    >
      <img
        src={imgSrc}
        alt={type}
        className={classnames(styles.Message__Image, {
          [styles['Message__Image--full']]: full,
        })}
      />
      <Title className={styles.Title} as="h3" thin>
        {children}
      </Title>
    </div>
  );
};
