import React from 'react';
import { useDispatch } from 'react-redux';

import { updateValue } from '../../store/passport';
import { SEX_OPTIONS } from '../../configs/passport';
import { Input } from '../shared/Input';
import { Select } from '../shared/Select';
import { Title } from '../shared/Title';
import ExclamationRed from '../../assets/ExclamationRed.svg';

import styles from './DataBlock.module.scss';

export const DataBlock = ({
  field,
  label,
  type,
  value,
  error,
  disabled,
  lightgrey,
  mark,
}) => {
  const dispatch = useDispatch();
  const handleChange = (event) => {
    dispatch(updateValue({ key: field, value: event.target.value }));
  };

  // TODO don't hardcode options
  if (type === 'select' && !disabled) {
    return (
      <div className={styles.DataBlock}>
        <Title as="h4">
          <div className={styles.Title}>
            {label}
            {mark && (
              <img
                src={ExclamationRed}
                alt="ExclamationRed"
                className={styles.Exclamation}
              />
            )}
          </div>
        </Title>
        <div className={styles.InputWrapper}>
          <Select
            options={Object.values(SEX_OPTIONS)}
            value={SEX_OPTIONS[value]}
            onChange={({ value }) =>
              dispatch(updateValue({ key: field, value }))
            }
            placeholder={label}
          />
          {error && <div className={styles.SelectError}>{error}</div>}
        </div>
      </div>
    );
  }

  if (type === 'selectView') value = SEX_OPTIONS[value]?.label;
  if (type === 'noDisplay') return null;

  return (
    <div className={styles.DataBlock}>
      <Title as="h4">
        <div className={styles.Title}>
          {label}
          {mark && (
            <img
              src={ExclamationRed}
              alt="ExclamationRed"
              className={styles.Exclamation}
            />
          )}
        </div>
      </Title>
      <div className={styles.InputWrapper}>
        <Input
          value={type === 'select' ? SEX_OPTIONS[value]?.label : value}
          onChange={handleChange}
          type={type}
          tooltip={error}
          disabled={disabled}
          lightgrey={lightgrey}
        />
      </div>
    </div>
  );
};
