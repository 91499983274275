import { API_ROUTES, TOKEN } from '../consts';
import { LocalStorageHelper } from '../utils';

export const uploadDocument = async (event) => {
  try {
    const { files } = event.target;

    const body = new FormData();
    for (let i = 0; i < files.length; i++) {
      body.append(`files`, files[i]);
    }

    const response = await fetch(API_ROUTES.UPLOAD, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${LocalStorageHelper.getItem(TOKEN)}`,
      },
      body,
    });

    const result = await response.json();   

    return result.map((file) => file.Location);
  } catch (error) {
    console.log(error);
  }
};
