import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getPayment } from '../api';
import { Pdf, PdfDocument } from '../components/shared/Pdf';

export const RefillFile = () => {
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState(null);
  const [systemAccount, setSystemAccount] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      const data = await getPayment(id);
      setPayment(data?.payment);
      setSystemAccount(data?.systemAccount);
      setTimeout(() => setLoading(false), 500);
    }
    fetchData();
  }, [id]);

  const src = `https://api.qrserver.com/v1/create-qr-code/?data=${window.location}`;

  return (
    <>
      {!loading && (
        <Pdf>
          <PdfDocument payment={payment} systemAccount={systemAccount} />
        </Pdf>
      )}
{/*      <img style={{ display: 'none' }} src={src} alt="url" />
      <img
        style={{ display: 'none' }}
        src="http://declarant.wires-web.com//app/themes/custom-theme/dist/images/logo_44dce396.svg"
        alt="url"
      />*/}
    </>
  );
};
