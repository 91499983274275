import {
  getUsers,
  getCompaniesForUser,
  getUserCompanies,
  getUserById,
} from '../../api/users';
import { updateProfile } from '../../api';
import { updateFilters } from '../filters';

export const LOAD_USERS_REQUEST = '@users/LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = '@users/LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = '@users/LOAD_USERS_FAILURE';
export const LOAD_USER_REQUEST = '@users/LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = '@users/LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = '@users/LOAD_USER_FAILURE';
export const LOAD_COMPANIES_REQUEST = '@users/LOAD_COMPANIES_REQUEST';
export const LOAD_COMPANIES_SUCCESS = '@users/LOAD_COMPANIES_SUCCESS';
export const LOAD_COMPANIES_FAILURE = '@users/LOAD_COMPANIES_FAILURE';
export const LOAD_USER_COMPANIES_REQUEST = '@users/LOAD_USER_COMPANIES_REQUEST';
export const LOAD_USER_COMPANIES_SUCCESS = '@users/LOAD_USER_COMPANIES_SUCCESS';
export const LOAD_USER_COMPANIES_FAILURE = '@users/LOAD_USER_COMPANIES_FAILURE';
export const UPDATE_USER_VALUE = '@users/UPDATE_USER_VALUE';
export const UPDATE_USERS_VALUE = '@users/UPDATE_USERS_VALUE';

const loadUsersRequest = () => ({
  type: LOAD_USERS_REQUEST,
});

const loadUsersSuccess = (payload) => ({
  type: LOAD_USERS_SUCCESS,
  payload,
});

const loadUsersFailure = (payload) => ({
  type: LOAD_USERS_FAILURE,
  payload,
});

const loadUserRequest = () => ({
  type: LOAD_USER_REQUEST,
});

const loadUserSuccess = (payload) => ({
  type: LOAD_USER_SUCCESS,
  payload,
});

const loadUserFailure = (payload) => ({
  type: LOAD_USER_FAILURE,
  payload,
});

const loadCompaniesRequest = () => ({
  type: LOAD_COMPANIES_REQUEST,
});

const loadCompaniesSuccess = (payload) => ({
  type: LOAD_COMPANIES_SUCCESS,
  payload,
});

const loadCompaniesFailure = (payload) => ({
  type: LOAD_COMPANIES_FAILURE,
  payload,
});

const loadUserCompaniesRequest = () => ({
  type: LOAD_USER_COMPANIES_REQUEST,
});

const loadUserCompaniesSuccess = (payload) => ({
  type: LOAD_USER_COMPANIES_SUCCESS,
  payload,
});

const loadUserCompaniesFailure = (payload) => ({
  type: LOAD_USER_COMPANIES_FAILURE,
  payload,
});

export const updateUserValue = (payload) => ({
  type: UPDATE_USER_VALUE,
  payload,
});

export const updateUsersValue = (payload, id) => ({
  type: UPDATE_USERS_VALUE,
  payload,
  id,
});

export const updateSetValue = (payload, id) => async (dispatch) => {
  try {
    dispatch(updateUserValue(payload));
    await updateProfile(payload, id);
  } catch (error) {
    console.log(error);
  }
};

export const updateUserById = (payload, id) => async (dispatch) => {
  try {
    dispatch(updateUsersValue(payload, id));
    await updateProfile(payload, id);
  } catch (error) {
    console.log(error);
  }
};

export const loadUsers = (filters) => async (dispatch) => {
  dispatch(loadUsersRequest());
  try {
    const data = await getUsers(filters);

    dispatch(loadUsersSuccess(data));
  } catch (error) {
    loadUsersFailure(error?.data);
  }
};

export const applyFilters = (filtersKey, filters) => async (dispatch) => {
  dispatch(loadUsersRequest());
  try {
    dispatch(updateFilters({ key: filtersKey, value: filters }));
    const data = await getUsers(filters);

    dispatch(loadUsersSuccess(data));
  } catch (error) {
    loadUsersFailure(error);
  }
};

export const loadUser = (id) => async (dispatch) => {
  dispatch(loadUserRequest());
  try {
    const data = await getUserById(id);
    dispatch(loadUserSuccess(data));
  } catch (error) {
    loadUserFailure(error);
  }
};

export const loadCompanies = (filters) => async (dispatch) => {
  dispatch(loadCompaniesRequest());
  try {
    const data = await getCompaniesForUser(filters);

    dispatch(loadCompaniesSuccess(data));
  } catch (error) {
    loadCompaniesFailure(error?.data);
  }
};

export const applyCompaniesFilters =
  (filtersKey, filters) => async (dispatch) => {
    dispatch(loadCompaniesRequest());
    try {
      dispatch(updateFilters({ key: filtersKey, value: filters }));
      const data = await getCompaniesForUser(filters);

      dispatch(loadCompaniesSuccess(data));
    } catch (error) {
      loadCompaniesFailure(error);
    }
  };

export const loadUserCompanies = (id) => async (dispatch) => {
  dispatch(loadUserCompaniesRequest());

  try {
    const data = await getUserCompanies(id);

    dispatch(loadUserCompaniesSuccess(data));
  } catch (error) {
    loadUserCompaniesFailure(error?.data);
  }
};
