import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../consts';

import styles from './MenuFooter.module.scss';

export const MenuFooter = () => {
  return (
    <ul className={styles.MenuFooter}>
      <li>
        <Link to={ROUTES.FULL_REVIEWS}>Обратная связь</Link>
      </li>
    </ul>
  );
};

/*      <li>
        <Link to={ROUTES.FULL_LOGISTICS}>Логистика</Link>
      </li>
      <li>
        <Link to={ROUTES.FULL_CERTIFICATION}>Сертификация</Link>
      </li>
      <li>
        <Link to={ROUTES.FULL_BUSINESS_CLUB}>Бизнес клуб</Link>
      </li>*/
