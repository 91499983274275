import React, { useState } from 'react';
import classnames from 'classnames';

import ScrollLeft from '../../../assets/ScrollLeft.svg';
import ScrollRight from '../../../assets/ScrollRight.svg';

import { DAY, MONTHS, WEEK_DAYS } from '../../../consts';
import { Button } from '../Button';

import styles from './Calendar.module.scss';

const getDefaultDate = (year, month) => new Date(year, month, 1);

export const Calendar = ({
  dateOnly,
  defaultDate = new Date(),
  onClose,
  onSubmit,
  buttonLabel,
  title,
}) => {
  const [chosenDate, setChosenDate] = useState({
    year: defaultDate.getFullYear(),
    month: defaultDate.getMonth(),
  });
  const [chosenTime, setChosenTime] = useState({ hour: 15, minute: 0 });
  const [finalDate, setFinalDate] = useState(defaultDate);

  const handleSubmit = () => {
    const resultDate = dateOnly
      ? new Date(
          finalDate.getFullYear(),
          finalDate.getMonth(),
          finalDate.getDate()
        )
      : new Date(
          finalDate.getFullYear(),
          finalDate.getMonth(),
          finalDate.getDate(),
          chosenTime.hour,
          chosenTime.minute
        );

    onSubmit(resultDate);
    onClose();
  };

  const date = getDefaultDate(chosenDate.year, chosenDate.month);
  const frozenDate = getDefaultDate(chosenDate.year, chosenDate.month);
  const weeks = [];
  let week = [];

  if (date.getDay() === 0) {
    weeks.push([{ value: 1, currentMonth: true }]);
    date.setTime(date.getTime() + DAY);
  } else if (date.getDay() === 1) {
    weeks.push([
      { value: 1, currentMonth: true },
      { value: 2, currentMonth: true },
      { value: 3, currentMonth: true },
      { value: 4, currentMonth: true },
      { value: 5, currentMonth: true },
      { value: 6, currentMonth: true },
      { value: 7, currentMonth: true },
    ]);
    date.setTime(date.getTime() + 7 * DAY);
  } else {
    week = [{ value: 1, currentMonth: true }];
    date.setTime(date.getTime() + DAY);
  }

  while (date.getMonth() === chosenDate.month) {
    while (date.getDay() > 0) {
      week.push({
        value: date.getDate(),
        currentMonth: date.getMonth() === chosenDate.month,
      });
      date.setTime(date.getTime() + DAY);
    }
    week.push({
      value: date.getDate(),
      currentMonth: date.getMonth() === chosenDate.month,
    });
    weeks.push(week);
    date.setTime(date.getTime() + DAY);
    week = [];
  }

  while (weeks[0].length < 7) {
    frozenDate.setDate(frozenDate.getDate() - 1);
    weeks[0].unshift({ value: frozenDate.getDate(), currentMonth: false });
  }

  const handleDaySelection = (day) => {
    setFinalDate(new Date(chosenDate.year, chosenDate.month, day));
  };

  const renderRow = ({ value, currentMonth }) => (
    <div
      key={`${value}-${chosenDate.month}`}
      className={classnames(styles.MonthDay, {
        [styles['MonthDay__Current']]: currentMonth,
        [styles['MonthDay__Current--selected']]:
          finalDate &&
          currentMonth &&
          finalDate.getYear() ===
            new Date(chosenDate.year, chosenDate.month, value).getYear() &&
          finalDate.getMonth() ===
            new Date(chosenDate.year, chosenDate.month, value).getMonth() &&
          finalDate.getDate() ===
            new Date(chosenDate.year, chosenDate.month, value).getDate(),
      })}
      onClick={() => currentMonth && handleDaySelection(value)}
    >
      {value}
    </div>
  );

  const renderWeekDays = (day) => {
    return (
      <div className={styles['Wrapper__WeekDays--WeekDay']} key={day}>
        {day}
      </div>
    );
  };

  const handleMonthBack = () =>
    setChosenDate((prevValue) => {
      if (prevValue.month === 0) {
        return { ...prevValue, month: 11, year: prevValue.year - 1 };
      }

      return { ...prevValue, month: prevValue.month - 1 };
    });

  const handleNextMonth = () =>
    setChosenDate((prevValue) => {
      if (prevValue.month === 11) {
        return { ...prevValue, month: 0, year: prevValue.year + 1 };
      }

      return { ...prevValue, month: prevValue.month + 1 };
    });

  const handleHourPick = (hour, minute) => () => {
    setChosenTime({ hour, minute });
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Wrapper__Head}>
        <div className={styles.ChoiceText}>{title}</div>
        <div className={styles.CalendarScroll}>
          <div
            className={styles.CalendarScroll__Left}
            onClick={handleMonthBack}
          >
            <img src={ScrollLeft} alt="scrollLeft" />
          </div>
          <div className={styles.CalendarScroll__Month}>
            {MONTHS[chosenDate.month]}
          </div>
          <div className={styles.CalendarScroll__Year}>{chosenDate.year}</div>
          <div
            className={styles.CalendarScroll__Right}
            onClick={handleNextMonth}
          >
            <img src={ScrollRight} alt="scrollRight" />
          </div>
        </div>
      </div>
      <div className={styles.Wrapper__WeekDays}>
        {WEEK_DAYS.map(renderWeekDays)}
      </div>
      <div className={styles.Wrapper__DaysArea}>
        {weeks.map((week, idx) => (
          <div
            key={`${chosenDate.month}-${idx}`}
            className={styles['Wrapper__DaysArea--Row']}
          >
            {week.map(renderRow)}
          </div>
        ))}
      </div>
      {!dateOnly && (
        <>
          <div className={styles.TimeTitle}>
            <div className={styles.ChoiceText}>Выберите время</div>
          </div>
          <div className={styles.TimeArea}>
            <div
              className={classnames(styles.TimeArea__Time, {
                [styles['TimeArea__Time--active']]:
                  chosenTime.hour === 15 && chosenTime.minute === 0,
              })}
              onClick={handleHourPick(15, 0)}
            >
              15:00 &#8722; 15:30
            </div>
            <div
              onClick={handleHourPick(15, 30)}
              className={classnames(styles.TimeArea__Time, {
                [styles['TimeArea__Time--active']]:
                  chosenTime.hour === 15 && chosenTime.minute === 30,
              })}
            >
              15:30 &#8722; 16:00
            </div>
            <div
              className={classnames(styles.TimeArea__Time, {
                [styles['TimeArea__Time--active']]:
                  chosenTime.hour === 16 && chosenTime.minute === 0,
              })}
              onClick={handleHourPick(16, 0)}
            >
              16:00 &#8722; 16:30
            </div>
            <div
              className={classnames(styles.TimeArea__Time, {
                [styles['TimeArea__Time--active']]:
                  chosenTime.hour === 16 && chosenTime.minute === 30,
              })}
              onClick={handleHourPick(16, 30)}
            >
              16:30 &#8722; 17:00
            </div>
            <div
              className={classnames(styles.TimeArea__Time, {
                [styles['TimeArea__Time--active']]:
                  chosenTime.hour === 17 && chosenTime.minute === 0,
              })}
              onClick={handleHourPick(17, 0)}
            >
              17:00 &#8722; 17:30
            </div>
          </div>
        </>
      )}
      <div className={styles.Wrapper__ButtonArea}>
        <Button onClick={handleSubmit} filled>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};
