import React from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import classnames from 'classnames';

import Exclamation from '../../../assets/Exclamation.svg';
import styles from './Tabs.module.scss';

import { Tooltip} from '../Tooltip';
import { Title} from '../Title';

const MatchRoute = ({ isPart, path, title, hasSubTabs }) => {
  if (!isPart) path = path+"/*";
  const match = useMatch({
    path: path,
    exact: hasSubTabs ? false : true,
  });

  return (
    <>
      <div
        className={classnames(styles.Tab__Title, {
          [styles['Tab__Title--active']]: match,
        })}
      >
        {title}
      </div>
      {match && <div className={styles.Tab__Underline} />}
    </>
  );
};

export const Tabs = ({ tabs, hasSubTabs }) => {
  const navigate = useNavigate();  
  return (
    <div className={styles.Tabs}>
      {tabs.map(({ isPart, route, href, replace, title, withWarning, visible }) => {
        return (visible)
        ?(<div
          key={route}
          className={styles.Tab}
          onClick={navigate.bind(null, (href || route), replace? {replace: true}:{replace: false})}
        >
          <MatchRoute isPart={isPart} path={route} title={title} hasSubTabs={hasSubTabs} />
          {withWarning && withWarning.visible && (
            <div
              className={classnames(styles.Tooltip, {
                [styles['Tooltip--offset20']]: true,
              })}
            >            
              <Tooltip withInfo iconSrc="exclamation">
                {withWarning.title && <Title as="h4">{withWarning.title}</Title>}
                <p className={styles.Tooltip__Content}>{withWarning.description}</p>
              </Tooltip>
            </div>
          )}
        </div>):undefined;
      })}
    </div>
  );
};



/*            <div className={styles.Warning}>
              <img src={Exclamation} alt="warning" />
            </div>*/
