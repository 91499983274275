import { LocalStorageHelper } from '../../utils/localStorage';
import { TOKEN } from '../../consts';
import {
  SET_TOKEN,
  UPDATE_AUTH,
  UPDATE_LOGIN_FORM,
  UPDATE_REGISTER_FORM
} from './actions';

const initialState = {
  token: LocalStorageHelper.getItem(TOKEN),
  offerta: false,
  loginForm: {
    emailOrPhone: '',
    password: '',
    errors: {
      emailOrPhone: '',
      password: '',
    },
  },
  registerForm: {
    email: '',
    password: '',
    repeatPassword: '',
    errors: {
      email: '',
      password: '',
      repeatPassword: '',
    },
  }
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AUTH:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case UPDATE_REGISTER_FORM:
      return {
        ...state,
        registerForm: {
          ...state.registerForm,
          [action.payload.key]: action.payload.value,
        },
      };
    case UPDATE_LOGIN_FORM:
      return {
        ...state,
        loginForm: {
          ...state.loginForm,
          [action.payload.key]: action.payload.value,
        },
      };
    case SET_TOKEN:
      const token = action.payload;
      LocalStorageHelper.addItem(TOKEN, token);
      return { ...state, token };
    default:
      return state;
  }
};
