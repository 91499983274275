import React from 'react';
import { format } from 'date-fns';

import { ACTION_OPTIONS } from '../../configs/transactions';
import { formatMoney } from '../../utils';
import { TRANSACTIONS_TYPES } from '../../consts';

export const ACCOUNTS_TABLE_HEADER = [
  {
    label: 'Владелец счета',
    value: 'name',
    sortable: true,
    renderItem: true,
    render: (item) =>
      item?.name === undefined && item?.surname === undefined
        ? ''
        : `${item.name} ${item.surname}`,
  },
  {
    label: 'Последняя операция',
    value: 'lastTransaction',
    renderItem: true,
    render: (item) =>
      item?.lastTransaction
        ? item.lastTransaction.type !== TRANSACTIONS_TYPES.ADD_PAYMENT_CADA ||
          item.role !== 'declarant'
          ? ACTION_OPTIONS[item.lastTransaction.action]?.label
          : 'Пополнение'
        : '',
  },
  {
    label: 'Дата операции',
    value: 'createdAt',
    renderItem: true,
    render: (item) =>
      item?.lastTransaction
        ? format(new Date(item.lastTransaction.createdAt), 'dd.MM.yyyy HH:mm')
        : '',
  },
  {
    label: 'Сумма операции',
    value: 'sum',
    renderItem: true,
    render: (item) =>
      item?.lastTransaction ? (
        <div>
          {item.lastTransaction.action === 'withdrawals' &&
          (item.lastTransaction.type !== TRANSACTIONS_TYPES.ADD_PAYMENT_CADA ||
            item.role !== 'declarant') ? (
            <div>-{formatMoney(item.lastTransaction.sum)}</div>
          ) : (
            <div>+{formatMoney(item.lastTransaction.sum)}</div>
          )}
        </div>
      ) : (
        ''
      ),
  },
  {
    label: 'Состояние счета',
    value: 'balance',
    renderItem: true,
    render: (item) =>
      item?.lastTransaction ? (
        <div>
          {item.lastTransaction.action === 'refill' ||
          (item.lastTransaction.type === TRANSACTIONS_TYPES.ADD_PAYMENT_CADA &&
            item.role === 'declarant') ? (
            <div>{formatMoney(item.lastTransaction.receiverBalance)}</div>
          ) : (
            <div>{formatMoney(item.lastTransaction.agentBalance)}</div>
          )}
        </div>
      ) : (
        '0'
      ),
  },
  {
    label: '',
    value: 'rowActions',
    render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
  },
];
