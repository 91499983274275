import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { updatePayment } from '../../api';
import { TRANSACTIONS_TYPES } from '../../consts';
import { generateTransaction } from '../../utils';
import { Button } from '../shared/Button';
import { Modal } from '../shared/Modal';
import { Title } from '../shared/Title';
import { Upload } from '../shared/Upload';

export const ResolveModal = ({ id, open, onClose, setPayment, role }) => {
  const [value, setValue] = useState();

  const handleClick = async () => {

    try {
      const response = await updatePayment(id, {
        status: 'resolved',
        confirmationDoc: value,
      });

      const { payment } = response;

      if (payment) {
        const { amount, profile, type } = payment;
        const typeTransaction =
          type === 'refill'
            ? TRANSACTIONS_TYPES.REFILL_CA
            : role === 'declarant'
            ? TRANSACTIONS_TYPES.DEBIT_DA
            : TRANSACTIONS_TYPES.DEBIT_PA;

        generateTransaction(typeTransaction, amount, profile);
        setPayment((prevPayment) => ({ ...prevPayment, status: 'resolved' }));
        onClose();
        setTimeout(()=>{
          toast.success(`Запрос исполнен.`);          
        }, 400);        
      }

    }catch(error){
      if (error?.error){        
        setTimeout(()=>{
          toast.error(`${error.error} Возможно компания удалена.`);          
        }, 400);
      }
      onClose();
        setTimeout(()=>{
          toast.error(`Произошла ошибка, проверьте соединение.`);          
        }, 400);            
    }

  };

  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ textAlign: 'center' }}>
        <Title>Подтверждение</Title>
        <div style={{ marginLeft: '3rem' }}>
          <Upload
            id="confirmationDoc"
            title={
              value ? (
                <span
                  style={{ margin: '0 auto' }}
                  role="img"
                  aria-label="yes-checkmark"
                >
                  Файл загружен ✅
                </span>
              ) : (
                'Загрузите подтверждающий документ'
              )
            }
            value={value}
            onUpload={(files) => setValue(files[0])}
          />
        </div>
        <Button disabled={!value} onClick={handleClick} filled>
          Принять
        </Button>
      </div>
    </Modal>
  );
};
