import React, { useState } from 'react';

import { Title } from '../shared/Title';
import { HighestEducationView } from './HighestEducationView';
import { EducationCoursesView } from './EducationCoursesView';
import { Accordion } from '../shared/Accordion';

import styles from './EducationFormView.module.scss';

export const EducationFormView = ({ education }) => {
  const [educationOpen, setEducationOpen] = useState(true);
  const handleToggle = () => setEducationOpen(!educationOpen);

  return (
    <Accordion
      title={
        <div className={styles.Title}>
          <div className={styles.Title__Text}>Образование</div>
        </div>
      }
      open={educationOpen}
      onToggle={handleToggle}
      fadedToggler
    >
      <div>
        <Title as="h4">Данные о высшем образовании</Title>
        <HighestEducationView education={education} />
        <Title as="h4">Дополнительные курсы и сертификаты</Title>
        <EducationCoursesView courses={education.courses} />
      </div>
    </Accordion>
  );
};
