import React, { useState } from 'react';
import { format } from 'date-fns';

import { Accordion } from '../Accordion';
import { PersonalInput } from '../../Personal/PersonalInput';

import styles from './PersonalInformation.module.scss';

const FIELDS = [
  {
    label: 'Имя',
    key: 'name',
  },
  {
    label: 'Фамилия',
    key: 'surname',
  },
  {
    label: 'Дата регистрации',
    key: 'createdAt',
    renderValue: (value) =>
      value ? format(new Date(value), 'dd.MM.yyyy') : 'информация недоступна',
  },
  {
    label: 'E-mail',
    key: 'email',
  },
  {
    label: 'Телефон',
    key: 'phone',
  },
  {
    label: 'id',
    key: '_id',
  },
];

export const PersonalInformation = ({ profile, permitEdit }) => {
  const [infoOpen, setInfoOpen] = useState(true);
  const handleToggle = () => setInfoOpen(!infoOpen);

  return (
    <Accordion
      open={infoOpen}
      title="Личная информация"
      onToggle={handleToggle}
      fadedToggler
    >
      <div className={styles.PersonalFields}>
        {FIELDS.map((field) => {
          const { key, renderValue } = field;
          const fieldValue = profile[key];
          const value = renderValue ? renderValue(fieldValue) : fieldValue;

          return (
            <PersonalInput
              key={key}
              label={field.label}
              value={value}
              onChange={{}}
              permitEdit={permitEdit}
            />
          );
        })}
      </div>
    </Accordion>
  );
};
