export const costDecore = (sumInt, currencySymbol) => {

	let sum;
	sum = sumInt.toString();
	
	for (let i = sum.length; i > 0; i = i-3){
		if( i != sum.length ){						
			sum = sum.slice(0,i)+" "+sum.slice(i);						
		}
	}

	return sum+" "+currencySymbol;	
}