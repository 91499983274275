import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getRequest,
  updateItem,
  updateRequest,
  excludeFormedRequestDocs,
} from '../../store/requests';
import { Accordion } from '../shared/Accordion';
import { DeliveryInfo } from './DeliveryInfo';
import { Documents } from './Documents';
import { getDocsQueriesByRequestId } from '../../api/docsQueries';
import { DocsQuery } from './DocsQuery';

export const RequestDetailInfo = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const request = useSelector(getRequest);
  const viewOnly = request.status === 'done';

  const changeHandler = (key, value) => {
    dispatch(updateItem({ key, value }));
    dispatch(updateRequest);
  };
  const onExclude = (docGroup, doc) => {
    dispatch(excludeFormedRequestDocs(docGroup, doc));
    dispatch(updateRequest);
  };

  useEffect(() => {
    let unmounted = false;
    async function fetchData() {
      const { docsQueries } = await getDocsQueriesByRequestId(request._id, {
        done: false,
      });

      if (!unmounted) {
        setData(docsQueries);
        setTimeout(() => setLoading(false), 500);
      }
    }

    if (request._id) {
      fetchData();
    }

    return () => {
      unmounted = true;
    };
  }, [request._id]);

  return (
    <>
      {!loading &&
        data?.length > 0 &&
        data.map((query) => (
          <DocsQuery query={query} key={query._id} setData={setData} />
        ))}
      <div>
        <Accordion
          fadedToggler
          title="Данные по заявке"
          open={open}
          onToggle={() => setOpen((prevOpen) => !prevOpen)}
        >
          <DeliveryInfo
            disabled
            showDiscount
            price={request.price}
            promocode={request.promocode}
            discount={request.discount}
            deliveryInfo={{ ...request, company: request?.company?._id }}
            onChange={changeHandler}
          />
          <Documents
            documents={request}
            onChange={changeHandler}
            onExclude={onExclude}
            viewOnly={viewOnly}
          />
        </Accordion>
      </div>
    </>
  );
};
