import React from 'react';
import classnames from 'classnames';
import styles from './HeaderWithChapter.module.scss';

export const HeaderWithChapter = ({ title, chapter }) => {
  return (
        <div className={classnames(styles.descr_item_choosen)}>
          <div className={classnames(styles.current_choice_item)}>{ title }</div>
          <div className={classnames(styles.current_choice_part)}>{ chapter }</div>
        </div>
  );
};