import React from 'react';
import classnames from 'classnames';

import InfoCircle from '../../../assets/InfoCircle.svg';

import styles from './Panel.module.scss';

export const Panel = ({ children, fluid, title }) => {
  return (
    <div
      className={classnames(styles.Wrapper, {
        [styles['Wrapper--fluid']]: fluid,
      })}
    >
      <div className={styles.Header}>
        <div>
          <img src={InfoCircle} alt="info" />
        </div>
        <div>{title}</div>
      </div>
      <div>{children}</div>
    </div>
  );
};
