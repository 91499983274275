import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getLoginForm, login, updateLoginForm } from '../../../store/auth';
import { Input } from '../../shared/Input';
import { Button } from '../../shared/Button';
import { Title } from '../../shared/Title';

import styles from './Login.module.scss';
import { ROUTES } from '../../../consts';

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { emailOrPhone, password, errors } = useSelector(getLoginForm);
  const handleChange = (key) => (e) =>
    dispatch(updateLoginForm({ key, value: e.target.value }));
  const handleSubmit = () => {
    dispatch(login(navigate));
  };  

  return (
    <div className={styles.Login}>
      <Title className={styles.Title}>Вход</Title>
      <div className={styles.Desc}>
        Чтобы авторизоваться, пожалуйста, введите логин и пароль
      </div>
      <div className={styles.InputWrapper}>
        <div className={styles.InputWrapper__Title}>Логин</div>
        <Input
          value={emailOrPhone}
          error={errors.emailOrPhone}
          placeholder="Введите почту"
          onChange={handleChange('emailOrPhone')}
        />
        {errors.emailOrPhone && (
          <div className={styles.InputWrapper__Error}>
            {errors.emailOrPhone}
          </div>
        )}
      </div>
      <div className={styles.InputWrapper}>
        <div className={styles.InputWrapper__Title}>
          <div>Пароль</div>
          <div>
            <Link tabIndex="1" to={ROUTES.FULL_AUTH_GATE_RESET_PASSWORD}>
              Забыли пароль?
            </Link>
          </div>
        </div>
        <Input
          value={password}
          placeholder="Введите пароль"
          type="password"
          error={errors.password}
          onChange={handleChange('password')}
          onEnter={handleSubmit}
        />
        {errors.password && (
          <div className={styles.InputWrapper__Error}>{errors.password}</div>
        )}
      </div>
      <div className={styles.ButtonArea}>
        <Button filled onClick={handleSubmit}>
          Войти
        </Button>
        <div className={styles.RegisterLink}>
          <Link to={ROUTES.FULL_AUTH_GATE_REGISTER_CLIENT}>Пока нет аккаунта?</Link>
        </div>
      </div>
    </div>
  );
};
