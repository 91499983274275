export const EDUCATION_HIGHEST = {
  institution: {
    label: 'Название учебного заведения',
    initialValue: '',
  },
  degree: {
    label: 'Степень образования',
    initialValue: '',
  },
  startYear: {
    label: 'Год начала учебы',
    initialValue: '',
  },
  finishYear: {
    label: 'Год окончания',
    initialValue: '',
  },
  notFinished: {
    label: 'По настоящее время',
    initialValue: false,
  },
};

export const EDUCATION_REJECT_REASON = {
  lackOfEducation: {
    value: 'lackOfEducation',
    label: 'Образование',
    type: 'noDisplay',
  },
  lackOfCourses : {
    value: 'lackOfCourses',
    label: 'Дополнительные курсы и сертификаты',
    type: 'noDisplay',
  },
  other: {
    value: 'other',
    label: 'Прочее',
    type: 'noDisplay',
  },
};

export const EDUCATION_ANCHOR = "educationAnch";
