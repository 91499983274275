import { TOKEN } from '../consts';
import { LocalStorageHelper } from './localStorage';

export class ApiHelper {
  static getHeaders() {
    return {
      Authorization: `Bearer ${LocalStorageHelper.getItem(TOKEN)}`,
      'Content-Type': 'application/json',
      mode:'cors',
    };
  }

  static preparePayload(payload) {
    return payload && JSON.stringify(payload);
  }

  static async prepareResponse(res) {
    const data = await res.json();
    if (res.status > 205) {
      return Promise.reject(data);
    }

    return data;
  }

  static async get(url) {
    const response = await fetch(url, {
      headers: ApiHelper.getHeaders(),
    });

    const result = await ApiHelper.prepareResponse(response);

    return result;
  }

  static async post(url, payload) {
    const body = ApiHelper.preparePayload(payload);

    const response = await fetch(url, {
      method: 'POST',
      headers: ApiHelper.getHeaders(),
      body,
    });

    const result = await ApiHelper.prepareResponse(response);

    return result;
  }

  static async patch(url, payload) {
    const body = ApiHelper.preparePayload(payload);
    const response = await fetch(url, {
      method: 'PATCH',
      headers: ApiHelper.getHeaders(),
      body,
    });

    const result = await ApiHelper.prepareResponse(response);

    return result;
  }

  static async delete(url, payload) {
    const body = ApiHelper.preparePayload(payload);
    const response = await fetch(url, {
      method: 'DELETE',
      headers: ApiHelper.getHeaders(),
      body,
    });

    const result = await ApiHelper.prepareResponse(response);

    return result;
  }
}
