import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useParams } from 'react-router-dom';
import {ROUTES} from '../../consts';
import {
  getDocsOpen,
  getCeoDocsOpen,
  getCompany,
  setDocsOpen,
  setCeoDocsOpen,
} from '../../store/companies';
import {Accordion} from '../shared/Accordion';
import {Tabs} from '../shared/Tabs';
import {UploadArea} from '../shared/UploadArea';

import styles from './DocumentsView.module.scss';
import {createCompanyAgreement} from "../../utils/createDocx";

  const CompanyTabs = ({id, companyId})=>{
    
    return (
      <Tabs
        tabs={[
          {
            title: 'Общие сведения о компании',
            route: ROUTES.FULL_USERS_CLIENT_COMPANY_INFO, 
            href: `../${companyId}/info`,
            visible: true,
          },
          {
            title: 'Документы компании',
            route: ROUTES.FULL_USERS_CLIENT_COMPANY_DOCS, 
            href: `../${companyId}/docs`,
            visible: true,
          }
        ]}
      />
  )}; 

const configFilesStatut = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
  addHead: 'Примечание',
  note: [
    'Обратить внимание на индекс, адрес, срок полномочий директора, наименование на английском',
  ],
};

const configFilesMemorandum = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
};

const configFilesStateRegistration = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
  addHead: 'Примечание',
  note: ['ОГРН, дата регистрации'],
};

const configFilesLegalEntityRegistry = {
  mainHead: 'Формат документа',
  docsList: [' Скан '],
};

const configFilesTaxOfficeRegistry = {
  mainHead: 'Формат документа',
  docsList: [' Скан '],
  addHead: 'Примечание',
  note: ['ИНН/КПП'],
};

const configFilesAccountSertificates = {
  mainHead: 'Формат документов',
  docsList: [' Скан '],
  addHead: 'Примечание',
  note: [
    'Со сроком не более 1 месяца на фирменных бланках Банка (содержащих информацию: название, код ОКПО, ИНН, юридический адрес, корреспондентский счет и БИК Банка',
  ],
};

const configFilesHeadAppointmentOrder = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
  addHead: 'Примечание',
  note: [
    'Если руководитель является нерезидентом, то требуется подтверждение миграционной службы на право работы в РФ, либо вид на жительство',
  ],
};

const configFilesAccountantAppointmentOrder = {
  mainHead: 'Формат документа',
  docsList: [' Скан и Word '],
};

const configFilesRegisterExtract = {
  mainHead: 'Формат документа',
  docsList: [' С сайта '],
  addHead: 'Примечание',
  note: ['Если не было никаких изменений в учредительных документах'],
};

const configFilesPassportCeo = {
  mainHead: 'Формат документа',
  docsList: [' Скан '],
  addHead: 'Примечание',
  note: ['При отсутствии прописки, необходима временная регистрация'],
};

export const DocumentsView = () => {
  const dispatch = useDispatch();
  const docsOpen = useSelector(getDocsOpen);
  const ceoDocsOpen = useSelector(getCeoDocsOpen);
  const company = useSelector(getCompany);
  let { companyId } = useParams();
  let { id } = useParams(); 

  return (
    <div className={styles.Documents}>
      <CompanyTabs  id={id} companyId={companyId}/>
      <Accordion
        title="Документы для регистрации участника ВЭД"
        open={docsOpen}
        onToggle={() => dispatch(setDocsOpen(!docsOpen))}
      >
        <div>
          <UploadArea
            files={company.statut}
            id="statut"
            configFiles={configFilesStatut}
            viewOnly
            description
            disabled
          >
            Устав компании и все изменения к нему
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.memorandum}
            id="memorandum"
            configFiles={configFilesMemorandum}
            viewOnly
            description
            disabled
          >
            Учредительный договор и все изменения к нему или решение (протокол)
            о создании организации
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.stateRegistration}
            id="stateRegistration"
            configFiles={configFilesStateRegistration}
            viewOnly
            description
            disabled
          >
            Свидетельство о государственной регистрации ФНС
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.legalEntityRegistry}
            id="legalEntityRegistry"
            configFiles={configFilesLegalEntityRegistry}
            viewOnly
            description
            disabled
          >
            Свидетельство о внесении в ЕГРЮЛ ФНС и всех изменений в
            учредительные документы
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.taxOfficeRegistry}
            id="taxOfficeRegistry"
            configFiles={configFilesTaxOfficeRegistry}
            viewOnly
            description
            disabled
          >
            Свидетельство или уведомление о постановке на учет в органах ФНС
            России
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.accountSertificates}
            id="accountSertificates"
            configFiles={configFilesAccountSertificates}
            viewOnly
            description
            disabled
          >
            Справки из банков об открытии банковских счетов (валютных, рублевых)
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.headAppointmentOrder}
            id="headAppointmentOrder"
            configFiles={configFilesHeadAppointmentOrder}
            viewOnly
            description
            disabled
          >
            Приказ (выписка из приказа) о назначении руководителя
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.accountantAppointmentOrder}
            id="accountantAppointmentOrder"
            configFiles={configFilesAccountantAppointmentOrder}
            viewOnly
            description
            disabled
          >
            Приказ (выписка из приказа) о назначении главного бухгалтера
          </UploadArea>
        </div>
        <div>
          <UploadArea
            files={company.registerExtract}
            id="registerExtract"
            configFiles={configFilesRegisterExtract}
            viewOnly
            description
            disabled
          >
            Выписка из ЕГРЮЛ
          </UploadArea>
        </div>
      </Accordion>
      <Accordion
        title="Документы руководителя компании"
        open={ceoDocsOpen}
        onToggle={() => dispatch(setCeoDocsOpen(!ceoDocsOpen))}
      >
        <div>
          <UploadArea
            files={company.passportCeo}
            id="passportCeo"
            configFiles={configFilesPassportCeo}
            viewOnly
            description
            disabled
          >
            Паспорт (главная + прописка)
          </UploadArea>
        </div>
      </Accordion>

      <Accordion
        title="Договор компании"
        open={ceoDocsOpen}
        openText={"Скачать"}
        onToggle={() => createCompanyAgreement(company)}
      >
        <div/>
      </Accordion>
    </div>
  );
};
