export const TECHNICAL_DOCS = {
  technicalDoc: { value: 'technicalDoc', label: 'Техничка' },
  extra: { value: 'extra', label: 'Дополнительно' },
};

export const CONTRACT_DOCS = {
  contract: { value: 'contract', label: 'Контракт' },
  uniqNumber: { value: 'uniqNumber', label: 'Уникальный номер/Паспорт сделки' },
  extraAgreement: { value: 'extraAgreement', label: 'Дополнения к контракту' },
  extra: { value: 'extra', label: 'Дополнительно' },
};

export const OTHER_DOCS = {
  invoice: { value: 'invoice', label: 'Инвойс' },
  packingList: { value: 'packingList', label: 'Упаковочный лист' },
  priceList: { value: 'priceList', label: 'Прайс-лист' },
  exportDT: { value: 'exportDT', label: 'Экспортная ДТ' },
  contractTEO: { value: 'contractTEO', label: 'Договор ТЭО' },
  requestTEO: { value: 'requestTEO', label: 'Заявка на ТЭО' },
  waybill: { value: 'waybill', label: 'Транспортная накладная' },
  paymentConfirmTEO: {
    value: 'paymentConfirmTEO',
    label: 'Транспортные расходы (платежка)',
  },
  originSertificate: {
    value: 'originSertificate',
    label: 'Сертификат страны происхождения',
  },
  paymentConfirmProduct: {
    value: 'paymentConfirmProduct',
    label: 'Оплата за товар (платежка)',
  },
  extra: { value: 'extra', label: 'Дополнительно' },
};

export const PERMISSION_DOCS = {
  conformitySertificate: {
    value: 'conformitySertificate',
    label: 'Сертификат соответствия',
  },
  conformityDeclaration: {
    value: 'conformityDeclaration',
    label: 'Декларация соответствия',
  },
  sgr: { value: 'sgr', label: 'СГР' },
  notification: { value: 'notification', label: 'Нотификация' },
  phytosanitarySertificate: {
    value: 'phytosanitarySertificate',
    label: 'Фитосанитарный сертификат',
  },
  veterinarySertificate: {
    value: 'veterinarySertificate',
    label: 'Ветеринарный сертификат',
  },
  extra: { value: 'extra', label: 'Дополнительно' },
};

export const matchArray = [
  {
    key: TECHNICAL_DOCS,
    section: 'Документы описания (техничка) каждого перевозимого товара',
    anchor: 'technicalDocs',
  },
  {
    key: CONTRACT_DOCS,
    section: 'ВЭД контракт и дополнения к нему',
    anchor: 'contractAnch',
  },
  {
    key: OTHER_DOCS,
    section: 'Прочие документы',
    anchor: 'otherDocs',
  },
  {
    key: PERMISSION_DOCS,
    section: 'Разрешительная документация на ввоз/вывоз товаров',
    anchor: 'permitsAnch',
  },
];

export const matchArrayDocs = [
  { value: 'technicalDoc', label: 'Техничка' },
  { value: 'extra', label: 'Дополнительно' },
  { value: 'contract', label: 'Контракт' },
  { value: 'uniqNumber', label: 'Уникальный номер/Паспорт сделки' },
  { value: 'extraAgreement', label: 'Дополнения к контракту' },
  { value: 'invoice', label: 'Инвойс' },
  { value: 'packingList', label: 'Упаковочный лист' },
  { value: 'priceList', label: 'Прайс-лист' },
  { value: 'exportDT', label: 'Экспортная ДТ' },
  { value: 'contractTEO', label: 'Договор ТЭО' },
  { value: 'requestTEO', label: 'Заявка на ТЭО' },
  { value: 'waybill', label: 'Транспортная накладная' },
  { value: 'paymentConfirmTEO', label: 'Транспортные расходы (платежка)' },
  { value: 'originSertificate', label: 'Сертификат страны происхождения' },
  { value: 'paymentConfirmProduct', label: 'Оплата за товар (платежка)' },
  { value: 'conformitySertificate', label: 'Сертификат соответствия' },
  { value: 'conformityDeclaration', label: 'Декларация соответствия' },
  { value: 'sgr', label: 'СГР' },
  { value: 'notification', label: 'Нотификация' },
  { value: 'phytosanitarySertificate', label: 'Фитосанитарный сертификат' },
  { value: 'veterinarySertificate', label: 'Ветеринарный сертификат' },
  { value: '', label: '' },
];
