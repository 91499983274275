import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SubPanel.module.sass';
import classnames from 'classnames';

import { SwitcherSubPanel } from './SwitcherSubPanel'; 
import { SimpleDropdownList } from '../../../shared/DropdownList';
import { List } from './List';
import {
	departureCityPortList,
	arrivalFromPortStationList,
	getSeaSubPanel,
	changeSubPanelState,
	departureCountryList,
	arrivalCountryList,
	getSeaCountryDeparture,
	getSeaCountryArrival,
	changeSeaCountryDeparture,
	changeSeaCountryArrival,
	getSeaStationArrivalList,
	getSeaPortDepartureList} from '../../../../store/makeOffers';


export const SubPanel = () => {

	const dispatch = useDispatch();
	const subPanelState = useSelector(getSeaSubPanel);
	const countryDeparture = useSelector(getSeaCountryDeparture);
	const countryArrival = useSelector(getSeaCountryArrival);
	const seaPortDepartureList = useSelector(getSeaPortDepartureList(countryDeparture.datatype));
	const seaStationArrival = useSelector(getSeaStationArrivalList(countryArrival.datatype));
	const countryArri = countryArrival.datatype;
	const countryDepart = countryDeparture.datatype;

	return (
		<div className={classnames(styles.wrap_sub_panel)}>
			<SwitcherSubPanel 
				state={subPanelState}
				setStateCb={(state)=>{dispatch(changeSubPanelState(state))}}
			/>
			<div className={classnames(styles.column_inputs)}>
				<SimpleDropdownList
					list={(subPanelState === "seaDeparture")? departureCountryList : arrivalCountryList}
					proposal="Выберите страну"
					width="100%"
					stepsData={(subPanelState === "seaDeparture")? countryDeparture : countryArrival}
					setStateCb={(subPanelState === "seaDeparture")? (state)=>{dispatch(changeSeaCountryDeparture(state))} : (state)=>{dispatch(changeSeaCountryArrival(state))}}
					name="countryDepart"							
				/>
				<List 
					inputName={(subPanelState === "seaDeparture")? "Порты отправления" : "Станции прибытия"}
					list={(subPanelState === "seaDeparture")? seaPortDepartureList : seaStationArrival }
					etalonList={(subPanelState === "seaDeparture")? departureCityPortList[countryDepart] : arrivalFromPortStationList[countryArri]}
					etalonName={(subPanelState === "seaDeparture")? "departCityPort": "fromPortStation"}
					country={(subPanelState === "seaDeparture")? countryDepart : countryArri}
				/>			
			</div>
		</div>
	);

}