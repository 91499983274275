import React from 'react';
import { format } from 'date-fns';

import Edit from '../../assets/Edit.svg';

export const TYPES_MAP = {
  declarant: 'Декларант',
  light: 'Лайт',
  fasterCustom: 'Ускоренное таможенное оформление',
  supportedCustom: 'Поддержка при таможенном оформлении',
  goodsLookup: 'Осуществление осмотра товаров (до подачи ДТ)',
  permissionDocs: 'Организация и получение разрешительных документов и заключений в органах государственного контроля и сертификационных органах',
  presense: 'Обеспечение присутствия при осуществлении досмотра/осмотра в ходе таможенного оформления',
  loadingControl: 'Осуществление контроля погрузочных/разгрузочных работ при проведении досмотра/осмотра товаров',
  fitoSanControl: 'Организация и проведение фитосанитарного контроля',
  docsTransfer: 'Содействие в получении перевода документов уполномоченным лицом',

};

const CATEGORIES_MAP = {
  service: 'Услуга',
  requestTariff: 'Тариф',
};

export const getHeaders = ({ setId, setPrice }) => [
  {
    label: 'Тип',
    value: 'category',
    render: (category) => CATEGORIES_MAP[category] || 'Тариф',
  },
  {
    label: 'Название',
    value: 'type',
    render: (type) => TYPES_MAP[type],
  },
  {
    label: 'Стоимость',
    value: 'price',
    render: (value) => value,
  },
  {
    label: 'Дата создания',
    value: 'createdAt',
    render: (value) => format(new Date(value), 'dd.MM.yyyy'),
  },
  {
    label: 'Дата редактирования',
    value: 'updatedAt',
    render: (value) => format(new Date(value), 'dd.MM.yyyy'),
  },

  {
    label: '',
    value: 'action',
    renderItem: true,
    render: (item) => {
      return (
        <span
          onClick={() => {
            setId(item._id);
            setPrice(item.price);
          }}
          style={{ padding: '0.5rem', cursor: 'pointer' }}
        >
          <img src={Edit} alt="edit" />
        </span>
      );
    },
  },
];
