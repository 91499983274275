import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import pDebounce from 'p-debounce';

import { getSuggestions } from '../../../api';
import { AGREEMENT_URL, ROUTES } from '../../../consts';
import {
  updateAuth,
  getRegisterForm,
  getPolicy,
  registerClient,
  updateRegisterForm,
} from '../../../store/auth';
import { Button } from '../../shared/Button';
import { Input } from '../../shared/Input';
import { Select } from '../../shared/Select';
import { Title } from '../../shared/Title';

import styles from './Register.module.scss';
import { Checkbox } from '../../shared/Checkbox';

const debouncedGetSuggestions = pDebounce(getSuggestions, 1500);

export const RegisterClient = ({ partner }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleUpdateAuth = (payload) => dispatch(updateAuth(payload));

  const {
    email,
    password,
    repeatPassword,
    errors,
  } = useSelector(getRegisterForm);
  const offerta = useSelector(getPolicy);

  const handleChange = (key) => (e) =>
    dispatch(updateRegisterForm({ key, value: e.target.value }));

  const handleSubmit = () => dispatch(registerClient(partner, navigate));

  const disabled = !offerta;

  return (
    <div className={styles.Register}>
      <Title className={styles.Title}>Регистрация</Title>
      <div className={styles.Desc}>
        Чтобы пройти регистрацию, укажите все требующиеся данные
      </div>
      <div className={styles.Form}>
        <div className={styles.InputWrapper}>
          <div className={styles.InputWrapper__Title}>Почта</div>
          <div className={styles.InputWrapper__InputRow}>
            <Input
              className={styles.InputWrapper__Input}
              type="email"
              placeholder="Введите вашу почту в формате name@example.ru"
              value={email}
              onChange={handleChange('email')}
              error={errors.email}
            />
            <div className={styles.InputWrapper__Tip}>
              Не рекомендуется указывать чужой или корпоративный адрес
              электронной почты
            </div>
          </div>
          <div className={styles.InputWrapper__Error}>{errors.email}</div>
        </div>
        <div className={styles.InputWrapper}>
          <div className={styles.InputWrapper__Title}>Придумайте пароль</div>
          <div className={styles.InputWrapper__InputRow}>
            <Input
              className={styles.InputWrapper__Input}
              type="password"
              placeholder="Пароль"
              value={password}
              onChange={handleChange('password')}
              error={errors.password}
            />
            <div className={styles.InputWrapper__Tip}>
              Пароль не может быть короче 8 символов и должен состоять из
              латинских букв и цифр.
            </div>
          </div>
          <div className={styles.InputWrapper__Error}>{errors.password}</div>
        </div>
        <div className={styles.InputWrapper}>
          <div className={styles.InputWrapper__Title}>Повторите пароль</div>
          <div className={styles.InputWrapper__InputRow}>
            <Input
              className={styles.InputWrapper__Input}
              type="password"
              placeholder="Пароль"
              value={repeatPassword}
              onChange={handleChange('repeatPassword')}
              error={errors.repeatPassword}
            />
            <div className={styles.InputWrapper__Tip}></div>
          </div>
          <div className={styles.InputWrapper__Error}>
            {errors.repeatPassword}
          </div>
        </div>
        <div className={styles.InputWrapper}>
          <div
            style={{ margin: '2.1rem 0 0.5rem' }}
            className={styles.InputWrapper__InputRow}
          >
            <Checkbox
              checked={offerta}
              onClick={() =>
                handleUpdateAuth({ key: 'offerta', value: !offerta })
              }
            >
              <div style={{ fontSize: '12px' }}>
                Я принимаю{' '}
                <a
                  href={AGREEMENT_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  соглашение об электронном взаимодействии, лицензионное
                  соглашение на использование программного обеспечения, правила
                  размещения и исполнения заданий, правила обработки
                  персональных данных, договор-оферта на оказание информационных
                  и консультационных услуг, договор оказания услуг таможенным
                  представителем
                </a>
              </div>
            </Checkbox>
          </div>
          <div className={styles.InputWrapper__Error}></div>
        </div>

        <div className={styles.ButtonWrapper}>
          <Button filled onClick={handleSubmit} disabled={disabled}>
            Зарегистрироваться
          </Button>
          <div className={styles.Link}>
            <Link to={ROUTES.FULL_AUTH_GATE_LOGIN}>Вернуться назад</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
