import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { TYPE_OPTIONS } from '../../../configs/request';
import {
  getLoading,
  getPool,
  loadPool,
  applyPoolFilters,
  getCount,
} from '../../../store/requests';
import { getRequestPoolFilters } from '../../../store/filters';
import { getProfile } from '../../../store/profile';
import { Message } from '../../shared/Message';
import { Table } from '../../shared/Table';
import { Button } from '../../shared/Button';
import { GetRequestForWork } from './GetRequestForWork';

const PreviewRequest = ({ onClick }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Button filled={false} small onClick={onClick}>
        Просмотр
      </Button>
    </div>
  );
};

export const Pool = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pool = useSelector(getPool);
  const loading = useSelector(getLoading);
  const filters = useSelector(getRequestPoolFilters);
  const countItems = useSelector(getCount);
  const verified = useSelector(getProfile).verified;

  const REQUEST_HEADERS = [
    {
      label: `№ заявки`,
      value: 'number',
      sortable: true,
    },
    {
      label: 'Дата заявки',
      value: 'createdAt',
      render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
      sortable: true,
    },
    {
      label: 'Тип перевозки',
      value: 'type',
      render: (value) => TYPE_OPTIONS[value]?.label,
    },
    {
      label: 'Компания заказчик',
      value: 'company',
      subKey: 'name',
      sortable: true,
    },
    {
      label: '',
      value: '_id',
      render: (value) => (
        <PreviewRequest
          onClick={() => navigate(`/requests/${value}/preview`)}
        />
      ),
    },
    {
      label: '',
      value: '_id',
      render: (value) => (
        <GetRequestForWork
          requestId={value}
          small
          disabled={!verified}
          offset
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(loadPool);
  }, [dispatch]);

  return (
    <div>
      {pool.length ? (
        <Table
          loading={loading}
          filters={filters}
          items={pool}
          countItems={countItems}
          headers={REQUEST_HEADERS}
          applyFilters={(filters) => dispatch(applyPoolFilters(filters))}
        />
      ) : (
        <Message type="info">Новые заявки отсутствуют</Message>
      )}
    </div>
  );
};
