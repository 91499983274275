import {Toaster as emptyToaster} from 'react-hot-toast';

export function Toaster(){

	let obj = {
		position:"top-right",
		reverseOrder:false,
		gutter:8,
		toastOptions:{	
			duration: 2500,
			success: {
				duration: 2500,
				icon: '🔥',
				style:{
					color: '#0474FD',
					fontFamily: 'Montserrat',
					fontSize: '16px'
				}
			},
			error: {
				duration: 2500,
				icon: '❗️',
				style:{
					color: '#FF0000',
					fontFamily: 'Montserrat',
					fontSize: '16px'					
				}
			}
		}
	}

	return emptyToaster.call(null, obj);
}