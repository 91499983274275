import React, {useMemo} from 'react';
import {format} from 'date-fns';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import {PAGE_PRICE} from '../../../consts';
import {formatMoney, numberInWords} from '../../../utils';
import {ru} from "date-fns/locale";
import {TYPES_MAP} from "../../Settings/headers";

Font.register({
  family: 'Roboto',
  src:
    'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

Font.register({
  family: 'Thin',
  src:
    'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-thin-webfont.ttf',
});

const styles = StyleSheet.create({
  main: {
    fontFamily: 'Roboto',
    padding: 10,
  },
  thin: {
    fontFamily: 'Thin',
    fontSize: 10,
  },
  logo: {
    width: '100px',
    height: '13px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  deadline: {
    fontSize: 10,
  },
  image: {
    width: '100px',
    height: '100px',
  },
  supplier: {
    marginTop: 10,
    marginBottom: 4,
    width: 'auto',
    fontSize: 11,
  },
  buyer: {
    fontSize: 11,
    marginTop: 4,
    marginBottom: 4,
  },
  body: {
    borderStyle: 'solid',
    borderWidth: 1,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    fontSize: 11,
  },
  tableRow: {flexDirection: 'row'},
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: 5,
  },
  tableCol1: {
    width: '12.5%',
  },
  tableCol2: {
    width: '25%',
  },
  tableCol3: {
    width: '37.5%',
  },
  tableCol4: {
    width: '50%',
  },
  tableCol8: {
    width: '100%',
  },
  tableCell: {margin: 'auto', marginTop: 5, fontSize: 10},
  h1: {
    fontSize: 20,
    marginTop: 15,
    marginBottom: 10,
    padding: 5,
    borderStyle: 'solid',
    borderBottomWidth: 1,
  },
});

export const PdfActDocument = ({request}) => {
  //const createdAt = format(new Date(request.createdAt), 'dd.MM.yyyy');
  const updatedAt = format(new Date(request.updatedAt), 'dd.MM.yyyy');

  const {basePrice, pagesPrice} = useMemo(() => {
    let basePrice = request.price - request.extraPages * PAGE_PRICE

    if (request.hasOwnProperty('tariffAddons'))
      basePrice = request.tariffAddons.reduce((acc, val) => acc - val.price, basePrice)

    return {
      basePrice: formatMoney(basePrice),
      pagesPrice: formatMoney(request.extraPages * PAGE_PRICE)
    }
  }, [request]);

  return (
    <Document title="Счет">
      <Page style={styles.main}>
        <View style={styles.header}>
          <Image style={styles.logo} source={'/pdflogo.png'}/>
          <Text style={styles.deadline}></Text>
          <Image
            style={styles.image}
            source={`https://api.qrserver.com/v1/create-qr-code/?data=${window.location}`}
          />
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableCol4]}>
              <Text>ПАО Сбербанк</Text>
              <Text style={styles.thin}>Банк получателя</Text>
              <Text style={styles.thin}></Text>
            </View>
            <View style={[styles.tableCol, styles.tableCol1]}>
              <Text>БИК</Text>
              <Text>Сч. №</Text>
            </View>
            <View style={[styles.tableCol, styles.tableCol3]}>
              <Text>044525225</Text>
              <Text>30101810400000000225</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, styles.tableCol4]}>
              <Text>ИНН 7751186633&nbsp;&nbsp;&nbsp;КПП 775101001</Text>
              <Text>ООО "МОЙ ДЕКЛАРАНТ"</Text>
              <Text style={styles.thin}>Получатель</Text>
            </View>
            <View style={[styles.tableCol, styles.tableCol1]}>
              <Text>Сч. №</Text>
            </View>
            <View style={[styles.tableCol, styles.tableCol3]}>
              <Text>40702810538000195866</Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={styles.h1}>
            Акт №{request.number} от {updatedAt}
          </Text>
        </View>
        <View style={styles.supplier}>
          <Text>
            <Text style={styles.thin}>Поставщик:</Text> ООО "МОЙ ДЕКЛАРАНТ", ИНН 7751186633,
            КПП 775101001, 108811, Москва г, Киевское Шоссе 22-Й (п Московский) км, дом ДОМОВЛ 4,
            строение 1, офис БЛ/ЭТ/П/ОФ А/4/413А/25, тел.: +7(499) 3988693
          </Text>
        </View>
        <View style={styles.buyer}>
          <Text>
            <Text style={styles.thin}>Покупатель:</Text> {request.company?.name}
            , ИНН {request.company?.itn} КПП {request.company?.kpp}. Счет{' '}
            {request.bankAccount?.accountNumber} в {request.bankAccount?.name},
            БИК {request.bankAccount?.bik}, Тел: {request.company?.phone}
          </Text>
        </View>
        <View style={styles.buyer}>
          <Text>
            <Text style={styles.thin}>Основание:</Text> Договор №{' '}
            {request.company?.number}{' '}
            {request.company?.createdAt &&
            `от ${format(new Date(request.company.createdAt), 'dd MMMM yyyy', {locale: ru})}`}
          </Text>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableRow, {textAlign: 'center'}]}>
            <View style={[styles.tableCol, {width: '20px'}]}>
              <Text>№</Text>
            </View>
            <View style={[styles.tableCol, {flexGrow: 1, textAlign: 'left'}]}>
              <Text>Товары (работы, услуги)</Text>
            </View>
            <View style={[styles.tableCol, { flexBasis: '50px' }]}>
              <Text>Кол-во</Text>
            </View>
            <View style={[styles.tableCol, {flexBasis: '30px'}]}>
              <Text>Ед.</Text>
            </View>
            <View style={[styles.tableCol, {flexBasis: '100px'}]}>
              <Text>Цена</Text>
            </View>
            <View style={[styles.tableCol, {flexBasis: '100px'}]}>
              <Text>Сумма</Text>
            </View>
          </View> 
          <View style={[styles.tableRow, {textAlign: 'center'}]}>
            <View style={[styles.tableCol, styles.thin, {width: '20px'}]}>
              <Text>1</Text>
            </View>
            <View
              style={[
                styles.tableCol,
                styles.thin,{
                flexGrow: 1,
                textAlign: 'left',
              }]}
            >
              <Text>Базовая стоимость заявки</Text>
            </View>
            <View style={[styles.tableCol, styles.thin, {width: '50px'}]}>
              <Text>1</Text>
            </View>
            <View style={[styles.tableCol, styles.thin, {width: '30px'}]}>
              <Text>шт.</Text>
            </View>
            <View
              style={[styles.tableCol, styles.thin, {flexBasis: '100px'}]}
            >
              <Text>{basePrice}</Text>
            </View>
            <View
              style={[styles.tableCol, styles.thin, {flexBasis: '100px'}]}
            >
              <Text>{basePrice}</Text>
            </View>
          </View>
          <View style={[styles.tableRow, {textAlign: 'center'}]}>
            <View style={[styles.tableCol, styles.thin, {width: '20px'}]}>
              <Text>2</Text>
            </View>
            <View
              style={[
                styles.tableCol,
                styles.thin,
                {flexGrow: 1,
                textAlign: 'left',
              }]}
            >
              <Text>Доп. листы</Text>
            </View>
            <View style={[styles.tableCol, styles.thin, {width: '50px'}]}>
              <Text>{request.extraPages}</Text>
            </View>
            <View style={[styles.tableCol, styles.thin, {width: '30px'}]}>
              <Text>шт.</Text>
            </View>
            <View
              style={[styles.tableCol, styles.thin, {flexBasis: '100px'}]}
            >
              <Text>{formatMoney(PAGE_PRICE)}</Text>
            </View>
            <View
              style={[styles.tableCol, styles.thin, {flexBasis: '100px'}]}
            >
              <Text>{formatMoney(request.extraPages * PAGE_PRICE)}</Text>
            </View>
          </View>
          {request.tariffAddons?.map((addon, index) => (
            <View key={addon.type} style={[styles.tableRow, {textAlign: 'center'}]}>
              <View style={[styles.tableCol, styles.thin, {flexBasis: '20px'}]}>
                <Text>{index + 3}</Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  styles.thin,
                  {flexGrow: 1,
                  flexWrap: 'wrap',
                  textAlign: 'left',
                  flex: 1
                }]}
              >
                <Text>Доп. услуга: {TYPES_MAP[addon.type]}</Text>
              </View>
              <View style={[styles.tableCol, styles.thin, {flexBasis: '50px'}]}>
                <Text>1</Text>
              </View>
              <View style={[styles.tableCol, styles.thin, {flexBasis: '30px'}]}>
                <Text>шт.</Text>
              </View>
              <View
                style={[styles.tableCol, styles.thin, {flexBasis: '100px'}]}
              >
                <Text>{formatMoney(addon.price)}</Text>
              </View>
              <View
                style={[styles.tableCol, styles.thin, {flexBasis: '100px'}]}
              >
                <Text>{formatMoney(addon.price)}</Text>
              </View>
            </View>
          ))}
        </View>
        {[{label: 'Итого', amount: request.price},
          {label: 'Без налога (НДС)', amount: '-'},
          {label: 'Всего к оплате', amount: request.price}
        ].map((value, index) => (<View
            key={index}
            style={[
              styles.thin,
              {display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingHorizontal: 5,
              marginVertical: 3,
            }]}
          >
            <Text>{value.label}</Text>
            <Text>
              {value.amount}
            </Text>
          </View>)
        )}

        <View
          style={[
            styles.thin,
            {display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 5,
            marginVertical: 3,
            marginTop: 15
          }]}
        >
          <Text>
            Всего наименований {2 + (request.tariffAddons?.length || 0)}, на сумму {formatMoney(request.price)}руб.
          </Text>
        </View>
        <View
          style={{
            fontWeight: '900',
            fontSize: 10,
            // fontFamily: 'Bold',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 5,
            marginVertical: 3,
          }}
        >
          <Text>
            {numberInWords(request.price, true)}
          </Text>
        </View>

        <View>
          <Text style={[styles.thin, {paddingHorizontal: 5}]}>
            Вышеперечисленные услуги выполнены полностью и в срок. Заказчик
            претензий по объему, качеству и срокам оказания услуг не имеет.
          </Text>
        </View>
        <View style={{display: 'flex', flexDirection: 'column'}}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 12,
              marginTop: 40,
              marginBottom: 20,
              justifyContent: 'space-between',
              paddingHorizontal: 50,
            }}>          
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text>Исполнитель: Левкина С.И. /</Text>
              <View
                style={{
                  width: '100px',
                  borderStyle: 'solid',
                  borderBottomWidth: 1,
                  marginLeft: 10,
                }}
              />
            </View>

            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text>Заказчик:</Text>
              <View
                style={{
                  width: '100px',
                  borderStyle: 'solid',
                  borderBottomWidth: 1,
                  marginLeft: 10,
                }}
              />
            </View>          
          </View>
          <View style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center'}}>          
            <View style={{display: 'flex', flexDirection: 'row', width: '180px', justifyContent: 'space-between'}}>
              <Image style={{ width: '90px' }} source={'/shared/stamp.png'}/>
              <Image style={{ width: '90px' }} source={'/shared/sign.png'}/>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
