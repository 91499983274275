import { omit } from 'lodash';

import { updateProfile } from '../../api';
import { getPassport } from './selectors';
import { getProfileId } from '../profile';

export const UPDATE_VALUE = '@passport/UPDATE_VALUE';
export const TOGGLE_OPEN = '@passport/TOGGLE_OPEN';
export const UPDATE_PASSPORT = '@passport/UPDATE_PASSPORT';
export const SET_LOADING = '@passport/SET_LOADING';

export const toggleOpen = () => ({
  type: TOGGLE_OPEN,
});

export const updateValue = (payload) => ({
  type: UPDATE_VALUE,
  payload,
});

export const updatePassport = (payload) => ({
  type: UPDATE_PASSPORT,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const savePassportData = async (dispatch, getState) => {
  dispatch(setLoading(true));
  try {
    const state = getState();
    const value = omit(getPassport(state), [
      'open',
      'errors',
      'loading',
    ]);

    const { profile } = await updateProfile(
      { key: 'passport', value },
      getProfileId(state)
    );

    const newPassport = profile.passport;
    if (
      !profile.passport.errors ||
      Object.keys(profile.passport.errors).length === 0
    ) {
      newPassport.open = false;
    }

    dispatch(updatePassport(newPassport));
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoading(false));
};
