import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import {
  getLoading,
  getTransactions,
  getCount,
  applyFilter,
  loadTransactions,
} from '../../store/transactions';
import { getAccountTransactionsFilters } from '../../store/filters';
import { getProfileId, getRole } from '../../store/profile';
import { Table } from '../shared/Table';
import { Message } from '../shared/Message';
import { formatMoney } from '../../utils';
import { FILTERS, ROLES, TRANSACTIONS_TYPES } from '../../consts';

const {
  REFILL_CA,
  ORDER_PAYMENT_CA,
  ORDER_REFUND_CA,
  ORDER_RECEIPT_DA,
  ORDER_RECEIPT_PA,
  ORDER_RECEIPT_SA,
  ADD_PAYMENT_CADA,
  ADD_PAYMENT_CASA,
  DEBIT_DA,
  DEBIT_PA,
} = TRANSACTIONS_TYPES;

export const AccountHistory = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const transactions = useSelector(getTransactions);
  const countItems = useSelector(getCount);
  const filters = useSelector(getAccountTransactionsFilters);
  const filtersKey = FILTERS.ACCOUNT_TRANSACTIONS;
  const userId = useSelector(getProfileId);
  const role = useSelector(getRole);

  const actionsCasa = transactions.filter(
    (action) => action.type === ADD_PAYMENT_CASA
  );

  const glueClientActions = () => {
    return transactions.reduce((accum, action) => {
      return action.type === ADD_PAYMENT_CASA
        ? [...accum]
        : action.type === ADD_PAYMENT_CADA
        ? [
            ...accum,
            {
              ...action,
              sum:
                action.sum +
                actionsCasa.find(
                  (item) => item.requestNumber === action.requestNumber
                )?.sum,
            },
          ]
        : [...accum, action];
    }, []);
  };

  const TABLE_HEADERS = [
    {
      label: 'ID',
      value: '_id',
    },
    {
      label: 'Тип операции',
      value: 'action',
      renderItem: true,
      render: (item) => {
        switch (item.type) {
          case REFILL_CA:
            return 'Пополнение счета';
          case ORDER_PAYMENT_CA:
          case ORDER_RECEIPT_DA:
          case ORDER_RECEIPT_PA:
          case ORDER_RECEIPT_SA:
            return 'Оплата за заявку';
          case ORDER_REFUND_CA:
            return 'Возврат за отмену заявки';
          case ADD_PAYMENT_CADA:
          case ADD_PAYMENT_CASA:
            return 'Оплата за доп листы';
          case DEBIT_DA:
          case DEBIT_PA:
            return 'Вывод средств';
          default:
            return '';
        }
      },
    },
    {
      label: '№ Заявки',
      value: 'requestNumber',
      renderItem: true,
      render: (item) =>
        (role === ROLES.USER && item.action === 'withdrawals') ||
        (role !== ROLES.USER && item.action === 'refill') ||
        item.type === ADD_PAYMENT_CADA ? (
          item.requestNumber
        ) : (
          <span>&ndash;</span>
        ),
    },
    {
      label: 'Сумма операции',
      value: 'sum',
      renderItem: true,
      render: (item) =>
        role === ROLES.USER ? (
          item.action === 'refill' ? (
            <div>+ {formatMoney(item.sum)}</div>
          ) : (
            <div>- {formatMoney(item.sum)}</div>
          )
        ) : item.action === 'withdrawals' && item.type !== ADD_PAYMENT_CADA ? (
          <div>- {formatMoney(item.sum)}</div>
        ) : (
          <div>+ {formatMoney(item.sum)}</div>
        ),
    },
    {
      label: 'Баланс счета',
      value: 'balance',
      renderItem: true,
      render: (item) =>
        formatMoney(
          item?.type !== ADD_PAYMENT_CADA
            ? item?.action === 'refill'
              ? item.receiverBalance
              : item.agentBalance
            : role === ROLES.USER
            ? actionsCasa.find(
                (action) => item.requestNumber === action.requestNumber
              )?.agentBalance || item.agentBalance
            : item.receiverBalance
        ),
    },
    {
      label: 'Дата операции',
      value: 'createdAt',
      sortable: true,
      render: (value) =>
        value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : 'нет данных',
    },
  ];

  useEffect(() => {
    if (userId) {
      dispatch(loadTransactions(userId, filters));
    }
  }, [dispatch, userId, filters]);

  return (
    <>
      {transactions?.length ? (
        <Table
          loading={loading}
          headers={TABLE_HEADERS}
          items={role === ROLES.USER ? glueClientActions() : transactions}
          countItems={countItems}
          filters={filters}
          applyFilters={(filters) =>
            dispatch(applyFilter(userId, filtersKey, filters))
          }
        />
      ) : (
        <div style={{ marginTop: '-1rem' }}>
          <Message type="info">Операции по счету не проводились</Message>
        </div>
      )}
    </>
  );
};
