import React from 'react';

import { ROUTES } from '../consts';
import { Company as CompanyComponent } from '../components/Company';
import { withLoading } from '../components/hocs/withLoading';
import { Title } from '../components/shared/Title';
import { Tabs } from '../components/shared/Tabs';

export const Company = withLoading(() => {
  return (
    <main>
      <Title>Моя компания</Title>
      <Tabs
        tabs={[
          {
            title: 'Компании',
            route: ROUTES.FULL_COMPANY,
            href: "../"+ROUTES.COMPANY,            
            visible: true,
          },
        ]}
      />
      <CompanyComponent />
    </main>
  );
});
