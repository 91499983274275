import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Panel.module.sass';
import classnames from 'classnames';

import { SwitcherPanel } from './SwitcherPanel';
import { SeaTab } from './SeaTab';
import { TrainTab } from './TrainTab';

import { getPanelState, changePanelState } from '../../../../store/makeOffers';

export const Panel = () => {

	const dispatch = useDispatch();
	const panelState = useSelector(getPanelState);



	return(
		<div className={classnames(styles.panel_block)}>
			<SwitcherPanel
				state={panelState}
				setStateCb={(state)=>{dispatch(changePanelState(state))}}
			/>
			{(panelState === "sea") && (<SeaTab />)}
			{(panelState === "train") && (<TrainTab />)}
		</div>
	);
}