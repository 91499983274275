import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import {
  getCount,
  getLoading,
  getPool,
  applyAllRequestsDoneFilters,
  loadAllRequestsDone,
} from '../../../store/requests';
import { Message } from '../../shared/Message';
import { Table } from '../../shared/Table';
import { ROUTES } from '../../../consts';
import { formatMoney } from '../../../utils';
import { getAllRequestsDoneFilters } from '../../../store/filters';

const REQUEST_HEADERS = [
  {
    label: '№ Заявки',
    value: 'number',
    sortable: true,
    render: (value) => <span style={{ color: '#1890ff' }}>{value}</span>,
  },
  {
    label: 'Дата завершения заявки',
    value: 'updatedAt',
    sortable: true,
    render: (value) => format(new Date(value), 'dd.MM.yyyy HH:mm'),
  },
  {
    label: 'Декларант',
    value: 'declarant',
    render: (value) =>
      value ? (
        <div>
          {value?.['name']} {value?.['surname']}
        </div>
      ) : (
        <div>&mdash;</div>
      ),
  },
  {
    label: 'Компания',
    value: 'company',
    render: (value) => <div>{value?.['name']}</div>,
  },
  {
    label: 'Стоимость заявки',
    value: 'price',
    render: formatMoney,
  },
  {
    label: '',
    value: 'rowActions',
    render: () => <div style={{ color: '#1890ff' }}>Открыть</div>,
  },
];

export const AllRequestsDone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pool = useSelector(getPool);
  const loading = useSelector(getLoading);
  const filters = useSelector(getAllRequestsDoneFilters);
  const countItems = useSelector(getCount);

  useEffect(() => {
    dispatch(loadAllRequestsDone);
  }, [dispatch]);

  return (
    <div>
      {pool.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`${ROUTES.FULL_REQUESTS_DECLARATIONS_ALL_REQUESTS}/${_id}/`);
          }}
          loading={loading}
          items={pool}
          headers={REQUEST_HEADERS}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) =>
            dispatch(applyAllRequestsDoneFilters(filters))
          }
        />
      ) : (
        <Message type="info">
          В системе нет сведений о выполненных заявках
        </Message>
      )}
    </div>
  );
};
