import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

export const Select = ({ creatable, ...restProps }) => {
  const C = creatable ? CreatableSelect : ReactSelect;

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #D9D9D9',
      minHeight: '2rem',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '2rem',
    }),
    ...(creatable && {
      option: (provided) => ({
        ...provided,
        fontSize: '0.8rem',
      }),
    }),
    placeholder: (provided) => ({ ...provided, fontSize: '0.8rem' }),
    singleValue: (provided) => ({ ...provided, fontSize: '0.8rem' }),
  };

  const props = {
    ...restProps,
    ...(creatable && { formatCreateLabel: (value) => `Создать: ${value}` }),
  };

  return <C styles={selectStyles} {...props} />;
};
