export const CUSTOM_QUERIES = {
  1010: {
    value: '1010',
    label:
      'Таможенные сборы за таможенные операции (за таможенное оформление товаров, за таможенное декларирование товаров, за таможенные операции)',
  },
  // 1020: {
  //   value: '1020',
  //   label: 'Таможенные сборы за таможенное сопровождение',
  // },
  2010: {
    value: '2010',
    label:
      'Ввозная таможенная пошлина (иные пошлины, налоги и сборы, имеющие эквивалентное действие), обязанность по уплате которой возникла с 1 сентября 2010 г.',
  },
  // 2020: {
  //   value: '2020',
  //   label:
  //     'Ввозная таможенная пошлина (иные пошлины, налоги и сборы, имеющие эквивалентное действие), обязанность по уплате которой возникла до 1 сентября 2010 г.',
  // },
  // 2040: {
  //   value: '2040',
  //   label:
  //     'Специальная пошлина, установленная в соответствии с приложением N 8 к Договору о Евразийском экономическом союзе от 29 мая 2014 г.',
  // },
  // 2060: {
  //   value: '2060',
  //   label:
  //     'Компенсационная пошлина, установленная в соответствии с приложением N 8 к Договору о Евразийском экономическом союзе от 29 мая 2014 г.',
  // },
  // 2110: {
  //   value: '2110',
  //   label: `Триггерная защитная мера, применяемая в соответствии с Соглашением о
  //     свободной торговле между Евразийским экономическим союзом и его
  //     государствами-членами, с одной стороны, и Социалистической Республикой
  //     Вьетнам, с другой стороны, от 29 мая 2015 года`,
  // },
  // 2140: {
  //   value: '2140',
  //   label: `Предварительная специальная пошлина`,
  // },
  // 2150: {
  //   value: '2150',
  //   label: `Предварительная антидемпинговая пошлина`,
  // },
  // 2160: {
  //   value: '2160',
  //   label: `Предварительная компенсационная пошлина`,
  // },
  // 2240: {
  //   value: '2240',
  //   label: `Специальная пошлина, уплачиваемая в порядке, установленном для взимания соответствующих предварительных видов пошлин`,
  // },
  // 2250: {
  //   value: '2250',
  //   label: `Антидемпинговая пошлина, уплачиваемая в порядке, установленном для взимания соответствующих предварительных видов пошлин`,
  // },
  // 2260: {
  //   value: '2260',
  //   label: `Компенсационная пошлина, уплачиваемая в порядке, установленном для взимания соответствующих предварительных видов пошлин`,
  // },
  5010: {
    value: '5010',
    label: `Налог на добавленную стоимость 20% (Российская Федерация)`,
  },
  // 6010: {
  //   value: '6010',
  //   label: `Таможенные пошлины, налоги по единым ставкам в отношении товаров для личного пользования`,
  // },
  // 6020: {
  //   value: '6020',
  //   label: `Совокупный таможенный платеж в отношении товаров для личного пользования`,
  // },
  // ХХХ1: {
  //   value: 'ХХХ1',
  //   label: `Пени, значения первых трех позиций кода соответствуют числовым значениям первых трех позиций кода вида платежа, на который начисляются пени ПРОЦЕНТЫ`,
  // },
  // ХХХ2: {
  //   value: 'ХХХ2',
  //   label: `Проценты за отсрочку уплаты платежа, значения первых трех позиций кода процентов соответствуют числовым значениям первых трех позиций кода вида платежа, на который начисляются проценты`,
  // },
  // ХХХ3: {
  //   value: 'ХХХ3',
  //   label: `Проценты за рассрочку уплаты платежа, значения первых трех позиций кода процентов соответствуют числовым значениям первых трех позиций кода вида платежа, на который начисляются проценты Коды для процентов сделаны по сноске ****`,
  // },
  // 2012: {
  //   value: '2012',
  //   label: `Проценты за ОТСРОЧКУ уплаты ВВозной таможенной пошлины`,
  // },
  // 2013: {
  //   value: '2013',
  //   label: `Проценты за РАССРОЧКУ уплаты ВВозной таможенной пошлины`,
  // },
  // 2022: {
  //   value: '2022',
  //   label: `Проценты за ОТСРОЧКУ уплаты ввозной таможенной пошлины, обязанность по уплате которой возникла до 1 сентября 2010 г.`,
  // },
  // 2023: {
  //   value: '2023',
  //   label: `Проценты за РАССРОЧКУ уплаты ввозной таможенной пошлины, обязанность по уплате которой возникла до 1 сентября 2010 г.`,
  // },
  // 2032: {
  //   value: '2032',
  //   label: `Проценты за предоставление отсрочки уплаты ввозной таможенной пошлины, взимаемой при реимпорте`,
  // },
  // 2042: {
  //   value: '2042',
  //   label: `Проценты за предоставление ОТСРОЧКИ уплаты специальной пошлины, установленной в соответствии с приложением N 8 к Договоруо Евразийском экономическом союзе от 29 мая 2014 г.`,
  // },
  // 2043: {
  //   value: '2043',
  //   label: `Проценты за предоставление рассрочки уплаты специальной пошлины, установленной в соответствии с приложением N 8 к Договору о Евразийском экономическом союзе от 29 мая 2014 г.`,
  // },
  // 2052: {
  //   value: '2052',
  //   label: `Проценты за предоставление отсрочки уплаты антидемпинговой пошлины, установленной в соответствии с приложением N 8 к Договору о Евразийском экономическом союзе от 29 мая 2014 г.`,
  // },
  // 2053: {
  //   value: '2053',
  //   label: `Проценты за предоставление рассрочки уплаты антидемпинговой пошлины, установленной в соответствии с приложением N 8 к Договору о Евразийском экономическом союзе от 29 мая 2014 г.`,
  // },
  // 2062: {
  //   value: '2062',
  //   label: `Проценты за предоставление отсрочки уплаты компенсационной пошлины, установленной в соответствии с приложением N 8 к Договору о Евразийском экономическом союзе от 29 мая 2014 г.  2. ВИДЫ ПЛАТЕЖЕЙ, УПЛАТА КОТОРЫХ ПРЕДУСМОТРЕНА ЗАКОНОДАТЕЛЬСТВОМ РОССИЙСКОЙ ФЕДЕРАЦИИ  2.1. Таможенные сборы `,
  // },
  // 1030: { value: '1030', label: `Таможенные сборы за хранение` },
  // '17Х0': {
  //   value: '17Х0',
  //   label: `Прочие таможенные сборы   2.3. Вывозные таможенные пошлины  `,
  // },
  // 3010: {
  //   value: '3010',
  //   label: `Вывозная таможенная пошлина на нефть сырую, вывозимую за пределы таможенной территории Евразийского экономического союза`,
  // },
  // 3020: {
  //   value: '3020',
  //   label: `Вывозная таможенная пошлина на нефть сырую, за исключением нефти, вывозимой за пределы таможенной территории Евразийского экономического союза`,
  // },
  // 3030: {
  //   value: '3030',
  //   label: `Вывозная таможенная пошлина на газ природный`,
  // },
  // 3040: {
  //   value: '3040',
  //   label: `Вывозная таможенная пошлина на товары, выработанные из нефти`,
  // },
  // 3050: {
  //   value: '3050',
  //   label: `Вывозная таможенная пошлина на товары, в отношении которыхне установлен отдельный код вида вывозной таможенной пошлины`,
  // },
  // 3060: {
  //   value: '3060',
  //   label: `Вывозная таможенная пошлина на нефть сырую, вывозимую с территории Российской Федерации через территорию Республики Беларусь трубопроводным транспортом в рамках отдельных соглашений`,
  // },
  // '37Х0': {
  //   value: '37Х0',
  //   label: `Прочие вывозные таможенные пошлины  2.4. Акцизы `,
  // },
  // 4010: {
  //   value: '4010',
  //   label: `Акциз на этиловый спирт из пищевого сырья (за исключением дистиллятов винного, виноградного, плодового, коньячного, кальвадосного,вискового)`,
  // },
  // 4020: { value: '4020', label: `Акциз на спиртосодержащую продукцию` },
  // 4030: {
  //   value: '4030',
  //   label: `Акциз на табак трубочный, курительный, жевательный, сосательный, нюхательный, кальянный (за исключением табака, используемого в качестве сырья для производства табачной продукции)`,
  // },
  // 4230: { value: '4230', label: `Акциз на сигары` },
  // 4240: { value: '4240', label: `Акциз на сигариллы (сигариты), биди, кретек` },
  // 4250: { value: '4250', label: `Акциз на сигареты, папиросы` },
  // 4260: { value: '4260', label: `Акциз на средние дистилляты` },
  // 4270: {
  //   value: '4270',
  //   label: `Акциз на электронные системы доставки никотина`,
  // },
  // 4280: {
  //   value: '4280',
  //   label: `Акциз на жидкости для электронных систем доставки никотина`,
  // },
  // 4290: {
  //   value: '4290',
  //   label: `Акциз на табак (табачные изделия), предназначенный для потребления путем нагревания`,
  // },
  // 4040: { value: '4040', label: `Акциз на бензин автомобильный` },
  // 4050: { value: '4050', label: `Акциз на прямогонный бензин` },
  // 4060: {
  //   value: '4060',
  //   label: `Акциз на автомобили легковые с мощностью двигателя до 67,5 кВт(90 л.с.) включительно`,
  // },
  // 4180: {
  //   value: '4180',
  //   label: `Акциз на автомобили легковые с мощностью двигателя свыше 67,5 кВт (90 л.с.) и до 112,5 кВт (150 л.с.) включительно`,
  // },
  // 4190: {
  //   value: '4190',
  //   label: `Акциз на мотоциклы с мощностью двигателя свыше 112,5 кВт (150 л. с.)`,
  // },
  // 4300: {
  //   value: '4300',
  //   label: `Акциз на автомобили легковые с мощностью двигателя свыше 112,5 кВт (150 л. с.) и до 147 кВт (200 л. с.) включительно`,
  // },
  // 4310: {
  //   value: '4310',
  //   label: `Акциз на автомобили легковые с мощностью двигателя свыше 147 кВт (200 л. с.) и до 220 кВт (300 л. с.) включительно`,
  // },
  // 4320: {
  //   value: '4320',
  //   label: `Акциз на автомобили легковые с мощностью двигателя свыше 220 кВт (300 л. с.) и до 294 кВт (400 л. с.) включительно`,
  // },
  // 4330: {
  //   value: '4330',
  //   label: `Акциз на автомобили легковые с мощностью двигателя свыше 294 кВт (400 л. с.) и до 367 кВт (500 л. с.) включительно`,
  // },
  // 4340: {
  //   value: '4340',
  //   label: `Акциз на автомобили легковые с мощностью двигателя свыше 367 кВт (500 л. с.)`,
  // },
  // 4070: { value: '4070', label: `Акциз на дизельное топливо` },
  // 4080: {
  //   value: '4080',
  //   label: `Акциз на моторные масла для дизельных и (или) карбюраторных(инжекторных) двигателей`,
  // },
  // 4090: {
  //   value: '4090',
  //   label: `Акциз на вина, фруктовые вина (за исключением игристых вин(шампанских)), винные напитки, изготавливаемые без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята`,
  // },
  // 4200: { value: '4200', label: `Акциз на игристые вина (шампанские)` },
  // 4100: {
  //   value: '4100',
  //   label: `Акциз на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта до 0,5% включительно`,
  // },
  // 4210: {
  //   value: '4210',
  //   label: `Акциз на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта свыше 0,5% и до 8,6% включительно, напитки, изготавливаемые на основе пива`,
  // },
  // 4220: {
  //   value: '4220',
  //   label: `Акциз на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта свыше 8,6%`,
  // },
  // 4120: {
  //   value: '4120',
  //   label: `Акциз на алкогольную продукцию с объемной долей этилового спирта свыше 9% (за исключением пива, вин, фруктовых вин, игристых вин (шампанских), винных напитков, изготавливаемых без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла,и (или) винного дистиллята, и (или) фруктового дистиллята)`,
  // },
  // 4130: {
  //   value: '4130',
  //   label: `Акциз на алкогольную продукцию с объемной долей этилового спирта до 9% включительно (за исключением пива, напитков, изготавливаемых на основе пива, вин, фруктовых вин, игристых вин (шампанских), сидра, пуаре, медовухи, винных напитков, изготавливаемых без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята)`,
  // },
  // 4140: {
  //   value: '4140',
  //   label: `Акциз на дистилляты винный, виноградный, плодовый, коньячный,кальвадосный, висковый`,
  // },
  // 4150: { value: '4150', label: `Акциз на этиловый спирт из непищевого сырья` },
  // 4160: { value: '4160', label: `Акциз на сидр, пуаре, медовуху` },
  // 4350: {
  //   value: '4350',
  //   label: `Акциз на виноматериалы, виноградное сусло, фруктовое сусло, ввозимые на территорию Российской Федерации`,
  // },
  // 4360: {
  //   value: '4360',
  //   label: `Акциз на устройства для нагревания табака, ввозимые на территорию Российской Федерации`,
  // },
  // 4370: {
  //   value: '4370',
  //   label: `Акциз на вина, фруктовые вина (за исключением игристых вин(шампанских), ликерных вин), ввозимые на территорию РоссийскойФедерации`,
  // },
  // 4380: {
  //   value: '4380',
  //   label: `Акциз на игристые вина (шампанские), винные напитки, изготавливаемые без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и дистиллятов, ввозимые на территорию Российской Федерации  `,
  // },
  // 3012: {
  //   value: '3012',
  //   label: `Проценты за предоставление отсрочки уплаты вывозной таможенной пошлины на нефть сырую, вывозимую за пределы таможенной территории Евразийского экономического союза`,
  // },
  // 3022: {
  //   value: '3022',
  //   label: `Проценты за предоставление отсрочки уплаты вывозной таможенной пошлины на нефть сырую, за исключением нефти, вывозимой за пределы таможенной территории Евразийского экономического союза`,
  // },
  // 3032: {
  //   value: '3032',
  //   label: `Проценты за предоставление отсрочки уплаты вывозной таможенной пошлины на газ природный`,
  // },
  // 3042: {
  //   value: '3042',
  //   label: `Проценты за предоставление отсрочки уплаты вывозной таможенной пошлины на товары, выработанные из нефти`,
  // },
  // 3052: {
  //   value: '3052',
  //   label: `Проценты за предоставление отсрочки уплаты вывозной таможенной пошлины на товары, в отношении которых не установлен отдельный код вида вывозной таможенной пошлины`,
  // },
  // 3062: {
  //   value: '3062',
  //   label: `Проценты за предоставление отсрочки уплаты вывозной таможенной пошлины на нефть сырую, вывозимую с территории Российской Федерации в Республику Беларусь, в рамках отдельных соглашений. Указанный код вида платежа применяется с 31.10.2017 г.`,
  // },
  // 4012: {
  //   value: '4012',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на этиловый спирт из пищевого сырья (за исключением дистилятов винного, виноградного, плодового, коньячного, кальвадосного, вискового)`,
  // },
  // 4022: {
  //   value: '4022',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на спиртосодержащую продукцию`,
  // },
  // 4032: {
  //   value: '4032',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на табак трубочный, курительный, жевательный, сосательный, нюхательный, кальянный (за исключением табака, используемого в качестве сырья для производства табачной продукции)`,
  // },
  // 4042: {
  //   value: '4042',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на бензинавтомобильный`,
  // },
  // 4052: {
  //   value: '4052',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на прямогонный бензин`,
  // },
  // 4062: {
  //   value: '4062',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на автомобили легковые с мощностью двигателя до 67,5 кВт (90 л.с.) включительно`,
  // },
  // 4072: {
  //   value: '4072',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на дизельное топливо`,
  // },
  // 4082: {
  //   value: '4082',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на моторные масла для дизельных и (или) карбюраторных (инжекторных) двигателей`,
  // },
  // 4092: {
  //   value: '4092',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на вина, фруктовые вина (за исключением игристых вин (шампанских)), винные напитки, изготавливаемые без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята`,
  // },
  // 4102: {
  //   value: '4102',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта до 0,5% включительно`,
  // },
  // 4122: {
  //   value: '4122',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на алкогольную продукцию с объемной долей этилового спирта свыше 9% (за исключением пива, вин, фруктовых вин, игристых вин (шампанских), винных напитков, изготавливаемых без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята)`,
  // },
  // 4132: {
  //   value: '4132',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на алкогольную продукцию с объемной долей этилового спирта до 9% включительно (за исключением пива, напитков, изготавливаемых на основе пива, вин, фруктовых вин, игристых вин (шампанских), сидра, пуаре, медовухи, винных напитков, изготавливаемых без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята)`,
  // },
  // 4142: {
  //   value: '4142',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на дистилляты винный, виноградный, плодовый, коньячный, кальвадосный, висковый`,
  // },
  // 4152: {
  //   value: '4152',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на этиловый спирт из непищевого сырья`,
  // },
  // 4162: {
  //   value: '4162',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на сидр, пуаре, медовуху`,
  // },
  // 4182: {
  //   value: '4182',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 67,5 кВт (90 л.с.) и до 112,5 кВт (150 л.с.) включительно`,
  // },
  // 4192: {
  //   value: '4192',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на мотоциклыс мощностью двигателя свыше 112,5 кВт (150 л.с.)`,
  // },
  // 4202: {
  //   value: '4202',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на игристые вина (шампанские)`,
  // },
  // 4212: {
  //   value: '4212',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта свыше 0,5% и до 8,6% включительно, напитки, изготавливаемые на основе пива`,
  // },
  // 4222: {
  //   value: '4222',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта свыше 8,6%`,
  // },
  // 4232: {
  //   value: '4232',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на сигары`,
  // },
  // 4242: {
  //   value: '4242',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на сигариллы(сигариты), биди, кретек`,
  // },
  // 4252: {
  //   value: '4252',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на сигареты,папиросы`,
  // },
  // 4262: {
  //   value: '4262',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на средниедистилляты`,
  // },
  // 4272: {
  //   value: '4272',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на электронные системы доставки никотина`,
  // },
  // 4282: {
  //   value: '4282',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на жидкости для электронных систем доставки никотина`,
  // },
  // 4292: {
  //   value: '4292',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на табак (табачные изделия), предназначенный для потребления путем нагревания`,
  // },
  // 4302: {
  //   value: '4302',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 112,5 кВт (150 л.с.) и до 147кВт (200 л.с) включительно`,
  // },
  // 4312: {
  //   value: '4312',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 147кВт (200 л.с) и до 220 кВт (300 л.с.) включительно`,
  // },
  // 4322: {
  //   value: '4322',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 220 кВт (300 л.с.) и до 294 кВт (400 л.с.) включительно`,
  // },
  // 4332: {
  //   value: '4332',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 294 кВт (400 л.с.) и до 367 кВт (500 л.с.) включительно`,
  // },
  // 4342: {
  //   value: '4342',
  //   label: `Проценты за предоставление отсрочки уплаты акциза на автомобилилегковые с мощностью двигателя свыше 367 кВт (500 л.с.)`,
  // },
  // 4352: {
  //   value: '4352',
  //   label: `Проценты за отсрочку уплаты акциза на виноматериалы, виноградное сусло, фруктовое сусло, ввозимые на территорию Российской Федерации`,
  // },
  // 4362: {
  //   value: '4362',
  //   label: `Проценты за отсрочку уплаты акциза на устройства для нагреваниятабака, ввозимые на территорию Российской Федерации`,
  // },
  // 4372: {
  //   value: '4372',
  //   label: `Проценты за отсрочку уплаты акциза на вина, фруктовые вина (за исключением игристых вин (шампанских), ликерных вин), ввозимые на территорию Российской Федерации`,
  // },
  // 4382: {
  //   value: '4382',
  //   label: `Проценты за отсрочку уплаты акциза на игристые вина (шампанские), винные напитки, изготавливаемые без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и дистиллятов, ввозимые на территорию Российской Федерации`,
  // },
  // 5012: {
  //   value: '5012',
  //   label: `Проценты за предоставление отсрочки налога на добавленную стоимость Проценты за предоставление рассрочки таможенного платежа   Поз.2033, 2043, 2053, 2063 Исключены - реш.237 В пр.917 поз.2033, 2043, 2053, 2063 - есть. Поставила дату приказа.`,
  // },
  // 2073: {
  //   value: '2073',
  //   label: `Проценты за предоставление рассрочки уплаты специальной пошлины, установленной в соответствии с законодательством Российской Федерации`,
  // },
  // 2083: {
  //   value: '2083',
  //   label: `Проценты за предоставление рассрочки уплаты антидемпинговой пошлины, установленной в соответствии с законодательством Российской Федерации`,
  // },
  // 2093: {
  //   value: '2093',
  //   label: `Проценты за предоставление рассрочки уплаты компенсационной пошлины, установленной в соответствии с законодательством Российской Федерации`,
  // },
  // 3013: {
  //   value: '3013',
  //   label: `Проценты за предоставление рассрочки уплаты вывозной таможенной пошлины на нефть сырую, вывозимую за пределы таможенной территории Евразийского экономического союза`,
  // },
  // 3023: {
  //   value: '3023',
  //   label: `Проценты за предоставление рассрочки уплаты вывозной таможенной пошлины на нефть сырую, за исключением нефти, вывозимой за пределы таможенной территории Евразийского экономического союза`,
  // },
  // 3033: {
  //   value: '3033',
  //   label: `Проценты за предоставление рассрочки уплаты вывозной таможеннойпошлины на газ природный`,
  // },
  // 3043: {
  //   value: '3043',
  //   label: `Проценты за предоставление рассрочки уплаты вывозной таможеннойпошлины на товары, выработанные из нефти`,
  // },
  // 3053: {
  //   value: '3053',
  //   label: `Проценты за предоставление рассрочки уплаты вывозной таможенной пошлины на товары, в отношении которых не установлен отдельный код вида вывозной таможенной пошлины`,
  // },
  // 4013: {
  //   value: '4013',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на этиловый спирт из пищевого сырья (за исключением дистиллятов винного, виноградного, плодового, коньячного, кальвадосного, вискового)`,
  // },
  // 4023: {
  //   value: '4023',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на спиртосодержащую продукцию`,
  // },
  // 4033: {
  //   value: '4033',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на табак трубочный, курительный, жевательный, сосательный, нюхательный, кальянный (за исключением табака, используемого в качестве сырья для производства табачной продукции)`,
  // },
  // 4043: {
  //   value: '4043',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на бензинавтомобильный`,
  // },
  // 4053: {
  //   value: '4053',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на прямогонный бензин`,
  // },
  // 4063: {
  //   value: '4063',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на автомобили легковые с мощностью двигателя до 67,5 кВт (90 л.с.) включительно`,
  // },
  // 4073: {
  //   value: '4073',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на дизельноетопливо`,
  // },
  // 4083: {
  //   value: '4083',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на моторные масла для дизельных и (или) карбюраторных (инжекторных) двигателей`,
  // },
  // 4093: {
  //   value: '4093',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на вина, фруктовые вина (за исключением игристых вин (шампанских)), винные напитки, изготавливаемые без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята`,
  // },
  // 4103: {
  //   value: '4103',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на пивос нормативным (стандартизированным) содержанием объемной долиэтилового спирта до 0,5% включительно`,
  // },
  // 4123: {
  //   value: '4123',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на алкогольную продукцию с объемной долей этилового спирта свыше 9% (за исключением пива, вин, фруктовых вин, игристых вин (шампанских), винных напитков, изготавливаемых без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята)`,
  // },
  // 4133: {
  //   value: '4133',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на алкогольную продукцию с объемной долей этилового спирта до 9% включительно (за исключением пива, напитков, изготавливаемых на основе пива, вин, фруктовых вин, игристых вин (шампанских), сидра, пуаре, медовухи, винных напитков, изготавливаемых без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята)`,
  // },
  // 4143: {
  //   value: '4143',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на дистилляты винный, виноградный, плодовый, коньячный, кальвадосный, висковый`,
  // },
  // 4153: {
  //   value: '4153',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на этиловый спирт из непищевого сырья`,
  // },
  // 4163: {
  //   value: '4163',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на сидр, пуаре, медовуху`,
  // },
  // 4183: {
  //   value: '4183',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 67,5 кВт (90 л.с.)и до 112,5 кВт (150 л.с.) включительно`,
  // },
  // 4193: {
  //   value: '4193',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на мотоциклыс мощностью двигателя свыше 112,5 кВт (150 л.с.)`,
  // },
  // 4203: {
  //   value: '4203',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на игристые вина (шампанские)`,
  // },
  // 4213: {
  //   value: '4213',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на пивос нормативным (стандартизированным) содержанием объемной долиэтилового спирта свыше 0,5% и до 8,6% включительно, напитки,изготавливаемые на основе пива`,
  // },
  // 4223: {
  //   value: '4223',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на пивос нормативным (стандартизированным) содержанием объемной долиэтилового спирта свыше 8,6%`,
  // },
  // 4233: {
  //   value: '4233',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на сигары`,
  // },
  // 4243: {
  //   value: '4243',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на сигариллы(сигариты), биди, кретек`,
  // },
  // 4253: {
  //   value: '4253',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на сигареты,папиросы`,
  // },
  // 4263: {
  //   value: '4263',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на средниедистилляты`,
  // },
  // 4273: {
  //   value: '4273',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на электронные системы доставки никотина`,
  // },
  // 4283: {
  //   value: '4283',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на жидкости для электронных систем доставки никотина`,
  // },
  // 4293: {
  //   value: '4293',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на табак (табачные изделия), предназначенный для потребления путем нагревания`,
  // },
  // 4303: {
  //   value: '4303',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 112,5 кВт (150 л.с.) и до 147кВт (200 л.с) включительно`,
  // },
  // 4313: {
  //   value: '4313',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 147кВт (200 л.с) и до 220 кВт (300 л.с.) включительно`,
  // },
  // 4323: {
  //   value: '4323',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 220 кВт (300 л.с.) и до 294 кВт (400 л.с.) включительно`,
  // },
  // 4333: {
  //   value: '4333',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 294 кВт (400 л.с.) и до 367 кВт (500 л.с.) включительно`,
  // },
  // 4343: {
  //   value: '4343',
  //   label: `Проценты за предоставление рассрочки уплаты акциза на автомобили легковые с мощностью двигателя свыше 367 кВт (500 л.с.)`,
  // },
  // 4353: {
  //   value: '4353',
  //   label: `Проценты за рассрочку уплаты акциза на виноматериалы, виноградное сусло, фруктовое сусло, ввозимые на территорию Российской Федерации`,
  // },
  // 4363: {
  //   value: '4363',
  //   label: `Проценты за рассрочку уплаты акциза на устройства для нагревания табака, ввозимые на территорию Российской Федерации`,
  // },
  // 4373: {
  //   value: '4373',
  //   label: `Проценты за рассрочку уплаты акциза на вина, фруктовые вина (за исключением игристых вин (шампанских), ликерных вин), ввозимые на территорию Российской Федерации`,
  // },
  // 4383: {
  //   value: '4383',
  //   label: `Проценты за рассрочку уплаты акциза на игристые вина (шампанские), винные напитки, изготавливаемые без добавления ректификованногоэтилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и дистиллятов, ввозимые на территорию Российской Федерации`,
  // },
  // 5013: {
  //   value: '5013',
  //   label: `Проценты за предоставление рассрочки налога на добавленную стоимость  2.6. Средства и штрафы`,
  // },
  // 7015: {
  //   value: '7015',
  //   label: `Средства от реализации конфискатов в части реализации материальных запасов по указанному имуществу`,
  // },
  // 7010: {
  //   value: '7010',
  //   label: `Средства от реализации товаров, задержанных таможенными органами, в размере исчисленных на день задержания этих товаров ввозных таможенных пошлин, налогов, которые подлежали бы уплате при помещении задержанных товаров под таможенную процедуру выпуска для внутреннего потребления`,
  // },
  // 7024: {
  //   value: '7024',
  //   label: `Штрафы, за исключением штрафов за нарушение валютного законодательства Российской Федерации и актов органов валютного регулирования,назначаемых таможенными органами, и штрафов за нарушение законодательства Российской Федерации об основах конституционного строя Российской Федерации, о государственной власти Российской Федерации, о государственной службе Российской Федерации, о выборах и референдумах Российской Федерации, об Уполномоченном по правамчеловека в Российской Федерации`,
  // },
  // 7025: {
  //   value: '7025',
  //   label: `Штрафы за нарушение валютного законодательства Российской Федерации и актов органов валютного регулирования,назначаемые таможенными органами`,
  // },
  // 7026: {
  //   value: '7026',
  //   label: `Штрафы за нарушение законодательства Российской Федерации об основах конституционного строя Российской Федерации, о государственной власти Российской Федерации, о государственной службе Российской Федерации, о выборах и референдумах Российской Федерации, об Уполномоченном по правам человека в Российской Федерации`,
  // },
  // 7027: {
  //   value: '7027',
  //   label: `Денежные взыскания (штрафы) за нарушение законодательства Российской Федерации о противодействии легализации (отмыванию) доходов,полученных преступным путем, и финансированию терроризма,об обороте наркотических и психотропных средств`,
  // },
  // 7028: {
  //   value: '7028',
  //   label: `Денежные взыскания (штрафы) за нарушение законодательства Российской Федерации о государственном контроле за осуществлением международных автомобильных перевозок`,
  // },
  // 7029: {
  //   value: '7029',
  //   label: `Денежные взыскания (штрафы) за нарушение лесного законодательства Российской Федерации на лесных участках, находящихся в федеральной собственности`,
  // },
  // 7031: {
  //   value: '7031',
  //   label: `Денежные взыскания (штрафы) за административные правонарушения, посягающие на здоровье, предусмотренные Кодексом Российской Федерации об административных правонарушениях`,
  // },
  // 7030: {
  //   value: '7030',
  //   label: `Средства, полученные в результате применения мер гражданско-правовой и административной ответственности`,
  // },
  // 7040: {
  //   value: '7040',
  //   label: `Средства, полученные в результате применения мер уголовнойответственности по делам, возбужденным таможенными органами`,
  // },
  // 7050: {
  //   value: '7050',
  //   label: `Административные штрафы, установленные Главой 12 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения в области дорожного движения (штрафы за нарушение правил перевозки опасных грузов)`,
  // },
  // 7060: {
  //   value: '7060',
  //   label: `Административные штрафы, установленные Главой 6 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения, посягающие на здоровье, санитарно-эпидемиологическое благополучие населения и общественную нравственность`,
  // },
  // 7070: {
  //   value: '7070',
  //   label: `Административные штрафы, установленные Главой 8 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения в области охраны окружающей среды и природопользования (штрафы за нарушение требований лесного законодательства об учете древесины и сделок с ней)`,
  // },
  // 7080: {
  //   value: '7080',
  //   label: `Административные штрафы, установленные Главой 11 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения на транспорте (штрафы за осуществление международных автомобильных перевозок без разрешений либо с незаполненными разрешениями, разрешениями, заполненными с нарушением установленных правил, или разрешениями, не соответствующими виду перевозки, либо без учетного талона, либо без отметки в уведомлении должностного лица федерального органа исполнительной власти, осуществляющего функции по контролю и надзору в сфере транспорта, об устранении нарушения)`,
  // },
  // 7090: {
  //   value: '7090',
  //   label: `Административные штрафы, установленные Главой 11 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения на транспорте (иные штрафы)`,
  // },
  // 7100: {
  //   value: '7100',
  //   label: `Административные штрафы, установленные Главой 14 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения в области предпринимательской деятельности идеятельности саморегулируемых организаций`,
  // },
  // 7110: {
  //   value: '7110',
  //   label: `Административные штрафы, установленные Главой 15 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения в области финансов, налогов и сборов, страхования, рынка ценных бумаг (за исключением штрафов, указанных в пункте 6 статьи 46 Бюджетного кодекса Российской Федерации) (штрафы за непредставление (несообщение) сведений, необходимых для осуществления налогового контроля)`,
  // },
  // 7120: {
  //   value: '7120',
  //   label: `Административные штрафы, установленные Главой 15 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения в области финансов, налогов и сборов, страхования, рынка ценных бумаг (за исключением штрафов, указанных в пункте 6 статьи 46 Бюджетного кодекса Российской Федерации) (иные штрафы)`,
  // },
  // 7130: {
  //   value: '7130',
  //   label: `Административные штрафы, установленные Главой 16 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения в области таможенного дела(нарушение таможенных правил)`,
  // },
  // 7140: {
  //   value: '7140',
  //   label: `Административные штрафы, установленные Главой 17 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения, посягающие на институты государственной власти (штрафы за невыполнение законных требований прокурора, следователя, дознавателя или должностного лица, осуществляющего производство по делу об административном правонарушении)`,
  // },
  // 7150: {
  //   value: '7150',
  //   label: `Административные штрафы, установленные Главой 19 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения против порядка управления (штрафы за невыполнение в срок законного предписания (постановления, представления, решения) органа (должностного лица), осуществляющего государственный надзор (контроль), организации, уполномоченной в соответствии с федеральными законами на осуществление государственного надзора (должностного лица), органа (должностного лица), осуществляющего муниципальный контроль)`,
  // },
  // 7160: {
  //   value: '7160',
  //   label: `Административные штрафы, установленные Главой 19 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения против порядка управления`,
  // },
  // 7170: {
  //   value: '7170',
  //   label: `Штрафы, установленные Главой 22 Уголовного кодекса РоссийскойФедерации, за преступления в сфере экономической деятельности`,
  // },
  // 7180: {
  //   value: '7180',
  //   label: `Денежные средства, изымаемые в собственность Российской Федерации в соответствии с решениями судов (за исключением обвинительныхприговоров судов), подлежащие зачислению в федеральный бюджет`,
  // },
  // 7190: {
  //   value: '7190',
  //   label: `Прочее возмещение ущерба, причиненного федеральному имуществу (за исключением имущества, закрепленного за федеральными бюджетными(автономными) учреждениями, унитарными предприятиями)`,
  // },
  // 7200: {
  //   value: '7200',
  //   label: `Денежные средства, обращенные в собственность государства на основании обвинительных приговоров судов, подлежащие зачислению в федеральный бюджет`,
  // },
  // 7210: {
  //   value: '7210',
  //   label: `Денежные средства, полученные от распоряжения и реализацииконфискованного и иного имущества, обращенного в собственность Российской Федерации, подлежащие зачислению в федеральный бюджет`,
  // },
  // 7220: {
  //   value: '7220',
  //   label: `Денежное возмещение в размере двукратной суммы причиненного ущерба, перечисляемое в федеральный бюджет лицом, впервые совершившимпреступление, для освобождения от уголовной ответственности`,
  // },
  // 7230: {
  //   value: '7230',
  //   label: `Судебные штрафы (денежные взыскания), налагаемые судами в случаях, предусмотренных Уголовным кодексом Российской Федерации`,
  // },
  // 7240: {
  //   value: '7240',
  //   label: `Административные штрафы, установленные Главой 12 Кодекса Российской Федерации об административных правонарушениях, за административные правонарушения в области дорожного движения (штрафы за нарушение правил движения тяжеловесного и (или) крупногабаритного транспортного средства,выявленные при осуществлении весового и габаритного контроля)`,
  // },
  // 7250: {
  //   value: '7250',
  //   label: `Доходы от уплаты (взыскания) административных штрафов по вступившим в законную силу постановлениям по делам об административныхправонарушениях до 1 января 2020 года  2.7. Иные виды платежей, взимание которых возложено на таможенные органы `,
  // },
  // 9010: {
  //   value: '9010',
  //   label: `Субсидии и иные суммы, не уплаченные либо полученные прямо или косвенно в качестве выплат, льгот либо возмещений в связи с вывозом товаров с таможенной территории`,
  // },
  // 9020: {
  //   value: '9020',
  //   label: `Иные виды платежей, взимание которых возложено на таможенные органы`,
  // },
  // 9030: {
  //   value: '9030',
  //   label: `Прочие доходы от оказания платных услуг получателями средств икомпенсации затрат`,
  // },
  // 9040: { value: '9040', label: `Прочие неналоговые доходы` },
  // 9070: {
  //   value: '9070',
  //   label: `Авансовые платежи в счет будущих таможенных и иных платежей`,
  // },
  // 9080: {
  //   value: '9080',
  //   label: `Обеспечение исполнения обязанности по уплате таможенных пошлин, налогов, специальных, антидемпинговых, компенсационных пошлин, за исключением такого обеспечения, вносимого денежным залогом`,
  // },
  // 9090: {
  //   value: '9090',
  //   label: `Денежный залог, внесенный в качестве обеспечения исполненияобязанности по уплате таможенных пошлин, налогов, специальных,антидемпинговых, компенсационных пошлин`,
  // },
  // 9910: {
  //   value: '9910',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве таможенногопредставителя`,
  // },
  // 9920: {
  //   value: '9920',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве таможенногоперевозчика`,
  // },
  // 9930: {
  //   value: '9930',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве владельца склада временного хранения`,
  // },
  // 9940: {
  //   value: '9940',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве владельца таможенного склада`,
  // },
  // 9950: {
  //   value: '9950',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве владельца магазина беспошлинной торговли`,
  // },
  // 9960: {
  //   value: '9960',
  //   label: `Обеспечение исполнения обязанностей уполномоченного экономического оператора`,
  // },
  // 9970: {
  //   value: '9970',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве владельца свободного склада`,
  // },
  // 9810: {
  //   value: '9810',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве таможенногопредставителя и таможенного перевозчика`,
  // },
  // 9820: {
  //   value: '9820',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве таможенного представителя, и обеспечение исполнения обязанностей уполномоченного экономического оператора`,
  // },
  // 9980: {
  //   value: '9980',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве таможенного перевозчика, и обеспечение исполнения обязанностей уполномоченного экономического оператора`,
  // },
  // 9990: {
  //   value: '9990',
  //   label: `Обеспечение исполнения обязанностей юридического лица, осуществляющего деятельность в сфере таможенного дела в качестве таможенного представителя и таможенного перевозчика, и обеспечение исполнения обязанностей уполномоченного экономического оператора`,
  // },
  // 9100: {
  //   value: '9100',
  //   label: `Денежный залог, внесенный в качестве обеспечения исполнения обязательств организации об использовании приобретенных акцизных марок, по которым обязательство организации не исполнено`,
  // },
  // 9110: {
  //   value: '9110',
  //   label: `Средства, уплачиваемые импортерами таможенным органам за выдачуакцизных марок`,
  // },
  // 9120: {
  //   value: '9120',
  //   label: `Утилизационный сбор, уплачиваемый за колесные транспортные средства (шасси) и прицепы к ним, ВВозимые в Российскую Федерацию, кроме колесных транспортных средств (шасси) и прицепов к ним, ввозимых с территории Республики Беларусь`,
  // },
  // 9130: {
  //   value: '9130',
  //   label: `Утилизационный сбор, уплачиваемый за колесные транспортные средства (шасси) и прицепы к ним, ВВозимые в Российскую Федерацию с территории Республики Беларусь`,
  // },
  // 9140: {
  //   value: '9140',
  //   label: `Государственная пошлина за принятие предварительных решений поклассификации товаров по ТН ВЭД ЕАЭС`,
  // },
  // 9150: {
  //   value: '9150',
  //   label: `Утилизационный сбор,уплачиваемый за самоходные машины и прицепы к ним, ВВозимые в РФ, кроме самоходных машин и прицепов к ним, ввозимых с территории Республики Беларусь`,
  // },
  // 9160: {
  //   value: '9160',
  //   label: `Утилизационный сбор,уплачиваемый за самоходные машины и прицепы к ним, ВВозимые в РФ с территории Республики Беларусь`,
  // },
  // 9170: {
  //   value: '9170',
  //   label: `Государственная пошлина за выдачу акцизных марок с двухмерным штриховым кодом, содержащим идентификатор единой государственной автоматизированной информационной системы учета объема производства и оборота этилового спирта, алкогольной и спиртосодержащей продукции для маркировки алкогольной продукции`,
  // },
  // 9180: {
  //   value: '9180',
  //   label: `Сбор за стоимость бланка паспорта транспортного средства (паспорта шасси транспортного средства), реализуемого юридическим лицам,осуществляющим ввоз транспортных средств в Российскую Федерацию`,
  // },
  // 9190: {
  //   value: '9190',
  //   label: `Прочие неналоговые доходы федерального бюджета в части невостребованных в установленные сроки остатков денежных средств, вырученных от реализации товаров, задержанных или изъятых таможенными органами Пени Значения первых трех позиций кода пеней соответствуют числовым значениям первых трех позиций кода вида платежа,на который начисляются пени`,
  // },
  // 1011: {
  //   value: '1011',
  //   label: `Пени за просрочку уплаты таможенных сборов за таможенные операции (за таможенное декларирование товаров, за таможенные операции)`,
  // },
  // 1021: {
  //   value: '1021',
  //   label: `Пени за просрочку уплаты таможенных сборов за таможенное сопровождение`,
  // },
  // 2011: {
  //   value: '2011',
  //   label: `Пени за просрочку уплаты ввозной таможенной пошлины (иных пошлин, налогов и сборов, имеющих эквивалентное действие), уплаченныев соответствии с приложением N 5 к Договору о Евразийскомэкономическом союзе от 29 мая 2014 г.`,
  // },
  // 2021: {
  //   value: '2021',
  //   label: `Пени за просрочку уплаты ввозной таможенной пошлины, обязанность по уплате которой возникла до вступления в силу Соглашения обустановлении и применении в Таможенном союзе порядка зачисленияи распределения ввозных таможенных пошлин (иных пошлин, налогови сборов, имеющих эквивалентное действие) от 20 мая 2010 г.`,
  // },
  // 2041: {
  //   value: '2041',
  //   label: `Пени за просрочку уплаты специальной пошлины, установленнойв соответствии с приложением N 8 к Договору о Евразийскомэкономическом союзе от 29 мая 2014 г.`,
  // },
  // 2051: {
  //   value: '2051',
  //   label: `Пени за просрочку уплаты антидемпинговой пошлины, установленнойв соответствии с приложением N 8 к Договору о Евразийскомэкономическом союзе от 29 мая 2014 г.`,
  // },
  // 2061: {
  //   value: '2061',
  //   label: `Пени за просрочку уплаты компенсационной пошлины, установленнойв соответствии с приложением N 8 к Договору о Евразийскомэкономическом союзе от 29 мая 2014 г.`,
  // },
  // 5011: {
  //   value: '5011',
  //   label: `Пени за просрочку уплаты налога на добавленную стоимость`,
  // },
  // 6011: {
  //   value: '6011',
  //   label: `Пени за просрочку уплаты таможенных пошлин, налогов по единым ставкам в отношении товаров для личного пользования`,
  // },
  // 6021: {
  //   value: '6021',
  //   label: `Пени за просрочку уплаты совокупного таможенного платежа в отношении товаров для личного пользования`,
  // },
  // 1031: {
  //   value: '1031',
  //   label: `Пени за просрочку уплаты таможенных сборов за хранение`,
  // },
  // 2031: {
  //   value: '2031',
  //   label: `Пени за просрочку уплаты ввозной таможенной пошлины, взимаемой при реимпорте`,
  // },
  // 2071: {
  //   value: '2071',
  //   label: `Пени за просрочку уплаты специальной пошлины, установленнойв соответствии с законодательством Российской Федерации`,
  // },
  // 2081: {
  //   value: '2081',
  //   label: `Пени за просрочку уплаты антидемпинговой пошлины, установленнойв соответствии с законодательством Российской Федерации`,
  // },
  // 2091: {
  //   value: '2091',
  //   label: `Пени за просрочку уплаты компенсационной пошлины, установленнойв соответствии с законодательством Российской Федерации`,
  // },
  // 2111: {
  //   value: '2111',
  //   label: `Пени за просрочку уплаты пошлин, взимаемых при применении триггерной защитной меры в соответствии с Соглашением о свободной торговле между Евразийским экономическим союзом и его государствами-членами, с одной стороны, и Социалистической Республикой Вьетнам, с другой стороны, от 29 мая 2015 года`,
  // },
  // 2141: {
  //   value: '2141',
  //   label: `Пени за просрочку уплаты предварительной специальной пошлины`,
  // },
  // 2151: {
  //   value: '2151',
  //   label: `Пени за просрочку уплаты предварительной антидемпинговой пошлины`,
  // },
  // 2161: {
  //   value: '2161',
  //   label: `Пени за просрочку уплаты предварительной компенсационной пошлины`,
  // },
  // 2241: {
  //   value: '2241',
  //   label: `Пени за просрочку уплаты специальной пошлины, уплачиваемой в порядке, установленном для взимания соответствующих предварительных видов пошлин`,
  // },
  // 2251: {
  //   value: '2251',
  //   label: `Пени за просрочку уплаты антидемпинговой пошлины, уплачиваемой в порядке, установленном для взимания соответствующих предварительных видов пошлин`,
  // },
  // 2261: {
  //   value: '2261',
  //   label: `Пени за просрочку уплаты компенсационной пошлины, уплачиваемой в порядке, установленном для взимания соответствующих предварительных видов пошлин`,
  // },
  // 3011: {
  //   value: '3011',
  //   label: `Пени за просрочку уплаты вывозной таможенной пошлины на нефть сырую, вывозимую за пределы таможенной территории Евразийского экономического союза`,
  // },
  // 3021: {
  //   value: '3021',
  //   label: `Пени за просрочку уплаты вывозной таможенной пошлины на нефть сырую, за исключением нефти, вывозимой за пределы таможенной территории Евразийского экономического союза`,
  // },
  // 3031: {
  //   value: '3031',
  //   label: `Пени за просрочку уплаты вывозной таможенной пошлины на газ природный`,
  // },
  // 3041: {
  //   value: '3041',
  //   label: `Пени за просрочку уплаты вывозной таможенной пошлины на товары,выработанные из нефти`,
  // },
  // 3051: {
  //   value: '3051',
  //   label: `Пени за просрочку уплаты вывозной таможенной пошлины на товары,в отношении которых не установлен отдельный код вида вывознойтаможенной пошлины`,
  // },
  // 3061: {
  //   value: '3061',
  //   label: `Пени за просрочку уплаты вывозной таможенной пошлины на нефть сырую, вывозимую с территории Российской Федерации в Республику Беларусь, в рамках отдельных соглашений. Указанный код вида платежа применяется с 31.10.2017 г.`,
  // },
  // 4011: {
  //   value: '4011',
  //   label: `Пени за просрочку уплаты акциза на этиловый спирт из пищевого сырья (за исключением дистиллятов винного, виноградного, плодового,коньячного, кальвадосного, вискового)`,
  // },
  // 4021: {
  //   value: '4021',
  //   label: `Пени за просрочку уплаты акциза на спиртосодержащую продукцию`,
  // },
  // 4031: {
  //   value: '4031',
  //   label: `Пени за просрочку уплаты акциза на табак трубочный, курительный, жевательный, сосательный, нюхательный, кальянный (за исключением табака, используемого в качестве сырья для производства табачной продукции)`,
  // },
  // 4041: {
  //   value: '4041',
  //   label: `Пени за просрочку уплаты акциза на бензин автомобильный`,
  // },
  // 4051: {
  //   value: '4051',
  //   label: `Пени за просрочку уплаты акциза на прямогонный бензин`,
  // },
  // 4061: {
  //   value: '4061',
  //   label: `Пени за просрочку уплаты акциза на автомобили легковые с мощностью двигателя до 67,5 кВт (90 л.с.) включительно`,
  // },
  // 4071: {
  //   value: '4071',
  //   label: `Пени за просрочку уплаты акциза на дизельное топливо`,
  // },
  // 4081: {
  //   value: '4081',
  //   label: `Пени за просрочку уплаты акциза на моторные масла для дизельных и (или) карбюраторных (инжекторных) двигателей`,
  // },
  // 4091: {
  //   value: '4091',
  //   label: `Пени за просрочку уплаты акциза на вина, фруктовые вина(за исключением игристых вин (шампанских)), винные напитки, изготавливаемые без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или)фруктового дистиллята`,
  // },
  // 4101: {
  //   value: '4101',
  //   label: `Пени за просрочку уплаты акциза на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта до 0,5% включительно`,
  // },
  // 4121: {
  //   value: '4121',
  //   label: `Пени за просрочку уплаты акциза на алкогольную продукцию с объемной долей этилового спирта свыше 9% (за исключением пива, вин, фруктовых вин, игристых вин (шампанских), винных напитков, изготавливаемых без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или) фруктового дистиллята)`,
  // },
  // 4131: {
  //   value: '4131',
  //   label: `Пени за просрочку уплаты акциза на алкогольную продукцию с объемной долей этилового спирта до 9% включительно (за исключением пива, напитков, изготавливаемых на основе пива, вин, фруктовых вин, игристых вин (шампанских), сидра, пуаре, медовухи, винных напитков, изготавливаемых без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и (или) винного дистиллята, и (или)фруктового дистиллята)`,
  // },
  // 4141: {
  //   value: '4141',
  //   label: `Пени за просрочку уплаты акциза на дистилляты винный, виноградный, плодовый, коньячный, кальвадосный, висковый`,
  // },
  // 4151: {
  //   value: '4151',
  //   label: `Пени за просрочку уплаты акциза на этиловый спирт из непищевого сырья`,
  // },
  // 4161: {
  //   value: '4161',
  //   label: `Пени за просрочку уплаты акциза на сидр, пуаре, медовуху`,
  // },
  // 4181: {
  //   value: '4181',
  //   label: `Пени за просрочку уплаты акциза на автомобили легковые с мощностью двигателя свыше 67,5 кВт (90 л.с.) и до 112,5 кВт (150 л.с.)включительно`,
  // },
  // 4191: {
  //   value: '4191',
  //   label: `Пени за просрочку уплаты акциза на мотоциклы с мощностью двигателя свыше 112,5 кВт (150 л.с.)`,
  // },
  // 4201: {
  //   value: '4201',
  //   label: `Пени за просрочку уплаты акциза на игристые вина (шампанские)`,
  // },
  // 4211: {
  //   value: '4211',
  //   label: `Пени за просрочку уплаты акциза на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта свыше 0,5% и до 8,6% включительно, напитки, изготавливаемые на основе пива`,
  // },
  // 4221: {
  //   value: '4221',
  //   label: `Пени за просрочку уплаты акциза на пиво с нормативным (стандартизированным) содержанием объемной доли этилового спирта свыше 8,6%`,
  // },
  // 4231: { value: '4231', label: `Пени за просрочку уплаты акциза на сигары` },
  // 4241: {
  //   value: '4241',
  //   label: `Пени за просрочку уплаты акциза на сигариллы (сигариты), биди, кретек`,
  // },
  // 4251: {
  //   value: '4251',
  //   label: `Пени за просрочку уплаты акциза на сигареты, папиросы`,
  // },
  // 4261: {
  //   value: '4261',
  //   label: `Пени за просрочку уплаты акциза на средние дистилляты`,
  // },
  // 4271: {
  //   value: '4271',
  //   label: `Пени за просрочку уплаты акциза на электронные системы доставкиникотина`,
  // },
  // 4281: {
  //   value: '4281',
  //   label: `Пени за просрочку уплаты акциза на жидкости для электронных систем доставки никотина`,
  // },
  // 4291: {
  //   value: '4291',
  //   label: `Пени за просрочку уплаты акциза на табак (табачные изделия),предназначенные для потребления путем нагревания`,
  // },
  // 4301: {
  //   value: '4301',
  //   label: `Пени за просрочку уплаты акциза на автомобили легковые с мощностью двигателя свыше 112,5 кВт (150 л.с.) и до 147кВт (200 л.с)включительно`,
  // },
  // 4311: {
  //   value: '4311',
  //   label: `Пени за просрочку уплаты акциза на автомобили легковые с мощностью двигателя свыше 147кВт (200 л.с) и до 220 кВт (300 л.с.) включительно`,
  // },
  // 4321: {
  //   value: '4321',
  //   label: `Пени за просрочку уплаты акциза на автомобили легковые с мощностью двигателя свыше 220 кВт (300 л.с.) и до 294 кВт (400 л.с.)включительно`,
  // },
  // 4331: {
  //   value: '4331',
  //   label: `Пени за просрочку уплаты акциза на автомобили легковые с мощностью двигателя свыше 294 кВт (400 л.с.) и до 367 кВт (500 л.с.)включительно`,
  // },
  // 4341: {
  //   value: '4341',
  //   label: `Пени за просрочку уплаты акциза на автомобили легковые с мощностью двигателя свыше 367 кВт (500 л.с.)`,
  // },
  // 4351: {
  //   value: '4351',
  //   label: `Пени за просрочку уплаты акциза на виноматериалы, виноградное сусло, фруктовое сусло, ввозимые на территорию Российской Федерации`,
  // },
  // 4361: {
  //   value: '4361',
  //   label: `Пени за просрочку уплаты акциза на устройства для нагревания табака, ввозимые на территорию Российской Федерации`,
  // },
  // 4371: {
  //   value: '4371',
  //   label: `Пени за просрочку уплаты акциза на вина, фруктовые вина (за исключением игристых вин (шампанских), ликерных вин), ввозимые на территорию Российской Федерации`,
  // },
  // 4381: {
  //   value: '4381',
  //   label: `Пени за просрочку уплаты акциза на игристые вина (шампанские), винные напитки, изготавливаемые без добавления ректификованного этилового спирта, произведенного из пищевого сырья, и (или) спиртованных виноградного или иного фруктового сусла, и дистиллятов, ввозимые на территорию Российской Федерации`,
  // },
  // 9121: {
  //   value: '9121',
  //   label: `Пени за просрочку уплаты утилизационного сбора, уплачиваемого за колесные транспортные средства (шасси) и прицепы к ним, ввозимые в Российскую Федерацию, кроме колесных транспортных средств (шасси) и прицепов к ним, ввозимых с территории Республики Беларусь. Указанный код вида платежа применяется с 12.12.2017 г.`,
  // },
  // 9131: {
  //   value: '9131',
  //   label: `Пени за просрочку уплаты утилизационного сбора, уплачиваемого за колесные транспортные средства (шасси) и прицепы к ним, ввозимые в Российскую Федерацию с территории Республики Беларусь. Указанный код вида платежа применяется с 12.12.2017 г.`,
  // },
  // 9151: {
  //   value: '9151',
  //   label: `Пени за просрочку уплаты утилизационного сбора, уплачиваемого за самоходные машины, ввозимые в Российскую Федерацию, кроме самоходных машин, ввозимых с территории Республики Беларусь`,
  // },
  // 9161: {
  //   value: '9161',
  //   label: `Пени за просрочку уплаты утилизационного сбора, уплачиваемого за самоходные машины, ввозимые в Российскую Федерацию с территорииРеспублики Беларусь НЕДЕЙСТВУЮЩИЕ КОДЫ`,
  // },
};
