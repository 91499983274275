import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getAccountsCount,
  getLoading,
  getAccounts,
  applyAccountsFilters,
  loadAccounts,
} from '../../store/transactions';
import { Message } from '../shared/Message';
import { Table } from '../shared/Table';
import { ROUTES } from '../../consts';
import { ACCOUNTS_TABLE_HEADER } from './AccountsTableHeader';

export const AccountsTable = ({ getFilters, filtersKey, messageSubject }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(getLoading);
  const accounts = useSelector(getAccounts);
  const filters = useSelector(getFilters);
  const countItems = useSelector(getAccountsCount);

  useEffect(() => {
    dispatch(loadAccounts(filters));
  }, [dispatch, filters]);

  return (
    <div>
      {accounts?.length ? (
        <Table
          onRowClick={({ _id }) => {
            navigate(`${ROUTES.FULL_ACCOUNTS}/${_id}`);
          }}
          loading={loading}
          items={accounts}
          headers={ACCOUNTS_TABLE_HEADER}
          filters={filters}
          countItems={countItems}
          applyFilters={(filters) =>
            dispatch(applyAccountsFilters(filtersKey, filters))
          }
        />
      ) : (
        <Message type="info">
          В системе пока нет сведений о счетах {messageSubject}
        </Message>
      )}
    </div>
  );
};
