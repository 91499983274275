import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UploadedDocument } from './UploadedDocument';
import { Button } from '../Button';
import { Survey } from '../Survey';
import { matchArray } from '../../../configs/requestDocs';
import { getRequest } from '../../../store/requests';
import { saveDocsQuery } from '../../../store/docsQueries';
import { getDocName } from '../../../utils';

import styles from './UploadRequestArea.module.scss';

export const UploadRequestArea = ({
  children,
  files,
  id,
  disabled,
  requestDocs,
  showButton,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const request = useSelector(getRequest);
  const requestId = request._id;
  const docsSection = matchArray.find((item) => item.key === requestDocs)
    .section;
  const sendDocsQuery = (queries) => {
    dispatch(saveDocsQuery(queries, requestId, docsSection));
  };

  return (
    <div className={styles.UploadRequestArea}>
      <div className={styles.FilesArea}>
        <div className={styles.FilesArea__HeadLine}>
          <div className={styles['FilesArea__HeadLine--PackageName']}>
            {children}
          </div>
          {showButton && (
            <Button filled onClick={() => setModalOpen(true)}>
              Запросить документ
            </Button>
          )}
        </div>
        <div className={styles.FilesArea__UploadFiles}>
          {files?.map((doc, idx) => (
            <UploadedDocument
              key={doc + `${idx}`}
              link={doc}
              index={idx}
              name={getDocName({ doc, id, idx })}
              // TODO add onExclude
              disabled={disabled}
            />
          ))}
        </div>
      </div>
      <Survey
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        options={requestDocs}
        onSubmit={sendDocsQuery}
        title="Запрос документов"
        textPlaceholder="Комментарий"
        submitButtonText="Запросить"
      />
    </div>
  );
};
