import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './PackSeaRoutes.module.sass';
import classnames from 'classnames';

import { Input } from './Input';
import {
	getSeaSeaView,
	getSeaTrainView,
	recoverSeaSeaRoute,
	recoverSeaTrainRoute,
	changeSeaTrainView,
	changeSeaSeaView } from '../../../../store/makeOffers';

export const PackSeaRoutes = () => {

	const dispatch = useDispatch();
	const listSeaRoutes = useSelector(getSeaSeaView);
	const listTrainRoutes = useSelector(getSeaTrainView);

	const handlerSeaClick = (event) => {
		let element;
		if(element = event.target.closest("."+classnames(styles.reject))){
			dispatch(recoverSeaSeaRoute(element.dataset.idx, element.dataset.country));
		}
	}
	const handlerTrainClick = (event) => {
		let element;
		if(element = event.target.closest("."+classnames(styles.reject))){
			dispatch(recoverSeaTrainRoute(element.dataset.idx, element.dataset.country));
		}
	}

	const changeSeaValueInput = (index, country, field, state) => {
		dispatch(changeSeaSeaView(index, country, field, state));
	}	

	const changeTrainValueInput = (index, country, field, state) => {
		dispatch(changeSeaTrainView(index, country, field, state));
	}

	return (
		<>	<div className={classnames(styles.wrap_pack_routes)}>
			{!Boolean(listSeaRoutes.length) && !Boolean(listTrainRoutes.length) && (<div className={classnames(styles.routes_info)}>
				Чтобы создать предложения, воспользуйтесь панелью справа. 
			</div>)}	
			{(Boolean(listSeaRoutes.length) || Boolean(listTrainRoutes.length)) && (<><ul className={classnames(styles.pack_routes)} onClick={handlerSeaClick}>
				{Boolean(listSeaRoutes.length) && listSeaRoutes.map(({index, country, departure, arrival, params})=>(
					<li className={classnames(styles.route)}>
						<div className={classnames(styles.reject)} data-idx={Number(index)} data-country={country}></div>
						<div className={classnames(styles.pack_route_descr)}>{`${departure.cityPort} - ${arrival.city}`}</div>
						<div className={classnames(styles.wrap_con_and_cur)}>
							<div className={classnames(styles.wrap_containers)}>
								<div className={classnames(styles.pack_containers)}>
									<Input 
										inputName="20'"
										placeHolder="Цена"
										rule="number"
										inputValue={params.twenty}
										setStateCb={changeSeaValueInput.bind(null, index, country, "twenty")}			
									/>
									<Input 
										inputName="40'"
										placeHolder="Цена"
										rule="number"
										inputValue={params.forty}
										setStateCb={changeSeaValueInput.bind(null, index, country, "forty")}			
									/>	
									<Input 
										inputName="40HC"
										placeHolder="Цена"
										rule="number"
										inputValue={params.fortyhc}
										setStateCb={changeSeaValueInput.bind(null, index, country, "fortyhc")}			
									/>											
								</div>
								<div className={classnames(styles.pack_containers_st)}>
									<div className={classnames(styles.descr_input)}>Дней в пути</div>
									<Input
										placeHolder="Дней"
										rule="number"
										inputValue={params.duration}
										setStateCb={changeSeaValueInput.bind(null, index, country, "duration")}		
									/>
								</div>								
							</div>
							<div className={classnames(styles.route_currency)}>$</div>	
						</div>										
					</li>
				))}									
			</ul>
			<ul className={classnames(styles.pack_routes)} onClick={handlerTrainClick}>
				{Boolean(listTrainRoutes) && listTrainRoutes.map(({index, country, departure, arrival, params})=>(
					<li className={classnames(styles.route)}>
						<div className={classnames(styles.reject)} data-idx={Number(index)} data-country={country}></div>
						<div className={classnames(styles.pack_route_descr)}>{`${departure.city} - ${arrival.city}`}</div>
						<div className={classnames(styles.wrap_con_and_cur)}>
							<div className={classnames(styles.wrap_containers)}>
								<div className={classnames(styles.pack_containers)}>
									<Input 
										inputName="20'"
										placeHolder="Цена"
										rule="number"
										inputValue={params.twenty}
										setStateCb={changeTrainValueInput.bind(null, index, country, "twenty")}			
									/>
									<Input 
										inputName="40'"
										placeHolder="Цена"
										rule="number"
										inputValue={params.forty}
										setStateCb={changeTrainValueInput.bind(null, index, country, "forty")}			
									/>	
									<Input 
										inputName="40HC"
										placeHolder="Цена"
										rule="number"
										inputValue={params.fortyhc}
										setStateCb={changeTrainValueInput.bind(null, index, country, "fortyhc")}			
									/>											
								</div>
								<div className={classnames(styles.pack_containers_st)}>
									<div className={classnames(styles.descr_input)}>Дней в пути</div>
									<Input
										placeHolder="Дней"
										rule="number"
										inputValue={params.duration}
										setStateCb={changeTrainValueInput.bind(null, index, country, "duration")}				
									/>
								</div>								
							</div>
							<div className={classnames(styles.route_currency)}>₽</div>	
						</div>										
					</li>
				))}									
			</ul></>)}
			</div>
		</>		

	);
}