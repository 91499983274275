import React from 'react';

export const NotFound = () => (
  <div
    style={{
      display: 'flex',
      height: 'calc(100vh - 4.3rem)',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <h1>404 :(</h1>
  </div>
);
