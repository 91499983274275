import {
  LOAD_DECLARANTS_REQUEST,
  LOAD_DECLARANTS_SUCCESS,
  LOAD_DECLARANTS_FAILURE,
  LOAD_DECLARANT_REQUEST,
  LOAD_DECLARANT_SUCCESS,
  LOAD_DECLARANT_FAILURE,
  UPDATE_VERIFY_STEP,
} from './actions';

const initialState = {
  items: [],
  count: 0,
  loading: true,
  error: null,
  item: null,
};

export const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DECLARANTS_REQUEST:
    case LOAD_DECLARANT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOAD_DECLARANT_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.payload,
      };
    case LOAD_DECLARANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        count: action.payload.count,
        items: action.payload.declarants,
      };
    case LOAD_DECLARANT_FAILURE:
    case LOAD_DECLARANTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_VERIFY_STEP:
      const { section, stepToSet } = action.payload;

      return {
        ...state,
        item: {
          ...state.item,
          [section]: { ...state.item[section], step: stepToSet },
        },
      };
    default:
      return state;
  }
};
