import React, { useState, useRef, useEffect } from 'react';
import styles from './Input.module.sass';
import 'react-calendar/dist/Calendar.css';
import classnames from 'classnames';
import Calendar from 'react-calendar';
import { toDate } from '../../../../utils';
import { format } from 'date-fns';

//ex/ 
// rule = "english" or "number" or "date" or "russian"
// pin = "schedule" or {title:"", firstclue:"", secondclue:"", example:""}


export const Input = ({inputName, placeHolder, rule, pin, width, inputValue, setStateCb, disabled}) => {


	const wrapInput = useRef(null);	
	const [calendarState, setCalendarState] = useState(false);


	const createDivs = (pin) => {

		const pins = {
			schedule: ()=>(
				<>
					<div className={classnames(styles.sched_pin)}></div>
				    <div className={classnames(styles.wrap_calendar, {[styles["show"]]: calendarState})}>
				      <Calendar onChange={stateCb} value={toDate(inputValue.value)} />
				    </div>
				</>
			)
		};
		const validateArr =	["title", "firstclue", "secondclue", "example"];

		if (pin){
			if (typeof pin == "object" && Object.keys(pin).every((key)=>validateArr.includes(key))){
				return(
					<>
						<div className={classnames(styles.info_pin)}></div>
						<div className={classnames(styles.tooltip_wrap)}>
							<div className={classnames(styles.tooltip_wrap_title)}>
								<div className={classnames(styles.tooltip_header_title)}>
									{pin.title}
								</div>
								<div className={classnames(styles.tooltip_icon_info)}></div>
							</div>
							<div className={classnames(styles.tooltip_clue)}>
								{pin.firstclue}							
							</div>
							<div className={classnames(styles.tooltip_clue)}>
								{pin.secondclue}
							</div>
							<div className={classnames(styles.tooltip_example)}>
								{pin.example}							
							</div>
						</div>	
					</>				
				);

			} else if (typeof pin === "string" && pin in pins){
				return pins[pin]();
			}
		}

		return;
	}
	const stateCb = (value) => {		
		setStateCb({
						value: format(value, 'dd.MM.yyyy'),
						correct: true
					});
		setCalendarState(false);		
	}
	const handlerChange = (event) => {

		let input, regExp;

		if ( input = event.target.closest("."+classnames(styles.input))){

			regExp = {
				english: /^[0-9a-zA-Z,.'\-_№\\\/() :&*]+$/g,
				number: /^\d+$/g,
				date: /^\d{4}[ \/\.]\d{1,2}[ \/\.]\d{1,2}$|^\d{1,2}[ \/\.]\d{1,2}[ \/\.]\d{4}$/g,
				russian: /^[а-яА-Я0-9a-zA-Z,.'\-_№\\\/() :&*]+$/g,
				email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			}[rule];

			if ((!regExp.test(input.value) && /.+/g.test(input.value)) || /^\s+$/g.test(input.value)){

				if (setStateCb){
					setStateCb({
						value: /^\s+$/g.test(input.value)?false:null,
						correct: /^\s+$/g.test(input.value)?true:false
					});
				}				

			} else {

				if (setStateCb){
					setStateCb({
						value: (input.value)? input.value : false,
						correct: true
					});
				}				
								
			}
						
		}
	}
	const clickHandler = (event) => {
		if (event.target.closest("."+classnames(styles.sched_pin))){
			setCalendarState((prev)=>!prev);			
		}
	}

	const clickOutHandler = (event) => {		
		if (!(event.target.closest("."+classnames(styles.wrap_calendar))) && !(event.target.closest("."+classnames(styles.sched_pin))) && calendarState){
			setCalendarState(false);			
		}
	}

	useEffect(()=>{
		document.addEventListener("click", clickOutHandler);
		return ()=>{document.removeEventListener("click", clickOutHandler);}
	},[calendarState]);		

	return(	
		<div
			className={classnames(styles.wrap_input, {
				[styles["invalid"]]: !inputValue.correct,
				[styles["disabled"]]: disabled
			})}
			style={{width:width}}
			onChange={handlerChange}
			onClick={clickHandler}			
			ref={wrapInput}
		>
			<input
				type="text"
				autocomplete="none"
				className={classnames(styles.input,{[styles["no_pin"]]:!pin})}
				placeholder={placeHolder}
				value={inputValue.value === false?"":inputValue.value}
				disabled={disabled}
			/>
			{Boolean(inputName) && (<div className={classnames(styles.input_name)}>{inputName}</div>)}
			{Boolean(pin) && createDivs(pin)}
		</div>		
	);
}



