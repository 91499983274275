import {
  ADD_ITEM_REQUEST,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_FAILURE,
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAILURE,
  FETCH_ITEM_REQUEST,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEM_FAILURE,
  REMOVE_ITEM,
  SET_BANK_INFO,
  SET_COMMON_INFO,
  SET_EXTRA_INFO,
  SET_DOCS_OPEN,
  SET_CEO_DOCS_OPEN,
  SET_ITEM,
  UPDATE_ITEM,
  SET_DEFAULT_ITEM,
  SET_COMPANY_OFFER
} from './actions';

const DEFAULT_BANK_STATE = {
  bik: '',
  name: '',
  accountNumber: '',
  mainAccount: false,
  correspondenceAccount: '',
  itn: '',
  kpp: '',
};

export const initialState = {
  items: [],
  item: {
    commonAddress: false,
    legalAddress: null,
    actualAddress: {},
    offer: '',
    itn: '',
    name: '',
    form: '',
    type: '',
    kpp: '',
    ogrn: '',
    registrationDate: new Date(),
    status: '',
    ceo: '',
    bank: DEFAULT_BANK_STATE,
    site: '',
    email: '',
    phone: '',
    esign: '',
    statut: [],
    bankAccounts: [],
    memorandum: [],
    stateRegistration: [],
    legalEntityRegistry: [],
    taxOfficeRegistry: [],
    accountSertificates: [],
    headAppointmentOrder: [],
    accountantAppointmentOrder: [],
    registerExtract: [],
    passportCeo: [],
  },
  loading: false,
  errors: null,
  commonInfo: {
    open: false,
    edit: false,
  },
  extraInfo: {
    open: false,
    edit: false,
  },
  bankInfo: {
    open: false,
    edit: false,
  },
  documentsOpen: false,
  ceoDocsOpen: false,
};

export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    case FETCH_ITEMS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case REMOVE_ITEM:      
      return {
        ...state,
        items: state.items.filter(({_id})=> (_id !== action.payload)),
        loading: false,
      };    
    case ADD_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ITEM_SUCCESS:
      return {
        ...state,
        item: { ...state.item, ...action.payload },
        loading: false,
      };
    case FETCH_ITEM_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case SET_ITEM:
      return {
        ...state,
        item: { ...state.item, ...action.payload },
      };
    case SET_COMPANY_OFFER:
      return {
        ...state,
        items: state.items.map((item)=>{if(item._id === state.item._id){item.offer=action.payload}return item}),
        item: { ...state.item, offer: action.payload },
      };
    case SET_DEFAULT_ITEM:
      return { ...state, item: initialState.item };      
    case UPDATE_ITEM:
      const { key, subKey, value } = action.payload;
      if (subKey) {
        return {
          ...state,
          item: {
            ...state.item,
            [key]: {
              ...state.item[key],
              [subKey]: value,
            },
            errors: {
              ...state.item.errors,
              [subKey]: undefined,
            },
          },
        };
      }
      return {
        ...state,
        item: {
          ...state.item,
          [key]: value,
        },
      };
    case SET_COMMON_INFO:
      return {
        ...state,
        commonInfo: { ...state.commonInfo, ...action.payload },
      };
    case SET_EXTRA_INFO:
      return {
        ...state,
        extraInfo: { ...state.extraInfo, ...action.payload },
      };
    case SET_BANK_INFO:
      return {
        ...state,
        bankInfo: { ...state.bankInfo, ...action.payload },
      };
    case SET_DOCS_OPEN:
      return {
        ...state,
        documentsOpen: action.payload,
      };
    case SET_CEO_DOCS_OPEN:
      return {
        ...state,
        ceoDocsOpen: action.payload,
      };
    default:
      return state;
  }
};
